/*
 * @Description: 本项目相关
 * @Author: luocheng
 * @Date: 2021-09-15 18:12:25
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-12-26 10:03:17
 */
import Vue from 'vue';

export default {
  state: {
    // 页面类型
    globalConfig: null,
    // 当前一级
    currentPart: null,
    // 是否显示一级菜单
    showChildMenu: true,
    // 历史列表
    historyList: [],
    // 当前浏览器打开的url列表
    urlList: [],
  },
  mutations: {
    setUrlList(state, data) {
			state.urlList = data;
		},
    /**
     * @desc: 设置数据配置
     */
    setRouterConfig(state, config) {
      state.globalConfig = config;
    },
    /**
     * @desc: 当前一级菜单
     */
    serCurrentPart(state, part) {
      if (!part || typeof part !== 'object') return;
      state.currentPart = part;
      sessionStorage.setItem('currentPart', JSON.stringify(part));
    },
    /**
     * @desc: 切换子菜单显示
     */
    changeChildShowStatus(state, status) {
      state.showChildMenu = status !== undefined ? status : !state.showChildMenu;
    },
    /**
     * @desc: 设置菜单历史列表
     */
    setNavHistory(state, history) {
      if (Array.isArray(history)) {
        Vue.set(state, 'historyList', history);
      } else {
        const idUUID = '4f6619f9-cd08-40b1-9487-c06441a82b6f';
        if (!state.historyList.find(ele => ele[idUUID] === history[idUUID])) {
          state.historyList.push(history);
        }
      }
      sessionStorage.setItem('navHistoryList', JSON.stringify(state.historyList))
    },
    /**
     * @desc: 移除
     */
    removeNavHistory(state, index) {
      state.historyList.splice(index, 1)
      sessionStorage.setItem('navHistoryList', JSON.stringify(state.historyList))
    }
  }
}