<!--
    @name: StepProp
    @description：StepProp
    @author: ZengWei
    @date: 2022-08-27 11:26
-->
<template>
  <div class="step-prop">
    <el-form
      ref="form"
      :model="flowStep"
      label-width="80px"
      label-position="left"
      size="small"
    >
      <el-collapse v-model="activeNames">
        <el-collapse-item name="basic">
          <template #title>
            <span class="collapse-title">基本设置</span>
          </template>
          <el-form-item label="步骤名称">
            <el-input v-model="flowStep.name" @change="onChange" :disabled="disabled"></el-input>
          </el-form-item>
          <el-form-item label="分支选择">
            <el-switch
              class="switch-fr"
              v-model="flowStep.selectStep"
              :disabled="disabled"
              @change="onChange">
            </el-switch>
          </el-form-item>
          <el-form-item label="开启转交">
            <el-switch
              class="switch-fr"
              v-model="flowStep.turnTo"
              @change="onChange"
              :disabled="disabled"></el-switch>
          </el-form-item>
<!--          <el-form-item label="流程撤回">
            <el-switch
              class="switch-fr"
              v-model="flowStep.revoke"
              @change="onChange"
              :disabled="disabled"></el-switch>
          </el-form-item>
          <el-form-item label="退回类型">
            <el-radio-group
              v-model="flowStep.backType"
              size="small"
              style="float: right"
              :disabled="disabled"
              @change="onChange"
            >
              <el-radio-button :label="1">退回发起人</el-radio-button>
              <el-radio-button :label="2">退回上一步</el-radio-button>
              <el-radio-button :label="3">选择退回</el-radio-button>
            </el-radio-group>
          </el-form-item>-->
        </el-collapse-item>
        <el-collapse-item name="check">
          <template #title>
            <span class="collapse-title">审批人员</span>
          </template>
          <el-form-item label="" label-width="0px">
            <el-radio-group v-model="flowStep.userType" @change="onUserType" :disabled="disabled">
              <el-radio :label="1">指定人员</el-radio>
              <el-radio :label="2">发起人自选</el-radio>
              <!--<el-radio :label="3">主管（负责人）</el-radio>-->
              <el-radio :label="5">部门人员</el-radio>
              <el-radio :label="6">岗位人员</el-radio>
              <el-radio :label="7">流程中指定</el-radio>
              <el-radio :label="8">发起人审核</el-radio>
              <el-radio :label="9">空步骤</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="选择范围"
            v-if="[1, 2, 6, 7].indexOf(flowStep.userType) > -1">
            <el-radio-group
              v-model="flowStep.userFrom"
              size="small"
              :disabled="disabled"
              @change="onUserScope"
            >
              <el-radio-button :label="1">本级架构</el-radio-button>
              <el-radio-button :label="3">上级组织</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="flowStep.userType === 1" label="人员选择">
            <UserPicker
              :key="'check'+flowStep.userArch"
              :show-choose-button="true"
              :company-id="flowStep.userArch"
              :show-list="flowStep.targetUser"
              @on-select-change="onSelectUser">
            </UserPicker>
          </el-form-item>
          <el-form-item v-if="flowStep.userType === 5" label="部门选择">
            <DepartPicker
              :show-choose-button="true"
              :show-list="flowStep.userSection"
              @on-select-change="onSelectDepart">
            </DepartPicker>
          </el-form-item>
          <el-form-item v-if="flowStep.userType === 6" label="岗位选择">
            <el-select
              multiple
              v-model="flowStep.userJob"
              @change="onChange"
              :disabled="disabled"
              placeholder="请选择"
              class="full-width"
            >
              <el-option
                v-for="item in jobList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="[1,5,6].includes(flowStep.userType)" label="人员范围">
            <el-switch
              v-model="flowStep.userScope"
              :disabled="disabled"
              @change="onChange"
            ></el-switch>
            <span style="margin-left: 15px">开启：已选择人员作为选择范围</span>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item name="formCtrl">
          <template #title>
            <span class="collapse-title">表单控制</span>
          </template>
          <template v-for="(item, index) in formItemList">
            <el-form-item :label="item.label" label-width="165px" :key="index">
              <el-radio-group
                v-model="item.type"
                size="small"
                :disabled="disabled"
                @change="onChange"
              >
                <el-radio-button :label="1">可编辑</el-radio-button>
                <el-radio-button :label="2">仅可见</el-radio-button>
                <el-radio-button :label="3">隐藏</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </template>
        </el-collapse-item>
        <el-collapse-item name="coSign">
          <template #title>
            <span class="collapse-title">会签或签加签</span>
          </template>
          <el-form-item label="" label-width="4px">
            <el-radio-group v-model="flowStep.signType" @change="onChange" :disabled="disabled">
              <el-radio :label="1" style="margin-right: 15px">或签（任意一人通过）</el-radio>
              <el-radio :label="2">会签（全部人员通过）</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="flowStep.signType === 2" label="会签比例">
            <el-radio-group
              v-model="flowStep.signRate"
              size="small"
              :disabled="disabled"
              @change="onChange"
            >
              <el-radio-button :label="1">全部通过</el-radio-button>
              <el-radio-button :label="2">过半通过</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div style="text-indent: 5px">加签类型</div>
          <el-form-item label="" label-width="4px">
            <el-radio-group v-model="flowStep.addSignType" @change="onChange" :disabled="disabled">
              <el-radio :label="1" style="margin-right: 15px">审批前加签</el-radio>
              <el-radio :label="2">通过后加签</el-radio>
              <el-radio :label="3">用户自选</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item name="btnList">
          <template #title>
            <span class="collapse-title">按钮设置</span>
          </template>
          <div class="form-table">
            <template v-for="(item, index) in flowStep.btnList">
              <div :key="index">
                <div class="clearfix self-define" v-if="!disabled">
                  <span>{{ index > 9 ? index : "0" + (index + 1) }}</span>
                  <span
                    v-if="item.system === 0"
                    class="float-r"
                    @click="delBtn(index)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                  <span class="float-r" @click="upGo(index)">
                    <i class="el-icon-top"></i>
                  </span>
                  <span class="float-r" @click="downGo(index)">
                    <i class="el-icon-bottom"></i>
                  </span>
                </div>
                <el-form-item label="按钮操作">
                  <el-select v-model="item.status" class="full-width" @change="onChange" :disabled="disabled">
                    <el-option label="退回" :value="-1">退回</el-option>
                    <el-option label="通过" :value="1">通过</el-option>
                    <el-option label="转交" :value="2">转交</el-option>
                    <el-option label="拒绝" :value="6">拒绝</el-option>
                    <el-option label="作废" :value="7">作废</el-option>
                    <el-option label="催办" :value="9">催办</el-option>
                    <el-option label="加签" :value="10">加签</el-option>
                    <el-option label="重新发起" :value="3">重新发起</el-option>
                    <el-option label="提交至退回人" :value="4">提交至退回人</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="按钮文字">
                  <el-input v-model="item.btn" @change="onChange" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="显示隐藏">
                  <el-radio-group v-model="item.show" @change="onChange" :disabled="disabled">
                    <el-radio :label="true">显示</el-radio>
                    <el-radio :label="false">隐藏</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </template>
            <el-button
              @click="addBtn"
              size="small"
              style="width: 100%; margin: 15px 0"
              icon="el-icon-plus"
              :disabled="disabled"
              >添加按钮</el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item name="suggest">
          <template #title>
            <span class="collapse-title">审批意见</span>
          </template>
          <div class="form-table">
            <el-form-item label="附件显示">
              <el-switch
                class="switch-fr"
                @change="onChange"
                :disabled="disabled"
                v-model="flowStep.attachShow"
              ></el-switch>
            </el-form-item>
            <el-form-item label="附件必填" v-if="flowStep.attachShow">
              <el-switch
                class="switch-fr"
                @change="onChange"
                :disabled="disabled"
                v-model="flowStep.attachRequired"
              ></el-switch>
            </el-form-item>
            <el-form-item label="意见显示">
              <el-switch
                class="switch-fr"
                @change="onChange"
                :disabled="disabled"
                v-model="flowStep.suggestShow"
              ></el-switch>
            </el-form-item>
            <el-form-item label="意见必填" v-if="flowStep.suggestShow">
              <el-switch
                class="switch-fr"
                @change="onChange"
                :disabled="disabled"
                v-model="flowStep.suggestRequired"
              ></el-switch>
            </el-form-item>
            <template v-for="(item, index) in flowStep.extendFields.fields">
              <div :key="index" style="margin-top: 10px">
                <el-form-item label="控件名称">
                  {{ item.__config__.label }}
                </el-form-item>
                <el-form-item label="控件类型">
                  {{ item.__config__.tagIcon }}
                </el-form-item>
              </div>
            </template>
            <el-button
              size="small"
              @click="addExtendDesign"
              style="width: 100%; margin: 15px 0"
              icon="el-icon-plus"
              :disabled="disabled">
              审批意见扩展表单控件配置
            </el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import {Collapse,CollapseItem,RadioGroup,RadioButton,Radio} from 'element-ui'
import UserPicker from '@/custom-component/form/formParser/selectUser'
import DepartPicker from '@/custom-component/form/formParser/flowEngine/components/selectDepartment'
import {dataInterface} from "@/apis/data";

export default {
  name: 'StepProp',
  components: {
    UserPicker,
    DepartPicker,
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-radio': Radio,
  },
  props: {
    curCellProp: {
      type: Object,
      default: () => {},
    },
    formItem: {
      type: Array,
      default: () => [],
    },
    userData: {
      type: Array,
      default: () => [],
    },
    formDesign: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flowStep: {
        name: '审批节点',
        userType: 1,
        userFrom: 1,
        userArch: 0,
        userScope: false, // 二次选择范围
        userSection: [], // 部门
        userJob: [], // 岗位
        targetUser: [],
        selectStep: false,
        backType: 1,
        turnTo: false,
        revoke: false,
        signType: 1,
        signRate: 1,
        addSignType: 1,
        attachShow: true,
        attachRequired: false,
        suggestShow: true,
        suggestRequired: false,
        extendFields: {},
        btnList: [],
        editFields: []
      },
      formItemList: [],
      jobList: [],
      activeNames: 'basic',
    };
  },
  watch: {
    formDesign: {
      handler(val){
        this.flowStep.extendFields = val
        this.onChange()
      },
      deep: true
    },
    formItem: {
      handler(){
        const formItem = []
        for (const item of this.formItem) {
          const show = item.element.config.__config__.addShow
          let type = show === false ? 3 : 2
          let eleItem = {
            label: item.label,
            formId: item.formId,
            field: item.field,
            type: type,
          }
          if(this.curCellProp.editFields){
            eleItem.type = this.curCellProp.editFields[item.formId] || 2
          }
          formItem.push(eleItem)
        }
        this.formItemList = formItem
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initProp();
  },
  methods: {
    initProp() {
      this.flowStep = this.curCellProp
      if(this.flowStep.userType === 6){
        this.getArchRole() //请求岗位接口
      }
    },
    onSelectDepart(departData){
      let department = [];
      for (let item of departData) {
        let depart = {
          id: item.id,
          name: item.name,
          type: item.section_type,
        };
        department.push(depart);
      }
      this.flowStep.userSection = department;
      this.onChange()
    },
    onSelectUser(userData){
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          name: item.name,
          user_table: item.user_table,
          section_id: item.section_id,
          user_id: item.user_id,
        };
        users.push(user);
      }
      this.flowStep.targetUser = users;
      this.onChange()
    },
    onUserType() {
      switch (this.flowStep.userType){
        case 1:
          this.flowStep.userSection = []
          this.flowStep.userJob = []
          break
        case 2:
          this.flowStep.targetUser = []
          this.flowStep.userSection = []
          this.flowStep.userJob = []
          this.flowStep.userScope = false
          break
        case 5:
          this.flowStep.targetUser = []
          this.flowStep.userArch = 0
          this.flowStep.userFrom = 1
          this.flowStep.userJob = []
          this.flowStep.userScope = false
          break
        case 6:
          this.flowStep.targetUser = []
          this.flowStep.userSection = []
          this.flowStep.userArch = 0
          this.flowStep.userFrom = 1
          this.flowStep.userScope = false
          break
        case 7:
          this.flowStep.targetUser = []
          this.flowStep.userSection = []
          this.flowStep.userJob = []
          this.flowStep.userScope = false
          break
        case 8:
        case 9:
          this.flowStep.targetUser = []
          this.flowStep.userSection = []
          this.flowStep.userJob = []
          this.flowStep.userArch = 0
          this.flowStep.userFrom = 1
          this.flowStep.userScope = false
          break
      }
      this.onUserScope();
    },
    getArchRole(){
      let queryData;
      const archiInfo = this.$GetTargetArchi()
      let viewUuid = 'view6172842c7f669'
      let archiId = archiInfo.archiId

      if(this.flowStep.userFrom === 1){
        if(archiInfo.archiType === 'company') {
          viewUuid = 'view61728398b0390'
          queryData = {
            object_uuid: 'object6170c260890d9',
            view_uuid: viewUuid,
            company_id: archiId,
            transcode: 0,
            __method_name__:'dataList'
          }
        } else {
          queryData = {
            object_uuid: 'object6170c260890d9',
            view_uuid: viewUuid,
            project_id: archiId,
            transcode: 0,
            __method_name__:'dataList'
          }
        }
      } else {
        if(archiInfo.archiType === 'company') {
          if(archiInfo.data.parent_id === 0) {
            this.jobList = []
            return
          } else {
            archiId = archiInfo.data.parent_id
            queryData = {
              object_uuid: 'object6170c260890d9',
              view_uuid: viewUuid,
              company_id: archiId,
              transcode: 0,
              __method_name__:'dataList'
            }
          }
        } else {
          if(archiInfo.data.parent_id === 0) {
            viewUuid = 'view61728398b0390'
            archiId = archiInfo.data.company_id
            queryData = {
              object_uuid: 'object6170c260890d9',
              view_uuid: viewUuid,
              company_id: archiId,
              transcode: 0,
              __method_name__:'dataList'
            }
          } else {
            viewUuid = 'view6172842c7f669'
            archiId = archiInfo.data.parent_id
            queryData = {
              object_uuid: 'object6170c260890d9',
              view_uuid: viewUuid,
              project_id: archiId,
              transcode: 0,
              __method_name__:'dataList'
            }
          }
        }
      }

      dataInterface(queryData).then(res => {
        if(res.data.code === 200){
          this.jobList = res.data.data;
        }
      })
    },
    onUserScope() {
      if(this.flowStep.userType === 6){
        this.getArchRole() //请求岗位接口
      }
      let targetArch = JSON.parse(localStorage.getItem('targetArchi'))
      const archId = targetArch.company_id ? targetArch.company_id : targetArch.parent_id
      if(this.flowStep.userFrom === 3){
        this.flowStep.userArch = archId
      } else {
        this.flowStep.userArch = 0
      }
      this.onChange()
    },
    addBtn() {
      let item = {
        btn: '', status: 1, show: true, system: 0,
      };
      this.flowStep.btnList.push(item)
      this.onChange();
    },
    delBtn(index) {
      this.flowStep.btnList.splice(index,1)
      this.onChange();
    },
    upGo(index) {
      let fieldData = this.flowStep.btnList;
      if(index !== 0){
        fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
      }else{
        fieldData.push(fieldData.shift());
      }
      this.flowStep.btnList = fieldData;
      this.onChange();
    },
    downGo(index) {
      let fieldData = this.flowStep.btnList;
      if(index !== fieldData.length-1){
        fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
      }else{
        fieldData.unshift( fieldData.splice(index,1)[0]);
      }
      this.flowStep.btnList = fieldData;
      this.onChange();
    },
    addExtendDesign() {
      this.$emit('on-form',this.curCellProp.extendFields)
    },
    onChange() {
      const fields = {}
      for (let item of this.formItemList){
        fields[item.formId] = item.type
      }
      this.flowStep.editFields = fields
      this.$emit('on-change', this.flowStep);
    },
  },
};
</script>

<style lang="less" scoped>
.step-prop {
  text-align: initial;

  .collapse-title {
    text-indent: 15px;
  }

  .switch-fr {
    float: right;
    margin-top: 8px;
  }

  .full-width {
    width: 100%;
  }

  .float-r {
    float: right;
    padding: 0 1px;
    cursor: pointer;
  }
  .self-define {
    margin: 8px 0;
    text-indent: 2px;
  }

  :deep(.el-radio) {
    margin-top: 8px;
    margin-bottom: 5px;
  }

  :deep(.el-collapse-item__content) {
    padding-bottom: 0;
  }

  :deep(.el-collapse-item__header) {
    position: relative;

    &:before {
      position: absolute;
      top: 16px;
      width: 3px;
      height: 14px;
      margin-right: 10px;
      background-color: var(--themeColor) !important;
      content: "";
    }
  }

  :deep(.form-table) {
    .el-form-item {
      border: 1px solid #eaebed;
      display: flex;
      margin-bottom: -1px !important;
      /*margin-bottom: -1px !important;*/

      .el-form-item__label {
        border-right: 1px solid #eaebed;
        background: #f8f8f9;
        padding: 6px 10px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        flex: 1;
        padding: 6px 10px;
        overflow: hidden;
        clear: both;
        /*margin-bottom: 1px;*/

        .el-select,
        .el-cascader {
          width: 100%;
        }

        .self-type {
          .el-radio {
            margin-right: 16px;
          }
        }
      }
    }
  }
}
</style>
