import Viewer from "../../Viewer";
import eventBus from '@/plugins/eventBus';
export default class PartManager {

    get id(){
        return "PartManager";
    }

    get data(){
        return {
        }
    }

    get watch (){
        return {

        }
    }

    get methods (){
        return {
            //行为注册
            part_initBehaviors(){
                this.registerBehaviors([{
                    label : "[构件]添加颜色",
                    desc : `
                        参数[ dbids ]。 dbids=>构件id数组 例如[12,15]
                        参数[ color ]。 color=>修改的颜色 例如[#223344]
                        参数[ model_id ]. model_id=>模型id 例如[fae9fe29b0fa477587b34812f1522b5e]
                    `,
                    value : "partman_setColor",
                    params: ['dbids', 'color', 'model_id'],
                    func : (params)=>{
                        if(!params?.dbids || !params.color || !params.model_id ) return;

                        const { dbids , color , model_id } = params;
                        const list = [];

                        dbids.split(",").map((i)=>{
                            list.push(parseInt(i));
                        });

                        this.viewer.action('setColor' , list , color , model_id);
                    },
                },{
                    label : "[构件]清除颜色",
                    desc : `
                        参数[ dbids ]。 dbids=>构件id数组 例如[12,15]
                        参数[ model_id ]. model_id=>模型id 例如[fae9fe29b0fa477587b34812f1522b5e]
                    `,
                    value : "partman_clearColor",
                    params: ['dbids', 'model_id'],
                    func : (params)=>{
                        if(!params?.dbids ||  !params.model_id ) return;
                        const { dbids , model_id } = params;
                        const list = [];

                        dbids.split(",").map((i)=>{
                            list.push(parseInt(i));
                        });

                        this.viewer.action('clearColor' , list , model_id);
                    },
                }]);
            },
            part_setColor( selection , color ){
                for(let { model_id , dbids} of selection){
                    this.viewer.action('setColor' , dbids , color , model_id);
                }
            },
            part_deleteColor( selection ){
                for(let { model_id , dbids} of selection){
                    this.viewer.action('clearColor' , dbids , model_id);
                }
            },
        }
    }

    get func(){
        return {
            initViewerEventListener(){
                this.viewer.on(Viewer.Events.ViewerInited , ()=>{
                    for(let id in this.element.modelBIMPartColor){
                        const { dbids , color } = this.element.modelBIMPartColor[id];

                        this.part_setColor(dbids , color);
                    }
                });
            },
            mounted (){
                this.part_initBehaviors();

                if( !this.element?.modelBIMPartColor){
                    this.element.modelBIMPartColor = {}
                }

                eventBus.$on("ViewerBIM.Part.GetColorMap" , ( eid , cb )=>{
                    if( eid !== this.element.id)return;

                    cb && cb(Object.assign({} , this.element.modelBIMPartColor));
                });

                eventBus.$on("ViewerBIM.Part.ClearColorById" , (eid , id) =>{
                    if( eid !== this.element.id)return;

                    if(this.element.modelBIMPartColor[id]){
                        const { dbids } = this.element.modelBIMPartColor[id];

                        this.part_deleteColor(dbids);
                    }
                });

                eventBus.$on("ViewerBIM.Part.SyncColorData" , (eid , data)=>{
                    if( eid !== this.element.id)return;

                    if ( this.element.modelBIMPartColor[data.id]){
                        const old = this.element.modelBIMPartColor[data.id];

                        let resetColorFlag = false;
                        if( JSON.stringify(old.dbids) !== JSON.stringify(data.dbids)) resetColorFlag = true;
                        if( old.color !== data.color) resetColorFlag = true;

                        this.element.modelBIMPartColor[data.id] = data;

                        if(resetColorFlag){
                            this.part_setColor(data.dbids , data.color);
                        }
                    } else {
                        this.element.modelBIMPartColor[data.id] = data;
                        this.part_setColor(data.dbids , data.color);
                    }
                });

                //根据 dbid 颜色 模型设置颜色
                eventBus.$on('ViewerBIM.Part.SetColor', ( eid , dbids , color , model_id  )=>{
                    if( eid !== this.element.id)return;

                    this.viewer.action('setColor' , dbids , color , model_id);
                });

                //根据 dbids 模型id 取消颜色
                eventBus.$on('ViewerBIM.Part.ClearColor', ( eid , list , model_id  )=>{
                    if( eid !== this.element.id)return;

                    this.viewer.action('clearColor' , list , model_id);
                });

                eventBus.$on('ViewerBIM.Part.ClearAllColor', (eid ) =>{
                    if( eid !== this.element.id)return;

                    this.viewer.action('clearAllcolor' );
                });
            }
        }
    }
}

