<!--
 * @Description: 常规页面配置
 * @Author: luocheng
 * @Date: 2021-08-17 16:59:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-11-22 22:21:22
-->
<template>
	<div class="normal">
		<ComponentBox
			v-for="element in componentList"
			:key="element.id"
			:element="element"
			@click.native.stop="onClickBox(element, 'click')"
      @mouseleave.native.stop="onClickBox(element, 'mouseleave')"
      @mouseenter.native.stop="onClickBox(element, 'mouseenter')"
			:pageId="pageId"
		></ComponentBox>
	</div>
</template>

<script>
import ComponentBox from './ComponentBox';

export default {
	name: 'NormalPage',
	components: {
		ComponentBox
	},
	props: {
		// 页面数据
    pageData: {
      type: Array,
      default: () => [],
      require: true
    },
		// 页面样式配置
    pageId: {
      type: Number,
      required: true
    }
	},
	data() {
		return {
			componentList: []
		};
	},
	created() {
		// console.log(this.pageData, 'this.pageData');
		this.componentList = this.pageData || []
	},
	inject: ['EDITOR_pageUUID'],
	watch: {
		// 组件列表
		pageData: {
			handler() {
				this.componentList = this.pageData || []
			},
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 触发点击事件
		 * @param {Object} element 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(element, eventType) {
			if (
				!element.events ||
				!element.events[eventType] ||
				!element.events[eventType].targets
			)
				return;
			// 暂时不管事件
			this.$store.commit('triggerEvents', {
				config: element.events.click,
				element,
				EDITOR_pageUUID: this.EDITOR_pageUUID
			});
		},
		
	}
};
</script>

<style lang="less" scoped>
.normal {
	height: 100%;
	width: 100%;
	position: relative;
	background: #fff;
	margin: auto;
	transition: all 0.3s;
	overflow: hidden;
	box-sizing: border-box;
	padding: 10px;
}
</style>