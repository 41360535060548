/* eslint-disable */
// 自定义验证规则
const checkRate = (rule, value, callback) => {
	if ( Object.prototype.toString.call(value) !=='[object Object]') {
		callback(new Error('值类型错误！'));
	} else {
		if (value.rate <=0 || value.comment.length <= 0) {
			callback(new Error('请填写评分评价'));
		} else {
			callback();
		}
	}
};
// 自定义验证规则
const checkVideo = (rule, value, callback) => {
	if (isNaN(value)) {
		callback(new Error('值类型错误！'));
	} else {
		if (Number(value) <= 0) {
			callback(new Error('必须观看视频'));
		} else {
			callback();
		}
	}
};

const formatElement = (ele) => {
	let itemData; //格式化后的item数据
	let recoverVal = '';
	let type = 'string'

  switch (ele.__ques__.quesType) {
    case 'textarea':
    case 'judge':
    case 'radio':
      recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue + '' : '';
      itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
      if (ele.__config__.required) {
        itemData.validate = {
          required: true, type: 'string', message: ele.__config__.label + '为必填项',
          trigger: 'change', transform: (value) => {
            let base;
            if (value) base = "" + value;
            return base;
          }
        };
      }
      break;
    case 'checkbox':
    case 'indefinite':
    case 'blank':
    case 'upload':
      recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
      itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
      if (ele.__config__.required) {
        itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
      }
      break;
    case 'video':
      recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
      itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
      if (ele.__config__.required) {
        itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'number', trigger: 'change'};
      }
      break;
    default:
      itemData = {type: ele.__config__.tagIcon, config: ele};
      if(ele.__vModel__){
        itemData.field = ele.__vModel__;
        itemData.value = '';
      }
  }
	return itemData;
}

export default formatElement;
