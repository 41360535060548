export default class ToolbarEvents {
    constructor() {}

    static getEvents(viewer, comp) {
        return {
            'ViewerGIS.getTerrain': (eid, cb) => {
                if (eid !== comp.element.id) return;

                let isActive = viewer.Scene.getTerrain();
                cb && cb(!!isActive);
            },
            'ViewerGIS.getDepthTest': (eid, cb) => {
                if (eid !== comp.element.id) return;

                let isActive = viewer.Scene.getDepthTest();
                cb && cb(!!isActive);
            },
            'ViewerGIS.getShadow': (eid, cb) => {
                if (eid !== comp.element.id) return;

                let isActive = viewer.Model.getShadows();
                cb && cb(!!isActive);
            },

            'ViewerGIS.setTerrain': (eid, isActive) => {
                if (eid !== comp.element.id) return;

                viewer.Scene.setTerrain(isActive);
            },
            'ViewerGIS.setDepthTest': (eid, isActive) => {
                if (eid !== comp.element.id) return;

                viewer.Scene.setDepthTest(isActive);
            },
            'ViewerGIS.setMeasure': (eid, isActive, cb) => {
                if (eid !== comp.element.id) return;

                viewer.Measure.setMeasureMenu(isActive, {
                    closeCallback: () => {
                        cb && cb();
                    }
                });
            },
            'ViewerGIS.setKeyboardRoam': (eid, isActive) => {
                if (eid !== comp.element.id) return;

                viewer.Roam.isKeyboardRoam = isActive;
            },
            'ViewerGIS.setBoxesSelect': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Model.boxesSelect();
            },
            'ViewerGIS.setIntersectSelect': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Model.intersectSelect();
            },
            'ViewerGIS.setShadow': (eid, isActive) => {
                if (eid !== comp.element.id) return;

                viewer.Model.setShadows(isActive);
            },
            'ViewerGIS.setShow': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Model.showAllModelFeature();
            },
            'ViewerGIS.setHide': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Model.hideTileBySelect();
            },
            'ViewerGIS.setInsulate': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Model.insulateTileBySelect();
            },
            'ViewerGIS.setNorthView': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Scene.setNorthView();
            },
            'ViewerGIS.setSquarelyView': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Scene.setSquarelyView();
            },
            'ViewerGIS.setModelManage': (eid, isActive, cb) => {
                if (eid !== comp.element.id) return;

                let updateCallback = ({modelId, position}) => {
                    comp.setResolveData("gis_modelUpdated_modelId", modelId);
                    comp.setResolveData("gis_modelUpdated_lng", position.lng);
                    comp.setResolveData("gis_modelUpdated_lat", position.lat);
                    comp.setResolveData("gis_modelUpdated_height", position.height);
                    comp.setResolveData("gis_modelUpdated_rotate", position.rotate);
                    comp.onAction('onModelUpdated');
                };

                let closeCallback = () => {
                    cb && cb();
                };

                viewer.Model.setModelMenu(isActive, {
                    updateCallback,
                    closeCallback
                });
            },
        };
    }
}
