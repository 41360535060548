/*
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-19 17:12:17
 * @Descripttion: 
 */
/* eslint-disable */
import formatElement from "@/components/answer/quesParser/scripts/quesParserElement.js";

const formValidate = (formJson, data = []) => {
	if(!formJson){
		return
	}
	for (let ele of formJson) {
		const itemData = formatElement(ele); //格式化后的item数据

		const children = ele.__config__?.children || [];
		const fillDataCopy = ele.__config__?.fillData || [];

		if (ele.__config__.tagIcon === 'tab') {
			itemData.value = [];
			for (let tab of ele.__tabs__) {
				let childrenData = formValidate(tab.children, []);
				itemData.value.push(childrenData);
			}
		} else if (ele.__config__.tagIcon === 'form') {
			let childrenData = formValidate(ele.__config__.children, []);
			let relateIndex = childrenData.findIndex(item => item.type === 'database')
			if (relateIndex !== -1) {
				const otherComp = childrenData.filter(item => item.type !== 'database')
				const relateComp = childrenData[relateIndex]
				relateComp.config.__slot__.options = relateComp.config.__slot__.options.filter(item => !item.element)
				for (let item of otherComp) {
					let attachItem = {
						label: item.config.__config__.label,
						value: item.config.__vModel__,
						width: item.config.__config__.width || 150,
						element: item
					}
					relateComp.config.__slot__.options.push(attachItem)
				}
				itemData.config.__config__.display = 'table-relate'
				itemData.config.__config__.relateConfig = relateComp.config;
			} else if (fillDataCopy && fillDataCopy.length > 0) {
				const fillItem = []; //预设填充数据
				for (const item of ele.__config__.fillData) {
					const formId = Object.keys(item)
					const cycleData = JSON.parse(JSON.stringify(childrenData));
					cycleData.forEach((ite) => {
						if (formId.includes(ite.config.__config__.formId)) {
							ite.value = item[ite.config.__config__.formId]
							//填充数据禁用输入
							if (ele.__config__.fillType && ele.__config__.fillType === 'text') {
								ite.config.disabled = true
							}
						}
					})
					fillItem.push(cycleData)
				}
				itemData.value = fillItem;
			} else {
				itemData.value.push(childrenData);
			}
		} else if (children && children.length > 0) {
			const childrenData = formValidate(ele.__config__.children, []);
			itemData.value = childrenData;
		}

		data.push(itemData);
	}
	return data;
}

export default formValidate;
