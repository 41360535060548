<!--
 * @Description: 饼图
 * @Author: luocheng
 * @Date: 2022-01-10 18:48:33
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-04-06 14:20:31
-->
<template>
	<div
		class="chart-pie"
		:style="{
			height: height + 'px'
		}"
		v-loading="getting"
		:element-loading-background="loadingBackColor"
	>
		<ChartContainer
			v-if="option && canShow"
			ref="chartInstance"
			class="chart-pie"
			:option="option"
		></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
/* eslint-disable */
import mixin from './mixin';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'ChartPie',
	mixins: [mixin],
	data() {
		return {
			statisticalData: null,
			option: null
		};
	},
	created() {
		this.getOptions();
	},
	methods: {
		/**
		 * @desc: 设置配置项
		 * @param {*} data
		 * @return {*}
		 */
		setOptions(data) {
			if (!data || !data.length) return false;
			const { attributes = {} } = this.chartData;
			const {
				showTitle = true,
				title = '',
				textColor = '',
				titleFontSize = 12,
				titleFontWeight = 400,
				showTooltip = true,
				tooltipFontsize = 14,
				showLegend = true,
				legendTextColor = '#000000',
				legenFontSize = 12,
				colorRange = [],
				showLabel = true,
				labelSize = 12,
				labelColor = '#000000',
				legendIcon = 'circle',
				changeToRing = false,
				isRose = false,
				legendOrient = 'vertical',
				legendType = 'plain',
				legendLimit = 100,
				titleTop = 'auto',
				titleBottom = 'auto',
				titleRight = 'auto',
				titleLeft = 'auto',
				left = '10%',
				right = '10%',
				top = 60,
				bottom = 60,
				pieLeft = '50%',
				pieTop = '50%',
				startRadius = 0,
				endRadius = 0,
        borderWidth = 0,
        borderRadius = 0, // 间距圆角
        borderColor = '#FFF', // 间距颜色
				centerFontColor = '#2EBF76',
				centerFontSize = 24,
				centerFontTop = 50,
				centerFontLeft = 50,
				descType = 'none' // 描述类型
			} = attributes;
			const dataItem = data[0];
			const seriesData = [];
			for (let key in dataItem) {
				seriesData.push({
					name: key,
					value: dataItem[key]
				});
			}
			if (!seriesData?.length) {
				this.canShow = false;
				return false;
			}
			let legendTop = '20%';
			const chartPie = this.$refs && this.$refs.chartInstance;
			if (chartPie) {
				legendTop =
					this.scaleSize((chartPie.offsetHeight - seriesData.length * (0 + 10)) / 2);
				if (legendTop < 10) {
					legendTop = 10;
				}
			}
			this.option = {
				title: {
					show: showTitle,
					text: title,
					left: 'left',
					y: 'top',
					textStyle: {
						color: textColor,
						fontFamily: '微软雅黑',
						fontSize: this.scaleSize(titleFontSize || 12),
						fontWeight: titleFontWeight || 400,
						left: titleLeft,
						top: titleTop,
						bottom: titleBottom,
						right: titleRight
					}
				},
				tooltip: {
					show: showTooltip,
					trigger: 'item',
					appendToBody: true,
					textStyle: {
						fontSize: this.scaleSize(tooltipFontsize || 14),
					},
					confine: true // 避免浮窗显示超出容器框
				},
				legend: {
					show: showLegend,
					// right: 'left',
					// top: legendTop || '20%', // 改为通过计算上下居中
					// right: '5%',
					...this.getLegendPosition(attributes),
					type: legendType,
					orient: legendOrient,
					itemWidth: 10,
					itemHeight: 10,
					icon: legendIcon,
					textStyle: {
						color: legendTextColor,
						fontSize: this.scaleSize(legenFontSize)
					},
					formatter: (name) => {
						const data = seriesData;
						let tarValue;
						for (var i = 0; i < data.length; i++) {
							if (data[i].name === name) {
								tarValue = data[i].value;
								break;
							}
						}
						// TIPS 计算需要截取的字体
						let formatTest = name
						if(this.$refs && this.$refs.chartInstance) {
							const limit = Math.floor(this.$refs.chartInstance?.$el.offsetWidth * legendLimit / 100 / 12)
							formatTest = name.length > limit - 4 ? name.substring(0, limit) + '...' : name
						}
						return `${formatTest} ${tarValue ?? 0}`;
					}
				},
				color: colorRange || [],
				grid: {
					left,
					right,
					bottom,
					top,
					containLabel: true
				},
				series: [
					{
						name: title || '',
						radius: changeToRing || isRose ? 
						startRadius && endRadius ? [`${startRadius}%`, `${endRadius}%`] : ['30%', '70%'] : '50%',
						// 玫瑰图
						roseType: isRose ? 'radius' : false,
						center: [pieLeft, pieTop],
						// 结束
						type: 'pie',
						data: seriesData || [],
						label: {
							show: showLabel,
							// formatter: '{b} {c}',
							textStyle: {
								fontSize: this.scaleSize(labelSize) //文字的字体大小
							}
						},
						itemStyle:{
              borderWidth:borderWidth,
              borderRadius:borderRadius,
              borderColor:borderColor,
            },
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}
				]
			};
			if(descType !== 'none') {
				let textValue = seriesData.reduce((pre, currentv) => {
					if(isNaN(currentv.value)) {
						return pre + 0;
					} else {
						return pre + Number(currentv.value);
					}
				}, 0)
				if(descType === 'total') {
					textValue = '总数：' + textValue;
				}
				this.option.graphic = [
					{
						type: 'group',
						rotation: 0,
						bounding: 'raw',
						top: `${centerFontTop}%`,
						left: `${centerFontLeft}%`,
						z: 100,
						children: [
							{
								type: 'text',
								left: 'center',
								top: 'center',
								z: 100,
								style: {
									fill: `${centerFontColor}`,
									text: textValue,
									font: `bold ${centerFontSize}px sans-serif`
								}
							}
						]
					}
				]
			}
		}
	}
};
</script>

<style lang="less" scoped>
.chart-bar {
	height: 100%;
	width: 100%;
	:deep(.el-empty){
		padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
		.el-empty__image{
			width: 35%;
			max-width: 120px;
			min-width: 50px;
		}
		.el-empty__description{
			margin-top: 15px;
		}
		.page-container p{
			color: #fff;
		}
	}
}
</style>
