export default {
  state: {
    // 任务状态列表
    taskStatusList: [],
    taskGraphData: [],
  },
  mutations: {
    setStatusList(state,statusConfig) {
      state.taskStatusList = statusConfig
    },
    setGraphData(state,graphData) {
      state.taskGraphData = graphData
    }
  }
}
