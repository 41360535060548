/*
 * @Date: 2022-12-08 16:52:22
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-17 11:54:47
 * @FilePath: /dataview-viewer-test/src/custom-component/mixins/BehaviorHandlerMixin.js
 */
import eventBus from "@/plugins/eventBus";
import { initParams, removeEventBus, getComponentById } from "@/utils/tools";
import { mapState } from "vuex";
import pcComponentList from "@/custom-component/component-list";

export default {
  props: {
    element: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["sContainer", "originComponentData", "componentData", 'editorType']),
  },
  data() {
    return {
      behaviorHandler: {},
    };
  },
  methods: {
    /**
     * @description: 注册行为
     * @param {Array} list
     */
    registerBehaviors(list = []) {
      for (let { label, func, value, desc, params } of list) {
        if (!this.behaviorHandler[value]) {
          this.element.behaviors.push({
            label,
            value,
            desc,
            params
          });
        }

        this.behaviorHandler[value] = func;
      }

      this.$store.commit("modifyComponent", {
        component: {
          ...this.element,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
    },
		/**
		 * @description: 获取组件列表
		 */
		_getBehaviors(element) {
			let behaviorList = [];
			const componentName = element.component;
			const componentId = element.id;
			if (["ViewerBIM", "ViewerGIS", "UEClient"].includes(componentName)) {
				const list = this.sContainer? this.originComponentData || this.componentData: this.componentData;
				const originComponent = getComponentById(list,componentId);
				behaviorList = originComponent.behaviors || [];
			} else {
				let componentList = [];
				if (
					[
						"page",
						"dataview",
						"console",
						"template",
						"second",
						"card",
					].includes(this.editorType) &&
					Array.isArray(pcComponentList)
				) {
					componentList = pcComponentList;
				}
				for (let i = 0; i < componentList.length; i++) {
					const children = componentList[i]?.componentList;
					if (Array.isArray(children)) {
						const component = children.find(
							(ele) => ele.component === componentName
						);
						if (component) {
							behaviorList = component.behaviors || [];
							break;
						}
					}
				}
			}
			return behaviorList;
		},
    /**
     * @description: 格式化参数
     * @param {Object/Array} value 参数值
     * @param {Object} mapObj 映射
     * @return {Object/Array} 结果
     */
    _formatParam(value, mapObj) {
      let result = null;
      if (Array.isArray(value)) {
        result = value.map((ele) => {
          if (Object.prototype.toString.call(ele) === '[object Object]' || Array.isArray(ele)) {
            return this._formatParam(ele, mapObj);
          }
          return ele;
        })
      }
      if (Object.prototype.toString.call(value) === '[object Object]') {
        for (let key in value) {
          if (mapObj?.[key]) {
            if (!result) {
              result = {
                ...value,
                [mapObj?.[key]]: value?.[key]
              }
            } else {
              result = {
                ...result,
                [mapObj?.[key]]: value?.[key]
              }
            }
          }
        }
      }
      return result;
    }
  },
  mounted() {
    const componentBehavior = {
      [this.element.id]: ({ component, list }) => {
        if (component === this.element.id) {
          for (let { behaviors, params, keyMap = null } of list) {
            for (let bid of behaviors) {
              if (this.behaviorHandler[bid]) {
                const { param = {} } = initParams(
                  params,
                  this.isGroup,
                  this.subComponentData,
                  this.groupComponents
                );
                try {
                  if (params && Object.keys(params).length) {
                    let mapObj = {};
                    let mapParam = param;
                    // 获取映射字段
                    if (keyMap && Object.keys(keyMap).length) {
                      for (let key in keyMap) {
                        const val = keyMap[key];
                        if (val?.field) {
                          mapObj = {
                            ...mapObj,
                            [val.field]: key
                          };
                        }
                      }
                    }
                    // 处理参数
                    if (mapObj && Object.keys(mapObj).length) {
                      for (let paramKey in param) {
                        let paramValue = param[paramKey];
                        if (['[object Array]', '[object Object]'].includes(Object.prototype.toString.call(param[paramKey]))) {
                          paramValue = this._formatParam(paramValue, mapObj);
                        }
                        mapParam = {
                          ...mapParam,
                          [paramKey]: paramValue
                        }
                      }
                    }
                    // 结果
                    this.behaviorHandler[bid](mapParam);
                  } else {
                    this.behaviorHandler[bid](param);
                  }
                  if (window.EDITORUseDebug) {
                    let behaviorList = this._getBehaviors(this.element);
                    window.$EditorDebug.push({
                      desc: `<p>组件【${this.element.name}】响应了行为<i>【${behaviorList?.find((ele) => ele.value === bid)?.label}】</i><strong>完毕！</strong></p>`,
                      details: {
                        param,
                        element: this.element,
                        behaviorName: bid,
                      },
                    });
                  }
                } catch (err) {
                  if (window.EDITORUseDebug) {
										let behaviorList = this._getBehaviors(this.element);
                    window.$EditorDebug.push({
                      desc: `<p>组件【${this.element.name}】执行行为<i>【${behaviorList?.find((ele) => ele.value === bid)?.label}】</i><em>出错了！</em></p>`,
                      details: {
                        param,
                        element: this.element,
                        behaviorName: bid,
                      },
                    });
                  }
                }
              }
            }
          }
        }
      }
    };
    eventBus.$on("doComponentBehavior", componentBehavior[this.element.id]);
  },
  beforeDestroy() {
    removeEventBus(["doComponentBehavior"], this.element.id);
  },
};
