/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-12-07 15:48:36
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-08-15 13:59:05
 * @FilePath: /dataview-viewer-test/src/utils/events.js
 * @Description: 事件相关抽离（部分，未完成全部）
 */
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data/index';
import { getComponentById, getQueryValue, doEEActionHandle, openUrl } from '@/utils/tools';
import store from '@/store/index';
/**
 * @description: 触发事件
 * @param {Object} element 组件
 * @param {String} eventKey 事件类型
 */
export const triggerEvents = (element, eventKey, componentData = [], that) => {
  eventBus.$emit('EDITOR_loopResolve', element.loopData);
  const excludesEvents = ['CommonDateButton'];
  if (excludesEvents.includes(element.component)) {
    return false;
  }
  if (!element.events || Array.isArray(element.events)) return;
  const { actionType, effects = [], behaviors, specialEventList = [], pattern } = element.events?.[eventKey] || {};
  const { linkType, queryList = [], linkUrl = '', pageId } = element?.events?.[eventKey] || {};
  if (actionType === 'eeAction') {
    // 触发后端事件
    const { objectUUID, viewUUID, eventName } = element.events?.[eventKey];
    if (!objectUUID || !viewUUID || !eventName) {
      that.$message.error('事件配置错误！');
      return false;
    }
    const sourceParams = that.getBindParams(element);
    dataInterface({
      __method_name__: 'customEventCall',
      object_uuid: objectUUID,
      view_uuid: viewUUID,
      ...sourceParams,
      event: eventName
    }).then((res) => {
      if (res.status === 200 && res.data.code === 200) {
        that.$message.success('操作成功！');
        doEEActionHandle(res.data?.__adds__);
      }
    }).catch(() => {
      // 出错了
    });
    return false;
  } else if (actionType === 'jumpPage') {
    if (linkType === 'projectPage') {
      const query = {};
      queryList.forEach((queryelement) => {
        let component = getComponentById(componentData, queryelement.componentId);
        that.$set(
          query,
          queryelement.key,
          component?.resolveData[queryelement.feild]
        );
      });
      that.$router.push({
        path: pageId,
        query
      });
      return;
    } else if (linkType === 'outPage') {
      openUrl(linkUrl,linkUrl)
      // window.open(linkUrl);
    } else if (linkType === 'custom') {
      // 暂未处理
      // const customLink = getLinkByTemplate(linkTemplate, output)
      // window.open(customLink);
    }
  } else {
    // 普通事件
    if (!pattern) {
      effects.forEach((ele) => {
        store.commit('triggerEvents', {
          config: ele,
          element: element
        });
      });
    } else if (pattern === 'special') {
      specialEventList.forEach(ele => {
        ele.effects.forEach(effect => {
          store.commit('triggerEvents', {
            config: effect,
            element: element
          });
        })
        if (ele.behaviors?.length) {
          for (let i = 0; i < ele.behaviors.length; i++) {
            store.commit('triggerEvents', {
              config: {
                behavior: ele.behaviors[i],
                isBehavior: true
              }
            });
          }
        }
      })
    }
  }
  if (behaviors && Array.isArray(behaviors)) {
    behaviors.forEach(behavior => {
      store.commit('triggerEvents', {
        config: {
          behavior,
          isBehavior: true
        },
        element: element
      });
    })
  }
}

/**
 * @description: 获取后端事件绑定参数
 * @param {Object} comp 组件
 */
export const getBindParams = comp => {
  if (!comp) return {};
  const sourceConfig = comp.sourceConfig || [];
  const sourceParams = {};
  for (let i = 0; i < sourceConfig.length; i++) {
    const {
      componentId,
      field,
      key,
      originType = '',
      urlParamKey = '',
      systemKey = '',
      systemCode = '',
      fixedValue = '',
      statusCode = ''
    } = sourceConfig[i];
    if (originType === 'url' && urlParamKey) {
      // 从url获取参数
      const result = getQueryValue(urlParamKey);
      this.$set(sourceParams, key, result);
    } else if (originType === 'system') {
      // 系统参数
      try {
        let obj = sessionStorage.getItem(systemKey);
        if (!obj) {
          obj = localStorage.getItem(systemKey);
        }
        if (!obj) break;
        const result = JSON.parse(obj);
        if (result && Object.prototype.toString.call(result) === '[object Object]') {
          const queryVal = result[systemCode];
          // if (isRequired && (queryVal === undefined || queryVal === null)) {
          //   canPost = false;
          //   break;
          // }
          this.$set(sourceParams, key, queryVal);
        }
      } catch (err) {
        console.log(err, '99999999');
      }
    } else if (originType === 'fixed') {
      // 固定值
      this.$set(sourceParams, key, fixedValue);
    } else if (originType === 'pageStatus') {
      // 页面状态
      const statusCodeValue = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode];
      this.$set(sourceParams, key, statusCodeValue || '');
    } else if (componentId && field && key) {
      // 普通从组件获取
      let sourceComponent = getComponentById(this.componentList || this.componentData, componentId);
      if (!sourceComponent && this.isGroup && this.groupComponents.length) {
        sourceComponent = getComponentById(this.groupComponents, componentId);
      }
      const result =
        sourceComponent && sourceComponent.resolveData
          ? sourceComponent.resolveData[field]
          : '';
      this.$set(sourceParams, key, result);
    }
  }
  return sourceParams;
}

