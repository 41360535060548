<!--
 * @Description: 公共表单容器 用于引入外部表单
 * @Author: luocheng
 * @Date: 2021-10-15 15:01:42
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:43:21
-->
<template>
	<div class="common-form-container" v-loading="loading">
		<template v-if="canInitForm">
			<article class="common-form-main">
				<PcParser
					v-if="contentConfig && contentConfig.objectUUID && formDesignData"
					@submit="onFormSubmit"
					:form-data="formDesignData"
					:ref="'common-form-container' + element.id"
					:displayData="displayData"
					@on-close="onEndFlow"
					:editFields="editFields"
					:disabled="formDisabled"
					:needFlow="!!needFlow"
					:moduleUuid="contentConfig.formUUID || contentConfig.objectUUID"
					:needParentFlow="!!needParentFlow"
					:formMode="requestType"
				>
				</PcParser>
			</article>
			<footer
				class="footer"
				v-if="statusConfig && statusConfig.showAction && Array.isArray(actionConfig)"
			>
				<el-button
					v-for="(btn, bIndex) in actionConfig"
					:key="bIndex"
					:type="btn.buttonType"
					@click="onButton(btn)"
				>
					{{ btn.text }}
				</el-button>
			</footer>
		</template>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
	</div>
</template>

<script>
import PcParser from '@/custom-component/form/newParser/PcParser';
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import { getComponentById, getQueryValue } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { Empty } from 'element-ui';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
	name: 'CommonFormContainer',
	mixins: [databaseTriggerMixin, databaseTriggerDebug],
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
	components: {
		PcParser,
		'el-empty': Empty
	},
	data() {
		return {
			// 表单配置数据
			formDesignData: null,
			displayData: {},
			// 流程中展示字段
			editFields: {},
			// 表单是否disable
			formDisabled: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 是否可以渲染表单
			canInitForm: false,
			loading: false
		};
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
		// 内容配置
		contentConfig() {
			return this.element?.contentConfig || {};
		},
		// 操作配置
		actionConfig() {
			return this.element?.actionConfig || {};
		},
		// 组件属性配置
		statusConfig() {
			return this.element?.statusConfig || {};
		},
		// 请求类型(待追加)
		requestType() {
			const types = {
				createData: 'add',
				dataInfo: 'detail'
			};
			return types[this.element?.sourceConfig?.[0]?.type || 'add'];
		},
		// 更新规则
		updateRulesConfig() {
			return (
				this.element?.updateRulesConfig || {
					pattern: '',
					rules: []
				}
			);
		}
	},
	watch: {
		contentConfig: {
			handler() {
				if (!this.contentConfig?.objectUUID) return;
				this.initFormDesign(this.contentConfig);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			// 监听请求
			// 配置关联参数的容器才需要监听
			const databaseTrigger = {
				[this.$elementId]: data => {
					if (data?.parentId !== this.element.id && data.action !== 'any') return false;
					this._startDataDebug();
					if (!this.contentConfig?.objectUUID) {
						this._failDataDebug('暂未配置对象UUID');
						return;
					}
					this.initFormDesign(this.contentConfig);
				}
			};
			eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
		});
	},
	methods: {
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			const { actionConfig } = this.element;
			const btnConfig = actionConfig.find(ele => ele.key === 'confirm');
			if (!btnConfig?.effect) {
				this.statusConfig.isShow = false;
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				let params = {};
				// 发送请求 整理参数
				const param = this.initParams().param;
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					...param,
					...formData // 表单的字段
				};
				this.$loading();
				// 发送请求
				dataInterface(params)
					.then(res => {
						this.$loading().close();
						if (res.status === 200 && res.data.code === 200) {
							this.beforeClose();
						}
					})
					.catch(err => {
						console.log(err);
						this.$loading().close();
					});
			}
		},
		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			this.formDesignData = null;
			const { objectUUID, formUUID } = contentConfig;
			transFieldsUUid(objectUUID, formUUID)
				.then(res => {
					this._successDataDebug({
						url: `api/metadata/transformuuid/${formUUID || objectUUID}`,
						content: {},
						res
					});
					if (res.data.code == 200) {
						const { sourceConfig } = this.element;
						if (!Array.isArray(sourceConfig) || !sourceConfig.length) {
							this._failDataDebug('暂未配置来源配置');
							return;
						}
						const { type } = sourceConfig?.[0] || {};
						this.formDisabled = type === 'dataInfo';
						this.needFlow = !!res.data?.data?.need_flow;
						this.needParentFlow = !!res.data?.data?.need_parent_flow;
						// 编辑或详情
						if (['updateData', 'dataInfo'].includes(type)) {
							// 编辑	获取已经有的数据进行绑定
							let config = res.data?.data;
							let ruuids = [];
							for (let i = 0; i < config.fields.length; i++) {
								let item = config.fields[i];
								if (item.__config__.tagIcon == 'form') {
									ruuids.push({ relationship_uuid: item.__vModel__ });
								}
							}
							window.$EditorDebug.setDivider('表单编辑或详情-请求表单内容');
							const param = this.initParams().param;
							const content = {
								...param,
								__method_name__: 'dataInfo',
								object_uuid: contentConfig.objectUUID,
								view_uuid: type === 'dataInfo' ? this.contentConfig.viewUUID : '',
								ruuids
							};
							dataInterface(content).then(res1 => {
								let data = res1.data.data;
								this.formDesignData = res.data.data;
								this.excelFormData = {
									[contentConfig.objectUUID]: JSON.parse(JSON.stringify(data))
								};
								this.excelMetaData = JSON.parse(JSON.stringify(res1?.data?.metadata || {})) || {};
								this.displayData = data || {};
								this.editFields = data.editFields || {};
								this.loading = false;
								this.canInitForm = true;
								this._successDataDebug({
									url: '',
									content,
									res: res1
								});
							});
						} else {
							this.formDesignData = res.data.data;
							this.canInitForm = true;
							this.loading = false;
						}
					}
				})
				.catch(err => {
					console.log(err);
					this.loading = false;
					this._errorDataDebug({
						url: `api/metadata/transformuuid/${formUUID || objectUUID}`,
						content: {},
						err
					});
				});
		},
		/**
		 * @desc: 流程处理结束回调处理
		 */
		onEndFlow() {
			this.beforeClose();
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		beforeClose() {
			// 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			// 更新规则 pattern: '' 默认规则, rules 强规则， compatible 兼容
			const { pattern = '', rules = [] } = this.updateRulesConfig;
			if (!pattern || pattern === 'compatible') {
				// 默认或兼容模式
				const sourceConfig = this.element.sourceConfig || [];
				for (let i = 0; i < sourceConfig.length; i++) {
					const {
						originType = '',
						componentId = ''
					} = sourceConfig[i] || {};
					if (!originType && componentId) {
						eventBus.$emit('databaseTrigger', {
							action: 'dataList',
							componentId: componentId,
							isInit: componentId.indexOf('CommonTree-') > -1,
							isUpdate: true,
							output:
								componentId.indexOf('CommonTabs-') > -1
									? this.getOutput(componentId)
									: ''
						});
					}
				}
			}
			if (['rules', 'compatible'].includes(pattern)) {
				rules.forEach(ele => {
					const { componentId = '' } = ele;
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: componentId,
						isInit: componentId.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: componentId.indexOf('CommonTabs-') > -1 ? this.getOutput(componentId) : ''
					});
				});
			}
		},
		/**
		 * @desc: 点击按钮
		 * @param {Object} btn 按钮对象
		 */
		onButton(btn) {
			if (btn.key === 'confirm') {
				// 确认
				this.onConfirm();
			} else if (btn.key === 'cancel') {
				// 取消
				console.log('取消');
			}
		},
		/**
		 * @desc: 确认按钮
		 */
		onConfirm() {
			const refString = `common-form-container${this.element.id}`;
			if (this.$refs?.[refString]) {
				this.$refs[refString].handleSubmit();
			}
		},
		/**
		 * @desc: 初始化参数
		 */
		initParams() {
			const { sourceConfig } = this.element;
			// 是否可以请求
			let canPost = true;
			if (!sourceConfig?.length)
				return {
					canPost
				};
			let search = [];
			const param = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					key = '', // 类型
					originType = '', // 值来源类型
					componentId = '', // 组件id
					statusCode = '', // 全局状态code
					isRequired, // 是否必须
					urlParamKey = '', // url参数
					field = '' // 字段
				} = sourceConfig?.[i];
				let originComponent = getComponentById(this.subComponentData, componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, componentId);
				}
				if (key !== 'search') {
					if (originType === 'pageStatus') {
						const queryVal = this._PageCustomStatus?.[statusCode] || this._APPCustomStatus?.[statusCode];
						this.$set(param, key, queryVal);
						if (isRequired && !queryVal) {
							canPost = false;
							break;
						}
					} else if (originType === 'url') {
						// url参数
						const queryVal = getQueryValue(urlParamKey);
						this.$set(param, key, queryVal);
						// 必填的不能为空，带ID 的不能为空
						if ((isRequired && !queryVal) || (urlParamKey && key.indexOf('id') > -1 && !queryVal)) {
							canPost = false;
							break;
						}
					} else if (isRequired && !originComponent?.resolveData) {
						canPost = false;
						break;
					} else if (originComponent?.resolveData) {
						const queryVal = originComponent?.resolveData?.[field];
						this.$set(param, key, queryVal);
						if ((isRequired && !queryVal) || (urlParamKey && key.indexOf('id') > -1 && !queryVal)) {
							canPost = false;
							break;
						}
					}
				} else {
					if (Array.isArray(originComponent?.resolveData)) {
						search = search.concat(originComponent.resolveData);
					}
				}
			}
			return { search, param, canPost };
		}
	}
};
</script>

<style lang="less" scoped>
.common-form-container {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	.common-form-main {
		flex: 1;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
	}
	.footer {
		text-align: center;
	}
	// 公共内容
	:deep(.type-label) {
		border-left: 3px solid var(--themeColor) !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
		text-align: left;
	}
}
</style>
