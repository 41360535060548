<!--
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-20 10:02:25
 * @Descripttion: 
-->
<!--
    @name: ques-indefinite
    @description：ques-indefinite
    @author: ZengWei
    @date: 2022-04-01 11:18
-->
<template>
  <div class="widget-video">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="ob-ques-type">
        视频题
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-video-player">
      <div class="video-player">
        <video controls id="video" ref="videoRef" :src="element.config.__slot__.src"></video>
      </div>
      <p class="q-video-requirement">请至少观看{{ element.config.__ques__.minimumLimit }}秒（{{ showCurrentTime }}/{{ element.config.__ques__.minimumLimit }}）</p>
    </div>
  </div>
</template>

<script>
import quesWidgetMinix from "@/components/answer/quesParser/scripts/quesWidgetMinix.js";

export default {
  name: "ques-video",
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  data() {
    return {
      showCurrentTime: 0
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.videoRef.addEventListener('timeupdate', this.calcScore)
    })
    const video = document.getElementById('video');
    if(this.element?.value && video){
      video.currentTime = this.element?.value;
    }
  },
  methods: {
    calcScore(e) {
      this.element.value = e.target.currentTime
      this.showCurrentTime = Math.floor(e.target.currentTime);
      this.$emit('changeVideo',e.target.currentTime);
      if(!this.element.disabled && +e.target.currentTime > +this.element.config.__ques__.minimumLimit) {
        if (this.element.config.__ques__.answerScore) {
          this.element.score = this.element.config.__ques__.score
          this.$emit('change')
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
.widget-video{
  .q-video-player{
    margin: 6px 0 0 16px;
    .video-player{
      padding-left: 30px;
      box-sizing: border-box;
      video{
        height: 320px;
        width: 100%;
      }
    }
    .q-video-requirement{
      padding-left: 30px;
      box-sizing: border-box;
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
