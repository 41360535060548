/*
 * @LastEditors: 冉桂精 156189868@qq.com
 * @Description: 获取树形叶子数量的混入方法
 * @FilePath: /dataview-viewer-test/src/custom-component/tree/treeCountMixin.js
 * @Date: 2022-07-06 15:51:32
 * @LastEditTime: 2023-11-29 11:14:26
 * @Author: lisushuang
 */

// 抛出一个getTreeCount(id)的方法 用于获取某个节点的子节点数量，渲染到树形组件中
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { getComponentById } from '@/utils/tools';

export default {
  data() {
    return {
      viewComponent: null,
      viewParams: [],
      gettingCountLoading: false,
      countNmber: 0,
      countDataList: [],
      preCountNumber: 0,
      preCountDataList: []
    }
  },

  methods: {
    /**
     * @description: 确定是否需要自动分析出查询叶子节点数据数量的视图
     */
    checkIfAutoFindView() {
      return true;
    },

    /**
     * @description: 从已经拿到的数据数量数组中一个一个取出数据
     */
    getNumberFromView() {
      if (this.countDataList.length == 0) {
        return 0;
      }
      const value = this.countDataList[this.countNmber];
      this.countNmber++;
      if (this.countNmber == this.countDataList.length - 1) {
        this.$nextTick(() => {
          this.countNmber = 0;
        })
      }
      if (!value) {
        return 0;
      }
      return value;
    },


    /**
     * @description: 将已经生成的数量数组放到树形数据里面去
     * @param {Array} treeData 当前要处理的数组
     * @param {Int} count 取数据到第几个了
     */
    sendNumberDataToTree(treeData = [], count = 0, preNumber = 0) {
      const childrenKey = this.propObj?.children
      if (!childrenKey || !Array.isArray(treeData)) return treeData;
      this.countNmber = count;
      this.preCountNumber = preNumber;
      // if (treeData === null) treeData = this.treeData
      return treeData.map((item) => {
        let number = this.countDataList[this.countNmber]
        if (this.element.countNumberConfig?.showPreNumber && this.preCountDataList.length) {
          // console.log('got it ')
          preNumber = this.preCountDataList[this.preCountNumber]
          this.preCountNumber++
        } else {
          preNumber = null
        }
        this.countNmber++

        // this.countDataList = this.countDataList.slice(1, this.countDataList.length)

        // if (item[childrenKey] && item[childrenKey].length > 0) {
        //   // item = this.sendNumberDataToTree(item[childrenKey])
        // }

        return {
          ...item,
          [childrenKey]: this.sendNumberDataToTree(item[childrenKey] || [], this.countNmber, this.preCountNumber),
          __number_count: number,
          __pre_number_count: preNumber
        };
      })
    },

    /**
     * @description: 通过页面配置，找到哪一个是查询叶子节点数量的视图组件
     * @param {Array} data 视图组件列表的部分
     */
    findTargetView(data = null) {
      if (data === null) {
        data = JSON.parse(JSON.stringify(this.componentData));
      } else {
        data = JSON.parse(JSON.stringify(data));
      }
      for (let i = data.length - 1; i >= 0; i--) {
        const element = data[i];
        // if (element.children === undefined || !element.children) {
        //   continue;
        // }
        if (element.children === undefined || !element.children || element.children.length === 0) {

          if (element.component.indexOf('TableContainer') > -1) {
            element.database?.paramsConfig.forEach(item => {
              if (item.componentId == this.element.id) {
                this.viewComponent = {
                  view_uuid: element.database?.viewData?.uuid,
                  key: item.key,
                  paramKey: item.paramKey
                };
              }
            });
            // this.viewComponent.push(element);
          }
        } else {
          this.findTargetView(element.children);
        }
      }
    },
    /**
     * @description: 找到树形数据中所有的参数相关数据
     * @param {*} data 查询的数据
     */
    findAllParamsKey(data = null) {
      const childrenKey = this.propObj.children;
      if (data === null) {
        data = JSON.parse(JSON.stringify(this.treeData));
      } else {
        data = JSON.parse(JSON.stringify(data));
      }
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element[childrenKey] === undefined || !element[childrenKey] || element[childrenKey].length === 0) {
          this.viewParams.push(element[this.viewComponent.paramKey]);
        } else {
          this.viewParams.push(element[this.viewComponent.paramKey]);
          this.findAllParamsKey(element[childrenKey]);
        }
      }
    },

    /**
     * @description: 清空数字缓存
     */
    clearNumberCache() {
      this.numberCount = 0;
      this.countDataList = [];
      this.preCountNumber = 0;
      this.preCountDataList = [];
    },

    /**
     * @description: 初始化属性数据的count数据
     * @param {Boolean} rebuild 是否需要重建数字数据
     */
    initNumberCount(rebuild = false) {
      // get id uuid 
      let idUuid = this.element.database.fieldList.filter(item => item.name == 'id')?.[0]?.uuid;
      // console.log(this.element.countNumberShow);
      if (rebuild) {
        this.clearNumberCache()
      }
      if (!this.element.countNumberShow || +this.element.countNumberConfig?.numberDataType === 4) {
        return;
      }
      if (this.gettingCountLoading || this.checkIfAutoFindView() === false) {
        return;
      }
      if (this.element.countNumberShow && !this.element.countNumberConfig?.showPreNumber && this.countDataList.length) {
        return;
      }
      if (this.element.countNumberShow && this.countDataList.length
        && this.element.countNumberConfig?.showPreNumber && this.preCountDataList.length) {
        return
      }
      // this.countNmber = 0;
      // this.countDataList = [];
      this.viewParams = []

      // 自动查找类型
      if (this.element.countNumberConfig?.numberDataType == 1) {
        this.findTargetView();
      }

      // 指定组件类型
      if (this.element.countNumberConfig?.numberDataType == 2) {
        // console.log(this.element.countNumberConfig?.numberDataComponent,'countNumberConfig')
        if(!this.element.countNumberConfig?.numberDataComponent) {
          return 
        }
        let component = getComponentById(this.componentData, this.element.countNumberConfig?.numberDataComponent);
        console.log(component,'component')
        this.viewComponent = {
          view_uuid: component.database?.viewData?.uuid,
          paramKey: idUuid,
          key: this.element.countNumberConfig?.idToParam
        };
      }

      // 指定视图类型
      if (this.element.countNumberConfig?.numberDataType == 3) {
        this.viewComponent = {
          view_uuid: this.element.countNumberConfig?.forceViewUuid,
          paramKey: idUuid,
          key: this.element.countNumberConfig?.idToParam
        };
      }

      if (!this.viewComponent || !this.viewComponent.view_uuid) {
        console.log("尚未找到有效视图")
        return
      }

      // 开始请求数据，上方都是准备 viewComponent 数据
      this.findAllParamsKey();
      if (this.viewParams.length == 0) {
        return
      }
      this.gettingCountLoading = true;

      let params = this.generateNumberData()

      // 请求后置数字
      dataInterface(params, '/api/mapi').then(res => {
        this.gettingCountLoading = false
        if (res.data.code == 200) {
          res = res.data.data;
          this.countDataList = res;
          // this.sendNumberDataToTree()
        }
      }).catch(() => this.gettingCountLoading = false)

      // 请求前置数字
      if (this.element?.countNumberConfig?.showPreNumber && !this.preCountDataList.length) {
        // params.payload = {...params.payload,"__show_pre__":1}
        // 请求一个全局取值类
        params = this.generateNumberData(true)
        this.gettingCountLoading = true;
        dataInterface(params, '/api/mapi').then(res => {
          this.gettingCountLoading = false
          if (res.data.code == 200) {
            res = res.data.data;
            this.preCountDataList = res;
            // this.sendNumberDataToTree()
          }
        }).catch(() => this.gettingCountLoading = false)
      }

    },

    /**
     * @description: 生成请求数据
     * @param {Boolean} needPreNumber
     * @return {Object} 全局moreAction请求数据
     */
    generateNumberData(needPreNumber = false) {
      let data = [];

      //todo 除视图类型外，其他的都需要把对方组件本身的非search条件全部带过来
      
      this.viewParams.forEach(item => {
        let tmp = {
          __method_name__: 'globalFunctionCall',
          funcName: "ViewAggregate",
          payload: {
            view_uuid: this.viewComponent.view_uuid,
            aggregate: "4",
            "_is_cache": false
          },
          typeName: "PublicFunc",
          type: "value"
        }
        if (needPreNumber) {
          tmp.__show_pre__ = 1
        }
        tmp[this.viewComponent.key] = item;
        data.push(tmp)
      })


      let params = {
        __method_name__: 'moreAction',
        data: data
      }

      if (needPreNumber) {
        params.__show_pre__ = 1
      }

      return params;
    }
  },
  mounted() {
    // 监听一个事件，强行刷新数字数据
    // EDITOR_xxx
    eventBus.$on('refresh_number_count' + this.element.id, () => {
      this.initNumberCount(true)
    })
  },
  beforeDestroy() {
    eventBus.$off('refresh_number_count' + this.element.id)
  }
}
