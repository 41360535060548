<!--
 * @Author: your name
 * @Date: 2022-04-21 11:59:27
 * @LastEditTime: 2022-04-29 14:05:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-position.vue
-->
<!--
    @name: widget-position
    @description：widget-position
    @author: ZengWei
    @date: 2022-03-25 09:40
-->
<template>
  <div>
    <SelectPosition
      v-model="element.value"
      :subId="element.config.__config__.cascade"
      :isCheckType="element.config.multiple"
      :subIdNecessary="element.config.__config__.cascadeOpen"
      :disabled="disabled || element.config.disabled"
      @change="positionChange"
      :maxCustomerWidth="maxCustomerWidth"
    />
  </div>
</template>

<script>
import SelectPosition from "@/custom-component/form/formParser/selectPosition/index";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetPosition",
  components: {SelectPosition},
  mixins: [formItemMinix],
  inject: ["parser"],
  props: ['element', 'disabled', 'displayData', 'maxCustomerWidth'],
  data() {
    return {}
  },
  created() {
    const casFormId = this.element.config?.__config__?.cascadeFormId
    if(casFormId){
      // 部位依赖筛选
      const cascade = this.parser.getFieldValue(casFormId)
      this.element.config.__config__.cascade = cascade
    }
  },
  methods: {
    positionChange(position){
			let data = JSON.parse(JSON.stringify(position))
      if(data instanceof Array){
        let modelArr = []
				let newArr = []
        if(data && data.length!=0){
          for(let i = 0; i < data.length; i++){
            if(data[i].correlation_model && data[i].correlation_model!=null){
							modelArr = modelArr.concat(data[i].correlation_model)
						}
          }
					modelArr.forEach((item)=>{
						if(newArr.length === 0){
							newArr.push(item)
						}else{
							let filterData = newArr.filter((v)=>v.model_id==item.model_id)
							if(filterData.length === 0){
								newArr.push(item)
							}else{
								const index = newArr.findIndex(el => el.model_id === item.model_id)
								newArr[index].dbids = newArr[index].dbids.concat(item.dbids)
								newArr[index].dbids  = Array.from(new Set(newArr[index].dbids))
							}
						}
					})
          this.$emit('trigger-active',{},'positionModel',newArr)
        }else{
					this.$emit('trigger-active',{},'positionModel',[])
				}
      }else{
				this.$emit('trigger-active',{},'positionModel',[])
			}
    },
  },
}
</script>

<style scoped>

</style>
