import { render, staticRenderFns } from "./ques-text.vue?vue&type=template&id=62c846f6&scoped=true"
import script from "./ques-text.vue?vue&type=script&lang=js"
export * from "./ques-text.vue?vue&type=script&lang=js"
import style0 from "./ques-text.vue?vue&type=style&index=0&id=62c846f6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c846f6",
  null
  
)

export default component.exports