<!--
 * @Author: zyf
 * @Date: 2024-08-06 11:34:10
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-22 18:28:28
 * @Descripttion: 
-->
<template>
  <div class="paper-container">
    <div class="skeleton" v-if="skeleton">
      <Skeleton></Skeleton>
    </div>
    <template v-else>
			<AnswerPage 
			v-if="paperInfo && Object.keys(paperInfo).length"
			:element="element" 
			:paperInfo="paperInfo"
			:isGroup="isGroup"
			:groupComponents="groupComponents"
			:cacheTimeStr="cacheTime"
			:cacheList="cacheList"
			></AnswerPage>
			<el-empty v-else description="请配置试卷数据" />
		</template>
  </div>
</template>
<script>
import AnswerPage from '@/components/answer/index.vue'
import { dataInterface } from '@/apis/data';
import { getComponentById,initParams } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';
import containerMixin from '@/custom-component/container/mixins';
import { Empty } from 'element-ui';
import Skeleton from '@/custom-component/form/quesParser/skeleton.vue'
import formValidate from "@/components/answer/quesParser/scripts/quesParserFormat.js"
export default {
	name: 'CommonAnswer',
  components: {
		'el-empty': Empty,
    AnswerPage,
    Skeleton
  },
  inject: ['EDITOR_pageUUID'],
  mixins: [containerMixin,databaseTriggerDebug],
	props: {
		element: {
      type: Object,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
     default: null
    }
	},
  data() {
    return {
      paperInfo: {},
      loading: true,
			// 配置数据
			metadata: [],
      skeleton:true,
			cacheTime: 0,
			cacheList: []
    }
  },
	computed: {
		isPaperLog() {
			return this.element?.statusConfig?.isPaperLog || false;
		},
    database() {
			return this.element?.database || {};
		},
		safetyKeyConfig() {
      return this.element?.safetyKeyConfig || {};
		},
    exitType() {
      return this.paperInfo?.[this.safetyKeyConfig?.exitOperate] || 1;
		},
	},
  created(){
		this.initData();
  },
  mounted(){
    // 监听请求
		// 配置关联参数的容器才需要监听
		const databseTrigger = {
			[this.element.id]: (data) => {
				if (data.parentId && data.parentId !== this.element.id) return false;
				// 配置时候触发请求
				if (data.componentId === this.element.id && data.isInit) {
					this._startDataDebug();
					const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents, this.EDITOR_pageUUID);
					if (!canPost) {
						this._failDataDebug('参数必填校验未通过');
						return;
					}
					this.getList(this.element.database, search, param);
					return;
				}
				// 点击操作时候不更新数据
				if (data.noUpdate) return;
				const { paramsConfig } = this.element.database;
				if (!paramsConfig || !paramsConfig.length) return;
				let isTarget = false;
				paramsConfig.forEach((ele) => {
					if (ele.componentId === data.componentId) {
						isTarget = true;
					}
				});
				if (!isTarget && !data.isUpdate) return;
				this._startDataDebug();
				// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
				const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents, this.EDITOR_pageUUID);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				this.param = param;
				this.getList(this.element.database, search, param);
			}
		}
		eventBus.$on('databaseTrigger', databseTrigger[this.element.id]);
  },
  methods:{
		async getCacheList(searchKey){
      const params = {
        object_uuid: 'object66c3053d1ac38',
				view_uuid: 'view66c3058bd6365',
				__method_name__: 'dataList',
				transcode: 0,
        key: searchKey
      }
      const res = await dataInterface(params);
			if(res.data.code === 200){
				return res.data.data || [];
			}
			return [];
    },
    initData(){
      if (this.database && !this.hasComponentParam()) {
				this._initDataDebug();
				const { search = [], param = {}, canPost } = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.subComponentData,
					this.groupComponents,
					this.EDITOR_pageUUID
				);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				this.getList(this.database, search, param);
			}
    },
    /**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (database.mapping === 'interface' && database.interfaceConfig) return true;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.subComponentData, componentId);
      if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
      this.skeleton = true;
			this.loading = true;
			this.search = Array.isArray(search) ? search : [];
			if (!this.validDatabase(database)) {
				this._failDataDebug('请求配置错误');
        this.skeleton = false;
				this.loading = false;
				return;
			}
			// 外部参数
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			const paramsObj = {
				...params,
				...outParams,
				search
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			let url = '/api/mapi';
			if (mapping === 'interface') {
				configObj = {
					...paramsObj
				}
				if (database?.interfaceConfig?.url?.indexOf('http') === -1) {
					url = `/api${database?.interfaceConfig?.url}`;
				} else {
					url = database?.interfaceConfig?.url
				}
			} else if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj
				};
			}
			// 获取试卷数据
			dataInterface(configObj, url).then(async (res) => {
				if (res && res.status === 200) {
					let tableData = [];
					if (mapping === 'interface' || ['dataList', 'relationList'].includes(__method_name__)) {
						// 列表数据
						tableData = this.getListFromRes(res, true) || [];
					}
					// 字段列表
					if (!this.metadata || !this.metadata.length) {
						let metadata = {};
						if (mapping === 'interface') {
							// 接口
							if (Array.isArray(tableData)) {
								if (Object.prototype.toString.call(tableData?.[0]) === '[object Object]') {
									for (let key in tableData?.[0]) {
										metadata[key] = key;
									}
								} 
							}
						} else {
							metadata = res.data.metadata;
						}
						this.metadata = metadata;
					}
					this.cacheTime = 0;
          if(tableData.length){
            this.paperInfo = tableData[0];
						if(!this.isPaperLog){
							if(+this.paperInfo?.[this.safetyKeyConfig?.retest] === 1 && this.paperInfo?.[this.safetyKeyConfig?.answer] && Object.keys(this.paperInfo?.[this.safetyKeyConfig?.answer]).length){
							this.paperInfo[this.safetyKeyConfig?.answer] =	this.initAnswerData(this.paperInfo?.[this.safetyKeyConfig?.config]?.fields || []);
							}
							if(this.exitType === 1){
								const userInfo = this.$GetUserInfo();
								const searchKey = `${userInfo.phone}-${this.paperInfo?.id}`;
								this.cacheList = await this.getCacheList(searchKey);
								if(this.cacheList.length){
									this.paperInfo[this.safetyKeyConfig?.answer] = this.cacheList[0]?.value?.paperLog;
									this.cacheTime = this.cacheList[0]?.value?.currentTime;
								}
							}
						}
          }
					// 完全数据 暂时保存
					this.fullData = res.data.data;
					// 保存容器数据
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							containerData: this.paperInfo,
							fullData: this.fullData,
							metadata: this.metadata,
							database: {
								...this.element.database,
								fieldList: this.getFieldList(this.metadata)
							}
						},
						containerId: null,
						isModify: true,
						pageUUID: this.EDITOR_pageUUID
					});
				}
        this.skeleton = false;
				this.loading = false;
				this._successDataDebug({
					url,
					content: configObj,
					res
				});
			}).catch((err) => {
				console.log(err);
				this.loading = false;
        this.skeleton = false;
				this._errorDataDebug({
					url,
					content: configObj,
					err
				});
			});
		},
		initAnswerData(items){
			const data = formValidate(items);
			const list = [].concat(data);
			// 采集表单输入数据
			const formSubmitData = {};
			const scoreSubmitData = {};
			for (const ele of list) {
				if (ele.type === "form") {
					const fieldData = [];
					const scoreData = [];
					const equalField = ele?.config.__config__.addShow;
					if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
						for (let eleData of ele.value) {
							let itemField = {};
							let itemScore = {};
							for (let eleItem of eleData) {
								itemField[eleItem.field] = '';
								itemScore[eleItem.field] = 0;
							}
							fieldData.push(itemField);
							scoreData.push(itemField);
						}
						formSubmitData[ele.field] = fieldData;
						scoreSubmitData[ele.field] = scoreData;
					}
				} else if (ele.type === "row") {
					if (ele.value) {
						for (let eleItem of ele.value) {
							formSubmitData[eleItem.field] = '';
							scoreSubmitData[eleItem.field] = 0;
						}
					}
				}else if(['checkbox','upload'].includes(ele.type)){
            if (ele.field) {
							formSubmitData[ele.field] = [];
							scoreSubmitData[ele.field] = 0;
						}
        }else {
					if (ele.field) {
						formSubmitData[ele.field] = '';
						scoreSubmitData[ele.field] = 0;
					}
				}
			}
			if(this.outerFormElement){
				// 考试前外部表单数据
				formSubmitData[this.outerFormElement.field] = '';
				scoreSubmitData[this.outerFormElement.field] = 0;
			}

			if(this.outerAfterElement){
				// 考试后外部表单数据
				formSubmitData[this.outerAfterElement.field] = ''
				scoreSubmitData[this.outerAfterElement.field] = 0;
			}
			return {value:formSubmitData,score:scoreSubmitData}
		},
    /**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
    /**
		 * @desc: 获取字段列表
		 * @param {Object} obj metadata对象
		 */
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		}
  }
};
</script>

<style lang="less" scoped>
.paper-container{
  width: 100%;
  height: 100%;
}
</style>
