/*
 * @Description: 图表
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:56
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-01-02 10:13:17
 */
// 图表
const screenComs = {
  type: 'screen',
  title: '大屏组件',
  show: true,
  key: 2,
	order: 6,
  componentList: [
		{
			component: 'ScreenTabs',
			type: 'screenTabs',
			name: '大屏TAB',
			icon: 'icondaping-dptab',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				isActive: false,
				defaultText: 'tabs',
				activeText: 'tabs',
				defaultColor: '',
				activeColor: '#409eff',
				defaultImage: '',
				activeImage: '',
				objectFit: 'fill',
				fontSize: '16'
			},
			tabConfig: {
				showList: [],
				displayList: []
			},
			propValue: '',
			style: {
				height: 44
			},
		},
		{
			component: 'TabsMenu',
			type: 'tabsMenu',
			name: 'TAB菜单',
			icon: 'iconbiaodan-tabcd',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				size: 4,
				scrollSize: 4,
				preIconDefault: '',
				preIconActive: '',
				nextIconDefault: '',
				nextIconActive: '',
				iconSize: 32,
				useLabel: false
			},
			propValue: '',
			style: {
				height: 44,
				fontSize: 14,
				fontWeight: 500,
			},
			// 用于直接渲染的数据
			containerData: null,
			tabConfig: [], // 固定参数
		},
    {
			component: 'IconBtnMenu',
			type: 'iconBtnMenu',
			name: 'ICON按钮菜单',
			icon: 'iconanniu',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				size: 4,
				align: 'left',
				gap: 0,
				iconSize: 32
			},
			propValue: '',
			style: {
				height: 44
			},
			metadata: {
        'value': 'value'
      },
			// 用于直接渲染的数据
			containerData: null,
			iconBtnMenuConfig: {
				configList: [
					{
						isFirst: false,
						isShow: true,
						focused: false,
						type: "",
						defaultIcon: "",
						clickActiveIcon: "",
						dblclickActiveIcon: "",
						eventList: [],
						// 按钮文本
						label: "按钮文本",
						// 是否展开
						isToggle: false,
						isDefaultSelected: false,
						// 子集选中的个数
						childFocusCount: 0,
						// 单个按钮间距
						btnSpace: 0,
					}
				],
				textConfig: {
					defaultColor: '#fff',
					clickColor: '#fff',
					dblColor: '#fff',
					size: '14',
					fontWeight: '400',
					lineHeight: '20',
					isDefaultSelected: false
				},
				// 图片/icon类型
				valueType: 'img',
				// 单击互斥
				clickedValue: false,
				// 文本隐藏
				textHiding: false,
				// 按钮缩放
				iconScale: 1.2,
			},
			behaviors: [
				{
					label: '隐藏全部',
					value: 'hideAll',
					desc: '参数配置必填项click_id对应点击的按钮key值, data_id_1,data_id_2等非必填项对应需要隐藏的按钮key值'
				},
				{
					label: '显示全部',
					value: 'showAll',
					desc: '参数配置必填项click_id对应点击的按钮key值, data_id_1,data_id_2等非必填项对应需要显示的按钮key值'
				},
				{
					label: '显示单个',
					value: 'showSign',
					desc: '参数配置事件名为data_id,对应需要显示的按钮key值'
				},
				{
					label: '隐藏单个',
					value: 'hideSign',
					desc: '参数配置事件名分别是data_id,对应需要隐藏的按钮key值'
				}
			]
		},
    {
      name: '全屏按钮',
      component: 'FullScreenCom',
      type: 'functionCom',
      icon: 'iconbiaodan-qpan',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        fontSize: '16px',
        textAlign: 'center',
        lineHeight: '32px',
      },
      style: {
        height: 32
      }
    },
    {
      name: '轮播行',
      component: 'ScrollBlock',
      type: 'container',
      icon: 'iconbiaodan-lbh',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        loopScroll: true,
        rowNumber: 3,
        duration: 3,
        titleColor: '#666',
        titleFontSize: '16px',
        bodyBackGround: '#fff',
        bodyTransparent: true,
        bodyColor: '#666',
        backgroundImage: '',
        objectFit: 'cover'
      },
      style: {
        height: 200
      }
    },
		{
			component: 'CommonRandomText',
			type: 'text',
			name: '随机数模拟',
			icon: 'iconbiaodan-sjs',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '50',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				defaultVal: 50, // 默认值
				showUnit: true,
				useRandom: true,
				unit: '%', // 单位
				unitSize: 14,
				randomTime: 300,
				min: 0,
				max: 100,
				floatLength: 0
			},
			style: {
				height: 32,
				width: 120,
				rotate: 0,
				color: 'rgba(232, 209, 34, 1)',
				fontSize: 30,
				fontWeight: 500,
				lineHeight: 32,
			}
		},
		{
			component: 'BtnGroup',
			type: 'btnGroup',
			name: '按钮组',
			icon: 'iconanniu',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				layout: 'horizontal',
				size: 4,
				align: 'left',
				gap: 0,
				iconSize: 32
			},
			propValue: '',
			style: {
				height: 44
			},
			metadata: {
        'value': 'value'
      },
			// 用于直接渲染的数据
			containerData: null,
			btnGroupConfig: {
				configList: [
					{
						isFirst: false,
						isShow: true,
						focused: false,
						type: "",
						// 渲染图片
						renderImgUrl: "",
						// 渲染文字颜色
						renderTextColor: "",
						// 渲染svg
						renderSvg: "",
						defaultIcon: "",
						clickActiveIcon: "",
						dblclickActiveIcon: "",
						eventList: [],
						// 按钮文本
						label: "按钮文本",
						// 是否展开
						isToggle: false,
						isDefaultSelected: false,
						// 子集选中的个数
						childFocusCount: 0,
						// 单个按钮间距
						btnSpace: 0,
					}
				],
				textConfig: {
					defaultColor: '#fff',
					clickColor: '#fff',
					dblColor: '#fff',
					size: '14',
					fontWeight: '400',
					lineHeight: '20',
					isDefaultSelected: false
				},
				// 图片/icon类型
				valueType: 'img',
				// 单击互斥
				clickedValue: false,
				// 文本隐藏
				textHiding: false,
				// 按钮缩放
				iconScale: 1.2,
			},
			behaviors: [
				{
					label: '隐藏全部',
					value: 'hideAll',
					desc: '参数配置必填项click_id对应点击的按钮key固定值'
				},
				{
					label: '显示全部',
					value: 'showAll',
					desc: '参数配置必填项click_id对应点击的按钮key固定值'
				},
				{
					label: '显示单个',
					value: 'showSign',
					desc: '参数配置事件名为data_id,对应需要显示的按钮key固定值'
				},
				{
					label: '隐藏单个',
					value: 'hideSign',
					desc: '参数配置事件名分别是data_id,对应需要隐藏的按钮key固定值'
				}
			]
		}
  ]
};

export default screenComs;