<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-10-25 14:15:03
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-26 19:16:52
 * @FilePath: /dataview-viewer-test/src/custom-component/form/newParser/FormItem/widget-date.vue
 * @Description:
-->
<!--
    @name: widget-date
    @description：widget-date
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ getDate }}</div>
    <el-date-picker
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :range-separator="element.config['range-separator']"
      :format="element.config.format"
      :placeholder="element.config.placeholder"
      :value-format="element.config['value-format']"
      style="width: 100%"
    >
    </el-date-picker>
  </div>
</template>

<script>
import {DatePicker} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {dateFormat} from "@/custom-component/form/newParser/scripts/tools";

export default {
  name: "WidgetDate",
  components: {
    'el-date-picker': DatePicker,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    getDate() {
      const time = this.element.value;
      if (time) {
        const fmt = this.element.config?.format;
        return dateFormat(fmt, time)
      }
      return '-';
    }
  },
  methods: {
    /**
     * @desc: 根据日期获取一年中的第几周
     * @param {*} year 年
     * @param {*} month 月
     * @param {*} date 日
     */
    getYearWeek(year,month,date){
      const dateNow = new Date(year, parseInt(month) - 1, date);
      const dateFirst = new Date(year, 0, 1);
      const dataNumber = Math.round((dateNow.valueOf() - dateFirst.valueOf()) / 86400000);
      return Math.ceil((dataNumber + ((dateFirst.getDay() + 1) - 1)) / 7);
    }
  },
}
</script>

<style scoped>
</style>
