<!--
    @name: TaskPicker
    @description：TaskPicker
    @author: ZengWei
    @date: 2022-06-07 10:44
-->
<template>
  <div>
    <template v-if="selectStepList.length > 1 && !turn">
      <div class="type-label">选择任务流转分支</div>
      <el-form ref="form" label-width="80px">
        <el-form-item label="流转分支">
          <el-select
            v-model="selectStep"
            placeholder="请选择流转分支"
            style="width: 286px"
            @change="getSelectStep"
          >
            <el-option
              v-for="(item, index) in selectStepList"
              :key="index"
              :label="item.nodeType === 'end' ? '结束' : item.name"
              :value="item.nodeId"
            >
              {{ item.nodeType === 'end' ? '结束' : item.name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <template v-if="turn">
      <div class="type-label">选择转交人</div>
      <el-timeline style="margin-left: 15px">
        <el-timeline-item color="#0bbd87" :hollow="true" type="primary">
          <div style="display: flex; justify-content: space-between">
            <h4>
              <span style="color: red">*</span>
              请选择转交人
            </h4>
          </div>
          <div style="width: 100%">
            <select-user
              picker-node="turn"
              picker-type="check"
              position-type="left"
              :show-choose-button="true"
              @on-select-change="onSelectChange"
            />
          </div>
          <!-- <div>
            <el-tag v-for="(dd, nn) in userSelected('send', 'turn')" :key="nn">
              {{ dd.name }}
            </el-tag>
          </div> -->
        </el-timeline-item>
      </el-timeline>
    </template>
    <template v-else>
      <template v-if="checkStep.length > 0">
        <div class="type-label">选择下一任务责任人</div>
        <el-timeline style="margin-left: 15px">
          <el-timeline-item
            v-for="(item, i) in checkStep"
            :key="i"
            color="#0bbd87"
            :hollow="true"
            type="primary"
          >
            <div style="display: flex; justify-content: space-between">
              <h4>
                <span style="color: red">*</span>
                {{ item.name }}
              </h4>
            </div>
            <div style="width: 100%">
              <select-user
                :key="'check-'+item.nodeId + companyId(item)"
                :company-id="companyId(item)"
                :picker-node="item.nodeId"
                :show-choose-button="true"
                picker-type="check"
                position-type="left"
                @on-select-change="onSelectChange"
              />
            </div>
            <!-- <div :key="userSelected('check', item.nodeId).length">
              <el-tag
                v-for="(dd, nn) in userSelected('check', item.nodeId)"
                :key="nn"
              >
                {{ dd.name }}
              </el-tag>
            </div> -->
          </el-timeline-item>
        </el-timeline>
      </template>
    </template>
  </div>
</template>

<script>
  import selectUser from '@/custom-component/form/formParser/selectUser/index.vue'
  import {Form, FormItem, Timeline, TimelineItem, Select , Option} from 'element-ui'
  import {dataInterface} from "../../../../apis/data"; // Tag,

  export default {
    name: 'TaskPicker',
    components: {
      selectUser,
			'el-form': Form,
			'el-form-item': FormItem,
			'el-select': Select,
			'el-option': Option,
			'el-timeline': Timeline,
			'el-timeline-item': TimelineItem // 'el-tag': Tag,
		},
    props: {
      stepNode: {
        type: String,
        default: '',
      },
      taskEngineId: {
        type: [String, Number],
        default: 0,
      },
      taskRecoverId: {
        type: [String, Number],
        default: 0,
      },
      type: {
        type: String,
        default: 'self',
      },
      turn: {
        type: Boolean,
        default: false,
      },
      archId: {
        type: [String, Number],
        default: 0,
      },
      archTable: {
        type: String,
        default: '',
      },
      //流程中修改的数据
      flowEdit:{
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data(){
      return {
        checkStep: [],
        sendStep: [],
        checkedUsers: [],
        userModalType: 'check',
        step_node: '',
        checkPicker: [],
        sendPicker: [],
        depart: {},

        selectStepList: [],
        selectStep: '',

        targetTemp: {},
        sendTemp: {},
      }
    },
    computed:{
      userSelected(){
        return function(type, node) {
          if (type === 'check' && this.targetTemp[node]) {
            return this.targetTemp[node]
          }
          if (type === 'send' && this.sendTemp[node]) {
            return this.sendTemp[node]
          }
          return []
        }
      },
      companyId(){
        return function (node) {
          let companyId = 0;
          if (node.user_from === 3) {
            let archObj = JSON.parse(localStorage.getItem('targetArchi'))
            companyId = archObj.data.company_id || 0
          }else{
            companyId = 0
          }
          return companyId
        }
      }
    },
    methods:{
      getSelectStep(value) {
        let selectStep = this.selectStepList.filter((item) => item.nodeId == value) || []
        if (selectStep instanceof Array) {
          if (selectStep[0].user_type && selectStep[0].user_type === 7) {
            this.checkStep = selectStep
          } else {
            this.checkStep = []
          }
        }
        this.$emit('selected-step', value)
      },
      onSelectChange(users, param) {
        let userData = []
        for (let item of users) {
          let user = {
            id: item.user_id,
            name: item.name,
            user_table: item.user_table,
            section_id: item.section_id
          }
          userData.push(user)
        }
        let { pickerNode, pickerType } = param
        if (pickerType === 'check' && userData.length >= 0) {
          this.targetTemp[pickerNode] = userData
        } else if (pickerType === 'send' && userData.length >= 0) {
          this.sendTemp[pickerNode] = userData
        }
        let selectedCheck = [],
          selectedSend = []
        for (let key in this.targetTemp) {
          if(this.targetTemp[key].length){
            let targetUser = { nodeId: key, nodeUser: this.targetTemp[key] }
            selectedCheck.push(targetUser)
          }
        }
        for (let key in this.sendTemp) {
          let sendUser = { nodeId: key, nodeUser: this.sendTemp[key] }
          selectedSend.push(sendUser)
        }
        this.checkPicker = selectedCheck
        this.sendPicker = selectedSend
        let checkedUsers = {
          target_users: this.checkPicker,
          send_users: this.sendPicker,
        }
        let number = -1
        if (this.turn) number = 1 //转交
        if (this.checkPicker.length > 0) number = this.checkPicker.length
        this.$emit('checked-users', checkedUsers, number)
      },
      getFlowPicker() {
        if (this.type === 'self') {
          let params = { task_engine_id: this.taskEngineId }
          if(this.archId && this.archTable){
            params.archi_id = this.archId
            params.archi_table = this.archTable
          }
          if (Object.keys(this.flowEdit).length) params.editFields = this.flowEdit
          dataInterface(params,'/api/taskengine/selfpicker').then(res=>{
            if (res.data.code === 200) {
              let allNode = res.data.data;
              this.checkStep = allNode.target_picker || []
              this.sendStep = allNode.send_picker || []
              this.selectStepList = allNode.select_step || []
              let checkedUsers = {
                target_users: [],
                send_users: [],
              }
              this.$emit('checked-users', checkedUsers, this.checkStep.length)
            }
          })
        } else {
          let params = { task_recover_id: this.taskRecoverId, step_node: this.stepNode }
          if (Object.keys(this.flowEdit).length) params.editFields = this.flowEdit
          dataInterface(params,'/api/taskengine/nextpicker').then(res=>{
            if (res.data.code === 200) {
              let allNode = res.data.data
              this.checkStep = allNode.target_picker
              this.sendStep = allNode.send_picker
              this.selectStepList = allNode.select_step || []
              let number = this.checkStep.length
              if (this.turn) number = 1
              let checkedUsers = {
                target_users: [],
                send_users: [],
              }
              this.$emit('checked-users', checkedUsers, number)
            }
          })
        }
      },
    },
    created () {
      this.getFlowPicker()
    },
  }
</script>

<style scoped>
  .type-label {
    border-left: 3px solid var(--themeColor) !important;
    padding: 0 10px;
    line-height: 1;
    font-size: 14px;
    margin: 0 0 16px;
    font-weight: 600;
    margin-top: 20px !important;
  }
</style>
