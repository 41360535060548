<!--
 * @Author: hw
 * @Date: 2024-01-16 09:48:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-06-28 16:44:59
 * @FilePath: /dataview-viewer-test/src/custom-component/voiceAssistant/VoiceAssistant.vue
-->
<template>
    <article class="voice-assistant">
        <!-- <transition name="voice-fade">
            <section v-show="!activated" class="main default">
                <img
                    src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/67c459051b221f94f18a903d7530e179.png"
                    alt=""
                />
                <svg
                    class="purple"
                    xmlns="http://www.w3.org/2000/svg"
                    width="112"
                    height="113"
                    viewBox="0 0 112 113"
                    fill="none"
                >
                    <circle
                        cx="55.965"
                        cy="56.5661"
                        r="55.7723"
                        transform="rotate(-16.311 55.965 56.5661)"
                        fill="url(#paint0_radial_5222_8605)"
                        fill-opacity="0.3"
                    />
                    <defs>
                        <radialGradient
                            id="paint0_radial_5222_8605"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(119.17 87.5588) rotate(-158.097) scale(123.352)"
                        >
                            <stop offset="0.0604044" stop-color="#0276FF" />
                            <stop
                                offset="0.494792"
                                stop-color="#0276FF"
                                stop-opacity="0.12"
                            />
                            <stop
                                offset="1"
                                stop-color="#0276FF"
                                stop-opacity="0"
                            />
                        </radialGradient>
                    </defs>
                </svg>
                <svg
                    class="blue"
                    xmlns="http://www.w3.org/2000/svg"
                    width="112"
                    height="112"
                    viewBox="0 0 112 112"
                    fill="none"
                >
                    <circle
                        cx="56.2154"
                        cy="56.0025"
                        r="55.7723"
                        transform="rotate(-89.1082 56.2154 56.0025)"
                        fill="url(#paint0_radial_5222_8606)"
                        fill-opacity="0.2"
                    />
                    <defs>
                        <radialGradient
                            id="paint0_radial_5222_8606"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(25.3512 8.63919) rotate(48.0864) scale(59.0858)"
                        >
                            <stop offset="0.0604044" stop-color="#4BE7FF" />
                            <stop
                                offset="0.644309"
                                stop-color="#38E4FF"
                                stop-opacity="0.31"
                            />
                            <stop
                                offset="1"
                                stop-color="#30E3FF"
                                stop-opacity="0"
                            />
                        </radialGradient>
                    </defs>
                </svg>
                <svg
                    class="group"
                    xmlns="http://www.w3.org/2000/svg"
                    width="165"
                    height="164"
                    viewBox="0 0 165 164"
                    fill="none"
                >
                    <g filter="url(#filter0_d_5222_8607)">
                        <circle
                            cx="82.8363"
                            cy="81.7888"
                            r="55.7723"
                            transform="rotate(24.7503 82.8363 81.7888)"
                            fill="url(#paint0_radial_5222_8607)"
                            fill-opacity="0.3"
                        />
                        <circle
                            cx="82.7493"
                            cy="82.0355"
                            r="55.7723"
                            transform="rotate(-16.311 82.7493 82.0355)"
                            fill="url(#paint1_radial_5222_8607)"
                            fill-opacity="0.3"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_5222_8607"
                            x="0.962463"
                            y="0.00305176"
                            width="163.66"
                            height="163.819"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="13" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.1125 0 0 0 0 0.2545 0 0 0 0 1 0 0 0 0.5 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_5222_8607"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_5222_8607"
                                result="shape"
                            />
                        </filter>
                        <radialGradient
                            id="paint0_radial_5222_8607"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(50.5589 31.9142) rotate(59.5306) scale(82.6058)"
                        >
                            <stop stop-color="#3B41D0" />
                            <stop
                                offset="0.48985"
                                stop-color="#3B41D0"
                                stop-opacity="0.3"
                            />
                            <stop
                                offset="1"
                                stop-color="#3B41D0"
                                stop-opacity="0"
                            />
                        </radialGradient>
                        <radialGradient
                            id="paint1_radial_5222_8607"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(145.955 113.028) rotate(-158.097) scale(123.352)"
                        >
                            <stop offset="0.0604044" stop-color="#0276FF" />
                            <stop
                                offset="0.494792"
                                stop-color="#0276FF"
                                stop-opacity="0.12"
                            />
                            <stop
                                offset="1"
                                stop-color="#0276FF"
                                stop-opacity="0"
                            />
                        </radialGradient>
                    </defs>
                </svg>
            </section>
        </transition>
        <transition name="voice-fade">
            <section v-show="activated" class="main activated">
                <div v-if="exactMatch" class="chat-content">
                    <span class="title">小椽正在为您：</span>
                    <span class="word">{{ exactMatch }}</span>
                </div>

                <div
                    v-else-if="matchedArr && matchedArr.length"
                    class="chat-content"
                >
                    <span class="title">为您找到以下选项：</span>
                    <span
                        v-for="(msg, index) in matchedArr"
                        :key="index"
                        class="word"
                    >
                        {{ index + 1 }},{{ msg.content }}
                    </span>
                    <span class="word" style="text-align: center">...</span>
                </div>

                <div v-else-if="notFind" class="chat-content">
                    <span class="title">暂未找到相关内容，请重新描述！</span>
                </div>

                <div v-else-if="failed" class="chat-content">
                    <span class="title">操作失败！</span>
                </div>

                <div v-else class="chat-content">
                    <span class="title">试试对我说</span>
                    <span class="word">"打开进度管理"</span>
                </div>

                <div class="control">
                    <div class="voice-icon">
                        <img
                            v-if="!dirsDone"
                            class="wave"
                            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/52c24395662066c74840bfddeb7ce6cc.gif"
                            alt="cover"
                        />
                        <img
                            class="bg-img"
                            :src="
                                dirsDone
                                    ? 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/67c459051b221f94f18a903d7530e179.png'
                                    : 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/db706d5d7a639b47c684754d232a6446.png'
                            "
                            alt="cover"
                        />
                        <svg
                            class="purple"
                            xmlns="http://www.w3.org/2000/svg"
                            width="112"
                            height="113"
                            viewBox="0 0 112 113"
                            fill="none"
                        >
                            <circle
                                cx="55.965"
                                cy="56.5661"
                                r="55.7723"
                                transform="rotate(-16.311 55.965 56.5661)"
                                fill="url(#paint0_radial_5222_8605)"
                                fill-opacity="0.3"
                            />
                            <defs>
                                <radialGradient
                                    id="paint0_radial_5222_8605"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(119.17 87.5588) rotate(-158.097) scale(123.352)"
                                >
                                    <stop
                                        offset="0.0604044"
                                        stop-color="#0276FF"
                                    />
                                    <stop
                                        offset="0.494792"
                                        stop-color="#0276FF"
                                        stop-opacity="0.12"
                                    />
                                    <stop
                                        offset="1"
                                        stop-color="#0276FF"
                                        stop-opacity="0"
                                    />
                                </radialGradient>
                            </defs>
                        </svg>
                        <svg
                            class="blue"
                            xmlns="http://www.w3.org/2000/svg"
                            width="112"
                            height="112"
                            viewBox="0 0 112 112"
                            fill="none"
                        >
                            <circle
                                cx="56.2154"
                                cy="56.0025"
                                r="55.7723"
                                transform="rotate(-89.1082 56.2154 56.0025)"
                                fill="url(#paint0_radial_5222_8606)"
                                fill-opacity="0.2"
                            />
                            <defs>
                                <radialGradient
                                    id="paint0_radial_5222_8606"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(25.3512 8.63919) rotate(48.0864) scale(59.0858)"
                                >
                                    <stop
                                        offset="0.0604044"
                                        stop-color="#4BE7FF"
                                    />
                                    <stop
                                        offset="0.644309"
                                        stop-color="#38E4FF"
                                        stop-opacity="0.31"
                                    />
                                    <stop
                                        offset="1"
                                        stop-color="#30E3FF"
                                        stop-opacity="0"
                                    />
                                </radialGradient>
                            </defs>
                        </svg>
                        <svg
                            class="group"
                            xmlns="http://www.w3.org/2000/svg"
                            width="165"
                            height="164"
                            viewBox="0 0 165 164"
                            fill="none"
                        >
                            <g filter="url(#filter0_d_5222_8607)">
                                <circle
                                    cx="82.8363"
                                    cy="81.7888"
                                    r="55.7723"
                                    transform="rotate(24.7503 82.8363 81.7888)"
                                    fill="url(#paint0_radial_5222_8607)"
                                    fill-opacity="0.3"
                                />
                                <circle
                                    cx="82.7493"
                                    cy="82.0355"
                                    r="55.7723"
                                    transform="rotate(-16.311 82.7493 82.0355)"
                                    fill="url(#paint1_radial_5222_8607)"
                                    fill-opacity="0.3"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_5222_8607"
                                    x="0.962463"
                                    y="0.00305176"
                                    width="163.66"
                                    height="163.819"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="13" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0.1125 0 0 0 0 0.2545 0 0 0 0 1 0 0 0 0.5 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_5222_8607"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_5222_8607"
                                        result="shape"
                                    />
                                </filter>
                                <radialGradient
                                    id="paint0_radial_5222_8607"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(50.5589 31.9142) rotate(59.5306) scale(82.6058)"
                                >
                                    <stop stop-color="#3B41D0" />
                                    <stop
                                        offset="0.48985"
                                        stop-color="#3B41D0"
                                        stop-opacity="0.3"
                                    />
                                    <stop
                                        offset="1"
                                        stop-color="#3B41D0"
                                        stop-opacity="0"
                                    />
                                </radialGradient>
                                <radialGradient
                                    id="paint1_radial_5222_8607"
                                    cx="0"
                                    cy="0"
                                    r="1"
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(145.955 113.028) rotate(-158.097) scale(123.352)"
                                >
                                    <stop
                                        offset="0.0604044"
                                        stop-color="#0276FF"
                                    />
                                    <stop
                                        offset="0.494792"
                                        stop-color="#0276FF"
                                        stop-opacity="0.12"
                                    />
                                    <stop
                                        offset="1"
                                        stop-color="#0276FF"
                                        stop-opacity="0"
                                    />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                    <div v-if="!dirsDone" class="control-text">
                        <span>小椽正在听</span>
                    </div>
                </div>
            </section>
        </transition> -->
    </article>
</template>

<script>
import DirectiveControl from './directiveControl'
import { dataInterface } from '@/apis/data/index';
import { HZRecorder } from '@/utils/HZRecorder.js';
import { mapState } from "vuex";
import axios from 'axios';
import { getComponentById,getLinkByTemplate,generateQuery,doEEActionHandle,judgingEventLogic, getStatusVal } from '@/utils/tools';
export default {

  name: "VoiceAssistant",
  props: {
    // 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		isGroup: {
			type: Boolean,
		},
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    },
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      dirs:null,
			recorder:null,
			audioObj:null,
			timer:null,
			activated:false,
			recordStartFlag:true,	
			// 精准匹配到的内容
			exactMatch : '',
			// 模糊匹配到的内容
			matchedArr : [],
			// 未能找到
			notFind : false,
			// 操作失败
			failed:false,
			// 指令回调完成
			dirsDone:false,
			//指令数据，取自用户配置的语音控制数据
			orderList:[],
            pageBtnData:[],
            firstTipsDone:false
    };
  },
  computed: {
    ...mapState([
        'componentData', // 组件数据
        'originComponentData' // 大屏容器组件的元组件
    ]),
  },
  mounted() {
    this.setPageBtnFunction()
    this.dirs = new DirectiveControl();
    if (
        navigator.mediaDevices.getUserMedia ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia
    ) {
        this.getUserMedia({ video: false, audio: true }); // 调用用户媒体设备，访问摄像头、录音
    } else {
        console.log('你的浏览器不支持访问用户媒体设备');
        this.$message.error('你的浏览器不支持访问用户媒体设备！');
    }
    /* 语音识别实验版，切换大屏tab */
    // 监听键盘按下事件
    window.addEventListener('keydown', this.keydownFun);
    window.addEventListener('keyup', this.keyUpFun);
  },
  methods: {
        async setPageBtnFunction (){
            await this.findBtnData(this.componentData || this.originComponentData,[]);
            window.voiceFunctionObj = {
                setPageBtnFunction:this.setPageBtnFunction,
                doFunction:this.doFunction,
                pageBtnData:this.pageBtnData,
            }
        },
        //查找页面可点击数据
        findBtnData(data, name = []){
            if(data?.length){
                for(let i = 0; i<data.length;i++){
                    const item = data[i]
                    let labelArr = name.concat(item.name);
                    //labelArr方便AI查找哪一个点击事件
                    const {displayShow,isShow} = item.statusConfig
                    if(displayShow && isShow){
                        //按钮组件
                        if(item.component === 'IconBtnMenu' && item.iconBtnMenuConfig?.configList?.length){
                            const newArr = item.iconBtnMenuConfig.configList.map(val=>{
                                return this.formatBtnMenuBehaviorsData({
                                    componentId:item.id,
                                    label:val.label,
                                    value:val.type,
                                    labelArr:[...labelArr,val.label],
                                })
                            })
                            this.pageBtnData = this.pageBtnData.concat(newArr)
                        }
                        //组件本身绑定了事件
                        if(item.events?.click){
                            this.pageBtnData.push({
                                ...item.events?.click,
                                labelArr:labelArr,
                                label:item.name,
                                componentId:item.id,
                                isBtnMenu:false
                            })
                        } 
                        //组件内事件暂不处理后续处理
                        // if(item.behaviors?.length){
                        //     console.log(item,'item5555555555555')
                        // }
                    }
                    if(item.children?.length){
                        if(item.id.includes('ConsoleSignCont')){
                            //标记容器内未显示标记不录入每个标记的事件
                            const signEle = document.getElementsByClassName('sign-item');
                            const aggreEle = document.getElementsByClassName('aggregation-item')
                            if(signEle.length && aggreEle.length){
                                this.findBtnData(item.children,labelArr);
                            }
                        } else {
                            this.findBtnData(item.children,labelArr);
                        }
                    }
                }
            }
        },
        // 图标按钮组件转换格式
        formatBtnMenuBehaviorsData(obj){
            return {
                "component": obj.componentId,
                "labelArr":obj.labelArr || [],
                "label":obj.label,
                "isBtnMenu":true,
                "list": [
                    {
                        "behaviors": [
                            "changeTab"
                        ],
                        "params": [
                            {
                                "componentId": "",
                                "sourceType": "fixed",
                                "urlParamsKey": "",
                                "isRequired": false,
                                "key": "name",
                                "comValueType": "",
                                "paramKey": "",
                                "fixedValue": obj.label,
                                "statusCode": ""
                            },
                            {
                                "componentId": "",
                                "sourceType": "fixed",
                                "urlParamsKey": "",
                                "isRequired": false,
                                "key": "click_id",
                                "comValueType": "",
                                "paramKey": "",
                                "fixedValue":obj.value,
                                "statusCode": ""
                            }
                        ]
                    }
                ]
            }

        },
		/**
		 * @description: 键盘抬起
		 * @param {*} event
		 * @return {*}
		 */
		keydownFun  (event)  {
			const keyCode = event.keyCode || event.which;
			console.log('keyCode::', +keyCode);
			this.notFind = false;
			this.failed = false;
			this.exactMatch = '';

			if (+keyCode === 32) {
                this.findBtnData()
				this.activated = true;
				this.dirsDone = false;
				// 开始录音
				if (this.recordStartFlag) {
					this.$message.info('正在识别语音。。。');
					this.recordStartFlag = false;
					this.recorder.start();
				}

				// 停止语音播报
				if (this.audioObj) {
					this.audioObj.pause();
				}
			}
		},
		keyUpFun (event) {
			const keyCode = event.keyCode || event.which;
			console.log(keyCode,'keyCode');
			if (+keyCode === 32) {
				if (!this.recordStartFlag) {
					this.recordStartFlag = true;
					// 结束录音识别
					this.recorder.stop();
					let audioData = new FormData();

					const newBolb = this.recorder.getBlob();
					const fileOfBlob = new File([newBolb], new Date().getTime() + '.wav', {
						type: 'audio/wav'
					});

					
					this.recorder.destory();
					// 重新初始化
					this.recorder = null;
					this.getUserMedia();

					audioData.append('file', fileOfBlob);

					dataInterface(audioData, 'api/repository/asrChar', 'POST').then((res) => {
						if (res && res.data.code === 200 && res.data.data) {
							const msg = res.data.data;
							this.notFind = false;
							this.failed = false;
                            this.pageBtnData = []
                            this.findBtnData(this.componentData || this.originComponentData,[])
                            let functionName = ''
                            for(let i = 0;i< this.pageBtnData.length;i++){
                                const newArr = functionName.split(',') || []
                                if(!newArr.includes(this.pageBtnData[i].label)){
                                    functionName += this.pageBtnData[i].label+','
                                }
                            }
							if (this.pageBtnData?.length) {
								const findOrder = this.pageBtnData.find(v=>v.labelArr.includes(msg) || msg.includes(v.label))
								if(findOrder){
									this.doFunction(findOrder,msg)
								} else {
                                    // const tipsStr = `该页面中有可直接操作的功能如下：${functionName}等功能，请重新描述！`
                                    // if(!this.firstTipsDone){
                                    //     //第一次为找到时语音播报功能列表
                                    //     this.handleNotFind(tipsStr)
                                    //     this.firstTipsDone = true
                                    // } else {
                                        //其余的借助AI分析
                                        dataInterface({funcs:functionName,say:msg}, 'api/graph/737').then((re) => {
                                            if(res.data.code === 200){
                                                const compareData = functionName.split(',') 
                                                if(compareData.includes(re.data.data)){
                                                    const AIFiterOrder = this.pageBtnData.find(v=>v.labelArr.includes(re.data.data) || re.data.data.includes(v.label))
                                                    if(AIFiterOrder){
                                                        this.doFunction(AIFiterOrder,`打开${re.data.data}`)
                                                    } else {
                                                        this.handleNotFind('暂未找到相关内容，请重新描述！');
                                                    }
                                                } else {
                                                    this.handleNotFind(res.data.data);  
                                                }
                                            }
                                        })
                                    // }
								}
							} else {
								// 未找到
								this.handleNotFind('该页面无直接可操作的功能！');   
							}
						} else {
							this.handleNotFind('暂未找到相关内容，请重新描述！');
							// 语音转义失败
							this.closeDialog(500);
						}
					}).catch(err => {
						this.closeDialog();
						console.log(err, 'err--err');
					})
				} else {
					this.$message.info('录音时间太短！');
				}
			}
		},
        //调用事件公共方法
        doFunction(findOrder,msg){
            if(msg){
                this.handleExactMatch(msg, `小椽已为您：${msg}`);
            }
            if(findOrder.isBtnMenu){
                this.$store.commit('triggerEvents', {
                    config: {
                        behavior:findOrder,
                        isBehavior: true
                    },
                    element:this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID
                });
            } else {
                let component = getComponentById(this.componentData, findOrder.componentId);
                if(component){
                    this.triggerEvent(component,findOrder)
                }
                
            }
        },
        /**
         * @description: 触发事件
         */
        async triggerEvent(item, click, eventType = 'click') {
            const {
                specialEventList = [],
                pattern,
            } = click || {};
            if (!pattern) {
                // 普通事件
                this.triggerEventItem(click, item, eventType);
            } else if (pattern === 'special') {
                specialEventList.forEach((ele) => {
                    const { actionType } = ele;
                    if(actionType === 'pageStatus'){
                        this.updatePageStatus(ele.stateMachine || [])
                    }else	if (actionType === 'pageAction') {
                        this.triggerEventItem(ele, item, eventType);
                    } else if(actionType === 'componentBehavior'){
                        //触发组件行为
                        setTimeout(()=>{
                            this.triggerEventItem(ele, item, eventType);
                        },0)
                    }
                });
            }
        },
        /**
         * @description: 触发单个事件
         * @return {*}
         */
        triggerEventItem(ele, item, eventType = 'click') {
            const {
                actionType,
                effects = [],
                behaviors,
                linkType,
                queryList = [],
                linkUrl = '',
                pageId,
                linkTemplate,
                stateMachine
            } = ele || {};
            if (actionType === 'eeAction') {
                // 触发后端事件
                window.$EditorDebug.startEEAction(item);
                const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
                if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
                this.$message.error('事件配置错误！');
                window.$EditorDebug.failEEAction(item);
                return false;
                }
                this.loading = true;
                if (this.statusConfig.globalLoading) {
                this.triggerGlobalLoading(true)
                }
                const sourceParams = this.getBindParams(item);
                // 蓝图
                if (eeType === 'blueprint') {
                dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
                    if (res.status === 200 && res.data.code === 200) {
                        this.$message.success('操作成功！');
                        window.$EditorDebug.successEEAction(item, `/api${interfaceUrl}`);
                        doEEActionHandle(res.data?.__adds__);
                    }
                    this.loading = false;
                    if (this.statusConfig.globalLoading) {
                        this.triggerGlobalLoading(false)
                    }
                }).catch(() => {
                    window.$EditorDebug.errorEEAction(item, `/api${interfaceUrl}`);
                    this.loading = false;
                    if (this.statusConfig.globalLoading) {
                        this.triggerGlobalLoading(false)
                    }
                })
                return true;
                }
                // 数仓
                const data_id = sourceParams?.data_id;
                dataInterface({
                __method_name__: 'customEventCall',
                object_uuid: objectUUID,
                view_uuid: viewUUID,
                ...sourceParams,
                data_id, // 参数配置
                event: eventName
                })
                .then(res => {
                    if (res.status === 200 && res.data.code === 200) {
                    this.$message.success('操作成功！');
                    window.$EditorDebug.successEEAction(item, eventName);
                    doEEActionHandle(res.data?.__adds__);
                    }
                    this.loading = false;
                    if (this.statusConfig.globalLoading) {
                    this.triggerGlobalLoading(false)
                    }
                })
                .catch(() => {
                    this.loading = false;
                    if (this.statusConfig.globalLoading) {
                    this.triggerGlobalLoading(false)
                    }
                    window.$EditorDebug.errorEEAction(item, eventName);
                });
                return true;
            } else if (actionType === 'jumpPage') {
                const query = {};
                queryList.forEach(queryItem => {
                let component = getComponentById(this.componentData, queryItem.componentId);
                this.$set(query, queryItem.key, component?.resolveData?.[queryItem.feild]);
                });
                const queryStr = generateQuery(query);
                if (linkType === 'projectPage') {
                window.$EditorDebug.jumpPage(1, pageId, query);
                this.$router.push({
                    path: pageId,
                    query
                });
                return;
                } else if (linkType === 'outPage') {
                if (window.EDITORUseDebug) {
                    window.$EditorDebug.jumpPage(2, linkUrl);
                }
                window.open(linkUrl);
                } else if (linkType === 'custom') {
                // if (!this.mapData?.renderData) return;
                const customLink = getLinkByTemplate(linkTemplate, this.mapData?.renderData || {})
                window.$EditorDebug.jumpPage(3, `${customLink}${queryStr}`);
                window.open(`${customLink}${queryStr}`);
                }
            } else if (actionType === 'export') {
                // 导出
                window.$EditorDebug.baseEvent(`<p>开始执行<i>全局导出事件</i></p>`);
                        this.onExport(item?.events?.[eventType]);
                    } else if (actionType === 'pageStatus') {
                // 修改页面状态
                this.updatePageStatus(stateMachine || [], item);
                    } else {
                // 页面事件
                effects.forEach((effect) => {
                this.$store.commit('triggerEvents', {
                    config: {
                    ...ele,
                    ...effect
                    },
                    element: this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID
                });
                });
            }
            // 行为
            if (behaviors && Array.isArray(behaviors)) {
                if (behaviors.length === 1) {
                const { component, list } = behaviors[0];
                if (!component) {
                    return;
                }
                if (list.length === 1) {
                    const behaviorsList = list[0].behaviors;
                    if (!behaviorsList.length) {
                    return;
                    }
                }
                }
                behaviors.forEach(behavior => {
                if (behavior.component) {
                    this.$store.commit('triggerEvents', {
                    config: {
                        behavior,
                        isBehavior: true
                    },
                    element: item,
                    EDITOR_pageUUID: this.EDITOR_pageUUID
                    });
                }
                });
            }
        },
        /**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr = [], element) {
            window.$EditorDebug.startPageStatus(element);
            let componentData = this.sContainer ? this.originComponentData : this.componentData;
            arr.forEach(ele => {
                if (!judgingEventLogic(ele.logics, componentData)) {
                    window.$EditorDebug.failPageStatus(element, ele.code);
                    return;
                }
                const { value } = getStatusVal(ele, componentData);
                this.$store.commit('updatePageCustomStatus', {
                    key: ele.code,
                    value
                });
			});
		},
		/**
			 * @description: 未找到
			 * @param {*} message
			 * @return {*}
			 */
		handleNotFind  (message)  {
			this.notFind = true;
			this.getMp3Url(message);
			this.closeDialog();
		},
		/**
		 * @description: 操作失败
		 * @param {*} message
		 * @return {*}
		 */
		operationFailed  (message) {
			this.failed = true;
			this.getMp3Url(message);
			this.closeDialog();
		},
		/**
		 * @description: 找到相识的内容
		 * @param {*} message
		 * @return {*}
		 */
		handleSimilarData  (similarData)  {
			this.matchedArr = similarData.slice(0, 5);
			let msg = '为您找到以下选项：';
			this.matchedArr.forEach((item, index) => {
				msg += `${index + 1},${item.content}`;
			});
			this.getMp3Url(msg);
			this.closeDialog(10000);
		},
		/**
		 * @description: 未找到
		 * @param {*} message
		 * @return {*}
		 */
		handleExactMatch  (msg, videoContentMsg)  {
			this.exactMatch = msg;
			this.getMp3Url(videoContentMsg);
			this.closeDialog();
		},
		/**
		 * @description: 关闭弹窗
		 * @return {*}
		 */
		closeDialog  (time = 4000) {
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
				this.activated = false;
				this.matchedArr = [];
			}, time);
		},
		/**
		 * @description: 返回并播放mp3链接
		 * @param {*} content
		 * @return {*}
		 */

		getMp3Url (videoContent) {
			axios
				.post('https://dwh_api.bimcc.net/api/repository/synthesis', { content: videoContent })
				.then((res) => {
					if (res && res?.data?.code === 200 && res?.data?.data) {
						this.audioObj = new Audio(res.data.data);
						this.audioObj.play();
					}
				})
				.catch((err) => {
					console.log(err, 'err--err');
				});
		},
		/**
		 * @description: 获取用户媒体
		 * @param {*} constrains
		 * @return {*}
		 */
		getUserMedia (constrains = { audio: true }) {
			if (navigator.mediaDevices.getUserMedia) {
				// 最新标准API
				navigator.mediaDevices
					.getUserMedia(constrains)
					.then((stream) => {
						this.success(stream);

						this.recorder = new HZRecorder(stream);
						console.log('录音初始化准备完成');
					})
					.catch((err) => {
						this.error(err);
					});
			} else if (navigator.webkitGetUserMedia) {
				// webkit内核浏览器
				navigator
					.webkitGetUserMedia(constrains)
					.then((stream) => {
						this.success(stream);
						this.recorder = new HZRecorder(stream);
						console.log('录音初始化准备完成');
					})
					.catch((err) => {
						this.error(err);
					});
			} else if (navigator.mozGetUserMedia) {
				// Firefox浏览器
				navigator
					.mozGetUserMedia(constrains)
					.then((stream) => {
						this.success(stream);
						this.recorder = new HZRecorder(stream);
						console.log('录音初始化准备完成');
					})
					.catch((err) => {
						this.error(err);
					});
			} else if (navigator.getUserMedia) {
				// 旧版API
				navigator
					.getUserMedia(constrains)
					.then((stream) => {
						this.success(stream);
						this.recorder = new HZRecorder(stream);
						console.log('录音初始化准备完成');
					})
					.catch((err) => {
						this.error(err);
					});
			}
		},

		// 成功的回调函数
		success (stream) {
			console.log('已点击允许,开启成功', stream);
		},
		// 异常的回调函数
		error (error)  {
			console.log('访问用户媒体设备失败：', error.name, error.message);
		},

	},
	beforeDestroy() {
    window.removeEventListener('keydown', this.keydownFun);
		window.removeEventListener('keyup', this.keyUpFun);
  }
}

</script>

<style lang="less" scoped>
@keyframes purpleAn {
    0% {
        transform: rotate(0deg) translateX(0px);
    }
    50% {
        transform: rotate(180deg) translateX(0px);
    }
    100% {
        transform: rotate(360deg) translateX(0px);
    }
}

@keyframes blueAn {
    0% {
        transform: rotate(360deg) translateY(0px);
    }
    50% {
        transform: rotate(180deg) translateY(0px);
    }
    100% {
        transform: rotate(0deg) translateY(0px);
    }
}

@keyframes groupAn {
    0% {
        transform: rotate(360deg) translateX(0px);
    }
    50% {
        transform: rotate(180deg) translateX(0px);
    }
    100% {
        transform: rotate(0deg) translateX(0px);
    }
}

.voice-fade-enter-active,
.voice-fade-leave-active {
    transition: all 0.5s ease;
}

.voice-fade-enter-from,
.voice-fade-leave-to {
    transform: translateX(200px);
    opacity: 0;
}

.voice-assistant {
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 100%;
    .default {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 172px;
        height: 184px;
        img {
            width: 100%;
            object-fit: contain;
        }
        .purple {
            position: absolute;
            top: 32px;
            right: 22px;
            transform: rotate(30deg);
            animation: purpleAn 6s infinite ease-in-out;
        }
        .blue {
            position: absolute;
            right: 36px;
            bottom: 35px;
            animation: blueAn 8s infinite ease;
        }
        .group {
            position: absolute;
            right: 8px;
            bottom: 20px;
            animation: groupAn 15s infinite ease-in-out;
        }
    }

    .activated {
        width: 417px;
        height: 235px;
        display: flex;
        align-items: center;
        gap: 15px;

        box-sizing: border-box;
        padding: var(--spacing-4, 8px) var(--spacing-8, 16px);
        border-radius: 16px;
        border: 1px solid #151aa6;
        background: rgba(4, 5, 42, 0.6);

        .chat-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: flex-end;
            gap: var(--spacing-4, 8px);
            .title {
                font-family: Source Han Sans CN;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: rgba(255, 255, 255, 0.8);
            }
            .word {
                font-family: Source Han Sans CN;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: #e8eff7;
                max-width: 200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .control {
            display: flex;
            flex-direction: column;
            align-items: center;
            .voice-icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 172px;
                height: 184px;
                .bg-img {
                    width: 100%;
                    object-fit: contain;
                }
            }
            .wave {
                position: absolute;
                width: 50%;
                top: -6px;
                height: 100%;
                filter: hue-rotate(217deg);
            }

            .purple {
                position: absolute;
                top: 32px;
                right: 22px;
                transform: rotate(30deg);
                animation: purpleAn 6s infinite ease-in-out;
            }
            .blue {
                position: absolute;
                right: 36px;
                bottom: 35px;
                animation: blueAn 8s infinite ease;
            }
            .group {
                position: absolute;
                right: 8px;
                bottom: 20px;
                animation: groupAn 15s infinite ease-in-out;
            }
            .control-text {
                display: flex;
                box-sizing: border-box;
                padding: var(--spacing-2, 4px) var(--spacing-8, 16px);
                align-items: flex-start;
                gap: 19px;
                border-radius: 32px;
                background: rgba(7, 62, 165, 0.3);
                box-shadow: 0px 4px 4px 0px rgba(122, 88, 255, 0.3) inset;

                font-family: Source Han Sans CN;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
                background: linear-gradient(
                    91deg,
                    #c99eff 2.22%,
                    #fff 58.29%,
                    #4992ff 105.1%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}
</style>
