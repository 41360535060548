<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-05-09 14:32:48
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:44:53
 * @FilePath: /dataview-viewer-test/src/custom-component/form/newParser/FormItem/widget-text.vue
 * @Description: 
-->
<!--
    @name: widget-text
    @description：widget-text
    @author: ZengWei
    @date: 2022-03-25 09:45
-->
<template>
  <div>
    <div v-if="element.config.__config__.isTitle" class="type-label">{{ element.config.__config__.label }}</div>
    <div v-else
      :class="className"
      :style="{ textIndent: element.config.__config__.labelWidth + 'px' }"
    >{{ element.config.__config__.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "WidgetText",
  components: {},
  props: ['element', 'disabled'],
  data() {
    return {}
  },
  computed: {
    className() {
      const className = {
        'normal-label': true,
        'text-left': this.element.config.__config__.align === 'left',
        'text-center': this.element.config.__config__.align === 'center',
        'text-right': this.element.config.__config__.align === 'right'
      }
      return className
    }
  },
  methods: {},
}
</script>

<style scoped>
.type-label {
  border-left: 3px solid var(--themeColor) !important;
  padding: 0 10px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.normal-label {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}
</style>
