<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-04-17 11:54:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-04-17 14:19:00
 * @FilePath: /dataview-next/src/custom-component/dialog/cache/Name.vue
 * @Description: 
-->
<template>
  <div class="cache-name">
    <p class="label">保存成功后，可在草稿处使用</p>
    <el-input v-model="draftName" placeholder="请输入草稿名称" clearable @input="onEmit"></el-input>
  </div>
</template>

<script>
import { Input } from 'element-ui';

export default {
  name: 'CacheName',
  components: {
    'el-input': Input
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      draftName: ''
    }
  },
  created() {
    this.draftName = this.value;
  },
  methods: {
    /**
     * @description: 提交
     */
    onEmit() {
      this.$emit('input', this.draftName);
    }
  }
}
</script>

<style lang="less" scoped>
.cache-name {
  width: 100%;
  p {
    width: 100%;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: rgba(112, 119, 134, 1);
  }
}
</style>