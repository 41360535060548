/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-08-02 11:20:07
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-08-14 14:02:16
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\scripts\formatElement.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint-disable */
import {formatThousandsMoney} from "@/custom-component/form/newParser/scripts/tools";


/**
 * @description: 逻辑输入验证
 * @param {*} rule
 * @param {*} value
 * @param {*} cb
 * @return {*}
 */
function logicInputValidator (rule, value, cb) {
	if(!Array.isArray(value) || !value.length) {
		cb(new Error(this.message));
		return;
	}

	// 检验
	const itemHandle = (data) => {
		if(Array.isArray(data)) {
			return data.every(el => itemHandle(el))
		} else if (data && typeof data === 'object') {
			switch(data.type) {
				case 'logic': {
					return !!data.logicType && itemHandle(data.children);
				}
				case 'condition': {
					return data.leftField &&
						data.condition && data.rightType &&
						(
							(data.rightType === 'field' && data.rightField) ||
							(data.rightType === 'fixed' && data.fixedValue !== '' && data.fixedValue !== undefined && data.fixedValue !== null)
						)
				}
				default: {
					return false;
				}
			}
		}
	}
	if(value) {
		if(itemHandle(value)) {
			cb();
		} else {
			cb(new Error(this.message));
		}
	}
}

const formatElement = (ele, fillMatch= {}, fillData= {}, recoverData = {}) => {
	let itemData; //格式化后的item数据
	let recoverVal = '';
	let type = 'string'
	const existKeys = Object.keys(recoverData)
	switch (ele.__config__.tagIcon) {
		case 'input':
		case 'textarea':
		case 'rich-text':
		case 'money':
		case 'password':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__]
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
      if(ele.__config__.tagIcon === 'money') {
        // 金融控件千分位格式化
        itemData.formatValue = formatThousandsMoney(itemData.value)
      }
			if (ele.__config__.required) {
				itemData.validate = [{required: true, message: ele.__config__.label + '为必填项', trigger: 'blur'}];
        if(ele.__config__?.regList && ele.__config__.regList.length){
          const regList = ele.__config__.regList
          for (let item of regList) {
            itemData.validate.push({pattern: item.pattern,  message: item.message, trigger: 'blur'})
          }
        }
			} else if(ele.__config__?.regList && ele.__config__.regList.length) {
				itemData.validate = []
				const regList = ele.__config__.regList
				for (let item of regList) {
					itemData.validate.push({pattern: item.pattern,  message: item?.message, trigger: 'blur'})
				}
			}
			break;
		case 'number':
		case 'slider':
		case 'rate':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : 0;
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__]
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'number', trigger: 'change'};
			}
			break;
		case 'select':
			if(ele.__config__.dataType !== 'static'){
				ele.__slot__.options = [] // 动态时将静态数据清空
			}
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			if (ele.multiple) recoverVal = []
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || recoverVal
        if(recoverData[ele.__vModel__] === 0) {
          recoverVal = 0
        }
			}
			if(!(recoverVal instanceof Array)){
				recoverVal += '';
			}
			itemData = {
				value: recoverVal,
				type: ele.__config__.tagIcon,
				field: ele.__vModel__,
				config: ele,
			};
			if (ele.__config__.required) {
				itemData.validate = [{
					required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				}];
			}
			if (ele.multiple) {
				itemData.value = recoverVal;
				if (ele.__config__.required) {
					itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'}
				}
			}
			break;
		case 'radio':
		case 'color':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue + '' : '';
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__]+'' || recoverVal
			}
			if(ele.__config__.tagIcon === 'radio'){
				const optionsVal = Array.from(ele.__slot__.options,item=>item.value)
				if(!(optionsVal.includes(recoverVal) || optionsVal.includes(recoverVal+'')|| optionsVal.includes(parseInt(recoverVal)))){
					recoverVal = ''
				}
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true, type: 'string', message: ele.__config__.label + '为必填项',
					trigger: 'change', transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				};
			}
			break;
		case 'switch':
			recoverVal = ele.__config__.defaultValue;
			const activeValue = ele['active-value'];
			if(existKeys.includes(ele.__vModel__)){
				// recoverVal = Boolean(parseInt(recoverData[ele.__vModel__]))
				recoverVal = (typeof activeValue === typeof recoverData[ele.__vModel__]) ? recoverData[ele.__vModel__] : Boolean(parseInt(recoverData[ele.__vModel__]));
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			const selfType = ele['active-value'] !== undefined ? typeof ele['active-value'] : 'boolean'
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: ele.__config__.label + '为必填项',
					type: selfType,
					trigger: 'change'
				};
			}
			break;
		case 'time':
		case 'date':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : '';
			if(existKeys.includes(ele.__vModel__)){
        if(recoverData[ele.__vModel__] === '0000-00-00 00:00:00' || recoverData[ele.__vModel__] === '0000-00-00'){
          recoverVal = ''
        } else {
          recoverVal = recoverData[ele.__vModel__]
        }
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele};
			if (ele.__config__.required) {
				itemData.validate = {
					required: true, message: ele.__config__.label + '为必填项', type: 'string', trigger: 'change',
					transform: (value) => {
						let base;
						if (value) base = "" + value;
						return base;
					}
				}
			}
			break;
		case 'cascader':
			if(ele.__config__.dataType !== 'static'){
				ele.options = [] // 动态时将静态数据清空
			}
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__]
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'checkbox':
		case 'time-range':
		case 'date-range':
		case 'upload':
			recoverVal = ele.__config__.defaultValue ? ele.__config__.defaultValue : [];
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || []
			}
			if(ele.__config__.tagIcon === 'time-range') {
				if(recoverVal.length === 0){
					recoverVal = ["00:00","12:00"]
				}
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'form':
			recoverVal = [];
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.componentName + '为必填项', type: 'array', trigger: 'blur'};
			}
			break;
		case 'bimList':
			recoverVal = [];
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || []
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'gisList':
			recoverVal = [];
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || []
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'change'};
			}
			break;
		case 'user':
			recoverVal = [];
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || []
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: 'array', trigger: 'blur'};
			}
			break;
		case 'treeSelect':
			recoverVal = '';
			ele.options = []
			const isMulti = ele.props.props.multiple;
			if(isMulti) {
				recoverVal = [];
				type = 'array';
			}
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || recoverVal
				if(!(recoverVal instanceof Array)){
					recoverVal += ''
				}
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', trigger: 'blur'};
			}
			break
		case 'signature':
		case 'position':
			recoverVal = [];
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__] || []
			}
			type = 'array';
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: type, trigger: 'blur'};
			}
			break
		case 'task':
			recoverVal = '';
			type = 'string';
			if(existKeys.includes(ele.__vModel__)){
				recoverVal = recoverData[ele.__vModel__]+''
			}
			itemData = {value: recoverVal, type: ele.__config__.tagIcon, field: ele.__vModel__, config: ele, validate: []};
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: type, trigger: 'change'};
			}
			break
		case 'outerForm':
			recoverVal = 0
			type = 'number';
			itemData = {type: ele.__config__.tagIcon, config: ele};
			if(ele.__vModel__){
				if(existKeys.includes(ele.__vModel__)){
					recoverVal = recoverData[ele.__vModel__]
				}
				if(recoverVal === 0) recoverVal = ''
				itemData.field = ele.__vModel__;
				itemData.value = recoverVal;
			}
			if (ele.__config__.required) {
				itemData.validate = {required: true, message: ele.__config__.label + '为必填项', type: type, trigger: 'change'};
			}
			break
		case 'logic-input':
			itemData = {type: ele.__config__.tagIcon, config: ele};
			if(ele.__vModel__){
				if(existKeys.includes(ele.__vModel__)){
					recoverVal = recoverData[ele.__vModel__]
				}
				itemData.field = ele.__vModel__;
				itemData.value = recoverVal;
			}
			if (ele.__config__.required) {
				itemData.validate = {
					required: true,
					message: ele.__config__.label + '为必填项',
					validator: logicInputValidator,
					trigger: 'change'};
			}
			break;
		default:
			itemData = {type: ele.__config__.tagIcon, config: ele};
			if(ele.__vModel__){
				if(existKeys.includes(ele.__vModel__)){
					recoverVal = recoverData[ele.__vModel__]
				}
				itemData.field = ele.__vModel__;
				itemData.value = recoverVal;
			}
	}
  if(itemData.type === 'tab' || itemData.type === 'form'){
    // 用于tab子项填充数据
    itemData._fillMatch = fillMatch
    itemData._fillData = fillData
  }

  if(itemData.value === null || itemData.value === 'null') itemData.value = ''

	//表单配置了填充规则的数据匹配 -- 数据自动填充
	const fillMatchKeys = Object.keys(fillMatch)
	const fillDataKeys = Object.keys(fillData)
	if(fillMatchKeys.length>0 && fillDataKeys.length>0){
		const formId = ele.__config__.formId;
		if(fillMatchKeys.includes(formId)){
			const matchVal = fillMatch[formId]
			if(fillDataKeys.includes(matchVal)){
        let value = fillData[matchVal]
        if(typeof value === 'number'){
          value = value + ''
        }
				itemData.value = value
			}
		}
	}

	return itemData;
}

export default formatElement;
