// import html2canvas from "html2canvas";
export default class Utils {
    /**
     * 检查是否已经加载完成某个url
     * @param {*} list 
     * @param {*} param1 
     * @returns 
     */
    static checkLoadedUrl( list ,{ onLoaded = null, onNotLoaded = null } = {}){
        const headDom = document.getElementsByTagName('head')[0];
        const urls = [
            ...headDom.getElementsByTagName('link') ,
            ...headDom.getElementsByTagName('script'),
        ]

        const unloadedUrl = [];
        const loadedUrl = [];
        for(let dom of urls){
            if( dom.href ){
                loadedUrl.push(dom.href)
            }

            if( dom.src){
                loadedUrl.push(dom.src)
            }
        }


        for(let url of list){
            if( loadedUrl.indexOf(url) == -1){
                unloadedUrl.push(url)
                onNotLoaded && onNotLoaded(url);
            }else{
                onLoaded && onLoaded(url);
            }
        }

        return unloadedUrl;
    }
    /**
     * 添加列表到header中
     * @param {*} list 
     */
    static appendListInHead( list ){
        for(let url of list){
            const temp = url.split('.')
            const ext = temp[temp.length-1];

            switch (ext) {
                case 'js':
                    Utils.appendScriptInHead(url);
                    break;
                case 'css':
                    Utils.appendLinkInHead(url);
                    break;
            }

        }
    }

    /**
     * 添加脚本到header中
     * @param {} url 
     */
    static appendScriptInHead( url ){
        const headDom = document.getElementsByTagName('head')[0];

        const scDom = document.createElement('script');

        scDom.src = url;

        headDom.appendChild(scDom)
    }
    
    static deepCopy(val){
        return JSON.parse(JSON.stringify(val));
    }

    /**
     * 添加link到header中
     * @param {} url 
     */
    static appendLinkInHead( url ){
        const headDom = document.getElementsByTagName('head')[0];

        const linkDom = document.createElement('link');

        linkDom.rel = 'stylesheet';
        linkDom.href = url;

        headDom.appendChild(linkDom)
    }

    /**
     * 等待时间
     * @param {} ms 
     * @returns 
     */
    static wait( ms ){
        return new Promise((rol)=> {
             setTimeout(()=>{
                rol();
            },ms);
        });
    }

    /**
     * 等待加载完成
     * @param {} isloadedFunc 
     * @param {*} time 
     * @returns 
     */
    static waitLoaded( isloadedFunc , time = 100){
        return new Promise((rol )=>{
            let index = setInterval(()=>{
                if(isloadedFunc()){
                    clearInterval(index);
                    rol();
                }
            }, time);
        });
    }

    static log(str){
        console.log(`%c [ViewerBIM]${str}` , `color:#62bf62`)
    }

    static warn(str){
        console.warn(`[ViewerBIM]${str}` );
    }

    static getGuid(format = 'xxxxxxxxxxxx'){
        var d = new Date().getTime();
        var guid = format.replace(
            /[xy]/g,
            function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
            });
        return guid;
    }
    
    static color16ToVector3(color16_str ){
        var color_16list =  [
            (color16_str[1]+color16_str[2]),
            (color16_str[3]+color16_str[4]),
            (color16_str[5]+color16_str[6])];
        var c = []
        color_16list.forEach((v)=>{
            var num = parseInt(v,16)
            c.push(num);
        })

        return c;
    }
}