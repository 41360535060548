export default class SceneEvents {
  constructor() {}

  static getEvents(viewer, comp) {
    return {
      'ViewerGIS.getCameraView': (eid, cb) => {
        if (eid !== comp.element.id) return;

        const state = viewer.Scene.getCameraView();
        cb(Object.assign({}, state));
      },
      'ViewerGIS.getImageryLayer': (eid, cb) => {
        if (eid !== comp.element.id) return;

        function getImageryName(type){
          switch (type) {
            case 'TianDiTu':
              return '天地图影像地图';
            case 'AMapStreets':
              return '高德街道地图';
            case 'AMapSatellite':
              return '高德影像地图';
            case 'ArcGIS':
              return 'ArcGIS暗黑街道地图';
            case 'MapBox':
              return 'MapBox自定义地图';
            case 'CustomMap':
              return '本地部署自定义地图';
          }
        }

        cb({
          type: viewer.viewer.imageryType,
          name: getImageryName(viewer.viewer.imageryType)
        });
      },
      'ViewerGIS.getSceneSetting': (eid, cb) => {
        if (eid !== comp.element.id) return;

        let setting = viewer.Scene.getSceneSetting();
        cb(setting);
      },
      'ViewerGIS.getFixPitch': (eid, cb) => {
        if (eid !== comp.element.id) return;

        let isFixPicth = !!viewer.viewer.isFixPicth;
        cb(isFixPicth);
      },

      'ViewerGIS.setSquarelyPitch': (eid, isActive) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.setSquarelyView();
        viewer.Scene.setFixPitch(isActive);
      },
      'ViewerGIS.setFixPitch': (eid, isActive) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.setFixPitch(isActive);
      },
      'ViewerGIS.setCameraView': (eid, state) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.setCameraView(Object.assign({}, state));
      },
      'ViewerGIS.setImageryLayer': (eid, type, options) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.setImageryLayer(type, options);
      },
      'ViewerGIS.addImageryByType': (eid, options) => {
        if (eid !== comp.element.id) return;

        let {mapType = 'Custom'} = options;
        switch (mapType) {
          case "Custom":
            viewer.Scene.addCustomImagery(options);
            break;
          case 'WMS':
            viewer.Scene.addWMSImagery(options);
            break;
        }

      },
      'ViewerGIS.showImagery': (eid, name, isShow) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.showImagery(name, isShow);
      },
      'ViewerGIS.removeImagery': (eid, name) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.removeImagery(name);
      },
      'ViewerGIS.setSceneSetting': (eid, type, options) => {
        if (eid !== comp.element.id) return;

        switch (type) {
          case 'shadows':
            viewer.Scene.setSun(options.value);
            break;
          case 'sunIntensity':
            viewer.Scene.setSunIntensity(Number(options.value));
            break;
          case 'moon':
            viewer.Scene.setMoon(options.value);
            break;
          case 'groundAtmosphere':
            viewer.Scene.setGroundAtmosphere(options.value);
            break;
          case 'skyAtmosphere':
            viewer.Scene.setSkyAtmosphere(options.value);
            break;
          case 'skyBox':
            viewer.Scene.setSkyBox(options.value);
            break;

          case 'collisionDetection':
            viewer.Scene.setCollisionDetection(options.value);
            break;
          case 'fxaa':
            viewer.Scene.setFXAA(options.value);
            break;
          case 'msaa':
            viewer.Scene.setMSAA(options.value);
            break;
          case 'ambientOcclusion':
            viewer.Scene.setAmbientOcclusion(options.isShow, options.value);
            break;
          case 'bloom':
            viewer.Scene.setBloom(options.isShow, options.value);
            break;
        }
      },
      'ViewerGIS.setDefaultView': (eid, options, type = -1) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.setDefaultView(options, type);
      },
      'ViewerGIS.flyDefaultView': (eid) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyDefaultView();
      },
      'ViewerGIS.flyMyLocation': (eid) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyMyLocation();
      },
      'ViewerGIS.flyTo': (eid, destination, range = 100, angle = 90, duration = 0) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyTo(destination, range, angle, duration);
      },
      'ViewerGIS.flyToPoint': (eid, lng, lat, height, duration, angle) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyToPoint(lng, lat, height, duration, angle);
      },
      'ViewerGIS.flyToBound': (eid, destination, angle = 90, duration = 0, range = 0) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyToBound(destination, angle, duration, range);
      },
      'ViewerGIS.flyToBoundingSphere': (eid, bound, angle = 90, duration, range = 0) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyToBoundingSphere(bound, angle, duration, range);
      },
      'ViewerGIS.flyToHeading': (eid, destination, range = 100, angle = 90, duration = 0) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyToHeading(destination, range, angle, duration);
      },
      'ViewerGIS.flyToPointHeading': (eid, lng, lat, height, duration, angle) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyToPointHeading(lng, lat, height, duration, angle);
      },
      'ViewerGIS.flyToBoundHeading': (eid, destination, angle = 90, duration = 0, range = 0) => {
        if (eid !== comp.element.id) return;

        viewer.Scene.flyToBoundHeading(destination, angle, duration, range);
      },

      'ViewerGIS.getViewPointState': (eid, cb) => {
        if (eid !== comp.element.id) return;

        viewer.ViewPoint.getState().then((res) => {
          cb && cb(res);
        });
      },
      'ViewerGIS.setViewPointState': (eid, info = {}) => {
        if (eid !== comp.element.id) return;

        viewer.ViewPoint.setState(info);
      },
      'ViewerGIS.setViewPointStateOnlyBase': (eid, info = {}) => {
        if (eid !== comp.element.id) return;

        viewer.ViewPoint.setStateOnlyBase(info);
      },
      'ViewerGIS.setViewPointStateOnlyModule': (eid, info = {}) => {
        if (eid !== comp.element.id) return;

        viewer.ViewPoint.setStateOnlyModel(info);
			},
      'ViewerGIS.getPolygonByMultiKeys': (eid, data, cb) => {
        if (eid !== comp.element.id) return;
        console.log(data, 'data')
        const res = viewer.Model.getPolygonByMultiKeys(data)
        cb && cb(res);
      },
    };
  }
}
