<!--
 * @Description: 公共表单容器 用于引入外部表单
 * @Author: luocheng
 * @Date: 2021-10-15 15:01:42
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-11-14 17:01:29
-->
<template>
  <div v-show="showToggle"  class="w-full h-full console-from-index" v-loading="loading" >
		<div  class="w-full h-full">
			<div class="form-top-part">
				<div class="detail-tab">
					<span v-for="(item,index) in tabData"  
						:class="[
							activeIndex==item.tab?'active':'',
							item.tab === 2&&isBim&&bimList===null?'tab-none':'',
							item.tab === 2&&!isBim&&gisList===null?'tab-none':'',
							item.tab === 3&&fileInfo?.length===0?'tab-none':'',
						]" 
						class="tab-item" @click="onChangeTab(item)" :key="index" >
						{{item.name}}
					</span>
					<span class="commit-btn" @click="onFormSubmit()" v-if="requestType !== 'detail' && activeIndex!==2" >提交</span>
				</div>
			</div>
			<div class="console-form-cont">	
				<!-- 基本信息 -->
				<FormCont
					:element="element"
					ref="formCont"
					:fileInfo="fileInfo"
					:chooseBimInfo="chooseBimInfo"
					:chooseGisInfo="chooseGisInfo"
					:chooseViewInfo= "chooseViewInfo"
					:groupComponents="groupComponents"
					:isGroup="isGroup"
					@set-special-info="setSpecialInfo"
					@reset-perent-data = "resetData"
					@postSuccess = "postSuccess($event)"
					v-show="activeIndex == 1" 
					/>
				<!-- 关联构件 -->
				<div  v-show="activeIndex == 2" v-if="otherShow"  class="components-cont">
					<div  class="w-full" :style="{display:showComponentCont}">
						<div class="flex-row w-full">
							<el-image
							v-if="viewPic"
							style="width: 72px; height: 75px;margin-right: 10px;"
							:src="viewPic"
							:preview-src-list="[viewPic]"
							fit="cover"></el-image>
							<div v-if="isBim"  class="flex1 overflow-hidden" >
								<div class="w-full">
									<!-- 详情 -->
									<div class="w-full" v-if="requestType === 'detail'">
										<el-button :disabled="bindedBimLen===0" @click="location($event)" type="primary" size="small" class="w-full" >
											<span>查看模型<span>({{bindedBimLen}})</span></span>	
										</el-button>
									</div>
									<!-- 编辑 -->
									<div class="w-full" v-else-if="requestType === 'edit'">
										<el-button :disabled="chooseBimInfo === null" v-if="bindedBimLen === 0" @click="saveComponents(true)"  type="primary" size="small" class="w-full" >
											<span>绑定模型<span></span></span>	  
										</el-button>
										<el-button :disabled="bindedBimLen === 0" v-else @click="location($event)" type="primary" size="small" class="w-full" >
											<span>查看模型<span>({{bindedBimLen}})</span></span>	
										</el-button>
									</div>
									<!-- 新增 -->
									<div class="w-full" v-else>
										<el-button :disabled="chooseBimInfo === null"  @click="saveComponents(true)"  type="primary" size="small" class="w-full" >
											<span>{{chooseBimInfo === null?'未选择':'已选择'}}</span><span v-if="chooseBimInfo !== null" v-html="chooseNum"></span>	  
										</el-button>
									</div>
								</div>
								<div v-if="requestType!=='detail'&&bindedBimLen!==0" class="inline-btns w-full">
									<el-tooltip class="item" effect="light" :content="isRebind?'绑定模型':'开启重绑'" placement="bottom">
										<el-button :disabled="bindedBimLen === 0" @click="clearComponents(1)" plain  circle><i class="iconfont " :class="isRebind?'icongis_bangding':'iconqingsaoqingchu'"/></el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="light" content="清除全部" placement="bottom">
										<el-button :disabled="bindedBimLen === 0" @click="clearComponents(2)" plain circle><i class="iconfont iconsaidi-neirong-jiebang" /></el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="light" content="添加已选择" placement="bottom">
										<el-button :disabled="chooseBimInfo === null"  @click="saveComponents(true)" plain circle><i class="iconfont iconzhankai1" /></el-button>
									</el-tooltip>
									<el-tooltip class="item" effect="light"  content="删除已选择" placement="bottom">
										<el-button style="margin-right: 0;" :disabled="chooseBimInfo === null"  @click="saveComponents(false)" plain circle><i class="iconfont iconshouqi" /></el-button>
									</el-tooltip>
								</div>
							</div>
							<div v-if="isGis"  class="flex1 overflow-hidden" > 
								<div v-if="chooseGisInfo !== null && chooseGisInfo"  class="w-full">
									<el-button   @click="location" type="primary" size="small" class="w-full" >
										<span>查看位置</span>	
									</el-button>
								</div>
								<div v-if="requestType!=='detail'" class="inline-btns w-full">
										<el-button class="gis-icon"  @click="clearComponents(1)" type="primary" plain  size="mini" >{{isRebind?'绑定位置':'开启重绑'}}</el-button>
										<el-button class="gis-icon" :disabled="chooseGisInfo === null || !chooseGisInfo"  @click="clearComponents(2)" type="primary" plain  size="mini" style="margin-right:0!important;">清除全部</el-button>
								</div>
							</div>
						</div>
					</div>
					<el-empty v-if="showComponentCont === 'none'" description="暂无数据" :image-size="80"></el-empty>
				</div>
				<!-- 附件 -->
				<div v-show="activeIndex == 3" v-if="otherShow" class="file-cont">
					<!-- 视点 -->
					<el-image
							v-loading="clickViewLoading"
							class="carousel"
							v-if="clickView !== null"
							style=" height: 140px;"
							:src="clickView.picture"
							:preview-src-list="[clickView.picture]"
							fit="cover">
					</el-image>
					<i v-loading="clickViewLoading" v-if="clickView !== null && requestType !== 'detail'" @click="handleView('update')" class="el-icon-refresh-right refresh-view-icon"></i>
					<div v-if="viewInfo !== null">
						<div  class="title">
							<span class="truncate">
								视点 <span>({{bindedViews && bindedViews.length? bindedViews.length:0}})</span>
							</span>
							<el-popover
								v-if="requestType !== 'detail'"
								placement="right"
								width="256"
								trigger="click">
								<div class="view-list-console" v-loading="viewLoading" v-if="viewList.length">
									<div class="view-title overflow-hidden">
										<span class="float-left">选择视图</span>
										<span  @click="handleView('createData')"  class="float-right add-file-btn">截取新视点</span>
									</div>
									<div class="view-item">
										<ul>
											<li  v-for="(v,j) in viewList" :key="j">
												<i @click="checkViewFn(v)" style="position:relative;top:-2px" class="iconfont float-left" :class="checkViewData.filter((f)=>f.id === v.id).length==0?'iconjijia_fuxuan1':'iconjijia_fuxuan2'"></i>
												<el-image
												class="float-left1"
												style="width: 20px; height: 20px;margin:0 5px;"
												:src="v.picture"
												fit="fit"></el-image>
												<span>
													{{v.name}}
												</span>
											</li>
										</ul>
									</div>	
								</div>
								<i  slot="reference" style="font-size: 18px;width: 18px;" class="el-icon-more"></i>
							</el-popover>
						</div>
						<!-- 视点列表 -->
						<ul v-if="bindedViews!=null && bindedViews.length" >
							<li class="list" :class="[clickView && clickView.id==v.id?'viewActive':'']"  v-for="(v,j) in bindedViews" :key="j">
								<div @click="tabView(v)"   style="flex:1;">
									<el-image
									class="float-left"
									style="width: 32px; height: 32px;margin-right:5px;"
									:src="v.picture"
									fit="fit"></el-image>
									<span style="font-size:14px">
										{{v.name}}
									</span>
								</div>
								<el-dropdown  :hide-on-click="false">
									<i v-show="requestType!='detail'" class="iconfont iconbimgis_gengduo" style="line-height:32px"></i>
									<template #dropdown>
										<el-dropdown-menu >
											<el-dropdown-item v-if="includesModel(v)"  ><p @click="loadViewModel(v)">加载视点模型</p></el-dropdown-item>
											<el-dropdown-item v-if="requestType!='detail'" ><p @click="delViewFile(bindedViews,j)">移除该视点</p>	</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
							</li>
						</ul>
						<el-empty v-else description="暂无数据" :image-size="80"></el-empty>
					</div>
					<!-- 详情附件 -->
					<div  v-for="(item,index) in fileInfo" :key="index">
						<div class="title">
							<span class="truncate" style="padding-right:20px">
								{{item.config.__config__.label}}
								({{item.value && item.value!=null? Object.prototype.toString.call(item.value) === '[object Array]'?item.value?.length:1:0}})
							</span>
						</div>
						<Uploader
							v-if="Object.prototype.toString.call(item.value) === '[object Array]'"
							blockUpload
							:disabled="requestType === 'detail'"
							:files="item.value"
							@extra-files="onSuccess($event,item)"
						>
						</Uploader>
						<Uploader
							v-if="Object.prototype.toString.call(item.value) === '[object String]'"
							:disabled="requestType === 'detail'"
							:limit="1"
							only-img-url
							:files="[item.value]"
							@extra-files="onSuccess($event,item)"
						>
						</Uploader>
					</div>
				</div>
			</div>	
			</div>
  </div>
</template>

<script>
import FormCont from '@/console-components/consoleForm/components/formCont.vue';
import eventBus from '@/plugins/eventBus';
import { findModelComponentId, getNowTime, getComponentById } from '@/utils/tools.js'
import { dataInterface } from '@/apis/data/index';
import Uploader from '@/components/fileUpload/newFiles/index.vue'
import { mapState } from 'vuex';
import { Empty, Image, Tooltip, Button, Popover, Dropdown, DropdownMenu, DropdownItem } from 'element-ui';

export default {
	name: 'ConsoleForm',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	components: {
		FormCont,
		Uploader,
		'el-empty': Empty,
		'el-image': Image,
		'el-tooltip': Tooltip,
		'el-button': Button,
		'el-popover': Popover,
		'el-dropdown': Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem
  },
	data() {
		return {
			tabData:[
				{name:'基本信息',tab:1},
				{name:'关联位置',tab:2},
				{name:'附件',tab:3},
			],
			otherShow: true,//控制非表单部分刷新数据用
			loading:false,
			activeIndex:1,
			bimList:null,//bim模型字段
			gisList:null,//gis模型字段
			fileInfo:[],//附件
			chooseGisInfo:null,//gis选中的位置以及模型
			chooseBimInfo:null,//bim选中的模型构件（里面是"field6268d8869a7a2": []格式）
			chooseViewInfo:null,//添加视点附件所选
			bindedBimInfo: null, //已绑定的模型构件信息
			bindedGisInfo: null, //已绑定的模型构件信息
			bindedBimLen:0,//已绑定的Bim构件数量
			isRebind:false,
			viewPic:'',//绑定构件的截图
			timer:null, //解决触发多次的计时器
			bimComponentId:'',//模型的组件id
			gisComponentId:'',//gis组件id
			showToggle: true,
			clickViewLoading:false,
			
			viewInfo:null,//视图字段
			viewLoading:false,
			checkViewData:[],//选中的视点数据
			clickView:null,//点击的视图
			bindedViews: [],//已绑定的视图
			viewList:[],//可选的视图
			loadedBimIds:[], //记载的模型列表
			defaultView: null
		};
	},
	computed: {
		...mapState(['componentData', 'curComponent']),
		// 内容配置
		contentConfig() {
			return this.element && this.element.contentConfig;
		},
		//数据来源
		isBim () {
			const bimComponent = getComponentById(this.componentData, this.bimComponentId)
			if(bimComponent && bimComponent.statusConfig?.displayShow && bimComponent.statusConfig?.isShow){
				return true
			}else{
				return false
			}
		},
		//是否是gis页面显示
		isGis () {
			const gisComponent = getComponentById(this.componentData, this.gisComponentId)
			if(gisComponent && gisComponent.statusConfig?.displayShow && gisComponent.statusConfig?.isShow){
				return true
			} else {
				return false
			}
		},
		sourceConfig(){
			return this.element && this.element.sourceConfig;
		},
		// 请求类型(待追加)
		requestType() {
			const types = {
				createData: 'add',
				dataInfo: 'detail',
				updateData: 'edit'
			};
			return types[this.element?.sourceConfig?.[0].type || 'add'];
		},
		//选中的构件个数
		chooseNum (){
			let num = 0
			if(this.chooseBimInfo && this.chooseBimInfo!==null && this.bimList.field && this.chooseBimInfo[this.bimList.field]?.length){
				this.chooseBimInfo[this.bimList.field].forEach((item)=>{
					if(item.dbids?.length){ 
						num += item.dbids.length
					}
				})
				return ' ('+ num +')'
			}
			return ' ('+ num +')'
		},
		showComponentCont(){
			if(this.isBim){
				if(this.bindedBimLen === 0){
					if(this.requestType === 'detail'){
						return 'none'
					}else{
						return 'block'
					}
				}else{
					return 'block'
				}
			}else{
				if(this.requestType === 'detail'){
					if(this.chooseGisInfo !== null && this.chooseGisInfo){
						return 'block'
					}else{
						return 'none'
					}
				} else {
					return 'block'
				}
			}
		},
		includesModel(){
			return function(data){
				if(data.state?.model_ids?.length){
					for(var i = 0; i < data.state.model_ids.length; i++){
						if(!this.loadedBimIds.includes(data.state.model_ids[i])){
							return true
						}
					}
					return false
				}else{
					return false
				}
			};
		},
	},
	created(){
		//获取模型的组件id
		this.bimComponentId = findModelComponentId(this.componentData,'ViewerBIM') || '';
		this.gisComponentId = findModelComponentId(this.componentData,'ViewerGIS') || '';
	},
	mounted() {
		//获取视点数据
		this.getViewData()
		//有页面状态取值为formStatusBysign时关闭页面
		let statusConfig = JSON.parse(sessionStorage.getItem('statusConfig'))
		if(statusConfig && statusConfig.length){
			let filterData = statusConfig.filter(v => v.code === 'formStatusBysign')
			if(filterData?.length && filterData[0].defaultValue === 1){
				this.showToggle = false
			}
		}
		//监听获取加载的模型
		eventBus.$on('Viewer.onLoadedModel',() => {
			//默认添加一个视点
			eventBus.$emit('ViewerBIM.getModelIds', this.bimComponentId , (list)=>{
				if(list){
					this.loadedBimIds = [...list]
				}
			})
		})
		//bim模型选中构件
		eventBus.$on('Viewer.onViewerSelect',(componentId,selection) => {
			//获取加载完的模型
			if(selection){
				if(this.bimList === null || this.requestType === 'detail') return
				this.chooseBimInfo = null
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					if(selection?.length){
						let arr = []
						selection.forEach((item)=>{
							let obj = {model_id:item.model_id, dbids:item.dbids}
							arr.push(obj)
						})
						this.$nextTick(()=>{
							this.setChooseBimData(arr)
						})
					} else {
						this.chooseBimInfo = null
					}
				}, 200)
			}
		})
		//gis选中位置
		eventBus.$on('Viewer.onViewerSelect',(componentId,selection) => {
			//获取加载完的模型
			if(selection){
				// this.selectFn(selection)
			}
		})
		//点击标记显示表单
		eventBus.$on('showForm',() => {
			//获取加载完的模型
			if(!this.showToggle){
				this.showToggle = true
			}
		})

	},
	methods: {
  /**
   * @description: 切换顶部菜单
   * @return {*}
   */		
		async onChangeTab(item){
			this.activeIndex = item.tab
			//新增默认截取视点
			if(item.tab === 3 && this.viewInfo !== null && this.requestType === 'add' && this.defaultView  === null){
				let graffiti = null
				const picture = await this.getScreenShotBuffer();
				const state = await this.getState()
				if(await this.getGraffitiActive()) {
					graffiti = await this.getGraffitiData()
				}
				this.defaultView = {
					state : state,
					id: getNowTime(2),
					name: '视点/'+ getNowTime(2),
					picture : picture,
					graffiti: graffiti
				}
				this.bindedViews.push(this.defaultView)
				this.clickView = this.bindedViews[0]
			}
		},
		/**
   * @description: 获取涂鸦数据
   * @return {*}
   */		
    getGraffitiData(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.Graffiti.getData" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
		/**
   * @description: 获取涂鸦状态
   * @return {*}
   */		
    getGraffitiActive(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.Graffiti.isActive" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
  /**
   * @description: 获取视点状态
   * @return {*}
   */		
		getState(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.getState" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
   /**
    * @description: 获取截图
    * @return {*}
    */
		getScreenShotBuffer(){
			if(!this.bimComponentId) return Promise.resolve(null);
			return new Promise( (rol )=>{
					eventBus.$emit("ViewerBIM.getScreenShotBuffer" , this.bimComponentId , (data)=>{
							rol(data);
					})
			});
		},
		/**
		 * @desc: 重置特殊数据
		 * @param {Object} 
		 */
		resetData(){
				this.activeIndex = 1
				this.otherShow = false
				this.chooseGisInfo = null//gis选中的位置以及模型
				this.chooseBimInfo = null//bim选中的模型构件
				this.chooseViewInfo = null//选中的视点构件
				this.bindedBimInfo = null //已绑定的bim模型构件
				this.bindedGisInfo = null //已绑定的gis模型构件
				this.checkViewData = [],//添加视点附件所选
				this.clickView = null
				this.bindedBimLen = 0
				this.bindedViews = []
				this.bimList = null
				this.gisList = null
				this.viewInfo = null
				this.fileInfo = []
				this.otherShow = true
		},
		/**
		 * @desc: 获取bim,gis以及附件
		 * @param {Object} 
		 */
		setSpecialInfo(field, data){
				let value = JSON.parse(JSON.stringify(data.value))
				if(field === 'fileInfo'){
					if(this.fileInfo.filter((v)=>v.field===data.field).length === 0){
						this.fileInfo.push(JSON.parse(JSON.stringify(data)))
					}
				}else{
					this[field]=data
					if(field === 'gisList'){
						if(value && value!=='null'){
							this.bindedGisInfo = value
							if(!Array.isArray(value)){
								this.setChooseGisData(value)
							}
						}
					}
					if(field === 'bimList'){
						if(Array.isArray(value)){
							this.bindedBimLen = 0
							let num = 0
							this.bindedBimInfo = value
							if(value!=null  && value?.length){
								value.forEach((item)=>{
									if(item.dbids?.length){ 
										num += item.dbids.length
									}
									if(this.viewPic === '' && item.viewPic){
										this.viewPic = item.viewPic
									}
								})
								this.bindedBimLen = num
							}
						}
					}
					if(field === 'viewInfo'){
						if(Array.isArray(value)){
							this.bindedViews = value
							this.checkViewData = value
							if(this.clickView === null && value !== null && value?.length){
								this.clickViewLoading = true
								this.clickView = value[0]
								this.clickViewLoading = false
							}
						}
					}
				
				}
		},
		/**
		 * @desc: 定位模型
		 * @param {Object} output 输出的数据
		 */
		location(e){
			if(this.isBim){
				if(this.bindedBimInfo?.length){
					eventBus.$emit('ViewerBIM.setSelect',this.bimComponentId,this.bindedBimInfo);
					eventBus.$emit('ViewerBIM.getModelIds', this.bimComponentId , async(list)=>{
						if(list){
							let loadedBimIds = [...list]
							let unloadModel = await this.findUnloadModel(loadedBimIds,this.bindedBimInfo)
							if(unloadModel?.length){
								this.$confirm(loadedBimIds.length === 0?'是否加载模型?':'是否加载其他模型?', '提示', {
									confirmButtonText: loadedBimIds.length === 0 ? '确定' : '叠加加载',
									cancelButtonText: loadedBimIds.length === 0 ? '取消' : '重新加载',
									type: 'warning',
									closeOnClickModal: false,
									distinguishCancelAndClose: true,
									center: false,
									beforeClose (action, instance, done) {
											done()
									}
								}).then(() => {
									unloadModel.forEach((item)=>{
										eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, item.model_id)
									})
								}).catch((error) => {
									if(error === 'close'){
										return
									} else if(this.loadedBimIds.length){
										this.loadedBimIds.forEach((item)=>{
												eventBus.$emit('ViewerBIM.unloadModel', this.bimComponentId, item)
										})
										unloadModel.forEach((item)=>{
											eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, item.model_id)
										}) 
									}
								});
							} else {
								this.onAction('location',e)
							}
						}else{
								this.onAction('location',e)
						}
					});
				}
			}
			if(this.isGis){
				if(this.bindedGisInfo !== null && this.bindedGisInfo.position){
					eventBus.$emit('ViewerGIS.flyTo', this.gisComponentId, this.bindedGisInfo.position, 400, 45, 2);
					if(this.bindedGisInfo.models?.length){
						for(var i = 0; i < this.bindedGisInfo.models.length;i++){
							const item = this.bindedGisInfo.models[i]
							eventBus.$emit('ViewerGIS.selectFeature', this.gisComponentId,item.model_id,item.dbids,false );
						}
						
					}
					this.onAction('location',e)
				}
			}
		},
		//提交成功后的回调
		postSuccess(e){
			this.onAction('onFormSubmit',e)
			eventBus.$emit('refreshConsoleList')
		},
		//查找未加载的模型id,数组
		findUnloadModel(loadedArr,arr){
			let newArr = [];
			if(loadedArr?.length && arr?.length){
				arr.forEach((item)=>{
					if(!loadedArr.includes(item.model_id)){
						newArr.push(item);
					}
				})
				return newArr
			}
			return newArr;
		},
		/**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, e) {
			if(e){
				e.preventDefault();
				e.stopPropagation();
			}
			const eventList = this.element?.actionConfig[action]?.eventList;
			if (eventList?.length) {
				eventList.forEach((el) => {
					el.eventList.forEach(ele => {
						if (ele.key === 'click') {
							if (ele.actionType !== 'jumpPage') {
								// 触发组件行为
								const { behaviors, effects} = ele;
								if(behaviors?.length){
									behaviors.forEach(behavior => {
										this.$store.commit('triggerEvents', {
											config: {
												behavior,
												isBehavior: true
											},
											element: this.element
										});
									});
								}
								if(effects?.length){
									effects.forEach((effect) => {
										this.$store.commit('triggerEvents', {
											config: {
												...ele,
											...effect
											},
											element: this.element
										});
									});
								}
							}
						}
					})
				});
			}
		},
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		async onFormSubmit() {
			//新增默认截取视点
			if(this.requestType === 'add'){
				if(this.defaultView === null){
					let obj = {
						__method_name__ : "createData",
						object_uuid:'object63a01147947d3',
						view_uuid:'view63aea9a433c0c',
					}
					if(this.isBim){
						obj.picture = await this.getScreenShotBuffer();
						const state = await this.getState()
						obj.state = JSON.stringify(state)
						if(await this.getGraffitiActive()) {
							obj.graffiti = await this.getGraffitiData()
						}
						obj.name = '视点/'+ getNowTime(2)
					}
					dataInterface(obj).then((res)=>{
						if (res.code || (res.status && res.status === 200)) {
							this.getViewData()
							let newArr = this.bindedViews.concat(res.data.data)
							this.chooseViewInfo = {}
							if(this.viewInfo?.field){
								this.$set(this.chooseViewInfo, this.viewInfo.field, newArr)
							}
							this.onConfirm()
						}
					});
				} else {
					dataInterface({
						__method_name__ : "createData",
						object_uuid:'object63a01147947d3',
						view_uuid:'view63aea9a433c0c',
						state : JSON.stringify(this.defaultView.state),
						name: this.defaultView.name,
						picture : this.defaultView.picture,
						graffiti : this.defaultView.graffiti,
					}).then((res)=>{
						if (res.code || (res.status && res.status === 200)) {
							this.getViewData()
							let newArr = this.bindedViews.concat(res.data.data)
							let filterArr = newArr.filter(v => !((v.id).toString()).includes('-'))
							this.chooseViewInfo = {}
							this.$set(this.chooseViewInfo, this.viewInfo.field, filterArr)
							this.onConfirm()
						}
					});
				}
				
			} else {
				this.onConfirm()
			}
			
		},
  /**
   * @description: 提交请求
   * @return {*}
   */		
		onConfirm () {
			setTimeout(() => {
				this.$refs.formCont.onConfirm()
			}, 100);
		},
		/**
		 * @desc: 上传文件
		 */
		onSuccess(files,item){
			item.value = files
		},
		/**
		 * @desc: 赋值this.chooseBimInfo
		 */
		setChooseBimData(data = []){
			if(this.chooseBimInfo === null){
				this.chooseBimInfo = {}
				this.$set( this.chooseBimInfo,this.bimList.field, data)
			}else{
				this.chooseBimInfo[this.bimList.field] = data
			}
		},
		/**
		 * @desc: 赋值this.chooseGisInfo
		 */
		setChooseGisData(data = null){
			if(data === null){
				if(this.gisList.field){
					this.chooseGisInfo[this.gisList.field] = null
				} else {
					this.chooseGisInfo = null
				}
				
			} else {
				let obj = null;
				if(data.position?.lat && data.position?.lng){
					obj = {latitude:data.position.lat,longitude:data.position.lng}
				}
				let info = this.$refs.formCont.displayData['field6178f64abd88d'];
				if(this.chooseGisInfo === null){
					this.chooseGisInfo = {}
					//项目表的经纬度字段不同，需要修改
					if(this.contentConfig.objectUUID === 'object616fe6fe8119f'){
						if(info?.length){
							info[0]['field617d174674c0b'] = obj
							this.$set( this.chooseGisInfo,'relationship6178ed6a6ab82', info)
						}
					} else {
						this.$set( this.chooseGisInfo,this.gisList.field, data)
					}
				}else{
					//项目表的经纬度字段不同，需要修改
					if(this.contentConfig.objectUUID === 'object616fe6fe8119f'){
						if(info?.length){
							info[0]['field617d174674c0b'] = obj
							this.chooseGisInfo['relationship6178ed6a6ab82'] = info
						}
					} else {
						this.chooseGisInfo[this.gisList.field] = data
					}
					
				}
			}
		},
		/**
		 * @desc: 保存构件信息
		 * @param {Boolean} bol true算上选中的模型以及构件 false删除已选中构件
		 */
		async saveComponents (bol) {
			let comp = await this.contrastFn(bol)
			this.chooseBimInfo = comp
			this.onConfirm()
		},
		/**
		 * @desc: 清除所有绑定的构件信息
		 * @param {Number} type 2 清理全部 1 开启重绑
		 */
		async clearComponents (type) {
			if(this.isBim){
				if(type === 2){
					//清除全部
					await this.setChooseBimData([])
					this.onConfirm()
				}else{
					if(this.isRebind){
						this.isRebind = false
						if(this.chooseBimInfo !== null && this.chooseBimInfo[this.bimList.field].length !== 0){
							this.onConfirm()
						}else{
							this.$message.error("请先选择构件！");
							return
						}
					}else{
						this.setChooseBimData([])
						//清除选中的模型构件
						eventBus.$emit('ViewerBIM.setSelect',this.bimComponentId,[])
						this.isRebind = true
					}
				}
			}else{
				if(type === 2){
					await this.setChooseGisData()
					this.onConfirm()
				}else{
					if(this.isRebind){
						// 关闭绘制点坐标
						eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.gisComponentId);
						this.isRebind = false
						this.onConfirm()
					}else{
						// 开启绘制点坐标
						eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.gisComponentId, async(position,selectFeature) => {
							if(position){
								let obj = {position:position}
								if(Object.keys(selectFeature).length){
									let models = []
									for (let key in selectFeature) {
										models.push({ model_id: key, dbids:selectFeature[key] });
									}
									obj = {position:position, models:models}
								}
								this.setChooseGisData(obj)
							}
						});
						this.isRebind = true
					}
				}
			}
		},
		/**
		 * @desc: 对比模型数据
		 */
		contrastFn (bol) {
				let binded=JSON.parse(JSON.stringify(this.bindedBimInfo))
				let choosed=JSON.parse(JSON.stringify(this.chooseBimInfo))
				let newArr=[]
				let componentInfo = {}
				if(binded === null || binded?.length === 0){
					return choosed
				}else{
					let choosedValue = choosed[this.bimList.field]
					if(bol){
						let mergeArr=binded.concat(choosedValue)
						newArr=[mergeArr[0]]
						if(mergeArr?.length){
								mergeArr.forEach((item,i)=>{
								if(i != 0){
									let filterArr=newArr.filter((v)=>v.model_id==item.model_id)
									if(filterArr?.length===0){ 
										newArr.push(item)
									}else{
										newArr.forEach((val,j)=>{
											if(val.model_id === item.model_id){
												newArr[j].dbids = Array.from(new Set(newArr[j].dbids.concat(item.dbids)))
											}
										})
									}
								}
							})
						}
					}else{
						for (var i = 0; i < binded.length; i++) {
							for (var j = 0; j < choosedValue.length; j++) {
								if (binded[i].model_id == choosedValue[j].model_id) {
									choosedValue[j].dbids.forEach((val)=>{
										binded[i].dbids=binded[i].dbids.filter(function(cur){
											if(cur === val){
													return false
											}
											return true
										})
									})	
								}
							}
						}
						newArr = binded
					}
					this.$set( componentInfo,this.bimList.field,newArr )
					return componentInfo
				}
			},
			/**
		 * @desc: 视点勾选
		 */
			checkViewFn (data) { 
				if(this.bindedViews.length === 0){
					this.clickView = data
				}
				if(this.checkViewData.length == 0){
					this.checkViewData.push(data)
				}else{
					if(this.checkViewData.filter((item)=>item.id==data.id).length === 0){
						this.checkViewData.push(data)
					}else{
						this.checkViewData = this.checkViewData.filter(function(cur){
							if(cur.id === data.id){
									return false
							}
							return true
						})
					}
				}
				this.bindedViews = this.checkViewData
				this.chooseViewInfo = {}
				this.$set(this.chooseViewInfo, this.viewInfo.field, this.checkViewData)
			},
				/**
		 * @desc: 删除视点附件
		 */
			delViewFile (arr,i) {
				let file=JSON.parse(JSON.stringify(arr))
				file.splice(i,1)
				this.chooseViewInfo = {}
				this.$set(this.chooseViewInfo, this.viewInfo.field, file)
				this.onConfirm()
			},
				/**
		 * @desc: 切换视点
		 */
			tabView (data){
				this.clickViewLoading = true
				this.clickView = data
				//回显视点
				if(data?.state){
					if (Object.prototype.toString.call(data.state) === '[object String]') {
						eventBus.$emit('ViewerBIM.ViewState.RestoreState', this.bimComponentId, JSON.parse(data.state))
					} else {
						eventBus.$emit('ViewerBIM.ViewState.RestoreState', this.bimComponentId, data.state)
					}
					
				}
				//回显涂鸦
				if(data.graffiti){
					eventBus.$emit('ViewerBIM.Graffiti.open', this.bimComponentId)
					eventBus.$emit('ViewerBIM.Graffiti.setData', this.bimComponentId,data.graffiti)
				}
				this.clickViewLoading = false
			},
			/**
		 * @desc: 加载视图模型
		 */
			loadViewModel (data) {
				this.loadedBimIds.forEach((item)=>{
						eventBus.$emit('ViewerBIM.unloadModel', this.bimComponentId, item)
				})
				if(data.state?.model_ids?.length){
					for(var i = 0; i < data.state.model_ids.length; i++){
						eventBus.$emit('ViewerBIM.loadModel', this.bimComponentId, data.state.model_ids[i])
					}
				}
			},
			/**
		 * @desc: 操作视图
		 */
			async handleView (type) {
				this.clickViewLoading = true
				let graffiti = null
				const picture = await this.getScreenShotBuffer();
				const state = await this.getState()
				if(await this.getGraffitiActive()) {
					graffiti = await this.getGraffitiData()
				}
				this.clickViewLoading = false
				if(type==="update"){
					this.clickView.picture = picture
					if(this.bindedViews?.length){
						this.bindedViews.map((item)=>{
							if(item.id === this.clickView.id){
								item.picture = picture
								item.state = state
								if(this.requestType !== 'add'){
									dataInterface({
										__method_name__ : "updateData",
										object_uuid:'object63a01147947d3',
										view_uuid:'view63aea9a433c0c',
										data_id : this.clickView.id,
										state : JSON.stringify(state),
										picture : picture,
										graffiti: graffiti
									}).then((res)=>{
										if (res.code || (res.status && res.status === 200)) {
											this.getViewData()
										}
									});
								}
							}
						})
						this.chooseViewInfo = {}
						this.$set(this.chooseViewInfo, this.viewInfo.field, this.bindedViews)
						if(this.requestType !== 'add'){
							this.onConfirm()
						}
					}
				} else if(type === 'createData'){
					dataInterface({
						__method_name__ : "createData",
						object_uuid:'object63a01147947d3',
						view_uuid:'view63aea9a433c0c',
						name:'视点/'+ getNowTime(2),
						state : JSON.stringify(state),
						picture : picture,
						graffiti: graffiti
						
					}).then((res)=>{
						if (res.code || (res.status && res.status === 200)) {
							this.getViewData()
						}
					});
				}
			},
			getViewData () {
				// 获取视点数据
				dataInterface({
					object_uuid:'object63a01147947d3',
					view_uuid:'view63aea9a433c0c',
					__method_name__: 'dataList',
					transcode: 0
				}).then((res) => {
					if (res.code || (res.status && res.status === 200)) {
						this.viewList = res.data.data.data
					}
				}).catch((err) => {
					console.log(err);
				});
			}

	},
	beforeDestroy() {
		//多个组件时关闭了另一个组件也监听不了，暂且先注释
		// eventBus.$off('Viewer.onViewerSelect');
		// eventBus.$off('showForm');
	}
};
</script>
<style>
.view-list-console	p{
	opacity: 0.6;
	margin-bottom: 5px;
}
.view-list-console	li{
	padding: 5px;
}
.view-list-console	li:hover{
	background:#f6f6f6;
}
.view-list-console .add-file-btn{
	padding: 3px 8px;
	background-color: #0F62FE;
	border-radius: 2px;
	font-size: 12px;
	color: #fff;
}
.view-list-console .add-new-btn{
	padding: 3px 8px;
	border-radius: 2px;
	font-size: 12px;
	color: #666;
	background-color:#f5f5f5;
}
.view-list-console .view-item{
	max-height: 500px;
	overflow-y: auto;
}
.view-list-console  .view-title{
	height: 23px;
}
.view-list-console .iconjijia_fuxuan2{
	color:#0F62FE;
}
</style>	

<style lang="less" scoped>
	.console-from-index{
		backdrop-filter: blur(4px);
		.console-form-cont::-webkit-scrollbar{
			display: none;
		}
		.console-form-cont{
			height: 100%;
			width: 100%;
			overflow-y:auto;
			text-align: left;
			margin-top:10px;
			.refresh-view-icon{
				position: absolute;
				top: 20px;
				right: 10px;
				font-size: 18px;
			}
			.components-cont{
				padding: 12px 0 0 0;
				height: calc(100% - 24px);
				img{
					width: 72px;
					height: 75px;
					object-fit: fill;
					margin-right:10px;
				}
				.inline-btns{
					display:flex;
					flex-direction:row;
					justify-content: space-between;
					margin:8px 0 12px 0;
					i{
						font-size: 20px;
					}
					:deep(.el-button--small.is-circle) {
						padding: 6px!important;
						color:inherit;
					}
					.gis-icon{
						flex-grow:1;
					}
					:deep(.el-button) {
						margin-right: 6px;
						margin-left: 0!important;
						background: transparent!important;
						// border-color:inherit;
						// color:inherit;
					}
					:deep(.el-button--primary.is-plain:focus), :deep(.el-button--primary.is-plain):hover{
						// border-color:#fff!important;
						color: #409eff !important;
					}
				}
			}
			.file-cont{
				height: 100%;
				position: relative;
				.iconshuaxin1{
					position: absolute;
					top:10px;
					right:0px;
					padding:2px;
				}
				.title{
					height: 40px;
					line-height: 40px;
					display:flex;
					flex-direction:row;
					justify-content: space-between;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color:inherit;
					.truncate{
						flex:1;
						overflow: hidden;
						font-size: 14px;
					}
					.color-icon{
						width: 20px;
						height: 20px;
						position: relative;
						top: 4px;
					}
					
				}
				.viewActive{
					background-color:rgb(229, 240, 254);
					border-radius: 2px;
					span{
						color:#666;
					}
				}
				.list{
					display:flex;
					flex-direction:row;
					justify-content: space-between;
					height: auto;
					width: calc(100% - 10px);
					padding:5px;
					line-height:32px;
					overflow: hidden;
					color:inherit;
				}
				.list:hover{
					opacity: 0.8;
				}
				.list-opacity{
					span,i{
						opacity: 0.6;
					}
				}
				.carousel{
					height: 150px;
					background:#DDE1E6;
					width: calc(100% + 20px);
					position: relative;
					margin-top:10px;
					left:-10px;
				}
			}
		}
		.detail-tab{
			height: 24px;
			line-height: 24px;
			background:transparent;
			position: relative;
			text-align: left;
			.tab-item{
				margin-right:10px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 400;
			}
			.tab-none{
				display: none;
			}
			.active{
				position:relative;
				font-weight: bold;
				color:@theme;
				border-bottom:3px solid @theme;
				text-align: center;
				padding-bottom: 5px;
			}
			.active::after{
				content: "";
				height: 2px;
				width: 16px;
				position: absolute;
				left:50%;
				margin-left:-8px;
				bottom: -9px;
			}	
			.commit-btn{
				padding:0px 8px;
				background-color:@theme;
				border-radius: 3px;
				font-size: 12px;
				color:#fff;
				position:absolute;
				right:0px;
				top:1px;
				height: 22px;
        line-height: 20px;
			}
			
		}
	}
</style>
