<!--
    @name: widget-textarea
    @description：widget-textarea
    @author: ZengWei
    @date: 2022-03-25 09:28
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ element.value ? element.value : '-' }}</div>
    <el-input
      v-else
      v-model="element.value"
      :rows="3"
      type="textarea"
      :placeholder="element.config.placeholder"
      :maxlength="element.config.maxlength"
      :disabled="disabled || element.config.disabled"
      :show-word-limit="showWordLimit"
    ></el-input>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetTextarea",
  components: {},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    showWordLimit() {
      if (this.element.config.maxlength) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {},
}
</script>

<style scoped>
</style>
