/*
 * @Description:
 * @Author: luocheng
 * @Date: 2021-09-13 11:40:21
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-13 10:54:32
 */
import Vue from 'vue'
import Vuex from 'vuex'
import configData from './configData';
import project from './project';
import events from './events';
import toggleArchi from './toggleArchi';
import uiControl from './uiControl';
import toDo from './toDo'
import application from './application';
import screen from './screen';
import contextMenu from './contextMenu';
import customStatus from './customStatus';
import workOrder from "@/store/workOrder";
import systemConfig from '@/store/systemConfig';
import task from './task'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...configData.state,
    ...project.state,
    ...events.state,
    ...toggleArchi.state,
    ...uiControl.state,
    ...application.state,
    ...screen.state,
    ...contextMenu.state,
    ...customStatus.state,
		...workOrder.state,
    ...systemConfig.state,
    // 当前项目环境
    projectEnvironment: '',
    // 是否需要获取历史架构
    needHistoryArchi: false,
    formInstance: null, // 解決新版表单与流程操作同级没有provide
  },
  mutations: {
    ...configData.mutations,
    ...project.mutations,
    ...events.mutations,
    ...toggleArchi.mutations,
    ...uiControl.mutations,
    ...application.mutations,
    ...screen.mutations,
    ...contextMenu.mutations,
    ...customStatus.mutations,
		...workOrder.mutations,
    ...systemConfig.mutations,
    // 当前项目
    setProjectEnvironment(state, envName) {
      state.projectEnvironment = envName;
    },
    // 修改获取历史架构状态
    getHistoryArchi(state, status) {
      state.needHistoryArchi = status;
      console.log('status修改获取历史架构状态', status)
    },
    setFormInstance(state, instance){
      state.formInstance = instance
    }
  },
  actions: {
  },
  modules: {
    toDo,
    task
  },
  getters: {
    ...uiControl.getter,
		...workOrder.getters,
  }
});
