<!--
 * @Author: ttheitao
 * @Date: 2023-10-11 11:39:42
 * @Description: some description
 * @LastEditors: ttheitao
 * @LastEditTime: 2024-03-07 13:51:34
-->
<template>
  <div :style="fcStyle">
    <el-select v-if="cloneConfig?.type === fcType.SELECT" class="bimcc-spreadsheet-select" v-model="value"
      :placeholder="selectPlaceholder" :style="compStyle" @change="handleValueChange"
      :multiple="cloneConfig?.multiple ?? false" :filterable="cloneConfig?.filterable ?? false" :collapse-tags="true"
      clearable>
      <el-option v-for="(item, index) in cloneConfig.options" :key="index"
        :label="cloneConfig.source === 'dwh' ? item[cloneConfig.meta.label_field] : item.label"
        :value="cloneConfig.source === 'dwh' ? item[cloneConfig.meta.value_field] : item.value">
      </el-option>
    </el-select>

    <el-datepicker v-if="cloneConfig?.type === fcType.DATE_PICKER" class="bimcc-spreadsheet-datepicker" :style="compStyle"
      v-model="value" :format="cloneConfig.format ?? 'yyyy-MM-dd'" :value-format="cloneConfig.valueFormat ?? 'HH:mm:ss'"
      @change="handleValueChange"></el-datepicker>

    <el-time-picker v-if="cloneConfig?.type === fcType.TIME_PICKER" class="bimcc-spreadsheet-timepicker"
      :style="compStyle" v-model="value" placeholder="选择时间" :value-format="cloneConfig.valueFormat ?? 'yyyy-MM-dd'"
      @change="handleValueChange">
    </el-time-picker>

    <select-user v-show="cloneConfig?.type === fcType.USER" key="bimcc-spreadsheet-selectuser"
      :show-list="cloneConfig?.type === fcType.USER && fcValue ? fcValue : []" :tableClearVisible="true"
      class="bimcc-spreadsheet-selectuser" @on-select-change="handleUserValueChange"></select-user>
  </div>
</template>

<script>
import { Select, DatePicker, TimePicker } from 'element-ui';
import { formatDate } from 'element-ui/src/utils/date-util';
import { deepCopy } from '../../../utils/tools';
import Type from '../DataVerifyPanel/js/Type';
import { Utils } from 'bimcc-spreadsheet';
import SelectUser from '@/custom-component/form/formParser/selectUser';

export default {
  components: {
    'el-select': Select,
    'el-datepicker': DatePicker,
    'el-time-picker': TimePicker,
    'select-user': SelectUser,
  },
  props: {
    config: {
      type: Object,
      require: true,
    },
    fcValue: {},
    dataStore: {
      tpye: Object,
      require: true,
    }
  },
  data() {
    return {
      cloneConfig: null,
      fcStyle: {
        display: 'none',
        position: 'absolute',
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        zIndex: '5000',
      },
      value: "",
      compStyle: {
        width: '100%',
        height: '100%',
      },
      fcType: Type,
      isRender: false,
      selectPlaceholder: '请选择',
    }
  },
  watch: {
    config: {
      handler(newVal) {
        if (newVal.config?.type) {
          this.isRender = true;
          this.cloneConfig = deepCopy(this.config.config);
          this.fcStyle.left = `${this.config.x}px`;
          this.fcStyle.top = `${this.config.y}px`;
          this.fcStyle.width = `${this.config.width + 2}px`;
          this.fcStyle.height = `${this.config.height + 2}px`;
          this.value = deepCopy(this.fcValue);

          this.updateDynamicConfig(this.cloneConfig);
          this.fcStyle.display = 'block';
        } else {
          this.fcStyle.display = 'none';
          this.isRender = false;
          this.cloneConfig = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleValueChange(value) {
      if (!this.isRender) return;

      if (Utils.isDefine(value)) {
        this.$emit('change', { v: value });
      } else {
        this.$emit('change', { v: '', m: '' });
      }
    },
    handleUserValueChange(value) {
      if (this.cloneConfig?.type !== Type.USER) return;
      this.handleValueChange(value);
    },
    getFcValue(value, config) {
      let res = {
        v: deepCopy(value)
      };
      let label = "";

      // for user
      let users = [];
      let names = [];

      // for select
      let labelKey = '';
      let valueKey = '';
      let selectValue = [];

      // for user
      let userValue = [];

      config = config ?? this.cloneConfig;
      this.updateDynamicConfig(config);

      switch (config.type) {
        case Type.SELECT:
          labelKey = config.source === 'dwh' ? config.meta.label_field : 'label';
          valueKey = config.source === 'dwh' ? config.meta.value_field : 'value';
          if (config.multiple) {
            selectValue = Array.isArray(value) ? value : [];
            config.options.forEach(option => {
              if (selectValue.includes(option[valueKey])) {
                label += `,${option[labelKey]}`;
              }
            });

            label = label.replace(',', '');
          } else {
            const one = config.options.find((option) => {
              return option[valueKey] === value;
            });

            label = one?.[labelKey] ?? '';
          }
          res.m = label;
          break;
        case Type.DATE_PICKER:
          res.m = formatDate(res.v, config.format);
          if (res.v == '0000-00-00 00:00:00') {
            res.v = '';
          }
          break;
        case Type.TIME_PICKER:
          res.m = value;
          break;
        case Type.USER:
          userValue = Array.isArray(value) ? value : [];
          userValue.forEach(element => {
            users.push({ user_id: element.user_id, section_id: element.section_id, name: element.name });
            names.push(element.name);
          });
          res.v = users;
          res.m = names.join(',');
          break;
      }

      return res;
    },
    updateDynamicConfig(config) {
      let storeKey = '';
      switch (config.type) {
        case Type.SELECT:
          storeKey = `${config.meta.object_uuid}_${config.meta.view_uuid}`;
          if (config.source === 'dwh') {
            if (this.dataStore[storeKey] === undefined) {
              config.options = [];
              this.placeholder == '请求远程数据中...';
            } else {
              config.options = this.dataStore[storeKey].options;
            }
          }

          if (config.multiple && !Array.isArray(this.value)) {
            this.value = [];
          }
          break;
      }
    }
  }
}
</script>

<style lang="less">
.bimcc-spreadsheet-select,
.bimcc-spreadsheet-datepicker,
.bimcc-spreadsheet-timepicker {
  .el-input {
    height: 100%;
    line-height: 100%;

    input {
      padding-left: 3px;
    }

    .el-input__icon {
      line-height: 100%;
    }
  }

  .el-input__icon {
    line-height: 100%;
  }

  .el-input__inner {
    height: 100% !important;
    line-height: 100%;
  }

  .el-select__tags {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.bimcc-spreadsheet-selectuser {
  max-width: 100% !important;

  .new-select-style {
    height: 100%;
    width: 100%;

    .el-input {

      .el-input__suffix,
      .el-input__prefix,
      .show-value {
        display: none;
      }

      // .show-value {
      //   height: 100% !important;
      //   line-height: 100% !important;
      //   padding: 0 3px;
      //   display: flex;
      //   align-items: center;
      // }
    }
  }
}
</style>