/*
 * @Author: ttheitao
 * @Date: 2023-10-10 13:46:59
 * @Description: 数据验证类型（组件类型）
 * @LastEditors: ttheitao
 * @LastEditTime: 2023-10-10 13:55:07
 */
export default {
  IMAGE: 8, // 图片
  RATE: 9, // 评分
  CHECKBOX: 3, // 复选框
  SELECT: 51, // 下拉选择
  CASCADER_PICKER: 52, // 级联选择
  DATE_PICKER: 53, // 日期选择
  TIME_PICKER: 54, // 时间选择
  USER: 55, // 用户
  ARCHI: 56, // 组织架构
}