<!--
 * @Description: 基础容器
 * @Author: luocheng
 * @Date: 2021-08-18 13:48:12
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2024-01-12 22:29:50
-->
<template>
  <div
    class="common-container"
    :data-id="element.id"
    v-loading="statusConfig?.userLoading ? loading: false"
  >
	<template v-for="(child,index) in element.children">
		<ShapeBox
			:key="index"
			:index="index"
			:defaultStyle="child.style || {}"
			:element="child"
			v-if="
				child.statusConfig &&
				child.statusConfig.isShow
			"
			:style="getBoxStyle(child.style, child.specialStyle.fixedSize, child)"
		>
			<ComponentBox
				style="position: inherit;"
				:isPreview="isPreview"
				:containerData="renderData"
				:fullData="fullData"
				@click.native.stop="onClickBox(child, 'click')"
				@dblclick.native.stop="onDblClickBox(child, 'dblclick')"
				@mouseleave.native.stop="onClickBox(child, 'mouseleave')"
				@mouseenter.native.stop="onClickBox(child, 'mouseenter')"
				:style="getComStyle(child.style,child.specialStyle.fixedSize, child)"
				:element="child"
				:pageId="pageId"
				:isGroup="isGroup"
				:groupComponents="groupComponents"
				:defaultTableSelectData="defaultTableSelectData"
				:componentList="componentList"
			>
			</ComponentBox>
		</ShapeBox>	
	</template>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { getStyle } from '@/utils/style';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { getComponentById, getQueryValue, initParams, doEEActionHandle, judgingEventLogic, getStatusVal, getLinkByTemplate, generateQuery } from '@/utils/tools';
import mixin from './mixins';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js'

export default {
  name: 'CommonSignContainer',
  mixins: [mixin, databaseTriggerDebug],
  props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
    // 是否为预览
    isPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    // 弹窗页面配置的ID
    pageId: {
      type: Number,
      required: false
    },
		scaleHeight: {
			type: Number,
			default: 1
		},
		scaleWidth: {
			type: Number,
			default: 1
		},
		ratio: {
			type: Number,
			default: 1
		}
  },
  components: {
  },
  data() {
    return {
      // 渲染数据
      renderData: [],
      // 完全渲染数据未格式化
      fullData: null,
      loading: false,
      // 事件timer
      timer: null,
      isInit: true
    };
  },
	inject: ['EDITOR_pageUUID'],
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus', 'signComponentData']),
		// 父组件配置时候的尺寸位置信息
		childPositionInfo() {
			return (
				this.element?.childPositionInfo || {
					top: 0,
					left: 0,
					right: 0,
					bottom: 0
				}
			);
		},
    // 值来源
    valueOrigin() {
      return this.element && this.element.valueOrign;
    }
  },
  created() {
    this.doInterface();
  },
  watch: {
    valueOrigin(newV) {
      // 数据源
      if (newV === 'database') {
        const { objectData, viewData } = this.element.database;
				if (objectData && viewData) {
					this.getRenderData(this.element?.database || {});
				}
      }
    }
  },
  mounted() {
    // 配置关联参数的容器才需要监听
     const databaseTrigger = {
      [this.element.id]: data => {
        if (data.originComponent === this.element.id) return;
        let { paramsConfig, requestType } = this.element.database;
        if (!Array.isArray(paramsConfig)) return;
        // 是否当前组件响应
        let isTarget = false;
        let configItem = null;
        let params = {};
        for (let i = 0; i < paramsConfig.length; i++) {
          if (
            (paramsConfig[i].componentId === data.componentId && this.isSameAction(requestType, data.action)) ||
            (data.componentId.indexOf('CommonTree') > -1 && data.parentId == this.element.id && data.isSearch) ||
            (data.componentId.indexOf('CommonForm') > -1 && data.isSearch)
          ) {
            isTarget = true;
            configItem = paramsConfig[i];
            params = {
              ...params,
              ...this.getParams(configItem, data)
            };
          }
        }
       
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        this._startDataDebug();
        const {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
					this.element
        );
        if (!canPost) {
          this._failDataDebug('参数必填校验未通过');
          return;
        }
        if (this.element?.database?.mapping === 'interface') {
          this.getDataByUrl(this.element?.database, param, search, data.isInit);
        } else {
          this.getRenderData(this.element.database, param, search, data.isInit);
        }
      }
    };
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
		/**
	 * @desc: 获取组件盒子的样式
	 * @param {Object} style 组件样式
	 * @return {Object} 格式化后样式对象
	 */
    getBoxStyle(styleObj, fixedSize, item) {
			let result = {};
			const  coefficientHeight = (+fixedSize || this.scaleHeight);
			const  coefficientWidth = (+fixedSize || this.scaleWidth);
			['width', 'height', 'top', 'left', 'rotate'].forEach(ele => {
				let val = styleObj[ele] || 0;
				if (ele !== 'rotate') {
					if (ele === 'top') {
						if (!isNaN(+val)) {
							val = `${((val - (this.childPositionInfo.top)) * coefficientHeight).toFixed(2)}px`;
						}
					} else if (ele === 'left') {
						if (!isNaN(+val)) {
							val = `${((val - this.childPositionInfo.left) * coefficientWidth).toFixed(2)}px`;
						}
					} else if (ele === 'height') {
						if (!isNaN(+val)) {
							val = `${(val * coefficientHeight).toFixed(2)}px`;
						}
					} else {
						if (!isNaN(+val)) {
							val = `${(val * coefficientWidth).toFixed(2)}px`;
						}
					}
					result[ele] = val;
				} else {
					result.transform = styleObj[ele] && styleObj[ele] !== '0' ? 'rotate(' + styleObj[ele] + 'deg)' : '';
				}
			});
			if (item.specialStyle?.useZIndex) {
				result = {
					...result,
					zIndex: item.specialStyle?.zIndex ?? 'inherit'
				}
			}
			return result;
		},
		/**
	 * @desc: 根据图层的样式对象获取可渲染的组件样式
	 * @param {Object/null} styleObj 样式对象
	 * @return {Object} 壳渲染组件
	 */
		getComStyle(styleObj) {
			if (!styleObj || !styleObj.backgroundColor) return {};
			// TIPS 对background进行忽略是因为会与backgroundColor冲突，且background暂时没有使用；
			let excludesArr = [
				'top',
				'left',
				'rotate',
				'height',
				'width',
				'fontSize',
				'lineHeight',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'paddingTop',
				'paddingBottom',
				'paddingLeft',
				'paddingRight',
				'borderRadius',
				'borderWidth'
			];
			const heightArr = ['height', 'lineHeight', 'borderWidth'];
			const widthArr = [
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'paddingTop',
				'paddingBottom',
				'paddingLeft',
				'paddingRight',
				'borderRadius',
				'borderWidth'
			];
			let styleResult = getStyle(styleObj, excludesArr)
			excludesArr.forEach(ele => {
				let val = styleObj[ele] || 0;
				if (ele !== 'rotate') {
					if (heightArr.includes(ele)) {
						val = `${val * this.scaleHeight}px`;
					} else if (widthArr.includes(ele)) {
						val = `${val * this.scaleWidth}px`;
					} else if (ele === 'fontSize') {
						val = `${val * this.scaleWidth}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${val * this.scaleWidth}px`;
						}
					}
				} else {
					val = styleObj[ele] && styleObj[ele] !== '0' ? 'rotate(' + styleObj[ele] + 'deg)' : '';
				}
				styleResult = {
					...styleResult,
					[ele]: val
				};
			});
			return {
				...styleResult,
				background: `${styleResult.backgroundColor || styleResult.background}!important`,
				left:0,
				top:0,
			};
		},
    /**
     * @description: 执行请求
     */
    doInterface() {
      if (!this.interfaceFinished) return;
      this.interfaceFinished = false;
      const { objectData, viewData, mapping } = this.element?.database;
      if (mapping === 'interface') {
        if (!this.element?.database?.interfaceConfig) return;
        // 接口请求
        this._startDataDebug();
        const {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
					this.element
        );
        if (!canPost) {
          this._failDataDebug('参数必填校验未通过');
          return;
        }
        this.getDataByUrl(this.element?.database, param, search);
      } else {
        // 数仓相关
        if (objectData && viewData) {
          this._startDataDebug();
          const {
            search = [],
            param = {},
            canPost
          } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.subComponentData,
            this.groupComponents,
						this.element
          );
          if (!canPost) {
            this._failDataDebug('参数必填校验未通过');
            return;
          }
          this.getRenderData(this.element.database, param, search);
        } else if (this.element.database?.requestType === 'moreAction') {
          this._startDataDebug();
          const { statisticalConfig } = this.element.database;
          if (!Array.isArray(statisticalConfig) || !statisticalConfig.length) {
            this._failDataDebug('请求配置错误');
            return;
          }
          this.getRenderData(this.element.database, {}, []);
        }
      }
    },
    /**
     * @description: 数据刷新后更新标记位置
     */
    updateSign(data) {
      this.$emit('updateSignData', data);
    },


		/**
		 * @description: 双击事件
		 * @param {Object} element
		 */
		onDblClickBox(element) {
			if (!element.events || !element.events.dblclick || !element.events.dblclick.effects) {
				return;
			}
			window.$EditorDebug.startEvent(element, 'dblclick');
			if (this.timer) {
				clearTimeout(this.timer);
			}
			const comEvents = element?.events?.dblclick || {};
			const { pattern, eventList = [], specialEventList = [] } = comEvents;
			if (!pattern) {
				this.doActionItem(comEvents, element);
			} else {
				const result = pattern === 'special' ? specialEventList : eventList;
				for (let i = 0; i < result.length; i++) {
					this.doActionItem(result[i], element);
				}
			}
		},
		/**
		 * @description: 触发单个操作
		 * @param {Object} item 单个操作配置
		 */
		doActionItem(eventConfig, element) {
			console.log(9999)
			const { actionType, linkType, queryList = [], linkUrl = '', pageId, effects, linkTemplate } = eventConfig;
			if (actionType === 'pageStatus') {
				// 修改页面状态
				this.updatePageStatus(eventConfig.stateMachine || []);
			} else if (actionType === 'jumpPage') {
        const query = {};
        queryList.forEach(queryItem => {
          let component = getComponentById(this.componentData, queryItem.componentId);
          this.$set(query, queryItem.key, component?.resolveData?.[queryItem.feild]);
        });
        const queryStr = generateQuery(query);
				if (linkType === 'projectPage') {
					this.$router.push({
						path: pageId,
						query
					});
					window.$EditorDebug.jumpPage(1, pageId, query);
					return;
				} else if (linkType === 'outPage') {
					window.$EditorDebug.jumpPage(2, linkUrl);
					window.open(linkUrl);
				} else if (linkType === 'custom') {
					// if (!this.mapData?.renderData) return;
          const customLink = getLinkByTemplate(linkTemplate, this.mapData?.renderData || {})
					window.$EditorDebug.jumpPage(3, `${customLink}${queryStr}`);
          window.open(`${customLink}${queryStr}`);
				}
			} else if (actionType === 'eeAction') {
				// 触发后端事件 暂未处理
				// console.log(ele, '0000001111111111', output);
				// this.doEEAction(element, output, type);
				// 搜集参数
				this.doEEAction(eventConfig, element);
			} else {
				console.log(effects,'effects')
				effects.forEach(ele => {
					this.$store.commit('triggerEvents', {
						config: ele,
						element: this.element,
						EDITOR_pageUUID: this.EDITOR_pageUUID
					});
				});
			}
			// 页面行为
			const { behaviors = [] } = eventConfig;
			behaviors.forEach(behavior => {
				if (behaviors.length === 1) {
          const { component, list } = behaviors[0];
          if (!component) {
            return;
          }
          if (list.length === 1) {
            const behaviorsList = list[0].behaviors;
            if (!behaviorsList.length) {
              return;
            }
          }
        }
				if (behavior.component) {
					this.$store.commit('triggerEvents', {
						config: {
							behavior,
							isBehavior: true
						},
						element,
						EDITOR_pageUUID: this.EDITOR_pageUUID
					});
				}
			});
		},
		/**
		 * @description: 触发后端事件
		 * @return {*}
		 */
    doEEAction(ele, element) {
			// 触发后端事件
			window.$EditorDebug.startEEAction(element);
			const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
			if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
				this.$message.error('事件配置错误！');
				window.$EditorDebug.failEEAction(element);
				return false;
			}
			this.loading = true;
			const sourceParams = this.getBindParams(element);
			// 蓝图
			if (eeType === 'blueprint') {
				dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						window.$EditorDebug.successEEAction(element, `/api${interfaceUrl}`);
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				}).catch(() => {
					this.loading = false;
					window.$EditorDebug.errorEEAction(element, `/api${interfaceUrl}`);
				})
				return true;
			}
			// 数仓
			const data_id = sourceParams?.data_id;
			dataInterface({
				__method_name__: 'customEventCall',
				object_uuid: objectUUID,
				view_uuid: viewUUID,
				...sourceParams,
				data_id, // 参数配置
				event: eventName
			})
				.then(res => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						window.$EditorDebug.successEEAction(element, eventName);
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
					window.$EditorDebug.errorEEAction(element, eventName);
				});
			return true;
		},
    /**
     * @desc: 触发点击事件
     * @param {Object} item 点击的组件(可能需要兼容)
     * 触发方式实质就是在改变指定的组件状态
     * 目前覆盖点击事件
     */
    async onClickBox(element, eventType) {
			if(eventType === 'click'){
				this.$emit('click-box',this.element, element)
				eventBus.$emit('signResolve',this.element)
			}

			if (!element.events || !element.events[eventType] || !element.events[eventType].effects) {
				return;
			}
			window.$EditorDebug.startEvent(element, eventType);
			if (this.timer) {
				clearTimeout(this.timer);
			}
			const comEvents = element?.events?.[eventType] || {};
			const { pattern, eventList = [], specialEventList = [] } = comEvents;
			if (element.events.dblclick) {
				this.timer = setTimeout(() => {
					if (!pattern) {
						this.doActionItem(comEvents, element);
					} else {
						const result = pattern === 'special' ? specialEventList : eventList;
						result.forEach(ele => {
							this.doActionItem(ele, element);
						});
					}
				}, 300);
			} else {
				if (!pattern) {
					this.doActionItem(comEvents, element);
				} else {
					const result = pattern === 'special' ? specialEventList : eventList;
					for (let i = 0; i < result.length; i++) {
						this.doActionItem(result[i], element);
					}
				}
			}
    },
    /**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
    
    /**
     * @description: 根据接口获取数据
     */
    getDataByUrl(database, param = {}, search = [], isInit = false) {
      if (!database) {
        this._failDataDebug('未配置请求');
        return;
      }
      const { interfaceConfig } = database;
      if (!interfaceConfig) return;
      //  method = 'POST', action 暂未启用
      const { url } = interfaceConfig;
       // 注入的参数
       let outParams = {};
      if (this.element.database.userOutParams) {
        outParams = sessionStorage.getItem(
          `dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
        );
        outParams = outParams ? JSON.parse(outParams) : {};
      }
      let requestUrl = `${url}`;
      if (url && url.indexOf('http') === -1) {
        requestUrl = `/api${url}`;
      }
      const content = {
        search,
        ...param,
        ...outParams
      };
      dataInterface(content, requestUrl).then(res => {
        this.formatResData(res, database, isInit, outParams);
        this.loading = false;
        this.interfaceFinished = true;
        this._successDataDebug({
          url: requestUrl,
          content,
          res
        });
      }).catch(err => {
        console.log(err);
        this.loading = false;
        this.interfaceFinished = true;
        this._errorDataDebug({
          url: requestUrl,
          content,
          err
        });
      })
    },
    /**
     * @desc: 获取数据渲染时候的数据
     */
    getRenderData(database, params, search = []) {
      this.loading = true;
      // 注入的参数
      let outParams = {};
      if (this.element.database.userOutParams) {
        outParams = sessionStorage.getItem(
          `dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
        );
        outParams = outParams ? JSON.parse(outParams) : {};
      }
      // 请求方式  dataList dataInfo updateData createData deleteData relationList moreAction
      if (!database.objectData) {
        this.loading = false;
        this.interfaceFinished = true;
        this._failDataDebug('请求配置数据对象为空');
        return;
      }
      if (!database.viewData && !database.relationData) {
        this.loading = false;
        this.interfaceFinished = true;
        this._failDataDebug('请求配置视图为空');
        return;
      }
      let __method_name__ = database.requestType;
      const mapping = database.mapping;
      let paramObj = null;
      if (mapping === 'object') {
        paramObj = {
          __method_name__,
          object_uuid: database.objectData.uuid,
          view_uuid: database.viewData.uuid,
          search,
          ...params,
          ...outParams
        };
      } else if (mapping === 'relation') {
        // 关联关系请求方式，后端方面基本已废弃（复杂度）
        __method_name__ = 'relationList';
        paramObj = {
          __method_name__,
          object_uuid: database.objectData.uuid,
          relationship_uuid: database.relationData.uuid,
          search,
          ...params,
          ...outParams
        };
      }
      //标记取值详情ID
      if (__method_name__ === 'dataInfo' && (this.element.id.includes('-view') || this.element.id.includes('-page'))) {
        const arr = this.element.id.split('-');
        const idLastIndex = arr.findIndex(v => v.includes('view') || v.includes('page'));
        if (idLastIndex !== arr.length - 1) {
          paramObj.data_id = arr[idLastIndex + 1];
        }
      }
      //标记取值详情ID结束
      dataInterface(paramObj)
        .then(res => {
          this.formatResData(res, database)
          this.loading = false;
          this.interfaceFinished = true;
          this._successDataDebug({
            url: '',
            content: paramObj,
            res
          });
        })
        .catch(err => {
          console.log(err, '-----err');
          this.loading = false;
          this.interfaceFinished = true;
          this._successDataDebug({
            url: '',
            content: paramObj,
            err
          });
        });
    },
    /**
     * @description: 处理容器数据
     */
    /**
     * @description: 处理请求数据
     * @param {Object/null} res
     */
    formatResData(res, database, isInit, outParams) {
      if (!res || Object.prototype.toString.call(res) !== '[object Object]') return;
      if (res.status !== 200) return;
      const { mapping = '', requestType = '' } = database;
      let metadata = {};
      if (['dataList', 'relationList'].includes(requestType)) {
        // 列表
        this.renderData = this.getListFromRes(res) || [];
      } else if (requestType === 'dataInfo') {
        // 详情
        this.renderData = res.data.data || {};
      }
      if (mapping === 'interface') {
        this.renderData = res.data.data || {};
      }
      this.element.containerData = this.renderData;
			// if(!this.element.resolveData){
				// 暴露单一对象
				if (this.statusConfig?.resolveObject && Array.isArray(this.renderData)) {
					this.element.resolveData = this.renderData?.[0];
				} else {
					this.element.resolveData = this.renderData;
				}
				this.$store.commit('updatePageCustomStatus', {
					origin: this.element,
					resolveData: this.element.resolveData
				});
			// }
			
      setTimeout(() => {
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          originComponent: this.element.id,
          isSearch: true,
          action: 'any',
          output: this.renderData
        });
      }, 0)
      // 完全数据
      this.fullData = res.data.data || {};
      const fieldList = [];
      if (mapping === 'interface') {
        let dataObj = null;
        if (Array.isArray(this.renderData)) {
          dataObj = this.renderData[0];
        } else {
          dataObj = this.renderData;
        }
        if (Object.prototype.toString.call(dataObj) === '[object Object]') {
          for (let key in dataObj) {
            metadata[key] = key;
          }
        }
      } else {
        // 数仓直接使用  metadata  
        metadata = res.data?.metadata || {};
      }
      // 保存容器数据
      for (let key in metadata) {
        fieldList.push({
          name: metadata[key],
          uuid: key
        });
      }
			if(this.element.id.includes('-page') && this.element.pageUUid){
				//标记的数据修改
				const idArr = this.element.id.split('-')
				const searchId = `${idArr[0]}-${idArr[1]}`
				const component = getComponentById(this.componentData, searchId)
				if(component){
					this.$store.commit('modifyComponent', {
						component: {
							...component,
							containerData: this.renderData,
							fullData: this.fullData,
							metadata,
							database: {
								...this.element.database,
								fieldList
							}
						},
						containerId: null,
						isModify: true,
						pageUUID: this.element.id.includes('-page')? '': this.EDITOR_pageUUID,
						type:1
					});
				}
			}
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					containerData: this.renderData,
					fullData: this.fullData,
					metadata,
					database: {
						...this.element.database,
						fieldList
					}
				},
				containerId: null,
				isModify: true,
				pageUUID: this.element.id.includes('-page')? '': this.EDITOR_pageUUID,
				type:1
			});
      // 编辑器情况
      if (
        isInit &&
        this.element.database.userOutParams &&
        outParams &&
        this.$route.path === '/modify-page' &&
        !this.pageId
      ) {
        // 详情页面需要初始化
        this.$store.commit('initDetailsPage', {
          targetContainer: this.element,
          fullData: this.fullData,
          containerData: this.renderData,
          metadata
        });
      }
    },
    /**
     * @desc: 获取请求参数
     * @param {Array} config 请求参数配置
     */
    getParams(config, data) {
      if (!config || !data?.output) return {};
      let result = {};
      for (let key in data.output) {
        if (key === config.paramKey) {
          result[config.key] = data.output[key];
        }
      }
      return result;
    },
    /**
     * @desc: 根据请求返回获取列表结构
     * @param {Object} res 请求返回的数据
     */
    getListFromRes(res) {
      if (Array.isArray(res?.data)) {
        return res.data;
      }
      if (typeof res === 'object' && res.data) {
        return this.getListFromRes(res.data);
      }
      return [];
    },
    /**
     * @desc: 判断前后端操作标识是否已知
     * @param {String} beAction 后端标识
     * @param {String} feAction 前端标识
     * @return {Boolean}
     */
    isSameAction(beAction, feAction) {
      if (feAction === 'any') return true;
      const feToBe = {
        list: 'dataList',
        add: 'createData',
        detail: 'dataInfo',
        modify: 'updateDate',
        delete: 'deleteData'
      };
      return beAction === feAction || beAction === feToBe[feAction];
    },
    /**
     * @desc: 获取绑定参数
     */
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(comp) {
      if (!comp) return {};
      const sourceConfig = comp.sourceConfig || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = ''
        } = sourceConfig[i];
        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (result && Object.prototype.toString.call(result) === '[object Object]') {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(this.subComponentData, componentId);
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(this.groupComponents, componentId);
          }
					const result = sourceComponent?.resolveData?.[field] || '';
          this.$set(sourceParams, key, result);
        }
      }
      return sourceParams;
    },
  }
};
</script>

<style lang="less" scoped>
.common-container {
  height: 100%;
  width: 100%;
}
</style>
