<!--
 * @Author: Shiltin
 * @Date: 2021-10-22 11:43:57
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-07-22 15:06:55
 * @Description:
-->
<template>
  <div id="main" style="width: 100%; height: 100%">
    <div id="viewerDom" ref="viewerDom" style="width: 100%; height: 100%">
      <template v-if="point_marker && positions.length">
        <div
          v-for="(item, index) in positions"
          class="position"
          :key="index"
          :style="{
            left: item.x + 'px',
            top: item.y + 'px',
          }"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
let viewer = null;
export default {
  name: "Preview",
  props: {
    //回显得模型格式[{model_id:'XXXX',select:[111,1513]}]
    modelData: {
      type: [Array, String],
      default: () => [],
    },
    //模型和视点model/view
    chooseType: {
      type: String,
      default: "model",
    },
    appId: {
      type: String,
      default: "",
    },
    appSecret: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      baseObk: "",
      initState: "stop", //初始化状态
      selectData: [],
      viewList: [],
      positions: [],
      point_marker: false,
    };
  },
  methods: {
    //dbids是回显的构件id
    addModel(id, info) {
      if (this.initState == "stop") {
        //加载第一个模型
        this.initModel(id, info);
      } else {
        let timer = setInterval(() => {
          if (this.initState == "done") {
            clearInterval(timer);
            viewer.loadModel(id).then(() => {
              console.log("加载完成");
              //视图列表
              this.getViewStateList();
              if (info != undefined && viewer) {
                //回显构件选中或视点
                if (this.chooseType == "model") {
                  viewer.select(info);
                  this.selectData = this.selectData.concat(
                    JSON.parse(JSON.stringify(info))
                  );
                } else {
                  this.setViewState(info);
                }
              }
            });
          }
        }, 200);
      }
    },
    initModel(id, info) {
      this.initState = "loading";
      const appId = this.appId || process.env.VUE_APP_MODEL_APPID;
      const appSecret = this.appSecret || process.env.VUE_APP_MODEL_APPSECRET;
      viewer = new window.BIMCC.Viewer("viewerDom", {});
      viewer.init(id, { appId, appSecret });

      viewer.on("viewerLoaded", async () => {
        this.initState = "done";
        //回显构件选中或视点
        if (info != undefined) {
          if (this.chooseType == "model") {
            this.selectData = JSON.parse(JSON.stringify(info));
            setTimeout(async () => {
              await viewer.select(info);
              if (this.config.__config__.point_marker) {
                this.selectData = JSON.parse(JSON.stringify(info));
              }
            }, 2000);
          } else {
            this.setViewState(info);
          }
        }
        viewer.on("Viewer.cameraChange", async () => {
          if (this.positions.length) {
            this.positions.forEach(async (item, index) => {
              const data = await viewer.worldToScreen(item.xyz);
              this.positions[index] = { ...item, ...data };
            });
            this.positions = [...this.positions];
          }
        });
        //视图列表
        setTimeout(() => {
          this.getViewStateList();
        }, 500);
        if (this.modelData.find((item) => item.xyz) && this.point_marker) {
          this.positions = [];
          this.modelData.forEach(async (item) => {
            const data = await viewer.worldToScreen(item.xyz);
            this.positions.push({ ...item, ...data });
          });
        }
      });
      viewer.on("Viewer.inited", async () => {
        viewer.hideNavbar();
      });
      //获取xyz
      const callback = (xyz) => {
        if (this.selectData.length) {
          this.selectData.map(async (item) => {
            item.xyz = xyz;
          });
          if (
            this.selectData.filter(async (item) => item.xyz).length &&
            this.point_marker
          ) {
            this.positions = [];
            this.selectData
              .filter((item) => item.xyz)
              .forEach(async (item) => {
                const data = await viewer.worldToScreen(item.xyz);
                this.positions.push({ ...item, ...data });
              });
          }
        }
      };
      //获取xyz结束
      viewer.on("Viewer.onSelect", async (selections) => {
        if (selections?.length) {
          selections.map((item) => {
            return {
              ...item,
              select: item.dbids || [],
            };
          });
        }
        this.selectData = selections;
        console.log(selections, "---169---");
      });
      viewer.viewerClickEventCallBack.push(callback);
    },
    minusModel(id) {
      viewer.unloadModel(id).then(() => {
        //视图列表
        this.getViewStateList();
      });
    },
    //视图回显
    setViewState(data) {
      if (viewer != null) {
        viewer.viewViewState(data);
      }
    },
    //添加视点
    async setViews() {
      if (viewer != null && this.initState != "loading") {
        const res = await viewer.addViewState();
        if (res) {
          this.$emit("setViewData", res);
        }
      }
    },
    async getViewStateList() {
      if (viewer != null) {
        this.viewList = await viewer.getViewStateList();
      }
    },
    // 数据回显
    showModelData() {
      this.point_marker = this.config.__config__.point_marker;
      if (Array.isArray(this.modelData) && this.modelData?.length) {
        this.modelData.forEach((item, i) => {
          if (this.chooseType == "model") {
            if (item.dbids?.length) {
              this.addModel(item.model_id, [
                { model_id: item.model_id, dbids: item.dbids, xyz: item.xyz },
              ]);
            } else if (item.select?.length) {
              this.addModel(item.model_id, [
                { model_id: item.model_id, dbids: item.select, xyz: item.xyz },
              ]);
            } else {
              this.addModel(item.model_id);
            }
          } else {
            //只显示第一个视图，中控台可显示多个
            if (i == 0) {
              this.addModel(item.model_id, {
                id: item.model_id,
                data: item.data,
              });
            }
          }
        });
      }
    },
  },
  mounted() {
    this.showModelData();
  },
  watch: {
    modelData() {
      this.showModelData();
    },
  },
};
</script>
<style lang="less">
#viewerDom {
  position: relative;
  .position {
    position: absolute;
    width: 12px;
    height: 12px;
    background: brown;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
</style>
