<!--
 * @Description: 网盘顶部
 * @Author: luocheng
 * @Date: 2022-03-02 09:45:14
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-10-17 16:37:46
-->
<template>
  <div class="disk-header" :style="`padding-bottom: ${ samll ? 4 : 10 }px;`">
    <template v-if="!samll">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(path, index) in pathList" :key="index" @click.native="onPath(path)">
          <span class="label">{{ path.label }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <section class="actions" v-if="actionConfig">
        <FileUpload class="actions-item" @extra-files="onUploadSuccess"
          :btnName=" actionConfig.upload && actionConfig.upload.name || '上传文件'"
          :multiple="statusConfig.multiple || false"
          v-if="showAction('upload')"></FileUpload>
        <el-dropdown class="actions-item" trigger="click" placement="bottom"  v-if="showAction('create')">
          <el-button type="primary">
            <i class="iconfont iconwangpan-xinzeng1x"></i>
            {{ actionConfig.create && actionConfig.create.name }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button type="text" @click="showCreate = true">
                <i class="iconfont iconwenjianjia5" style="color: rgb(249, 194, 10)"></i>
                <span>文件夹</span>
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item v-if="useCreateLink">
              <el-button type="text" @click="showCreateLink = true">
                <i class="iconfont iconlianjie1" style="color: rgb(249, 194, 10)"></i>
                <span>链接</span>
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item v-if="usePlaceholder">
              <el-button type="text" @click="showCreatePlaceholder = true">
                <i class="iconfont iconzhanweifu" style="color: rgb(249, 194, 10)"></i>
                <span>占位符</span>
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="actions-item" v-if="showAction('plus')">
          <el-button type="default" style="height:36px;" @click="showPlusFile = true">
            {{  actionConfig.plus && actionConfig.plus.name }}
          </el-button>
        </div>
      </section>
      <section class="search">
        <!-- <el-input
          placeholder="搜索文件名称"
          suffix-icon="el-icon-search"
          clearable
          @change="onSearch"
          v-model="searchName">
        </el-input> -->
        <el-autocomplete
          ref='refAuto'
          v-model='searchName'
          placeholder="搜索文件名称"
          suffix-icon="el-icon-search"
          :fetch-suggestions="querySearch"
        >
          <template slot-scope="{ item }">
            <div @click="checkItem(item)" class='net-disk-search'>
              <i class="iconfont" :class="[getClass(item)]"></i>
              <span style="font-size: 14px;line-height:20px;">{{ item?.name }}</span>
            </div>
            
          </template>
        </el-autocomplete>
      </section>
      <section class="view-ways">
        <i class="iconfont"
          v-for="item in modelList" :key="item.type"
          :class="[item.class, activeModel === item.type ? 'active' : '']" @click="onChangeModel(item)">
        </i>
      </section>
    </template>
    <!-- 小窗模式 结构有区别 但是内部基本一致 -->
    <template v-else>
      <div class="upload-com">
        <FileUpload ref="uploadCom" class="actions-item" @extra-files="onUploadSuccess"
          :btnName=" actionConfig.upload && actionConfig.upload.name || '上传文件'"
          :multiple="statusConfig.multiple || false"
          v-if="showAction('upload')"></FileUpload>
      </div>
      <div class="header-top-warp">
        <el-select :value="classify" @input="handleUpdateClassify" class="classify-select">
          <el-option v-for="item in classifyOptions" :label="item.label" :value="item.value" :key="item.value">
            <div class="classify-select-option-item">
              <svg width="16" height="16">
                <use :xlink:href="'#' + item.icon"></use>
              </svg>
              <span>{{ item.label }}</span>
            </div>
          </el-option>
        </el-select>
        <el-dropdown trigger="click" placement="bottom"  v-if="showAction('create')">
          <span class="el-dropdown-link" v-if="showAction('create')">
            <i class="iconfont iconxinzeng2"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="showAction('upload')" @click.native.stop="handleUpload">
              <div class="actions-dropdown-item">
                <svg width="16" height="16">
                  <use xlink:href="#iconshangchuan1"></use>
                </svg>
                <span>上传文件</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item @click.native="showCreate = true">
              <div class="actions-dropdown-item">
                <svg width="16" height="16">
                  <use xlink:href="#iconwenjianjia5"></use>
                </svg>
                <span>文件夹</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item v-if="usePlaceholder" @click.native="showCreatePlaceholder = true">
              <div class="actions-dropdown-item">
                <svg width="16" height="16">
                  <use xlink:href="#iconzhanweifu"></use>
                </svg>
                <span>占位符</span>
              </div>
            </el-dropdown-item>
            <el-dropdown-item v-if="useCreateLink" @click.native="showCreateLink = true">
              <div class="actions-dropdown-item">
                <svg width="16" height="16">
                  <use xlink:href="#iconlianjie1"></use>
                </svg>
                <span>链接</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="header-search-wrap">
        <el-input
          placeholder="搜索"
          prefix-icon="el-icon-search"
          clearable
          @change="onSearch"
          v-model="searchName">
        </el-input>
      </div>
      <div class="header-bottom-wrap">
        <div class="breadcrumb-wrap">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(path, index) in pathList" :key="index" @click.native="onPath(path)">
              {{ path.label }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <span class="el-dropdown-link" @click.stop="onChangeModel(null)">
          <svg width="16" height="16">
            <use :xlink:href="activeModel === 'table' ? '#iconsuolvemoshi' : '#iconliebiaomoshi2'"></use>
          </svg>
        </span>
        <!-- 设计的下拉 但是产品要求改为直接切换 -->
        <!-- <el-dropdown trigger="click" placement="bottom">
          <span class="el-dropdown-link">
            <svg width="16" height="16">
              <use :xlink:href="activeModel === 'table' ? '#iconsuolvemoshi' : '#iconliebiaomoshi2'"></use>
            </svg>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in modelList" :key="item.type" @click.native="onChangeModel(item)">
              <div class="actions-dropdown-item">
                <svg width="16" height="16">
                  <use :xlink:href="'#' + item.class"></use>
                </svg>
                <span>{{ item.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </template>
    <!-- 添加文件 -->
    <PlusFile v-if="showPlusFile" v-model="showPlusFile"></PlusFile>
    <!-- 创建文件夹 -->
    <Rename v-model="showCreate" :type="'createFolder'" @nameResult="onCreateFold"></Rename>
    <!-- 创建链接 -->
    <LinkModel v-model="showCreateLink" :type="'create'" @submit="onCreateLink"/>
    <!-- 创建占位符 -->
    <CreatePlaceholder v-model="showCreatePlaceholder" :type="'createPlaceholder'"  @nameResult="onCreatePlaceholder"/>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem, Dropdown, DropdownItem, DropdownMenu,Autocomplete } from 'element-ui';
import FileUpload from './FileUpload';
import PlusFile from './PlusFile';
import Rename from './Rename';
import CreatePlaceholder from './CreatePlaceholder'
import LinkModel from './LinkModel'
import { judgingArchiAPermission } from '@/utils/tools';
import { dataInterface } from '@/apis/data';
import mixin from '../mixin';
import { initParams } from "@/utils/tools";
export default {
  name: 'DiskHeader',
  mixins: [mixin],
  props: {
    pathList: {
      type: Array,
      required: true,
      default: () => []
    },
    useCreateLink: {
      type: Boolean,
      default: false
    },
    newFolder: {
      type: Boolean,
      default: true
    },
    usePlaceholder: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'normal'
    },
    classify: {
      type: String,
      default: ''
    },
  },
  components: {
    'el-breadcrumb': Breadcrumb,
    'el-breadcrumb-item': BreadcrumbItem,
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    'el-autocomplete':Autocomplete,
    FileUpload,
    PlusFile,
    Rename,
    LinkModel,
    CreatePlaceholder
  },
  inject: ['element', 'getParentId'],
  data() {
    return {
      searchName: '', // 搜索名称
      showPlusFile: false, // 添加文件
      showCreate: false, // 新增文件夹
      showCreateLink: false, // 新增链接
      showCreatePlaceholder: false, // 新增链接
      // 显示模式
      activeModel: 'table',
      // 模式选项
      modelList: [
        {
          type: 'table',
          class: 'iconliebiaomoshi2',
          label: '列表模式'
        },
        {
          type: 'folder',
          class: 'iconsuolvemoshi',
          label: '缩略模式'
        }
      ],
      // 分类选项
      classifyOptions: [
        {
          label: '全部',
          value: '',
          icon: 'iconzhishiku4'
        },
        {
          label: '文档',
          value: 'doc',
          icon: 'iconword'
        },
        {
          label: '图片',
          value: 'img',
          icon: 'icontupianzhaopian'
        },
        {
          label: '视频',
          value: 'video',
          icon: 'iconshipin1'
        },
        {
          label: 'CAD',
          value: 'cad',
          icon: 'icontuzhi'
        },
        {
          label: '模型',
          value: 'model',
          icon: 'iconmoxing3'
        },
        {
          label: '其他',
          value: 'other',
          icon: 'icona-wenjianwendang'
        }
      ]
    }
  },
  computed: {
    actionConfig() {
      return this.element?.actionConfig;
    },
    database() {
      return this.element?.database;
    },
    parentId() {
      return this.getParentId();
    },
    statusConfig() {
      return this.element?.statusConfig;
    },
    samll() {
      return this.mode === 'samll';
    }
  },
  methods: {
    checkItem(item){
      this.$emit('checkItem', item)
    },
    /**
     * @desc: 图表类名
     * @param {Object} row 行数据
    */
    getClass(row) {
      const { type_id, ext } = row;
      if (+type_id === 1) return 'iconwenjianjia';
      if (+type_id === 3) return 'iconlianjie';
      if (+type_id === 4) return 'iconzhanweifu'
      if (ext === 'xlsx' || ext === 'xls') {
        return 'iconexcle1x'
      } else if (ext === 'doc' || ext === 'docx') {
        return 'iconword1x'
      } else if (ext === 'txt') {
        return 'icontxt1x'
      } else if (ext === 'pptx' || ext === 'ppt') {
        return 'iconppt1x'
      } else if (['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(ext)) {
        return 'iconzhaopian1x'
      } else if (ext === 'pdf') {
        return 'iconpdf1x'
      }
      return "iconother1x";
    },
    handleUpload() {
      if(this.$refs?.uploadCom) {
        // 这么做的原因是因为 放到下拉内 会导致上传列表显示异常
        this.$refs?.uploadCom?.$refs?.upload?.$refs?.['upload-inner']?.$el?.click();
      }
    },
    handleUpdateClassify(res) {
      this.$emit('updateClassify', res);
    },
    /**
     * @desc: 是否显示操作
     * @param {String} type 操作类型
     * @return {Boolean}
     */
    showAction(type) {
      const item = this.actionConfig[type];
      if (!item) return false;
      const { use, archiLimit = [], permission = [] } = item;
      if (!use) {
        return false;
      }
      return judgingArchiAPermission(archiLimit, permission);
    },
    /**
     * @desc: 搜索
     */
    onSearch() {
      this.$emit('filterFile', this.searchName);
    },
    /**
     * @desc: 筛选搜索功能
     */
    async querySearch(queryString, cb){
      if(queryString === ''){
        cb([])
        return;
      }
      const {
        param = {},
        search = [],
        canPost,
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.componentList || this.componentData,
        this.groupComponents
      );
      if (!canPost){
        return ;
      } 
      const { objectData, viewData } = this.database;
      const objectUUID = objectData.uuid;
      const defaultView = viewData.uuid;
      dataInterface({
        __method_name__: "dataList",
        object_uuid: objectUUID,
        view_uuid: defaultView,
        ...param,
        search: [
          ...search,
          {
            code: "name",
            ruleType: "like",
            value: queryString,
          },
        ],
        transcode: 0,
      })
        .then((res) => {
          if (res.status === 200 && res.data?.code === 200) {
            const fileList = res?.data?.data || [];
            let files = [],
              folder = [],
              other = [];
            fileList.forEach((ele) => {
              if (+ele.type_id === 1) {
                folder.push(ele);
              } else if (+ele.type_id === 2) {
                files.push(ele);
              } else {
                other.push(ele);
              }
            });
            cb(folder.concat(files).concat(other)) ;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, "---");
          this.$message.error("获取文件失败！");
          this.loading = false;
        });
    },
    /**
     * @desc: 上传成功
     * @param {Object} data 上传成功的返回数据
     */
    onUploadSuccess(data) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.upload?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'createData',
        parent_id: this.parentId || 0,
        name: data.name,
        path: data.filepath,
        hash: data.file_md5,
        file_size: data.filesize,
        ext: data.fileext,
        type_id: 2,
        full_data: data,
        ...param
      });
    },
    /**
     * @desc: 切换模式
     * @param {Object} model 选中模式
     */
    onChangeModel(model) {
      if(!model) {
        this.activeModel = this.activeModel === 'table' ? 'folder' : 'table'
      } else {
        this.activeModel = model.type;
      }
      this.$emit('changeModel', this.activeModel);
    },
    /**
     * @desc: 点击面包屑
     */
    onPath(item) {
      this.$emit('changeBreadcrumb', item);
    },
    /**
     * @desc: 创建文件提交
     */
    onCreateFold(name) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.create?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'createData',
        name,
        type_id: 1,
        ...param
      });
    },
    /**
     * @description: 创建链接
     * @param {Object} data 创建数据
     */
    onCreateLink(data) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.create?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'createData',
        ...data,
        type_id: 3,
        ...param
      });
    },
    onCreatePlaceholder(name) {
      const { param = [], canPost = false } = this.getQuery(this.actionConfig?.create?.paramsConfig || []);
      if (!canPost) {
        return false;
      }
      this.modifyData({
        __method_name__: 'createData',
        name,
        type_id: 4,
        ...param
      });
    },
    /**
     * @desc: 创建数据或编辑数据
     * @param {Object} params 参数
     */
    modifyData(params) {
      // TODO NET
      const { objectData } = this.database;
      if (!objectData) {
        this.$message.error('操作失败！');
        return;
      }
      const loading = this.$loading();
      dataInterface({
        ...params,
        object_uuid: objectData.uuid,
        parent_id: this.parentId || 0,
      }).then(res => {
        if (res.status === 200 && res.data.code === 200) {
          this.$message.success('操作成功！');
          this.showCreate = false;
          this.showCreateLink = false;
          this.showCreatePlaceholder = false;
          this.$emit('updateList');
        }
        loading.close();
      }).catch(err => {
        console.log(err);
        this.$message.error('操作失败！');
        loading.close();
      })
    }
  }
}
</script>

<style lang="less" scoped>
@height: 46px;
.disk-header{
  min-height: @height;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
  :deep(.el-breadcrumb){
    margin-bottom: 10px;
    // flex: 1;
    width: auto;
    margin-right: 20px;
    // flex: auto;
    .el-breadcrumb__item{
      line-height: 36px;
      display: flex;
      align-items: center;
      span{
        color: #000;
        font-weight: bold;
      }
      .label{
        display: inline-block;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 100%;
        line-height: 100%;
        &:hover{
          max-width: unset;
        }
      }
      &:last-of-type{
        span{
          color: #606266;
          font-weight: 400;
        }
      }
    }
    .el-breadcrumb__separator{
      margin: 0 4px;
    }
  }
  .actions{
    display: flex;
    // margin-right: 20px;
    margin-bottom: 10px;
    .actions-item {
      margin-right: 20px;
    }
  }
  .search{
    :deep(.el-input__inner){
      width: 320px;
      height: 36px;
      line-height: 36px;
    }
  }
  .view-ways{
    display: flex;
    box-sizing: border-box;
    padding: 0 12px;
    i{
      height: 36px;
      box-sizing: border-box;
      padding: 0 8px;
      line-height: 36px;
      font-size: 16px;
      color: rgb(189, 192, 199);
      &.active{
        color: rgb(51, 51, 51);
        font-size: 18px;
      }
    }
  }
}
.el-button.el-button--text{
  display: flex;
  span{
    i{
      font-size: 20px;
    vertical-align: middle;
    }
    span{
      margin-left: 3px;
      font-size: 14px;
      color: #606266;
      vertical-align: middle;
    }
  }
}
.header-top-warp{
  width: 100%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0;
  box-sizing: border-box;
  :deep(.classify-select) {
    border: none;
    width: auto;
    .el-input__inner{
      width: auto;
      max-width: 60px;
      border: none;
      outline: none;
      padding-left: 0;
    }
  }
}
.header-search-wrap{
  width: 100%;
  padding: 6px 16px;
  box-sizing: border-box;
  :deep(.el-input) {
    width: 100%;
    .el-input__inner{
      border: none;
      background: var(--overall-background-default, #F4F6F9);
    }
  }
}
.header-bottom-wrap{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px 0;
  box-sizing: border-box;
  border-radius: 6px;
  .breadcrumb-wrap{
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    :deep(.el-breadcrumb){
      margin-bottom: 0px;
      width: auto;
      margin-right: 20px;
    }
  }
}
:deep(.classify-select-option-item) {
  display: flex;
  align-items: center;
  span{
    margin-left: 8px;
  }
}
:deep(.actions-dropdown-item) {
  display: flex;
  align-items: center;
  span{
    margin-left: 8px;
  }
}
.upload-com{
    width: 0;
    height: 0;
    overflow: hidden;
  }
</style>
<style lang='less'>
.net-disk-search{
  .iconfont {
      margin-right: 5px;
      font-size: 20px;
      line-height: 20px;
    }
    .iconwenjianjia {
      color: rgb(249, 194, 10);
    }
    .iconexcle1x {
      color: rgb(48, 165, 92);
    }
    .iconword1x {
      color: rgb(45, 133, 254);
    }
    .icontxt1x {
      color: rgb(45, 133, 254);
    }
    .iconzhaopian1x {
      color: rgb(44, 162, 92);
    }
    .iconpdf1x {
      color: rgb(226, 52, 45);
    }
    .iconppt1x {
      color: rgb(246, 123, 30);
    }
    .iconother1x {
      color: rgb(171, 189, 204);
    }
}
</style>