<!--
    @name: ques-indefinite
    @description：ques-indefinite
    @author: ZengWei
    @date: 2022-04-01 11:18
-->
<template>
  <div class="widget-video">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[视频题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-video-player">
      <div class="video-player">
        <video controls ref="videoRef" :src="element.config.__slot__.src"></video>
      </div>
      <p class="q-video-requirement">请至少观看{{ element.config.__ques__.minimumLimit }}秒</p>
    </div>
  </div>
</template>

<script>
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";

export default {
  name: "ques-video",
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  data() {
    return {}
  },
  mounted() {
    this.$nextTick().then(() => {
      this.$refs.videoRef.addEventListener('timeupdate', this.calcScore)
    })
  },
  methods: {
    calcScore(e) {
      this.element.value = e.target.currentTime

      console.log( this.element.value , 9999)

      if(e.target.currentTime > this.element.config.__ques__.minimumLimit) {
        if (this.element.config.__ques__.answerScore) {
          this.element.score = this.element.config.__ques__.score
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
.widget-video{
  .q-video-player{
    margin: 6px 0 0 16px;
    .video-player{
      padding-left: 30px;
      box-sizing: border-box;
      video{
        height: 320px;
        width: 100%;
      }
    }
    .q-video-requirement{
      padding-left: 30px;
      box-sizing: border-box;
      font-size: 12px;
      color: #aaa;
    }
  }
}
</style>
