<!--
    @name: ques-blank
    @description：ques-blank
    @author: ZengWei
    @date: 2022-04-01 11:19
-->
<template>
  <div class="widget-blank">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[填空题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div class="q-resource" v-if="element.config.__ques__.resource">
        <ChooseModel
          ref="chooseModel"
          :modelData="element.config.__ques__.resource !== null ? element.config.__ques__.resource: []">
          <template v-slot:showType>
            <el-button
              @click="openModelDrawer"
              size="small"
              type="primary"
            >查看资源</el-button>
          </template>
        </ChooseModel>
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-row :gutter="15">
          <el-col
            v-for="(option,index) in element.config.__slot__.options"
            :key="index"
            :span="element.config.__ques__.optSpan"
          >
            <div>
              <p>{{ optRoman[index] }}、{{ option.label }}</p>
              <el-input type="text" v-model="option.value" @change="blankChange"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {Input} from "element-ui";
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";

export default {
  name: "ques-blank",
  components: {
    'el-input': Input,
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  data() {
    return {}
  },
  mounted() {
    this.blankChange()
  },
  methods: {
    blankChange(){
      const blank = []
      const options = this.element.config.__slot__.options
      for (const item of options) {
        if(item.value) {
          blank.push(item.value)
        }
      }
      this.element.value = blank
      this.calcScore(blank) // 计算得分
    },
    calcScore(value){
      const config = this.element.config.__ques__
      const options = this.element.config.__slot__.options
      let score = 0
      let rightNum = 0 //回答正确数量

      if(config.scoreType === 1 && config.scoreTypeTwo === 1){
        // 全部答对，答案完全相同
        for (const index in value){
          const rightStr = options[index].right
          if(rightStr === value[index]){
            rightNum++
          }
          if(rightNum === options.length){
            score = config.score
          }
        }
      } else if(config.scoreType === 1 && config.scoreTypeTwo === 2){
        // 全部答对，包含答案
        for (const index in value){
          const rightStr = options[index].right
          const rightArr = rightStr.split(';')
          if(rightArr.includes(value[index])){
            rightNum++
          }
          if(rightNum === options.length){
            score = config.score
          }
        }
      } else if(config.scoreType === 2 && config.scoreTypeTwo === 1){
        // 答对几项得几分，答案完全相同
        for (const index in value){
          const rightStr = options[index].right
          if(rightStr === value[index]){
            score += parseFloat(options[index].score)
          }
        }
      } else if(config.scoreType === 2 && config.scoreTypeTwo === 2){
        // 答对几项得几分，包含答案
        for (const index in value){
          const rightStr = options[index].right
          const rightArr = rightStr.split(';')
          if(rightArr.includes(value[index])){
            score += parseFloat(options[index].score)
          }
        }
      }
      this.element.score = score
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
