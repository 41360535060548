import { render, staticRenderFns } from "./WorkTodo.vue?vue&type=template&id=74f4dfd4&scoped=true"
import script from "./WorkTodo.vue?vue&type=script&lang=js"
export * from "./WorkTodo.vue?vue&type=script&lang=js"
import style0 from "./WorkTodo.vue?vue&type=style&index=0&id=74f4dfd4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74f4dfd4",
  null
  
)

export default component.exports