<!--
    @name: EdgeProps
    @description：EdgeProps
    @author: ZengWei
    @date: 2023-06-21 14:58
-->
<template>
  <div class="prop-container">
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="逻辑配置" name="logic">
        <div class="scrollbar">
          <el-form label-position="top" label-width="80px">
            <el-form-item label="逻辑名称">
              <el-input :disabled="disabled" v-model="task.name" @change="onChange"></el-input>
            </el-form-item>
            <task-rules
              :disabled="disabled"
              :allFormFields="allFormFields"
              @on-logic="logicChange"
            ></task-rules>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {TabPane, Tabs} from "element-ui";
import TaskRules from "@/custom-component/task-flow/property/TaskRules";
export default {
  name: 'EdgeProps',
  components: {
    TaskRules,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
  },
  props: ['currentItem','currentNodeId','nodeType','formNode','disabled'],
  data() {
    return {
      activeTab: 'logic',
      allFormFields: [],
      task: {
        name: '',
        run_logic: []
      }
    }
  },
  mounted() {
    this.initPropData()
  },
  watch: {
    currentItem: {
      handler() {
        this.initPropData()
      },
      deep: true
    }
  },
  methods: {
    initPropData(){
      this.task.name = this.currentItem?.name
      this.task.run_logic = this.currentItem?.run_logic || []
    },
    onChange(){
      this.$emit('on-change',this.task)
    },
    logicChange(logicRules){
      this.task.run_logic = logicRules
      this.onChange()
    },
  },
}
</script>

<style lang="less" scoped>
.prop-container{
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  :deep(.el-tabs){
    height: 100%;
    .el-tabs__content{
      padding: 0;
      height: calc(100% - 40px);
      .el-tab-pane{
        height: 100%;
      }
    }
  }

  .iconc-close{
    position: absolute;
    font-size: 22px;
    top: 5px;
    right: 8px;
    z-index: 10;
    cursor: pointer;
  }
}

.scrollbar{
  overflow-y: auto;
  padding: 15px 15px 0 15px;
  height: calc(100% - 15px);
}

:deep(.el-tabs--border-card) {
  border-left: 1px solid #DCDFE6;
  border-top: none;
  border-right: none;
  border-bottom: none;
}

:deep(.form-table) {
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .self-type {
        .el-radio{
          margin-right: 18px;
        }
      }
    }
  }
}

.label-title{
  border-left: 3px solid var(--themeColor);
  padding: 0 10px;
  line-height: 1;
  margin: 0 0 16px;
  font-weight: 600;
}

.self-define{
  margin: 8px 0;
  text-indent: 2px;
}

.mb-10{
  margin-bottom: 10px;
}

.float-r{
  float: right;
  padding: 0 1px;
  cursor: pointer;
}
</style>
