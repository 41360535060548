<!--
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-16 16:04:33
 * @Descripttion: 
-->
<!--
    @name: ques-upload
    @description：ques-upload
    @author: ZengWei
    @date: 2022-04-01 11:20
-->
<template>
  <div class="widget-upload">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="sub-ques-type">
        案例题
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <uploader
          :files="element.value"
          @extra-files="onSuccess"
          :disabled="element.disabled"
        >
        </uploader>
      </div>
    </div>
  </div>
</template>

<script>
import uploader from "@/components/fileUpload/newFiles/index";

export default {
  name: "ques-upload",
  components: {uploader},
  props: ['element','number','disabled'],
  data() {
    return {}
  },
  methods: {
    onSuccess(files){
      this.element.value = files;
      this.$emit('change')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
.q-option-list{
  width: 100%;
  padding-top: 12px;
  overflow: hidden;
  box-sizing: border-box;
}
</style>
