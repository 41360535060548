<!--
 * @Author: ttheitao
 * @Date: 2023-10-09 14:26:00
 * @Description: some description
 * @LastEditors: ttheitao
 * @LastEditTime: 2024-01-31 14:15:59
-->
<template>
  <div class="list" ref="list">
    <div v-for="(item, index) in menus" :key="index" class="item" @click="handleClick(item)" v-show="item.show">
      <div class="name">
        <div v-if="item.icon" class="icon">
          <i :class="item.icon"></i>
        </div>
        <template v-if="item.name === '插入行' && insertRowItem">
          <div class="input-menu">
            <span>插入</span>
            <el-input ref="menuInput" size="mini" v-model="insertRowItem.options.rowNumber"
              @keydown.enter.native="handleInputEnter(item)" @click.stop.native="handleInpuClick"
              @mousedown.stop.native="handleInputMousedown($event, item)" @blur="handleInputBlur"></el-input>
            <span>行</span>
          </div>
        </template>
        <template v-else-if="item.name === '插入列' && insertColumnItem">
          <div class="input-menu">
            <span>插入</span>
            <el-input ref="menuInput" size="mini" v-model="insertColumnItem.options.columnNumber"
              @keydown.enter.native="handleInputEnter(item)" @click.stop.native="handleInpuClick"
              @mousedown.stop.native="handleInputMousedown($event, item)" @blur="handleInputBlur"></el-input>
            <span>列</span>
          </div>
        </template>
        <template v-else>{{ item.name }}</template>
      </div>
      <template v-if="item.children && item.children.length">
        <i class="iconfont iconlujingjiantou"></i>
        <Menu :menus="item.children" class="submenu" :style="subMenuStyle" :level="level + 1" :reverse="reverse"></Menu>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    menus: {
      type: Array,
      require: true
    },
    level: {
      type: Number,
      default: 1,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    isFirst: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      options: {},
      insertRowItem: null,
      insertColumnItem: null,
    }
  },
  watch: {
  },
  computed: {
    subMenuStyle() {
      let style = {};
      if (this.reverse) {
        style.left = `-160px`;
      } else {
        style.right = `-160px`;
      }
      return style;
    }
  },
  mounted() {
    this.isFirst && document.body.appendChild(this.$refs.list);
    this.menus.forEach(menu => {
      if (menu.name === '插入行') {
        this.insertRowItem = JSON.parse(JSON.stringify(menu));
      } else if (menu.name === '插入列') {
        this.insertColumnItem = JSON.parse(JSON.stringify(menu));
      }

      // console.log(this.insertRowItem, this.insertColumnItem);
    });
  },
  methods: {
    handleClick(item) {
      if (item.action) {
        if (item.name === '插入行') {
          item.action(this.insertRowItem.options);
        } else if (item.name === '插入列') {
          item.action(this.insertRowItem.options);
        } else {
          item.action(item.options);
        }
      }
    },
    handleInputMousedown(e, item) {
      if (['插入行', '插入列'].includes(item.name)) {
        e.stopPropagation();
        this.$emit('stopBlur');
      }
    },
    handleInputEnter(item) {
      this.handleClick(item);
    },
    handleInpuClick() {
    },
    handleInputBlur() {
      this.$emit('menuFocus');
    },
  }
}
</script>

<style lang="less" scoped>
.list {
  min-width: 160px;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #fff;
  box-shadow: rgb(204, 204, 204) 0px 0px 10px 1px;

  .item {
    position: relative;
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: space-between;

    .name {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 24px;
      text-align: left;
    }

    .input-menu {
      display: flex;
      align-items: center;

      .el-input {
        width: 50px;
        margin: 0 8px;
      }
    }

    .submenu {
      position: absolute;
      top: 0;
      display: none;
      user-select: none;
    }

    &:hover {
      background-color: #f1f2f3;

      >.submenu {
        display: block;
      }
    }
  }
}
</style>