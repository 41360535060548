<!--
 * @Author: your name
 * @Date: 2021-12-02 15:31:01
 * @LastEditTime: 2024-09-02 15:04:25
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\custom-component\bindModel\modelList.vue
-->
<template>
  <div class="detail-title">
    <el-tree
      ref="treeRef"
      class="scroller"
      v-loading="loading"
      :data="treeData"
      node-key="id"
      :props="props"
      show-checkbox
      :default-expanded-keys="expandedKeys"
      :default-checked-keys="checkedKeys"
      style="padding: 0 5px; height: 100%; overflow-y: auto"
      @check="change"
    >
      <div slot-scope="{ node, data }">
        <div>
          <i
            v-if="data[props.children]?.length"
            class="iconfont icona-wenjianjiafill"
          ></i>
          <i
            class="iconfont icona-moxingmoni"
            v-if="!data[props.children]?.length"
          ></i>
          {{ data[props.label] }}
        </div>
      </div>
    </el-tree>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { Tree } from 'element-ui';

export default {
  name: 'Index',
  components: {
    'el-tree': Tree,
  },
  props: {
    //回显数据
    modelData: {
      type: [Array, String],
      default: () => [],
    },
    search: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    bodyParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      treeData: [],
      loading: true,
      props: {
        label: 'name',
        children: 'children',
        isLeaf: 'isLeaf',
      },
      loadedData: [],
      expandedKeys: [], //回显展开的数据
      checkedKeys: [], //回显选中的数据
      nodeKey: 'id',
      modelIdKey: 'modelid',
    };
  },
  methods: {
    /**
     * @name:
     * @description:     // 遍历数组，数据格式调整为标准格式
     * @msg:
     * @param {*} list
     * @return {*}
     */
    recursiveTree(list = []) {
      const newList = list.map((item) => {
        if (Array.isArray(item.models)) {
          if (Array.isArray(item[this.props.children])) {
            item[this.props.children] = this.recursiveTree([
              ...item[this.props.children],
              ...item.models,
            ]);
          } else {
            item[this.props.children] = item.models;
          }
          return item;
        } else {
          return item;
        }
      });
      return newList;
    },
    change(data, checked) {
      const keys = this.$refs.treeRef.getCheckedNodes(true, false);
      if (keys.length != 0) {
        if (this.loadedData.length == 0) {
          //新增
          this.loadedData = keys;
          this.loopFn(keys, true);
        } else {
          let arr = this.diffent(this.loadedData, keys, 'id');
          this.loadedData = keys;
          // checked.checkedKeys 选中的id
          if (
            arr.length != 0 &&
            checked &&
            checked.checkedKeys &&
            checked.checkedKeys.length != 0
          ) {
            if (
              checked.checkedKeys.filter((it) => it == arr[0].id).length == 0
            ) {
              //减去
              this.loopFn(arr, false);
            } else {
              //新增
              this.loopFn(arr, true);
            }
          }
        }
      } else {
        let listChecked = JSON.parse(JSON.stringify(this.loadedData));
        this.loadedData = [];
        listChecked.forEach((item) => {
          if (item?.[this.modelIdKey]) {
            this.$emit('show-model', item?.[this.modelIdKey], false);
          }
        });
        this.$emit('clear-obk');
      }
    },
    loopFn(data, bol) {
      // bol 是否显示
      if (data && data.length != 0) {
        data.forEach((item) => {
          if (item?.[this.modelIdKey]) {
            this.$emit('show-model', item?.[this.modelIdKey], bol);
          }
        });
      }
    },
    diffent(fArr, cArr, field) {
      let diffRes = [];
      let fDatas = [];
      let cDatas = [];
      for (let i in fArr) {
        let flg = false;
        for (let j in cArr) {
          if (cArr[j][field] === fArr[i][field]) {
            flg = true;
            break;
          }
        }
        if (!flg) {
          fDatas.push(fArr[i]);
        }
      }
      for (let i in cArr) {
        let flg = false;
        for (let j in fArr) {
          if (fArr[j][field] === cArr[i][field]) {
            flg = true;
            break;
          }
        }
        if (!flg) {
          cDatas.push(cArr[i]);
        }
      }
      diffRes.push(...cDatas.concat(fDatas));
      return diffRes;
    },

    getData() {
      let searchParam = [
        {
          code: 'archi_type',
          ruleType: 'eq',
          value: JSON.parse(localStorage.getItem('targetArchi')).data_id,
        },
      ];
      let obj = {
        object_uuid: 'object6176693334cff',
        view_uuid: 'view617cf772586a0',
        __method_name__: 'dataList',
        transcode: 0,
        search: this.search.length
          ? JSON.parse(JSON.stringify(this.search))
          : searchParam,
      };
      const mapModelList = (list = []) => {
        list.forEach((item) => {
          let it = item;
          if (it.models?.length) {
            //回显数据
            if (Array.isArray(this.modelData) && this.modelData?.length) {
              this.checkFn(item, it.id);
            }
          }
          if (it.children?.length) {
            mapModelList(it.children);
          }
        });
      };
      dataInterface(obj).then((res) => {
        if (res.data.data?.length) {
          // res.data.data.forEach((item) => {
          //   let it = item;
          //   if (it.models?.length) {
          //     //回显数据
          //     if (Array.isArray(this.modelData) && this.modelData?.length) {
          //       this.checkFn(item, it.id);
          //     }
          //   }
          //   arr.push(it);
          // });
          let arr = [];
          mapModelList(res.data.data);
          arr = res.data.data;
          if (arr.length) {
            this.treeData = this.recursiveTree(arr);
          } else {
            this.treeData = this.recursiveTree(res.data.data);
          }
          this.loading = false;
        } else {
          this.treeData = [];
          this.loading = false;
        }
      });
    },
    checkFn(item, parentId) {
      let models = item.models;
      console.log(this.modelIdKey, item.models, '---236---');
      models.forEach((val) => {
        val.id = JSON.stringify(parentId) + JSON.stringify(val.id);
        if (val?.[this.modelIdKey]) {
          if (
            this.modelData.find((it) => it.model_id === val[this.modelIdKey])
          ) {
            this.loadedData.push(val[this.modelIdKey]);
            this.expandedKeys.push(item.id);
            this.expandedKeys = Array.from(new Set(this.expandedKeys));
            this.checkedKeys.push(val.id);
            this.$emit('get-checked-keys', this.loadedData);
          }
        }
      });
    },
    getCheckedKey() {
      const checkedIds = [];
      const modelIdList = this.modelData.map((model) => model.model_id);
      const loopTree = (arr = []) => {
        arr.forEach((item) => {
          if (item[this.modelIdKey]) {
            const checked = modelIdList.includes(item[this.modelIdKey]);
            if (checked) {
              checkedIds.push(item[this.nodeKey]);
              this.loadedData.push(item[this.modelIdKey]);
            }
          } else {
            if (Array.isArray(item[this.props.children])) {
              loopTree(item[this.props.children]);
            }
          }
        });
      };
      loopTree(this.treeData);
      this.checkedKeys = checkedIds;
      this.$emit('get-checked-keys', this.loadedData);
    },
    getGraphList() {
      const {
        props: { children, label, value, model_id },
      } = this.config.props;
      const { graphPath, openFilter } = this.config.__config__;
      let data = {
        ...this.bodyParams,
        ...(this.search.length && {
          search: JSON.parse(JSON.stringify(this.search)),
        }),
      };
      const pathArr = graphPath.split('-');
      dataInterface(
        openFilter ? data : {},
        '/api' + pathArr[pathArr.length - 1]
      )
        .then((res) => {
          if (res.data?.code === 200) {
            if (children) this.props.children = children;
            if (label) this.props.label = label;
            if (value) this.nodeKey = value;
            if (model_id) this.modelIdKey = model_id;
            this.treeData = res.data.data;
            this.getCheckedKey();
          } else {
            this.treeData = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    const { graphPath } = this.config.__config__;
    if (graphPath) {
      this.getGraphList();
    } else {
      this.getData();
    }
  },
};
</script>
