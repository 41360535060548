<!--
    @name: widget-slider
    @description：widget-slider
    @author: ZengWei
    @date: 2022-03-25 09:29
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ element.value ? element.value: '0' }}</div>
    <el-slider
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :min="element.config.min"
      :max="element.config.max"
      :step="element.config.step"
    ></el-slider>
  </div>
</template>

<script>
import {Slider} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetSlider",
  components: {
    'el-slider': Slider
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>

</style>
