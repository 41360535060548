import { BaseWidget} from 'bimcc-graph';

export default class ButtonWidget extends BaseWidget {

    static widgetType = 'button'

    DOM;

    // 右键菜单
    menu = [];

    isCustomClick = true;

    constructor(option) {
        super()

        this.DOM = document.createElement("button")

        this.DOM.type = 'button';

        this.setStyle({
            width: '100%',
            minHeight: '26px',
            height: 'auto',
            textAlign: 'center',
            display: 'block',
            fontSize: '20px',
            color: 'black',
            backgroundColor: 'white',
        })

        if (option.text) {
            this.DOM.innerText = option.text
        }

        // if (option.event_name) {
        //     this.event_name = option.event_name
        // }

        this.onMousedown((e) => {
            e.stopPropagation();
        })
    }


    setContextMenu(menu) {
        this.menu = menu;
    }

    getContextMenu() {
        return this.menu;
    }

    onChange(closure) {
        this.DOM.addEventListener('input', () => {
            closure(this.DOM.innerText)
        })
    }

    getValue() {
        return this.DOM.innerText;
    }

    setValue(value) {
        this.DOM.innerText = value;
    }

    getLabel() {
        //return this.DOM.innerText
    }
}