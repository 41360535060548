/*
 * @Description: 组织架构相关
 * @Author: luocheng
 * @Date: 2021-11-05 18:40:13
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-08-30 10:49:32
 */

export default {
  state: {
    // 当前点击的架构
    cacheArchi: null,
    // 当前已选择的架构
    targetArchi: null
  },
  mutations: {
    /**
     * @desc: 点击架构
     * @param {Object} cacheArchi
     */
    setCacheArchi(state, cacheArchi) {
      state.cacheArchi = cacheArchi || null;
    },
    /**
     * @desc: 设置当前选中架构
     * @param {Object} targetArchi
     */
    setTargetArchi(state, {targetArchi}) {
      // console.log(targetArchi, '设置当前选中架构', targetArchi?.name)
      // console.log(a, '---11--aa--');
      state.targetArchi = targetArchi || null;
      localStorage.setItem('targetArchi', targetArchi ? JSON.stringify(targetArchi) : '');
      if (targetArchi?.permissions !== undefined) {
        localStorage.setItem("userRoles", targetArchi.permissions || '无效权限');
      }
    }
  }
}