<!--
    @name: ques-indefinite
    @description：ques-indefinite
    @author: ZengWei
    @date: 2022-04-01 11:18
-->
<template>
  <div class="widget-indefinite">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="sub-ques-type">
        不定项
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-checkbox-group v-model="element.value" :disabled="element.disabled" @change="calcScore">
          <el-checkbox
            :class="columnClass"
            v-for="(option,index) in element.config.__slot__.options"
            :key="index"
            :label="option.value"
          >
            {{optNumber[index]}} 、{{ option.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import {Checkbox, CheckboxGroup} from "element-ui";
import quesWidgetMinix from "@/components/answer/quesParser/scripts/quesWidgetMinix.js";

export default {
  name: "ques-indefinite",
  components: {
    'el-checkbox-group': CheckboxGroup,
    'el-checkbox': Checkbox,
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  data() {
    return {}
  },
  methods: {
    calcScore(value){
      const config = this.element.config.__ques__
      const options = this.element.config.__slot__.options

      const rightOption = options.filter(item=>item.right === true)
      const rightArr = Array.from(rightOption,item=>item.value)

      let score = 0
      if(config.scoreType === 1){
        // 全部答对得分
        let equal = true
        for (const item of value){
          if(!rightArr.includes(item)){
            equal = false // 正确答案没包含选项不得分
          }
        }
        if(equal) score = config.score
      } else if (config.scoreType === 2){
        // 答对几项得几分，答错不得分
        let equal = true
        for (const item of value){
          if(!rightArr.includes(item)){
            equal = false // 正确答案没包含选项不得分
          }
        }
        if(equal){
          for (const opt of options){
            for (const optVal of value){
              if(opt.right && optVal === opt.value){
                score += parseFloat(opt.score)
              }
            }
          }
        }
      } else if (config.scoreType === 3){
        // 每项都有对应得分
        for (const opt of options){
          for (const optVal of value){
            if(optVal === opt.value){
              score += parseFloat(opt.score)
            }
          }
        }
      }
      this.element.score = score
      this.$emit('change')
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
</style>
