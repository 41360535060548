<!--
 * @Description: 重命名
 * @Author: luocheng
 * @Date: 2022-03-02 15:50:16
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-09-14 09:42:06
-->
<template>
  <div class="rename" v-if="value">
    <el-dialog
      :title="title"
      :visible.sync="value"
      :show-close="false"
      :before-close="handleClose"
      append-to-body
      width="400px">
      <el-input v-model="name" placeholder="请输入名称" clearable maxlength="32"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Rename',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    fileName: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'rename'
    }
  },
  data() {
    return {
      name: ''
    }
  },
  computed: {
    title() {
      return this.type === 'createFolder' ? '新增文件夹' : '重命名';
    }
  },
  watch: {
    value: {
      handler() {
        this.name = this.fileName
      }
    }
  },
  created() {
    this.name = this.fileName
  },
  methods: {
    handleClose(done) {
      this.onClose();
      done();
    },
    onClose() {
      this.name = '';
      this.$emit('input', false)
    },
    onConfirm() {
      if(this.name && this.name.trim()) {
        this.$emit('nameResult', this.name.trim());
      } else {
        this.$message.info('请输入名称');
      }
    }
  },
}
</script>

<style lang="less" scoped>
.rename{
  :deep(.el-dialog){
    .el-dialog__header{
      text-align: left;
      border-bottom: 1px solid #f5f5f5;
      .el-dialog__title{
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
</style>