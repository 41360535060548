<!--
    @name: FlowProps
    @description：FlowProps
    @author: ZengWei
    @date: 2021-10-22 09:06
-->
<template>
  <div class="flow-props">
    <template v-if="active == 'flowSetting'">
      <el-collapse>
        <el-collapse-item name="basicFlow">
          <template #title>
            <span class="collapse-title">基本设置</span>
          </template>
          <el-form label-position="right" label-width="70px">
            <el-form-item label="流程名称">
              <el-input v-model="flowConfig.flow_name" @change="flowChange" />
            </el-form-item>
            <el-form-item label="流程描述">
              <el-input v-model="flowConfig.flow_desc" @change="flowChange" />
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="formFields">
          <template #title>
            <span class="collapse-title">表单字段</span>
          </template>

					<el-form label-position="right" label-width="70px">
            <el-form-item label="对象表单">
              <el-select
                style="width: 100%"
                :filterable="true"
                v-model="flowConfig.formUuid"
                @change="changeObjectFormField"
              >
                <el-option
                  v-for="item in designList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.uuid" >
                  {{item.name}}
                </el-option>
              </el-select>
            </el-form-item>
					</el-form>

          <el-table border :data="fieldData" style="width: 100%">
            <el-table-column label="名称" prop="field_note" />
						<el-table-column label="控件ID" prop="field" />
            <!--<el-table-column label="类型" prop="amount1" />
            <el-table-column label="操作" prop="amount1" />-->
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </template>
    <template v-if="active == 'nodeSetting'">
      <el-collapse>
        <el-collapse-item name="basicNode">
          <template #title>
            <span class="collapse-title">基本设置</span>
          </template>
          <el-form label-position="right" label-width="70px">
            <el-form-item label="步骤名称">
              <el-input v-model="nodeName" @change="onChange" />
            </el-form-item>
            <el-form-item label="控件可见">
              <el-select
                v-model="editFields"
                multiple
                placeholder="请选择流程中展示的表单控件"
                style="width: 100%"
                @change="onChange"
              >
                <el-option
                  v-for="item in fieldData"
                  :key="item.field"
                  :label="item.field_note"
                  :value="item.field"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="selectBranch">
          <template #title>
            <span class="collapse-title">流转分支</span>
          </template>
          <div>
            <el-form label-position="right" label-width="70px" style="margin-top: 15px">
              <el-form-item label="分支选择">
                <el-switch v-model="flowStep.selectStep" @change="onChange" style="float: right;margin: 5px 10px 0"></el-switch>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item name="targetPicker">
          <template #title>
            <span class="collapse-title">审核人员</span>
          </template>
          <div>
            <el-radio-group v-model="flowStep.userType" @change="onTypeChange">
              <el-radio :label="1">指定人员</el-radio>
              <el-radio :label="2">发起人自选</el-radio>
              <!--<el-radio :label="3">主管（负责人）</el-radio>
              <el-radio :label="4">连续主管（部门负责人）</el-radio>-->
              <el-radio :label="5">部门人员</el-radio>
              <el-radio :label="6">岗位</el-radio>
              <el-radio :label="7">流程中指定</el-radio>
              <!--<el-radio :label="8">空步骤</el-radio>-->
              <el-radio :label="9">发起人审核</el-radio>
            </el-radio-group>
          </div>
          <el-form
            label-position="right"
            label-width="70px"
            style="margin-top: 15px"
          >
            <el-form-item
              v-if="[1, 2, 6, 7].indexOf(flowStep.userType) > -1"
              label="选择范围"
            >
              <el-radio-group
                v-model="flowStep.userFrom"
                size="small"
                @change="userScopeChange"
              >
                <el-radio-button :label="1">本级架构</el-radio-button>
                <!--<el-radio-button :label="2" disabled>全体人员</el-radio-button>-->
                <el-radio-button :label="3">上级公司</el-radio-button>
              </el-radio-group>
              <!--<el-select
                v-model="flowStep.userFrom"
                placeholder="请选择范围"
                style="width: 100%"
                @change="onChange"
              >
                <el-option
                  v-for="item in userFromConf"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>-->
            </el-form-item>
            <el-form-item v-if="flowStep.userType === 1" label="人员选择">
              <select-user :key="'check'+flowStep.userArch"
                :company-id="flowStep.userArch"
                picker-type="check"
                :show-list="userSelected('check')"
                @on-select-change="onSelectChange"
              />
            </el-form-item>
            <el-form-item v-if="flowStep.userType === 1" label="人员范围">
              <el-switch v-model="flowStep.userScope" @change="onChange"></el-switch>
              <span style="margin-left: 15px">开启：上述已选择人员作为选择范围</span>
            </el-form-item>
            <el-form-item v-if="flowStep.userType === 5" label="部门选择">
              <select-depart
                :show-list="recoverSection"
                @on-select-change="onSelectDepart"
              />
            </el-form-item>
            <template v-if="flowStep.userType === 6">
              <el-form-item label="岗位选择">
                <el-select
                  multiple
                  v-model="flowStep.userRole"
                  @change="onChange"
                  placeholder="请选择">
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <!--<el-form-item
              v-if="[3, 4, 5, 6].indexOf(flowStep.userType) > -1"
              label="用户为空"
            >
              <el-radio-group
                v-model="flowStep.userEmpty"
                size="small"
                @change="onChange"
              >
                <el-radio-button :label="1">自动通过</el-radio-button>
                <el-radio-button :disabled="true" :label="2">
                  转交管理员
                </el-radio-button>
              </el-radio-group>
            </el-form-item>-->
            <el-form-item v-if="flowStep.userType !== 8" label="能否转交">
              <el-radio-group
                v-model="flowStep.turnTo"
                size="small"
                @change="onChange"
              >
                <el-radio-button :label="false">否</el-radio-button>
                <el-radio-button :label="true">是</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="sendPicker">
          <template #title>
            <span class="collapse-title">抄送人员</span>
          </template>
          <div>
            <el-radio-group v-model="flowStep.sendType" @change="onChange">
              <el-radio :label="1">指定人员</el-radio>
              <el-radio :label="2">发起人自选</el-radio>
              <!--<el-radio :label="3">部门负责人</el-radio>-->
              <el-radio :label="4">岗位</el-radio>
              <el-radio :label="5">发起人自己</el-radio>
              <el-radio :label="6">流程中指定</el-radio>
            </el-radio-group>
          </div>
          <el-form
            label-position="right"
            label-width="70px"
            style="margin-top: 15px"
          >
            <el-form-item
              v-if="[1, 2, 4, 6].indexOf(flowStep.sendType) > -1"
              label="选择范围"
            >
              <el-radio-group
                v-model="flowStep.sendFrom"
                size="small"
                @change="userScopeChange($event,'send')"
              >
                <el-radio-button :label="1">本级架构</el-radio-button>
                <!--<el-radio-button :label="2" disabled>全体人员</el-radio-button>-->
                <el-radio-button :label="3">上级公司</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="flowStep.sendType === 1" label="人员选择">
              <select-user
                :key="'send'+flowStep.sendArch"
                :company-id="flowStep.sendArch"
                picker-type="send"
                :show-list="userSelected('send')"
                @on-select-change="onSelectChange"
              />
            </el-form-item>
            <!--<el-form-item v-if="flowStep.sendType === 3" label="部门选择">
              <el-button type="primary" size="small" icon="el-icon-search">请选择部门</el-button>
            </el-form-item>-->
            <el-form-item v-if="flowStep.sendType === 4" label="岗位选择">
              <el-select
                v-model="flowStep.sendRole"
                multiple
                placeholder="请选择"
                @change="onChange">
                <el-option
                  v-for="item in sendRoleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="coSign">
          <template #title>
            <span class="collapse-title">会签设置</span>
          </template>
          <div style="margin-left: 10px">
            <el-radio-group v-model="flowStep.signType" @change="onChange">
              <el-radio :label="1" style="width: 100%">
                或签（任意一人通过）
              </el-radio>
              <el-radio :label="2" style="width: 100%">
                会签（全部人员通过）
              </el-radio>
            </el-radio-group>
          </div>
          <el-form
            label-position="right"
            label-width="70px"
            style="margin-top: 15px"
          >
            <el-form-item v-if="flowStep.signType === 2" label="会签比例">
              <el-radio-group
                v-model="flowStep.signRate"
                size="small"
                @change="onChange"
              >
                <el-radio-button :label="1">全部通过</el-radio-button>
                <el-radio-button :label="2">过半通过</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="btnList">
          <template #title>
            <span class="collapse-title">按钮设置</span>
          </template>
          <div class="flow-button-group">
            <el-form label-position="left" class="form-table" label-width="80px">
              <template v-for="(item,index) in btnList">
                <div :key="index">
                  <div class="clearfix self-define">
                    <span>{{index>9 ? index : '0'+(index+1)}}</span>
                    <span v-if="item.system === 0" class="float-r" @click="delBtn(index)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="float-r" @click="upGo(index)">
                      <i class="el-icon-top"></i>
                    </span>
                    <span class="float-r" @click="downGo(index)">
                      <i class="el-icon-bottom"></i>
                    </span>
                  </div>
                  <el-form-item label="按钮文字">
                    <el-input v-model="item.btn" @change="onChange"></el-input>
                  </el-form-item>
                  <el-form-item label="按钮操作">
                    <el-select v-model="item.status">
                      <el-option label="退回" :value="-1">退回</el-option>
                      <el-option label="通过" :value="1">通过</el-option>
                      <el-option label="转交" :value="2">转交</el-option>
                      <el-option label="重新发起" :value="3">重新发起</el-option>
                      <el-option label="提交至退回人" :value="4">提交至退回人</el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="显示隐藏">
                    <el-radio-group v-model="item.show" @change="onChange">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </template>
              <el-button @click="addBtn" style="width: 100%;margin: 15px 0" icon="el-icon-plus">添加按钮</el-button>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item name="extendFields">
          <template #title>
            <span class="collapse-title">审批意见</span>
          </template>
          <div class="flow-button-group">
            <el-form label-position="left" class="form-table" label-width="80px">
              <el-form-item label="附件必填">
                <el-switch style="float: right;margin: 5px 0 0" @change="onChange" v-model="flowStep.attachRequired"></el-switch>
              </el-form-item>
              <el-form-item label="意见必填">
                <el-switch style="float: right;margin: 5px 0 0" @change="onChange" v-model="flowStep.suggestRequired"></el-switch>
              </el-form-item>
              <template v-for="(item,index) in extendFields">
                <div :key="index" style="margin-top: 10px">
                  <el-form-item label="控件名称">
                    {{item.name}}
                  </el-form-item>
                  <el-form-item label="控件类型">
                    {{item.type}}
                  </el-form-item>
                </div>
              </template>
              <el-button @click="addExtendDesign" style="width: 100%;margin: 15px 0" icon="el-icon-plus">审批意见扩展表单控件配置</el-button>
            </el-form>
          </div>
        </el-collapse-item>
        <!--<el-collapse-item name="trigger">
          <template #title>
            <span class="collapse-title">触发事件</span>
          </template>
          <div style="margin-bottom: 15px">
            <el-button round @click="openCheckEvent('trigger')">
              选择事件
            </el-button>
          </div>
          <el-table border :data="tableData" style="width: 100%">
            <el-table-column label="名称" prop="id" width="90" />
            <el-table-column label="事件" prop="name" />
            <el-table-column label="类型" prop="amount1" />
            <el-table-column label="操作" prop="amount1" />
          </el-table>
        </el-collapse-item>-->
      </el-collapse>
    </template>
    <template v-if="active == 'branchSetting'">
      <el-collapse>
        <el-collapse-item name="basicBranch">
          <template #title>
            <span class="collapse-title">基本设置</span>
          </template>
          <el-form label-position="right" label-width="70px">
            <el-form-item label="分支名称">
              <el-input v-model="nodeName" @change="onChange" />
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="condition">
          <template #title>
            <span class="collapse-title">条件设置</span>
          </template>
          <div>
            <el-radio-group
              v-model="flowBranch.conditionType"
              @change="onChange"
            >
              <el-radio :label="1">发起人</el-radio>
              <el-radio :label="2">判断条件</el-radio>
            </el-radio-group>
          </div>
          <template v-if="flowBranch.conditionType === 1">
            <div style="margin-top: 15px">
              <el-form label-position="right" label-width="70px">
                <el-form-item label="人员范围">
                  <el-radio-group
                    v-model="flowBranch.userType"
                    size="small"
                    @change="userScopeChange"
                  >
                    <el-radio-button :label="1">本级架构</el-radio-button>
                    <!--<el-radio-button :label="2" disabled>全体人员</el-radio-button>-->
                    <el-radio-button :label="3">上级公司</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="人员选择">
                  <select-user
                    :key="'branch'+flowBranch.userArch"
                    :company-id="flowBranch.userArch"
                    picker-type="publisher"
                    :show-list="userSelected('publisher')"
                    @on-select-change="onSelectChange"
                  />
                </el-form-item>
              </el-form>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(item, i) in flowBranch.conditionCollect"
              :key="i"
              style="position:relative;margin-top: 15px;padding-right: 15px"
            >
              <el-select
                v-model="item.field"
                placeholder="表单字段"
                style="width: 120px"
              >
                <el-option
                  v-for="(dd, ii) in objectFieldsList"
                  :key="ii"
                  :label="dd.name"
                  :value="dd.code"
                >
                  {{dd.name}}
                </el-option>
              </el-select>
              <el-select
                v-model="item.judge"
                placeholder="请选择"
                style="width: 100px"
                @change="onChange"
              >
                <el-option
                  v-for="(dd, ii) in conditionList"
                  :key="ii"
                  :label="dd.name"
                  :value="dd.value"
                />
              </el-select>
              <el-input
                v-model="item.value"
                placeholder="请输入条件值"
                style="width: 120px"
                @change="onChange"
              />
              <i class="iconfont iconbimgis_guanbi" style="line-height: 32px;position: absolute;right: 5px"
                 @click="delCondition(i)"></i>
            </div>
            <p class="condition-text">条件取多个值用 “|” 分隔</p>
            <el-button icon="el-icon-plus" size="small" @click="addCondition">新增条件</el-button>
          </template>
        </el-collapse-item>
      </el-collapse>
    </template>
    <template v-if="active == 'startEndSet'">
      <el-collapse>
        <el-collapse-item name="basicBranch">
          <template #title>
            <span class="collapse-title">抄送人员</span>
          </template>
          <div>
            <el-radio-group v-model="flowStep.sendType" @change="onChange">
              <el-radio :label="1">指定人员</el-radio>
              <el-radio :label="2">发起人自选</el-radio>
              <!--<el-radio  :label="3">部门负责人</el-radio>-->
              <el-radio :label="4">岗位</el-radio>
              <el-radio :label="5">发起人自己</el-radio>
              <el-radio :label="6">流程中指定</el-radio>
            </el-radio-group>
          </div>
          <el-form
            label-position="right"
            label-width="70px"
            style="margin-top: 15px"
          >
            <el-form-item v-if="[1,2,4,6].indexOf(flowStep.sendType) > -1" label="选择范围">
              <el-radio-group v-model="flowStep.sendFrom" @change="userScopeChange($event,'send')">
                <el-radio-button :label="1">本级架构</el-radio-button>
                <el-radio-button :label="3">上级公司</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="flowStep.sendType === 1" label="人员选择">
              <select-user
                :key="'start-end'+flowStep.sendArch"
                :company-id="flowStep.sendArch"
                picker-type="send"
                :show-list="userSelected('send')"
                @on-select-change="onSelectChange"
              />
            </el-form-item>
            <el-form-item v-if="flowStep.sendType === 4" label="岗位选择">
              <el-select
                style="width: 100%"
                v-model="flowStep.sendRole"
                multiple
                placeholder="请选择"
                @change="onChange">
                <el-option
                  v-for="item in sendRoleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </template>

    <el-dialog
      :visible.sync="extendDialog"
      title="流程中审批意见扩展表单内容配置"
      :fullscreen="true"
      :custom-class="'common-dialog'"
      append-to-body>
      <div class="dialog-main" v-if="extendDialog">
        <FormDesigner
          :recover-design-data="this.flowStep.extendFields"
          @form-data="confirmSetting"></FormDesigner>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import FormDesigner from "@/custom-component/form/FormDesigner";
  import selectUser from '../../selectUser/index'
  import selectDepart from './selectDepartment/index'
  import FlowPropData from '../scripts/FlowPropData'
  import flowPropMethods from '../scripts/FlowPropMethod'
  import { Form,
		FormItem,
		Collapse,
		CollapseItem,
		Input,
		Select,
		Option,
		Button,
		Radio,
		RadioButton,
		RadioGroup,
		Dialog,
  } from 'element-ui'

  export default {
    name: 'FlowProps',
    components: { selectUser, selectDepart,FormDesigner,
			'el-form': Form,
			'el-form-item': FormItem,
			'el-collapse': Collapse,
			'el-collapse-item': CollapseItem,
			'el-input': Input,
			'el-select': Select,
			'el-option': Option,
			'el-radio': Radio,
			'el-button': Button,
			'el-radio-group': RadioGroup,
			'el-radio-button': RadioButton,
			'el-dialog': Dialog,
		},
    props: {
      flowData: {
        type: Object,
        default: () => {
          return {}
        }
      },
      obuuid: {
        default: ''
      }
    },
		data: FlowPropData,
    computed:{
      userSelected(){
        return function (type) {
          let userData = [];
          let selected
          if(type === 'publisher'){
            selected = this.publisher
          } else {
            selected = type === 'check' ?
              this.targetUser : this.sendUser
          }
          for (let item of selected) {
            let itemData = {}
            itemData.user_id = item.id
            if(item.section_id) itemData.section_id = item.section_id
            userData.push(itemData)
          }
          return userData
        }
      },
      extendFields(){
        const extendFields = this.flowStep.extendFields
        const newData = []
        if(extendFields?.fields){
          for (let field of extendFields.fields){
            let item = {
              name: field.__config__.label,
              type: field.__config__.tagIcon,
            }
            newData.push(item)
          }
        }
        return newData
      },
      recoverSection(){
        let departList = [];
        const sectionId = this.flowStep?.userSection || []
        for (let val of sectionId){
          let item  = {id : val}
          departList.push(item)
        }
        return departList
      }
    },
		created() {
      this.getObjectFormList();
      this.getObjectFields();
      this.getArchRole() //请求岗位接口
      if(this.flowData?.formUuid){
        this.changeObjectFormField(this.flowData.formUuid)
      }
		},
		mounted() {
			// this.updated(1212)
		},
		methods: flowPropMethods,
  }
</script>
<style>
  .flow-props .el-collapse-item__header {
    position: relative;
  }
  .flow-props .el-collapse-item__header:before {
    position: absolute;
    top: 16px;
    width: 3px;
    height: 14px;
    margin-right: 10px;
    background-color: var(--themeColor) !important;
    content: '';
  }
	.flow-props .el-radio-group {
		line-height: 2;
	}
  .flow-props .el-radio {
    width: 93px;
		line-height: 2;
    margin-right: 28px !important;
  }
</style>
<style lang="less" scoped>
:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer {
    padding: 10px 20px 10px;
  }
}

.dialog-main{
  height: calc(100vh - 56px);
  overflow-y: auto;
  border: 1px solid #eeeeee;
}


:deep(.form-table) {
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .self-type {
        .el-radio{
          margin-right: 16px;
        }
      }
    }
  }
}

.float-r{
  float: right;
  padding: 0 1px;
  cursor: pointer;
}
.self-define{
  margin: 8px 0;
  text-indent: 2px;
}
.flow-props {
  padding: 0 15px;
  overflow-y: auto;
  height: calc(100vh - 115px);
  text-align: left;

  .collapse-title {
    text-indent: 15px;
  }
  .condition-text {
    color: red;
    margin: 10px 0;
    font-size: 10px;
    text-indent: 2px;
  }
}
</style>
