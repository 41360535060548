<!--
 * @Author: zyf
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @Date: 2023-06-29 17:38:06
 * @LastEditTime: 2023-10-16 14:37:29
 * @description:
-->
<template>
  <div class="remark-box-container">
    <section class="remark-wrap" v-loading="listLoading">
      <div v-for="(item, index) in remarkList" :key="index" class="remark-item">
        <div class="avatar">
          <el-avatar :size="32" @error="errorHandler">
            <img v-if="item.bbb" :src="item.bbbb"/>
            <span v-else>{{ item.user_name?.[0] || '-' }}</span>
          </el-avatar>
        </div>
        <div class="msg-wrap">
          <p class="info-warp">
            <span class="user-name">{{ item.user_name }}</span>
          </p>
          <p class="remark">
            {{ item.content }}
          </p>
          <div class="other-info-warp">
            <span class="time-stamp">{{ item.created_at }}</span>
            <span class="action-warp">
              <i class="iconfont iconpinglun1 action-item"  @click.stop="handleMessageReply(item)"/>
              <i class="iconfont iconc-trash-can action-item" @click.stop="deleteItem(item)"/>
            </span>
          </div>
          <template v-if="item.__expand && Array.isArray(item.children) && item.children.length">
            <div v-for="(sitem, sindex) in item.children" :key="sindex" class="remark-item sub-remark">
              <div class="avatar">
                <el-avatar :size="32" src="https://empty" @error="errorHandler">
                  <img v-if="sitem.bbb" :src="sitem.bbbb"/>
                  <span v-else>{{ sitem.user_name?.[0] || '-' }}</span>
                </el-avatar>
              </div>
              <div class="msg-wrap">
                <p class="info-warp">
                  <span class="user-name">{{ sitem.user_name }}</span>
                </p>
                <p class="remark">
                  <span>回复</span><span class="user-name text-span-padding">{{ sitem.to_user_name }}</span>
                  {{ sitem. content }}
                </p>
                <div class="other-info-warp">
                  <span class="time-stamp">{{ item.created_at }}</span>
                  <span class="action-warp">
                    <i class="iconfont iconpinglun1 action-item"  @click.stop="handleMessageReply(sitem)"/>
                    <i class="iconfont iconc-trash-can action-item" @click.stop="deleteItem(sitem)"/>
                  </span>
                </div>
              </div>
            </div>
            <span class="expand-btn" @click.stop="item.__expand = false">
              收起回复
            </span>
          </template>
          <template v-else-if="Array.isArray(item.children) && item.children.length">
            <span class="expand-btn" @click.stop="item.__expand = true">
              展开{{ item.children.length }}条回复
            </span>
          </template>
        </div>
      </div>
      <el-empty v-if="!Array.isArray(remarkList) || !remarkList.length" description="暂无内容"></el-empty>
    </section>
    <div class="remark-input" v-loading="messageLoading" @keydown.enter="handleMessage" @keydown.delete="handleDelete">
      <el-input
        class="custom-input"
        :placeholder="placeholder"
        v-model="inputText">
        <template #prefix>
          <svg v-if="!reply" t="1653893187156" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30175" width="18" height="18"><path d="M864 160a64 64 0 0 1 64 64v512a64 64 0 0 1-64 64H521.664l-183.936 122.624a32 32 0 0 1-49.28-21.312L288 896v-96h-128a64 64 0 0 1-64-64v-512a64 64 0 0 1 64-64z m0 64h-704v512H320a32 32 0 0 1 31.488 26.24L352 768v68.096l142.272-94.72a32 32 0 0 1 11.52-4.736L512 736h352v-512zM384 416v128H320v-128h64z m160 0v128h-64v-128h64z m160 0v128h-64v-128h64z" fill="#121619" p-id="30176"></path></svg>
          <span v-else class="reply-msg">
            <span class="reply-username">@{{ reply.user_name }}</span>
            <span class="clearn" @click.stop="handleMessageReply(reply)">清除</span>
          </span>
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
import { Avatar, Empty } from 'element-ui';
import { getUserInfo } from '@/utils/tools.js';
import { dataInterface } from '@/apis/data/index';
// TIPS bbbb 为头像预留 字段
export default {
  name: "Reamark",
  components: {
    'el-avatar': Avatar,
    'el-empty': Empty
  },
  props: {
    // 参数
    param: {
      type: Object,
      require: true,
      default() {
        return {
          graph_str_id: 'test'
        }
      }
    },
    // 对象
    object_uuid: {
      type: String,
      default: 'object64dec745110fd'
    },
    // 视图
    view_uuid: {
      type: String,
      default: 'view64dec87115d17'
    }
  },
  data() {
    return {
      userInfo: getUserInfo(), // 获取当前用户信息，用于判断是否能删除评论
      listLoading: false,
      remarkList: [],
      messageLoading: false,
      reply: null,
      inputText: '',
      placeholder: '输入您的评论，点击“Enter”发布'
    }
  },
  mounted() {
    // 获取评论列表
    this.getRemarkList();
  },
  methods: {
    /**
     * @description: 获取列表
     * @return {*}
     */
    getRemarkList() {
      const REQDATA = {
       ...this.param, //传入特殊参数
        __method_name__: 'dataList',
        object_uuid: this.object_uuid,
        view_uuid: this.view_uuid,
        transcode: 0,
      }
      this.listLoading = true;
      dataInterface(REQDATA).then(res => {
        if (res && res.status === 200) {
          if(Array.isArray(res.data.data)) {
            this.remarkList = res.data.data.map(el => ({
              ...el,
              __expand: false
            }));
          }
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.listLoading = false;
      })
    },
    deleteItem(item) {
      this.$confirm('此操作将删除该评论, 是否继续?', '提示', {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDeleteItem(item)
      }).catch(() => {
        // nothing todo          
      });
    },
    /**
     * @description: 删除小项
     * @param {*} data
     * @return {*}
     */
    handleDeleteItem(data) {
      const REQDATA = {
       ...this.param,
        __method_name__: 'deleteData',
        object_uuid: this.object_uuid,
        view_uuid: this.view_uuid,
        data_id: data.id
      }
      dataInterface(REQDATA).then(res => {
        if (res && res.status === 200) {
          this.getRemarkList()
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        // mothing to do
      })
    },
    /**
     * @description: 提交信息
     * @return {*}
     */
    handleMessage() {
      // 提交
      this.messageLoading = true;
      const REQDATA = {
       ...this.param,
        __method_name__: 'createData',
        object_uuid: this.object_uuid,
        view_uuid: this.view_uuid,
        content: this.inputText,
        parent_id: 0
      }
      if(this.reply) {
        REQDATA.to_user_id = this.reply.user_id;
        REQDATA.to_user_name = this.reply.user_name;
        REQDATA.parent_id = this.reply.parent_id || this.reply.id;
      }
      dataInterface(REQDATA).then(res => {
        if (res && res.status === 200) {
          this.inputText = ''
          this.reply = null
          this.getRemarkList()
        }
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.messageLoading = false
      })
    },
    /**
     * @description: 删除信息 本地
     * @return {*}
     */
    handleDelete() {
      if(this.reply && this.inputText === '') {
        this.reply = null;
        this.placeholder = `输入您的评论，按下“Enter”键发布`;
      }
    },
    /**
     * @description: 回复
     * @param {*} data
     * @return {*}
     */
    handleMessageReply(data) {
      if(this.reply === data) {
        this.reply = null;
        this.placeholder = `输入您的评论，按下“Enter”键发布`;
      } else {
        this.reply = data;
        this.placeholder = `输入回复,“Enter”键发布、“Backspace”键取消`;
      }
    },
    /**
     * @description: 头像错误捕捉
     * @return {*}
     */
    errorHandler() {
      return true;
    }
  }
}
</script>

<style lang="less" scoped>
  .remark-box-container{
    :deep(.el-avatar) {
      background: #579bfc;
    }

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .remark-wrap{
      flex-grow: 1;
      overflow-y: auto;
      :deep(.el-empty__description) {
        p {
          color: #666;
        }
      }
      .remark-item{
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        & + .remark-item {
          padding-top: 0;
        }
        .avatar{
          flex-shrink: 0;
        }
        .msg-wrap{
          flex-grow: 1;
          margin-left: 12px;
          .info-warp{
            display: flex;
            .user-name{
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: #707786;
            }
          }
          .remark{
            color: var(--text-on-surface-primary, #181B22);
            font-family: PingFang SC;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: 0.4px;
            word-break: break-all;
          }
          .other-info-warp{
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            .time-stamp{
              color: var(--text-on-surface-placeholder, #A4ACBD);
              font-family: PingFang SC;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
              letter-spacing: 0.4px;
            }
            .action-warp{
              display: flex;
              align-items: center;
              .action-item{
                color: var(--text-on-surface-placeholder, #A4ACBD);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 18px */
                letter-spacing: 0.4px;
                & + .action-item{
                  margin-left: 16px;
                }
                &:hover{
                  color: #409eff;
                }
              }
            }
          }
          .sub-remark{
            margin-top: 8px;
            padding: 0;
            .text-span-padding{
              padding: 0 4px;
              box-sizing: border-box;
            }
            .message-item-action{
              margin-left: 8px;
              display: flex;
              align-items: center;
            }
          }
          .expand-btn{
            margin-top: 8px;
            color: var(--text-on-surface-brand, #387FFC);
            /* web/cn/label/label-03 */
            font-family: PingFang SC;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 153.846% */
          }
        }
      }
    }
    .remark-input{
      flex-shrink: 0;
      height: 64px;
      margin-top: 8px;
      border-top: 1px solid #f1f1f1;
      .custom-input{
        width: 100%;
        height: 100%;
        display: flex;
        :deep(.el-input__inner) {
          width: 100%;
          height: 100%;
          border: none;
          padding-left: 70px;
        }
        :deep(.el-input__prefix) {
          width: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          .reply-msg{
            font-size: 12px;
            color: #409eff;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .clearn{
              display: none;
              color: red;
              line-height: 1.2;
            }
            .reply-username{
              display: inline-block;
              line-height: 1.2;
            }
            &:hover{
              .clearn{
                display: inline-block;
              }
              .reply-username{
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>
