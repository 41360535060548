import {Node} from 'bimcc-graph';

export default class TextNode extends Node {
    static  NodeType = "文字节点"; //节点类型
    static  NodeLabel = "文字节点"; //节点显示名
    static  NodePath = "文字节点"; // 节点路径

    buttonIndex = 0;

    constructor() {
        super();

        this.setInitProperty('event_name', '');
        this.setInitProperty('text', '文本内容');

        this.addInput({
            label: '输入', valueType: ['any'],
        })

        this.addOutput({
            label: "输出", valueType: 'any',
        });

        this.addWidget('text', {
            text: this.getProperty('text')
        }, '', 'text');
        // textW.bindProperty('text', (value) => {
        //     textW.setValue(value)
        // })
    }

    // eslint-disable-next-line no-unused-vars
    renderInit(render) {
        // super.renderInit(render);
        for (const key in this.properties) {
            if (key.startsWith('buttonEvent')) {
                const bIndex = key.slice(11, 12);
                this.addButtonWidget(bIndex);
            }
        }
    }

    getContextMenu() {
        return [{
            label: '添加按钮', callback: () => {
                this.addButtonWidget(this.buttonIndex);
            }
        }]
    }

    addButtonWidget(index) {
        const bIndex = index;
        const bText = 'buttonEvent' + bIndex
        //const bEventName = 'button' + bIndex + 'EventName'


        this.setInitProperty(bText, '按钮事件' + bIndex);
        //this.setInitProperty(bEventName, '按钮' + bIndex);

        const w = this.addWidget('button', {
            text: this.getProperty(bText),
            //event_name: this.getProperty(bEventName),
        }, '', bText);
        // w.bindProperty(bText, (value) => {
        //     w.setValue(value)
        // })
        // w.bindProperty(bEventName, (value) => {
        //     w.event_name = value;
        // })

        w.setContextMenu([{
            label: '删除按钮', callback: () => {
                delete this.properties[bText];
                //delete this.properties[bEventName];
                this.removeWidget(this.getWidgetIndex(w))
            }
        }])

        this.buttonIndex = ++index;
    }

}