<template>
    <div class="moduleWrap horizontal-flex">
        <div class="moduleBtn-wrap">
            <div v-for="item in btnList" :key="item.id" @click="onClickModule(item)"
                 :class="['moduleBtn-item vertical-flex', currentBtn === item.id ? 'moduleBtn-item-active' : '']">
                <img class="btnItem-icon" :src="item.icon">
                <span class="btnItem-content">{{item.name}}</span>
            </div>
        </div>

        <!--<div class="moduleBtn-bottomWrap vertical-flex">
            <a class="btnItem-icon iconfont iconfanhui"></a>
            <span class="btnItem-content">返回</span>
        </div>-->

        <div class="moduleContent-wrap">
            <DataEditor
                    v-if="currentBtn==='data'"
                    ref="dataEditor"
                    :bindViewerId="bindViewerId"
                    :element.sync="element"
                    @clickImagery="onChangeImagery"
                    @clickTerrain="onChangeTerrain"
                    @clickKml="onCreateKml"
                    @applyKml="onApplyKml"
                    @cancelKml="onCancelKml"
                    @saveKml="onSaveKml"
            ></DataEditor>
            <ModelEditor v-if="currentBtn==='model'" :element.sync="element"></ModelEditor>
            <EntityEditor
                    v-if="currentBtn==='entity'"
                    ref="entityEditor"
                    :bindViewerId="bindViewerId"
                    :element.sync="element"
                    @clickPoint="onCreatePoint"
                    @clickMarker="onCreateMarker"
                    @clickPlot="onCreatePlot"
                    @cancelEntity="onCancelEntity"
                    @editApply="onApplyEntity"
                    @editCancel="onCancelEntity"
                    @editSave="onSaveEntity"
            ></EntityEditor>
            <SceneEditor
                    v-if="currentBtn==='scene'"
                    ref="sceneEditor"
                    :bindViewerId="bindViewerId"
                    :element.sync="element"
                    @clickEnvironment="onClickEnvironment"
                    @clickEffection="onClickEffection"
                    @saveEnvironment="onSaveEnvironment"
                    @saveEffection="onSaveEffection"
            ></SceneEditor>
        </div>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import { dataInterface } from '@/apis/data/index';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import DataEditor from "./components/DataEditor";
    import ModelEditor from "./components/ModelEditor";
    import EntityEditor from "./components/EntityEditor";
    import SceneEditor from "./components/SceneEditor";
    import img_data from './background/data.png';
    import img_model from './background/model.png';
    import img_entity from './background/entity.png';
    import img_terrain from './background/terrain.png';
    import img_scene from './background/scene.png';
    import img_analysis from './background/analysis.png';
    import img_tool from './background/tool.png';
    import img_library from './background/library.png';

    const DataBaseInfo = {
        Point: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        },
        Marker: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        },
        Plot: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        },
        Scene: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        },
    };

    export default {
        name: "ViewerGISEditor",
        components: {
            DataEditor,
            ModelEditor,
            EntityEditor,
            SceneEditor
        },
        data(){
            return {
                bindViewerId: null,
                bindLayerId: null,
                isViewerInited: false,

                layerCurrentNode: null,

                btnList: [
                    {id: 'data', name: '地图数据', icon: img_data},
                    {id: 'model', name: '模型编辑', icon: img_model},
                    {id: 'entity', name: '标记标绘', icon: img_entity},
                    {id: 'terrain', name: '地形调整', icon: img_terrain},
                    {id: 'scene', name: '场景效果', icon: img_scene},
                    {id: 'analysis', name: '分析', icon: img_analysis},
                    {id: 'tool', name: '工具', icon: img_tool},
                    {id: 'library', name: '素材库', icon: img_library},
                ],
                currentBtn: 'data',

                sceneData: null,
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted(){
            this.initEventListener();

            this.initCheckBind();

            if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && this.bindViewerId){
                eventBus.$emit("ViewerGIS.getViewerInitedFlag", this.bindViewerId, (opt = {}) => {
                    let {viewerInited = false} = opt;
                    if(viewerInited){
                        this.getSceneData(this.bindViewerId);
                    }else{
                        this.initViewerInitedEventListener();
                    }
                });
            }else{
                this.initViewerInitedEventListener();
            }
        },
        methods: {
            initEventListener() {
                eventBus.$on('ViewerGISEditor.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = [], type) => {
                        for (let item of array) {
                            if (item.component !== type) {
                                if (item.children && item.children.length > 0){
                                    loop(item.children, resList, type);
                                }
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const viewerComps = [];
                    loop(this.subComponentData, viewerComps, 'ViewerGIS');
                    // loop(this.subComponentData, viewerComps, 'ViewerGIS');

                    const layerComps = [];
                    loop(this.subComponentData, layerComps, 'ViewerGISLayer');
                    // loop(this.subComponentData, layerComps, 'ViewerGISLayer');

                    cb({
                        viewerList: viewerComps,
                        viewerBindId: this.bindViewerId,
                        layerList: layerComps,
                        layerBindId: this.bindLayerId,
                    });
                });

                eventBus.$on('ViewerGISEditor.BindSource', (eid, {viewerBindId, layerBindId}) => {
                    if (eid !== this.element.id) return;

                    if(this.checkBind(viewerBindId)){
                        this.element.viewerBindId = viewerBindId;
                        this.bindViewerId = viewerBindId;
                        this.$message.success(`[${this.element.name}] 绑定GIS Viewer成功`);
                    }

                    if(this.checkBind(layerBindId)){
                        this.element.layerBindId = layerBindId;
                        this.bindLayerId = layerBindId;
                        this.$message.success(`[${this.element.name}] 绑定GIS图层组件成功`);
                    }
                });

                eventBus.$on('ViewerGISEditor.layerCurrentChange', (eid, node) => {
                    if (eid !== this.bindLayerId) return;

                    this.layerCurrentNode = node;
                });
            },
            initViewerInitedEventListener(){
                eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                    if (eid !== this.bindViewerId) return;

                    let {viewerInited = false} = opt;
                    if(viewerInited) this.getSceneData(this.bindViewerId);
                });
            },
            initCheckBind(){
                if (this.element.viewerBindId) {
                    if (this.checkBind(this.element.viewerBindId)) {
                        this.bindViewerId = this.element.viewerBindId;
                    }
                }

                if (this.element.layerBindId) {
                    if (this.checkBind(this.element.layerBindId)) {
                        this.bindLayerId = this.element.layerBindId;
                    }
                }
            },
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },

            getDataSearch(type = 'GISScene') {
                const archi_id = this.$GetTargetArchi('id');

                let search = [
                    {
                        code: 'archi_type',
                        ruleType: 'eq',
                        value: archi_id
                    },
                    {
                        code: 'type',
                        ruleType: 'eq',
                        value: type
                    }
                ];

                return search;
            },
            async getSceneData(bindId = this.bindViewerId){
                if (!bindId) return;

                const sceneRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Scene.object_uuid,
                    view_uuid: DataBaseInfo.Scene.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch('GISScene')
                });
                if (sceneRes?.data?.data && sceneRes?.data?.data?.data && sceneRes.status === 200) {
                    const list = sceneRes?.data?.data?.data;
                    if(list.length === 0) {
                        return;
                    }

                    let {id, name, group_id, state = {}} = list[0];
                    this.sceneData = {
                        id: id,
                        name: name,
                        module: 'scene',
                        group_id: group_id,
                        state: state
                    };
                    this.setSceneSetting(state);
                }
            },
            getEntityFile(){
                if(!this.layerCurrentNode) return {pId: 'gis', group_id: -1};

                let row = this.layerCurrentNode.data;
                if(row.module !== 'gis') return {pId: 'gis', group_id: -1};

                if(row.type === 'leaf') return row.pId === 'gis' ? {pId: 'gis', group_id: -1} : {pId: row.pId, group_id: row.group_id};

                return {pId: row.id, group_id: row.key};
            },
            setSceneSetting(state){
                let {environment, effection} = state;

                if(environment){
                    for (let key in environment){
                        this.onClickEnvironment(key, {value: environment[key]});
                    }
                }

                if(effection){
                    for (let key in effection){
                        if(key === 'ambientOcclusionOptions' || key === 'bloomOptions') continue;

                        if(key === 'ambientOcclusion'){
                            this.onClickEffection(key, {
                                isShow: effection[key],
                                value: effection.ambientOcclusionOptions
                            });
                        }else if(key === 'bloom'){
                            this.onClickEffection(key, {
                                isShow: effection[key],
                                value: effection.bloomOptions
                            });
                        }else{
                            this.onClickEffection(key, {value: effection[key]});
                        }
                    }
                }
            },

            async createSceneData(options){
                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Scene.object_uuid,
                    view_uuid: DataBaseInfo.Scene.view_uuid['all'],
                    type: 'GISScene',
                    name: 'GIS场景配置',
                    group_id: -1,
                    state: JSON.stringify(options)
                });

                if (!res?.data?.data || res.status !== 200){
                    return this.$message.error(`[GIS]保存场景配置失败！`);
                }
                const data = res?.data?.data;

                this.sceneData = {
                    id: data.id,
                    name: 'GIS场景配置',
                    module: 'scene',
                    group_id: -1,
                    state: options
                };
                this.$message.success(`[GIS]保存场景配置成功！`);
            },
            async updateSceneData(options){
                let {state} = this.sceneData;
                state = Object.assign(state, options);

                await dataInterface({
                    __method_name__: 'updateData',
                        object_uuid: DataBaseInfo.Scene.object_uuid,
                    view_uuid: DataBaseInfo.Scene.view_uuid['all'],
                    data_id: this.sceneData.id,
                    state: JSON.stringify(state)
                });

                this.sceneData.state = state;
                this.$message.success(`[GIS]保存场景配置成功！`);
            },

            onClickModule(row){
                this.currentBtn = row.id;
            },
            onChangeImagery(type, options = {}){
                if(type === 'CustomMap'){
                    eventBus.$emit("ViewerGIS.addImageryByType", this.bindViewerId, options);
                    if(this.bindLayerId){
                        eventBus.$emit("ViewerGISLayer.onEditorAdd", this.bindLayerId, 'addImagery', {
                            id: options.mapId,
                            name: options.mapName,
                            tag: options.type,
                            state: {
                                mapType: options.type,
                                options: {
                                    mapUrl: options.mapUrl,
                                    mapType: options.mapType,
                                    mapOrigin: options.mapOrigin
                                }
                            }
                        });
                    }
                }else{
                    eventBus.$emit("ViewerGIS.setImageryLayer", this.bindViewerId, type, options);
                    if(this.bindLayerId){
                        eventBus.$emit("ViewerGISLayer.onEditorAdd", this.bindLayerId, 'switchImagery', {
                            name: options.name,
                            tag: options.type
                        });
                    }
                }
            },
            onChangeTerrain(type, options = {}){
                let name;
                switch (type) {
                    case 'IonWorldTerrain':
                        eventBus.$emit("ViewerGIS.setTerrain", this.bindViewerId, options.isShow);
                        name = 'Ion世界地形服务';

                        if(this.bindLayerId){
                            eventBus.$emit("ViewerGISLayer.onEditorAdd", this.bindLayerId, options.isShow ? 'addTerrain' : 'deleteTerrain', {
                                id: 'terrain',
                                name: name,
                                tag: type,
                            });
                        }
                        break;
                }
            },
            onCreateKml(){
                eventBus.$emit('ViewerGIS.createKmlByLocal', this.bindViewerId, 'temp', (res) => {
                    let {options} = res;

                    let data = {
                        plotType: 'kml',
                        options: options
                    };

                    this.$refs.dataEditor.setEditorByKml(data);
                });
            },
            onApplyKml(data){
                eventBus.$emit('ViewerGIS.updateKml', this.bindViewerId, 'temp', data);
            },
            onCancelKml(){
                eventBus.$emit('ViewerGIS.removeKml', this.bindViewerId, 'temp');
                this.$refs.dataEditor.setEditorByEmpty();
            },
            async onSaveKml(data){
                const createItem = async (database, vType, vName, vPId, vState) => {
                    const res = await dataInterface({
                        __method_name__: 'createData',
                        object_uuid: database.object_uuid,
                        view_uuid: database.view_uuid['all'],
                        type: vType,
                        name: vName,
                        group_id: vPId,
                        state: JSON.stringify(vState)
                    });

                    if (!res?.data?.data || res.status !== 200){
                        this.$message.warning(`[GIS]创建对象失败！`);
                        return false;
                    }
                    const resData = res?.data?.data;

                    return resData.id;
                };

                let name = await this.$prompt('请输入对象名称：', '提示').catch(() => {});
                if(!name) return;

                eventBus.$emit('ViewerGIS.saveKmlByLocal', this.bindViewerId, 'temp', name.value, async (res) => {
                    if(!res) return this.$message.warning(`[GIS]保存Kml文件失败！`);

                    let {pId, group_id} = this.getEntityFile();
                    let state = {
                        plotType: data.plotType,
                        kmlFile: res,
                        options: data.options,
                    };
                    let msg = await createItem(DataBaseInfo.Plot, 'GISPlot', name.value, group_id, state);
                    if(!msg) return this.$message.warning(`[GIS]创建Kml对象失败！`);

                    let item = {
                        id: `gis_leaf_${msg}`,
                        key: msg,
                        name: name.value,
                        type: 'leaf',
                        module: 'gis',
                        tag: data.plotType,
                        pId: pId,
                        group_id: group_id,
                        state: data
                    };

                    eventBus.$emit('ViewerGIS.removeKml', this.bindViewerId, 'temp');
                    eventBus.$emit('ViewerGIS.createKmlByServe', this.bindViewerId, item.id, state, true);

                    if(this.bindLayerId && msg){
                        eventBus.$emit("ViewerGISLayer.onEditorAdd", this.bindLayerId, 'addEntity', item);
                    }

                    this.$refs.dataEditor.setEditorByEmpty();
                    this.$message.success(`[GIS]创建对象成功！`);
                });
            },

            onCreatePoint(pointType, data){
                eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.bindViewerId, (position) => {
                    data = Object.assign({
                        position,
                        module: 'point'
                    }, data);

                    eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.bindViewerId);
                    eventBus.$emit('ViewerGIS.createMarker', this.bindViewerId, 'temp', data, true);
                    this.$refs.entityEditor.setActiveEntity(data.module, data);
                });
            },
            onCreateMarker(markerType, data){
                eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.bindViewerId, (position) => {
                    data = Object.assign({
                        position,
                        module: 'marker'
                    }, data);

                    eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.bindViewerId);
                    eventBus.$emit('ViewerGIS.createTemplateMarker', this.bindViewerId, 'temp', data, true);
                    this.$refs.entityEditor.setActiveEntity(data.module, data);
                });
            },
            onCreatePlot(plotType, drawType){
                const createActiveByPlot = (plotType, data) => {
                    eventBus.$emit('ViewerGIS.getPlotOptions', this.bindViewerId, plotType, data.options, (res) => {
                        data.module = 'plot';
                        data.options = res;
                        data.plotType = res.plotType;

                        eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.bindViewerId);
                        eventBus.$emit('ViewerGIS.createPlot', this.bindViewerId, 'temp', data, true);
                        this.$refs.entityEditor.setActiveEntity(data.module, data);
                    });
                };

                switch (drawType) {
                    case 'point':
                        eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [res];
                            let data = {
                                plotType: plotType,
                                positions: positions,
                                options: {}
                            };

                            createActiveByPlot(plotType, data);
                        });
                        break;
                    case 'polyline':
                        eventBus.$emit('ViewerGIS.drawPolylineByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [];
                            res.map((item) => {
                                positions.push({lng: item[0], lat: item[1]});
                            });

                            let data = {
                                plotType: plotType,
                                positions: positions,
                                options: {}
                            };

                            createActiveByPlot(plotType, data);
                        });
                        break;
                    case 'circle':
                        eventBus.$emit('ViewerGIS.drawCircleByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [res.position];

                            let data = {
                                plotType: plotType,
                                positions: positions,
                                options: {
                                    radius: res.radius
                                }
                            };

                            createActiveByPlot(plotType, data);
                        });
                        break;
                    case 'rectangle':
                        eventBus.$emit('ViewerGIS.drawRectangleByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [
                                {lng: res.west, lat: res.south},
                                {lng: res.east, lat: res.south},
                                {lng: res.east, lat: res.north},
                                {lng: res.west, lat: res.north},
                            ];

                            let data = {
                                plotType: plotType,
                                positions: positions,
                                options: {

                                }
                            };

                            createActiveByPlot(plotType, data);
                        });
                        break;
                    case 'polygon':
                        eventBus.$emit('ViewerGIS.drawPolygonByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [];
                            res.map((item) => {
                                positions.push({lng: item[0], lat: item[1]});
                            });

                            let data = {
                                plotType: plotType,
                                positions: positions,
                                options: {}
                            };

                            createActiveByPlot(plotType, data);
                        });
                        break;
                }
            },
            onApplyEntity(module, data){
                switch (module) {
                    case 'point':
                        eventBus.$emit('ViewerGIS.updateMarker', this.bindViewerId, 'temp', data);
                        break;
                    case 'marker':
                        eventBus.$emit('ViewerGIS.updateTemplateMarker', this.bindViewerId, 'temp', data);
                        break;
                    case 'plot':
                        eventBus.$emit('ViewerGIS.updatePlot', this.bindViewerId, 'temp', data);
                        break;
                }
            },
            onCancelEntity(module){
                if(!module){
                    eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.bindViewerId);
                    return;
                }

                switch (module) {
                    case 'point':
                        eventBus.$emit('ViewerGIS.removeMarker', this.bindViewerId, 'temp');
                        break;
                    case 'marker':
                        eventBus.$emit('ViewerGIS.removeTemplateMarker', this.bindViewerId, 'temp');
                        break;
                    case 'plot':
                        eventBus.$emit('ViewerGIS.removePlot', this.bindViewerId, 'temp');
                        break;
                }
                this.$refs.entityEditor.setEmptyEntity();
            },
            async onSaveEntity(module, data){
                const createItem = async (database, vType, vName, vPId, vState) => {
                    const res = await dataInterface({
                        __method_name__: 'createData',
                        object_uuid: database.object_uuid,
                        view_uuid: database.view_uuid['all'],
                        type: vType,
                        name: vName,
                        group_id: vPId,
                        state: JSON.stringify(vState)
                    });

                    if (!res?.data?.data || res.status !== 200){
                        this.$message.warning(`[GIS]创建对象失败！`);
                        return false;
                    }
                    const resData = res?.data?.data;

                    return resData.id;
                };

                let name = await this.$prompt('请输入对象名称：', '提示').catch(() => {});
                if(!name) return;

                let {pId, group_id} = this.getEntityFile();
                let item = {};
                let msg = false;
                switch (module) {
                    case 'point':
                        eventBus.$emit('ViewerGIS.removeMarker', this.bindViewerId, 'temp');
                        msg = await createItem(DataBaseInfo.Point, 'GISPoint', name.value, group_id, data);
                        if(msg){
                            item = {
                                id: `gis_leaf_${msg}`,
                                key: msg,
                                name: name.value,
                                type: 'leaf',
                                module: 'gis',
                                tag: data.pointType,
                                pId: pId,
                                group_id: group_id,
                                state: data
                            };
                            eventBus.$emit('ViewerGIS.createMarker', this.bindViewerId, item.id, data, true);
                        }
                        break;
                    case 'marker':
                        eventBus.$emit('ViewerGIS.removeTemplateMarker', this.bindViewerId, 'temp');
                        msg = await createItem(DataBaseInfo.Marker, 'GISMarker', name.value, group_id, data);
                        if(msg){
                            item = {
                                id: `gis_leaf_${msg}`,
                                key: msg,
                                name: name.value,
                                type: 'leaf',
                                module: 'gis',
                                tag: data.markerType,
                                pId: pId,
                                group_id: group_id,
                                state: data
                            };
                            eventBus.$emit('ViewerGIS.createTemplateMarker', this.bindViewerId, item.id, data, true);
                        }
                        break;
                    case 'plot':
                        eventBus.$emit('ViewerGIS.removePlot', this.bindViewerId, 'temp');
                        msg = await createItem(DataBaseInfo.Plot, 'GISPlot', name.value, group_id, data);
                        if(msg){
                            item = {
                                id: `gis_leaf_${msg}`,
                                key: msg,
                                name: name.value,
                                type: 'leaf',
                                module: 'gis',
                                tag: data.plotType,
                                pId: pId,
                                group_id: group_id,
                                state: data
                            };
                            eventBus.$emit('ViewerGIS.createPlot', this.bindViewerId, item.id, data, true);
                        }
                        break;
                }

                if(this.bindLayerId && msg){
                    eventBus.$emit("ViewerGISLayer.onEditorAdd", this.bindLayerId, 'addEntity', item);
                }

                this.$refs.entityEditor.setEmptyEntity();
                this.$message.success(`[GIS]创建对象成功！`);
            },

            onClickEnvironment(type, options){
                eventBus.$emit("ViewerGIS.setSceneSetting", this.bindViewerId, type, options);
            },
            onClickEffection(type, options){
                switch (type) {
                    case 'depthTest':
                        eventBus.$emit("ViewerGIS.setDepthTest", this.bindViewerId, options.value);
                        break;
                    default :
                        eventBus.$emit("ViewerGIS.setSceneSetting", this.bindViewerId, type, options);
                        break;
                }
            },
            async onSaveEnvironment(options){
                if(!this.sceneData){
                    await this.createSceneData({environment: options})
                }else{
                    await this.updateSceneData({environment: options})
                }
            },
            async onSaveEffection(options){
                if(!this.sceneData){
                    await this.createSceneData({effection: options})
                }else{
                    await this.updateSceneData({effection: options})
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .moduleBtn-wrap{
        padding-top: 10px;
        width: 65px;
        height: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        overflow: hidden;
        backdrop-filter: blur(5px);
    }

    .moduleBtn-bottomWrap{
        position: absolute;
        left: 2px;
        bottom: 20px;
        width: 60px;
        height: 50px;
        line-height: 25px;
        border-radius: 5px;
        background-color: inherit;
    }

    .moduleBtn-bottomWrap:hover{
        background-color: rgba(131, 131, 131, 0.5);
    }

    .moduleBtn-item{
        display: flex;
        margin-left: 2px;
        margin-top: 5px;
        padding-top: 10px;
        width: 60px;
        height: 50px;
        line-height: 25px;
        border-radius: 5px;
    }

    .moduleBtn-item:hover{
        background-color: rgba(131, 131, 131, 0.5);
    }

    .moduleBtn-item-active{
        background-color: rgba(131, 131, 131, 0.5);
    }

    .btnItem-icon{
        margin: auto;
        width: 25px;
        height: 25px;
        text-align: center;
        fill: currentColor;
    }

    .btnItem-content{
        color: inherit;
        font-size: 12px;
    }

    .moduleContent-wrap{
        width: calc(100% - 65px);
        overflow: hidden;
        backdrop-filter: blur(5px);
    }

</style>
