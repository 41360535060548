<!--
 * @Description: 顶部
 * @Author: luocheng
 * @Date: 2022-08-01 10:21:10
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-12-18 17:24:32
-->
<template>
  <div class="calendar-header">
    <!-- eslint-disable -->
    <span @click="onQuickSwitch('prevYear', $event)"><<</span>
    <span @click="onQuickSwitch('prevMonth', $event)" v-if="showMonth"><</span>
    <p class="title">{{ year }}年<template v-if="showMonth"> {{ month }}月</template></p>
    <span @click="onQuickSwitch('nextMonth', $event)" v-if="showMonth">></span>
    <span @click="onQuickSwitch('nextYear', $event)">>></span>
</div>
</template>

<script>
export default {
  name: 'CalendarHeader',
  props: {
    year: {
      required: true,
      default: ''
    },
    month: {
      required: false,
      default: ''
    },
    headerType: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    showMonth() {
      return this.headerType !== 'year';
    }
  },
  methods: {
    /**
     * @desc: 快捷切换
     * @param {String} type 类型
     */
    onQuickSwitch(type, e) {
      e.stopPropagation();
      e.preventDefault();
      switch(type) {
        case 'prevYear':
          this.$emit('setQuickDate', {
            year: this.year - 1,
            month: this.month
          });
          break;
        case 'prevMonth':
          if (+this.month === 1) {
            this.$emit('setYear', this.year - 1);
            this.$emit('setQuickDate', {
              year: this.year - 1,
              month: 12
            });
          } else {
            this.$emit('setQuickDate', {
              year: this.year,
              month: this.addZero(+this.month - 1)
            });
          }
          break;
        case 'nextYear':
          this.$emit('setQuickDate', {
            year: +this.year + 1,
            month: this.month
          });
          break;
        case 'nextMonth':
          if (+this.month === 12) {
            this.$emit('setQuickDate', {
              year: +this.year + 1,
              month: '01'
            });
          } else {
            this.$emit('setQuickDate', {
              year: +this.year,
              month: this.addZero(+this.month + 1)
            });
          }
          break;
      }
    },
    /**
     * @desc: 补零
     * @param {Number/Number} val 补零
     * @return {String/Number}
     */
    addZero(val) {
      if (isNaN(+val)) return val;
      if (val >= 0 && val < 10) {
        return `0${+val}`;
      }
      return val;
    }
  }
}
</script>

<style lang="less" scoped>
.calendar-header{
  margin: 12px 12px 0;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 0;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef5;
  span{
    font-size: 14px;
    margin-right: 10px;
    font-weight: bold;
    user-select: none;
    &:hover{
      color: @theme;
    }
  }
  .title{
    width: auto;
    margin-right: 10px;
    font-size: 14px;
    user-select: none;
    color: #333;
    font-weight: bold;
  }
}
</style>