<!--
 * @Date: 2024-07-23 14:21:38
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-08-15 10:31:53
 * @FilePath: \dataview-viewer-test\src\custom-component\viewerBIMModules\DunGou.vue
-->
<template>
    <div ref="view">
    </div>
</template>
<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
    import { Viewer } from './DunGouViewer.js';
    import { mapState } from 'vuex';
    import { dataInterface } from '@/apis/data/index';
    export default {
        name: "DunGou",
        components: {
        },
        data(){
            return{
            }
        },
        computed: {
            ...mapState(['componentData', 'originComponentData']),
        },
        props :{
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {
                    }
                }
            },
            // 是否在组合内
            isGroup: {
                type: Boolean,
            },
            // 组合内组件列表
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins : [CustomComponentMixin,BehaviorHandlerMixin],
        methods: {
            async initViewer(){
                const viewer = new Viewer(this.$refs.view);
                const data = await this.getDunGouInfo();
                viewer.drawDungou({
                        x : data.guideAHA ? parseFloat( data.guideAHA ) : 0,//导向水平后
                        y : data.guideVR  ? parseFloat( data.guideVR )  : 0,//导向垂直后
                    },{
                        x : data.guideHF ?  parseFloat( data.guideHF ) : 0, //导向水平前
                        y : data.guideVF ?  parseFloat( data.guideVF ) : 0, //导向垂直前
                    }, 
                    data.guideSA ? parseFloat( data.guideSA ) : 0, //俯仰角
                    data.guideRP ? parseFloat( data.guideRP ) : 0, //滚动角
                );
            },
            async getDunGouInfo(){
                const database = this.element.database;

                if( database.mapping !== "interface"){
                    this.$message.error('盾构:配置错误!');
                    return {};
                } 
                const interfaceConfig = database.interfaceConfig;
                const paramsConfig = database.paramsConfig;
                if( paramsConfig.length === 0 || !interfaceConfig.url ) {
                    this.$message.error('盾构:配置参数错误!');
                    return {};
                }

                let type = 0;
                let deviceName = "";
                let url = interfaceConfig.url;

                if ( url?.indexOf('http') === -1) {
					url = `/api${interfaceConfig.url}`;
				} 

                for(let param of paramsConfig){
                    if(param.key === 'type'){
                        type = parseInt(param.fixedValue);
                    }

                    if(param.key === 'deviceName'){
                        deviceName = param.fixedValue;
                    }
                }

                const res = await dataInterface({
                    __method_name__ : "dataList",
                    search : {
                        type,
                        deviceName,
                    },
                    type,
                    deviceName,
                },url);

                if( res.data.code !== 200) {
                    this.$message.error('盾构:请求出错!');
                    return {}
                }

                return res.data.data;
            }
        },
        mounted(){
            this.initViewer();
        },
        destroyed(){
        },
};
</script>

<style lang="less" scoped>
</style>
