/*
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-14 10:29:45
 * @Descripttion: 
 */
export default {
	computed:{
		columnClass() {
			const arr = ['first-column','second-column','three-column'];
			const span = this.element.config.__ques__.optSpan
			let number = 0 // 选项展示一栏
			if(span === 12) number = 1 // 选项展示二栏
			if(span === 8) number = 2  // 选项展示三栏
			return arr[number]
		},
	},
	data () {
		return {
			optNumber: ['A','B','C','D','E','F','G','H','J','O','P','Q','R'],
			optRoman: ['Ⅰ','Ⅱ','Ⅲ','Ⅳ','Ⅴ','Ⅵ','Ⅶ','Ⅷ','Ⅸ','Ⅹ'],
			optChinese:['一','二','三','四','五','六','七','八','九','十','十一','十二','十三','十四','十五','十六','十七','十八','十九','二十']
		}
	},
	methods: {}
}
