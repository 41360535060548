import IBehaviors from './IBehaviors';

export default class SceneBehaviors extends IBehaviors {
    constructor(viewer, comp) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        const viewer = this.viewer;

        return [
            {
                label: '[场景]飞行至默认视角',
                desc: `
                    参数[ 无 ]。 
                    `,
                value: 'scene_flyDefaultView',
                func: () => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    viewer.Scene.flyDefaultView();
                }
            },
        ];
    }
}
