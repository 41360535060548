<!--
 * @Author: wsp
 * @Date: 2021-10-22 14:29:14
 * @LastEditors: zx
 * @LastEditTime: 2023-02-21 18:07:32
 * @Description:
-->

<template>
  <div class="file-upload">
    <el-upload
      v-if="!disabled"
      :action="uploadURL"
      :before-upload="beforeUpload"
      class="upload-style"
      :data="{ token }"
      :headers="{
        metadatatoken: '4fb0b638-2c43-4d7e-8acf-f1a7abdee58easdfasdfqwerqwer',
      }"
      :multiple="multiple"
      :on-success="onSuccess"
      :show-file-list="uploading"
    >
      <el-button :icon="icon" type="default">
        <i class="iconfont iconjijia_shangchuan" style="font-size: 16px" />
        {{ btnName }}
      </el-button>
      <!-- <div v-if="drag" class="drag-text">
        <i
          class="iconfont iconjijia_shangchuan"
          style="font-size: 24px; display: block; margin-top: 20px"
        ></i>
        <p style="font-size: 14px; font-weight: 400">
          <span style="color: #202126">拖拽上传</span>
          <span style="color: #8a8f99; margin: 0 8px">或</span>
          <span class="public-color">点击上传</span>
        </p>
      </div> -->
    </el-upload>
    <div class="upload-img-area">
      <div v-for="(item, index) in imageArr" :key="index">
        <div class="upload-img-item">
          <el-image :src="url + '/' + item.thumb_path" @click="handleView(index)" />
          <!-- :preview-src-list="domainImageArr" -->
          <div v-show="!disabled" class="del-image">
            <i
              class="iconfont iconshanchu"
              style="color: #fff; font-size: 18"
              @click.stop="handleRemoveImg(index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-loading="uploadLoading"
      v-if="Array.isArray(fileArr) && fileArr.length"
      class="upload-files-area"
    >
      <el-table border :data="fileArr" style="width: 100%">
        <el-table-column label="附件名称" prop="process_name">
          <template #default="scope">
            <div>
              <img
                alt=""
                :src="fileType(scope.row.filename)"
                style="width: 20px; vertical-align: middle"
              >
              <span class="file-name" :title="scope.row.filename">
                {{ scope.row.filename }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="上传人"
          prop="user_name"
          width="120"
        />
        <el-table-column
          align="center"
          label="上传时间"
          prop="upTime"
          width="170"
        />
        <el-table-column align="center" label="操作" prop="" width="110">
          <template #default="scope">
            <div>
              <span class="operation-primary" @click="openFile(scope.row)">
                预览
              </span>
              <!-- 下载使用拼接避免filepath会取当前域名 -->
              <span
                v-if="!disabled ? false : true"
                style="color: #087AFF;padding-left:10px"
                @click="downloadFile(scope.row.filepath.indexOf('http') > -1 ? scope.row.filepath : url + '' + scope.row.filepath, scope.row.name)"
              >
                下载
              </span>
              <!-- <a
                v-if="!disabled ? false : true"
                class="operation-down"
                :download="scope.row.name"
								target="_blank"
                :href="url+''+scope.row.filepath"
                style="padding-left:10px"
              >
                下载
              </a> -->
              <span
                v-if="disabled ? false : true"
                class="operation-del"
                @click="handleRemove(scope.index)"
              >
                删除
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div style="width: 100%; height: 100%">
        <el-image fit="fill" :src="dialogImageUrl" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { parseTime } from '@/utils/tools'
// 需要获取baseURL
// getToken
import { baseUrl } from "@/plugins/util";
import { downloadFileBlob,openUrl } from '@/utils/tools';

// 文件图标
const pdf = '//static.bimcc.com/images/disk/pdf.png'
const ppt = '//static.bimcc.com/images/disk/ppt.png'
const word = '//static.bimcc.com/images/disk/word.png'
const excel = '//static.bimcc.com/images/disk/excel.png'
const none = '//static.bimcc.com/images/disk/none.png'

import {
	Upload,
	Button,
	Table,
	TableColumn,
	Dialog,
	Image,
} from 'element-ui'

export default {
	components: {
		'el-upload': Upload,
		'el-button': Button,
		'el-table': Table,
		'el-table-column': TableColumn,
		'el-dialog': Dialog,
		'el-image': Image,
	},
  props: {
    // 文件
    files: {
      type: Array,
      default () {
        return []
      }
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 按钮名
    btnName: {
      type: String,
      default: '上传文件'
    },
    // icon
    icon: {
      type: String,
      default: ''
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: true
    },
    // 允许上传的文件格式
    fileTypeList: {
      type: Array,
      default: () => []
    }
  },
  // emits: ['extra-files'],
  // setup () {
  //   const uploadURL = ref(baseUrl + '/api/mapi?__method_name__=file') // 上传地址
  //   const token = ref(getToken()) // token
  //   const imageType = ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'] // 图片类型
  //   return { uploadURL, token, imageType }
  // },
  data () {
    return {
      extra_file: [],
      uploading: false,
      currentUser: '', // 当前用户
      imageArr: [], // 图片数组
      fileArr: [], // 文件数组
      url: baseUrl(),
      dialogVisible: false,
      dialogImageUrl: '',
      uploadLoading: false
    }
  },
  watch: {
    files: {
      handler (files) {
        this.imageArr = []
        this.fileArr = []
        if (files && files.length !== 0) {
          files.map((item) => {
            if (typeof item === 'object') {
              if (
                this.imageType.indexOf(
                  item.filepath
                    .substring(item.filepath.lastIndexOf('.') + 1)
                    .toLowerCase()
                ) > -1
              ) {
                this.imageArr.push(item)
              } else {
                this.fileArr.push(item)
              }
            } else {
              this.imageArr.push({
                name: '',
                upUser: '未知',
                upTime: parseTime(new Date()),
                path: item
              })
            }
          })
          this.extra_file = files
        }
      },
      immediate: true,
      deep: true
    },
    extra_file: {
      handler (data) {
        this.loading = false
        this.$emit('extra-files', data, this.index)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    /**
       * @description 上传成功回调
       * @param {*} response
       * @param {*} file
       * @param {*} fileList
       */
    onSuccess (res) {
      this.uploading = false
      if (!Array.isArray(this.extra_file)) {
        this.extra_file = []
      }
      /* this.extra_file.push({
          name: file.name,
          upUser: this.currentUser,
          upTime: parseTime(new Date()),
          path: response.data.filepath,
        }) */

      this.uploading = false
      if (this.extra_file == null) {
        this.extra_file = []
      }
      // 判断上传文件是否为图片
      res.data.upTime = parseTime(new Date())
      res.data.name = res.data.filename
      if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
        res.data.path = res.data.thumb_path
      } else {
        res.data.path = res.data.filepath
      }
      this.extra_file.push(res.data)
    },
    /**
       * @description 上传前置钩子
       */
    beforeUpload (file) {
      this.uploading = true
      if (this.fileTypeList.length > 0) {
        const arr = []
        this.fileTypeList.forEach((item) => {
          if (file.name.indexOf(item) > -1) {
            arr.push(file.name)
          }
        })
        if (arr.length === 0) {
          this.$Message.error(
            '请上传 ' + this.fileTypeList.join(',') + ' 格式的文件！'
          )
          return false
        }
      }
    },
    /**
       * @description 查看图片
       * @param {Number} index
       */
    handleView (index) {
			const isThumb =  this.imageArr[index].filepath.indexOf('_thumb') !== -1
			let str = '';
			if(isThumb) {
				let path = this.imageArr[index].filepath.split('_thumb');
				if (path[1] && path[1] !== 'undefined') {
					str = path[0] + '' + path[1];
				} else {
					str = path[0];
				}
			} else {
				str = this.imageArr[index].filepath
			}
      this.dialogImageUrl = this.url + '/' + str
			this.dialogVisible = true;
      // 暂时没有实现
      // let arr=[]
      // this.imageArr.map(item=>{
      //     arr.push(item.path)
      // })
      // this.$store.commit('setImageUrls', {data: arr,index :index})
    },
    /**
       * @description 查看文件预览
       * @param {Object} row
       */
    openFile (row) {
      if (
        ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        // this.$store.commit('setImageUrls', { data: [row.path] })
        this.dialogImageUrl = row.path
        this.dialogVisible = true
      } else if (
        ['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        let url = 'https://weboffice.bimcc.net?furl='
        if (row.path.includes('https://')) {
          url = 'https://weboffice.bimcc.net?ssl=1&furl='
        }
        // window.open(
        //   url + row.path
        // )
        openUrl(url + row.path,url + row.path)
      } else if (
        ['mp4'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        // window.open(this.url + '/' + row.path)
        openUrl(this.url + '/' + row.path,this.url + '/' + row.path)
      } else {
        this.$Message.info('无法打开此格式的文件！')
      }
    },
    /**
       * @description 移除图片
       * @param {Number} index
       */
    handleRemoveImg (index) {
      this.imageArr.splice(index, 1)
      this.extra_file = [].concat(this.imageArr, this.fileArr)
    },
    /**
       * @description 移除文件
       * @param {Number} index
       */
    handleRemove (index) {
      this.fileArr.splice(index, 1)
      this.extra_file = [].concat(this.imageArr, this.fileArr)
    },
    /**
       * @description 返回文件类型
       * @param {String} url
       */
    fileType (url) {
      if (url.indexOf('pdf') > -1) return pdf
      else if (url.indexOf('ppt') > -1) return ppt
      else if (url.indexOf('doc') > -1) return word
      else if (url.indexOf('xls') > -1) return excel
      else return none
    },
    /**
     * @description 文件下载
     * @param {String, String} path, name
     */
    async downloadFile(url, name) {
			this.uploadLoading = true
			await downloadFileBlob(url, name)
			this.uploadLoading = false
		}
  }
}
</script>

<style lang="less" scoped>
  .file-upload {
    width: 100%;
    height: auto;
    .upload-style {
      height: auto;
      .drag-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .upload-img-area {
      width: 100%;
      height: auto;
      margin: 10px 0;
      .upload-img-item {
        display: inline-block;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        margin-right: 4px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        &:hover .del-image {
          display: inline-block;
        }
        .del-image {
          display: none;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          background: #d3d4d5;
          color: #fff;
          line-height: 16px;
        }
      }
    }
    .upload-files-area {
      .file-name {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 23px);
        vertical-align: middle;
      }
    }
  }
</style>
