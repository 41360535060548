<!--
    @name: TaskNode
    @description：TaskNode
    @author: ZengWei
    @date: 2022-05-13 16:35
-->
<template>
  <div class="task-node-block" @click="showFormParser">
    <i class="iconfont iconfujian2" @click.stop="posAttachRemark('netdisk')"></i>
    <p class="task-title" :style="{'background-color': color}">
      <i class="iconfont iconfabu"></i> {{title}}
    </p>
    <div class="task-content">
      <div class="fixed-text">
        <div class="text-item">
          <div class="left">任务内容</div>
          <i class="iconfont iconpinglun" @click.stop="posAttachRemark('remark')"></i>
        </div>
        <el-form ref="form" class="task-form" label-width="100px" label-position="left" size="mini">
          <el-form-item label="任务状态">未开始</el-form-item>
          <template v-for="(item,index) in baseInfo">
            <el-form-item v-if="item.show" :key="index" :label="item.name">
              <span>{{item.value}}</span>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="business-text" v-if="content.length">
        <el-form ref="form" class="task-form" label-width="100px" label-position="left" size="mini">
          <div class="text-item">
            <div class="left">业务内容</div>
          </div>
          <el-form-item v-for="(item,index) in content" :key="index" :label="item.name">
            <div v-if="item.type !== 'upload'">
              {{item.value}}
            </div>
            <div v-else>
              <el-link type="primary" @click.stop="showAttach(item.attach)">
                <i class="el-icon-link"></i> 查看附件
              </el-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {Link} from "element-ui";
import eventBus from "@/plugins/eventBus";

export default {
  name: "TaskNodePng",
  components: {
    'el-link': Link
  },
  inject: ["getGraph", "getNode"],
  data() {
    return {
      title: '任务标题',
      color: '#BFC7D8',
      status: '',
      statusList: [],
      content: [],
      baseInfo: [
        {
          name: '任务责任人',
          field: 'targetUser',
          value_type: null,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '任务抄送人',
          field: 'sendUser',
          value_type: null,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '计划开始',
          field: 'planStartDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '计划完成',
          field: 'planEndDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '实际开始',
          field: 'actualStartDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '实际完成',
          field: 'actualEndDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        }
      ],
    }
  },
  computed: {
  },
  mounted() {
    const node = this.getNode()
    const data = node.getData()
    node.setProp('size', { width: 270, height: 318 })
    if(data){
      this.title = data.name
      this.statusList = data?.statusList || []
      this.baseInfo = data?.baseInfo || this.baseInfo
      const users = data?.target_users || []
      const sendusers = data?.send_users || []
      const username = Array.from(users,t=>t.name).join(',')
      const senduser = Array.from(sendusers,t=>t.name).join(',')
      const target = this.baseInfo.find(t=>t.field === 'targetUser')
      const send = this.baseInfo.find(t=>t.field === 'sendUser')
      target.value = username
      send.value = senduser
      this.content = data?.content || []
      this.color = data?.color || '#BFC7D8'
    }
    // 监听数据改变事件
    node.on("change:data", ({ current }) => {
      this.title = current.name
      this.statusList = current?.statusList || []
      this.baseInfo = current?.baseInfo || this.baseInfo
      const users = current?.target_users|| []
      const sendusers = current?.send_users || []
      const username = Array.from(users,t=>t.name).join(',')
      const senduser = Array.from(sendusers,t=>t.name).join(',')
      const target = this.baseInfo.find(t=>t.field === 'targetUser')
      const send = this.baseInfo.find(t=>t.field === 'sendUser')
      target.value = username
      send.value = senduser
      this.content = current.content
      this.color = current?.color || '#BFC7D8'
    });
  },
  methods: {
    colorChange(val){
      const node = this.getNode()
      const status = this.statusList.find(t=>t.fieldValue === val)
      this.status = val
      this.color = status.color
      node.setData({
        status: val,
        color: status.color
      })
    },
    onChange() {
      const node = this.getNode()
      node.setData({
        baseInfo: this.baseInfo
      })
    },
    showFormParser(){
      const node = this.getNode()
      const data = node.getData()
      data.id = node.id
      eventBus.$emit('open-task-form',data)
    },
    showAttach(attach){
      eventBus.$emit('open-task-attach',attach)
    },
    posAttachRemark(flag) {
      console.log(flag, 9999)
      eventBus.$emit('pos-task-attach',flag)
    }
  },
}
</script>

<style lang="less" scoped>

.task-form {
  :deep(.el-form-item ) {
    margin-top: 3px;
    margin-bottom: 0;

    .el-date-editor, .el-select {
      width: 100%;
    }
  }
}

.task-node-block{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px #e1e4e8;
  border: 1px solid #F7F9FF;
  background-color: #F7F9FF;

  /*&:hover{
    box-shadow: 0 0 5px #F2654F,
    0 0 25px #F2654F,
    0 0 50px #F2654F,
    0 0 100px #F2654F;
  }*/

  .iconfujian2{
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }

  .task-title{
    border-radius: 8px 8px 0 0;
    padding: 10px 25px 10px 10px;
    background-color: #F2654F;
    color: #ffffff;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-content{
    margin-bottom: 2px;
    height: calc(100% - 43px);
    overflow: hidden;

    .fixed-text {
      margin: 10px;
      padding: 10px;
      background: #FFFFFF;
      border-radius: 5px;
    }

    .business-text {
      margin: 10px;
      padding: 10px;
      background: #FFFFFF;
      border-radius: 5px;
    }
  }

  .text-item{
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 8px;
    .left {
      font-weight: 400;
    }
  }
}
</style>
