/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-10-17 17:49:08
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-20 13:54:34
 * @FilePath: /dataview-viewer-test/src/custom-component/mixins/databaseTriggerDebug.js
 * @Description: 
 */
export default {
  props: {
    element: {
      type: Object
    }
  },
  methods: {
    /**
     * @description: 初始化数据请求
     */
    _initDataDebug() {
      if (window.EDITORUseDebug) {
				window.$EditorDebug.push({
					desc: '------------------------------------',
					isDivider: true
				});
				window.$EditorDebug.push({
					desc: `<p>组件【${this.element?.name}】初始化数据请求</p>`,
					eventType: 'databaseTrigger',
					details: {
						element: this.element,
					}
				});
			}
    },
    /**
     * @description: 开始数据请求
     */
    _startDataDebug() {
      if (window.EDITORUseDebug) {
				window.$EditorDebug.push({
					desc: '------------------------------------',
					isDivider: true
				});
				window.$EditorDebug.push({
					desc: `<p>组件【${this.element?.name}】开始响应数据请求</p>`,
					eventType: 'databaseTrigger',
					details: {
						element: this.element,
					}
				});
			}
    },
    /**
     * @description: 发送请求失败
     */
    /**
     * @description: 发送请求失败
     * @param {String} fail 失败
     * @return {void}
     */
    _failDataDebug(fail = '未知原因') {
      if (window.EDITORUseDebug) {
        window.$EditorDebug.push({
          desc: `<p>组件【${this.element?.name}】请求预处理<em>失败</em>${fail}</p>`,
          eventType: 'databaseTrigger',
          details: {
            element: this.element,
          }
        });
      }
    },
     /**
     * @description: 成功的数据请求
     * @param {Object} interfaceDetails
     *    url  请求链接
     *    content  请求体,包含请求的所有相关信息，<Object>/<Array>
     *    res  请求的结果 <Object>/<Array>
     * @return {void}
     */
    _successDataDebug(interfaceDetails) {
      if (window.EDITORUseDebug) {
				window.$EditorDebug.push({
					desc: `<p>组件【${this.element.name}】<strong>成功</strong>响应数据请求</p>`,
					eventType: 'databaseTrigger',
					details: {
						element: this.element,
            interfaceDetails
					}
				});
			}
    },
    /**
     * @description: 失败的数据请求
     * @param {Object} interfaceDetails
     *    url  请求链接
     *    content  请求体,包含请求的所有相关信息，<Object>/<Array>
     *    err  请求的结果 <Object>/<Array>
     * @return {void}
     */
    _errorDataDebug(interfaceDetails) {
      if (window.EDITORUseDebug) {
				window.$EditorDebug.push({
					desc: `<p>组件【${this.element.name}】<em>数据请求出错</em></p>`,
					eventType: 'databaseTrigger',
					details: {
						element: this.element,
            interfaceDetails
					}
				});
			}
    }
  }
}