<template>
    <div class="moduleWrap vertical-flex">
        <div class="title-wrap">
            <img class="title-icon" src="../background/model.png">
            <span class="title-span">模型编辑</span>
        </div>

        <div class="btn-wrap">
            <div v-for="item in btnList" :key="item.id" @click="onClickBtn(item)"
                 :class="['btn-item vertical-flex', currentBtn === item.id ? 'btnItem-active' : '']">
                <a :class="['btnItem-icon iconfont', item.icon]"></a>
                <span class="btnItem-content">{{item.name}}</span>
            </div>
        </div>

        <div class="content-wrap">
            <div class="content-wrap vertical-flex">
                <span class="contentWrap-title">模型</span>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModelEditor",
        data(){
            return {
                btnList: [
                    {id: 'modelFlatten', name: '模型压平', icon: 'icongis_moxingyaping'},
                    {id: 'modelEffect', name: '模型效果', icon: 'icongis_zengjiakejigan'},
                    {id: 'featureTool', name: '构件工具', icon: 'iconbimgis_goujiangongjushezhi'},
                    {id: 'modelPosition', name: '模型位置', icon: 'icongis_moxingguanli'},
                ],
                currentBtn: '',
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            bindViewerId: {
                type: String,
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            onClickBtn(row){
                this.currentBtn = row.id;
            },
        }
    }
</script>

<style scoped>
    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .title-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .title-icon{
        float: left;
        padding-left: 10px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        fill: currentColor;
    }

    .title-span {
        float: left;
        padding-left: 5px;
        font-size: 14px;
        color: inherit;
    }

    .btn-wrap{
        padding-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        display: flex;
        flex-wrap: wrap;
    }

    .btn-item{
        margin-top: 10px;
        margin-left: 15px;
        padding-top: 15px;
        width: 80px;
        height: 65px;
        line-height: 25px;
        border-radius: 5px;
    }

    .btnItem-active{
        color: #00B7FF;
    }

    .btnItem-icon{
        text-align: center;
        font-size: 20px;
        color: inherit;
    }

    .btnItem-content{
        color: inherit;
        font-size: 14px;
    }

    .content-wrap{
        width: 100%;
    }

    .contentWrap-title{
        margin-left: 15px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 12px;
        opacity: 70%;
    }

    .contentWrap-img{
        margin-top: 15px;
        margin-left: 15px;
        width: calc(100% - 30px);
    }

    .contentWrap-item{
        margin-left: 15px;
        width: calc(100% - 30px);
        height: 30px;
    }

    .contentItem-span{
        float: left;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 14px;
    }

    .contentItem-switch{
        float: right;
        margin-top: 5px;
    }
</style>
