<!--
 * @Author: zyf
 * @Date: 2024-08-06 11:38:27
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-22 09:26:04
 * @Descripttion: 
-->
<template>
  <div class="question-card-container">
    <div class="header">
      <div class="name">答题卡</div>
    </div>
    <div class="content">
      <div class="list-box">
        <div class="list">
          <div class="question-item" v-for="(item,index) in questionList" :key="index" @click="turnToQues(index)" :class="[getStatus(item), +index === +activeId ? 'is-active' : '']">
            <span class="name">{{ index + 1 }}</span>
          </div>
        </div>
      </div>
      <div class="status-box">
        <template v-if="!isPaperLog">
          <div class="status-item">
            <div class="frame answered"></div>
            <span class="name">已答</span>
          </div>
          <div class="status-item">
            <div class="frame answer"></div>
            <span class="name">未答</span>
          </div>
        </template>
        <template v-else>
          <div class="status-item">
            <div class="frame correct"></div>
            <span class="name">正确</span>
          </div>
          <div class="status-item">
            <div class="frame error"></div>
            <span class="name">错误</span>
          </div>
          <div class="status-item">
            <div class="frame empty"></div>
            <span class="name">未答</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	name: 'QuestionCard',
  components: {
  },
	props: {
		// 考试状态
    isPaperLog:{
      type: Boolean,
      default: false
    },
    queslist:{
      type: Array,
      default: ()=>{
        return []
      }
    },
    activeId:{
      type: Number,
      default: 0
    }
	},
  data() {
    return {
      questionList: [],
      total: 20
    }
  },
	computed: {
		
	},
  watch:{
    queslist: {
			handler(val) {
        this.questionList = val;
			},
			deep: true,
      immediate:true
		}
  },
  created(){
  },
  mounted(){
  },
  methods:{
    getStatus(data){
      if(!this.isPaperLog){
        if(data?.answered){
          return 'answered';
        }
        return 'answer';
      }
      if(!data?.answered){
        return 'empty';
      }else if(data?.isCorrect){
        return 'correct';
      }else{
        return 'error';
      }
    },
    turnToQues(blockInd){
      this.$emit('turnToQues',blockInd);
    },
  }
};
</script>

<style lang="less" scoped>
.question-card-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .header{
    width: 100%;
    height: 56px;
    flex-shrink: 0;
    border-bottom: 1px solid #F0F1F4;
    display: flex;
    align-items: center;
    text-align: left;
    .name{
      color: #181B22;
      font-family: "MiSans VF";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: block;
      padding-left: 24px;
      box-sizing: border-box;
    }
  }
  
  .content{
    flex: 1;
    width: 100%;
    overflow: hidden;
    .answered{
      border: 1px solid #7DEAA4;
      background: #E9FBEF;
      .name{
        color: #4DC077;
      }
    }
    .answer{
      border-radius: 3px;
      border: 1px solid #E1E4EC;
      background: #fff;
      .name{
        color: #596058;
      }
    }
    .correct{
      background: #DFF7E5;
      .name{
        color: #4DC077;
      }
    }
    .error{
      background: #FFEEE2;
      .name{
        color: #FFA364;
      }
    }
    .empty{
      background: #F2F5FA;
      .name{
        color: #596058;
      }
    }
    .list-box{
      max-height: calc(100% - 56px);
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .list{
        width: 100%;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(auto-fill, 40px);
        grid-template-rows: repeat(auto-fill, 40px);
        grid-gap: 18px;
        align-items: center;
        .question-item{
          width: 40px;
          height: 40px;
          border-radius: 8px;
          text-align: center;
          line-height: 40px;
          box-sizing: border-box;
          &.answered:hover{
            border: 1px solid #7DEAA4;
            background: #D9F9E3;
            .name{
              color: #4DC077;
            }
          }
          &.answer:hover{
            border: 1px solid #E1E4EC;
            background: #F4F6F9;
            .name{
              color: #596058;
            }
          }

          &.correct:hover{
            background: #CEF5D7;
            .name{
              color: #4DC077;
            }
          }
          &.error:hover{
            background: #FFE4D1;
            .name{
              color: #FFA364;
            }
          }
          &.empty:hover{
            background: #EDF1F7;
            .name{
              color: #596058;
            }
          }
          &.is-active{
            border: 1px solid #4F8EFF;
            background: #fff;
            .name{
              color: #4F8EFF;
            }
          }
          .name{
            text-align: center;
            font-family: "MiSans VF";
            font-size: 14px;
            font-style: normal;
            font-weight: 330;
            line-height: 22px;
          }
        }
      }
    }
    .status-box{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      height: 56px;
      .status-item{
        display: flex;
        align-items: center;
        gap: 6px;
        .frame{
          width: 12px;
          height: 12px;
          border-radius: 3px;
          // border: 1px solid #E1E4EC;
          // opacity: 0.6;
        }
        .name{
          color: #707786;
          font-family: "MiSans VF";
          font-size: 12px;
          font-style: normal;
          font-weight: 330;
          line-height: 18px;
          display: block;
        }
      }
    }
  }
}
</style>
