<template>
    <div class="moduleWrap vertical-flex">
        <div class="title-wrap">
            <img class="title-icon" src="../background/data.png">
            <span class="title-span">地图数据</span>
        </div>

        <div class="btn-wrap">
            <div v-for="item in btnList" :key="item.id" @click="onClickBtn(item)"
                 :class="['btn-item vertical-flex', currentBtn === item.id ? 'btnItem-active' : '']">
                <a :class="['btnItem-icon iconfont', item.icon]"></a>
                <span class="btnItem-content">{{item.name}}</span>
            </div>
        </div>

        <div class="content-wrap vertical-flex" v-if="currentBtn === 'imagery'">
            <span class="contentWrap-title">在线底图</span>

            <div class="contentWrap-scroll">
                <img src="../background/img_tianditu.png" class="contentWrap-img">
                <div class="contentWrap-item">
                    <span class="contentItem-span">天地图影像地图</span>
                    <el-switch v-model="TianDiTu" class="contentItem-switch" @change="onSwitchImagery('TianDiTu')"></el-switch>
                </div>

                <img src="../background/img_gaode.png" class="contentWrap-img">
                <div class="contentWrap-item">
                    <span class="contentItem-span">高德地图</span>
                    <el-switch v-model="AMap" class="contentItem-switch" @change="onSwitchImagery('AMap')"></el-switch>
                </div>
                <el-radio-group v-if="AMap" v-model="AMapType" class="contentWrap-radio" @change="onSwitchImagery('AMap')">
                    <el-radio label="AMapStreets" size="small">街道地图</el-radio>
                    <el-radio label="AMapSatellite" size="small">影像地图</el-radio>
                </el-radio-group>

                <img src="../background/img_arcgis.png" class="contentWrap-img">
                <div class="contentWrap-item">
                    <span class="contentItem-span">ArcGIS暗黑街道地图</span>
                    <el-switch v-model="ArcGIS" class="contentItem-switch" @change="onSwitchImagery('ArcGIS')"></el-switch>
                </div>

                <img src="../background/img_mapbox.png" class="contentWrap-img">
                <div class="contentWrap-item">
                    <span class="contentItem-span">MapBox自定义地图</span>
                    <el-switch v-model="MapBox" class="contentItem-switch" @change="onSwitchImagery('MapBox')"></el-switch>
                </div>

                <el-input v-if="MapBox" v-model="MapBoxId" placeholder="请输入StyleId" size="small" class="contentWrap-input" @change="onSwitchImagery('MapBox')"></el-input>

                <div class="contentWrap-item" style="margin-top: 15px">
                    <span class="contentItem-span">本地部署自定义地图</span>
                    <el-button type="primary" round size="small" class="contentItem-button" @click="onSwitchImagery('CustomMap')">+</el-button>
                </div>

                <el-input v-model="CustomMapUrl" placeholder="请输入链接地址" size="small" class="contentWrap-input"></el-input>
                <div class="contentWrap-item horizontal-flex" style="margin-top: 15px">
                    <span class="contentItem-span">名称</span>
                    <el-input v-model="CustomMapName" size="small" placeholder="请输入图层名称" class="contentItem-input"></el-input>
                </div>
                <div class="contentWrap-item horizontal-flex" style="margin-top: 15px">
                    <span class="contentItem-span">类型</span>
                    <el-select v-model="CustomMapType" placeholder="请选择" size="small" class="contentItem-select">
                        <el-option value="WMS" label="WMS服务"></el-option>
                        <el-option value="Custom" label="自定义部署"></el-option>
                    </el-select>
                </div>
                <div class="contentWrap-item horizontal-flex" style="margin-top: 15px">
                    <span class="contentItem-span">原点</span>
                    <el-select v-model="CustomMapOrigin" placeholder="请选择" size="small" class="contentItem-select">
                        <el-option value="LeftBottom" label="左下角"></el-option>
                        <el-option value="LeftTop" label="左上角"></el-option>
                        <el-option value="RightTop" label="右上角"></el-option>
                        <el-option value="RightBottom" label="右下角"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="content-wrap vertical-flex" v-if="currentBtn === 'terrain'">
            <span class="contentWrap-title">地形</span>
            <img src="../background/img_cesium.png" class="contentWrap-img">
            <div class="contentWrap-item">
                <span class="contentItem-span">Ion世界地形服务</span>
                <el-switch v-model="IonWorldTerrain" class="contentItem-switch" @change="onSwitchTerrain('IonWorldTerrain')"></el-switch>
            </div>
        </div>

        <div class="content-wrap vertical-flex" v-if="currentBtn === 'kml'">
            <span class="contentWrap-title">KML</span>

            <el-button type="primary" class="contentWrap-button" @click="onSwitchKml">选择本地文件</el-button>

            <div class="contentWrap-editWrap vertical-flex" v-if="isEditing">
                <EditorList
                        ref="editor"
                        class="editWrap-content"
                        :element.sync="element"
                        :bindViewerId="bindViewerId"
                        :editList="editList"
                ></EditorList>

                <el-button type="success" class="editWrap-apply" @click="onEditKml('apply')">应用</el-button>

                <div class="horizontal-flex editWrap-bottom">
                    <el-button type="info" class="editWrap-btn" @click="onEditKml('cancel')">取消</el-button>
                    <el-button type="primary" class="editWrap-btn" @click="onEditKml('save')">保存</el-button>
                </div>
            </div>
        </div>

        <div class="content-wrap vertical-flex" v-if="currentBtn === 'geojson'">
            <span class="contentWrap-title">GEOJSON</span>

        </div>
    </div>
</template>

<script>
    import { Switch, Input, Button, RadioGroup, Radio, Select, Option } from 'element-ui';
    import EditorList from "../../viewerComponent/components/EditorList";

    export default {
        name: "DataEditor",
        components: {
            EditorList,
            'el-switch': Switch,
            'el-input': Input,
            'el-button': Button,
            'el-radio-group': RadioGroup,
            'el-radio': Radio,
            'el-select': Select,
            'el-option': Option
        },
        data(){
          return {
              btnList: [
                  {id: 'imagery', name: '在线底图', icon: 'icontupian'},
                  {id: 'terrain', name: '地形', icon: 'icongis_dixing'},
                  {id: 'kml', name: 'KML', icon: 'icongis_kml'},
                  // {id: 'geojson', name: 'GEOJSON', icon: 'iconpailie-wangge'},
              ],
              currentBtn: 'imagery',

              TianDiTu: true,
              AMap: false,
              AMapType: 'AMapStreets',
              ArcGIS: false,
              MapBox: false,
              MapBoxId: '',
              CustomMapUrl: '',
              CustomMapName: '',
              CustomMapType: 'Custom',
              CustomMapOrigin: 'LeftTop',

              IonWorldTerrain: false,

              originEditList: [
                  {id: 'styleType', type: 'objSelector', name: '样式模式', options: [
                          {id: 'Origin', name: '保持原文件样式'},
                          {id: 'Custom', name: '自定义样式'}
                      ], value: 'Origin'},
                  {id: 'isClampToGround', type: 'radio', name: '是否贴地', value: true},
                  {id: 'fill', type: 'radio', name: '是否填充', value: true},
                  {id: 'fillColor', type: 'colorPicker', name: '填充颜色', value: '#FFFFFF'},
                  {id: 'alpha', type: 'slider', name: '不透明度(单位：%)', value: 100, min: 0, max: 100, step: 1},
                  {id: 'outline', type: 'radio', name: '是否边框', value: true},
                  {id: 'outlineColor', type: 'colorPicker', name: '边框颜色', value: '#FFFFFF'},
                  {id: 'outlineAlpha', type: 'slider', name: '边框不透明度(单位：%)', value: 100, min: 0, max: 100, step: 1},
                  {id: 'outlineWidth', type: 'input', name: '边框线宽', value: 1, tag: 'Number'},
                  {id: 'labelColor', type: 'colorPicker', name: '字体颜色', value: '#FFFFFF'},
                  {id: 'labelSize', type: 'input', name: '字体大小', value: 1, tag: 'Number'},
                  {id: 'labelScale', type: 'input', name: '字体比例', value: 1, tag: 'Number'},
                  {id: 'horizontalOrigin', type: 'objSelector', name: '字体横对齐', options: [
                          {id: 'LEFT', name: '靠左对齐'},
                          {id: 'CENTER', name: '居中对齐'},
                          {id: 'RIGHT', name: '靠右对齐'}
                      ], value: 'CENTER'},
                  {id: 'verticalOrigin', type: 'objSelector', name: '字体纵对齐', options: [
                          {id: 'TOP', name: '靠上对齐'},
                          {id: 'CENTER', name: '居中对齐'},
                          {id: 'BOTTOM', name: '靠下对齐'}
                      ], value: 'BOTTOM'},
              ],
              editList: [],
              editObject: null,
              isEditing: false
          }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            bindViewerId: {
                type: String,
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        methods: {
            getGuid(format = 'xxxxxxxxxxxx') {
                let d = new Date().getTime();
                let guid = format.replace(
                    /[xy]/g,
                    function (c) {
                        var r = (d + Math.random() * 16) % 16 | 0;
                        d = Math.floor(d / 16);
                        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
                    });
                return guid;
            },
            getImageryName(type, options){
                switch (type) {
                    case 'TianDiTu':
                        return '天地图影像地图';
                    case 'AMapStreets':
                        return '高德街道地图';
                    case 'AMapSatellite':
                        return '高德影像地图';
                    case 'ArcGIS':
                        return 'ArcGIS暗黑街道地图';
                    case 'MapBox':
                        return 'MapBox自定义地图';
                    case 'CustomMap':
                        return options.mapName;
                }
            },
            getEditOptions(list){
                let res = {};
                for (let item of list){
                    if(item.tag === 'Number'){
                        res[item.id] = Number(item.value);
                    }else{
                        res[item.id] = item.value;
                    }
                }

                return res;
            },

            setEditorByKml(data){
                let list = [];
                let {options} = data;
                for (let item of this.originEditList){
                    if(!options[item.id]) continue;

                    let temp = Object.assign(item, {
                        value: options[item.id]
                    });

                    list.push(temp);
                }

                this.isEditing = true;
                this.editList = list;
                this.editObject = data;
            },
            setEditorByEmpty(){
                this.isEditing = false;
                this.editList = [];
                this.editObject = null;
            },

            onClickBtn(row){
                if(this.isEditing){
                    return this.$message.error(`编辑中，尚未保存数据！`);
                }

                this.currentBtn = row.id;
            },
            onSwitchImagery(type){
                this.TianDiTu = false;
                this.AMap = false;
                this.ArcGIS = false;
                this.MapBox = false;

                this[type] = true;
                let options = {};
                if(type === 'AMap') {
                    type = this.AMapType;
                }else if(type === 'MapBox'){
                    if(this.MapBoxId === '') return;
                    options.styleId = this.MapBoxId;
                }else if(type === 'CustomMap'){
                    if(this.CustomMapUrl === '') return;
                    options.mapId = `imagery${this.getGuid()}`;
                    options.mapUrl = this.CustomMapUrl;
                    options.mapName = this.CustomMapName !== '' ? this.CustomMapName : '自定义地图';
                    options.mapType = this.CustomMapType;
                    options.mapOrigin = this.CustomMapOrigin;
                }

                this.$emit('clickImagery', type, Object.assign({
                        type,
                        name: this.getImageryName(type, options)
                    }, options));
            },
            onSwitchTerrain(type){
                let options;
                switch (type) {
                    case 'IonWorldTerrain':
                        options = {
                            isShow: this.IonWorldTerrain
                        };
                        break;
                }

                this.$emit('clickTerrain', type, options);
            },
            onSwitchKml(){
                this.editList = this.originEditList;
                this.$emit('clickKml');
            },

            onEditKml(type){
                if(type === 'cancel'){
                    this.$emit('cancelKml');
                    return;
                }

                let options = this.getEditOptions(this.editList);
                if(!this.editObject.options) this.editObject.options = {};
                this.editObject.options = Object.assign(this.editObject.options, options);

                switch (type) {
                    case 'apply':
                        this.$emit('applyKml', this.editObject);
                        break;
                    case 'save':
                        this.$emit('saveKml', this.editObject);
                        break;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .title-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .title-icon{
        float: left;
        padding-left: 10px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        fill: currentColor;
    }

    .title-span {
        float: left;
        padding-left: 5px;
        font-size: 14px;
        color: inherit;
    }

    .btn-wrap{
        padding-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        display: flex;
        flex-wrap: wrap;
    }

    .btn-item{
        margin-top: 10px;
        margin-left: 15px;
        padding-top: 15px;
        width: 80px;
        height: 65px;
        line-height: 25px;
        border-radius: 5px;
    }

    .btnItem-active{
        color: #00B7FF;
    }

    .btnItem-icon{
        text-align: center;
        font-size: 20px;
        color: inherit;
    }

    .btnItem-content{
        color: inherit;
        font-size: 14px;
    }

    .content-wrap{
        width: 100%;
        overflow: hidden;
    }

    .contentWrap-title{
        margin-left: 15px;
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 12px;
        opacity: 70%;
    }

    .contentWrap-img{
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        width: calc(100% - 30px);
    }

    .contentWrap-button{
        margin-top: 15px;
        margin-left: 12px;
        width: calc(100% - 20px);
    }

    .contentWrap-item{
        float: left;
        margin-left: 15px;
        width: calc(100% - 30px);
        height: 30px;
    }

    .contentItem-span{
        float: left;
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 14px;
    }

    .contentItem-switch{
        float: right;
        margin-top: 5px;
    }

    .contentItem-button{
        float: right;
        margin-top: 5px;
        height: 20px;
        padding: 0 15px !important;
    }

    .contentItem-input{
        margin-left: 10px;
     }

    .contentItem-select{
        margin-left: 10px;
    }

    .contentWrap-scroll{
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .contentWrap-radio{
        margin-top: 5px;
        margin-left: 15px;
        float: left;

        :deep(.el-radio){
            margin-right: 10px;
            color: inherit;
        }
    }

    .contentWrap-input{
        /*margin-left: 15px;*/
        width: calc(100% - 30px);
        height: 30px;
    }

    .contentWrap-editWrap{
        width: 100%;
        overflow: hidden;
    }

    .editWrap-content{
        margin-top: 20px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .editWrap-apply{
        float: left;
        margin-top: 15px;
        margin-left: 15px;
        width: calc(100% - 30px);
    }

    .editWrap-bottom{
        float: left;
        display: flex;
        margin-top: 10px;
        margin-left: 15px;
        margin-bottom: 10px;
        width: calc(100% - 30px);
    }

    .editWrap-btn{
        flex: 1;
    }
</style>
