<!--
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-15 17:20:03
 * @Descripttion: 
-->
<!--
    @name: ques-radio
    @description：ques-radio
    @author: ZengWei
    @date: 2022-04-01 11:17
-->
<template>
  <div class="widget-text">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <!-- <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div> -->
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（非必填）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div class="q-title" v-html="element.config.__ques__.quesDesc"></div>
    </div>
  </div>
</template>

<script>
import quesWidgetMinix from "@/components/answer/quesParser/scripts/quesWidgetMinix.js";

export default {
  name: "ques-text",
  components: {},
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  computed:{},
  data() {
    return {

    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
</style>
