import Viewer from "../../Viewer";
import eventBus from '@/plugins/eventBus';
import Utils from '../../Utils';
export default class Marker {

    get id(){
        return "Marker";
    }

    get data(){
        return {
        }
    }

    get watch (){
        return {

        }
    }

    get methods (){
        return {
            //刷新标记
            mark_refresh(){
                if( !this.viewer ) return  this.$message.warning(`viewer未初始化，无法操作标记`);

                const marks = this.element.modelBIMMark;
                if(!marks ) return;

                for(let id in marks){
                    const { position , visible , content } = marks[id];

                    if( !this.viewer.action('hasMarker' , id )){
                        this.marker_create(id , position , content);
                    }

                    this.marker_changePosition(id , position);
                    this.marker_display(id , visible);
                }

            },
            //删除标记
            marker_delete( id ){
                if( !this.viewer ) return  this.$message.warning(`viewer未初始化，无法操作标记`);

                if( this.viewer.action('hasMarker' , id )){
                    this.viewer.action('removeMarker' , id);

                    this.removeActionConfig(`mark_ac_${id}`);
                    
                    this.$message.success('删除标记成功');
                }else{
                    const msg = `删除标记失败,无此标记id${id}`;
                    this.$message.warning(msg)
                    Utils.warn(msg);
                }
            },
            //创建标记
            marker_create( id , position , content , needActionConfig = false){
                if( !this.viewer ) return  this.$message.warning(`viewer未初始化，无法操作标记`);

                if( !this.viewer.action('hasMarker' , id )){
                    this.viewer.action('createMarker' , id , 'System' , 'blackMarker' , position , content);

                    const mark = this.viewer.action('getMarker' , id );
                    if(!mark) return this.$message.warning(`标记获取失败`);

                    if(needActionConfig){
                        this.registerActionConfig(`mark_ac_${id}`, `标记: 【${content}】 点击事件`);
                    }

                    mark.dom.onclick = ()=>{
                        this.onMarkClick( id , mark.position , mark.label);
                        this.setResolveData('mark_click_id' , id);
                        this.onAction(`mark_ac_${id}`);
                    }

                    this.$message.success('添加标记成功');
                }else{
                    const msg = `创建标记失败,重复的标记id${id}`;
                    this.$message.warning(msg);
                    Utils.warn(msg);
                }
            },
            //切换标记显示
            marker_display (id , isShow){
                if( !this.viewer ) return  this.$message.warning(`viewer未初始化，无法操作标记`);

                if( this.viewer.action('hasMarker' , id )){
                    this.viewer.action(isShow ? 'showMarker' : 'hideMarker', id);
                }else{
                    const msg = `切换标记显示失败,无此标记id${id}`;
                    this.$message.warning(msg)
                    Utils.warn(msg);
                }
            },
            //修改标记位置
            marker_changePosition(id , position ){
                if( !this.viewer ) return  this.$message.warning(`viewer未初始化，无法操作标记`);

                if( this.viewer.action('hasMarker' , id )){
                    this.viewer.action('changeMarkerPosition' , id , position);
                }
            },
            //初始化行为监听
            marker_initBehaviors(){
                this.registerBehaviors([{
                    label : "[标记]删除标记",
                    desc : "参数[ id ]。 id=>删除标记的id值 例如[mark00]",
                    value : "marker_delete",
                    params: ['id'],
                    func : (params)=>{
                        if(params?.id && this.viewer){
                            this.marker_delete(params.id);
                        }
                    },
                },{
                    label : "[标记]创建标记",
                    desc : `参数[ id , position , content ]。 
                            id=>添加标记的id值 例如[mark00]
                            position=>添加位置 例如[100,0,100] 备注:分别对应x,y,z
                            content=>标记文字 例如[测试标记]
                            needEvent=>传入任意值就会添加自定义点击事件
                    `,
                    value : "marker_create",
                    params: ['id', 'position', 'content'],
                    func : (params)=>{
                        if(params?.id && params?.position && params?.content && this.viewer){
                            const _p = params.position.split(',');

                            const position = {
                                x : _p[0],
                                y : _p[1],
                                z : _p[2],
                            }
                            this.marker_create(params.id , position , params.content , !!params.needEvent);
                        }
                    }
                },{
                    label : "[标记]显示标记",
                    desc : `参数[ id  ]。 
                            id=>显示标记的id值 例如[mark00]
                    `,
                    value : "marker_show",
                    params: ['id'],
                    func : (params)=>{
                        if(params?.id && this.viewer){
                            this.marker_display(params.id , true);
                        }
                    },
                },{
                    label : "[标记]隐藏标记",
                    desc : `参数[ id  ]。 
                            id=>隐藏标记的id值 例如[mark00]
                    `,
                    value : "marker_hide",
                    params: ['id'],
                    func : (params)=>{
                        if(params?.id && this.viewer){
                            this.marker_display(params.id , false);
                        }
                    },
                }]);
            },
            onMarkClick( id , position , label ){
                eventBus.$emit('ViewerBIM.Marker.onClick' , id , position , label);
            },
        }
    }

    get func(){
        return {
            initViewerEventListener(){
                this.viewer.on(Viewer.Events.ViewerInited , ()=>{
                    this.mark_refresh();
                });
            },
            mounted (){
                this.addResolveList('mark_click_id' , '标记点击事件id');
                this.marker_initBehaviors();
                eventBus.$on('ViewerBIM.Marker.Add' , (eid ,{ id , content , position , visible}) => {
                    if( eid !== this.element.id)return;

                    if( !this.element?.modelBIMMark){
                        this.element.modelBIMMark = {}
                    }

                    this.element.modelBIMMark[id] = {
                        id , content , position , visible
                    }

                    this.marker_create(id , position , content);

                    this.marker_display(id , visible);
                });

                eventBus.$on('ViewerBIM.Marker.Delete' , (eid , id)=>{
                    if( eid !== this.element.id)return;

                    if( !this.element?.modelBIMMark?.[id]) return;

                    this.marker_delete(id);

                    delete this.element.modelBIMMark[id];
                });

                eventBus.$on('ViewerBIM.Marker.Edit' , (eid , id , {position , visible , content})=>{
                    if( eid !== this.element.id)return;

                    if( !this.element?.modelBIMMark?.[id]) return;

                    if( this.viewer.action('hasMarker' , id )){
                        const prev = this.element.modelBIMMark[id];

                        //todo 标记如果文字改变只能重新生成
                        if(prev.content !== content){
                            this.marker_delete(id);
                            this.marker_create(id , position , content);
                        }else{

                            if(prev.position !== position){
                                this.marker_changePosition(id , position);
                            }

                            this.marker_display(id , visible);
                        }

                        prev.position = Object.assign({},position);
                        prev.visible = visible;
                        prev.content = content;
                    }
                });
            }
        }
    }
}

