<!--
 * @Description: 进度曲线
 * @Author: luocheng
 * @Date: 2022-05-31 15:41:30
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2024-04-28 15:42:34
-->
<template>
  <div class="curve-chart" v-if="paramsResult">
		<i class="iconfont filter-icon" @click="showFilter=!showFilter" :class="showFilter?'iconshouqi2':'iconzhankai3'"></i>
		<div v-show="showFilter" class="filter-select">
			<el-select
				v-model="typeId"
				placeholder="请选择计划类别"
				@change="getPlanList"
				size="mini"
			>
				<el-option
					v-for="item in typeData"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				></el-option>
			</el-select>
			<el-select
          v-model="schedule_Id"
          placeholder="请选择计划"
          size="mini"
					@change="getChartOption(paramsResult)"
        >
          <el-option
            v-for="item in listData"
            :key="item.id"
            :label="item.name+'/'+item.plan_edition"
            :value="item.id"
          />
        </el-select>
			<el-select size="mini" @change="getChartOption(paramsResult)" v-model="ruleId" placeholder="请选择分项">
				<el-option v-for="item in filterList"
					:key="item.id"
					:label="item.rule_name"
					:value="item.id"
				></el-option>
			</el-select>
		</div>
    <ChartContainer
      v-loading="loading"
      v-if="option"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
    ></ChartContainer>
		<el-empty
			description="暂无数据"
			v-else
		></el-empty>
  </div>
</template>

<script>
import ChartContainer from '@/chart-components/components/common/ChartContainer';
import { dataInterface } from '@/apis/data';
import { Empty } from 'element-ui';

export default {
  name: 'CurveChart',
  components: {
    ChartContainer,
		'el-empty': Empty,
  },
  data() {
    return {
      option: null,
      loading: false,
			showFilter:false,
      filterList: [],
			typeData:[],
			listData:[],
			typeId:'',
			ruleId:'',
			schedule_Id:'',
      colorArr: ['#1A73E8', '#FF8C00', '#8B8682', '#ff3333', '#FFD700', '#836FFF'], // 颜色数组
    }
  },
  props: {
    paramsResult: {
      required: true,
    }
  },
  watch: {
    paramsResult: {
      handler() {
        this.getChartOption(this.paramsResult);
      },
      deep: true,
			immediate:true
    },
  },
  methods: {
    /**
     * @desc: 获取图表option配置
     */
    async getChartOption(params = {}) {
      this.loading = true;
			this.showFilter = false;
      let result = {};
      if (!this.ruleId) return false;
      const { param = {}, search = [], canPost = true } = params;
      if (!canPost) return false;
      // 测试删除后分部分项问题
      const questionRes = await this.getData(1, param, search);
      if (questionRes.code !== 200) {
        this.$message.error('获取数据失败!');
        this.loading = false;
        return false;
      }
      const questionData = questionRes?.data || [];
      if (questionData.length) {
        questionData.forEach((ele, index) => {
          const list = ele.schedule_list || [];
          if (index) {
            result = Object.assign(result, { [ele.schedule_name + index]: list })
          } else {
            result = Object.assign(result, { [ele.schedule_name]: list })
          }
        });
      }
      // 实际进度
      const progressRes = await this.getData(2, param, search);
      if (progressRes.code !== 200) {
        this.$message.error('获取数据失败!');
        this.loading = false;
        return false;
      }
      let progressData = progressRes?.data || [];
      if (!progressData.length) {
				if (questionData.length && questionData[0].schedule_list?.length) {
					const actualList = questionData[0].schedule_list.map((val) => {
						return {
							...val,
							number: 0,
							rate: 0
						};
					});
					progressData = {...result, '实际进度': actualList };
					this.buildData(progressData);
					return;
				} else {
					this.buildData(result);
					return;
				}
      }
      result = Object.assign(result, { '实际进度': progressData });
      // 获取完成率
      const percentRes = await this.getPercent(param, search);
      let percentData = percentRes?.data || [];
      if (percentData.length) {
        percentData = percentData.map(ele => {
          return {
            ...ele,
            rate: parseFloat(ele.rate)
          }
        });
				result = Object.assign(result, { '完成率': percentData });
      }
      this.buildData(result)
			this.$emit('selectKey',{rule_id:this.rule_id,typeId:this.typeId,schedule_Id:''})
    },
    /**
     * @desc: 构建数据
     */
    buildData(data) {
      const rangeX = []
      const seriesList = []
      let n = 0
      for (var i in data) {
        if (i !== '实际进度') {
          n++
        }
        const item = data[i]
        const innerData = []
        if (item.length !== 0) {
          item.forEach((item) => {
            innerData.push([item.date, item.rate * 100])
            rangeX.push(item.date)
            // 日期升序排序
            rangeX.sort(function (a, b) {
              return Date.parse(a) - Date.parse(b)
            })
          })
        }
        const obj = {
          name: i,
          type: 'line',
          data: innerData,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            normal: {
              lineStyle: {
                color: i === '实际进度' ? 'green' : this.colorArr[n - 1],
                lineStyle: {
                  width: i === '实际进度' ? 3 : 1.5,
                  type: i === '实际进度' ? 'solid' : 'dotted', // 'dotted'虚线 'solid'实线
                  color: i === '实际进度' ? 'green' : this.colorArr[n - 1]
                }
              }
            }
          }
        }
        seriesList.push(obj)
      }
      this.option = {
        animationDuration: 3000,
        title: {
          align: 'center',
          color: '#ffffff'
        },
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            let str = params[0].axisValueLabel + '<br/>'
            for (let i = 0; i < params.length; i++) {
              str += '<div style="margin: 0px 0 0;line-height:20px;height:20px;background">' +
              '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' + params[i].color + '"></span>' +
              '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' + params[i].seriesName + '</span>' +
              '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + (params[i].value[1]).toFixed(2) + '%</span>' +
              '<div style="clear:both"></div>' +
              '</div>'
            }
            return str
          }

        },
        grid: {
          top: 45,
          right: 20,
          bottom: 30
        },
        legend: {
          // data: ['计划进度', '实际进度', '完成率'],
          width: 400,
          top: 0,
          right: 20,
          textStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Array.from(new Set(rangeX)),
          nameTextStyle: {
            color: '#ffffff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}' + '%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#939191'
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#ffffff'
            }
          }
        },
        series: seriesList
      };
    },
    /**
     * @desc: 获取统计数据
     * @param {String,Number} type 类型
     * type 1 测试删除后分部分项问题
     *      2 实际进度
     *      3 完成率
     */
    getData(type, param = {}, search = []) {
      return new Promise((resolve, reject) => {
        dataInterface({
          rule_id: this.ruleId,
					schedule_id:this.schedule_Id,
          type,
          token: this.$GetUserInfo()?.token,
          search,
          ...param,
          transcode: 0
        }, '/api/fourSchedule/countGroupChart', 'GET').then(res => {
          if (res.status === 200) {
            resolve(res.data);
            return;
          }
          reject(false);
        }).catch((err) => {
          console.log(err);
          reject(false);
        })
      })
    },
		getFilters(params = {}) {
			//获取分项数据以及计划分类、计划列表
      const { param = {}, search = [], canPost = true } = params;
      if (!canPost) return false;
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        view_uuid: 'view6257d0268d93d',
        object_uuid: 'object62426410f3cc4',
        ...param,
        search,
        transcode: 0
      }).then(res => {
        if (res.status === 200 && res.data?.code === 200) {
          this.filterList = res.data.data || [];
          // 测试数据
          this.ruleId = this.filterList?.[0]?.id || '';
					this.getPlanTypes()
        }
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    },
		/**
     * @desc: 获取计划类别
     */
		getPlanTypes() {
      const { param = {}, search = [], canPost = true } = this.paramsResult;
      if (!canPost) return;
      dataInterface({
        // archi_type: this.$GetTargetArchi()?.id, // 测试，正式由参数传入
        object_uuid: 'object61a89029d0dc3',
        view_uuid: 'view61a892c2b6e1a',
        __method_name__: 'dataList',
        search,
        ...param,
        transcode: 0
      }).then(res => {
        this.typeData = res?.data?.data?.data || [];
        this.typeId = this.typeData?.[0].id;
        this.getPlanList();
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 获取计划列表
     */
    getPlanList() {
      this.listData = [];
      if (!this.typeId) return;
      const { param = {}, search = [], canPost = true } = this.paramsResult;
      if (!canPost) return;
      dataInterface({
        size: 1000,
        type_id: this.typeId,
        __method_name__: 'dataList',
        view_uuid: 'view61d552a955ca0',
        object_uuid: 'object61a973b39cf96',
        search,
        ...param,
        transcode: 0
      }).then(res => {
        this.listData = res.data?.data || [];
        this.schedule_Id = this.listData?.[0].id;
				this.getChartOption(this.paramsResult)
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 完成率
     */
    getPercent(param = {}, search = []) {
      return new Promise((resolve, reject) => {
        dataInterface({
          rule_id: this.ruleId,
          token: this.$GetUserInfo()?.token,
          search,
          ...param,
          transcode: 0
        }, '/api/fourSchedule/mileageCount', 'GET').then(res => {
          if (res.status === 200) {
            resolve(res.data);
            return;
          }
          reject(false);
        }).catch(err => {
          console.log(err);
          reject(false);
        });
      });
    },
  },
	mounted(){
		this.getFilters()
	}
}
</script>

<style lang="less" scoped>
.curve-chart{
  position: relative;
  height: 220px;
  width: 100%;
	.filter-icon{
		position: absolute;
		left: 10px;
		top: 10px;
		z-index: 10;
	}
  .filter-select{
    position: absolute;
		display: flex;
		flex-direction: column;
		top: 20px;
		left: 10px;
		z-index: 100;
		background: #fff;
		border-radius: 6px;
		.el-select{
			margin:10px ;
		}
  }
}
</style>