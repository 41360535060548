<!--
    @name: widget-input
    @description：widget-input
    @author: ZengWei
    @date: 2022-03-25 09:25
-->
<template>
  <div>
    <div
      v-if="
        disabled ||
        element.config.disabled ||
        element.config.__config__.openRule
      "
      class="input-show"
    >
      <span v-if="element.value && element.config.__slot__.prepend">{{
        element.config.__slot__.prepend
      }}</span>
      {{ element.value ? element.value : "-" }}
      <span v-if="element.value && element.config.__slot__.append">{{
        element.config.__slot__.append
      }}</span>
    </div>
    <template v-else>
      <div
        v-if="
          !(
            disabled ||
            element.config.disabled ||
            element.config.__config__.openRule
          )
        "
      >
        <div v-if="element.config.__config__.ruleType === 2">
          <el-button
            style="width: 70%"
            type="primary"
            icon="el-icon-edit-outline"
            @click="openCodeDialog"
          >
            公式规则配置
          </el-button>
          <el-button style="width: 27%" type="primary" @click="verifyRule">
            核算
          </el-button>

          <div class="formula-rule" v-if="element.value?.originVal">
            {{ element.value.originVal }}
          </div>
        </div>
        <el-input
          v-else
          v-model.trim="element.value"
          :disabled="
            disabled ||
            element.config.disabled ||
            element.config.__config__.openRule
          "
          :placeholder="element.config.placeholder"
          :maxlength="element.config.maxlength"
          :min="element.config.min"
          :max="element.config.max"
          :show-word-limit="element.config['show-word-limit']"
          @change="formatPrecision"
        >
          <template slot="prepend" v-if="element.config.__slot__.prepend">
            {{ element.config.__slot__.prepend }}
          </template>
          <template slot="append" v-if="element.config.__slot__.append">
            {{ element.config.__slot__.append }}
          </template>
        </el-input>
      </div>
      <div v-else>
        <div
          class="el-input el-input--medium el-input-group el-input-group--append"
        >
          <div
            class="el-input-group__prepend"
            v-if="element.config.__slot__.prepend"
          >
            {{ element.config.__slot__.prepend }}
          </div>
          <input
            type="text"
            disabled
            class="el-input__inner"
            :placeholder="
              element.value || element.value === 0 || element.value === '0'
                ? element.value
                : element.config.placeholder
            "
          />
          <div
            class="el-input-group__append"
            v-if="element.config.__slot__.append"
          >
            {{ element.config.__slot__.append }}
          </div>
        </div>
      </div>

      <el-dialog
        title="公式计算规则配置"
        :visible.sync="dialogVisible"
        width="70%"
        append-to-body
        top="5vh"
        custom-class="no-body-padding"
      >
        <div style="height: 70vh" v-if="dialogVisible">
          <code-rules
            ref="codeRules"
            :drawingList="formData.fields"
            :codeRule="element.value || {}"
          >
          </code-rules>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">
            取 消
          </el-button>
          <el-button size="small" type="primary" @click="getCalcRules">
            确 定
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="公式运算过程"
        :visible.sync="verifyVisible"
        width="50%"
        append-to-body
        top="5vh"
        custom-class="no-body-padding"
      >
        <div style="height: 65vh" v-if="verifyVisible" v-loading="loading">
          <div
            style="background-color: #eaeef5; height: 100%; border-radius: 5px"
          >
            <el-input
              type="textarea"
              style="width: 98%; margin: 10px"
              v-model="simulate"
              placeholder="请输入json数据模拟"
            ></el-input>
            <el-button
              type="primary"
              style="width: 98%; margin: 0 10px"
              @click="verifyRule"
              >公式模拟运算</el-button
            >
            <div style="padding: 20px">
              <p
                v-for="(item, key) in process"
                :key="key"
                style="line-height: 35px"
              >
                {{ item }}
              </p>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="verifyVisible = false">
            取 消
          </el-button>
          <el-button size="small" type="primary" @click="verifyVisible = false">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { Input } from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import CodeRules from "@/custom-component/form/newParser/CodeRules";
import { dataInterface } from "@/apis/data/index";
import { toFixed } from "@/utils/tools";

export default {
  name: "WidgetInput",
  components: {
    CodeRules,
    "el-input": Input,
  },
  mixins: [formItemMinix],
  props: ["element", "formData", "disabled", "displayData", "drawingList"],
  data() {
    return {
      dialogVisible: false,
      verifyVisible: false,
      process: [],
      simulate: "",
      loading: false,
    };
  },
  created() {
    window.calcField = this.getFieldValue.bind(this);
  },
  methods: {
    getFieldValue() {
      return 1;
    },
    formatPrecision(val) {
      this.triggerFlowBranch();
      this.triggerInputChange(this.element);
      this.$emit("change-calc");
      let value = val;
      if (value < this.element.config.min) {
        value = this.element.config.min;
      } else if (value > this.element.config.max) {
        value = this.element.config.max;
      }
      if (this.element.config.__config__?.numberType) {
        let type = this.element.config.__config__.numberType;
        let prec = 1;
        let valuePos = value.toString().split(".");
        if (valuePos.length > 1) {
          for (let i = 0; i < valuePos[1].length; i++) {
            prec = prec * 10; // 小数精度处理
          }
        }
        value = Number(value);
        if (type === 1)
          value = Math.floor((value * prec).toPrecision(12)) / prec; // 向下取整
        if (type === 2)
          value = Math.ceil((value * prec).toPrecision(12)) / prec; // 向上取整
        if (type === 3)
          value = Math.round((value * prec).toPrecision(12)) / prec; // 四舍五入
      }
      if (this.element.config.precision) {
        let precision = this.element.config.precision;
        value = toFixed(value, precision); // toFixed 银行家算法 四舍五入存在问题
        if (value.indexOf("NaN") > -1) {
          value = Number("0").toFixed(precision);
        }
      }
      this.element.value = value;
    },
    openCodeDialog() {
      this.dialogVisible = true;
    },
    verifyRule() {
      this.verifyVisible = true;
      this.loading = true;
      const codeRule = this.element.value;
      const express = codeRule.express;
      let data = {};
      if (this.simulate) {
        data = JSON.parse(this.simulate);
      }
      dataInterface(
        { formula: express, ...data },
        "/api/verify/formula",
        "POST"
      ).then((res) => {
        if (res.data.code === 200) {
          this.loading = false;
          this.process = res.data.data.process;
        }
      });
    },
    getCalcRules() {
      const codeRule = this.$refs.codeRules.getRule();
      const express = codeRule.express;
      try {
        // 后端公式预执行
        dataInterface({ formula: express }, "/api/verify/formula", "POST").then(
          (res) => {
            if (res.data.code === 0) {
              this.$message.error("计算公式规则配置错误！");
              return;
            } else {
              this.element.value = codeRule;
              this.dialogVisible = false;
            }
          }
        );
      } catch (e) {
        console.log("运算错误信息：", e);
        this.$message.error("计算公式规则配置错误！");
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.no-body-padding) {
  .el-dialog__body {
    padding: 0 20px;
  }
}

.formula-rule {
  margin: 8px 0;
  padding: 10px 14px;
  white-space: normal;
  line-height: 1.5;
  border-radius: 2px;
  background-color: #f5f7fa;
  color: #b2b2b2;
  font-size: 12px;
}
</style>
