<!--
 * @Description: 日历组件
 * @Author: luocheng
 * @Date: 2022-08-01 10:34:50
 * @LastEditors: zyf
 * @LastEditTime: 2024-05-24 10:11:13
-->
<template>
  <div class="common-calendar">
    <!-- 日期选择器 -->
    <CalendarDate
      v-if="statusConfig.type === 'date'"
      :element="element"
      @setDate="onResolve"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
      type="date"
    ></CalendarDate>
    <!-- 月选择器 -->
    <CalendarMonth
      v-else-if="statusConfig.type === 'month'"
      :element="element"
      @setDate="onResolve"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
      type="date"
    ></CalendarMonth>
    <!-- 年选择器 -->
    <CalendarYear
      v-else-if="statusConfig.type === 'year'"
      :element="element"
      @setDate="onResolve"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
      type="year"
    ></CalendarYear>
    <!-- 月周选择器 -->
    <MonthWeek
      v-else-if="statusConfig.type === 'monthWeek'"
      :element="element"
      @setDate="onResolve"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
      type="week"
      >
    </MonthWeek>
    <!-- 年周选择器 -->
    <YearWeek
      v-else-if="statusConfig.type === 'monthYear'"
      :element="element"
      @setDate="onResolve"
      :isGroup="isGroup"
      :groupComponents="groupComponents"
      :componentList="componentList"
      type="week"
    ></YearWeek>
  </div>
</template>

<script>
import CalendarYear from './child/CalendarYear';
import MonthWeek from './child/MonthWeek';
import YearWeek from './child/YearWeek';
import CalendarDate from './child/CalendarDate';
import CalendarMonth from './child/CalendarMonth';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonCalendar',
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否为分组
    isGroup: {
      type: Boolean
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
    // 组件类表
    componentList: {
      default: null
    }
  },
  inject: ['EDITOR_pageUUID'],
  components: {
    CalendarYear,
    MonthWeek,
    YearWeek,
    CalendarDate,
    CalendarMonth
  },
  computed: {
    statusConfig() {
      return this.element?.statusConfig;
    }
  },
  methods: {
    /**
     * @desc: 暴露数据
     */
    onResolve(value) {
      console.log(value);
      this.element.resolveData = {
        date: value.date,
        dateString:value.dateString,
        week:value.week,
        day:value.day,
        month:value.month,
        year:value.year,
      };
      this.onSearch(value);
      this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData: {
          date: value.date,
          dateString:value.dateString,
          week:value.week,
          day:value.day,
          month:value.month,
          year:value.year,
        }
      });
    },
    /**
     * @desc: 搜索
     */
    onSearch(dateValue) {
      eventBus.$emit('databaseTrigger', {
        componentId: this.element.id,
        action: 'any',
        output: dateValue,
        isInit: false,
        noUpdate: false
      });
    },
  }
}
</script>

<style lang="less" scoped>
.common-calendar{
  height: 100%;
  width: 100%;
}
</style>