<template>
	<div class="task-analysis">
		<div class="task-title">
			<p>任务图分析</p>
		</div>
		<div class="task-display">
			<div
				class="chart-box"
				ref="chartBox"></div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';

export default {
	name: 'TaskSidebar',
	components: {},
	props: {},
	data() {
		return {
			options: {},
      taskGraphData: [],
		};
	},
	watch: {},
	methods: {
		renderChart() {
			this.options = this.echartsConfiguration();
			// 清空之前的数据
			this.chart.clear();
			this.options && this.chart.setOption(this.options);
		},
		/**
		 * @desc: echart图参数配置
		 */
		echartsConfiguration() {
      const taskStatusList = this.$store.state.task?.taskStatusList || []
      const taskGraphData = this.$store.state.task?.taskGraphData?.cells || []
      const total = taskGraphData.filter(t=>t.shape === 'task')
      const data = [
        {name: '总任务', value: total.length}
      ]
      for (const item of taskStatusList) {
        let value = taskGraphData.filter(t=>t.data?.status == item.index) // 暂缺自定义的情况
        data.push({
          name: item.status,
          value: value.length
        })
      }
			/*const data = [
				{name: '总任务', value: 300},
				{name: '未开始', value: 10},
				{name: '待处理', value: 50},
				{name: '进行中', value: 65},
				{name: '未开始', value: 10},
				{name: '待处理', value: 50},
				{name: '进行中', value: 65}
			];*/
			let arr = [];
			data.map((item) => {
				arr.push(item.value);
			});
			let max = Math.max.apply(null, arr);
			const defaultData = [];
			for (let i = 0; i < arr.length; i++) {
				defaultData.push(max);
			}
			let params = {
				tooltip: {
					show: false
				},
				grid: {
					top: '1%',
          bottom: '40%',
          right: 16,
          left: 16,
				},
				xAxis: {
					show: false
				},
				yAxis: [
					{
						triggerEvent: false,
						show: true,
						inverse: true,
						data: this.getArrByKey(data, 'name'),
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							show: false,
							interval: 0,
							color: '#fff',
							align: 'left',
							margin: 80,
							fontSize: 13,
							formatter: function (value) {
								return '{title|' + value + '}';
							},
							rich: {
								title: {
									width: 165
								}
							}
						}
					},
					{
						triggerEvent: false,
						show: true,
						inverse: true,
						data: this.getArrByKey(data, 'name'),
						axisLine: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},

						axisLabel: {
							interval: 0,
							shadowOffsetX: '-20px',
							align: 'right',
							verticalAlign: 'bottom',
							lineHeight: 40,
							color: 'rgba(112, 119, 134, 1)',
							fontFamily: 'PingFang SC',
							fontSize: '12px',
							fontWeight: '500',
							letterSpacing: '0.4000000059604645px',
							formatter: function (value, index) {
								return data[index].value;
							}
						}
					}
				],
				series: [
					{
						name: 'XXX',
						type: 'pictorialBar',
						symbol: '',
						symbolSize: [0, 0],
						symbolOffset: [0, 0],
						z: 12,
						itemStyle: {
							normal: {
								color: '#fff'
							}
						},
						data: this.getSymbolData(data)
					},
					{
						name: '背景',
						type: 'bar',
						barBorderRadius: 6,
						barWidth: 4,
						barGap: '-100%',
						z: 1,
						data: defaultData,
						itemStyle: {
							normal: {
								color: 'rgba(244, 246, 249, 1)'
							}
						}
					},
					{
						name: '条',
						type: 'bar',
						barBorderRadius: 6,
						yAxisIndex: 0,
						// barCateGoryGap: 82,
						data: data,
						barWidth: 4,
						showBackground: true,
						itemStyle: {
							normal: {
								color: function (params) {
									const colorList = [
										['#A25DDC', '#FEBDF0'],
										// ['#F5cF0D', '#fa9203'],
										// ['#61dbe8', '#0785de'],
										// ['#ff9717', '#ff4518']
									];
                  for (const status of taskStatusList) {
                    colorList.push([status.color,status.color])
                  }
									let index = params.dataIndex;
									if (params.dataIndex >= colorList.length) {
										index = params.dataIndex - colorList.length;
									}
									return new echarts.graphic.LinearGradient(0, 0, 1, 0, [
										{
											offset: 0.6,
											color: colorList[index][1]
										},
										{
											offset: 0,
											color: colorList[index][0]
										}
									]);
								},
								barBorderRadius: 6
							},
							barBorderRadius: 4
						},
						label: {
							normal: {
								color: '#000000',
								show: true,
								position: [0, '-24px'],
								textStyle: {
									color: 'rgba(22, 28, 31, 1)',
									fontFamily: 'PingFang SC',
									fontSize: '12px'
								},
								formatter: function (a) {
									return a.name;
								}
							}
						}
					}
				]
			};
			return params;
		},
		getArrByKey(data, k) {
			let key = k || 'value';
			let res = [];
			if (data) {
				data.forEach(function (t) {
					res.push(t[key]);
				});
			}
			return res;
		},
		getSymbolData(data) {
			let arr = [];
			for (var i = 0; i < data.length; i++) {
				arr.push({
					value: data[i].value,
					symbolPosition: 'end'
				});
			}
			return arr;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.chart = echarts.init(this.$refs.chartBox);
			this.renderChart();
			this.resizeFn = () => {
				this.chart.resize();
			};
			window.addEventListener('resize', this.resizeFn);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeFn);
		this.chart.dispose();
	}
};
</script>

<style lang="less" scoped>
.task-analysis {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
	.task-display {
		width: 100%;
		flex: 1;
		.chart-box {
			width: 100%;
			height: 100%;
		}
	}
	.task-title {
		width: 100%;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 8px 5px 8px;
		box-sizing: border-box;
		margin-bottom: 8px;
		p {
			padding-left: 8px;
			box-sizing: border-box;
			color: rgba(24, 27, 34, 1);
			font-family: PingFang SC;
			font-size: 14px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: 0.5px;
			text-align: left;
		}
	}
	.single-line {
		width: 100%;
		border-top: 1px solid #eff1f5;
	}
}
</style>
