<!--
 * @Description: 组件盒子
 * @Author: luocheng
 * @Date: 2021-08-18 11:57:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-18 11:59:26
-->
<template>
	<!-- <el-col
		class="component-box"
		:style="getBoxStyle(element)"
		:data-id="element.id"
		:span="element.span !== undefined ? +element.span : -1"
		v-if="element.statusConfig && element.statusConfig.isShow
			&& judgingArchiAPermission(element.archiLimit, element.authConfig && element.authConfig.list)
			&& getUILogicStatus()"
	> -->
		<!-- :span="element.span !== undefined ? +element.span : -1" -->
	<AnimateCom :key="element.id"  :animations="element.animations">
		<div 
			class="component-box"
			:style="getBoxStyle(element)"
			:data-id="element.id"
			:class="[
				'component-box-span-' + (+element.span),
				getHoverClass(element?.specialStyle?.hoverConfig)
			]"
			v-if="element.statusConfig
				&& element.statusConfig.isShow
				&& judgingArchiAPermission(element.archiLimit, element.authConfig.list)
				&& judgingUILogic(element.UILogic, componentList || componentData, mapData)"
		>
			<slot name="inner"></slot>
			<!-- v-show="element.statusConfig
				&& (element.statusConfig.displayShow || element.statusConfig.displayShow === undefined)
			" -->
			<component
				:is="element.component"
				:element="element"
				:containerData="containerData"
				:style="getComStyle(element)"
				:defaultTableSelectData="defaultTableSelectData"
				:componentList="componentList"
				:mapData="mapData"
			>
			</component>
		</div>
	</AnimateCom>
	<!-- </el-col> -->
</template>

<script>
import { Col } from 'element-ui';
import { getStyle } from '@/utils/style';
import { mapState } from 'vuex';
import { judgingArchiAPermission, getComponentById, judgingUILogic } from '@/utils/tools';
import AnimateCom from '@/components/parser/screen/AnimateCom.vue'

// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
componentsConsole.keys().forEach((fileName) => {
  let name = fileName.replace(/.vue/, '');
  name = name.replace(/\.\//, '');
  name = name.split('/')[1];
  customComponents[name] = componentsConsole(fileName).default;
});
export default {
	name: 'ComponentBox',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 容器数据
		containerData: {
			type: [Object, Array],
			required: false
		},
		// 表格默认选中数据
		defaultTableSelectData: {
			type: Array,
			default: () => []
		},
		// 组件数据
		componentList: {
			type: Array,
			default: () => null
		},
		// 循环映射值
		mapData: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		...customComponents,
		'el-col': Col,
		AnimateCom
	},
	computed: {
		...mapState([
			'curComponent', // 当前组件
			'componentData'
		])
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @description: 获取hover动效类名
		 * @param {*} element
		 * @return {*}
		 */
		getHoverClass(hoverConfig) {
			if (!hoverConfig || !hoverConfig.use) return '';
			if (hoverConfig?.config?.type === 'boxShadow' && hoverConfig?.config?.shadowConfig?.inset) {
				return 'component-box-hover-inset';
			} 
			return 'component-box-hover';
		},
		/**
		 * @desc: 获取UI判断逻辑(暂弃用)
		*/
		getUILogicStatus() {
			const { UILogic } = this.element;
			if (!UILogic || !UILogic.enable) return true;
			const { logicConfigs } = UILogic;
			const logicList = [];
			for (let i = 0; i < logicConfigs.length; i++) {
				const item = logicConfigs[i];
				const { dataAttr, field, itemLogic, logicRule, type, value, valueType,
					componentId,
					dataOrigin,
					valueField
				} = item;
				// 逻辑关系
				let itemBoolean = true;
				let logicValue = null;
				if (type === 'userInfo') {
					// 用户信息
					const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
					logicValue = userInfo && userInfo[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'framework') {
					// 架构信息
					const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
					logicValue = targetArchi && targetArchi[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'component') {
					// 组件
					let originComponent = getComponentById(this.componentData, componentId);
					if (!originComponent && this.isGroup && this.groupComponents.length) {
						originComponent = getComponentById(this.groupComponents, componentId);
					}
					if (!originComponent) return false;
					let logicData = null;
					if (dataOrigin === 'resolveData') {
						// 暴露值
						logicData = originComponent?.resolveData;
					} else if (dataOrigin === 'componentData') {
						// 组件数据
						logicData = originComponent?.fullData || originComponent?.containerData;
					}
					if (dataAttr === 'value') {
						logicValue = logicData;
						if (valueField) {
							// 取值字段
							logicValue = logicData?.[valueField];
						}
					} else if (dataAttr === 'length') {
						try {
							logicValue = logicData.length;
						} catch (e) {
							console.log(e, '---999----')
						}
					}
				}
				// 逻辑关系
				if (logicRule === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (logicRule === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (logicRule === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (logicRule === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (logicRule === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (logicRule === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				} else if (logicRule === 'like') {
					if (valueType === 'string') {
						itemBoolean = logicValue.toString()?.includes(value.toString());
					}
				} else if (logicRule === 'whereIn') {
					// 包含
					try {
						let valArr = [];
						if (Array.isArray(logicValue)) {
							valArr = logicValue;
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
							valArr = JSON.parse(logicValue);
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (value.toString()?.startsWith('[') && value.toString()?.endsWith(']')) {
							valArr = JSON.parse(value);
							itemBoolean = valArr.includes(logicValue) || valArr.includes(+logicValue);
						} else {
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						}
					} catch (err) {
						console.log(err);
					}
				}
				logicList.push({
					itemBoolean,
					itemLogic
				});
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 组合逻辑判断
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * @desc: 根据ID在数组中获取组件
		 * @param {Array} arr 组件列表
		 * @param {String} id 组件ID
		 * @return {Object} 组件对象
		 */
		getChildComponent(arr, id) {
			if (!arr || !Array.isArray(arr) || !arr.length) return null;
			let result = null;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].id === id) {
					result = arr[i];
					break;
				}
				if (arr[i].children && arr[i].children.length) {
					result = this.getChildComponent(arr[i].children, id);
					if (result) {
						break;
					}
				}
			}
			return result;
		},
		/**
		 * @desc: 组件盒型样式
		 * @param {Object} element 组件对象
		 */
		getBoxStyle(element) {
			if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
			const { style, specialStyle } = element;
			if (!style) return {};
			// , 'overflowY', 'overflowX' 如果表格容器又问题则需注释，但是注释后循环容器有问题
			let excludesArr = ['top', 'left', 'rotate', 'overflowY', 'overflowX'];
			let result = getStyle(style, excludesArr);
			if ((this.element.span !== undefined && +this.element.span !== -1)
				|| (this.element.component === 'CommonTableContainer' && result.width && result.width.toString().indexOf('calc') === -1)
			) {
				result.width = '100%';
			}
			// 特殊样式
			if (specialStyle.hoverConfig?.use) {
				const { type, shadowConfig, borderConfig } = specialStyle?.hoverConfig?.config || {};
				if (type === 'boxShadow' && shadowConfig) {
					const {v, h, blur, spread, color = 'rgba(104, 110, 123, .25)' } = shadowConfig;
					result = {
						...result,
						'--hoverH': `${h}px`,
						'--hoverW': `${v}px`,
						'--hoverBlur': `${blur}px`,
						'--hoverSpread': `${spread}px`,
						'--hoverColor': color,
						// '--hoverInset': `${inset ? 'inset' : ''}`
					}
				} else if (type === 'border' && borderConfig) {
					const { w, style = 'solid', color = '#337EFF'} = borderConfig;
					result = {
						...result,
						'--borderW': `${w}px`,
						'--borderStyle': style,
						'--borderColor': color
					}
				}
			}
			if (specialStyle.isCollector) {
				// 收集者
				result.flex = 1;
			}
			if (specialStyle.isAbsolute) {
				result.position = 'absolute';
				const { absoluteTop = '', absoluteLeft = '', absoluteBottom = '', absoluteRight = '', zIndex = 10 } = specialStyle;
				if (absoluteTop !== '') {
					result.top = isNaN(+absoluteTop) ? absoluteTop : `${absoluteTop}px` ;
				}
				if (absoluteBottom !== '') {
					result.bottom = isNaN(+absoluteBottom) ? absoluteBottom : `${absoluteBottom}px` ;
				}
				if (absoluteLeft !== '') {
					result.left = isNaN(+absoluteLeft) ? absoluteLeft : `${absoluteLeft}px` ;
				}
				if (absoluteRight !== '') {
					result.right = isNaN(+absoluteRight) ? absoluteRight : `${absoluteRight}px` ;
				}
				result.zIndex = isNaN(+zIndex) ? 10 : zIndex;
			}
			// if(element.id === 'CommonContainer-1662621612732') {
			// 	console.log(result, '---123123--0000000-', element)
			// }
			return result;
		},
		/**
		 * @desc: 组件样式
		 * @param {Object} element 组件对象
		 */
		getComStyle(element) {
			if (element.component === 'CommonModel' || element.component==='ConsoleBimModel') return;
			const { style } = element;
			// 基础样式
			if (!style) return {};
			let excludesArr = [
				'top',
				'left',
				'rotate',
				'paddingLeft',
				'paddingRight',
				'paddingTop',
				'paddingBottom',
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'borderWidth',
				'borderStyle',
				'borderColor',
				// 'borderRadius'
			];
			let result = getStyle(style, excludesArr);
			if ((this.element.span !== undefined && +this.element.span !== -1)
				|| (this.element.component === 'CommonTableContainer' && result.width && result.width.toString().indexOf('calc') > -1)) {
				result.width = '100%';
			}
			if (element.specialStyle.flexDirection) {
				// 轴方向
				result.flexDirection = element.specialStyle.flexDirection;
			}
      result.flexWrap = element.specialStyle.flexWrap ? 'wrap' : 'nowrap';
			result.justifyContent =  element.specialStyle?.justifyContent;
			result.height = '100%';
			result.width = '100%';
			if (this.element.statusConfig.bold) {
				result.fontWeight = 'bold'
			}
			if (this.element.statusConfig.italic) {
				result.fontStyle = 'oblique'
			}
			return result;
		}
	}
};
</script>

<style lang="less" scoped>
.component-box {
	box-sizing: border-box;
	cursor: pointer;
	position: relative;
	&.component-box-hover-inset {
		&:hover {
			box-shadow: var(--hoverH) var(--hoverW) var(--hoverBlur) var(--hoverSpread) var(--hoverColor) inset;
			border: var(--borderW) var(--borderStyle) var(--borderColor);
		}
	}
	&.component-box-hover {
		&:hover {
			box-shadow: var(--hoverH) var(--hoverW) var(--hoverBlur) var(--hoverSpread) var(--hoverColor);
			border: var(--borderW) var(--borderStyle) var(--borderColor);
		}
	}
	&.component-box-span-1{
		width: 4.17%;
	}
	&.component-box-span-2{
		width: 8.33%;
	}
	&.component-box-span-3{
		width: 12.5%;
	}
	&.component-box-span-4{
		width: 16.67%;
	}
	&.component-box-span-5{
		width: 20.83%;
	}
	&.component-box-span-6{
		width: 25%;
	}
	&.component-box-span-7{
		width: 29.17%;
	}
	&.component-box-span-8{
		width: 33.33%;
	}
	&.component-box-span-9{
		width: 37.5%;
	}
	&.component-box-span-10{
		width: 41.67%;
	}
	&.component-box-span-6{
		width: 45.83%;
	}
	&.component-box-span-12{
		width: 50%;
	}
	&.component-box-span-13{
		width: 54.16%;
	}
	&.component-box-span-14{
		width: 58.33%;
	}
	&.component-box-span-15{
		width: 62.5%;
	}
	&.component-box-span-16{
		width: 66.67%;
	}
	&.component-box-span-17{
		width: 70.83%;
	}
	&.component-box-span-18{
		width: 75%;
	}
	&.component-box-span-19{
		width: 79.17%;
	}
	&.component-box-span-20{
		width: 83.33%;
	}
	&.component-box-span-21{
		width: 87.5%;
	}
	&.component-box-span-22{
		width: 91.67%;
	}
	&.component-box-span-23{
		width: 95.83%;
	}
	&.component-box-span-24{
		width: 100%;
	}
}
</style>