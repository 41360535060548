<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-04-17 11:44:50
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2024-04-30 15:12:57
 * @FilePath: /dataview-next/src/custom-component/dialog/cache/List.vue
 * @Description: 
-->
<template>
  <div class="cache-list" v-loading="loading">
    <el-form :inline="true" :model="filterData" class="demo-form-inline">
      <el-form-item>
        <el-date-picker
          v-model="filterData.date"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getList"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="filterData.name"
          placeholder="搜索草稿名称"
          prefix-icon="el-icon-search"
          clearable
          @change="getList"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table
      :height="560"
      :data="cacheData"
      :border="true"
      style="width: 100%"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
    >
      <el-table-column type="index" label="序号" width="76"> </el-table-column>
      <el-table-column prop="created_at" label="草稿时间" width="160">
      </el-table-column>
      <el-table-column prop="name" label="草稿名称" width="296">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click.native.prevent="onSelect(scope.row)" type="text">
            应用草稿
          </el-button>
          <el-button @click.native.prevent="onRemove(scope.row)" type="text" style="color: rgba(255, 111, 111, 1);">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <footer class="footer">
      <el-pagination
        @size-change="onSize"
        @current-change="onPage"
        :current-page="pageObj.currenPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageObj.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageObj.total">
      </el-pagination>
    </footer>
  </div>
</template>

<script>
import { DatePicker, Input, Pagination } from "element-ui";
import { dataInterface } from '@/apis/data/index';

export default {
  name: "CacheList",
  components: {
    "el-date-picker": DatePicker,
    "el-input": Input,
    'el-pagination': Pagination
  },
  props: {
    formUUID: {
      type: String,
      default: ''
    },
    objectUUID: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filterData: {
        date: "",
        name: "",
      },
      cacheData: [],
      headerCellStyle: {
        background: 'rgba(242, 245, 250, 1)',
        border: 'none',
        color: 'rgba(24, 27, 34, 1)',
        padding: '12px 16px'
      },
      cellStyle: {
        padding: '12px 16px'
      },
      pageObj: {
        total: 0,
        currenPage: 1,
        pageSize: 15
      },
      loading: false,
      selectCache: {}
    };
  },
  methods: {
    /**
     * @description: 获取草稿列表
     */
    getList() {
      this.loading = true;
      const params = {
				key: `formcache-${this.formUUID || this.objectUUID}`,
        page: this.pageObj.currenPage,
        size: this.pageObj.pageSize,
        clearHistory: 0,
      }
      if (this.filterData.name) {
        params.name = this.filterData.name
      }
      if (this.filterData.date?.length) {
        params.start_date = this.filterData.date?.[0] || ''
        params.end_date = this.filterData.date?.[1] || ''
      }
      dataInterface(params, 'api/form4/dataCache', 'GET').then(res => {
        this.cacheData = res?.data?.data?.data || []
        this.pageObj.total = res?.data?.data?.total || 0
        this.loading = false;
      })
    },
    /**
     * @description: 选择草稿
     * @param {Object} data
     */
    onSelect(data) {
      this.selectCache = data
      this.$emit('selectCache', data)
    },
    /**
     * @description: 移除
     * @param {Object} data
     */
    onRemove(data) {
      this.$confirm(`是否确认删除草稿记录【${data.name || '未命名'}】，删除后不可恢复，请谨慎操作!`).then(() => {
        this.loading = true;
        dataInterface({
          object_uuid: 'object61bbffbdabbbe',
          data_id: data.id,
          __method_name__: 'deleteData',
        }).then(res => {
          if (res.status === 200) {
            this.cacheData = []
            this.getList()
          }
          this.loading = false;
        })
      }).catch(() => {});
    },
    /**
     * @description: 切换每页数据
     */
    onSize(data) {
      this.pageObj.currenPage = 1;
      this.pageObj.pageSize = data;
      this.getList();
    },
    /**
     * @description: 切换页码
     */
    onPage(data) {
      this.pageObj.currenPage = data;
      this.getList();
    }
  },
  created() {
    this.getList();
  }
};
</script>

<style lang="less" scoped>
.cache-list {
  width: 100%;
  height: 664px;
  overflow: hidden;
  :deep(.el-form) {
    .el-form-item--small.el-form-item {
      margin-bottom: 16px;
    }
  }
  :deep(.el-table) {
    .el-table__header-wrapper {
      .cell {
        padding: 0;
        letter-spacing: .4px;
        font-size: 13px;
      }
    }
    .cell {
      padding: 0;
    }
    .el-button+.el-button {
      margin-left: 24px;
    }
  }
  .footer {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    padding: 12px 0;
    :deep(.el-pagination) {
      display: flex;
      .el-pagination__total {
        flex: 1;
      }
    }
  }
}
</style>
