<template>
  <ViewPointSingle
          ref="viewPointTree"
          class="moduleWrap"
          :element.sync="element"
          :bindViewerId="bindViewerId"
          :bindViewerType="bindViewerType"
          :viewFilter="viewFilter"
          :viewFilterIndex="viewFilterIndex"
          @dataChange="onDataChange"
          @addGroup="onAddGroup"
          @addViewPoint="onAddViewPoint"
          @renameGroup="onRenameGroup"
          @deleteGroup="onDeleteGroup"
          @defaultViewPoint="onDefaultViewPoint"
          @undefaultViewPoint="onUndefaultViewPoint"
          @setViewPoint="onSetViewPoint"
          @renameViewPoint="onRenameViewPoint"
          @refreshViewPoint="onRefreshViewPoint"
          @deleteViewPoint="onDeleteViewPoint"
  ></ViewPointSingle>
</template>

<script>
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';
import ViewPointSingle from "@/custom-component/viewerComponent/ViewPointSingle";

const DataBaseInfo = {
  Group: {
    object_uuid: 'object63a957e49579b',
    view_uuid: {
      all: 'view63a95806c9d19',
      user_id: 'view63acf85b00d84',
      archi_id: 'view63acf85e22389'
    }
  },
  ViewPoint: {
    object_uuid: 'object63a15d09d4f7f',
    view_uuid: {
      all: 'view63a15da09d7b4',
      user_id: 'view63acf8fcf12ad',
      archi_id: 'view63acf9055957e'
    }
  }
};

export default {
  name: 'ViewerGISViewPoint',
  components: {
    ViewPointSingle
  },
  data() {
    return {
      bindViewerType: 'GIS',
      bindViewerId: null,
      bindModelId: null,
      bindMarkerId: null,
      bindPlotId: null,
      bindLayerId: null,

      modelStatusList: undefined,
      markerStatusList: undefined,
      plotStatusList: undefined,
      layerStatusList: undefined,

      viewFilter: [
        { id: 'all', name: '所有数据' },
        { id: 'user_id', name: '由我创建' },
        { id: 'archi_id', name: '项目中的所有视图' }
      ],
      viewFilterIndex: 0,

      compInitedFlag: false
    };
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    isGroup: {
      type: Boolean
    },
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  mixins: [CustomComponentMixin],
  computed: {
    ...mapState(['componentData', 'originComponentData'])
  },
  mounted() {
    this.initEventListener();
    this.initCompListener();

    this.initCheckBind();

    this.initViewerCreatedListener(this.bindViewerId, (opt = {}) => {
              let {viewerInited = false} = opt;
              if(viewerInited){
                 this.getViewPointData(this.bindViewerId);
              }
            },
    );
  },
  methods: {
    /**
     * @description: 注册组件事件
     */
    initEventListener() {
      eventBus.$on('ViewerGISViewPoint.GetSource', (eid, cb) => {
        if (eid !== this.element.id) return;

        const loop = (array = [], resList = [], type) => {
          for (let item of array) {
            if (item.component !== type) {
              if (item.children && item.children.length > 0)
                loop(item.children, resList, type);
              continue;
            }

            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
            resList.push({
              id: item.id,
              name: item.name
            });
          }

          return resList;
        };

        const viewerComps = [];
        loop(this.subComponentData, viewerComps, 'ViewerGIS');
        // loop(this.subComponentData, viewerComps, 'ViewerGIS');

        const modelComps = [];
        loop(this.subComponentData, modelComps, 'ViewerGISModelTree');
        // loop(this.subComponentData, modelComps, 'ViewerGISModelTree');

        const markerComps = [];
        loop(this.subComponentData, markerComps, 'ViewerGISMarker');
        // loop(this.subComponentData, markerComps, 'ViewerGISMarker');

        const plotComps = [];
        loop(this.subComponentData, plotComps, 'ViewerGISPlot');
        // loop(this.subComponentData, plotComps, 'ViewerGISPlot');

        const layerComps = [];
        loop(this.subComponentData, layerComps, 'ViewerGISLayer');
        // loop(this.subComponentData, layerComps, 'ViewerGISLayer');

        cb({
          viewerList: viewerComps,
          viewerBindId: this.bindViewerId,
          modelList: modelComps,
          modelBindId: this.bindModelId,
          markerList: markerComps,
          markerBindId: this.bindMarkerId,
          plotList: plotComps,
          plotBindId: this.bindPlotId,
          layerList: layerComps,
          layerBindId: this.bindLayerId
        });
      });

      eventBus.$on('ViewerGISViewPoint.BindSource', (eid, {viewerBindId, modelBindId, markerBindId, plotBindId, layerBindId}) => {
        if (eid !== this.element.id) return;

        if(this.checkBind(viewerBindId)){
          this.element.viewerBindId = viewerBindId;
          this.bindViewerId = viewerBindId;
          this.$message.success(`[${this.element.name}] 绑定GIS Viewer成功`);
        }

        if(this.checkBind(modelBindId)){
          this.element.modelBindId = modelBindId;
          this.bindModelId = modelBindId;
          this.$message.success(`[${this.element.name}] 绑定GIS模型树组件成功`);
        }

        if(this.checkBind(markerBindId)){
          this.element.markerBindId = markerBindId;
          this.bindMarkerId = markerBindId;
          this.$message.success(`[${this.element.name}] 绑定GIS标记组件成功`);
        }

        if(this.checkBind(plotBindId)){
          this.element.plotBindId = plotBindId;
          this.bindPlotId = plotBindId;
          this.$message.success(`[${this.element.name}] 绑定GIS标绘组件成功`);
        }

        if(this.checkBind(layerBindId)){
          this.element.layerBindId = layerBindId;
          this.bindLayerId = layerBindId;
          this.$message.success(`[${this.element.name}] 绑定GIS图层组件成功`);
        }
      });
    },
    /**
     * @description: 注册关联组件监听事件
     */
    initCompListener(){
      eventBus.$on('ViewerGISViewPoint.GetModelStatus', (eid, keys) => {
        if (eid !== this.bindModelId) return;

        this.modelStatusList = keys;
      });

      eventBus.$on('ViewerGISViewPoint.GetMarkerStatus', (eid, keys) => {
        if (eid !== this.bindMarkerId) return;

        this.markerStatusList = keys;
      });

      eventBus.$on('ViewerGISViewPoint.GetPlotStatus', (eid, keys) => {
        if (eid !== this.bindPlotId) return;

        this.plotStatusList = keys;
      });

      eventBus.$on('ViewerGISViewPoint.GetLayerStatus', (eid, keys) => {
        if (eid !== this.bindLayerId) return;

        this.layerStatusList = keys;
      });
    },
    /**
     * @description: 注册Viewer组件初始化监听事件
     * @param {String} bindId 关联组件Id
     * @param {Function} cb 仅Viewer加载完成后的触发方法
     */
    initViewerCreatedListener(bindId = this.bindViewerId, cb = null){
      function createListener() {
        eventBus.$on("ViewerGIS.onViewerCreated", (eid, opt = {}) => {
          if (eid !== bindId) return;

          cb && cb(opt);
        });
      }

      if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && bindId){
        eventBus.$emit("ViewerGIS.getViewerInitedFlag", bindId, (opt = {}) => {
          let {viewerInited = false} = opt;
          if(viewerInited){
            cb && cb(opt);
          }else{
            createListener();
          }
        });
      }else{
        createListener();
      }
    },
    /**
     * @description: 注册Viewer组件初始化监听事件
     * @param {String} bindId 关联组件Id
     * @param {Function} cb Viewer及模型都加载完成触发方法
     */
    initViewerInitedListener(bindId = this.bindViewerId, cb = null){
      function initListener() {
        eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
          if (eid !== bindId) return;

          let {viewerInited = false, dynamicLoaded = false} = opt;
          if(viewerInited && dynamicLoaded){
            cb && cb();
          }
        });
      }

      eventBus.$emit("ViewerGIS.getViewerInitedFlag", bindId, (opt = {}) => {
        let {viewerInited = false, dynamicLoaded = false} = opt;
        if(viewerInited && dynamicLoaded){
          cb && cb();
        }else{
          initListener();
        }
      });
    },
    /**
     * @description: 注册Model组件初始化监听事件
     * @param {String} bindId 关联组件Id
     * @param {Function} cb 触发方法
     */
    initModelInitedListener(bindId = this.bindModelId, cb = null){
      function listener() {
        eventBus.$on("ViewerGISModelTree.onCompInited", (eid) => {
          if (eid !== bindId) return;

          cb && cb();
          eventBus.$off('ViewerGISModelTree.onCompInited');
        });
      }

      if(eventBus._events['ViewerGISModelTree.getCompInitedFlag'] && bindId){
        eventBus.$emit("ViewerGISModelTree.getCompInitedFlag", bindId, (flag) => {
          if(flag){
            cb && cb();
          }else{
            listener();
          }
        });
      }else{
        listener();
      }
    },
    /**
     * @description: 注册Marker组件初始化监听事件
     * @param {String} bindId 关联组件Id
     * @param {Function} cb 触发方法
     */
    initMarkerInitedListener(bindId = this.bindMarkerId, cb = null){
      function listener() {
        eventBus.$on("ViewerGISMarker.onCompInited", (eid) => {
          if (eid !== bindId) return;

          cb && cb();
          eventBus.$off('ViewerGISMarker.onCompInited');
        });
      }

      if(eventBus._events['ViewerGISMarker.getCompInitedFlag'] && bindId){
        eventBus.$emit("ViewerGISMarker.getCompInitedFlag", bindId, (flag) => {
          if(flag){
            cb && cb();
          }else{
            listener();
          }
        });
      }else{
        listener();
      }
    },
    /**
     * @description: 注册Plot组件初始化监听事件
     * @param {String} bindId 关联组件Id
     * @param {Function} cb 触发方法
     */
    initPlotInitedListener(bindId = this.bindPlotId, cb = null){
      function listener() {
        eventBus.$on("ViewerGISPlot.onCompInited", (eid) => {
          if (eid !== bindId) return;

          cb && cb();
          eventBus.$off('ViewerGISPlot.onCompInited');
        });
      }

      if(eventBus._events['ViewerGISPlot.getCompInitedFlag'] && bindId){
        eventBus.$emit("ViewerGISPlot.getCompInitedFlag", bindId, (flag) => {
          if(flag){
            cb && cb();
          }else{
            listener();
          }
        });
      }else{
        listener();
      }
    },
    /**
     * @description: 注册Layer组件初始化监听事件
     * @param {String} bindId 关联组件Id
     * @param {Function} cb 触发方法
     */
    initLayerInitedListener(bindId = this.bindLayerId, cb = null){
      function listener() {
        eventBus.$on("ViewerGISLayer.onCompInited", (eid) => {
          if (eid !== bindId) return;

          cb && cb();
          eventBus.$off('ViewerGISLayer.onCompInited');
        });
      }

      if(eventBus._events['ViewerGISLayer.getCompInitedFlag'] && bindId){
        eventBus.$emit("ViewerGISLayer.getCompInitedFlag", bindId, (flag) => {
          if(flag){
            cb && cb();
          }else{
            listener();
          }
        });
      }else{
        listener();
      }
    },
    /**
     * @description: 初始化默认视点
     */
    initDefaultViewPoint(){
      let defaultItem = this.$refs.viewPointTree.getDefaultItem();
      if(!defaultItem) return;

      this.setStateOnlyBase(defaultItem);

      this.initViewerInitedListener(this.bindViewerId, () => {
        this.setStateOnlyModule(defaultItem);
      });
    },
    /**
     * @description: 初始化组件配置参数
     */
    initCheckBind(){
      if (this.element.viewerBindId) {
        if (this.checkBind(this.element.viewerBindId)) {
          this.bindViewerId = this.element.viewerBindId;
        }
      }

      if (this.element.modelBindId) {
        if (this.checkBind(this.element.modelBindId)) {
          this.bindModelId = this.element.modelBindId;
        }
      }

      if (this.element.markerBindId) {
        if (this.checkBind(this.element.markerBindId)) {
          this.bindMarkerId = this.element.markerBindId;
        }
      }

      if (this.element.plotBindId) {
        if (this.checkBind(this.element.plotBindId)) {
          this.bindPlotId = this.element.plotBindId;
        }
      }

      if (this.element.layerBindId) {
        if (this.checkBind(this.element.layerBindId)) {
          this.bindLayerId = this.element.layerBindId;
        }
      }
    },
    /**
     * @description: 检验绑定的组件Id是否有效
     * @param {String} bindId 组件标识值
     */
    checkBind(bindId = null) {
      if(!bindId) return false;

      const checkFunc = (bid, list) => {
        let hasComp = false;
        if(!list) return hasComp;

        for (let comp of list) {
          if (comp.children) {
            const flag = checkFunc(bid, comp.children);

            if (flag) {
              hasComp = true;
              break;
            }
          }
          if (comp.id !== bid) continue;

          hasComp = true;
          break;
        }

        return hasComp;
      };

      const hasComp = checkFunc(bindId, this.subComponentData);
      // const hasOriginComp = checkFunc(bindId, this.subComponentData);

      if (!hasComp)
        return this.$message.error(
          `[${this.element.name}] 绑定失效，组件可能已经被删除`
        );

      return hasComp;
    },

    /**
     * @description: 获取数据仓库检索条件
     * @param {String} type 请求类型
     */
    getDataSearch(type) {
      const user_id = this.$GetUserInfo('id');
      const archi_id = this.$GetTargetArchi('id');

      let search = [];

      if (type === 'Group') {
        search.push({
          code: 'type',
          ruleType: 'eq',
          value: 'ViewPoint'
        });
      }

      if (this.viewType === 'all' || this.viewType === 'archi_id') {
        search.push({
          code: 'archi_type',
          ruleType: 'eq',
          value: archi_id
        });
      } else if (this.viewType === 'user_id') {
        search.push({
          code: 'user_id',
          ruleType: 'eq',
          value: user_id
        });
      }

      return search;
    },
    /**
     * @description: 获取视点列表数据
     */
    async getViewPointData(bindId = this.bindViewerId) {
      if (!bindId) {
        this.$refs.viewPointTree.emptyTreeData();
        return;
      }

      this.$refs.viewPointTree.emptyTreeData();
      this.$refs.viewPointTree.setIsLoading(true);

      let viewType = this.viewFilter[this.viewFilterIndex].id;
      const groupRes = await dataInterface({
        __method_name__: 'dataList',
        object_uuid: DataBaseInfo.Group.object_uuid,
        view_uuid: DataBaseInfo.Group.view_uuid[viewType],
        transcode: 0,
        search: this.getDataSearch('Group')
      });

      if (
              groupRes?.data?.data &&
              groupRes?.data?.data?.data &&
              groupRes.status === 200
      ) {
        const groupList = groupRes?.data?.data?.data;

        for (let { id, name } of groupList) {
          let item = {
            id: `group_${id}`,
            name: name,
            type: 'Group',
            children: []
          };
          this.$refs.viewPointTree.appendTreeData(item, null);
        }
      }

      const childRes = await dataInterface({
        __method_name__: 'dataList',
        object_uuid: DataBaseInfo.ViewPoint.object_uuid,
        view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
        transcode: 0,
        search: this.getDataSearch('Child')
      });

      if (
              childRes?.data?.data &&
              childRes?.data?.data?.data &&
              childRes.status === 200
      ) {
        const childList = childRes?.data?.data?.data;

        for (let {id, name, group_id, tooltip, img, state, created_at, isDefault} of childList) {
          let item = {
            id: `child_${id}`,
            type: 'Child',
            name: name,
            tooltip: tooltip,
            group_id: group_id,
            img: img,
            state: state,
            created_at: created_at,
            isDefault: isDefault && isDefault !== "undefined" ? JSON.parse(isDefault) : false
          };
          this.$refs.viewPointTree.appendTreeData(item, `group_${group_id}`);
        }
      }

      this.$refs.viewPointTree.setDefaultExpand();
      this.$refs.viewPointTree.setIsLoading(false);

      if(!this.compInitedFlag){
        this.compInitedFlag = true;
        this.initDefaultViewPoint();
      }
    },

    /**
     * @description: 复现视点仅基础部分
     * @param {Object} data 数据对象
     */
    setStateOnlyBase(data){
      eventBus.$emit('ViewerGIS.setViewPointStateOnlyBase', this.bindViewerId, data.state);
      eventBus.$emit('ViewerGIS.setDefaultView', this.bindViewerId, data.state.camera, 0);
    },
    /**
     * @description: 复现视点仅组件数据部分
     * @param {Object} data 数据对象
     */
    setStateOnlyModule(data){
      let {model, marker, plot, layer} = data.state;
      if(model){
        this.initModelInitedListener(this.bindModelId, () => {
          if(layer && layer.model) model = layer.model;
          eventBus.$emit('ViewerGISModelTree.onModelShow', this.bindModelId, model);
        });
      }

      if(marker){
        this.initMarkerInitedListener(this.bindMarkerId, () => {
          eventBus.$emit('ViewerGISMarker.onMarkerShow', this.bindMarkerId, marker);
        });
      }

      if(plot){
        this.initPlotInitedListener(this.bindPlotId, () => {
          eventBus.$emit('ViewerGISPlot.onPlotShow', this.bindPlotId, plot);
        });
      }

      if(layer){
        this.initLayerInitedListener(this.bindLayerId, () => {
          eventBus.$emit('ViewerGISLayer.onGISShow', this.bindLayerId, layer?.gis);
          eventBus.$emit('ViewerGISLayer.onModelShow', this.bindLayerId, layer?.model);
        });
      }
    },

    /**
     * @description: 数据视图改变，重新获取数据
     * @param {Number} index 数据视图索引
     */
    onDataChange(index){
      if(this.viewFilterIndex === index) return;

      this.viewFilterIndex = index;
      this.getViewPointData(this.bindViewerId);
    },
    /**
     * @description: 添加分组
     */
    async onAddGroup(){
      let name = await this.$prompt('请输入分组名称：', '提示').catch(() => {});
      if(!name) return;

      let viewType = this.viewFilter[this.viewFilterIndex].id;
      const res = await dataInterface({
        __method_name__: 'createData',
        object_uuid: DataBaseInfo.Group.object_uuid,
        view_uuid: DataBaseInfo.Group.view_uuid[viewType],
        type: 'ViewPoint',
        name: name.value
      });

      if (!res?.data?.data || res.status !== 200){
        return this.$message.warning(`[GIS]创建视点分组失败！`);
      }
      const data = res?.data?.data;

      let item = {
        id: `group_${data.id}`,
        name: name.value,
        type: 'Group',
        children: []
      };
      this.$refs.viewPointTree.appendTreeData(item, null);
      this.$message.success(`创建视点分组成功！`);
    },
    /**
     * @description: 重命名分组
     * @param {Object} data 数据对象
     */
    async onRenameGroup(data){
      let name = await this.$prompt('请输入分组名称：', '提示', {
        inputValue: data.name
      }).catch(() => {});
      if(!name) return;

      let viewType = this.viewFilter[this.viewFilterIndex].id;
      await dataInterface({
        __method_name__: 'updateData',
        object_uuid: DataBaseInfo.Group.object_uuid,
        view_uuid: DataBaseInfo.Group.view_uuid[viewType],
        data_id: data.id.split('_')[1],
        name: name.value
      });

      this.$refs.viewPointTree.updateTreeData(data.id, 'name', name.value);
      this.$message.success(`视点分组重命名成功！`);
    },
    /**
     * @description: 删除分组
     * @param {Object} data 数据对象
     */
    async onDeleteGroup(data){
      if(data.children){
        let children = [];
        for(let item of data.children){
          children.push({
            id: item.id,
            group_id: item.group_id
          })
        }

        for(let item of children){
          await this.onDeleteViewPoint(item);
        }
      }

      let viewType = this.viewFilter[this.viewFilterIndex].id;
      await dataInterface({
        __method_name__: 'deleteData',
        object_uuid: DataBaseInfo.Group.object_uuid,
        view_uuid: DataBaseInfo.Group.view_uuid[viewType],
        data_id: data.id.split('_')[1]
      });
      this.$refs.viewPointTree.deleteTreeData(data.id);
      this.$message.success(`[GIS]视点分组删除成功！`);
    },

    /**
     * @description: 添加视点
     * @param {Object} group 分组数据对象
     */
    async onAddViewPoint(group){
      if (!this.bindViewerId) return this.$message.warning('[GIS]未绑定viewerGIS！');

      let viewType = this.viewFilter[this.viewFilterIndex].id;
      let name = await this.$prompt('请输入视点名称：', '提示').catch(() => {});
      if(!name) return;

      const createItem = async () => {
        eventBus.$emit('ViewerGIS.getViewPointState', this.bindViewerId, async (opt) => {
          opt.info.model = this.modelStatusList;
          opt.info.marker = this.markerStatusList;
          opt.info.plot = this.plotStatusList;
          opt.info.layer = this.layerStatusList;

          const res = await dataInterface({
            __method_name__: 'createData',
            object_uuid: DataBaseInfo.ViewPoint.object_uuid,
            view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
            name: name.value,
            group_id: group.id.split('_')[1],
            img: opt.img,
            state: JSON.stringify(opt.info),
            isDefault: 'false'
          });

          if (res.status !== 200) return;

          const id = res.data.data.id;
          const created_at = res.data.data.created_at;
          let item = {
            id: `child_${id}`,
            type: 'Child',
            name: name.value,
            tooltip: '',
            group_id: group.id.split('_')[1],
            img: opt.img,
            state: opt.info,
            created_at: created_at,
            isDefault: false
          };
          group.children.push(item);

          this.$message.success('[GIS]视点创建成功！');
        });
      };

      await createItem();
    },
    /**
     * @description: 视点设为默认
     * @param {Object} data 数据对象
     */
    async onDefaultViewPoint(data){
      let viewType = this.viewFilter[this.viewFilterIndex].id;

      let defaultItem = this.$refs.viewPointTree.getDefaultItem();
      if(defaultItem){
        await dataInterface({
          __method_name__: 'updateData',
          object_uuid: DataBaseInfo.ViewPoint.object_uuid,
          view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
          data_id: defaultItem.id.split('_')[1],
          isDefault: 'false'
        });

        this.$refs.viewPointTree.updateTreeData(defaultItem.id, 'isDefault', false, `group_${defaultItem.group_id}`);
      }

      await dataInterface({
        __method_name__: 'updateData',
        object_uuid: DataBaseInfo.ViewPoint.object_uuid,
        view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
        data_id: data.id.split('_')[1],
        isDefault: 'true'
      });
      this.$refs.viewPointTree.updateTreeData(data.id, 'isDefault', true, `group_${data.group_id}`);
      this.$message.success(`视点对象设为默认视点成功！`);
    },
    /**
     * @description: 视点取消设为默认
     * @param {Object} data 数据对象
     */
    async onUndefaultViewPoint(data){
      let viewType = this.viewFilter[this.viewFilterIndex].id;

      await dataInterface({
        __method_name__: 'updateData',
        object_uuid: DataBaseInfo.ViewPoint.object_uuid,
        view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
        data_id: data.id.split('_')[1],
        isDefault: 'false'
      });

      this.$refs.viewPointTree.updateTreeData(data.id, 'isDefault', false, `group_${data.group_id}`);
      this.$message.success(`视点对象取消设为默认视点成功！`);
    },
    /**
     * @description: 视点复现
     * @param {Object} data 数据对象
     */
    onSetViewPoint(data){
      eventBus.$emit('ViewerGIS.setViewPointStateOnlyBase', this.bindViewerId, data.state);

      let {model, marker, plot, layer} = data.state;
      if(model) {
        if(layer && layer.model) model = layer.model;
        eventBus.$emit('ViewerGISModelTree.onModelShow', this.bindModelId, model);
      }

      if(marker) {
        eventBus.$emit('ViewerGISMarker.onMarkerShow', this.bindMarkerId, marker);
      }

      if(plot) {
        eventBus.$emit('ViewerGISPlot.onPlotShow', this.bindPlotId, plot);
      }

      if(layer){
        eventBus.$emit('ViewerGISLayer.onGISShow', this.bindLayerId, layer?.gis);
        eventBus.$emit('ViewerGISLayer.onModelShow', this.bindLayerId, layer?.model);
      }
    },
    /**
     * @description: 重命名视点
     * @param {Object} data 数据对象
     */
    async onRenameViewPoint(data){
      let name = await this.$prompt('请输入视点名称：', '提示', {
        inputValue: data.name
      }).catch(() => {});
      if(!name) return;

      let viewType = this.viewFilter[this.viewFilterIndex].id;
      await dataInterface({
        __method_name__: 'updateData',
        object_uuid: DataBaseInfo.ViewPoint.object_uuid,
        view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
        data_id: data.id.split('_')[1],
        name: name.value
      });

      this.$refs.viewPointTree.updateTreeData(data.id, 'name', name.value, `group_${data.group_id}`);
      this.$message.success(`视点对象重命名成功！`);
    },
    /**
     * @description: 更新视点
     * @param {Object} data 数据对象
     */
    async onRefreshViewPoint(data){
      let viewType = this.viewFilter[this.viewFilterIndex].id;

      const updateItem = async () => {
        eventBus.$emit('ViewerGIS.getViewPointState', this.bindViewerId, async (res) => {
          res.info.model = this.modelStatusList;
          res.info.marker = this.markerStatusList;
          res.info.plot = this.plotStatusList;
          res.info.layer = this.layerStatusList;

          await dataInterface({
            __method_name__: 'updateData',
            object_uuid: DataBaseInfo.ViewPoint.object_uuid,
            view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
            data_id: data.id.split('_')[1],
            img: res.img,
            state: JSON.stringify(res.info)
          });

          this.$refs.viewPointTree.updateTreeData(data.id, 'img', res.img, `group_${data.group_id}`);
          this.$refs.viewPointTree.updateTreeData(data.id, 'state', JSON.parse(JSON.stringify(res.info)), `group_${data.group_id}`);

          this.$message.success('视点更新成功！');
        });
      };

      await updateItem();
    },
    /**
     * @description: 删除视点
     * @param {Object} data 数据对象
     */
    async onDeleteViewPoint(data){
      let viewType = this.viewFilter[this.viewFilterIndex].id;

      await dataInterface({
        __method_name__: 'deleteData',
        object_uuid: DataBaseInfo.ViewPoint.object_uuid,
        view_uuid: DataBaseInfo.ViewPoint.view_uuid[viewType],
        data_id: data.id.split('_')[1]
      });

      this.$refs.viewPointTree.deleteTreeData(data.id, `group_${data.group_id}`);
      this.$message.success('视点删除成功！');
    }
  }
};
</script>

<style lang="less" scoped>
  .moduleWrap {
    width: 100%;
    height: 100%;
  }
</style>
