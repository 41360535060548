
<!--
 * @Description: 走马灯/swiper
 * @Author: zyf
 * @Date: 2023-08-23 15:12:22
 * @LastEditors: zyf
 * @LastEditTime: 2024-01-02 10:56:40
-->
<template>
  <div class="new-common-swiper-box">
    <div v-if="swiperList.length" class="new-common-swiper" :style="`flex-direction: ${isRow ? 'column' : 'row'}!important;`" @mouseover="overStop" @mouseout="outStart">
      <div class="swiper-box" :class="[`${statusConfig.arrow}-class`,getClassWithType]" >
        <!-- 图片 -->
        <div ref="carousel" class="swiper-content-box" :style="{transition: transitionStyle,transform:transformStyle,flexDirection:`${isRow ? 'row' : 'column'}`}" >
          <template v-if="swiperImgList.length">
            <div v-for="(item,index) in swiperImgList" :title="item.index" :style="{transition: transitionStyle}"  :key="index" :class="{
              'row-swiper':isRow,
              'column-swiper':!isRow,
              'show-row-swiper':showImgIndex==index && isRow,
              'show-column-swiper':showImgIndex==index && !isRow,
              'left-row-swiper':showImgIndex - 1 == index && isRow,
              'left-column-swiper':showImgIndex - 1 == index && !isRow,
              'right-row-swiper':showImgIndex + 1 == index && isRow,
              'right-column-swiper':showImgIndex + 1 == index && !isRow
              }">
              <template>
                  <template v-if="Array.isArray(item)">
                    <div class="swiper-wrap" :style="swiperWidthStyle">
                      <div class="swiper-item" v-for="(el, sIndex) in item" :key="sIndex">
                        <img v-if="!statusConfig.preview" :src="el.img || defaultImg" :style="imgStyle" :alt="el.text || ''" @click="onItem(el, index)">
                          <el-image
                            v-else
                            style="height: 100%"
                            :src="el.img || defaultImg"
                            :preview-src-list="[el.img || defaultImg]"
                          >
                          </el-image>
                          <p class="desc" v-if="statusConfig.showText && el.text" @click="onItem(el, index)">{{ el.text || ''}}</p>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="swiper-wrap" :style="swiperWidthStyle">
                      <img v-if="!statusConfig.preview" :style="imgStyle" :src="item.img || defaultImg" :alt="item.text || ''" @click="onItem(item, index)">
                      <el-image
                          v-else
                          :fit="statusConfig.objectFit"
                          :style="imgStyle"
                          style="height: 100%"
                          :src="item.img || defaultImg"
                          :preview-src-list="[item.img || defaultImg]"
                        >
                        </el-image>
                        <p class="desc" v-if="statusConfig.showText && item.text" @click="onItem(item, index)">{{ item.text || ''}}</p>
                    </div>
                  </template>
              </template>
            </div>
          </template>
        </div>
        <!-- 左箭头按钮 -->
        <div v-show="isRow && isNextAndPre" class="left-btn" @click="throttle(prevFun)">
          <i class="iconfont iconc-chevron-left"></i>
        </div>
        <!-- 右箭头按钮 -->
        <div v-show="isRow && isNextAndPre" class="right-btn" @click="throttle(nextFun)">
          <i class="iconfont iconxiangyoujiantou2"></i>
        </div>
        <!-- 里面指示点容器 -->
        <div v-if="!statusConfig.indicatorPosition && swiperList.length" :class="[isRow ? 'row-inst-box' : 'column-inst-box',isRow ? 'row-in-site' : 'column-in-site']">
          <div v-for="(item,index) in swiperList" :key="index"
          @click="isClickTrigger && onSwitchSwiper(index+ initIndex)" @mouseover="!isClickTrigger && onSwitchSwiper(index+ 1)" class="inst" :class="{'in-inst-active':showImgIndex==index + initIndex && !statusConfig.indicatorPosition}">
          </div>
        </div>
      </div>
      <!-- 外面指示点容器 -->
      <div :class="isRow ? 'row-box' : 'column-box'">
        <div v-if="statusConfig.indicatorPosition === 'outside' && swiperList.length" :class="[isRow ? 'row-inst-box' : 'column-inst-box',isRow ? 'row-out-site' : 'column-out-site']">
          <div v-for="(item,index) in swiperList" :key="index"
          @click="isClickTrigger && onSwitchSwiper(index + initIndex)" @mouseover="!isClickTrigger && onSwitchSwiper(index+ 1)" class="inst" :class="{'out-inst-active':showImgIndex==index + initIndex && statusConfig.indicatorPosition === 'outside'}">
          </div>
        </div>
      </div>
    </div>
    <el-empty
      v-else
      description="暂无数据"
    ></el-empty>
    <div v-if="statusConfig.numberIndicator && swiperList.length" class="number-indicator">
      <span>{{ initialIndex  }}/{{ swiperList.length }}</span>
    </div>
  </div>
</template>

<script>
import { Image,Empty } from 'element-ui'

export default {
  name: 'ConstomSwiper',
  components: {
    'el-image': Image,
    'el-empty': Empty,
  },
  props: {
    // 组件状态配置
    statusConfig:{ 
      type: Object,
      default: () => { },
      required: true,
    },
    // 轮播图数组
    swiperList: {
      type: Array,
      default: () => [],
      required: true,
    },
    height: {
      type: String,
      default: '100%',
    }
  },
  data() {
    return {
      defaultImg: require('@/assets/images/defaultImage.png'),
      showImgIndex: 0,  // 表示当前显示的图片
      flag:true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
      totalWidth: 0,// 轮播总宽度
      totalHeight:0,// 轮播总高度
      slideWidth: 0, // 滑动项宽度
      slideHeight: 0, // 滑动项高度
      translateValue: 0, // 滑动距离

      swiperListCopy: this.swiperList,
      swiperImgList: this.swiperList,
      initIndex: 0,// 画廊初始移动位置
      addNumber: 0,// 为了循环前后手动添加个数
      transformType: 'next', // 滑动类型
      initialIndex: 0,// 轮播索引
      isInit:true
    }
  },
  computed: {
    /**
     * @description: 根据轮播图类型得到唯一的class,实现改类型的样式
     * @return {*}
     */
    getClassWithType () { 
      if (!this.statusConfig.type) { 
        return 'default-swiper-box';
      }
      return `${this.statusConfig.type}-swiper-box`;
    },
    /**
     * @description: 图片样式
     * @return {*}
     */
		imgStyle() {
			const { objectFit } = this.statusConfig;
      if (objectFit) {
        return { 'object-fit': objectFit };
      }
      const editorType = sessionStorage.getItem('editorType');
      if (editorType === 'dataview' || editorType === 'console') {
        return { 'object-fit': 'fill' };
      }
      return { 'object-fit': 'cover' };
    },
    /**
     * @description: 图片样式
     * @return {*}
     */
		transformStyle() {
      if (this.isRow) {
        return `translateX(${this.translateValue}px)`
      } else { 
        return `translateY(${this.translateValue}px)`
      }
    },
    // 过度时间
    transitionStyle () { 
      if (this.isInit) { 
        return `none`;
      }
      const { switchTime } = this.statusConfig;
      let time = 1 
      if (switchTime) {
        time = switchTime
      }
      return `all ${time}s ease`;
    },
    // 是否是水平显示
    isRow () { 
      const { direction } = this.statusConfig
      if (direction === 'horizontal') { 
        return true
      }
      return false
    },
    // 轮播宽度
    swiperWidthStyle () { 
      const { swiperWidth,type } = this.statusConfig;
      if (type !== 'gallery' || !swiperWidth) { 
        return { height : '100%', width:'100%'}
      }
      if (this.isRow) {
        return { width: swiperWidth + 'px' }
      } else { 
        return { height: swiperWidth + 'px' }
      }
    },
    // 指示器是否点击触发方式
    isClickTrigger () { 
      const { trigger } = this.statusConfig;
      if (trigger) { 
        return true
      }
      return false
    },
    // 是否可以上一张或下一张
    isNextAndPre () { 
      const { type } = this.statusConfig
      if ((type === 'fade' || !type) && this.swiperList.length > 1 ) { 
        return true
      }
      if (type === 'card' && this.swiperList.length > 2 ) { 
        return true
      }
      if (type === 'gallery') {
        if (this.isRow && this.totalWidth < this.slideWidth * this.swiperList.length) {
          return true
        }
        if (!this.isRow && this.totalHeight < this.slideHeight * this.swiperList.length) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    statusConfig:{ 
      handler () { 
        this.swiperImgList = []
        this.getSlideData()
      },
      deep: true
    },
    swiperList:{ 
      handler () {
        this.swiperImgList = []
        this.getSlideData()
      },
      deep: true
    }
  },
  mounted () {
    this.getSlideData()
    // 自动播放
    if (this.statusConfig.autoplay) { 
      this.onLoop()
    } 
  },
  methods: {
    initInitialIndex () { 
      let num = this.statusConfig.initialIndex || 0;
      this.initialIndex = num + 1;
    },
    /**
     * @description: 获取轮播图数据
     * @return {*}
     */
    getSlideData () { 
      this.addNumber = 0
      this.initInitialIndex()
      if (this.swiperList.length === 0) { 
        this.swiperImgList = []
        this.swiperListCopy = []
        return
      }
      this.swiperImgList = JSON.parse(JSON.stringify(this.swiperList))
      this.swiperListCopy = JSON.parse(JSON.stringify(this.swiperList))
      let wraperDiv = document.querySelector('.swiper-content-box') 
      this.$nextTick(() => { 
        this.totalWidth = this.$refs.carousel?.offsetWidth
        this.totalHeight = this.$refs.carousel?.offsetHeight
        this.slideWidth = this.$refs.carousel.children[0]?.offsetWidth
        this.slideHeight = this.$refs.carousel.children[0]?.offsetHeight
        // 默认轮播
        if (this.isNextAndPre && (!this.statusConfig.type || this.statusConfig.type === 'fade')) { 
          this.swiperImgList.push(this.swiperListCopy[0])
          this.swiperImgList.unshift(this.swiperListCopy[this.swiperListCopy.length - 1])
          this.addNumber = 1
        }
         // 卡片轮播
         if (this.statusConfig.type === 'card') { 
          this.swiperImgList.push(this.swiperListCopy[0])
          this.swiperImgList.push(this.swiperListCopy[1])
          this.swiperImgList.unshift(this.swiperListCopy[this.swiperListCopy.length - 1])
          this.swiperImgList.unshift(this.swiperListCopy[this.swiperListCopy.length - 2])
          this.addNumber = 2
        }
        // 画廊轮播
        if (this.isNextAndPre && this.statusConfig.type === 'gallery') { 
          const addArrPre = this.swiperListCopy;
          const addArrnext = this.swiperListCopy;
          const newLeftArr = this.swiperImgList.concat(addArrnext);
          const newArr = addArrPre.concat(newLeftArr)
          this.swiperImgList = newArr
          this.addNumber = this.swiperListCopy.length
        }
        // 为了轮播循环，手动前后添加数据，根据添加了多少数据，起始位置加几
        this.showImgIndex = parseInt(this.statusConfig.initialIndex) + parseInt(this.addNumber)
        this.initIndex = parseInt(this.statusConfig.initialIndex) + parseInt(this.addNumber)
        // 初始移动位置
        // 默认轮播、卡片轮播 画廊轮播
        if (this.statusConfig.type === 'card' || this.statusConfig.type === 'gallery' || !this.statusConfig.type) { 
          const slideNum = this.isRow ? -this.slideWidth : -this.slideHeight
          this.getTranslateValue(slideNum * this.initIndex)
          
          if (wraperDiv) { 
            wraperDiv.style.transition = this.transitionStyle
          }
        }
        // 渐变轮播
        if (this.statusConfig.type === 'fade') { 
          this.translateValue = 0
        }
      })
      
      this.$nextTick(() => { 
        setTimeout(() => { 
          this.isInit = false;
        },0)
      })
    },
    /**
     * @description: 自动切换
     * @return {*}
     */
    onLoop(){
        this.start = setInterval(()=>{
        this.nextFun()
      },Number(this.statusConfig.interval))
    },

    /**
     * @description: 通过额外封装的节流函数触发 prevFun() 和 nextFun(),以达到防止重复点击的效果
     * @param {*} fun 传入的prevFun()或nextFun()函数
     * @return {*}
     */
    throttle(fun) {
      if (this.flag) {
        this.flag = false;
        fun(); // 此为模板中传递进来的prevFun()或nextFun()函数
        setTimeout(() => {
          this.flag = true;
        }, 1000); // 节流间隔时间
      }
    },
    /**
     * @description: 上一张
     * @return {*}
     */
    prevFun () {
      this.showImgIndex--
      if (+this.initialIndex > 1) {
        this.initialIndex--
      } else { 
        this.initialIndex = this.swiperList.length
      }
      if (!this.statusConfig.type || this.statusConfig.type === 'gallery' ) { 
        this.getTranslateValue()
      }
      // 处理循环移动显示
      if (this.statusConfig.loop && this.showImgIndex === this.initIndex - 1) { 
        // 默认轮播、画廊轮播
        if (this.statusConfig.type === 'gallery' || !this.statusConfig.type) { 
          let slideNum = this.isRow ? -this.slideWidth : -this.slideHeight
          const index = (this.swiperListCopy.length - 1) + this.initIndex
          const transform = slideNum * ((this.swiperListCopy.length - 1) + this.initIndex)
          this.handleLoopMove(index, transform)
         
        }
        // 渐变
        if (this.statusConfig.type === 'fade') { 
          this.showImgIndex = this.swiperListCopy.length
        }

        // 卡片轮播
        if (this.statusConfig.type === 'card') { 
          this.handleLoopMoveCard(this.initIndex + (this.swiperListCopy.length - 1),'pre')
        }
      }
    },
    /**
     * @description: 下一张
     * @return {*}
     */
    nextFun () {
      this.showImgIndex++
      if (this.initialIndex === this.swiperList.length) {
        this.initInitialIndex()
      } else { 
        this.initialIndex++
      }
      // 默认轮播 画廊轮播
      if (!this.statusConfig.type || this.statusConfig.type === 'gallery') {
        // 获取移动距离
        this.getTranslateValue()
      }
      // 卡片轮播
      if (this.statusConfig.type === 'card') {
        this.getTranslateValue()
      }

      // 处理循环移动显示
      if (this.statusConfig.loop && this.showImgIndex === this.swiperListCopy.length + this.initIndex) { 
        // 默认轮播、画廊轮播
        if (this.statusConfig.type === 'gallery' || !this.statusConfig.type) { 
          let slideNum = this.isRow ? -this.slideWidth : -this.slideHeight
          this.handleLoopMove(this.initIndex, slideNum * this.initIndex)
        }

        // 渐变轮播
        if (this.statusConfig.type === 'fade') { 
          this.showImgIndex = this.initIndex
        }

        // 卡片轮播
        if (this.statusConfig.type === 'card') { 
          this.handleLoopMoveCard(this.initIndex,'next')
        }
        
      }
    },

    /**
     * @description: 循环移动
     * @param {*} index 图片索引
     * @param {*} transform 移动位置
     * @return {*}
     */
    handleLoopMove (index, transform) { 
      let wraperDiv = document.querySelector('.swiper-content-box')
      const reset = () => {
        wraperDiv.style.transition = 'none';
        this.showImgIndex = index;
        this.getTranslateValue(transform)
        setTimeout(()=>{
          wraperDiv.style.transition = this.transitionStyle
        }, 16)

        wraperDiv.removeEventListener('transitionend', reset)

      }
      wraperDiv.addEventListener('transitionend', reset)
    },

    /**
     * @description: 卡片轮播循环处理
     * @param {*} index 当前展示索引
     * @return {*}
     */
    handleLoopMoveCard (index, type) { 
      console.log(type);
      const items = document.querySelectorAll('.row-swiper')
      let parentNode = items[0].parentNode;
      const reset = () => {
        Array.from(items).forEach(item=>{
          item.style.transition = 'none'
        })
        this.showImgIndex = index;
        setTimeout(()=>{
          Array.from(items).forEach(item => {
            item.style.transition = this.transitionStyle
          })
        }, 16)

        parentNode.removeEventListener('transitionend', reset)

      }
      parentNode.addEventListener('transitionend', reset)
    },
    /**
     * @desc: 点击
     * @param {Object} item
     * @param {Number} index
     */
    onItem (item, index) {
      this.$emit('onItem',item, index)
    },
    /**
     * @description: 指示器触发
     * @param {*} index
     * @return {*}
     */
    onSwitchSwiper (index) { 
      this.showImgIndex = index
      if (this.statusConfig.type !== 'fade') { 
        this.getTranslateValue()
      }
    },
    /**
     * @description: 获取移动距离
     * @param {*} num 传入的移动距离，不传入的话默认通过滑片宽度* 移动的索引
     * @return {*}
     */
    getTranslateValue (num) { 
      if (this.statusConfig.type === 'fade') {
        return
      }
      if (num) {
        this.translateValue = num
      } else { 
        let slideNum = this.isRow ? -this.slideWidth : -this.slideHeight
        this.translateValue = slideNum * this.showImgIndex
      }

    },

    /**
     * @description: 鼠标停留停止
     * @return {*}
     */
    overStop () {
      clearInterval(this.start)
    },

    /**
     * @description: 鼠标离开重新滑动
     * @return {*}
     */
    outStart () {
      // 自动播放
      if (this.statusConfig.autoplay) { 
        this.onLoop()
      }  
    },
  },
}
</script>

<style lang="less" scoped>
  .new-common-swiper-box{
    width: 100%;
    position: relative;
    .new-common-swiper{
      width: 100%;
      height: 100%;
      display: flex;
      /* 轮播内容公共样式 */
      .swiper-box {
        flex: 1;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        cursor: pointer;
        .swiper-content-box{
          width: 100%;
          height: 100%;
          img,.el-image{
            display: block;
            margin: 0 auto;
            height: 100%;
            width: 100%;
          }

          /* 图片默认样式 */
          .swiper-wrap{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .desc{
              position: absolute;
              bottom: 0;
              left: 0;
              height: 20px;
              width: 100%;
              background: rgba(0, 0, 0, 0.6);
              font-size: 14px;
              line-height: 20px;
              box-sizing: border-box;
              padding: 0 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: left;
              color: #fff;
            }
            .swiper-item{
              flex-grow: 1;
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 4px;
              box-sizing: border-box;
            }
          }
        }

        .left-btn,
        .right-btn {
          display: none;
          position: absolute;
          top: 50%;
          transition: .3s;
          transform: translateY(-50%);
          width: 36px;
          height: 36px;
          text-align: center;
          line-height: 36px;
          background-color: rgba(31,45,61,.11);
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
          z-index: 300;
          .iconfont{
            color: #fff;
            font-size: 12px;
            
          }

        }
        .left-btn {
          left: 16px;
        }
        .right-btn {
          right: 16px;
        }
      }
      /* 切换按钮显示样式 */
      .hover-class{
        .left-btn,.right-btn{
          display: none;
        }
        &:hover{
          .left-btn,.right-btn{
            display: block;
          }
        }
      }
      .always-class{
        .left-btn,.right-btn{
          display: block;
        }
      }
      .never-class{
        .left-btn,.right-btn{
          display: none;
        }
      }
      /* 切换按钮显示样式结束 */
      /* 渐变轮播样式 */
      .fade-swiper-box{
        .swiper-content-box{
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          transform: none !important;
        }
        .row-swiper,.column-swiper {
          width: 100%;
          height: 100%;
          flex-grow: 1;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: relative;

          position: absolute;
          left: 0px;
          top: 0px;
          object-fit: cover; /* 保持原有尺寸比例。内容被缩放。 */
          width: 100%;
          height: 100%; 
          opacity: 0;

          .desc{
            position: absolute;
            bottom: 0;
            left: 0;
            height: 20px;
            width: 100%;
            background: rgba(0, 0, 0, .6);
            font-size: 14px;
            line-height: 20px;
            box-sizing: border-box;
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
          }

        }
        /* 图片选中样式(继承上方默认样式) */
        .show-row-swiper,.show-column-swiper {
          opacity: 1;
        }
      }
      /* 默认轮播样式 */
      .default-swiper-box{
        width: 100%;
        display: flex;
        .swiper-content-box{
          display: flex;
        }
        .row-swiper,.column-swiper{
          width: 100%;
          height: 100%;
          flex-grow: 1;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          transition: none !important;
        }
      }
      /* 画廊轮播样式 */
      .gallery-swiper-box{
        width: 100%;
        display: flex;
        .swiper-content-box{
          display: flex;
          .row-swiper,.column-swiper{
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: none !important;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .row-swiper{
            padding: 0 4px;
            height: 100%;
          }
          .column-swiper{
            padding: 4px 0;
            width: 100%;
          }
        } 
      }
      /* 卡片轮播样式 */
      .card-swiper-box{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .swiper-content-box{
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          transform: none !important;
        }
        .row-swiper,.column-swiper{
          display: block;
          position: absolute;
          z-index: 1;
          opacity: 0;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .row-swiper{
          left: 50%;
          top: 0;
          width: 70%;
          height:100%;
          transform: translateX(50%) scale(0.83);
        }
        .column-swiper{
          top: 50%;
          left: 0;
          width: 100%;
          height:70%;
          transform: translateY(50%) scale(0.83);
        }
        .show-row-swiper,.show-column-swiper{
          display: block;
          position: absolute;
          z-index: 3;
          opacity: 1;
        }
        .show-row-swiper{
          left: 50%;
          top: 0;
          transform: translateX(-50%) scale(1);
        }
        .show-column-swiper{
          left: 0;
          top: 50%;
          transform: translateY(-50%) scale(1);
        }
        .left-row-swiper,.left-column-swiper{
          display: block;
          position: absolute;
          z-index: 2;
          opacity: 1;
        }
        .left-row-swiper{
          left: 0;
          top: 0;
          transform: translateX(-50%) scale(0.83);
        }
        .left-column-swiper{
          left: 0;
          top: 0;
          transform: translateY(-50%) scale(0.83);
        }
        .right-row-swiper,.right-column-swiper{
          display: block;
          position: absolute;
          z-index: 2;
          opacity: 1;
        }
        .right-row-swiper{
          right: 0;
          top: 0;
          left: unset;
          transform: translateX(50%) scale(0.83);
        }
        .right-column-swiper{
          right: 0;
          top: 0;
          bottom: unset;
          transform: translateY(50%) scale(0.83);
        }
      }
      /* 指示器开始 */
      .row-box{
        width: 100%;
      }
      .column-box{
        height: 100%;
      }
      .row-box,.column-box{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .row-inst-box,.column-inst-box{
        display: flex;
        .inst{
          background: rgb(206, 206, 207);
          cursor: pointer;
        }
        /* 小圆点 */
        .in-inst-active{
          background: #fff;
        }
        .out-inst-active{
          background: rgb(125, 125, 126);
        }
      }
      .row-inst-box{
        max-width: 60%;
        .inst{
          width: 30px;
          height: 2px;
          margin-right: 8px;
        }
        .inst:last-child{
          margin-right: 0px;
        }
      }
      .column-inst-box{
        max-height: 60%;
        flex-direction: column;
        .inst{
          width: 2px;
          height: 30px;
          margin-bottom: 8px;
        }
        .inst:last-child{
          margin-bottom: 0px;
        }
      }
      .row-in-site{
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        bottom: 10px;
        z-index: 99;
      }
      .row-out-site{
        width: 100%;
        height: 20px;
        justify-content: center;
        align-items: center;
        z-index: 99;
      }
      .column-in-site{
        position: absolute;
        z-index: 99;
        top: 50%;
        right: 10px;
        transform: translate(-50%, -50%);
      }
      .column-out-site{
        height: 100%;
        width: 20px;
        justify-content: center;
        align-items: center;
        z-index: 99;
      }
      /* 指示器结束 */
    }
    .number-indicator{
      width: 45px;
      height: 22px;
      padding: 2px 12px 2px 12px;
      line-height: 22px;
      border-radius: 24px;
      background: #161C1FA3;
      // opacity: 0.64;
      position: absolute;
      right: 16px;
      top: 12px;
      span{
        font-family: PingFang SC;
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #fff;

      }
    }
  }
</style>