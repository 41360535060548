<!--
    @name: ques-radio
    @description：ques-radio
    @author: ZengWei
    @date: 2022-04-01 11:17
-->
<template>
  <div class="widget-radio">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="ob-ques-type">
        单选题
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>

    <div class="q-option-list">
      <div class="q-option-ul constom-ques-radio">
        <el-radio-group v-model="element.value" :disabled="element.disabled" @change="calcScore">
          <el-radio
            :class="columnClass"
            v-for="(option,index) in element.config.__slot__.options"
            :key="index"
            :label="option.value+''"
          >
            {{optNumber[index]}} 、{{ option.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import {Radio,RadioGroup} from "element-ui";
import quesWidgetMinix from "@/components/answer/quesParser/scripts/quesWidgetMinix.js";

export default {
  name: "ques-radio",
  components: {
    'el-radio-group':RadioGroup,
    'el-radio':Radio,
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  computed:{},
  data() {
    return {

    }
  },
  methods: {
    // 计算分值
    calcScore(value){
      const config = this.element.config.__ques__
      const options = this.element.config.__slot__.options
      let score = 0
      if(config.scoreType === 1){
        for (const opt of options){
          if(opt.right && value == opt.value){
            score = config.score
          }
        }
      } else if (config.scoreType === 2){
        for (const opt of options){
          if(value == opt.value){
            score = opt.score
          }
        }
      }
      this.element.score = score
      this.$emit('change')
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
</style>
