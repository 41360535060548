<!--
 * @Description: tabs
 * @Author: luocheng
 * @Date: 2021-10-08 17:11:12
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-07-16 11:22:55
-->
<template>
  <div
    class="common-tabs"
    v-loading="loading"
    :style="{
      '--tabs-fontsize': fontSize
    }"
  >
    <el-tabs
      v-model="activeName"
      @tab-click="onTabClick"
      :type="statusConfig.type"
      :tab-position="statusConfig.tabPosition || 'top'"
      :class="[statusConfig.type]"
    >
      <!-- 固定参数 -->
      <template v-if="tabOrigin === 'fixed'">
          <!-- :label="$getLocalizedLabel({'zhLabel':item?.label, 'enLabel': item?.enLabel})" -->
        <el-tab-pane
          :label="item.label"
          :name="item.type"
          v-for="(item, index) in tabConfig"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span :style="{ color: activeName === item.type ? activeColor : defaultColor }">
              <!-- {{ $getLocalizedLabel({'zhLabel':item?.label, 'enLabel': item?.enLabel}) }} -->
              {{ item.label }}
            </span>
          </template>
        </el-tab-pane>
      </template>
      <!-- 字典 -->
      <template v-if="tabOrigin === 'dictionary'">
        <el-tab-pane
          :label="item.value"
          :name="item.code"
          v-for="(item, index) in dictionaryList"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span :style="{ color: activeName === item.code ? activeColor : defaultColor }">
              {{ item.value }}
            </span>
          </template>
        </el-tab-pane>
      </template>
      <!-- 动态数据 -->
      <template v-if="tabOrigin === 'dynamic'">
        <el-tab-pane
          :label="item[dynamicConfig.labelKey]"
          :name="item[dynamicConfig.valueKey].toString()"
          v-for="(item, index) in dynamicTabs"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span :style="{
              color: activeName === item[dynamicConfig.valueKey].toString() ? activeColor : defaultColor
            }">
              {{ item[dynamicConfig.labelKey] }}
            </span>
          </template>
        </el-tab-pane>
      </template>
      <!-- 常规接口 -->
      <template v-if="tabOrigin === 'interface'">
        <el-tab-pane
          :label="item[interfaceConfig.labelKey].toString()"
          :name="item[interfaceConfig.valueKey].toString()"
          v-for="(item, index) in interfaceTabs"
          :key="index"
        >
          <template
            v-if="useSolt"
            #label
          >
            <span :style="{
              color: activeName === item[interfaceConfig.valueKey].toString() ? activeColor : defaultColor
            }">
              {{ item[interfaceConfig.labelKey] }}
            </span>
          </template>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import { TabPane, Tabs } from 'element-ui';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { initParams, removeEventBus, getComponentById, getTargetArchi, getUserInfo } from '@/utils/tools';
import { mapState } from 'vuex';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

export default {
  name: 'CommonTabs',
  props: {
    element: {
      type: Object,
      required: false,
      default: () => {}
    },
    componentList: {
      default: null
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  mixins: [databaseTriggerDebug],
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      activeName: '',
      // 字典配置的列表
      dictionaryList: [],
      // 动态数据
      loading: false,
      dynamicTabs: [],
      // 常规接口数据
      interfaceTabs: [],
      // 固定参数
      fixedInit: false,
      oldDictionaryConfig: ''
    };
  },
  computed: {
    ...mapState(['componentData', 'subsidiaryComponentData', '_PageCustomStatus']),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
    // 增加全部选项
    tabConfig() {
      const result = this.element?.tabConfig || [];
      if (Array.isArray(result) && this.element?.statusConfig?.useAll) {
        if (!result.find(ele => ele?.type === '0' && ele?.label === '全部')) {
          result.unshift({
            // label: this.$getLocalizedLabel({'zhLabel': '全部', 'enLabel': 'all'}),
            label: '全部',
            type: '0'
          });
        }
      }
      return result;
    },
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    },
    // 参数类型
    tabOrigin() {
      return (this.element && this.element.tabOrigin) || 'fixed';
    },
    // 字典配置
    dictionaryConfig() {
      return this.element && this.element.dictionaryConfig;
    },
    // 动态数据
    dynamicConfig() {
      return this.element && this.element.dynamicConfig;
    },
    // 接口配置参数
    interfaceConfig() {
      return this.element && this.element.interfaceConfig;
    },
    useSolt() {
      return this.element && this.element.statusConfig.useSolt;
    },
    defaultColor() {
      return this.element && this.element.statusConfig.defaultColor;
    },
    activeColor() {
      return this.element && this.element.statusConfig.activeColor;
    },
    // 首选项类型
    tabPreferenceType() {
      return this.element?.tabPreferenceType ?? ''
    },
    // 首选项配置
    tabPreferenceTypeConfig() {
      return this.element?.tabPreferenceTypeConfig ?? {}
    },
    // 需要穿透的fontSize
    fontSize() {
      let fontSize = isNaN(Number(this.statusConfig.fontSize)) ? this.statusConfig.fontSize : this.statusConfig.fontSize + 'px';
      if(!(/^\d+[(px)|(em)|(rem)]+$/i.test(fontSize))) {
        fontSize = '14px'
      }
      return fontSize;
    }
  },
  watch: {
    // 固定参数
    tabConfig: {
      handler() {
        if (this.tabOrigin !== 'fixed' || !this.tabConfig?.length) {
          return;
        }
        if(this.tabPreferenceType && !this.fixedInit) {
          this.fixedInit = true;
          this.initActiveTab()
        }
        for (let i = 0; i < this.tabConfig.length; i++) {
          if (this.tabConfig[i].isFirst && this.activeName === '') {
            this.activeName = this.tabConfig[i].type;
            if (!this.fixedInit) {
              this.fixedInit = true;
              this.onTabClick({ index: i });
            }
            return;
          }
        }
        // test 此处代码仍有待考证
        if (!this.activeName) {
          this.activeName = this.tabConfig[0].type;
        }
      },
      deep: true,
      immediate: true
    },
    // 字典
    dictionaryConfig: {
      handler() {
        if (
          this.tabOrigin !== 'dictionary' ||
          !this.dictionaryConfig ||
          this.oldDictionaryConfig === this.dictionaryConfig
        )
          return;
        this.oldDictionaryConfig = this.dictionaryConfig;
        this.getDictionaryList();
      },
      deep: true,
      immediate: true
    },
    // tab数据来源
    tabOrigin: {
      handler(newv, oldv) {
        // 动态数据
        if (newv !== oldv && this.tabOrigin === 'dynamic') {
          this.getTabList(this.dynamicConfig);
        } else if(newv !== oldv && this.tabOrigin === 'interface') {
          this.getTabListByInterface(this.interfaceConfig)
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: data => {
        if (this.tabOrigin !== 'dynamic' && this.tabOrigin !== 'interface') return;
        if(this.tabOrigin === 'dynamic') {
          let { paramsConfig } = this.element.database;
          let isTarget = false;
          for (let i = 0; i < paramsConfig.length; i++) {
            if (paramsConfig[i].componentId === data.componentId) {
              isTarget = true;
            }
          }
          if (!isTarget && !data.isInit && !data.isUpdate) return;
          this._startDataDebug();
          this.getTabList(this.dynamicConfig);
        } else if(this.tabOrigin === 'interface') {
          let { paramsConfig } = this.element.database;
          let isTarget = false;
          for (let i = 0; i < paramsConfig.length; i++) {
            if (paramsConfig[i].componentId === data.componentId) {
              isTarget = true;
            }
          }
          if (!isTarget && !data.isInit && !data.isUpdate) return;
          this._startDataDebug();
          this.getTabListByInterface(this.interfaceConfig);
        }
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  methods: {
    /**
     * @description: 被点击时触发回调
     * @param {Object} tab
     */
    onTabClick(tab) {
      if (this.tabOrigin === 'fixed') {
        // 固定
        const index = +tab.index;
        const comEvents = this.tabConfig[index]?.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
          if (pattern === undefined) {
            if (comEvents[i].key === 'click') {
              comEvents[i].effects.forEach(effect => {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...comEvents[i],
                    ...effect
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID
                });
              });
            }
            break;
          }
          const result = pattern === 'special' ? specialEventList : eventList;
          result.forEach(ele => {
            if (ele.key === 'click') {
              ele.effects.forEach(effect => {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...ele,
                    ...effect
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID
                });
              });
              ele.behaviors?.forEach(behavior => {
                if (behavior.component) {
                    this.$store.commit('triggerEvents', {
                    config: {
                        behavior,
                        isBehavior: true
                    },
                    element: this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID
                    });
                }
                });
            }
          });
        }
      }
      // 暴露出绑定的数据
      const index = +tab.index;
      let resolveData = null;
      // 暴露数据
      if (this.tabOrigin === 'fixed') {
        resolveData = {
          label: this.tabConfig[index].label,
          value: this.tabConfig[index].type
        };
      } else if (this.tabOrigin === 'dictionary') {
        resolveData = {
          label: this.dictionaryList[index].value,
          value: this.dictionaryList[index].code
        };
      } else if (this.tabOrigin === 'dynamic') {
        resolveData = {
          ...this.dynamicTabs?.[index],
          label: this.dynamicTabs?.[index]?.[this.dynamicConfig.labelKey],
          value: this.dynamicTabs?.[index]?.[this.dynamicConfig.valueKey]
        };
      } else if (this.tabOrigin === 'interface') {
        resolveData = {
          ...this.interfaceTabs?.[index],
          label: this.interfaceTabs?.[index]?.[this.interfaceConfig.labelKey],
          value: this.interfaceTabs?.[index]?.[this.interfaceConfig.valueKey]
        };
      }
      this.element.resolveData = resolveData;

      this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData
      });
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
      // 触发事件
      // event bus  挂载卸载问题
      setTimeout(() => {
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          action: 'any',
          isSearch: true,
          output: resolveData
        });
      }, 0);
    },
    /**
     * @desc: 获取字典配置的列表
     */
    getDictionaryList() {
      dataInterface({}, `api/metadata/dictionary/${this.dictionaryConfig}`, 'GET')
        .then(res => {
          if (!res) return;
          const dictionaryList = res.data.data.item || [];
          if (!Array.isArray(dictionaryList)) {
            console.log('字典错误', dictionaryList);
            return;
          }
          if (Array.isArray(dictionaryList) && this.element?.statusConfig?.useAll) {
            if (!dictionaryList.find(ele => ele?.value === '全部' && ele?.code === '0')) {
              dictionaryList.unshift({
                // value: this.$getLocalizedLabel({'zhLabel': '全部', 'enLabel': 'all'}),
                value: '全部',
                code: '0'
              });
            }
          }
          if (dictionaryList?.length) {
            this.activeName = dictionaryList[0]?.code || '';
          }
          this.dictionaryList = dictionaryList;
          if(!this.tabPreferenceType) {
            this.onTabClick({ index: 0 });
          } else {
            this.initActiveTab()
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    /**
     * @desc: 获取字段列表
     */
    getTabList(config) {
      const {
        search = [],
        param = {},
        canPost
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents,
        this.EDITOR_pageUUID
      );
      if (!canPost) {
        console.log('无法请求')
        this._failDataDebug('参数必填校验未通过');
        return false;
      }
      this.loading = true;
      const content = {
        __method_name__: 'dataList',
        object_uuid: config.objectUUID,
        view_uuid: config.viewUUID,
        search,
        ...param
      };
      dataInterface(content).then(res => {
        this.loading = false;
        if (res.status !== 200 || !res?.data?.data) return;
        const data = res.data.data;
        const dynamicTabs = Array.isArray(data) ? data : data.data || [];
        if (Array.isArray(dynamicTabs) && this.element?.statusConfig?.useAll) {
          if (!dynamicTabs.find(ele => ele?.[this.dynamicConfig.labelKey] === '全部' && ele?.[this.dynamicConfig.valueKey] === '0')) {
            dynamicTabs.unshift({
              // [this.dynamicConfig.labelKey]: this.$getLocalizedLabel({'zhLabel': '全部', 'enLabel': 'all'}),
                [this.dynamicConfig.labelKey]: '全部',
              [this.dynamicConfig.valueKey]: '0'
            });
          }
        }
        // 默认选中
        if (dynamicTabs && dynamicTabs.length) {
          this.activeName =
            (dynamicTabs?.[0]?.[this.dynamicConfig.valueKey] &&
              dynamicTabs?.[0]?.[this.dynamicConfig.valueKey].toString()) ||
            '';
        }
        this.dynamicTabs = dynamicTabs;
        if(!this.tabPreferenceType) {
          this.onTabClick({ index: 0 });
        } else {
          this.initActiveTab()
        }
        this._successDataDebug({
          url: '',
          content,
          res
        });
      }).catch(err => {
        this.loading = false;
        console.log(err);
        this._errorDataDebug({
          url: '',
          content,
          err
        });
      });
    },
    /**
     * @description: 获取接口数据
     * @param {*} config
     * @return {*}
     */    
    getTabListByInterface(config) {
      const {
        search = [],
        param = {},
        canPost
      } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents,
        this.EDITOR_pageUUID
      );
      if (!canPost) {
        console.log('无法请求')
        return false;
      }
      this.loading = true;
      dataInterface({
        search,
        ...param
      }, `/api${config.url}`)
        .then(res => {
          this.loading = false;
          if (res.status !== 200 || !res?.data?.data) return;
          const data = res.data.data;
          const interfaceTabs = Array.isArray(data) ? data : data.data || [];
          if (Array.isArray(interfaceTabs) && this.element?.statusConfig?.useAll) {
            if (!interfaceTabs.find(ele => ele?. [this.interfaceConfig.labelKey] === '全部' && ele?.[this.interfaceConfig.valueKey] === '0')) {
              interfaceTabs.unshift({
                // [this.interfaceConfig.labelKey]: this.$getLocalizedLabel({'zhLabel': '全部', 'enLabel': 'all'}),
                [this.interfaceConfig.labelKey]: '全部',
                [this.interfaceConfig.valueKey]: '0'
              });
            }
          }
          // 默认选中
          if (interfaceTabs && interfaceTabs.length) {
            this.activeName =
              (interfaceTabs?.[0]?.[this.interfaceConfig.valueKey] &&
                interfaceTabs?.[0]?.[this.interfaceConfig.valueKey].toString()) ||
              '';
          }
          this.interfaceTabs = interfaceTabs;
          if(!this.tabPreferenceType) {
            this.onTabClick({ index: 0 });
          } else {
            this.initActiveTab()
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    /**
     * @description: 获取tab的key值
     * @param {*} data
     * @return {*}
     */    
    getTabItemKey(index = -1) {
      switch(this.tabOrigin) {
        case 'fixed': {
          if(index > -1) {
            return this.tabConfig[index].type
          } else {
            return this.tabConfig.map(el => el.type)
          }
        }
        case 'dictionary': {
          if(index > -1) {
            return this.dictionaryList[index]?.code
          } else {
            return this.dictionaryList.map(el => el?.code)
          }
        }
        case 'dynamic': {
          if(index > -1) {
            return this.dynamicTabs[index]?.[this.dynamicConfig.valueKey] + ''
          } else {
            return this.dynamicTabs.map(el => el?.[this.dynamicConfig.valueKey] + '')
          }
        }
        case 'interface': {
          if(index > -1) {
            return this.interfaceTabs[index]?.[this.interfaceConfig.valueKey] + ''
          } else {
            return this.interfaceTabs.map(el => el?.[this.interfaceConfig.valueKey] + '')
          }
        }
      }
      return ''
    },
    /**
     * @description: 初始化激活tab
     * @return {*}
     */    
    initActiveTab() {
      switch(this.tabPreferenceType) {
        case 'first': {
          this.onTabClick({ index: 0 });
          this.activeName = this.getTabItemKey(0)
          return
        }
        case 'fixed': {
          const index = this.getTabItemKey()?.findIndex(el => el === this.tabPreferenceTypeConfig?.fiexdVal)
          if(index > -1) {
            this.onTabClick({index});
            this.activeName =this.getTabItemKey(index)
            return
          }
          break
        }
        case 'other': {
          if(this.tabPreferenceTypeConfig?.sourceType === 'component') {
            const component = getComponentById(this.componentData, this.tabPreferenceTypeConfig?.target)
            if(component) {
              let v = null
              if(this.tabPreferenceTypeConfig?.dataKey === 'resolveData') {
                v = component.resolveData?.[this.tabPreferenceTypeConfig?.dataField]
              } else if(this.tabPreferenceTypeConfig?.dataKey === 'componentData') {
                v = component.resolveData?.componentData
              } else if(this.tabPreferenceTypeConfig?.dataKey === 'propValue') {
                v = component.resolveData?.propValue
              }
              if(v) {
                const index = this.getTabItemKey()?.findIndex(el => el === v)
                if(index > -1) {
                  this.onTabClick({index});
                  this.activeName = this.getTabItemKey(index)
                  return
                }
              }
            }
          } else if(this.tabPreferenceTypeConfig?.sourceType === 'system') {
            const { valueObj, valueKey } = this.tabPreferenceTypeConfig
            let targetObj = {}
            switch(valueObj) {
              case 'targetArchi': {
                targetObj = getTargetArchi()
                break
              }
              case 'userInfo': {
                targetObj = getUserInfo()
                break
              }
            }
            if(targetObj[valueKey]) {
              const index = this.getTabItemKey()?.findIndex(el => el === targetObj[valueKey])
              if(index > -1) {
                this.onTabClick({index});
                this.activeName = this.getTabItemKey(index)
                return
              }
            }
          } else if(this.tabPreferenceTypeConfig?.sourceType === 'pageStatus') {
            const { statusCode } = this.tabPreferenceTypeConfig
            if(this._PageCustomStatus[statusCode]) {
              const index = this.getTabItemKey()?.findIndex(el => el === this._PageCustomStatus[statusCode])
              if(index > -1) {
                this.onTabClick({index});
                this.activeName = this.getTabItemKey(index)
                return
              }
            }
          }
        }
      }
      this.onTabClick({ index: 0 });
      this.activeName = this.getTabItemKey(0)
    }
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
     removeEventBus('databaseTrigger', this.element.id);
    }
  }
};
</script>

<style lang="less" scoped>
.common-tabs {
  width: 100%;
  overflow: hidden;
  :deep(.el-tabs) {
    .el-tabs__item{
      height: calc((var(--tabs-fontsize) + 2px) * 2.5) !important;
      font-size: var(--tabs-fontsize) !important;
      line-height: calc((var(--tabs-fontsize) + 2px) * 2.5) !important;
    }
    &.block {
      background: rgba(239, 241, 246, 0.8);
      // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      padding: 12px 0;
      .el-tabs__header {
        margin-bottom: 0;
        .el-tabs__nav-wrap::after {
          height: 0;
        }
        .el-tabs__nav-wrap {
          .el-tabs__nav-scroll {
            box-sizing: border-box;
            padding: 0 12px;
            .el-tabs__nav {
              .el-tabs__item {
                height: 32px;
                background: #fff;
                margin-right: 12px;
                box-sizing: border-box;
                padding: 0 20px;
                border-radius: 4px;
                line-height: 32px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
    &.primary-block {
      padding: 12px 0;
      .el-tabs__header {
        margin-bottom: 0;
        .el-tabs__nav-wrap::after {
          height: 0;
        }
        .el-tabs__nav-wrap {
          .el-tabs__nav-scroll {
            box-sizing: border-box;
            padding: 0 12px;
            .el-tabs__nav {
              .el-tabs__item {
                height: 32px;
                background: #fff;
                margin-right: 12px;
                box-sizing: border-box;
                padding: 0 20px;
                border-radius: 4px;
                line-height: 32px;
                &:last-child {
                  margin: 0;
                }
              }
              .is-active {
                background: #409eff;
              }
            }
          }
        }
      }
    }
  }
  .el-tabs__content {
    display: none;
  }
}
</style>
