<!--
    @name: widget-switch
    @description：widget-switch
    @author: ZengWei
    @date: 2022-03-25 09:36
-->
<template>
  <div>
    <div v-if="disabled" class="input-show">{{ element.value === (element.config['active-value'] ?? true) ? '是' : '否' }}</div>
    <el-switch
      v-else
      v-model="element.value"
      :disabled="disabled"
      @change="triggerInteractive"
      :active-value="element.config['active-value'] ?? true"
      :inactive-value="element.config['inactive-value'] ?? false"
      ></el-switch>
  </div>
</template>

<script>
import {Switch} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";

export default {
  name: "WidgetSwitch",
  components: {
    'el-switch': Switch
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  created() {
    this.triggerInteractive(this.element.value,'auto')
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
