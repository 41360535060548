<!--
    @name: widget-checkbox
    @description：widget-checkbox
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ getCheckboxValue }}</div>
    <el-checkbox-group
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
    >
      <el-checkbox
        v-for="(val, key) in element.config.__slot__.options"
        :label="'' + val.value"
        :key="key"
      >{{ val.label }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {CheckboxGroup,Checkbox} from "element-ui";

export default {
  name: "WidgetCheckbox",
  components: {
    'el-checkbox-group':CheckboxGroup,
    'el-checkbox':Checkbox,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  computed: {
    getCheckboxValue() {
      const data = this.element.config.__slot__.options
      let value = this.element.value
      if (Array.isArray(value) && value?.length && Array.isArray(data) && data?.length) {
        value = value.map(el => {return el.toString()})
        let arr = data.filter((item) => value.includes(item.value.toString()))
        if (arr?.length) {
          arr = arr.map(el => { return el?.label })
          return arr.join(' ; ')
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    }
  },
  methods: {},
}
</script>

<style scoped>

</style>
