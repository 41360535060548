<!--
  * @Author: your name
  * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2024-08-30 18:01:22
 * @LastEditors: qinmengyuan 2715025514@qq.com
  * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\custom-component\bindLatLng\index.vue
 -->
<template>
  <div class="lat-choose-cont">
    <div class="header absolute">
      <div class="relative">
        <el-page-header class="page-header" :content="title" @back="back" />
        <div
          v-show="componentType === 'latlng' && !config?.disabled"
          :class="{
            btn: true,
          }"
        >
          <div
            v-if="config?.edit_marker"
            @click="handleDrawType('point', true)"
            :class="{
              'marker-btn': true,
              active: switchPoint === true,
              ponit: config?.edit_marker && +config?.locateType !== 1,
            }"
          >
            <i class="iconfont icongerenxinxika"></i>
            标记名称
          </div>
          <div
            v-if="
              !config?.edit_marker &&
              (+config?.locateType === 1 || +config?.locateType === 0)
            "
            :class="[
              'select-btn',
              {
                active: switchPoint === true,
                ponit: +config?.locateType === 0,
              },
            ]"
            @click="handleDrawType('point', true)"
          >
            <i class="iconfont iconOutlined"></i>
            选点
          </div>
          <div
            v-if="+config?.locateType === 2 || +config?.locateType === 0"
            :class="{
              active: switchPoint === false,
            }"
            @click="handleDrawType('cover', false)"
          >
            <i class="iconfont icona-Outlined1"></i>
            画区域
            <ul
              class="dropdown"
              v-if="componentType === 'latlng' && switchPoint === false"
            >
              <li
                v-for="graph in graphList"
                :key="graph.value"
                :class="{ active: graph.value === graphType }"
                @click="handleCommand(graph.value)"
              >
                <img :src="graph.icon" alt="" /> {{ graph.label }}
              </li>
            </ul>
          </div>
        </div>
        <el-button
          :disabled="config?.disabled"
          class="absolute"
          size="mini"
          style="top: 8px; right: 78px"
          type="default"
          @click="clearChooseData()"
        >
          清除
        </el-button>
        <el-button
          class="absolute"
          :disabled="config?.disabled"
          size="mini"
          style="top: 8px; right: 10px"
          type="primary"
          @click="commit()"
        >
          确定
        </el-button>
      </div>
    </div>
    <div
      v-if="showInfoModal && componentType === 'latlng' && modelsInfo.length"
      class="point-info"
    >
      <div class="info-header">
        <div class="info-header-top">
          <div class="icon">
            <img
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/16c174bf623249a11921137ac92c13bb.png"
              alt=""
            />
          </div>
          <div class="maker">标记</div>
          <div class="name">
            {{ info.fullName }}
          </div>
        </div>
        <div class="detail-address">
          {{ info.fullName }}
        </div>
      </div>
      <div class="info-desc">
        <div class="desc">
          <div class="icon">
            <img
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/3a72bcd7ceb0c8375f6891757b1a9e65.png"
              alt=""
            />
          </div>
          <div class="text">标记信息描述</div>
        </div>
        <div class="item-container">
          <div class="item">经 度：{{ latLng?.lng }}</div>
          <div class="item">纬 度：{{ latLng?.lat }}</div>
          <div class="item">
            A 坐标：{{ modelsInfo[modelsInfo.length - 1]?.A }}
          </div>
          <div class="item">
            B 坐标：{{ modelsInfo[modelsInfo.length - 1]?.B }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showInfoModal && componentType === 'latlng' && modelsInfo.length"
      class="point-info list-itm"
    >
      <div
        class="info-header"
        v-for="(item, index) in modelsInfo"
        @click.stop="location(item)"
        :key="item.id"
      >
        <div class="info-header-top">
          <div class="icon">
            <i style="color: #fff" class="iconfont iconshezhi2"></i>
          </div>
          <div class="maker">区域</div>
          <div class="name">
            {{ item.location_name?.split('|')[1] || item.location_name }}
          </div>
        </div>
        <div class="detail-desc">
          <div class="detail-address">
            {{ item.location_name }}
          </div>
          <div class="detail-icon">
            <i
              v-if="!item.selfPolygon && config?.edit_marker"
              :class="{
                iconfont: true,
                iconbim_bianji: true,
                active: edit_marker.id === item.id,
              }"
              @click.stop="handlePoint(item)"
            ></i>
            <i class="el-icon-delete" @click.self="delPloygon(item, index)"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="componentType === 'latlng' && edit_marker.id"
      class="marker-name"
    >
      <div class="info-header">
        <div class="info-header-top">
          <div class="icon">
            <i style="color: #fff" class="iconfont icongerenxinxika"></i>
          </div>
          <div class="maker">标记名称</div>
          <div class="name">{{ edit_marker.location_name }}</div>
        </div>
        <div class="detail-desc">
          <div class="detail-address">
            {{ edit_marker.location_name }}
          </div>
        </div>
        <el-input
          class="marker-input"
          v-model="edit_marker_name"
          @change="handleInput"
        />
      </div>
    </div>
    <div class="w-full h-full" style="flex-direction: columns; display: flex">
      <List
        v-if="componentType === 'gis'"
        class="list-cont scroller"
        ref="listRef"
        :model-data="modelData"
        @show-model="showModel"
        @clear-obk="clearObk"
      />
      <div id="map2dCont" class="relative flex1"></div>
    </div>
    <!-- 搜索 -->
    <div v-show="componentType === 'latlng'" class="bind-search-cont">
      <el-select
        popper-class="bind-select-list"
        v-model="value"
        filterable
        remote
        style="width: 88%"
        reserve-keyword
        placeholder="请输入关键词"
        loading-text="加载中..."
        :remote-method="remoteMethod"
        @change="checkList"
        :loading="loading"
        @focus="onFocus"
        @blur="onBlur"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.address"
          :value="item.id"
        >
          <p class="item-desc">
            <img
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d3173395c39d6ac8dc0e0799378b30db.png"
              alt=""
            />
            <span class="item-address">{{ item.address }}</span>
            <span class="item-adname">{{ item.adname }}</span>
          </p>
        </el-option>
      </el-select>
      <el-button
        class="search-btn"
        slot="append"
        icon="el-icon-search"
        type="primary"
      ></el-button>
    </div>
    <img
      class="sample-img"
      v-if="config?.sampleUrl"
      :src="config.sampleUrl"
      alt=""
    />
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import List from '@/custom-component/bindLatLng/modelList.vue';
import { PageHeader, Message } from 'element-ui';
import { div } from './marker.js';

let viewerGis;
export default {
  name: 'Bind',
  components: {
    'el-page-header': PageHeader,
    List,
  },
  props: {
    modelData: {
      type: [Object, Array],
      default: null,
    },
    componentType: {
      type: String,
      default: '',
    },
    appId: {
      type: String,
      default: '',
    },
    appSecret: {
      type: String,
      default: '',
    },
    drawType: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: null,
    },
    defaultPosition: {
      type: Object,
      default: null,
    },
    interfaceData: {
      type: Object,
      default: null,
    },
    searchData: {
      type: Array,
      default: () => [],
    },
    bodyParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      latLng: null,
      info: {
        area: '',
        fullName: '',
        address: '',
      },
      switchPoint: null,
      loading: false,
      // 模型绑定数据
      modelsInfo: [
        {
          model_id: '',
          select: [],
          polygon: [],
          location_gis: null,
          location_name: '',
          A: '',
          B: '',
          location_area: '',
          location_address: '',
          position: {},
        },
      ],
      chooseType: '',
      options: [],
      value: '',
      showInfoModal: false,
      graphList: [
        {
          label: '矩形',
          value: 'rectangle',
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5181caffdead86d8b0136b49fc82a8ec.png',
        },
        {
          label: '圆形',
          value: 'circular',
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/45eb1b20f8ab8d1338617648b0eaf5e7.png',
        },
        {
          label: '多边形',
          value: 'polygon',
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/c6b7bfe693faaea34a60b3f40fc032b7.png',
        },
      ],
      graphType: 'polygon',
      polygonNameList: [],
      edit_marker: {
        location_gis: {},
        A: '',
        B: '',
        id: '',
        location_name: '',
      },
      edit_marker_name: '',
    };
  },
  computed: {
    title() {
      if (this.componentType === 'latlng') {
        return '绑定经纬度';
      } else if (this.componentType === 'gis') {
        return '绑定GIS';
      } else {
        return '';
      }
    },
  },
  watch: {
    componentType: {
      handler: function () {
        switch (this.componentType) {
          case 'gis':
            viewerGis.Scene.setImageryLayer('TianDiTu');
            this.drawPoint();
            break;
          case 'latlng':
            viewerGis.Scene.setImageryLayer('AMapStreets');
            this.drawPolygon();
            break;
          default:
        }
      },
    },
    // 监听switchPoint的变化，若为true则切换为经纬度，若为false则切换为画区域
    chooseType: {
      handler: function (newVal) {
        if (newVal === 'point') {
          this.drawPoint();
        } else if (newVal === 'cover') {
          this.drawPolygon();
        }
      },
    },
    edit_marker: {
      handler: function (newVal) {
        if (this.config?.edit_marker && newVal.location_name) {
          let image = viewerGis.Image.getDivBuilderImage({
            div: div(newVal.location_name),
          });
          viewerGis.Marker.createMarker(
            this.modelsInfo[this.modelsInfo.length - 1]?.id,
            {
              type: 'Billboard_And_Popup',
              position:
                this.modelsInfo[this.modelsInfo.length - 1].location_gis,
              options: {
                billboard: {
                  image:
                    'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2ca55b7a8fcaab6505839edd0e198811.png',
                  isShow: false,
                  width: 1,
                  height: 1,
                },
                popup: {
                  image: image,
                  pixelOffset: [40, 60],
                  isShow: true,
                },
              },
            }
          );
        }
      },
    },
  },
  methods: {
    location(item) {
      viewerGis.Scene.flyToPoint(
        item.location_gis.lng,
        item.location_gis.lat,
        1000
      );
    },
    handlePoint(item) {
      if (this.config?.edit_marker && !item.selfPolygon) {
        this.edit_marker = JSON.parse(JSON.stringify(item));
        this.edit_marker_name = item.location_name;
      }
    },
    /**
     * @name:
     * @description:
     * @msg:
     * @return {*}
     */
    lngLatTransformAB(list = []) {
      if (!viewerGis) return;
      return viewerGis.Coordinate.NanNingTransform(list);
    },
    handleInput(val) {
      this.modelsInfo = this.modelsInfo.map((item) => {
        if (item.id === this.edit_marker.id) {
          let image = viewerGis.Image.getDivBuilderImage({
            div: div(val),
          });
          viewerGis.Marker.removeMarker(item.id);
          viewerGis.Marker.createMarker(item.id, {
            type: 'Billboard_And_Popup',
            position: item.location_gis,
            options: {
              billboard: {
                image:
                  'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2ca55b7a8fcaab6505839edd0e198811.png',
                isShow: false,
                width: 1,
                height: 1,
              },
              popup: {
                image: image,
                pixelOffset: [40, 60],
                isShow: true,
              },
            },
          });
          return { ...item, location_name: val };
        } else {
          return item;
        }
      });
    },
    delPloygon(data, index) {
      if (!viewerGis) return;
      if (data.selfPolygon) {
        viewerGis.Plot.removePlot(data.id);
      } else {
        viewerGis.Marker.removeMarker(data.id);
      }
      // 清除最后一个同时清除绘制的标记
      if (index === this.modelsInfo.length - 1) {
        if (this.switchPoint) {
          this.drawPoint();
        } else {
          this.drawPolygon();
        }
      }
      if (this.edit_marker.id === data.id) {
        this.edit_marker = {};
      }
      this.modelsInfo = this.modelsInfo.filter((item) => data.id !== item.id);
      if (this.modelsInfo.length) {
        this.info = {
          area: this.modelsInfo[this.modelsInfo.length - 1].location_name.split(
            '|'
          )[0],
          fullName:
            this.modelsInfo[this.modelsInfo.length - 1].location_name.split(
              '|'
            )[1],
        };
        this.latLng = this.modelsInfo[this.modelsInfo.length - 1].location_gis;
      }
    },
    /**
     * @description: 切换搜索地址
     * @return {*}
     */
    checkList(id) {
      if (viewerGis !== null) {
        const findData = this.options.find((v) => v.id === id);
        if (findData) {
          viewerGis.Poi.locationPOI(id);
          if (this.chooseType === 'point' && !this.config.disabled) {
            this.latLng = findData.position;
            this.info = {
              area: `${findData.pname}/${findData.cityname}/${findData.adname}`,
              fullName: `${findData.pname}${findData.cityname}${findData.adname}${findData.address}`,
            };
            const abCoord = this.lngLatTransformAB([findData.position]);
            this.modelsInfo = [
              {
                location_gis: findData.position,
                location_name: this.info.area + '|' + this.info.fullName,
                A: abCoord[0].A,
                B: abCoord[0].A,
              },
            ];
          }
        }
      }
    },
    /**
     * @name:
     * @description: 聚焦展示详情面板
     * @msg:
     * @return {*}
     */
    onFocus() {
      this.showInfoModal = false;
    },
    /**
     * @name:
     * @description: 失去焦点隐藏详情面板
     * @msg:
     * @return {*}
     */
    onBlur() {
      const show = this.modelsInfo.filter((item) => item.location_name);
      if (this.modelsInfo.length && show.length) {
        this.showInfoModal = true;
      }
    },
    /**
     * @name:
     * @description:
     * @msg:
     * @return {*}
     */
    handleDrawType(type, value) {
      if (
        (this.switchPoint === true && !value) ||
        (this.switchPoint === false && value)
      ) {
        // 切换分类清空之前的modelsInfo数据 单选情况下需要清空
        if (!this.config?.multiple) {
          this.clearChooseData();
        }
      }
      this.chooseType = type;
      this.switchPoint = value;
      if (type === 'cover' && !this.modelsInfo.length) {
        this.showInfoModal = false;
      }
    },
    /**
     * @name:
     * @description: 点击下拉菜单绘制图形
     * @msg:
     * @return {*}
     */
    handleCommand(command) {
      this.graphType = command;
      if (viewerGis) {
        // this.clearChooseData();
        this.drawPolygon();
      }
    },
    /**
     * @description: 远程搜索，调用gis接口
     * @param {*} query
     * @return {*}
     */
    async remoteMethod(query) {
      this.loading = true;
      if (query !== '') {
        if (viewerGis !== null) {
          this.options = await viewerGis.Poi.createPOI(query);
          this.loading = false;
        }
      } else {
        this.latLng = null;
        this.options = [];
        this.info = {
          area: '',
          fullName: '',
        };
      }
    },
    /**
     * @description: 加载模型
     * @param {*} id
     * @return {*}
     */
    showModel(id, bol) {
      if (viewerGis !== null) {
        if (bol) {
          viewerGis.Model.createModel(id, { isLocation: true });
        } else {
          viewerGis.Model.removeModel(id);
        }
      }
    },
    clearObk() {
      this.modelsInfo = [
        {
          model_id: '',
          select: [],
          polygon: [],
          location_gis: null,
          location_name: '',
          A: '',
          B: '',
        },
      ];
      if (viewerGis !== null) {
        const loadedModesId = viewerGis.Model.getModelKeys();
        if (loadedModesId?.length) {
          loadedModesId.forEach((item) => {
            viewerGis.Model.removeModel(item);
          });
        }
      }
    },
    /**
     * @description: 清除绘制的面
     * @return {*}
     */
    clearChooseData() {
      this.showInfoModal = null;
      this.switchPoint = null;
      this.latLng = null;
      this.info = {
        area: '',
        fullName: '',
      };
      if (viewerGis) {
        viewerGis.Poi.removePOI();
        viewerGis.MouseCustomEvent.removeDrawing(true);
        this.chooseType = '';
        this.modelsInfo.forEach((item) => {
          viewerGis.Plot.removePlot(item.id);
          viewerGis.Marker.removeMarker(item.id);
        });
      }
      this.modelsInfo = [
        {
          model_id: '',
          select: [],
          polygon: [],
          location_gis: null,
          location_name: '',
          A: '',
          B: '',
        },
      ];
      this.edit_marker = {};
      this.edit_marker_name = '';
    },
    /**
     * @description: 返回
     * @return {*}
     */
    back() {
      this.$emit('back');
    },
    /**
     * @description: 提交数据
     * @return {*}
     */
    commit() {
      //删除空对象以及空数字空字符串
      if (this.modelsInfo.length) {
        for (let i = 0; i < this.modelsInfo.length; i++) {
          for (let k in this.modelsInfo[i]) {
            if (
              Array.isArray(this.modelsInfo[i][k]) &&
              !this.modelsInfo[i][k].length
            ) {
              delete this.modelsInfo[i][k];
            } else if (!this.modelsInfo[i][k]) {
              delete this.modelsInfo[i][k];
            }
          }
        }
      }
      this.$emit('set-data', this.modelsInfo);
    },
    /**
     * @description: 经纬度转换省市区
     * @param {*} val
     * @return {*}
     */
    getInfo(val) {
      dataInterface(
        { location: `${val.lat},${val.lng}`, language: this.$i18n.locale },
        'api/language/baiduMap',
        'post'
      ).then((res) => {
        if (res.data.code === 200 && res.data?.data?.result?.addressComponent) {
          this.info.area = '';
          this.info.fullName = '';
          if (this.modelsInfo.length) {
            const data = res.data.data.result.addressComponent;
            let arr = [
              'country',
              'province',
              'city',
              'district',
              'street',
              'street_number',
            ];
            let n = 4;
            if (data.country && data.country === '中国') {
              arr = arr.splice(1, arr.length - 1);
              n = 3;
            }
            arr.forEach((item, i) => {
              if (data[item]) {
                if (i === 0) {
                  if (i < n) {
                    this.info.area += data[item] + '/';
                  }
                  this.info.fullName += data[item];
                } else if (data[arr[i - 1]] !== data[item]) {
                  if (i < n) {
                    this.info.area += data[item] + '/';
                  }
                  this.info.fullName += ' ' + data[item];
                }
              }
              if (item === 'city') {
                this.info[item] = data[item];
              }
            });
            if (this.info.area && this.info.area.includes('/')) {
              this.info.area = this.info.area.substring(
                0,
                this.info.area.length - 1
              );
            }
            this.modelsInfo[this.modelsInfo.length - 1].location_name =
              this.info.area && this.info.fullName
                ? this.info.area + '|' + this.info.fullName
                : '';
            if (this.chooseType !== 'cover') {
              this.edit_marker.location_name = this.info.fullName || '';
              this.edit_marker = JSON.parse(JSON.stringify(this.edit_marker));
              this.edit_marker_name = this.info.fullName || '';
            }
          }
        } else {
          if (this.chooseType !== 'cover') {
            this.edit_marker.location_name = '标记名称';
            this.edit_marker = JSON.parse(JSON.stringify(this.edit_marker));
          }
        }
      });
    },
    // 监听绘制面结束，返回面的经纬度数组
    drawPolygon() {
      viewerGis.Scene.setGroundAtmosphere(false);
      viewerGis.MouseCustomEvent.removeDrawing();
      if (this.graphType === 'polygon')
        viewerGis.MouseCustomEvent.drawPolygon(false);
      if (this.graphType === 'circular') {
        viewerGis.MouseCustomEvent.drawCircle(false);
      }
      if (this.graphType === 'rectangle') {
        viewerGis.MouseCustomEvent.drawRectangle(false);
      }
    },
    // 监听绘制点结束，返回点的经纬度
    drawPoint() {
      if (viewerGis) {
        // 如果有默认的polygonList 则清除的时候保留默认的 多选的时候不需要清空，用户需要清空手动清空
        if (!this.config?.multiple) {
          if (this.polygonNameList.length) {
            this.modelsInfo.forEach((item) => {
              viewerGis.Plot.removePlot(item.id);
            });
          } else {
            viewerGis.Plot.removeAllPlot();
          }
        }
      }
      viewerGis.Scene.setGroundAtmosphere(false);
      viewerGis.MouseCustomEvent.removeDrawing();
      viewerGis.MouseCustomEvent.drawPoint(false);
    },
    getBooleanInsideByPolygon(point, polygon) {
      let x = Number(point.lng),
        y = Number(point.lat);
      let inside = false;
      for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = Number(polygon[i].lng),
          yi = Number(polygon[i].lat);
        let xj = Number(polygon[j].lng),
          yj = Number(polygon[j].lat);

        let intersect =
          yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    },
    /**
     * @name:
     * @description: 范围定位
     * @msg:
     * @param {*} list
     * @return {*}
     */
    flyToBoundHeading(list = []) {
      if (!list.length) return;
      const lngArr = list.map((item) => item.lng);
      const latArr = list.map((item) => item.lat);
      const east = Math.max(...lngArr);
      const west = Math.min(...lngArr);
      const south = Math.min(...latArr);
      const north = Math.max(...latArr);
      viewerGis.Scene.flyToBoundHeading(
        { west, east, south, north },
        90,
        0,
        15000
      );
    },
  },
  async mounted() {
    viewerGis = new global.BIMCC_GIS_Base.Viewer('map2dCont', {
      host: 'models.bimcc.net',
      port: '',
      isHttps: true,
      appId: this.appId || process.env.VUE_APP_MODEL_APPID,
      appSecret: this.appSecret || process.env.VUE_APP_MODEL_APPSECRET,
    });
    await viewerGis.init();
    window.viewerGis = viewerGis;
    //切换成2d地图
    if (!this.config?.is3D) {
      viewerGis.Scene.setImageryLayer('AMapStreets');
      viewerGis.MouseController.mouseStyleByColumbusView();
      if (navigator.geolocation && !this.modelsInfo[0].location_gis) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          if (!this.modelsInfo[0].location_gis) {
            viewerGis.Scene.flyTo({ lng, lat }, 4000, 90, 0);
          }
        });
      }
    }
    //默认定位到配置的位置
    if (
      this.config?.defaultPosition?.lat &&
      this.config?.defaultPosition?.lat
    ) {
      viewerGis.Scene.flyTo(this.config.defaultPosition, 2000);
    }

    const getCenter = (list) => {
      if (!list.length) return;
      const lngArr = list.map((item) => item.lng);
      const latArr = list.map((item) => item.lat);
      const east = Math.max(...lngArr);
      const west = Math.min(...lngArr);
      const south = Math.min(...latArr);
      const north = Math.max(...latArr);
      const centerPoint = { lat: (south + north) / 2, lng: (east + west) / 2 };
      const canvasPoint = viewerGis.Coordinate?.getCanvasByWgs(
        centerPoint.lng,
        centerPoint.lat,
        0
      );
      return { ...centerPoint, ...canvasPoint };
    };
    // 绘制用户上传接口的标绘标点
    const drawDefaultPolygon = (data) => {
      const colorList = [
        '#00A1F8',
        '#00F8CB',
        '#F2AA3D',
        '#834BCB',
        '#35B423',
        '#FFFE30',
        '#DC4E4E',
        '#54DADD',
      ];
      const nameList = [];
      const positionArr = [];
      let list = (data || [])
        .filter(
          (item) =>
            item[this.interfaceData.value]?.length &&
            Array.isArray(item[this.interfaceData.value])
        )
        .forEach((item, index) => {
          item[this.interfaceData.value].forEach((poi) => {
            positionArr.push({
              ...poi,
              label: item[this.interfaceData.label],
              color: colorList[index],
            });
          });
        });
      list = positionArr;
      list.forEach((item) => {
        if (!item.selfPolygon) return;
        if (!item.selfPolygon?.radius) {
          nameList.push({
            position: getCenter(item.selfPolygon?.position),
            name: item?.label,
            polygon: item?.selfPolygon,
          });
        }
        viewerGis.Plot.createPlot(`default${item.selfPolygon?.id}`, {
          plotType: item.selfPolygon?.radius ? 'circle' : 'polygon',
          positions: item.selfPolygon?.position || [],
          options: {
            plotType: item.selfPolygon?.radius ? 'circle' : 'polygon',
            perPositionHeight: true,
            height: 5,
            fill: true,
            fillType: 'color',
            fillColor: item.color,
            alpha: 50,
            outline: true,
            outlineColor: '#dc2e41',
            outlineWidth: 1,
            isClampToGround: false,
            stRotation: 0,
            radius: item.selfPolygon?.radius,
          },
          isLabel: true,
          labelOptions: {
            text: item?.label,
          },
        });
      });
      this.polygonNameList = nameList;
      if (this.polygonNameList?.length) {
        this.flyToBoundHeading(this.polygonNameList[0]?.polygon?.position);
      }
    };
    // 用户配置了视点接口，发送请求 （选项为对象视图）
    if (this.config?.viewType === 'object') {
      let data = {
        ...this.bodyParams,
        ...(this.searchData.length && {
          search: JSON.parse(JSON.stringify(this.searchData)),
        }),
      };
      if (this.interfaceData.viewUuid && this.interfaceData.objectUuid) {
        dataInterface({
          __method_name__: 'dataList',
          view_uuid: this.interfaceData.viewUuid,
          object_uuid: this.interfaceData.objectUuid,
          ...(this.config.openFilter && data),
        }).then((res) => {
          const { code, data } = res.data;
          if (code === 200) {
            drawDefaultPolygon(data);
          }
        });
      }
    }
    if (this.config?.viewType === 'graph') {
      let data = {
        ...this.bodyParams,
        ...(this.searchData.length && {
          search: JSON.parse(JSON.stringify(this.searchData)),
        }),
      };
      const pathArr = this.config.graphPath.split('-');
      if (this.config.graphPath) {
        dataInterface(
          this.config.openFilter ? data : {},
          '/api' + pathArr[pathArr.length - 1]
        ).then((res) => {
          if (res.data.code === 200) {
            drawDefaultPolygon(res.data.data);
          }
        });
      }
    }
    if (this.config?.mapUrlType === 'png') {
      if (this.config?.mapUrl) {
        viewerGis.Plot.createWISPolygon(undefined, {
          url: this.config.mapUrl,
          ...(this.config.thumbnailUrl && {
            thumbnailUrl: this.config.thumbnailUrl,
          }),
        });
      }
    } else {
      if (this.config?.mapUrl) {
        if (this.config.mapUrl.includes('|')) {
          const arr = this.config.mapUrl.split('|');
          if (arr.length) {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].includes('_WMS') || arr[i].includes('_WFS')) {
                viewerGis.Scene.addWMSImagery({
                  mapUrl: arr[i],
                });
              } else {
                viewerGis.Scene.addCustomImagery({
                  mapUrl: arr[i],
                });
              }
            }
          }
        } else {
          if (
            this.config.mapUrl.includes('_WMS') ||
            this.config.mapUrl.includes('_WFS')
          ) {
            viewerGis.Scene.addWMSImagery({
              mapUrl: this.config.mapUrl,
            });
          } else {
            viewerGis.Scene.addCustomImagery({
              mapUrl: this.config.mapUrl,
            });
          }
        }
      }
    }

    //监听模型加载完毕
    viewerGis.on(viewerGis.EventType.modelLoaded, (modelId) => {
      if (modelId) {
        if (
          Object.prototype.toString.call(this.modelData) ===
            '[object Object]' &&
          this.modelData?.models.length
        ) {
          //回显构件选中，兼容老格式
          this.modelData?.models.forEach((val) => {
            if (val.model_id === modelId) {
              if (val.dbids?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.dbids);
              } else if (val.select?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.select);
              }
            }
          });
        } else if (
          Object.prototype.toString.call(this.modelData) === '[object Array]'
        ) {
          //回显构件选中,新格式
          this.modelData.forEach((val) => {
            if (val.model_id === modelId) {
              if (val.select?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.select);
              } else if (val.select?.length) {
                viewerGis.Model.selectFeature(val.model_id, val.select);
              }
            }
          });
        }
      }
    });
    // 回显model 位置
    if (this.componentType === 'gis' && this.modelData) {
      if (Array.isArray(this.modelsInfo)) {
        this.modelsInfo = this.modelData;
        this.modelsInfo.forEach((item) => {
          if (item.position) {
            viewerGis.Marker.createMarker(item.id, {
              type: 'Billboard',
              position: item.position,
              options: {
                image: viewerGis.Image.getIconBlendImage({
                  url: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d52679369b5eefa811be37c436f50584.png',
                }),
                width: 36,
                height: 36,
              },
            });
          }
        });
      }
    }
    //监听绘面多边形返回数据
    viewerGis.on(viewerGis.EventType.drawPolygonByMouseCustom, (res) => {
      const arr = res.map((item) => ({
        lng: item[0],
        lat: item[1],
        height: 0,
      }));
      const id = Date.now();
      const position = {
        lat: res[0][1],
        lng: res[0][0],
      };
      const ABCoord = viewerGis.Coordinate.NanNingTransform([position]);
      const positionItem = {
        location_gis: position,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        selfPolygon: { position: arr, id },
        id,
      };
      this.latLng = position;
      this.showInfoModal = true;
      if (this.config?.multiple) {
        this.modelsInfo = [...this.modelsInfo, positionItem].filter(
          (item) => item.location_gis
        );
      } else {
        this.modelsInfo[0] = positionItem;
        viewerGis.Plot.removeAllPlot();
      }
      this.getInfo(position);
      viewerGis.Plot.createPlot(id, {
        plotType: 'polygon',
        positions: arr,
        options: {
          plotType: 'polygon',
          perPositionHeight: true,
          height: 5,
          fill: true,
          fillType: 'color',
          fillColor: '#2FA45A',
          alpha: 20,
          outline: true,
          outlineColor: '#14ae5c',
          outlineWidth: 2,
          isClampToGround: false,
          stRotation: 0,
        },
      });
      //绘制后就可以清除绘制状态，若想一直绘制则可以不用写这句
      // viewerGis.MouseCustomEvent.removeDrawing();
    });
    //监听绘面矩形返回数据
    viewerGis.on(viewerGis.EventType.drawRectangleByMouseCustom, (res) => {
      const arr = [
        { lng: res.east, lat: res.north, height: 0 },
        { lng: res.west, lat: res.north, height: 0 },
        { lng: res.west, lat: res.south, height: 0 },
        { lng: res.east, lat: res.south, height: 0 },
      ];
      const id = Date.now();
      // 多个图形 position 数组
      const position = {
        lat: (res.north + res.south) / 2,
        lng: (res.east + res.west) / 2,
      };
      const ABCoord = viewerGis.Coordinate.NanNingTransform([position]);
      const positionItem = {
        location_gis: position,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        selfPolygon: { position: arr, id },
        id,
      };
      this.latLng = position;
      this.showInfoModal = true;

      if (this.config?.multiple) {
        this.modelsInfo = [...this.modelsInfo, positionItem].filter(
          (item) => item.location_gis
        );
      } else {
        this.modelsInfo[0] = positionItem;
        viewerGis.Plot.removeAllPlot();
      }
      viewerGis.Plot.createPlot(id, {
        plotType: 'polygon',
        positions: arr,
        options: {
          plotType: 'polygon',
          perPositionHeight: true,
          height: 5,
          fill: true,
          fillType: 'color',
          fillColor: '#2FA45A',
          alpha: 20,
          outline: true,
          outlineColor: '#14ae5c',
          outlineWidth: 2,
          isClampToGround: false,
          stRotation: 0,
        },
      });
      this.getInfo(position);
      //绘制后就可以清除绘制状态，若想一直绘制则可以不用写这句
      // viewerGis.MouseCustomEvent.removeDrawing();
    });
    //监听绘面圆形返回数据
    viewerGis.on(viewerGis.EventType.drawCircleByMouseCustom, (res) => {
      const id = Date.now();
      const position = res.position;
      const ABCoord = viewerGis.Coordinate.NanNingTransform([position]);
      const positionItem = {
        location_gis: position,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        selfPolygon: { position: [res.position], radius: res.radius, id },
        id,
      };
      this.latLng = position;
      this.showInfoModal = true;

      if (this.config?.multiple) {
        this.modelsInfo = [...this.modelsInfo, positionItem].filter(
          (item) => item.location_gis
        );
      } else {
        this.modelsInfo[0] = positionItem;
        viewerGis.Plot.removeAllPlot();
      }
      this.getInfo(position);
      viewerGis.Plot.createPlot(id, {
        plotType: 'circle',
        positions: [res.position],
        options: {
          radius: res.radius,
          perPositionHeight: true,
          height: 5,
          fill: true,
          fillType: 'color',
          fillColor: '#2FA45A',
          alpha: 20,
          outline: true,
          outlineColor: '#14ae5c',
          outlineWidth: 2,
          isClampToGround: false,
          stRotation: 0,
        },
      });
      //绘制后就可以清除绘制状态，若想一直绘制则可以不用写这句
      // viewerGis.MouseCustomEvent.removeDrawing();
    });
    //监听绘制点的经纬度数据
    viewerGis.on(viewerGis.EventType.drawPointByMouseCustom, async (res) => {
      const ABCoord = viewerGis.Coordinate.NanNingTransform([
        { lat: res.lat, lng: res.lng },
      ]);
      const id = Date.now();
      if (this.componentType === 'gis') {
        this.modelsInfo[0].position = { ...res };
        this.modelsInfo[0].id = id;
        return;
      }
      const position = {
        location_gis: res,
        A: ABCoord[0].A,
        B: ABCoord[0].B,
        id,
      };
      if (this.config?.edit_marker) {
        this.edit_marker = position;
      }
      if (res) {
        if (this.config?.multiple) {
          this.modelsInfo = [...this.modelsInfo, position].filter(
            (item) => item.location_gis
          );

          await this.getInfo(res);
          this.latLng = { lat: res?.lat, lng: res?.lng };
          this.value = '';
          this.options = [];
          this.showInfoModal = true;
          if (!this.config?.edit_marker) {
            viewerGis.Marker.createMarker(id, {
              type: 'Billboard',
              position:
                this.modelsInfo[this.modelsInfo.length - 1].location_gis,
              options: {
                image: viewerGis.Image.getIconBlendImage({
                  url: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d52679369b5eefa811be37c436f50584.png',
                }),
                width: 36,
                height: 36,
              },
            });
          }
        } else {
          this.modelsInfo[0] = position;
          await this.getInfo(res);
          this.latLng = { lat: res?.lat, lng: res?.lng };
          this.value = '';
          this.options = [];
          this.showInfoModal = true;
        }
      }
      // 判断选点是在默认加载的内部还是外部 用户默认有区域限制的时候
      if (this.config.showRegionLimit && this.polygonNameList.length) {
        const insertArr = this.polygonNameList.map((item) => {
          return this.getBooleanInsideByPolygon(res, item.polygon.position);
        });
        const insert = insertArr.some((item) => item);
        if (!insert) {
          Message.warning({ message: '不在限制区域范围内' });
        }
      }
    });
    // 主要的回显操作
    const showPlot = () => {
      if (this.modelsInfo?.filter((item) => item.location_name)?.length) {
        this.latLng = {
          lat: this.modelsInfo[this.modelsInfo.length - 1].location_gis?.lat,
          lng: this.modelsInfo[this.modelsInfo.length - 1].location_gis?.lng,
        };
        for (let i = 0; i < this.modelsInfo.length; i++) {
          if (
            this.modelsInfo[i].location_gis &&
            !this.modelsInfo[i].selfPolygon
          ) {
            if (this.config?.edit_marker) {
              let image = viewerGis.Image.getDivBuilderImage({
                div: div(this.modelsInfo[i].location_name),
              });
              viewerGis.Marker.createMarker(this.modelsInfo[i].id, {
                type: 'Billboard_And_Popup',
                position: this.modelsInfo[i].location_gis || null,
                options: {
                  billboard: {
                    image:
                      'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/2ca55b7a8fcaab6505839edd0e198811.png',
                    isShow: false,
                    width: 1,
                    height: 1,
                  },
                  popup: {
                    image: image,
                    pixelOffset: [40, 60],
                    isShow: true,
                  },
                },
              });
            } else {
              viewerGis.Marker.createMarker(this.modelsInfo[i].id, {
                type: 'Billboard',
                position: this.modelsInfo[i].location_gis || null,
                options: {
                  image: viewerGis.Image.getIconBlendImage({
                    url: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d52679369b5eefa811be37c436f50584.png',
                  }),
                  width: 36,
                  height: 36,
                },
              });
            }
            viewerGis.Scene.flyTo(
              this.modelsInfo[this.modelsInfo.length - 1].location_gis,
              4000,
              90,
              0
            );
          } else {
            viewerGis.Plot.createPlot(this.modelsInfo[i]?.id, {
              plotType: this.modelsInfo[i]?.selfPolygon?.radius
                ? 'circle'
                : 'polygon',
              positions: this.modelsInfo[i]?.selfPolygon.position || [],
              options: {
                plotType: this.modelsInfo[i]?.selfPolygon.radius
                  ? 'circle'
                  : 'polygon',
                perPositionHeight: true,
                height: 5,
                fill: true,
                fillType: 'color',
                fillColor: '#eabebf',
                alpha: 50,
                outline: true,
                outlineColor: '#dc2e41',
                outlineWidth: 1,
                isClampToGround: false,
                stRotation: 0,
                radius: this.modelsInfo[i]?.selfPolygon.radius,
              },
            });
            if (i === 0) {
              viewerGis.Scene.flyTo(
                this.modelsInfo[i]?.selfPolygon?.position[0],
                4000,
                90,
                0
              );
            }
          }
          if (i === this.modelsInfo.length - 1) {
            if (this.modelsInfo[i].location_name) {
              const addressType = this.modelsInfo[i].location_name.split('|');
              if (addressType.length === 2) {
                this.info.area = addressType[0];
                this.info.fullName = addressType[1];
              }
            }
          }
        }
      }
      if (this.modelsInfo[0]?.location_gis && this.componentType === 'latlng') {
        this.showInfoModal = true;
      }
    };
    // 默认选中操作按钮
    if (this.componentType === 'gis') {
      this.drawPoint();
    }
    if (this.componentType !== 'gis') {
      if (
        (Array.isArray(this.modelData) && !this.modelData?.length) ||
        this.modelData === null
      ) {
        if (this.config?.disabled) return;
        //默认绑定经纬度
        if (this.drawType === 2) {
          // this.drawPolygon();
          // this.chooseType = "cover";
        } else {
          this.drawPoint();
          this.chooseType = 'point';
          this.switchPoint = true;
        }
      } else {
        // 回显框选区域 现在的数据格式应该不进行这一步，暂时保留
        if (
          this.modelData?.coverData &&
          Array.isArray(this.modelData.coverData) &&
          this.modelData.coverData.length
        ) {
          this.modelsInfo[0].polygon = [
            {
              position: this.modelData.coverData,
            },
          ];
          this.modelData.coverData.forEach((item, index) => {
            //定位到第一个点
            if (index === 0) {
              viewerGis.Scene.flyTo(item[0], 4000, 90, 0);
            }
            viewerGis.Plot.createPlot(index, {
              plotType: 'polygon',
              positions: item,
              options: {
                plotType: 'polygon',
                perPositionHeight: true,
                height: 5,
                fill: true,
                fillType: 'color',
                fillColor: '#eabebf',
                alpha: 50,
                outline: true,
                outlineColor: '#dc2e41',
                outlineWidth: 1,
                isClampToGround: false,
                stRotation: 0,
              },
            });
          });
        }
        //  标点回显，对象老格式 暂时保留老数据格式
        if (
          this.modelData !== null &&
          ((this.modelData?.lat && this.modelData?.lng) ||
            this.modelData.position)
        ) {
          let position = this.modelData;
          if (this.modelData.position) {
            position = this.modelData.position;
          }
          this.modelsInfo[0].location_gis = position;
          this.getInfo(position);
          this.latLng = { lat: position?.lat, lng: position?.lng };
          viewerGis.Marker.createMarker('point', {
            type: 'Billboard',
            position: {
              lng: position?.lng,
              lat: position?.lat,
            },
            options: {
              image: viewerGis.Image.getIconBlendImage({
                url: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/d52679369b5eefa811be37c436f50584.png',
              }),
              width: 36,
              height: 36,
            },
          });
          //定位到这个点
          viewerGis.Scene.flyTo(position, 4000, 90, 0);
        }
        //用户无数据填充时的回显
        if (
          this.modelData !== null &&
          Array.isArray(this.modelData) &&
          this.modelData.length
        ) {
          this.modelsInfo = [...this.modelData];
        }
        // 用户回显操作
        showPlot();
      }
    }

    // 监听构件选中;
    viewerGis.on(viewerGis.EventType.featureSelect, (res) => {
      if (Array.isArray(res) && res.length) {
        let models = [];
        for (let i = 0; i < res.length; i++) {
          if (i === 0) {
            models.push({
              model_id: res[i].modelId,
              dbids: [res[i].dbid],
              select: [res[i].dbid],
            });
          } else {
            const index = models.findIndex(
              (v) => v.model_id === res[i].modelId
            );
            if (index > -1) {
              if (!models[index].dbids.includes(res[i].dbid)) {
                models[index].dbids.push(res[i].dbid);
                models[index].select.push(res[i].dbid);
              }
            } else {
              models.push({
                model_id: res[i].modelId,
                dbids: [res[i].dbid],
                select: [res[i].dbid],
              });
            }
          }
        }
        if (models.length) {
          this.modelsInfo[0].model_id = models[0].model_id;
          this.modelsInfo[0].dbids = models[0].dbids;
          this.modelsInfo[0].select = models[0].select;
        }
      }
    });
  },
  beforeDestroy() {
    viewerGis.MouseCustomEvent.removeDrawing();
    viewerGis.off(viewerGis.EventType.drawPolygonByMouseCustom);
    viewerGis.off(viewerGis.EventType.drawPointByMouseCustom);
    viewerGis.off(viewerGis.EventType.drawCircleByMouseCustom);
    viewerGis.off(viewerGis.EventType.drawRectangleByMouseCustom);
  },
};
</script>
<style lang="less">
.lat-choose-cont {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .header {
    height: 48px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    .page-header {
      line-height: 47px;
      padding-left: 15px;
      border-bottom: 1px solid #dcdfe6;
    }
  }
  .el-page-header__content {
    font-size: 14px !important;
  }
  .el-page-header__left {
    color: #409eff;
    animation: fade 1500ms infinite;
    -webkit-animation: fade 1500ms infinite;
  }
  .el-input__inner {
    height: 40px !important;
    border-radius: 6px 0 0 6px;
    border-right: 0;
  }
  .search-btn {
    height: 40px;
    border-radius: 0 6px 6px 0;
    padding: 10px 18px;
  }
  @keyframes fade {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  .flex1 {
    flex: 1;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  // .singleBtn {
  //   width: 90px !important;
  // }
  .hiddenPoi,
  .hiddenCov {
    display: none !important;
  }

  .btn {
    top: 70px;
    right: 20px;
    height: 40px;
    background: #fff;
    align-items: center;
    display: flex;
    border-radius: 6px;
    width: auto;
    position: absolute;
    .marker-btn {
      margin-left: 8px;
      padding-right: 8px;
      .iconfont {
        color: #181b22;
      }
    }
    div {
      line-height: 16px;
      white-space: nowrap;
      width: 83px;
      background: #fff;
      border: none;
      padding: 0;
      color: #181b22;
      font-family: PingFang SC;
      font-size: 14px;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      gap: 6px;
      ul {
        position: absolute;
        right: -12px;
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 6px;
        background: #fff;
        top: 40px;
        box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.08),
          0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
          0px 1px 3px 0px rgba(25, 27, 35, 0.04);
        li {
          display: flex;
          width: 60px;
          height: 32px;
          padding: 0px 12px;
          align-items: center;
          color: #181b22;
          gap: 4px;
          border-radius: 4px;
          white-space: nowrap;
          overflow: hidden;
          &:hover {
            background: #f4f6f9;
          }
        }
        .active {
          background: #f4f6f9;
          color: #181b22;
        }
      }
    }
    .ponit {
      border-right: 1px dashed #c5c7d0 !important;
    }
    .hiddenBor {
      border: none !important;
    }
    .active {
      color: #387ffc;
      .iconfont {
        color: #387ffc;
      }
    }
  }
  [lang='en'] & {
    .select-btn {
      width: 120px;
    }
  }
  .zIndexUp {
    z-index: 100;
    position: absolute;
  }
  .zIndexDown {
    z-index: 0;
    position: absolute;
  }
  .choose-detail {
    left: 0px;
    top: 45px;
    background: #f5f5f5;
    z-index: 99;
    opacity: 0.8;
    p {
      padding: 5px 2px;
    }
  }
  .list-cont {
    width: 300px;
    padding-top: 50px;
  }
}
.marker-name {
  padding: 24px 20px 24px 20px;
  position: absolute;
  bottom: 24px;
  width: 380px;
  left: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.08),
    0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
    0px 1px 3px 0px rgba(25, 27, 35, 0.04);
  z-index: 1;
  .detail-address {
    flex: 1;
    margin-right: 24px;
  }
  .detail-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.point-info {
  position: absolute;
  display: flex !important;
  top: 128px;
  width: 380px;
  padding: 24px 20px 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-6, 12px);
  border-radius: var(--radius-6, 12px);
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.08),
    0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
    0px 1px 3px 0px rgba(25, 27, 35, 0.04);
  max-height: 280px;
  overflow-y: auto;
  z-index: 1;
  left: 20px;
}
.info-header {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #f0f1f4;
  &-top {
    display: flex;
    align-items: center;
  }
  .maker {
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid #e1e4ec;
    padding: 0 6px;
    white-space: nowrap;
  }
  .icon {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/da1644e4ef3b7cf46f5a26c9a1e3d09d.png);
  }
  .name {
    overflow: hidden;
    color: #181b22;
    text-overflow: ellipsis;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-left: 6px;
    white-space: nowrap;
    flex: 1;
  }
  .detail-address {
    overflow: hidden;
    color: #707786;
    text-overflow: ellipsis;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin-left: 40px;
    white-space: nowrap;
  }
  .detail-icon {
    display: flex;
    gap: 8px;
    align-items: center;
    .active {
      color: #387ffc;
    }
  }
  .marker-input {
    padding: 6px 6px 0 40px;
    width: calc(100% - 40px);

    .el-input__inner {
      height: 32px !important;
      border: 1px solid #c0c4cc;
      border-radius: 6px;
      &:focus {
        border: 1px solid #409eff;
      }
    }
  }
}

.info-desc {
  width: 100%;
  .desc {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(https://openim-1309784708.cos.ap-shanghai.myqcloud.com/6310a92661f16fcbe971a577eab2d7cd.png);
      img {
        width: 16px;
        height: 16px;
      }
    }
    .text {
      overflow: hidden;
      color: #181b22;
      text-overflow: ellipsis;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .item-container {
    margin-left: 40px;

    .item {
      line-height: 40px;
      color: #424751;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border-bottom: 1px solid #f0f1f4;
    }
  }
}
.list-itm {
  top: 440px;
  .info-header {
    height: 60px;
    overflow: unset;
  }
  .detail-desc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
<style>
.bind-search-cont {
  width: 420px;
  position: absolute;
  top: 70px;
  left: 20px;
  display: flex;
  align-items: center;
}
.bind-select-list {
  width: 420px !important;
  right: 10% !important;
}
.bind-select-list .el-select-dropdown__item {
  word-wrap: break-word !important;
  word-break: normal !important;
  height: auto;
  white-space: inherit !important;
  overflow: inherit !important;
  text-overflow: inherit !important;
  position: relative;
}
.bind-select-list .el-select-dropdown__item:hover {
  border-radius: 6px;
}
.bind-select-list .el-select-dropdown__wrap {
  padding: 8px;
}
.cesium-viewer-message {
  z-index: 100;
}
.item-desc {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: inherit;
}
.item-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-adname {
  font-family: PingFang SC;
  font-size: 12px;
  color: #a4acbd;
  white-space: nowrap;
}
.sample-img {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 350px;
}
</style>
