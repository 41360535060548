<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-05-04 10:49:35
 * @Description:
-->
<template>
  <div
    style="height: 100%;display: flex;"
    :style="!notForm && maxShowWidth ? 'max-width:' + maxShowWidth + 'px' : 'width:100%'"
  >
    <cascader
      :data-info="dataInfo"
      :default-expand="defaultExpand"
      :disabled="disabled"
      :is-check-type="isCheckType"
      :max-height="maxHeight"
      :defaultOptionChecked="defaultOptionChecked"
      :position-type="positionType"
      :show-list="value"
      :show-tree="showTree"
      :width="width"
      :optionDisabled="optionDisabled"
      @onSelectChange="onSelectChange"
      :maxCustomerWidth="maxCustomerWidth"
    />
  </div>
</template>
<script>
/* eslint-disable */
import cascader from './components/cascader'
import { dataInterface } from '@/apis/data';
export default{
  name: '',
  components: { cascader },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    // false 级联单选
    // true 级联多选
    isCheckType: {
      type: Boolean,
      default: true
    },
    subId: {
      type: [Number , String, Array],
      default: 0
    },
    /* 点击选择窗口位置 */
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 370
    },
    notForm: {
      type: Boolean,
      default: false
    },
    /* 最大的显示人的长度(form表单默认100%会撑开，不能超出隐藏，form表单下使用需要设置宽度数值) */
    maxShowWidth: {
      type: Number,
      default: 0
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: false
    },
    /* 禁用选择 */
    disabled: {
      type: Boolean,
      default: false
    },
    /* 分项id是否必传 */
    subIdNecessary: {
      type: Boolean,
      default: false
    },
    /* 禁止分部分项切换选择 */
    optionDisabled: {
      type: Boolean,
      default: false
    },
    maxCustomerWidth: {
      type: [Number, String],
      default: 0,
    },
  },
  data(){
    return {
      userInfo:{},
      archiInfo:{},
      dataInfo: null,
      showTree: true,
      defaultOptionChecked: [],
    }
  },
  watch: {
    subId: {
      handler (val) {
        if (val || (Array.isArray(val) && val.length)) {
          let checked = []
          const loopTree = (arr) => {
            arr.forEach(element => {
              if ((Array.isArray(this.subId) && this.subId.findIndex(element.origin_subitem) > -1) || +element.origin_subitem === +this.subId) {
                checked.push(element.nodeKey)
              }
              if (element.children && element.children.length) {
                loopTree(element.children)
              }
            })
          }
          if (this.dataInfo && Array.isArray(this.dataInfo) && this.dataInfo.length) {
            loopTree(this.dataInfo)
          }
          this.defaultOptionChecked = checked
        }
      },
      deep: true
    },
  },
  methods: {
    onSelectChange(arr){
      this.$emit('input', arr)
      this.$emit('change',arr)
    },
    getList() {
      this.showTree = true
      dataInterface({}, 'api/graph/18').then((res) => {
        let list = res.data.data
        let key = 1
        this.defaultOptionChecked = []
        const loopTree = (arr) => {
          arr.forEach(element => {
            if (element.option_id === 'sectional_works' && element.option === '分项工程') {
              element.nodeKey = key
              key += 1
            }
            if ((Array.isArray(this.subId) && this.subId.findIndex(element.origin_subitem) > -1) || +element.origin_subitem === +this.subId) {
              this.defaultOptionChecked.push(element.nodeKey)
            }
            if (element.wbs_child && element.wbs_child.length) {
              element.children = element.wbs_child
              if(element?.children?.length && element.nodeKey) {
                element.positionChildren = element.children
                element.children = []
              }
              delete element.wbs_child
              loopTree(element.children)
            }
          })
        }
        if (list && Array.isArray(list) && list.length) {
          loopTree(list)
        }
        this.dataInfo = list
        this.showTree = false
        console.log(list, 'list')
      })
    }
  },
  mounted () {
    this.getList()
  }
}
</script>
<style lang="less" scoped></style>
