/*
 * @Author: zx
 * @Date: 2022-07-04 14:45:31
 * @LastEditTime: 2024-08-21 09:58:51
 * @Description:
 * @LastEditors: 1028509503@qq.com 1028509503@qq.com
 */
let roleId = null
/* 获取项目下的人员处理 */
const projectListHandle = ($list, $type, companyInfo) => {
  const externalList = []
  if( !$list||!$list.length) return []
  const data = JSON.parse(JSON.stringify($list))
  let usersList = []
  data.forEach((element) => {
    const edit = ($obj) => {
      /* 项目处理函数 */
      const project_name = $obj.name
      const project_id = $obj.id
      if ($obj.extarnal && $obj.extarnal.length) {
        $obj.table_name =  $obj.name
        $obj.extarnal.forEach((item) => {
          let users = []
          const external_name = item.name
          const external_id = item.id
          if (item.sections && item.sections.length) {
            item.sections.forEach((section) => {
              if (section.users && section.users.length) {
                /* 判断roles里面有没有指定的岗位，没有则清除 */
                if (roleId && section.users?.length) {
                  for (let index = 0; index < section.users.length; index++) {
                    const element = section.users[index];
                    let isRole = false
                    if (element?.roles?.length) {
                      element.roles.forEach(item => {
                        let arr = roleId.map((ite) => {
                          return +ite
                        })
                        if (arr.indexOf(+item.roleID) > -1) {
                          isRole = true
                        }
                      });
                    }
                    if (!isRole) {
                      section.users.splice(index, 1)
                      index--;
                    }
                  }
                }
                if (companyInfo && companyInfo.company_name) {
                  section.users.forEach((user) => {
                    user.company_name = companyInfo.company_name
                    user.company_id = companyInfo.company_id
                  })
                }
                section.users.forEach((user) => {
                  user.external_name = external_name
                  user.external_id = external_id
                  user.project_name = project_name
                  user.project_id = project_id
                })
                users = users.concat(section.users)
                usersList = usersList.concat(section.users)
                section.children = section.users
              }
            })
            // item.children = item.sections
            // delete item.sections
          }
          if(item.sections){
            item.sections = item.sections.filter((ite) => ite.users?.length > 0)
            item.children = item.sections
          }

          // item.children = users
        })
        
        $obj.children = $obj.extarnal
        $obj.children = $obj.children.filter((itemz) => itemz.children?.length > 0)
        
 
        delete $obj.extarnal
        if ($obj.children && $obj.children.length) {
          externalList.push($obj)
        }
      }
      if ($obj.project && $obj.project.length) {
        $obj.project.forEach((project) => {
          edit(project)
        })
      }
    }
    if (element.extarnal && element.extarnal.length) {
      edit(element)
    }
    const externalEdit = ($list) => {
      if ($list && $list.length) {
        $list.forEach(($element) => {
          edit($element)
        })
      }
    }
    if (element.project && element.project.length) {
      externalEdit(element.project)
    }
  })
  if ($type === 'users') {
    /* 返回所有人的数组 */
    return usersList
  } else if ($type === 'section') {
    /* 返回部门的数组（部门下是人员） */
    return externalList
  }
}
/* 处理公司下的人员数据 */
const companyListHandle = ($list, $type) => {
  console.log($list);
  if(!$list || !$list.length) return []
  const data = JSON.parse(JSON.stringify($list))
  let usersList = []
  if (data && data.length) {
    data.forEach((dataItem) => {
      let childCompany = []
      let sectionList = []
      const companyEdit = ($dataItem) => {
        /* 公司本级部门处理 */
        const company_name = $dataItem.short_name || $dataItem.name
        const company_id = $dataItem.id
        if ($dataItem.sections && $dataItem.sections.length) {
          $dataItem.sections.forEach((element) => {
            if (element.users?.length) {
              /* 判断roles里面有没有指定的岗位，没有则清除 */
              if (roleId && element.users?.length) {
                for (let index = 0; index < element.users.length; index++) {
                  const obj = element.users[index];
                  let isRole = false
                  if (obj?.roles?.length) {
                    obj.roles.forEach(item => {
                      let arr = roleId.map((ite) => {
                        return +ite
                      })
                      if (arr.indexOf(+item.roleID) > -1) {
                        isRole = true
                      }
                    });
                  }
                  if (!isRole) {
                    element.users.splice(index, 1)
                    index--;
                  }
                }
              }
              element.children = element.users
              element.children.forEach((element) => {
                element.company_name = company_name
                element.company_id = company_id
              })
              // delete element.users
              sectionList.push(element)
              usersList = usersList.concat(element.children)
            }
          })
          $dataItem.sections = $dataItem.sections.filter((item) => item.children && item.children.length > 0)
        }
        /* 公司项目处理 */
        if ($dataItem.project && $dataItem.project.length) {
          $dataItem.project.forEach((element) => {
            if ($type === 'users') {
              usersList = usersList.concat(
                projectListHandle([element], $type, { company_name, company_id })
              )
            } else {
              sectionList = sectionList.concat(
                projectListHandle([element], $type, { company_name, company_id })
              )
            }
          })
        }
        /* 公司下面的子公司处理 */
        if ($dataItem.company && $dataItem.company.length) {
          /* 处理公司数据函数 */
          const companyChildEdit = (list) => {
            let newList = []
            const reChildConcat = (array) => {
              if (array && array.length) {
                array.forEach((element) => {
                  element.children = []
                  if (element.sections && element.sections.length) {
                    element.sections.forEach((item) => {
                      if (item.users && item.users.length) {
                        /* 判断roles里面有没有指定的岗位，没有则清除 */
                        if (roleId && item.users?.length) {
                          for (let index = 0; index < item.users.length; index++) {
                            const obj = item.users[index];
                            let isRole = false
                            if (obj?.roles?.length) {
                              obj.roles.forEach(objInfo => {
                                let arr = roleId.map((ite) => {
                                  return +ite
                                })
                                if (arr.indexOf(+objInfo.roleID) > -1) {
                                  isRole = true
                                }
                              });
                            }
                            if (!isRole) {
                              item.users.splice(index, 1)
                              index--;
                            }
                          }
                        }
                        item.users.forEach((user) => {
                          user.company_name = element.short_name || element.name
                          user.company_id = element.id
                        })
                        item.children = item.users
                        usersList = usersList.concat(item.children)
                      }
                    })
                    element.sections = element.sections.filter((item) => item.users.length > 0)
                    element.children = element.sections
                  }
                  if (element.project && element.project.length) {
                    const arr = projectListHandle(element.project, $type, {
                      company_name: element.short_name || element.name,
                      company_id: element.id
                    })
                    element.children = element.children.concat(arr)
                    if ($type === 'users') {
                      usersList = usersList.concat(arr)
                    }
                  }
                  if (element.company && element.company.length) {
                    reChildConcat(element.company)
                  }
                  if (element.children.length > 0) {
                    newList.push(element)
                  }
                })
              }
            }
            reChildConcat(list)
            newList = newList.filter((item) => item.children.length > 0)
            return newList
          }
          childCompany = companyChildEdit($dataItem.company)
          // sectionList = sectionList.concat(companyChildEdit($dataItem.company))
        }
      }
      companyEdit(dataItem)
      dataItem.children = sectionList
      data.push(...childCompany)
    })
  }
  if ($type === 'section') {
    return data
  } else if ($type === 'users') {
    return usersList
  }
}

const sectionListHandle = ($list,) => {
  $list?.forEach(item=>{
    item.children = item.users
  })
  return $list
}
/*
 $list:接口获取的原始数据
$archiType:数据的类型(company公司，project项目)
$type(返回数据结构（section：部门为最小级（参考人员选择组件），users：直接返回所有人员数组）)

 */
const getUserList = ($list, $archiType, $type, $roleId) => {
  roleId = null
  if ($roleId?.length) {
    roleId = $roleId
  }
  /* 项目人员接口数据 */
  if (+$archiType === 3 && $list && $list.length > 0) {
    console.log($list);
    return projectListHandle($list, $type)
  } else if (+$archiType === 2 && $list && $list.length > 0) {
    return companyListHandle($list, $type)
  }else if (+$archiType === 1 && $list && $list.length){
    let companyList=[],projectList=[],sectionList =[],arr=[]
    
    $list.forEach(item=>{
      if(item.archi_table === 'org_projects'){
        // org_projects 直接用
        let dataList = projectListHandle([item], $type)
        if(item.projects && item.projects.length){
          dataList[0].children = [...dataList[0].children , ...projectListHandle(item.projects, $type)]
        }
        arr.push(...dataList)
      }else{
        companyList = [...companyListHandle(item.child_company??item.companys, $type)]
        projectList = [...projectListHandle(item.projects, $type) ]
        
        if(item.sections&&item.sections.length){
          sectionList = [...sectionListHandle(item.sections,) ]
        }
        arr.push({archi_table:item.archi_table,parent_id:item.parent_id, name:item.name,id:item.id,short_name:item.short_name,children:[...sectionList,...companyList,...projectList]}) 
      }

    })
    console.log(arr);
    return arr
  }
}
export { getUserList }
