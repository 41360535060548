import IBehaviors from './IBehaviors';

export default class MarkerBehaviors extends IBehaviors {
    constructor(viewer, comp) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        return [
            {
                label: '[标签]删除全部标签',
                desc: `
                    参数[ 无 ]。 
                    `,
                value: 'marker_removeAllMarker',
                func: () => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Marker.removeAllMarker();
                }
            },
            {
                label: '[标签]删除指定tag的标签',
                desc: `
                    参数[ tag ]。 
                    根据传入的类型值，删除相应类型的标签对象
                    tag => 指定tag值
                    `,
                value: 'marker_removeMarkerByKeyWords',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.tag) return console.warn("[参数出错]");

                    this.viewer.Marker.removeMarkerByKeyWords(params.tag);
                }
            },
        ];
    }
}
