<template>
    <div class="main">
        <iframe v-if="isInited" :src="viewUrl" class="viewer"></iframe>
        <div v-if="!isInited" class="mask"></div>
    </div>
</template>
<script>
    import { RemoteClient } from './UEStreamClient.js';
    import { mapState } from 'vuex';
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
    import eventBus from '@/plugins/eventBus';

    let client = null;

    //UE回调池
    const callbackPool = {}
    
    export default {
        data () {
            return {
                viewHost : '',
                viewPort : '',
                isInited : false,
                UEID : null,
            }
        },
        mixins : [CustomComponentMixin , BehaviorHandlerMixin],
        computed: {
            ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
            viewUrl(){
                return `http://${this.viewHost}:${this.viewPort}`;//?AutoConnect=true&AutoPlayVideo=false&OfferToReceive=true
            }
        },
        methods : {
            initClient(opt){
                const serverHost = opt.serverHost ?? '127.0.0.1';
                const serverPort = opt.serverPort ?? "8889";
                this.viewHost = opt.viewHost ?? '127.0.0.1';
                this.viewPort = opt.viewPort ?? "2233";
                this.UEID = opt.UEID ?? 'test';

                client = new RemoteClient({
                    url : `ws://${serverHost}:${serverPort}` ,
                    UEID : this.UEID ,
                    onGetMsg : ( data )=>{
                        switch(data.type){
                            case "callback":
                                this.handleUECallback(data);
                                break;
                        }
                    },
                    onInited : ()=>{
                        console.log('[UE推流]服务连接成功')
                    }
                });
                
                this.isInited = true;
        
            },  
            //处理回调方法
            handleUECallback({ id, data }){
                if(callbackPool[id]){
                    const { func, /*type*/ } = callbackPool[id];
                    func && func(data);
                }
            },
            initEventListener(){
                eventBus.$on('UEStreamClient.getInfo', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    cb({
                        UEID : this.UEID,
                    });
                });

                eventBus.$on('UEStreamClient.getViewState',async  (eid , cb)=>{
                    if (eid !== this.element.id) return;
                    if ( !this.isInited) return;

                    const cbid = Date.now();

                    callbackPool[cbid] = {
                        id : cbid,
                        func : cb,
                        type : 'getViewState',
                    }

                    client.sendMsg({
                        type : "GetViewStateCB",
                        id : cbid,
                    });
                });

                eventBus.$on('UEStreamClient.setViewState', async (eid, state)=>{
                    if (eid !== this.element.id) return;
                    if ( !this.isInited) return;
                    
                    client.sendMsg({
                        type : "SetViewState",
                        data : JSON.stringify(state),
                    });
                });
            },
            initBehaviors(){
                this.element.behaviors = [];
                this.registerBehaviors([{
                    label : "[基础]初始化Viewer",
                    desc : `
                    `,
                    value : "init_viewer",
                    params : ['serverHost','viewPort','serverPort','UEID','viewHost'],
                    func : (params)=>{
                        console.log(params);
                        if(!params.serverHost || !params.serverPort || !params.viewHost || !params.viewPort || !params.UEID) return this.$message.error('[UE推流]初始化失败，参数错误'); 
                        this.initClient(params);
                        eventBus.$emit('UEStreamClient.initedViewer', this.element.id, {
                            UEID : this.UEID,
                        });
                    },
                },{
                    label : "[基础]推送消息",
                    desc : `
                    `,
                    value : "send_msg",
                    params : ['data'],
                    func : (params)=>{
                        if(!params.data) return this.$message.error('[UE推流]推送消息，参数错误'); 
                        
                        let msg = params.data

                        if( typeof params.data === 'string'){
                            msg = JSON.parse(params.data);
                        }
                        client.sendMsg(msg);
                    },
                },{
                    label : "[基础]推送参数消息",
                    desc : `
                        将参数作为对象推送
                    `,
                    value : "send_param_msg",
                    params : [],
                    func : (params)=>{
                        console.log(params);
                        client.sendMsg(params);
                    },
                }]);
            },
            //注册组件内事件
            registerActionConfig( id , label ){
                const element = this.element;

                if(!element.actionConfig){
                    element.actionConfig = {}
                }

                if(!element.actionConfig[id]){
                    element.actionConfig[id] = {
                        use: true,
                        useDepend: false,
                        dependLogic: [],
                        type: id,
                        name: label,
                        color: '#409EFF',
                        btnType: 'text',
                        btnSize: '',
                        margin: 5,
                        // eventList: [],
                        // permission: [], // 权限
                        // archiLimit: [], // 架构限制
                    }
                }
            },
            /**
             * 删除组件内事件
             * @param {} id
             */
            removeActionConfig(id){
                const element = this.element;

                if(!element.actionConfig){
                    element.actionConfig = {}
                }

                if(element.actionConfig[id]){
                    delete element.actionConfig[id]
                }
            },
            //触发行为
            onAction( id ){
                const ac = this.element.actionConfig;
                if(!ac[id]) return;

                const eventList = ac[id].eventList || [];

                for(let el of eventList){
                    const { pattern, eventList = [], specialEventList = []  } = el;
                    const resultList = pattern === 'special' ? specialEventList : eventList;

                    for(let ele of resultList){
                        const { effects , behaviors } = ele;
                        effects.forEach((effect) => {
                            this.$store.commit('triggerEvents', {
                                config: {
                                    ...ele,
                                ...effect
                                },
                                element: this.element,
                                EDITOR_pageUUID: this.EDITOR_pageUUID
                            });
                        });

                        behaviors.forEach(behavior => {
                            this.$store.commit('triggerEvents', {
                                config: {
                                    behavior,
                                    isBehavior: true
                                },
                                element: this.element,
                                EDITOR_pageUUID: this.EDITOR_pageUUID
                            });
                        });
                    }
                }

            },
            //设置组件暴露数据
            setResolveData( id , data){
                // 原代码
                // this.element.resolveData[id] = data;
                // 2023-09-28 修改内容
                // 拷贝resolevData
                const resolveData = {
                    ...this.element.resolveData,
                    [id]: data
                }
                this.element.resolveData = resolveData;
                // 修改store 中的内容 主要修改store
                this.$store.commit('modifyComponent', {
                    component: {
                        ...this.element,
                        resolveData: resolveData
                    },
                    containerId: null,
                    isModify: true,
                    pageUUID: this.EDITOR_pageUUID
                });
                // 触发 EventBug 请求数据 相关事件
                // TIPS 合适触发合适的问题
                eventBus.$emit('databaseTrigger', {
                    componentId: this.element.id,
                    action: 'any',
                    output: resolveData
                });
                // 2023-09-28 修改内容结束
            },
            //添加到组件暴露数据的列表
            addResolveList( uuid , name){
                this.element.UEResolveList.push({
                    uuid,
                    name,
                });
            },
        },
        mounted (){
            this.initEventListener();
            this.initBehaviors();
        },
    }
</script>
<style scoped>
    .mask{
        width : 100%;
        height : 100%;
        background-color : #656565;
    }
    .viewer{
        width : 100%;
        height : 100%;
        border: 0;
        padding: 0;
        margin: 0;
    }
</style>