<!--
    @name: TaskNode
    @description：TaskNode
    @author: ZengWei
    @date: 2022-05-13 16:35
-->
<template>
  <div class="task-node-block">
    <i class="iconfont iconxinzeng3" @click="showFormParser"></i>
    <i class="iconfont iconfujian2" @click="posAttachRemark('netdisk')"></i>
    <p class="task-title" :style="{'background-color': color}">
      {{title}}
    </p>
    <div class="task-content">
      <div class="fixed-text">
        <div class="text-item">
          <div class="left">任务内容</div>
          <i class="iconfont iconpinglun" @click="posAttachRemark('remark')"></i>
        </div>
        <el-form ref="form" class="task-form" label-width="100px" label-position="left" size="mini">
          <el-form-item>
            <span slot="label" class="form-item-label">
              <i class="iconfont iconrenwuzhuangtai"></i> 任务状态
            </span>
            <el-select
              v-if="statusType === 'hand'"
              v-model="status"
              @change="colorChange"
              placeholder="任务状态">
              <el-option
                v-for="(item,index) in statusList"
                :key="index"
                :label="item.status"
                :value="item.index + ''"
              ></el-option>
            </el-select>
            <div
              v-else
              class="task-node-text"
              :style="{'background-color': color,'color': '#fff'}">
              {{statusText}}
            </div>
          </el-form-item>
          <template v-for="(item,index) in baseInfo">
            <el-form-item v-if="item.show" :key="index">
              <span slot="label" class="form-item-label">
                <i :class="`iconfont ${prefixIcon(item.field)}`" ></i> {{item.name}}
              </span>
              <div
                v-if="!item.editable"
                class="task-node-text"
                @click.stop="showEditable(item)">
                {{(item.value || item.value === 0 || item.value === '0') ? item.value : '暂无数据' }}
              </div>
              <template v-if="item.editable && (item.value_type === 1 || item.value_type === null || item.value_type === '')">
                <select-user
                  class="select-user"
                  :key="'user'+ item.field"
                  v-if="item.field === 'targetUser'"
                  :show-choose-button="false"
                  :show-list="targetUser"
                  :visibleEmit="true"
                  position-type="left"
                  @on-select-change="onSelectUser($event,item.field)"
                >
                </select-user>

                <select-user
                  class="select-user"
                  :key="'user'+ item.field"
                  v-if="item.field === 'sendUser'"
                  :show-choose-button="false"
                  :visibleEmit="true"
                  position-type="left"
                  :show-list="sendUser"
                  @on-select-change="onSelectUser($event,item.field)"
                >
                </select-user>

                <el-date-picker
                  :ref="item.field"
                  v-if="['planStartDate','planEndDate','actualStartDate','actualEndDate'].includes(item.field)"
                  v-model="item.value"
                  :placeholder="item.name"
                  value-format="yyyy-MM-dd"
                  @change="onChange"
                  @blur="blur">
                </el-date-picker>
                <el-input v-if="item.field === 'preWarnDay'" v-model="item.value" :placeholder="item.name" @change="onChange"></el-input>
              </template>
            </el-form-item>
          </template>
          <el-form-item v-if="preWarning">
            <span slot="label" class="form-item-label">
              <i class="iconfont iconyujingtianshu"></i> 预警天数
            </span>
            <div class="task-node-text">{{preWarnDay}}</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="business-text" v-if="content.filter(t=>t.show).length">
        <el-form ref="form" class="task-form" label-width="100px" label-position="left" size="mini">
          <div class="text-item">
            <div class="left">业务内容</div>
          </div>
          <template v-for="(item,index) in content">
            <el-form-item v-if="item.show" :key="index" :label="item.name">
              <template v-if="item.value_type === 1">
                <el-input v-if="item.type === 'input'" v-model="item.value" :placeholder="item.name" @change="onChange"></el-input>
                <el-date-picker
                  v-if="item.type === 'date'"
                  v-model="item.value"
                  :placeholder="item.name"
                  value-format="yyyy-MM-dd"
                  @change="onChange"
                >
                </el-date-picker>
                <el-select
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :multiple="item.isMultiple"
                  :placeholder="item.name"
                  @change="onChange"
                  >
                  <el-option
                    v-for="(el,ind) in item.options"
                    :key="ind"
                    :label="el.label"
                    :value="el.value">
                  </el-option>
                </el-select>
                <div v-if="item.type === 'upload'">
                  <div class="task-node-text" >
                    <el-link v-for="el in item.attach" :key="el.file_md5" type="primary" @click="showAttach(el)">
                      <i class="el-icon-link"></i> {{ el.filename }}
                    </el-link>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="task-node-text" v-if="item.type !== 'upload'">
                  {{(item.value || item.value === 0 || item.value === '0') ? item.value : '暂无数据'}}
                </div>
                <div class="task-node-text" v-else>
                  <el-link type="primary" @click="showAttach(item.attach)">
                    <i class="el-icon-link"></i> 查看附件
                  </el-link>
                </div>
              </template>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {Link,Tag} from "element-ui";
import eventBus from "@/plugins/eventBus";
import selectUser from "@/custom-component/form/formParser/selectUser";
import { judgingArchiAPermission } from '@/utils/tools';

export default {
  name: "TaskNode",
  components: {
    'el-link': Link,
    'el-tag': Tag,
    selectUser
  },
  inject: ["getGraph", "getNode"],
  data() {
    return {
      userInfo: null,
      title: '任务标题',
      color: '#BFC7D8',
      status: '未开始',
      statusType: 'hand',
      statusList: [],
      targetUser: [],
      sendUser: [],
      content: [],
      baseInfo: [],
      preWarning: false,
      preWarnDay: 0,
      baseCopy: [
        {
          name: '任务责任人',
          field: 'targetUser',
          value_type: null,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '任务抄送人',
          field: 'sendUser',
          value_type: null,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '计划开始',
          field: 'planStartDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '计划完成',
          field: 'planEndDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '实际开始',
          field: 'actualStartDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        },
        {
          name: '实际完成',
          field: 'actualEndDate',
          value_type: 1,
          value: '',
          cell_block: '',
          cell_field: '',
          show: true,
          show_setting: true
        }
      ]
    }
  },
  computed: {
    prefixIcon () {
      return (field) => {
        switch (field) {
          case 'targetUser':
            return 'icongonneng-xzry';
          case 'sendUser':
            return 'icongonneng-xzry';
          case 'planStartDate':
            return 'iconjihuakaishiriqi';
          case 'planEndDate':
            return 'iconjihuawanchengriqi';
          case 'actualStartDate':
            return 'iconshijikaishiriqi';
          case 'actualEndDate':
            return 'iconshijiwanchengriqi';
        }
      }
    },
    statusText () {
      let status = this.statusList.find(t=>t.index == this.status)
      if(status === undefined) {
        status = this.statusList[0]
      }
      return status?.status || ''
    },
    checkTargetUser () {
      const index = this.targetUser.findIndex(ele => ele.user_id === this.userInfo?.id)
      const bool = (index !== -1) ? true : false
      return bool
    },
  },
  created () {
    this.userInfo = this.$GetUserInfo();
  },
  mounted() {
    const node = this.getNode()
    const data = node.getData()
    if(data){
      this.title = data.name
      if(data?.status === undefined) {
        this.status = ''
      } else {
        this.status = data?.status + ''
      }
      this.status = data?.status + '' || '未开始'
      this.statusType = data?.statusType || 'hand'
      this.statusList = data?.statusList || []
      const baseInfo = data?.baseInfo || this.baseCopy
      for (const datum of baseInfo) {
        datum.editable = false
      }
      this.baseInfo = baseInfo
      this.content = data?.content || []
      this.color = data?.color || '#BFC7D8'
      const users = data?.target_users || []
      const sendusers = data?.send_users || []
      this.targetUser = users
      this.sendUser = sendusers
      this.preWarning = data?.isPreWarn || false
      this.preWarnDay = data?.dayCount || 0
      const username = Array.from(users,t=>t.name).join(',')
      const senduser = Array.from(sendusers,t=>t.name).join(',')
      const target = this.baseInfo.find(t=>t.field === 'targetUser')
      const send = this.baseInfo.find(t=>t.field === 'sendUser')
      if(target) target.value = username
      if(send) send.value = senduser
    }
    // 监听数据改变事件
    node.on("change:data", ({ current }) => {
      this.title = current.name
      if(current?.status === undefined) {
        this.status = ''
      } else {
        this.status = current?.status + ''
      }
      this.statusType = current?.statusType || 'hand'
      this.statusList = current?.statusList || []
      this.baseInfo = current?.baseInfo || this.baseCopy
      this.content = current.content
      this.color = current?.color || '#BFC7D8'
      const users = current?.target_users|| []
      const sendusers = current?.send_users || []
      this.targetUser = users
      this.sendUser = sendusers
      this.preWarning = current?.isPreWarn || false
      this.preWarnDay = current?.dayCount || 0
      const username = Array.from(users,t=>t.name).join(',')
      const senduser = Array.from(sendusers,t=>t.name).join(',')
      const target = this.baseInfo.find(t=>t.field === 'targetUser')
      const send = this.baseInfo.find(t=>t.field === 'sendUser')
      if(target) target.value = username
      if(send) send.value = senduser
    });
  },
  methods: {
    blur(){
      for (let datum of this.baseInfo){
        datum.editable = false
      }
    },
    showEditable (item) {
      for (let datum of this.baseInfo){
        datum.editable = false
      }
      if (this.checkTargetUser || judgingArchiAPermission([], ['QuestOperation.all'])) {
        item.editable = true
        this.$nextTick(()=>{
          this.$refs?.[item.field]?.[0]?.focus()
        })
      }
    },
    onSelectUser(userData,flag){
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          user_id: item.user_id,
          name: item.name,
          avatar: item.avatar,
          user_table: item.user_table,
          section_id: item.section_id
        }
        users.push(user)
      }
      const newUserId = Array.from(users,t=>t.user_id)
      if (flag === 'targetUser') {
        const existUserId = Array.from(this.targetUser,t=>t.user_id)
        let isEqual = existUserId.length === newUserId.length && existUserId.sort().toString() === newUserId.sort().toString();
        if(!isEqual && users.length){
          this.targetUser = users
          this.onChange()
        }
      } else if (flag === 'sendUser') {
        const existUserId = Array.from(this.sendUser,t=>t.user_id)
        let isEqual = existUserId.length === newUserId.length && existUserId.sort().toString() === newUserId.sort().toString();
        if(!isEqual && users.length){
          this.sendUser = users
          this.onChange()
        }
      }
    },
    colorChange(val){
      const node = this.getNode()
      const status = this.statusList.find(t=>t.index+'' === val)
      this.status = val
      this.color = status.color
      node.setData({
        status: val,
        color: status.color
      })
      eventBus.$emit('prop-change',node)
    },
    onChange() {
      // for (const el of this.baseInfo) {
      //   el.editable = false
      // }
      const node = this.getNode()
      node.setData({
        baseInfo: this.baseInfo,
        content: this.content,
        user_type: 1,
        send_type: 1,
        target_users: this.targetUser,
        send_users: this.sendUser
      }, {deep: false})
      eventBus.$emit('prop-change',node)
    },
    showFormParser(){
      const node = this.getNode()
      const data = node.getData()
      data.id = node.id
      eventBus.$emit('open-task-form',data)
    },
    showAttach(attach){
      console.log(attach, 999)
      eventBus.$emit('open-task-attach',attach)
    },
    posAttachRemark(flag) {
      const node = this.getNode()
      eventBus.$emit('pos-task-attach',flag, node)
    }
  },
}
</script>

<style lang="less" scoped>

.task-form {
  :deep(.el-form-item ) {
    margin-top: 3px;
    margin-bottom: 0;

    .el-date-editor, .el-select {
      width: 100%;
    }
    .el-date-editor.el-input--suffix {
       .el-input__inner {
        padding-right: 0;
      }
    }
  }
}

.task-node-block{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px #e1e4e8;
  border: 1px solid #F4F6F9;
  background-color: #F4F6F9;

  /*&:hover{
    box-shadow: 0 0 5px #F2654F,
    0 0 25px #F2654F,
    0 0 50px #F2654F,
    0 0 100px #F2654F;
  }*/

  .iconxinzeng3{
    position: absolute;
    top: 8px;
    right: 30px;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }

  .iconfujian2{
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
  }

  .task-title{
    border-radius: 8px 8px 0 0;
    padding: 10px 25px 10px 10px;
    background-color: #F2654F;
    color: #ffffff;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-item-label {
    .iconfont {
      font-size: 12px;
    }
    font-size: 12px;
  }

  .task-node-text {
    text-align: right;
    padding-right: 5px;
    background-color: #F4F6F9;
    font-size: 12px;
    color: #181B22;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .task-content{
    margin-bottom: 2px;
    height: calc(100% - 43px);
    overflow: hidden;

    .fixed-text {
      margin: 10px;
      padding: 10px;
      background: #FFFFFF;
      border-radius: 5px;
    }

    .business-text {
      margin: 10px;
      padding: 10px;
      background: #FFFFFF;
      border-radius: 5px;
    }
  }

  .text-item{
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 8px;
    .left {
      font-weight: 400;
    }
  }

  :deep(.select-user) {
    width: 100%;
    .show-value {
      height: 28px;
      line-height: 28px;
      box-sizing: border-box !important;
    }
    .new-select-style{
      height: auto;
    }
  }
}
</style>
