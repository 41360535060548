<!--
 * @Author: zyf
 * @Date: 2024-08-06 11:38:27
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-23 16:25:10
 * @Descripttion: 
-->
<template>
  <div class="result-card-container">
    <div v-if="!isPaperLog" class="exam">
      <div class="time-box">
        <div class="title">
          <i class="iconfont iconform-shijian"></i>
          <span class="name">考试倒计时</span>
        </div>
        <div class="countdown">
          <span>{{ countdown }}</span>
        </div>
      </div>
      <div class="schedule">
        <div class="bar-box">
          <div :style="{'width': getBarWidth}" class="active-bar"></div>
        </div>
        <div class="question-count">
          <span class="finish">已完成 {{finishCount}} 题</span>
          <span class="total">共 {{queslist.length}} 题</span>
        </div>
      </div>
    </div>
    <div v-else class="analysis">
      <div class="score-box">
        <div class="score" :class="isPass ? 'pass' : 'no-pass'">
          <span class="title">{{paperData?.[safetyKeyConfig?.score] || 0}}</span>
          <span class="label">得分</span>
        </div>
      </div>
      <div class="score-situation">
        <div class="situation-item">
          <div class="result correct">{{correctCount}}题</div>
          <div class="name">答对</div>
        </div>
        <div class="situation-item">
          <div class="result error">{{errorCount}}题</div>
          <div class="name">答错</div>
        </div>
        <div class="situation-item">
          <div class="result" :class="isPass ? 'correct' : 'error'">{{isPass ? '合格' : '不合格'}}</div>
          <div class="name">考试判定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
	name: 'Result',
  components: {
  },
	props: {
		// 考试状态
    isPaperLog:{
      type: Boolean,
      default: false
    },
    paperData:{
      type: Object,
      default: ()=>{
        return {};
      }
    },
    queslist:{
      type: Array,
      default: ()=>{
        return []
      }
    },
    finishCount:{
      type: Number,
      default: 0
    },
    cacheTime:{
      type: [String,Number],
      default: 0
    },
    exitType:{
      type: Number,
      default: 1
    },
    correctCount:{
      type: Number,
      default: 0
    },
    errorCount:{
      type: Number,
      default: 0
    },
    element: {
      type: Object,
      default: () => {}
    },
    totalScore:{
      type: Number,
      default: 0
    }
	},
  data() {
    return {
      countdown: '00:00:00',
      seconds: 0,
      timer: null,
      allQuestion:[],
    }
  },
	computed: {
    safetyKeyConfig() {
      return this.element?.safetyKeyConfig || {};
		},
		getBarWidth() {
			if(this.queslist.length === 0){
        return '0px';
      }
      return `${(this.finishCount / this.queslist.length) * 100}%`;
		},
    getPassScore(){
      const passCondition = this.paperData?.[this.safetyKeyConfig?.passCondition] ? this.paperData?.[this.safetyKeyConfig?.passCondition] : +this.paperData?.pass_condition;
      if(+passCondition === 1){
        return +this.paperData?.[this.safetyKeyConfig?.passScore]; 
      }else if(+passCondition === 2){
        return +this.totalScore;
      }
      return 0
    },
    isPass(){
      return +this.paperData?.[this.safetyKeyConfig?.score] >= this.getPassScore;
    },
	},
  created(){
    this.allQuestion = this.queslist;
  },
  mounted(){
    if(!this.isPaperLog && this.paperData?.[this.safetyKeyConfig?.timeLength]){
      this.startTimer();
    }
  },
  methods:{
    isNumber(value){
      return !isNaN(Number(value)) && isFinite(value);
    },
    updateCountdown(diff){
      if (diff <= 0) {  
        // 如果倒计时结束，停止计时器  
        this.countdown = '00:00:00';
        this.stopTimer();
        this.$emit('finishExam')
        return;
      }  
      // 计算小时、分钟和秒  
      const hours = Math.floor(diff / 3600);  
      const minutes = Math.floor((diff % 3600) / 60);  
      const seconds = Math.floor(diff % 60); 
  
      // 格式化并更新倒计时  
      this.countdown = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`; 
    },
    startTimer(){
      this.seconds = parseInt(this.paperData?.[this.safetyKeyConfig?.timeLength]) * 60;
      if(+this.exitType === 1 && this.cacheTime){
        this.seconds = this.cacheTime;
      }
      this.updateCountdown(this.seconds);
      this.timer = setInterval(()=>{
        this.seconds = this.seconds - 1;
        this.updateCountdown(this.seconds);
      },1000)
    },
    stopTimer(){
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
  },
  beforeDestroy() {
		this.stopTimer();
	}
};
</script>

<style lang="less" scoped>
.result-card-container{
  width: 100%;
  height: 100%;
  .exam{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 24px 32px 24px;
    box-sizing: border-box;
    overflow: hidden;
    .time-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title{
        display: flex;
        align-items: center;
        gap: 6px;
        i{
          font-size: 16px;
          color: #596058;
        }
        .name{
          color: #596058;
          text-align: center;
          font-family: "MiSans VF";
          font-size: 13px;
          font-style: normal;
          font-weight: 330;
          line-height: 20px;
        }
      }
      .countdown{
        color: #4DC077;
        text-align: center;
        font-family: "MiSans VF";
        font-size: 24px;
        font-style: normal;
        font-weight: 450;
        line-height: 32px;
        padding-top: 8px;
        box-sizing: border-box;
      }
    }
    .schedule{
      width: 100%;
      .bar-box{
        width: 100%;
        height: 8px;
        border-radius: 6px;
        background: #E9FBEF;
        position: relative;
        .active-bar{
          position: absolute;
          left: 0;
          top: 0;
          background: #4DC077;
          height: 8px;
          border-radius: 6px;
        }
      }
      .question-count{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 8px;
        box-sizing: border-box;
        span{
          font-family: "MiSans VF";
          font-size: 13px;
          font-style: normal;
          font-weight: 305;
          line-height: 20px;
          display: block;
        }
        .finish{
          color: #2FA45A;
        }
        .total{
          color: #596058;
        }
      }
    }
  }
  .analysis{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .score-box{
      flex: 1;
      width: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      .score{
        width: 106px;
        height: 86px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title{
          color: #FFF;
          text-align: center;
          font-family: "MiSans VF";
          font-size: 32px;
          font-style: normal;
          font-weight: 450;
          line-height: 40px;
        }
        .label{
          color: #FFF;
          text-align: center;
          font-family: "MiSans VF";
          font-size: 13px;
          font-style: normal;
          font-weight: 330;
          line-height: 20px;
        }
      }
      .pass{
        background: linear-gradient(180deg, rgba(77, 77, 77, 0.00) 0%, rgba(77, 77, 77, 0.40) 100%), var(--semantic-positive-border, #4DC077);
        background-blend-mode: overlay, normal;
      }
      .no-pass{
        background: linear-gradient(180deg, rgba(77, 77, 77, 0.00) 0%, rgba(77, 77, 77, 0.90) 100%), var(--semantic-warning-border, #FFA364);
        background-blend-mode: overlay, normal;
      }
    }
    .score-situation{
      width: 100%;
      height: 82px;
      border-top: 1px solid #F2F5FA;
      padding: 0 40px;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .situation-item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .result{
          text-align: center;
          font-family: "MiSans VF";
          font-size: 14px;
          font-style: normal;
          font-weight: 380;
          line-height: 22px;
        }
        .correct{
          color: #4DC077;
        }
        .error{
          color: #FD7823;
        }
        .name{
          color: #707786;
          text-align: center;
          font-family: "MiSans VF";
          font-size: 13px;
          font-style: normal;
          font-weight: 330;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
