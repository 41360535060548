<!--
 * @Description: 总进度曲线
 * @Author: luocheng
 * @Date: 2022-06-01 10:36:53
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-12 10:20:21
-->
<template>
  <div class="common-overall-progress">
    <div class="eject-icon" @click.stop="dialogVisible = !dialogVisible">
			<i class="iconfont icondaping-quanping" />
		</div>
    <!-- 筛选 -->
    <el-form :model="filterForm" class="overall-filter" :inline="true">
      <el-form-item>
        <el-select
          v-model="filterForm.typeId"
          placeholder="请选择计划类别"
          style="width: 120px; margin-right: 0px"
          @change="getPlanList"
          size="mini"
        >
          <el-option
            v-for="item in typeData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="filterForm.listId"
          placeholder="请选择计划"
          style="width: 140px"
          @change="getPlan"
          size="mini"
        >
          <el-option
            v-for="item in listData"
            :key="item.id"
            :label="item.name+'/'+item.plan_edition"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 图表 -->
    <ChartContainer
      v-if="option"
			ref="chartInstance"
			class="chart-bar"
			:option="option"
    ></ChartContainer>
    <el-dialog
			:visible.sync="dialogVisible"
			title=""
			:modal="false"
			:append-to-body="true"
			custom-class="chart-container-dialog"
			width="60%"
		>
      <div class="common-overall-progress" style="height: 600px">
        <!-- 筛选 -->
        <el-form :model="filterForm" class="overall-filter" :inline="true">
          <el-form-item>
            <el-select
              v-model="filterForm.typeId"
              placeholder="请选择计划类别"
              style="width: 120px; margin-right: 0px"
              @change="getPlanList"
              size="mini"
            >
              <el-option
                v-for="item in typeData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="filterForm.listId"
              placeholder="请选择计划"
              style="width: 140px"
              @change="getPlan"
              size="mini"
            >
              <el-option
                v-for="item in listData"
                :key="item.id"
                :label="item.name+'/'+item.plan_edition"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 图表 -->
        <ChartContainer
          v-if="option"
          ref="chartInstance"
          class="chart-bar"
          :option="option"
        ></ChartContainer>
      </div>
		</el-dialog>
  </div>
</template>

<script>
import ChartContainer from '@/chart-components/components/common/ChartContainer';
import eventBus from '@/plugins/eventBus';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
// import { getQueryValue, getComponentById } from '@/utils/tools';
import { dataInterface } from '@/apis/data';
import { Dialog } from 'element-ui';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';

export default {
  name: 'OverallFilter',
  mixins: [CustomComponentMixin, databaseTriggerMixin],
  components: {
    ChartContainer,
		'el-dialog': Dialog
  },
  data() {
    return {
      option: null,
      typeData: [],
      listData: [],
      filterForm: {
        typeId: '',
        listId: ''
      },
      colorArr: ['#1A73E8', '#FF8C00', '#8B8682', '#ff3333', '#FFD700', '#836FFF'], // 颜色数组
      paramsResult: {
        param: {},
        search: [],
        canPost: true
      },
			dialogVisible: false
    }
  },
  computed: {
    // 组件属性
		statusConfig() {
			return this.element?.statusConfig;
		},
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    }
	},
  mounted() {
    this.paramsResult = this.initParams(this.paramsConfig || []);
    this.getPlanTypes();
    const databaseTrigger = {
      [this.$elementId]: config => {
        if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
          return;
        }
        this.paramsResult = this.initParams(this.paramsConfig || []);
        this.option = null;
        this.typeData = [];
        this.listData = [];
        this.filterForm = {
          typeId: '',
          listId: ''
        };
        // 获取数据
        this.getPlanTypes();
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
	},
  methods: {
    /**
     * @desc: 获取计划类别
     */
    getPlanTypes() {
      const { param = {}, search = [], canPost = true } = this.paramsResult;
      if (!canPost) return;
      dataInterface({
        // archi_type: this.$GetTargetArchi()?.id, // 测试，正式由参数传入
        object_uuid: 'object61a89029d0dc3',
        view_uuid: 'view61a892c2b6e1a',
        __method_name__: 'dataList',
        search,
        ...param,
        transcode: 0
      }).then(res => {
        this.typeData = res?.data?.data?.data || [];
        this.filterForm.typeId = this.typeData?.[0].id;
        this.getPlanList();
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 获取计划列表
     */
    getPlanList() {
      this.listData = [];
      if (!this.filterForm.typeId) return;
      const { param = {}, search = [], canPost = true } = this.paramsResult;
      if (!canPost) return;
      dataInterface({
        size: 1000,
        type_id: this.filterForm.typeId,
        __method_name__: 'dataList',
        view_uuid: 'view61d552a955ca0',
        object_uuid: 'object61a973b39cf96',
        search,
        ...param,
        transcode: 0
      }).then(res => {
        this.listData = res.data?.data || [];
        this.filterForm.listId = this.listData?.[0].id;
        this.getOption();
      }).catch(err => {
        console.log(err);
      });
    },
    /**
     * @desc: 获取配置
     */
    async getOption() {
      let result = {};
      const dataOne = await this.getData({
        schedule_id: this.filterForm.listId,
        type: 1
      });
      dataOne.forEach((ele, index) => {
        result = Object.assign(result, { [`${ele.schedule_name}${index ? index : ''}`]: ele.schedule_list })
      });
      const dataTwo = await this.getData({
        schedule_id: this.filterForm.listId,
        type: 2
      });
      if (dataTwo.length) {
        result = Object.assign(result, { 实际进度: dataTwo })
      }
      this.buildData(result);
    },
    /**
     * @desc: 获取统计数据
     */
    getData(fixParams) {
      const { param = {}, search = [], canPost = true } = this.paramsResult;
      if (!canPost) return [];
      return new Promise((resolve, reject) => {
        dataInterface({
          ...fixParams,
          ...param,
          search
        }, 'api/fourSchedule/countMoney', 'GET').then(res => {
          resolve(res?.data?.data || [])
        }).catch(err => {
          console.log(err);
          reject([]);
        })
      });
    },
    /**
     * @desc: 获取计划
     */
    getPlan() {},
    /**
     * @desc: 构建数据
     */
    buildData(data) {
      // 销毁echart
      const rangeX = []
      const seriesList = []
      let n = 0
      for (var i in data) {
        if (i !== '实际进度') {
          n++
        }
        const item = data[i]
        const innerData = []
        if (item.length !== 0) {
          item.forEach((item) => {
            innerData.push(item.rate * 100)
            rangeX.push(item.date)
            // 日期升序排序
            rangeX.sort(function (a, b) {
              return Date.parse(a) - Date.parse(b)
            })
          })
        }
        const obj = {
          name: i,
          type: 'line',
          data: innerData,
          smooth: true,
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            normal: {
              color: i === '实际进度' ? 'green' : this.colorArr[n - 1],
              lineStyle: {
                width: i === '实际进度' ? 3 : 1.5,
                type: i === '实际进度' ? 'solid' : 'dotted', // 'dotted'虚线 'solid'实线
                color: i === '实际进度' ? 'green' : this.colorArr[n - 1]
              }
            }
          }
        }
        seriesList.push(obj)
      }
      // 绘制图表
      this.option = {
        animationDuration: 3000,
        title: { align: 'center' },
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            let str = params[0].axisValueLabel + '<br/>'
            for (let i = 0; i < params.length; i++) {
              str += '<div style="margin: 0px 0 0;line-height:1;">' +
              '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' + params[i].color + '"></span>' +
              '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' + params[i].seriesName + '</span>' +
              '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + (params[i].value).toFixed(2) + '%</span>' +
              '<div style="clear:both"></div>' +
              '</div>'
            }
            return str
          }
        },
        legend: {
          width: 400,
          top: 0,
          right: 20,
          bottom: 20,
          textStyle: {
            color: '#ffffff'
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: Array.from(new Set(rangeX)),
          nameTextStyle: {
            color: '#ffffff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}' + '%'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#939191'
            }
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#ffffff'
            }
          }
        },
        grid: {
          top: 50,
          right: 30,
          bottom: 30
        },
        series: seriesList
      };
    }
  }
}
</script>

<style lang="less" scoped>
.common-overall-progress{
  height: 100%;
  width: 100%;
  position: relative;
  &:hover{
		.eject-icon{
			visibility: visible;
		}
	}
	.eject-icon{
		visibility: hidden;
		position: absolute;
		top: 8px;
		right: 5px;
		width: 16px;
		height: 16px;
		z-index: 1000;
    color: #eee;
		&:hover{
			color: #409eff;
		}
	}
  .overall-filter{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3000;
    :deep(.el-select) {
      width: auto;
      .el-input{
        width: 140px;
        display: inline-block;
      }
      .el-input__inner{
        background: transparent;
        border: none;
        color: #fff;
      }
      i {
        color: #fff;
      }
    }
  }
}
</style>
