import { render, staticRenderFns } from "./viewerBIMScene.vue?vue&type=template&id=faed308e&scoped=true"
import script from "./viewerBIMScene.vue?vue&type=script&lang=js"
export * from "./viewerBIMScene.vue?vue&type=script&lang=js"
import style0 from "./viewerBIMScene.vue?vue&type=style&index=0&id=faed308e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faed308e",
  null
  
)

export default component.exports