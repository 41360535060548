
<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-15 13:57:29
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2024-03-29 10:19:04
 * @FilePath: \dataview-next\src\custom-component\video\player\EzuiFullPlayer.vue
 * @Description: 萤石云播放器组件
-->
<template>
  <div ref="player" v-loading="loading" class="ezui-full-player-container">
    <div ref="vidoeWarp" class="video-container" v-ezuiDirectives="options"></div>
    <div v-show="config.showControl" class="action">
      <Control 
        :data="data"
        :dataRange="dataRange"
        :playing="playing"
        :isRecording="saveing"
        :isfull="isfull"
        :intelligibility="intelligibility"
        :showSave="true"
        :element="element"
        :rate="rate"
        @play="play"
        @stop="stop"
        @capturePicture="capturePicture"
        @record="record"
        @fullScreen="fullScreen"
        @ptzvisibleChange="handlePtzvisibleChange"
        @changeIntelligibility="handleChangeIntelligibility"
        @playbackRange="handlePlaybackRangeChange"
        @playbackType="handlePlaybackType"
        @changeRate="changeRate"
      />
    </div>
    <Rocker :voideData="data" v-if="showPTZ" @handleAction="handlePTZevent" />
  </div>
</template>

<script>
/**
 * @desc 萤石云播放器完整版本, 采用指令来进行播放，这样可以简化dom的操作，进行分别设置是为了上层适配，提高可阅读性
 */
import EZUIKit from 'ezuikit-js';
import axios from 'axios';
import Control from './Control.vue';
import Rocker from './Rocker.vue';
import { format } from "date-fns";

// 处理盒子大小改变
const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if(entry.target.player && entry.contentRect.width && entry.contentRect.height) {
      try{
        // 播放由canvas完成，所以大小改变必须触发内部的reSize事件
        entry.target.player.reSize(entry.contentRect.width, entry.contentRect.height);
      } catch (err) {
        // 第一次回调会早于初始化完成 这里是为捕获第一次的报错
      }
    }
  }
});

export default {
  name: "EzuiFullPlayer",
  components: { Control, Rocker },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
      require: true
    },
    config: {
      type: Object,
      default () {
        return {}
      }
    },
    element: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      player: null,
      playing: false,
      saveing: false,
      isfull: false,
      showPTZ: false,
      intelligibility: 0, // 0 标清 1 高清
      dataRange: null,
      loading: false,
      playBackType: '',
      rate: '1'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.player.onfullscreenchange = () => {
        this.isfull = !this.isfull;
      }
    })
  },
  computed: {
    // 配置信息
    options() {
      return {
        accessToken: this.data.accessToken,
        channel_id: this.data.channel_id,
        device_serial: this.data.device_serial,
        intelligibility: this.intelligibility, // 清晰度
        playBackType: this.playBackType,
        dataRange: this.dataRange, // 回放时间段
        setPlayer: this.setPlayer.bind(this), // 用于设置指令中实例中的player
        setLoading: this.changeLoading.bind(this)
      }
    },
  },
  directives: {
    /**
     * @description: 萤石云指令
     */
    ezuiDirectives: {
      inserted: (el, binding) => {
        // ezuikit-js只支持id
        const id = 'ezuiplayer' + Math.floor(Math.random() * 1000).toString(16);
        // 这里是因为极简模式下会改变dom结构，导致盒子监听存在异常， 所以采用包裹
        const warp = document.createElement('div');
        warp.id = id;
        el.appendChild(warp);
        resizeObserver.observe(el);
        const intelligibility = binding.value.intelligibility ? '/hd' : '';
        const player = new EZUIKit.EZUIKitPlayer({
          id: id, // 视频容器ID
          accessToken: binding.value.accessToken,
          url: `ezopen://open.ys7.com/${binding.value.device_serial}/${binding.value.channel_id}${intelligibility}.live`,
          // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
          template: 'simple',
          plugin: [], // 加载插件，talk-对讲
          width: el.offsetWidth,
          height: el.offsetHeight,
          handleSuccess() {
            player.playing = true;
            binding.value.setPlayer(player);
          },
          handleError() {
            player.playing = false;
            binding.value.setPlayer(player);
          },
          startSaveCallBack() {
            player.saveing = true;
            binding.value.setPlayer(player);
          },
          stopSaveCallBack() {
            player.saveing = false;
            binding.value.setPlayer(player);
          },
          capturePictureCallBack(data) {
            console.log(data);
          }
        })
        el.player = player;
        binding.value.setPlayer(player);
      },
      update: (el, binding) => {
        const oldValue = binding.oldValue;
        const value = binding.value;
        const key = ['accessToken', 'channel_id', 'device_serial', 'intelligibility', 'dataRange', 'playBackType'];
        const diff = key.some(el => {
          return !Object.is(oldValue[el], value[el]);
        })
        if(!diff) return;
        if(el.player) {
          const setLoading = binding.value.setLoading;
          el.player.stop().then(() => {
            const intelligibility = binding.value.intelligibility ? '.hd' : '';
            const dateRange = binding.value?.dataRange;
            if(Array.isArray(dateRange) && dateRange.length) {
              const s = format(dateRange[0], 'yyyyMMddHHmmss');
              const e = format(dateRange[1], 'yyyyMMddHHmmss');
              const source = binding.value?.playBackType === 'cloud' ? '.cloud' : '';
              // 回放模式
              setLoading(true);
              el.player.play({
                url: `ezopen://open.ys7.com/${binding.value.device_serial}/${binding.value.channel_id}${intelligibility}${source}.rec?begin=${s}&end=${e}`,
              }).then(()=>{}).catch(() => {
                console.log('当前时间段无回放片段');
              }).finally(() => {
                setLoading(false);
              })
            } else {
              setLoading(true);
              el.player.play({
                url: `ezopen://open.ys7.com/${binding.value.device_serial}/${binding.value.channel_id}${intelligibility}.live`,
              }).then(() => {

              }).catch(() => {

              }).finally(() => {
                setLoading(false);
              })
            }
          })
        }
      },
      unbind: (el) => {
        // 取消盒子大小监听
        resizeObserver.unobserve(el);
        if(el.player) {
          el.player.stop().then(() => {
            el.player.destroy();
          })
        }
      }
    }
  },
  methods: {
    changeLoading(v) {
      this.loading = v;
    },
    handlePlaybackRangeChange(dateRange) {
      this.dataRange = dateRange;
    },
    handlePlaybackType(type) {
      this.playBackType = type;
    },
    handleChangeIntelligibility(v) {
      console.log(v)
      this.intelligibility = Number(v);
    },
    handlePtzvisibleChange(v) {
      this.showPTZ = v;
    },
    setPlayer(install) {
      this.player = install;
      this.playing = this.player && this.player.playing;
      this.saveing = this.player && this.player.saveing;
    },
    // 播放
    play() {
      const playPromise = this.player.play();
      playPromise.then(() => {
        this.playing = this.player.playing = true;
      })
    },
    // 暂停
    stop() {
      const stopPromise = this.player.stop();
      stopPromise.then(() => {
        this.playing = this.player.playing = false;
      })
    },
    // 抓拍
    capturePicture() {
      // 以下是本地抓拍
      // const capturePicturePromise = this.player.capturePicture(`${new Date().getTime()}`);
      // capturePicturePromise.then((data) => {
      //   console.log("抓拍", data)
      // })
      // 以下是接口抓拍
      const data = {
        accessToken: this.options.accessToken,
        deviceSerial: this.options.device_serial,
        channelNo: this.options.channel_id,
        quality: 1
      }
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      axios.post('https://open.ys7.com/api/lapp/device/capture', REQDATA).then(res => {
        // console.log(res);
        if (+res.data.code === 200) {
          this.$emit('docapturePicture', {
            device_serial: this.options.device_serial,
            screenshot_picture: [
              {
                filename: `${this.data.monitor_name}.jpg`,
                fileext: 'jpg',
                filepath: res.data.data.picUrl,
                filesize: 0,
                filetype: 'image/jpeg',
                path: res.data.data.picUrl,
                url: res.data.data.picUrl
              }
            ],
            monitor_name: this.data.monitor_name,
            monitor_id: this.data.monitor_id
          })
        }
      })
    },
    // 打开声音
    openSound() {
      const openSoundPromise = this.player.openSound();
      openSoundPromise.then(() => {
      })
    },
    // 关闭声音
    closeSound() {
      const openSoundPromise = this.player.closeSound();
      openSoundPromise.then(() => {
      })
    },
    record() {
      if(this.saveing) {
        this.stopSave();
      } else {
        this.startSave();
      }
    },
    // 开始保存
    startSave() {
      const startSavePromise = this.player.startSave(`${new Date().getTime()}`);
      startSavePromise.then((data) => {
        console.log("开始保存", data);
      })
    },
    // 停止保存
    stopSave() {
      const stopSavePromise = this.player.stopSave();
      stopSavePromise.then((data) => {
        console.log("停止保存", data);
      })
    },
    // 开始对话
    ezopenStartTalk() {
      this.player.startTalk();
    },
    // 结束对话
    ezopenStopTalk() {
      this.player.stopTalk();
    },
    // 全屏
    fullScreen() {
      if (this.isfull) {
        document.exitFullscreen();
      } else {
        this.$refs.player.requestFullscreen();
      }
    },
    /**
     * @description: 改变速率
     * @param {*} v
     * @return {*}
     */    
    changeRate(v) {
      const availableRate = [1, 2, 4];
      const oldVlaue = this.rate;
      this.rate = v;
      if(availableRate.includes(Number(v))) {
        this.player.fast(Number(v)).then(() => {
          console.log('fast', v)
        }).catch(() => {
          this.rate = oldVlaue;
        })
      }
    },
    // 销毁
    destroy() {
      const destroyPromise = this.player.destroy();
      destroyPromise.then((data) => {
        console.log("销毁", data);
      })
    },
    // 摇杆事件
    handlePTZevent(instruct) {
      // 一秒触发一次
      if(this.PTZTimer) return;
      this.PTZMove(instruct);
      this.PTZTimer = setTimeout(() => {
        this.PTZTimer = null;
      }, 1000)
    },
    PTZMove(type) {
      const data = {
        accessToken: this.options.accessToken,
        deviceSerial: this.options.device_serial,
        channelNo: this.options.channel_id,
        direction: -1,
        speed: 1 // 0-慢，1-适中，2-快
      }
      // 操作命令：0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距
      switch(type) {
        case 'up': {
          data.direction = 0;
          break
        }
        case 'down': {
          data.direction = 1;
          break
        }
        case 'left': {
          data.direction = 2;
          break
        }
        case 'right': {
          data.direction = 3;
          break
        }
        case 'right-top': {
          data.direction = 6;
          break
        }
        case 'right-bottom': {
          data.direction = 7;
          break
        }
        case 'left-top': {
          data.direction = 4;
          break
        }
        case 'left-bottom': {
          data.direction = 5;
          break
        }
        case 'scaleup': {
          data.direction = 8;
          break
        }
        case 'scaledown': {
          data.direction = 9;
          break
        }
      }
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      axios.post('https://open.ys7.com/api/lapp/device/ptz/start', REQDATA).then(res => {
        if(+res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.PTZStop(data.direction);
        }
      })
    },
    PTZStop(direction) {
      const data = {
        accessToken: this.options.accessToken,
        deviceSerial: this.options.device_serial,
        channelNo: this.options.channel_id,
        direction: direction,
        speed: 1
      }
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      axios.post('https://open.ys7.com/api/lapp/device/ptz/stop', REQDATA).then(res => {
        console.log(res, 'res-stop');
        this.oprateFlag = false;
      })
    },
    // 下面三个方法暂时不用
    PTZPresetAdd() {
      const data = {
        accessToken: this.options.accessToken,
        deviceSerial: this.options.device_serial,
        channelNo: this.options.channel_id,
      };
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      axios.post('https://open.ys7.com/api/lapp/device/preset/add', REQDATA).then(res => {
        console.log(res, 'res-stop')
        this.oprateFlag = false;
      });
    },
    PTZPresetMove() {
      const data = {
        accessToken: this.options.accessToken,
        deviceSerial: this.options.device_serial,
        channelNo: this.options.channel_id,
        index: ''
      };
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      axios.post('https://open.ys7.com/api/lapp/device/preset/move', REQDATA).then(res => {
        console.log(res, 'res-stop');
        this.oprateFlag = false;
      })
    },
    PTZPresetClear() {
      const data = {
        accessToken: this.options.accessToken,
        deviceSerial: this.options.device_serial,
        channelNo: this.options.channel_id,
        index: ''
      };
      const REQDATA = new FormData();
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          REQDATA.append(key, data[key]);
        }
      }
      axios.post('https://open.ys7.com/api/lapp/device/preset/clear', REQDATA).then(res => {
        console.log(res, 'res-stop');
        this.oprateFlag = false;
      })
    }
  }
};
</script>

<style lang="less" scoped>
  .ezui-full-player-container{
    position: relative;
    width: 100%;
    height: 100%;
    .video-container{
      width: 100%;
      height: 100%;
    }
    .video-model{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .action{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    :deep(.ptz-container) {
      position: absolute;
      right: 10px;
      bottom: 60px;
      width: 160px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
