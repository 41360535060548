<template>
  <div class="logic-wrap">
    <el-tree
      class="logic-tree-wrap"
      :data="element.value"
      :expand-on-click-node="false"
      :default-expand-all="true"
      node-key="id"
    >
      <div class="logic-item" slot-scope="{ node, data }">
        <div v-if="data.type ==='logic'" class="logic">
          <el-select v-model="data.logicType" :disabled="!readonly" class="logic-type-select" size="mini" placeholder="请选择...">
            <el-option value="and" label="且：全部成立才可"></el-option>
            <el-option value="or" label="或：有一个成立即可"></el-option>
          </el-select>
          <div v-if="readonly" class="actions">
            <el-button type="text" size="mini" @click="addItem('logic', data)">
              <svg t="1720770140620" class="icon" viewBox="0 0 1091 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="94654" width="16" height="16"><path d="M189.537249 172.207675m-26.087069 0a26.087069 26.087069 0 1 0 52.174137 0 26.087069 26.087069 0 1 0-52.174137 0Z" fill="#1296db" p-id="94655"></path><path d="M209.185104 1023.669784a24.601096 24.601096 0 0 1-16.510803-43.58852c1.981296-1.65108 202.587552-176.665592 202.587553-603.469848 0-165.10803 40.781683-279.03257 121.354401-336.490165C611.71848-27.242825 724.487265 10.236698 729.275398 11.887778a24.766204 24.766204 0 0 1-16.510803 46.725573c-1.65108 0-93.946469-30.544985-167.749758 22.289584-66.043212 47.716221-100.220574 147.44147-100.220575 296.038697 0 451.570461-211.338278 633.849726-220.254111 641.279587a24.105772 24.105772 0 0 1-15.355047 5.448565z" fill="#1296db" p-id="94656"></path><path d="M575.394714 365.38407H280.346665a24.766204 24.766204 0 0 1 0-49.532409H575.394714a24.766204 24.766204 0 0 1 0 49.532409zM692.621415 886.465011a24.601096 24.601096 0 0 1-21.464044-12.713318c-176.170268-316.512093 55.146082-555.753628 57.457594-558.230248a24.766204 24.766204 0 1 1 35.168011 34.837794c-8.585618 8.585618-207.210577 215.796195-49.532409 499.286682a24.931312 24.931312 0 0 1-9.576266 33.021606 24.27088 24.27088 0 0 1-12.052886 3.797484zM974.956146 886.465011a25.261529 25.261529 0 0 1-12.052886-3.137052 24.601096 24.601096 0 0 1-9.411158-33.021606C1112.986459 561.367301 1007.977752 346.726862 1005.996455 344.250242a24.766204 24.766204 0 0 1 43.918736-22.6198c4.953241 9.741374 119.703322 240.232183-53.825217 552.121251a24.601096 24.601096 0 0 1-21.133828 12.713318zM25.584975 913.872944a24.27088 24.27088 0 0 1-5.943889 0A24.766204 24.766204 0 0 1 0.818771 883.327959l136.709448-561.367301a24.601096 24.601096 0 1 1 47.881329 11.72267L50.35118 895.050629a24.766204 24.766204 0 0 1-24.766205 18.822315z" fill="#1296db" p-id="94657"></path></svg>
              逻辑
            </el-button>
            <el-button type="text" size="mini" @click="addItem('condition', data)">
              <svg t="1720770278513" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="100051" width="16" height="16"><path d="M716.8 80c0 14.4-4.8 28.8-14.4 40-9.6 11.2-22.4 17.6-36.8 17.6-14.4 0-25.6-4.8-32-14.4-6.4-9.6-12.8-25.6-20.8-48-1.6-6.4-4.8-14.4-9.6-19.2-3.2-6.4-8-8-16-8s-16 4.8-20.8 11.2c-4.8 9.6-9.6 19.2-11.2 30.4L494.4 304h84.8l-14.4 49.6H480l-88 310.4c-24 81.6-44.8 144-64 190.4-17.6 44.8-44.8 84.8-80 120C212.8 1008 169.6 1024 118.4 1024c-36.8 0-67.2-8-86.4-27.2-22.4-17.6-32-36.8-32-59.2 0-16 4.8-32 16-43.2 11.2-12.8 25.6-19.2 41.6-19.2 20.8 0 33.6 6.4 40 17.6 6.4 11.2 11.2 27.2 12.8 46.4 3.2 24 12.8 36.8 30.4 36.8 17.6 0 32-9.6 43.2-30.4 11.2-20.8 22.4-52.8 33.6-94.4l142.4-496h-89.6l14.4-49.6h89.6l9.6-35.2c12.8-49.6 27.2-92.8 43.2-129.6 16-36.8 40-68.8 70.4-97.6C528 14.4 568 0 614.4 0c17.6 0 33.6 3.2 49.6 9.6 16 6.4 28.8 16 38.4 27.2 11.2 11.2 14.4 25.6 14.4 43.2zM1024 532.8c0 22.4-3.2 46.4-9.6 67.2h-48c6.4-22.4 9.6-44.8 9.6-68.8 0-4.8-1.6-11.2-4.8-14.4-3.2-4.8-8-8-14.4-8-4.8 0-9.6 1.6-14.4 4.8-3.2 3.2-12.8 12.8-28.8 30.4l-94.4 107.2 43.2 144c4.8 16 9.6 28.8 14.4 38.4 4.8 9.6 11.2 16 17.6 16 11.2 0 22.4-9.6 32-28.8 9.6-19.2 16-36.8 20.8-52.8h46.4c-9.6 40-24 72-40 97.6-16 25.6-33.6 43.2-52.8 54.4-19.2 11.2-36.8 17.6-52.8 17.6-27.2 0-46.4-9.6-60.8-30.4-14.4-19.2-27.2-48-36.8-84.8l-19.2-68.8-112 126.4c-32 36.8-59.2 54.4-81.6 54.4-30.4 0-44.8-20.8-44.8-64 0-30.4 6.4-60.8 17.6-91.2h54.4c-12.8 38.4-19.2 62.4-19.2 72 0 11.2 3.2 17.6 9.6 17.6 4.8 0 14.4-6.4 28.8-20.8l129.6-147.2-25.6-84.8c-3.2-12.8-8-25.6-12.8-38.4-3.2-8-6.4-14.4-11.2-19.2-4.8-4.8-11.2-8-17.6-8-19.2 0-35.2 27.2-48 80h-46.4c11.2-38.4 22.4-70.4 36.8-94.4 14.4-25.6 30.4-44.8 48-57.6 17.6-12.8 36.8-20.8 56-20.8 27.2 0 48 9.6 64 30.4 16 20.8 28.8 48 40 83.2l8 27.2 78.4-91.2c30.4-33.6 57.6-51.2 81.6-51.2 24 0 40 8 46.4 25.6 9.6 16 12.8 33.6 12.8 51.2z" p-id="100052" fill="#1296db"></path></svg>
              条件
            </el-button>
            <el-button v-if="!data.root" type="text" class="danger" size="mini" @click="deleteItem(node, data)">
              <svg t="1720770677513" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="101092" width="16" height="16"><path d="M253.2 268h516.6c-8.7 0-15.9-7.6-15.3-16.4L715.4 878c0.5-7.4 6.3-12.8 13.8-12.8H293.8c7.4 0 13.3 5.5 13.8 12.8l-39.1-626.4c0.5 8.7-6.6 16.4-15.3 16.4z m-2 613.6c1.4 22.4 20.2 40.1 42.6 40.1h435.3c22.4 0 41.2-17.8 42.6-40.1l39.1-626.4c1.5-23.9-17.2-43.7-41.1-43.7H253.2c-23.9 0-42.5 19.8-41.1 43.7l39.1 626.4zM878.7 268c15.6 0 28.3-12.6 28.3-28.2s-12.6-28.3-28.3-28.3H144.3c-15.6 0-28.3 12.6-28.3 28.3 0 15.6 12.6 28.2 28.3 28.2h734.4zM389 268h245c23.1 0 41.7-18.9 41.7-41.8v-81.8c0-23.1-18.8-41.8-41.7-41.8H389c-23.1 0-41.7 18.9-41.7 41.8v81.8c-0.1 23 18.7 41.8 41.7 41.8z m14.7-123.7c0 8.1-6.5 14.7-14.8 14.7h245c-8.2 0-14.8-6.5-14.8-14.7v81.8c0-8.1 6.5-14.7 14.8-14.7H389c8.2 0 14.8 6.5 14.8 14.7v-81.8z m174.7 626.6c0 15.6 12.6 28.2 28.3 28.2 15.6 0 28.2-12.6 28.2-28.2V362.3c0-15.6-12.6-28.2-28.2-28.2s-28.3 12.6-28.3 28.2v408.6zM444.5 362.3c0-15.6-12.6-28.2-28.2-28.2s-28.2 12.6-28.2 28.2v408.6c0 15.6 12.6 28.2 28.2 28.2s28.2-12.6 28.2-28.2V362.3z m0 0" fill="#EB5D51" p-id="101093"></path></svg>
            </el-button>
          </div>
        </div>
        <div v-else class="condition">
          <div class="condition-wrap">
            <el-select v-model="data.leftField" :disabled="!readonly" class="condition-input" size="mini" placeholder="请选择...">
              <el-option v-for="item in fields" :key="item.uuid" :value="item.uuid" :label="item.name"></el-option>
            </el-select>
            <el-select v-model="data.condition" :disabled="!readonly" class="condition-input" size="mini" placeholder="请选择...">
              <el-option v-for="item in rules" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
            <el-select v-model="data.rightType" :disabled="!readonly" class="condition-input" size="mini" placeholder="请选择...">
              <el-option value="field" label="对象字段"></el-option>
              <el-option value="fixed" label="固定值"></el-option>
            </el-select>
            <el-select v-if="data.rightType === 'field'" :disabled="!readonly" v-model="data.rightField" class="condition-input" size="mini" placeholder="请选择...">
              <el-option v-for="item in fields" :key="item.uuid" :value="item.uuid" :label="item.name"></el-option>
            </el-select>
            <el-select v-if="data.rightType === 'fixed'" :disabled="!readonly" v-model="data.valueType" class="condition-input date-input" @change="handleItemChange(data)" size="mini" placeholder="请选择...">
              <el-option v-for="item in valueTypes" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
            <template v-if="data.rightType === 'fixed'">
              <el-input v-if="data.valueType === 'string'" :disabled="!readonly" class="condition-input" v-model="data.fixedValue" placeholder="请输入"></el-input>
              <el-input v-else-if="data.valueType === 'number'" :disabled="!readonly" type="number" class="condition-input" v-model="data.fixedValue" placeholder="请输入"></el-input>
              <el-date-picker v-else-if="data.valueType === 'date'" :disabled="!readonly" type="datetime" class="condition-input" v-model="data.fixedValue" placeholder="请选择" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </template>
          </div>
          <div v-if="readonly" class="actions">
            <el-button type="text" class="danger" size="mini" @click="deleteItem(node, data)">
              <svg t="1720770677513" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="101092" width="16" height="16"><path d="M253.2 268h516.6c-8.7 0-15.9-7.6-15.3-16.4L715.4 878c0.5-7.4 6.3-12.8 13.8-12.8H293.8c7.4 0 13.3 5.5 13.8 12.8l-39.1-626.4c0.5 8.7-6.6 16.4-15.3 16.4z m-2 613.6c1.4 22.4 20.2 40.1 42.6 40.1h435.3c22.4 0 41.2-17.8 42.6-40.1l39.1-626.4c1.5-23.9-17.2-43.7-41.1-43.7H253.2c-23.9 0-42.5 19.8-41.1 43.7l39.1 626.4zM878.7 268c15.6 0 28.3-12.6 28.3-28.2s-12.6-28.3-28.3-28.3H144.3c-15.6 0-28.3 12.6-28.3 28.3 0 15.6 12.6 28.2 28.3 28.2h734.4zM389 268h245c23.1 0 41.7-18.9 41.7-41.8v-81.8c0-23.1-18.8-41.8-41.7-41.8H389c-23.1 0-41.7 18.9-41.7 41.8v81.8c-0.1 23 18.7 41.8 41.7 41.8z m14.7-123.7c0 8.1-6.5 14.7-14.8 14.7h245c-8.2 0-14.8-6.5-14.8-14.7v81.8c0-8.1 6.5-14.7 14.8-14.7H389c8.2 0 14.8 6.5 14.8 14.7v-81.8z m174.7 626.6c0 15.6 12.6 28.2 28.3 28.2 15.6 0 28.2-12.6 28.2-28.2V362.3c0-15.6-12.6-28.2-28.2-28.2s-28.3 12.6-28.3 28.2v408.6zM444.5 362.3c0-15.6-12.6-28.2-28.2-28.2s-28.2 12.6-28.2 28.2v408.6c0 15.6 12.6 28.2 28.2 28.2s28.2-12.6 28.2-28.2V362.3z m0 0" fill="#EB5D51" p-id="101093"></path></svg>
            </el-button>
          </div>
        </div>
      </div>
    </el-tree>
  </div>
</template>
<script>
import { Tree, Select, Option, DatePicker } from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import { getBodyParams } from "@/custom-component/form/newParser/scripts/tools";
import { getFields } from '@/apis/data/index';

export default {
  name: "WidgetLogicInput",
  components: {
    'el-tree': Tree,
    'el-select': Select,
    'el-option': Option,
    'el-date-picker': DatePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      rules: [
        {
          label: '= 等于',
          value: '==='
        },
        {
          label: '!= 不等于',
          value: '!='
        },
        {
          label: '> 大于',
          value: '>'
        },
        {
          label: '< 小于',
          value: '<'
        },
        {
          label: '<= 小于等于',
          value: '<='
        },
        {
          label: '>= 大于等于',
          value: '>='
        },
        {
          label: '类似(字符串类型)',
          value: 'like'
        },
        {
          label: '包含(简单数组或数组字符串)',
          value: 'whereIn'
        },
        {
          label: '不包含(简单数组或数组字符串)',
          value: 'noIn'
        }
      ],
      fields: [],
      valueTypes: [
        { label: '字符串', value: 'string' },
        { label: '数字', value: 'number' },
        { label: '日期', value: 'date' },
      ]
    }
  },
  computed: {
    readonly() {
      return !(this.disabled);
    },
    badyParams() {
      const filterRule = this.element.config.__config__.filterRule ?? [];
      return getBodyParams(filterRule,this.parser.getFormIdValue);
    }
  },
  watch: {
    badyParams: {
      handler(n) {
        if(n) {
          this.getFields();
        }
      },
      deep: true
    }
  },
  created() {
    this.initData();
    this.getFields();
  },
  methods: {
    /**
     * @description: 小项点击
     * @param {*} data
     * @return {*}
     */    
    handleItemChange(data) {
      data.fixedValue = ''
    },
    /**
     * @description: 初始化数据
     * @return {*}
     */    
    initData() {
      if(!Array.isArray(this.element.value)) {
        this.element.value = [
          {
            type: 'logic',
            logicType: 'or',
            root: true,
            children: [
              {
                type: 'condition',
                table: '',
                leftField: '',
                condition: '',
                rightType: 'fixed',
                rightField: '',
                fixedValue: '',
                valueType: 'string'
              }
            ]
          }
        ];
      }
    },
    /**
     * @description: 添加小项
     * @param {*} type
     * @param {*} data
     * @return {*}
     */    
    addItem(type, data) {
      let res = null;
      switch(type) {
        case 'logic': {
          res = {
            type: 'logic',
            logicType: 'or',
            children: []
          }
          break;
        }
        case 'condition': {
          res = {
            type: 'condition',
            table: '',
            leftField: '',
            condition: '',
            rightType: 'fixed',
            rightField: '',
            fixedValue: '',
            valueType: 'string'
          }
          break;
        }
      }
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      data.children.push(res);
    },
    /**
     * @description: 删除小项
     * @param {*} node
     * @param {*} data
     * @return {*}
     */    
    deleteItem(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d === data);
      if(index >= 0) {
        children.splice(index, 1);
      }
    },
    /**
     * @description: 获取字段
     * @return {*}
     */    
    getFields() {
      const bodyParams = this.badyParams;
      // 这个值来自于数据过滤
      // const uuid = 'object6686646135b9c';
      getFields({
        ...bodyParams,
				size: 1000,
				page: 1
			})
				.then((res) => {
					if (res.status === 200 && res.data.code === 200 && res.data.data) {
						const data = res.data.data;
						const list = (data.data || []).map(ele => {
              return {
                id: ele.id || '',
                name: ele.name || '',
                code: ele.code || '',
                uuid: ele.uuid || '',
                // 字段配置
                viewConfig: {
                  name: ele.name || '',
                  code: ele.code || '',
                  uuid: ele.uuid || '',
                  showInFilter: false, // 是否显示在过滤组件
                  type: 'input', // 表单类型
                  optionOrign: 'fixed', // 值来源 tabs 下拉框  radio 
                  isRadius: false,
                  options: [
                    {
                      label: '',
                      en_label: '',
                      value: '',
                      widthStatistics: false,
                      defaultSelect: false,
                      statisticsConfig: [
                        {
                          key: 'count', // 统计字段
                          view_uuid: '', // 视图
                          field_uuid: '', // 字段UUID 可以不填
                          aggregate: 4 // 统计函数
                        }
                      ]
                    }
                  ], // 备选项
                  optionConfig: {
                    objectUUID: '',
                    viewUUID: '',
                    labelCode: '',
                    valueCode: '',
                    widthStatistics: false,
                    defaultSelect: false,
                    popAll: false,
                    statisticsConfig: [
                      {
                        key: 'count', // 统计字段
                        view_uuid: '', // 视图
                        field_uuid: '', // 字段UUID 可以不填
                        aggregate: 4 // 统计函数
                      }
                    ]
                  },
                  dateType: '', // 时间类型
                  dateFormat: '', //  时间格式化类型
                  ruleType: '', // 逻辑判断(后端字段)
                  defaultDate: '', // 默认时间
                }
              }
            })
            this.fields = list;
					}
				})
				.catch((err) => {
					console.log(err);
				});
    }
  },
}
</script>

<style lang="less">
.logic-wrap{
  padding: 12px 0;
  box-sizing: border-box;
  .logic-tree-wrap{
    .el-tree-node__content {
      height: 32px;
      width: 100%;
      box-sizing: border-box;
      .logic-item{
        flex-grow: 1;
        overflow: hidden;
        .logic{
          border-left: 1px solid #409eff;
          width: 100%;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 12px;
          box-sizing: border-box;
          .logic-type-select{
            width: 160px;
            .el-input__inner{
              border: none;
              outline: none;
            }
          }
          .actions{
            flex-shrink: 0;
            display: flex;
            align-items: center;
            .el-button{
              span{
                display: flex;
                align-items: center;
                gap: 6px;
              }
            }
            .danger{
              span{
                color: #F56C6C;
              }
            }
          }
        }
        .condition{
          border-left: 1px solid #409eff;
          width: 100%;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 12px;
          box-sizing: border-box;
          gap: 24px;
          .condition-wrap{
            flex-grow: 1;
            display: flex;
            align-items: center;
            gap: 12px;
            .condition-input{
              flex: 1;
              &.date-input{
                width: 80px;
                flex-shrink: 0;
              }
              .el-input__inner{
                border: none;
                outline: none;
              }
            }
          }
          .actions{
            flex-shrink: 0;
            display: flex;
            align-items: center;
            .el-button{
              span{
                display: flex;
                align-items: center;
                gap: 6px;
              }
            }
            .danger{
              span{
                color: #F56C6C;
              }
            }
          }
        }
      }
    }
  }
}
</style>