<!--
 * @Description: 进度曲线(南华专用)
 * @Author: luocheng
 * @Date: 2022-05-31 14:57:36
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2024-04-28 14:37:00
-->
<template>
  <div class="common-progress-curve" v-if="paramsResult">
    <div class="eject-icon" @click.stop="dialogVisible = !dialogVisible">
			<i class="iconfont icondaping-quanping" />
		</div>
    <!-- 进度曲线 -->
    <div class="box-chart">
      <CurveChart @selectKey="onSelectKey" :paramsResult="paramsResult"></CurveChart>
    </div>
    <!-- 施工参数列表 -->
    <TableList :filterKey="filterKey" :paramsResult="paramsResult"></TableList>
    <el-dialog
			:visible.sync="dialogVisible"
			title=""
			:modal="false"
			:append-to-body="true"
			custom-class="chart-container-dialog"
			width="60%"
		>
      <div class="common-progress-curve" style="height: 600px">
        <!-- 进度曲线 -->
        <div class="box-chart">
          <CurveChart @selectKey="onSelectKey" :paramsResult="paramsResult"></CurveChart>
        </div>
        <!-- 施工参数列表 -->
        <TableList :filterKey="filterKey" :paramsResult="paramsResult"></TableList>
      </div>
		</el-dialog>
  </div>
</template>

<script>
import CurveChart from './processComponents/CurveChart.vue';
import TableList from './processComponents/TableList';
import eventBus from '@/plugins/eventBus';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import { Dialog } from 'element-ui';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';

export default {
  name: 'CommonProgressCurve',
  mixins: [CustomComponentMixin, databaseTriggerMixin],
  components: {
    CurveChart,
    TableList,
		'el-dialog': Dialog
  },
  data() {
    return {
      filterKey: null,
      paramsResult: null,
			dialogVisible: false
    }
  },
  computed: {
    paramsConfig() {
      return this.element?.paramsConfig || [];
    }
  },
  mounted() {
    this.paramsResult = this.initParams(this.paramsConfig || []);
    const databaseTrigger = {
      [this.$elementId]: config => {
        if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
          return;
        }
        this.paramsResult = this.initParams(this.paramsConfig || []);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
  },
  methods: {
    /**
     * @description: 选中
     * @param {String} val
     */
    onSelectKey(val) {
      this.filterKey = val;
    } 
  }
}
</script>

<style lang="less" scoped>
.common-progress-curve{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
	&:hover{
		.eject-icon{
			visibility: visible;
		}
	}
	.eject-icon{
		visibility: hidden;
		position: absolute;
		top: 8px;
		right: 5px;
		width: 16px;
		height: 16px;
		z-index: 1000;
    color: #eee;
		&:hover{
			color: #409eff;
		}
	}
  .box-chart{
    // height: 220px;
    width: 100%;
    flex: 1;
  }
}
</style>