<template>
    <ModelTreeSingle
            ref="modelTree"
            class="moduleWrap"
            v-show="show"
            :element.sync="element"
            :modelTreeSpread="modelTreeSpread"
            :bindViewerId="bindViewerId"
            :bindDataMainId="bindDataMainId"
            :loadModelNodeCallback="loadModelNodeCallback"
            @registerBehaviors="registerBehaviors"
            @clickToolbar="onClickToolbar"
            @addModel="onAddModel"
            @deleteModel="onDeleteModel"
            @locationModel="onLocationModel"
            @checkModel="onCheckModel"
    ></ModelTreeSingle>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
    import ModelTreeSingle from "@/custom-component/viewerComponent/ModelTreeSingle";
    import eventBus from '@/plugins/eventBus';
    import {initParams} from '@/utils/tools';
    import {mapState} from 'vuex';

    export default {
        name: "viewerBIMModuleModelTree",
        components: {
            ModelTreeSingle,
        },
        data() {
            return {
                show: true,
                modelTreeSpread: 'right',
                bindViewerId: null,
                bindDataMainId: '',
                db: {
                    object_uuid: 'object6176693334cff',
                    view_uuid: 'view6176699c082b5'
                },
                isViewerInited: false,
                viewerInitedFunc: [],
            }
        },
        computed: {
            ...mapState([
                'componentData',
                'originComponentData'
            ]),
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin, BehaviorHandlerMixin],
        mounted() {
            let {objectData, viewData} = this.element.database;
            if(objectData && viewData){
                this.db.object_uuid = objectData.uuid;
                this.db.view_uuid = viewData.uuid;
            }

            if (this.element.modelBindId) {
                if (this.checkBind(this.element.modelBindId)) {
                    this.bindViewerId = this.element.modelBindId;
                }
            }

            if(this.element.modelTreeSpread){
                this.modelTreeSpread = this.element.modelTreeSpread;
            }

            const {
                search = [],
                param = {}
            } = initParams(
                this.element?.database?.paramsConfig || [],
                this.isGroup,
                this.subComponentData,
                this.groupComponents,
                this.EDITOR_pageUUID
            );
            this.$refs.modelTree.getBIMUnloadListData(this.db, this.bindViewerId, search, param).then(() => {
                this.initEventListener();
                this.initBehaviors();
            });
        },
        methods: {
            /**
             * @description: 注册组件事件
             */
            initEventListener() {
                eventBus.$on("BIMModule.GetSource", (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== "ViewerBIM") {
                                if (item.children && item.children.length > 0) loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex(x => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                        spreadDirection: this.modelTreeSpread
                    });
                });

                eventBus.$on("BIMModule.BindViewer", (eid, bindId) => {
                    if (eid !== this.element.id) return;
                    if (!this.checkBind(bindId)) return;

                    this.element.modelBindId = bindId;
                    this.bindViewerId = bindId;
                    this.refreshDataMainId();
                    this.$message.success(`[${this.element.name}] 绑定成功`);
                    this.$refs.modelTree.getBIMUnloadListData(this.db, this.bindViewerId);
                });

                eventBus.$on("BIMModule.ModelTreeSpread", (eid, direction) => {
                    if (eid !== this.element.id) return;

                    this.element.modelTreeSpread = direction;
                    this.modelTreeSpread = direction;
                });

                if(eventBus._events['ViewerBIM.getViewerInitedFlag'] && this.bindViewerId){
                    eventBus.$emit("ViewerBIM.getViewerInitedFlag", this.bindViewerId, (flag) => {
                        if(flag){
                            this.getDataByMounted();
                        }else{
                            this.initViewerInitedEventListener();
                        }
                    });
                }else{
                    this.initViewerInitedEventListener();
                }

                eventBus.$on("Viewer.onLoadedModel", (eid, model_id) => {
                    if (eid !== this.bindViewerId) return;

                    this.$refs.modelTree.setBIMNodeLoaded(model_id);
                });
            },
            /**
             * @description: 注册Viewer组件初始化监听事件
             */
            initViewerInitedEventListener(){
                eventBus.$on("Viewer.onViewerInited", (eid) => {
                    if (eid !== this.bindViewerId) return;

                    this.refreshDataMainId();

                    this.getDataByViewerInited(eid);
                });
            },
            /**
             * @description: 注册组件行为
             */
            initBehaviors() {
                this.element.behaviors = [];
                this.registerBehaviors([
                    {
                        label: "[基础]切换显示",
                        desc: `
                            调用后就会切换显示和隐藏
                        `,
                        value: "BIMModule-switchDisplay",
                        func: () => {
                            this.show = !this.show;
                        },
                    },
                ]);

                for (let eid in this.$refs) {
                    const ele = this.$refs[eid];
                    if (ele.initBehaviors) {
                        ele.initBehaviors();
                    }
                }
            },
            /**
             * @description: 检验绑定的Viewer是否有效
             * @param {String} bindId Viewer标识值
             */
            checkBind(bindId = this.bindViewerId) {
                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

                return hasComp;
            },
            /**
             * @description: 获取绑定的Viewer
             * @param {String} bindId Viewer标识值
             */
            getBindViewer(bindId = this.bindViewerId){
                const loop = (array = []) => {
                    for (let item of array) {
                        if(item.id === bindId) return item;

                        if (item.component !== "ViewerBIM") {
                            if (item.children && item.children.length > 0) loop(item.children);
                            continue;
                        }
                    }
                };

                let comp1 = loop(this.subComponentData);
                if(comp1) return comp1;

                let comp2 = loop(this.subComponentData);
                if(comp2) return comp2;
            },
            /**
             * @description: 刷新DataMainId
             */
            refreshDataMainId() {
                if (!this.bindViewerId) return false;
                eventBus.$emit('ViewerBIM.getDataMainId', this.bindViewerId, (id) => {
                    this.bindDataMainId = id;

                    this.$nextTick(() => {
                        for (let cid in this.$refs) {
                            const comp = this.$refs[cid];
                            if (!comp.onChangeDataMainId) continue;

                            comp.onChangeDataMainId(id);
                        }
                    });
                });
            },
            /**
             * @description: 模型树节点获取子级节点的异步方法
             * @param {Object} node 节点对象
             */
            getNodeChildren(node) {
                return new Promise((rol) => {
                    let model_id = null;
                    let dbid = null;

                    if (node.type === "model") {
                        model_id = node.id;
                        dbid = 1;

                    } else if (node.isPart) {
                        model_id = node.model_id;
                        dbid = node.dbid;
                    } else {
                        return;
                    }

                    eventBus.$emit("ViewerBIM.getNodeChildren", this.bindViewerId, model_id, dbid, (data) => {
                        rol({
                            model_id,
                            data,
                        });
                    });
                });

            },
            /**
             * @description: 获取BIM Viewer默认已加载的模型
             */
            getLoadedModelIds() {
                eventBus.$emit("ViewerBIM.getModelIds", this.bindViewerId, (ids) => {
                    this.$refs.modelTree.setUnloadNodeCheck(ids);
                });
            },
            /**
             * @description: 获取BIM Viewer默认已加载的模型信息
             */
            async getLoadedModelInfo() {
                return new Promise((rol) => {
                    eventBus.$emit("ViewerBIM.getLoadedModelInfo", this.bindViewerId, (loadedInfo) => {
                        rol(loadedInfo);
                    });
                });
            },
            /**
             * @description: 监听ViewerInited事件，监听模型加载数据
             */
            async getDataByViewerInited() {
                const loadedInfo = await this.getLoadedModelInfo();

                let ids = this.$refs.modelTree.setModelMapData(loadedInfo);

                this.$nextTick(() => {
                    this.$refs.modelTree.setUnloadNodeCheck(ids);

                    this.isViewerInited = true;

                    for (let func of this.viewerInitedFunc) {
                        func();
                    }
                })
            },
            /**
             * @description: 在组件Mounted中获取模型加载数据
             */
            async getDataByMounted() {
                const loadedInfo = await this.getLoadedModelInfo();

                let ids = this.$refs.modelTree.setModelMapData(loadedInfo);

                this.$nextTick(() => {
                    this.$refs.modelTree.setUnloadNodeCheck(ids);

                    setTimeout(() => {
                        for (let model_id of ids) {
                            this.$refs.modelTree.setBIMNodeLoaded(model_id);
                        }

                        this.isViewerInited = true;

                        for (let func of this.viewerInitedFunc) {
                            func();
                        }
                    }, 1000)
                })
            },

            /**
             * @description: 模型树懒加载方法
             * @param {Object} node 模型节点对象
             */
            async loadModelNodeCallback(node) {
                const {data, model_id} = await this.getNodeChildren(node);
                const list = [];

                for (let {name, dbid, count} of data) {
                    list.push({
                        name,
                        model_id,
                        id: `${model_id}_${dbid}`,
                        isPart: true,
                        dbid,
                        isLeaf: count === 0
                    })
                }

                return {leafs: list};
            },
            /**
             * @description: 模型树其他功能按钮事件
             * @param {String} type 功能按钮类型
             */
            onClickToolbar(type = '') {
                if (type === 'local') {
                    window.open(`https://models.bimcc.net/local/viewer`, '_blank');
                } else {
                    this.$message.warning("功能开发中...");
                }
            },
            /**
             * @description: 添加模型事件
             * @param {Array} keys 模型ID集
             */
            onAddModel(keys) {
                if (!this.checkBind(this.bindViewerId)) return;

                const list = this.$refs.modelTree.setLoadMapData(keys);

                if (this.isViewerInited) {
                    for (let mid of list) {
                        eventBus.$emit('ViewerBIM.loadModel', this.bindViewerId, mid);
                    }
                } else {
                    eventBus.$emit('ViewerBIM.getViewerInitFlag', this.bindViewerId, (initFlag) => {
                        //获取初始化标记
                        if (!initFlag) {
                            eventBus.$emit('ViewerBIM.initViewer', this.bindViewerId, list.splice(0, 1)[0]);

                            this.viewerInitedFunc.push(() => {
                                for (let mid of list) {
                                    eventBus.$emit('ViewerBIM.loadModel', this.bindViewerId, mid);
                                }
                            });
                        }
                    });
                }
            },
            /**
             * @description: 删除模型事件
             * @param {Array} nodes 模型ID集
             */
            onDeleteModel(nodes) {
                for (let item of nodes) {
                    eventBus.$emit('ViewerBIM.unloadModel', this.bindViewerId, item.id);
                    this.$refs.modelTree.deleteLoadMapData([item.id]);
                }
            },
            /**
             * @description: 定位模型事件
             * @param {Object} node 模型节点对象
             */
            onLocationModel(node) {
                eventBus.$emit("ViewerBIM.fitToView", this.bindViewerId, [
                    {
                        model_id: node.id,
                        dbids: [1],
                    }
                ]);
            },
            /**
             * @description: 勾选模型事件
             * @param {Object} node 模型节点对象
             * @param {Boolean} isChecked 是否勾选
             */
            onCheckModel(node, isChecked) {
                if (!node.type) {
                    const model_id = node.model_id;
                    const dbid = node.dbid;

                    if (isChecked) {
                        eventBus.$emit("ViewerBIM.show", this.bindViewerId, [dbid], model_id);
                    } else {
                        eventBus.$emit("ViewerBIM.hide", this.bindViewerId, [{model_id, dbids: [dbid]}]);
                    }

                } else if (node.type === "model") {
                    const model_id = node.id;

                    if (isChecked) {
                        eventBus.$emit("ViewerBIM.show", this.bindViewerId, [1], model_id);
                    } else {
                        eventBus.$emit("ViewerBIM.hide", this.bindViewerId, [{model_id, dbids: [1]}]);
                    }
                } else {
                    if (node.children) {
                        const findModel = (list, result) => {
                            for (let info of list) {
                                if (info.type === "model") {
                                    result.push(info.id);
                                }

                                if (info.type === "file" && info.children) {
                                    findModel(info.children, result);
                                }
                            }
                        };

                        const model_ids = [];
                        findModel(node.children, model_ids);
                        if (isChecked) {
                            for (let mid of model_ids) {
                                eventBus.$emit("ViewerBIM.show", this.bindViewerId, [1], mid);
                            }
                        } else {
                            const selection = [];
                            for (let mid of model_ids) {
                                selection.push({
                                    model_id: mid,
                                    dbids: [1],
                                })
                            }
                            eventBus.$emit("ViewerBIM.hide", this.bindViewerId, selection);
                        }

                    }
                }
            }
        }
    };
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }
</style>
