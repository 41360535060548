/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-10-08 09:39:06
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-02-27 10:21:42
 * @FilePath: /dataview-viewer-test/src/utils/debugLog.js
 * @Description: 筑云编辑器debug
 */
/**
 * 数据结构：
 *  id: 每次操作生成一次，并伴随本次操作全程
 *  type: 操作类型 => 点击等事件
 *  origin: 来源组件 => 组件名 + id => 附加组件定位
 *  target: 目标组件 => 同来源组件
 *  action: 目标组件操作 => 各种事件类型（显影、后端事件、行为...） + 数据更新
 * 
 * 注意事项：
 *  一次操作存在多种类型或针对多个对象的操作
 *  操作引起的附加操作基本为数据刷新 => 根据参数进行决定，需要进行追溯 => 同样会出现多个请求 => 可能存在顺序请求
 *  日志应当是一个树形结构
 * 
 * 
 * 说明：
 *  p 常规文本
 *  i 事件
 *  span 值
 *  em 失败
 *  strong 成功
 * 
 */
import store from '@/store';

export class BIMCCEditorDebug {
  constructor() {
    this.logList = [];
    // this.useDebug = ['page-editor.bimcc.site', 'localhost'].includes(window.location.hostname)
    this.useDebug = false;
    window.EDITORUseDebug = this.useDebug;
  }
  // 写入
  push(logObj) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    // TODO... 需要进行去重，合并等操作
    this.logList.push(logObj);
    // store.commit('updateDebugLog', this.logList)
  }
  // 编辑
  modify(logObj) {
    if (!this.useDebug) return;
    if (!logObj || !logObj.id) {
      console.warn('数据错误!');
      return;
    }
    const index = this.logList.findIndex(ele => ele.id === logObj?.id)
    if (index === -1) {
      console.warn('暂无可编辑log');
      return;
    }
    this.logList.splice(index, 1, logObj);
  }
  // 清空日志列表
  clear() {
    this.logList = [];
    store.commit('clearDebug');
  }
  // 获取日志列表
  getLog() {
    if (!this.useDebug) return;
    return this.logList
  }
  // 查看指定id的日志记录
  getLogByComponent(componentId) {
    if (!this.useDebug) return [];
    return this.logList.filter(ele => ele.componentId === componentId);
  }
  // 获取行为名称
  getBehaviorName(component, name) {
    return component?.behaviors.find(ele => ele.value === name)?.label || '-';
  }

  // 写入debug
  pushDebug(logObj) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.logList.push(logObj);
    // store.commit('updateDebugLog', this.logList)
  }
  // 预设的写入格式
  // 
  // 
  // 分隔线
  setDivider(str) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `-------------${str || '------'}----------------`,
      isDivider: true
    });
  }
  // 
  // 后端事件
  // 开始执行后端事件
  startEEAction(element = null) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>${element? '组件【'+element?.name+'】' : ''}开始执行<i>后端事件</i></p>`,
      eventType: 'eeAction',
      details: {
        element
      }  
    });
  }
  // 后端事件错误
  failEEAction(element = null, str = null) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: str || `<p>${element? '组件【' + element?.name + '】' : ''}, 后端事件<em>配置错误，结束</em>！</p>`,
      eventType: 'eeAction',
      details: {
        element
      }
    }); 
  }
  // 后端事件执行成功
  successEEAction(eventName, element) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>组件【${element?.name || '--'}】后端事件${eventName ? '【' + eventName + '】' : ''}<strong>执行成功</strong>！</p>`,
      eventType: 'eeAction',
      details: {
        element,
        eventName
      }
    });
  }
  // 后端事件执行失败
  errorEEAction(eventName, element) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>组件【${element?.name || '--'}】后端事件${eventName ? '【' + eventName + '】' : ''}<em>执行失败</em>！</p>`,
      eventType: 'eeAction',
      details: {
        element,
        eventName
      }
    });
  }
  // 参数
  descEEActionParam(step) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    let desc = '';
    switch(step) {
      case 1: 
        desc = `<p>后端事件<em>无参数配置</em>。</p>`;
        break;
      case 2:
        desc = `<p>获取后端事件参数开始...</p>`;
        break;
      default:
        desc = '--------------'
    }
    this.pushDebug({
      desc
    });
  }
  // 参数成功
  successEEActionParam(param = {}, paramsConfig) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>获取后端事件参数<strong>完毕！</strong>。参数：${Object.keys(param).length}个。</p>`,
      eventType: 'getParams',
      details: {
        param,
        paramsConfig
      }
    });
  }
  // 
  // 导出
  // 开始导出
  startExport(element) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>${element? '组件【'+element?.name+'】' : ''}开始执行<i>导出</i></p>`,
      eventType: 'export',
      details: {
        element
      }
    });
  } 
  // 导出失败
  failExport(element, reason) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>${element? '组件【'+element?.name+'】' : ''}导出<em>${reason}结束</em>！</p>`,
      eventType: 'export',
      details: {
        element
      }
    });
  }
  // 导出成功
  successExport(element) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>${element? '组件【'+element?.name+'】' : ''}导出数据<strong>成功，执行完毕</strong>！/p>`,
      eventType: 'export',
      details: {
        element
      }
    });
  }
  // 导出失败
  errorExport(element = null) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>${element? '组件【'+element?.name+'】' : ''}导出数据<em>失败，执行结束</em>！/p>`,
      eventType: 'export',
      details: {
        element
      }
    });
  }
  // 
  // 行为
  startBehavior(element = null, component, funName, param) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    const label = Object.prototype.toString.call(component) === '[object Object]' ? this.getBehaviorName(component, funName) : component
    this.pushDebug({
      desc: `<p>组件【${element?.name}】响应了行为<i>【${label}】</i><strong>完毕！</strong></p>`,
      details: {
        param,
        element,
        behaviorName: funName
      }
    });
  }
  // 行为出错
  failBehavior(element = null, behaviorName) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>组件【${element?.name}】不存在此行为(【${behaviorName}】)<em>结束！</em></p>`,
      details: {
        element,
        behaviorName
      }
    });
  }
  // 行为执行成功
  successBehavior() {

  }
  // 行为执行失败
  errorBehavior() {
    
  }

  // 
  // 事件
  // 开始执行事件
  startEvent(element, isDblclick) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '------------------------------------',
      isDivider: true
    });
    this.pushDebug({
      desc: `<p><i>${isDblclick ? '双' : '单 '}击</i>组件【${element?.name}】</p>`,
      eventType: 'click',
      details: {
        element
      }
    });
  }
  // 基础事件（组件属性类切换）
  baseEvent(desc, element = null, eventType = 'click') {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc,
      eventType,
      details: {
        element
      }
    });
  }
  // 事件执行出错
  failEvent() {

  }
  // 事件执行成功
  successEvent() {

  }
  // 事件执行失败
  errorEvent() {

  }

  // 
  // 跳转页面
  /**
   * @description: 跳转页面
   * @param {Number} type 1 项目内，2  外部， 3  模板
   * @param {String} link 链接或页面uuid
   * @param {Object/null} query
   */
  jumpPage(type, link, query = null) {
    let typeStr = '';
    if (type === 1) {
      typeStr = '（项目内）</i>页面uuid';
    } else if (type === 2) {
      typeStr = '（外部）</i>页面链接';
    } else if (type === 3) {
      typeStr = '（模板）</i>页面链接'
    }
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>执行<i>跳转页面${typeStr}<span>${link}</span>${query ? ',参数$'+ query : ''}。<strong>完毕</strong></p>`,
      eventType: 'jumpPage',
      details: {
        element: null
      }
    });
  }

  // 页面状态
  // 
  // 页面状态修改开始
  startPageStatus(element) { 
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '------------------------------------',
      isDivider: true
    });
    this.pushDebug({
      desc: `<p>开始<i>修改页面状态</i>操作</p>`,
      eventType: 'pageStatus',
      details: {
        element
      }
    });
  }
  // 逻辑校验失败
  failPageStatus(element = null, code) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>修改页面状态<i>${code}</i><em>逻辑验证失败, 结束!</em></p>`,
      eventType: 'pageStatus',
      details: {
        element
      }
    });
  }
  // 修改页面状态
  successPageStatus(code, value) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>修改页面状态<span>【${code}】</span>值为<span>${value}</span></p>`,
      eventType: 'pageStatus'
    });
  }
  // 开始页面状态事件。type 1 值变化， 2 值更新
  startPageStatusEvent(type = 1) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>触发页面状态<i>${type === 1 ? '值变化' : '值更新'}</i>事件</p>`,
      eventType: 'pageStatus'
    });
  }
  // 页面状态事件配置错误
  errorPageStatusEvent() {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>触发页面状态<em>事件配置错误执行结束</em></p>`,
      eventType: 'pageStatus'
    });
  }
  // 页面状态事件响应
  responsePageStatusEvent() {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>页面状态绑定事件开始执行...</p>`,
      eventType: 'pageStatus'
    });
  }

  // 
  // 
  // 前置事件
  startFrontOperation(element = null) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '<p>开始执行<i>前置事件</i></p>',
      eventType: 'frontOperation',
      details: {
        element
      }
    });
  }
  // 前置事件执行结果
  resultFrontOperation(canNext, element) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>执行前置事件${canNext ? '<strong>成功</strong>' : '<em>失败, 结束</em>'}！</p>`,
      eventType: 'frontOperation',
      details: {
        element
      }
    });
  }
  // 
  // 
  // 数据
  startDatabase(element) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '------------------------------------',
      isDivider: true
    });
    this.pushDebug({
      desc: `<p>组件【${element?.name || '--'}】执行数据刷新</p>`,
      eventType: 'databaseTrigger',
      details: {
        element
      }
    });
  }
  // 
  // 
  // 
  // 权限校验
  startArchiAPermission(element = null, action = null) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '------------------------------------',
      isDivider: true
    });
    this.pushDebug({
			desc: `<p>组件【${element?.name}${action ? '-' + action.name : ''}】开始执行架构权限判断</p>`,
      eventType: 'ArchiAPermission',
      details: {
        element
      }
    });
  }
  // 权限校验成功
  successArchiAPermission(element = null, action = null, reason = '') {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>组件【${element?.name}${action ? '-' + action.name : ''}】架构权限判断，<strong>成立！${reason}</strong></p>`,
      details: {
        element
      }
    });
  }
  // 权限校验成功
  errorArchiAPermission(element = null, action = null, reason = '') {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: `<p>组件【${element?.name}${action ? '-' + action.name : ''}】架构权限判断，<em>不成立！${reason}</em></p>`,
      details: {
        element
      }
    });
  }
  // 
  // UI逻辑判断
  // 开始UI逻辑判断
  startUILogic() {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '------------------------------------',
      isDivider: true
    });
    this.pushDebug({
			desc: `<p>开始<i>UI逻辑判断</i></p>`,
      eventType: 'judgingUILogic',
      details: {}
    });
  }
  // 通过
  successUILogic(logics, details) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '<p><i>UI逻辑判断</i><strong>不通过!</strong></p>',
      eventType: 'judgingUILogic',
      details: {
        logics,
        details
      }
    });
  }
  // 错误、不通过
  errorUILogic(logics, details) {
    if (!this.useDebug || !window.EDITORUseDebug) return;
    this.pushDebug({
      desc: '<p><i>UI逻辑判断</i><em>不通过!</em></p>',
      eventType: 'judgingUILogic',
      details: {
        logics,
        details
      }
    });
  }
}

/**
 * @description: 生成编辑器日志ID
 */
export const getLogId = () => {
  return `EDITORDebug-${new Date().getTime()}`;
}