/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-04-22 14:19:45
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-07-16 10:57:57
 * @FilePath: \dataview-viewer-test\src\custom-component\eventMixin.js
 * @Description: 事件全局出发逻辑
 */
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { getComponentById, doEEActionHandle, doFrontOperation, getLinkByTemplate, generateQuery, judgingEventLogic, getStatusVal, getQueryValue } from '@/utils/tools';
import { dataInterface } from '@/apis/data/index';

export default {
  props: {
    element: {
      type: Object
    },
    // 是否为分组
    isGroup: {
      type: Boolean
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
    componentList: {
      default: null
    }
  },
  inject: ['EDITOR_pageUUID'],
  computed: {
    ...mapState(['signComponentData', 'subsidiaryComponentData']),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
    statusConfig() {
      return this.element?.statusConfig;
    }
  },
  mounted() {
    this.onResolve();
  },
  methods: {
    /**
     * @description: 暴露数据
     */
    onResolve() {
      if(!this.value){
        return;
      }
      const resolveData = {
        value: this.value
      }
      this.element.resolveData = resolveData;
      this.$store.commit('updatePageCustomStatus', {
        origin: this.element,
        resolveData
      });
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
    },
    /**
     * @description: 输入
     */
    onInput(item, eventType) {
      this.onResolve();
      window.$EditorDebug.startEvent(item, eventType);
      if (!item?.events?.[eventType]) return;
			if(item.id.includes('-page')){
				eventBus.$emit('signResolve',this.element)
				this.setTemplateId(item)
			}
      eventBus.$emit('EDITOR_loopResolve', item.loopData);
      const excludesEvents = ['CommonDateButton'];
      if (excludesEvents.includes(item.component)) {
        return false;
      }
      if (!item.events || Array.isArray(item.events)) return;
      if (this.timer) {
				clearTimeout(this.timer);
			}
			const click = item.events?.[eventType] || {};
      if (item.events?.dblclick) {
				this.timer = setTimeout(() => {
					this.triggerEvent(item, click, eventType);
				}, 300);
			} else {
				this.triggerEvent(item, click, eventType);
			}
    },
    /**
     * @description: 标记点击写入标记容器内标记暴露值resolveId
     */
		setTemplateId(ele){
			const idArr = ele.id.split('-')
			let pageId = `${idArr[idArr.length - 2]}-${idArr[idArr.length - 1]}`
			const findData = this.signComponentData.filter(v=>v.id.includes(pageId))
			if(findData.length){
				const findDataIdArr = findData[0].id.split('-')
				let signTemplate = getComponentById(this.componentData, `${findDataIdArr[0]}-${findDataIdArr[1]}`);
				if(signTemplate){
					this.$store.commit('modifyComponent', {
						component: {
							...signTemplate,
							resolveId: findDataIdArr[3]
						},
						containerId: null,
						isModify: true
					});
					// 清除resolveId,暂时采用定时器
					setTimeout(()=>{
						this.$store.commit('modifyComponent', {
							component: {
								...signTemplate,
								resolveId: ''
							},
							containerId: null,
							isModify: true
						});
					},2000)
				}
			}
			
		},
    /**
     * @description: 触发事件
     */
    async triggerEvent(item, click, eventType = 'click') {
      const {
        specialEventList = [],
        pattern,
        frontOperation = []
      } = click || {};
			let canNext = true;
			if (frontOperation && frontOperation.length) {
				// 触发预处理事件、行为
        window.$EditorDebug.startFrontOperation(item);
				canNext = await doFrontOperation(frontOperation, {
					isGroup: this.isGroup,
					componentList: this.subComponentData,
					componentData: this.componentData,
					groupComponents: this.groupComponents
				});
        window.$EditorDebug.resultFrontOperation(canNext, item);
			}
			if (!canNext) {
				this.$message.error('操作失败！');
				return false;
			}
      if (!pattern) {
        // 普通事件
				this.triggerEventItem(click, item, eventType);
      } else if (pattern === 'special') {
				specialEventList.forEach((ele) => {
					const { actionType } = ele;
					if(actionType === 'pageStatus'){
						this.updatePageStatus(ele.stateMachine || [])
					} else if(actionType === 'componentBehavior'){
						//触发组件行为
						setTimeout(()=>{
							this.triggerEventItem(ele, item, eventType);
						},0)
					} else {
            // 其他情况
						this.triggerEventItem(ele, item, eventType);
					} 
				});
      }
    },
    /**
     * @description: 触发单个事件
     */
    triggerEventItem(ele, item, eventType = 'click') {
      const {
        actionType,
        effects = [],
        behaviors,
        linkType,
        queryList = [],
        linkUrl = '',
        pageId,
        linkTemplate,
        stateMachine
      } = ele || {};
      if (actionType === 'eeAction') {
        // 触发后端事件
        window.$EditorDebug.startEEAction(item);
        const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
        if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
          this.$message.error('事件配置错误！');
          window.$EditorDebug.failEEAction(item);
          return false;
        }
        this.loading = true;
        if (this.statusConfig.globalLoading) {
          this.triggerGlobalLoading(true)
        }
        const sourceParams = this.getBindParams(item);
        // 蓝图
        if (eeType === 'blueprint') {
          dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('操作成功！');
              window.$EditorDebug.successEEAction(item, `/api${interfaceUrl}`);
              doEEActionHandle(res.data?.__adds__);
            }
            this.loading = false;
            if (this.statusConfig.globalLoading) {
              this.triggerGlobalLoading(false)
            }
          }).catch(() => {
            window.$EditorDebug.errorEEAction(item, `/api${interfaceUrl}`);
            this.loading = false;
            if (this.statusConfig.globalLoading) {
              this.triggerGlobalLoading(false)
            }
          })
          return true;
        }
        // 数仓
        const data_id = sourceParams?.data_id;
        dataInterface({
          __method_name__: 'customEventCall',
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          ...sourceParams,
          data_id, // 参数配置
          event: eventName
        }).then(res => {
          if (res.status === 200 && res.data.code === 200) {
            this.$message.success('操作成功！');
            window.$EditorDebug.successEEAction(item, eventName);
            doEEActionHandle(res.data?.__adds__);
          }
          this.loading = false;
          if (this.statusConfig.globalLoading) {
            this.triggerGlobalLoading(false)
          }
        }).catch(() => {
          this.loading = false;
          if (this.statusConfig.globalLoading) {
            this.triggerGlobalLoading(false)
          }
          window.$EditorDebug.errorEEAction(item, eventName);
        });
        return true;
      } else if (actionType === 'jumpPage') {
        const query = {};
        queryList.forEach(queryItem => {
          let component = getComponentById(this.componentData, queryItem.componentId);
          this.$set(query, queryItem.key, component?.resolveData?.[queryItem.feild]);
        });
        const queryStr = generateQuery(query);
        if (linkType === 'projectPage') {
          window.$EditorDebug.jumpPage(1, pageId, query);
          this.$router.push({
            path: pageId,
            query
          });
          return;
        } else if (linkType === 'outPage') {
          if (window.EDITORUseDebug) {
            window.$EditorDebug.jumpPage(2, linkUrl);
          }
          window.open(linkUrl);
        } else if (linkType === 'custom') {
          // if (!this.mapData?.renderData) return;
          const customLink = getLinkByTemplate(linkTemplate, this.mapData?.renderData || {})
          window.$EditorDebug.jumpPage(3, `${customLink}${queryStr}`);
          window.open(`${customLink}${queryStr}`);
        }
      } else if (actionType === 'export') {
        // 导出
        window.$EditorDebug.baseEvent(`<p>开始执行<i>全局导出事件</i></p>`);
				this.onExport(item?.events?.[eventType]);
			} else if (actionType === 'pageStatus') {
        // 修改页面状态
        this.updatePageStatus(stateMachine || [], item);
			} else {
        // 页面事件
        effects.forEach((effect) => {
          this.$store.commit('triggerEvents', {
            config: {
              ...ele,
              ...effect
            },
            element: this.element,
            EDITOR_pageUUID: this.EDITOR_pageUUID
          });
        });
      }
      // 行为
      if (behaviors && Array.isArray(behaviors)) {
        if (behaviors.length === 1) {
          const { component, list } = behaviors[0];
          if (!component) {
            return;
          }
          if (list.length === 1) {
            const behaviorsList = list[0].behaviors;
            if (!behaviorsList.length) {
              return;
            }
          }
        }
        behaviors.forEach(behavior => {
          if (behavior.component) {
            this.$store.commit('triggerEvents', {
              config: {
                behavior,
                isBehavior: true
              },
              element: item,
              EDITOR_pageUUID: this.EDITOR_pageUUID
            });
          }
        });
      }
    },
    /**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
    updatePageStatus(arr = [], element) {
      window.$EditorDebug.startPageStatus(element);
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) {
          window.$EditorDebug.failPageStatus(element, ele.code);
          return;
        }
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(comp) {
      if (!comp) {
        window.$EditorDebug.descEEActionParam(1);
        return {};
      }
      window.$EditorDebug.descEEActionParam(2);
      const sourceConfig = comp.sourceConfig || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = ''
        } = sourceConfig[i];
        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (result && Object.prototype.toString.call(result) === '[object Object]') {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(this.subComponentData, componentId);
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(this.groupComponents, componentId);
          }
          const componentName = componentId.toString().split('-')[0];
          if (field === 'DATAVIEWSELECT' && ['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)) {
            this.$set(sourceParams, key, sourceComponent?.resolveData || []);
          } else {
            const result = sourceComponent?.resolveData?.[field] || '';
            this.$set(sourceParams, key, result);
          }
        }
      }
			window.$EditorDebug.successEEActionParam(sourceParams, comp.sourceConfig);
      return sourceParams;
    },
    /**
     * @description: 触发全局loading
     */
    triggerGlobalLoading(isClose) {
      eventBus.$emit('dataviewGlobalLoading', {
        loading: isClose
      });
    }
  }
}