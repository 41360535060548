import { render, staticRenderFns } from "./CommonImage.vue?vue&type=template&id=58a8f244&scoped=true"
import script from "./CommonImage.vue?vue&type=script&lang=js"
export * from "./CommonImage.vue?vue&type=script&lang=js"
import style0 from "./CommonImage.vue?vue&type=style&index=0&id=58a8f244&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a8f244",
  null
  
)

export default component.exports