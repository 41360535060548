<!--
 * @Description: 表单(页面筛选器)
 * @Author: luocheng
 * @Date: 2021-09-23 17:22:31
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-04-08 15:41:17
-->
<template>
	<div class="common-form">
		<!-- filterForm依赖注入渲染问题 -->
		<span v-show="false">{{ filterForm }}</span>
		<template v-if="
			fieldsForView &&
			fieldsForView.length &&
			filterForm &&
			Object.keys(filterForm).length
		">
			<el-form
				:inline="true"
				:model="filterForm"
				class="form-inline"
				ref="filterForm"
			>
				<!-- eslint-disable -->
				<el-form-item
					:label="item.viewConfig.name"
					v-for="item in fieldsForView"
					:key="item.id"
					v-if="
						judgingArchiAPermission(
              item.viewConfig && item.viewConfig.archiLimit,
              item.viewConfig && item.viewConfig.permission
            )
						&& judgingUILogic(item.viewConfig.UILogic, subComponentData)
					"
				>
					<!-- 输入框 -->
					<el-input
						v-model="filterForm[item.uuid]"
						:placeholder="'请输入' + item.viewConfig.name"
						v-if="item.viewConfig.type === 'input'
							&& item
						"
						clearable
						:class="{
							'is-radius': item.viewConfig.isRadius
						}"
						@input="onResolve"
					></el-input>
					<!-- 时间点、时间段 -->
					<template v-if="
							item.viewConfig.type === 'datepicker' ||
							item.viewConfig.type === 'during'
						">
						<!-- 时间 -->
						<el-time-picker
							v-if="item.viewConfig.dateType === 'time'"
							v-model="filterForm[item.uuid]"
							:picker-options="{
								selectableRange: '00:00:00 - 23:59:00',
							}"
							clearable
							value-format="HH:mm:ss"
							:placeholder="'请选择' + item.viewConfig.name"
							@change="onResolve"
							:class="{
							'is-radius': item.viewConfig.isRadius
						}"
						></el-time-picker>
						<!-- 时间或日期时间 -->
						<!--  (item.viewConfig.dateType || 'daterange') -->
						<el-date-picker
							v-else
							v-model="filterForm[item.uuid]"
							:type="item.viewConfig.type === 'during' ? 'daterange' : item.viewConfig.dateType"
							clearable
							:placeholder="'请选择' + item.viewConfig.name"
							:value-format="item.viewConfig.dateFormat || 'yyyy-MM-dd HH:mm:ss'"
							range-separator="至"
							start-placeholder="开始时间"
							end-placeholder="结束时间"
							@change="onResolve"
							:class="{
							'is-radius': item.viewConfig.isRadius
						}"
						></el-date-picker>
					</template>
					<!-- 筛选项 -->
					<template v-if="item.viewConfig.type === 'select'">
						<el-select
							v-model="filterForm[item.uuid]"
							:placeholder="'请选择' + item.viewConfig.name"
							clearable
							@change="onResolve"
							:class="{
							'is-radius': item.viewConfig.isRadius
						}"
						>
							<template v-if="item.viewConfig.optionOrign === 'fixed'">
								<el-option
									v-for="option in item.viewConfig.options"
									:key="option.value"
									:label="option.label"
									:value="option.value"
								></el-option>
							</template>
							<template v-else-if="item.viewConfig.selectOptions && item.viewConfig.selectOptions.length">
								<el-option
									v-for="option in item.viewConfig.selectOptions"
									:key="option.value"
									:label="option.label"
									:value="option.value"
								></el-option>
							</template>
						</el-select>
					</template>
					<!-- tab切换 -->
					<template v-if="item.viewConfig.type === 'tabs'">
						<ul class="tabs-list">
							<li
								class="tab-item"
								v-for="option in item.viewConfig.options"
								:key="option.value"
								:class="{
									'is-active': filterForm[item.uuid] === option.value
								}"
								@click="onTab(option.value, item.uuid)"
							>
								<p class="text">{{ option.label }}</p>
								<template v-if="option.widthStatistics">
									<StaticCount
										v-for="(staticItem, staticIndex) in option.statisticsConfig"
										:key="staticIndex"
										:config="staticItem"
									></StaticCount>
								</template>
							</li>
						</ul>
					</template>
				</el-form-item>
				<el-form-item v-if="statusConfig.showButton || statusConfig.showButton === undefined">
					<el-button
						v-for="action in actionConfig"
						:key="action.type + Math.random()"
						:type="action.btnType"
						:icon="action.icon"
						v-show="action.show
							&& judgingArchiAPermission(action && action.archiLimit, action && action.permission)
							&& judgingUILogic(action.UILogic, subComponentData)
						"
						@click="onAction(action)"
					>{{ action.label }}</el-button>
				</el-form-item>
			</el-form>
		</template>
	</div>
</template>

<script>
import { DatePicker, TimePicker } from 'element-ui';
import { formatDate } from 'element-ui/src/utils/date-util';
import eventBus from '@/plugins/eventBus';
import StaticCount from './filterChildren/StaticCount';
import { judgingArchiAPermission, judgingUILogic, doEEActionHandle, getComponentById, getQueryValue, judgingEventLogic, getStatusVal, getLinkByTemplate } from '@/utils/tools';
import { dataInterface } from '@/apis/data';
import { mapState } from 'vuex';

export default {
	name: 'CommonForm',
	components: {
		'el-time-picker': TimePicker,
		'el-date-picker': DatePicker,
		StaticCount
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		componentList: {
			default: null
		},
		// 是否为分组
    isGroup: {
      type: Boolean
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
	},
	data() {
		return {
			filterForm: null, // 过滤数据
			lastSearch: []
		};
	},
	inject: ['EDITOR_pageUUID'],
	computed: {
		...mapState([
			'componentData',
			'subsidiaryComponentData',
      'sContainer',
      'originComponentData'
		]),
		// 字段列表
		fieldList() {
			return  this.element?.database?.fieldList || []
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 配置
		statusConfig() {
			return this.element.statusConfig;
		},
		// 取值组件列表
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
		}
	},
	watch: {
		// 监听变化重新整理需要装填的格式
		fieldList: {
			handler() {
				this.fieldsForView = [];
				if (!this.fieldList || !this.fieldList.length) return [];
				const result = [];
				// 暂不做校验
				this.fieldList.forEach((ele) => {
					if (ele.viewConfig.showInFilter) {
						result.push(ele);
					}
				});
				this.fieldsForView = result || [];
				// 处理字段
				this.filterForm = {};
				let initSearch = false;
				this.fieldsForView.forEach(async (ele, index, arr) => {
					let defaultVal = '';
					let selectOptions = []
					// tabs 筛选默认选中
					if (ele.viewConfig && (ele.viewConfig.type === 'tabs' || ele.viewConfig.type === 'select')) {
						const { optionOrign, options = [], optionConfig} = ele.viewConfig;
						if (optionOrign === 'fixed') {
							defaultVal = options.find(ele => ele.defaultSelect)?.value;
							initSearch = true;
							selectOptions = options;
							this.$set(ele, 'selectOptions', selectOptions);
						} else if (optionOrign === 'database') {
							await this.getDataOptions(optionConfig, ele, index, JSON.parse(JSON.stringify(arr)));
							if (optionConfig.popAll) {
								initSearch = true;
							}
						}
					} else if (['datepicker', 'during'].includes(ele.viewConfig?.type) && ele.viewConfig?.defaultDate) {
						// 时间，时间段
						const { type, defaultDate } = ele.viewConfig;
						const now = new Date();
						if (type === 'datepicker') {
							// 时间点
							if (defaultDate === 'today') {
								// defaultVal = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
								defaultVal = formatDate(now, ele.viewConfig.dateFormat)
							}
						} else if (type === 'during') {
							// 时间段
							// 结束时间
							const endTime = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 23:59:59`;
							let startTime = 0;
							if (defaultDate === 'todayWeek') {
								startTime = (new Date(endTime)) - 7 * 24 * 60 * 60 * 1000 + 1000;
							} else if (defaultDate === 'todayMonth') {
								startTime = (new Date(endTime)) - 30 * 24 * 60 * 60 * 1000 + 1000;
							} else if (defaultDate === 'todayYear') {
								startTime = (new Date(endTime)) - 365 * 24 * 60 * 60 * 1000 + 1000;
							} 
							const startDate = new Date(startTime);
							// defaultVal = [`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()} 00:00:00`,
							// 	endTime
							// ];
							defaultVal = [formatDate(startDate, ele.viewConfig.dateFormat), formatDate(new Date(endTime), ele.viewConfig.dateFormat)]
						}
					}
					this.$set(this.filterForm, ele.uuid, defaultVal);
				});
				this.$nextTick(() => {
					this.$refs && this.$refs.filterForm && this.$refs.filterForm.resetFields();
					if (initSearch) {
						// 基本避免请求覆盖问题
						setTimeout(() => {
							this.onResolve();
						}, 1000)
					}
				});
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.onResolve();
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 数仓options
		 * @param {*} optionConfig
		 * @param {*} itemData
		 * @param {*} index
		 */
		getDataOptions(optionConfig, itemData, index) {
			return new Promise((resolve, reject) => {
				dataInterface({
					__method_name__: 'dataList',
					object_uuid: optionConfig.objectUUID,
					view_uuid: optionConfig.viewUUID,
				}).then(res => {
					let data = [];
					if (Array.isArray(res?.data?.data?.data)) {
						data = res?.data?.data?.data || [];
					} else {
						data = Array.isArray(res?.data?.data) ? res?.data?.data : [];
					}
					const selectOptions = data.map(ele => {
						return {
							label: ele[optionConfig.labelCode],
							value: ele[optionConfig.valueCode]
						}
					});
					if (optionConfig.popAll) {
						selectOptions.unshift({
							label: '全部',
							value: ''
						})
					}
					try {
						this.fieldsForView.splice(index, 1, {
							...itemData,
							viewConfig: {
								...itemData.viewConfig,
								selectOptions
							}
						})
						if (optionConfig.defaultSelect) {
							const defaultVal = selectOptions[0].value || '';
							this.$set(this.filterForm, itemData.uuid, defaultVal);
						}
						resolve()
					} catch (err) {
						console.log(err);
					}
				}).catch(err => {
					console.log(err);
					reject(null)
				})
			})
		},
		// 选中tab值
		onTab(value, uuid) {
			this.$set(this.filterForm, uuid, value)
			this.onResolve();
		},
		/**
		 * @desc: 点击操作
		 * @param {Object} item 操作配置
		 */
		onAction(item) {
			const type = item && item.type;
			if (!type) return;
			this.triggerEvents(item);
			if (type === 'search') {
				this.onSearch();
			} else if (type === 'reset') {
				this.onReset();
			} else if (type === 'export') {
				// 导出
				// this.onExport(item);
			} else if (type === 'add') {
				// 新增
				// 新增时候需要选择关联的数据
			}
		},
		/**
		 * @desc: 触发事件
		 * @param {Object} item
		 */
		triggerEvents(item) {
			if (item.type === 'search' || item.type === 'reset') return;
			const comEvents = item.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.triggerEventItem(comEvents[i], item);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.triggerEventItem(ele, item, pattern);
				});
			}
		},
		/**
		 * @desc: 触发单个事件
		 */
		triggerEventItem(ele, item, pattern) {
			if (ele?.key === 'click' || pattern === 'special') {
				if (ele.actionType === 'eeAction') {
					// 后端事件
					this.doEEAction(ele);
				} else if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						ele.queryList.forEach((queryItem) => {
							let component = getComponentById(this.subComponentData, queryItem.componentId);
							if (!component && this.isGroup && this.groupComponents.length) {
								component = getComponentById(this.groupComponents, queryItem.componentId);
							}
							this.$set(
								query,
								queryItem.key,
								component.resolveData[queryItem.feild]
							);
						});
						this.$router.push({
							path: ele.pageId,
							query
						});
						// 基座设置
						// if (ele.routePath) {
						// 	this.$router.replace({
						// 		path: ele.routePath,
						// 		query
						// 	});
						// }
						return;
					} else if (ele.linkType === 'outPage') {
						window.open(ele.linkUrl);
					} else if (ele.linkType === 'custom') {
						const customLink = getLinkByTemplate(ele.linkTemplate, {})
						window.open(customLink);
					}
				} if (ele.actionType === 'pageStatus') {
					// 修改页面状态
					this.updatePageStatus(ele.stateMachine || []);
				}else {
					// 常规操作
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
								...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			}
			// 导出
			if (ele?.actionType === 'export') {
				this.onExport(item);
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			let componentData = this.sContainer ? this.originComponentData : this.componentData;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) return;
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			})
		},
		/**
		 * @desc: 触发后端事件
		 */
		doEEAction(btnConfig) {
			if (!btnConfig || btnConfig.actionType !== 'eeAction') return;
			const { objectUUID, viewUUID, eventName, sourceList, eeType, interfaceUrl } = btnConfig;
			if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
				this.$message.error('请正确配置事件!');
				return false;
			}
			this.loading = true;
			const sourceParams = this.getBindParams(sourceList);
			// 蓝图
			if (eeType === 'blueprint') {
				dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				}).catch(() => {
					this.loading = false;
				})
				return true;
			}
			dataInterface({
				__method_name__: 'customEventCall',
				object_uuid: btnConfig.objectUUID,
				view_uuid: btnConfig.viewUUID,
				event: btnConfig.eventName,
				...sourceParams
			}).then((res) => {
				if (res.status === 200 && res.data.code === 200) {
					this.$message.success('操作成功！');
					doEEActionHandle(res.data?.__adds__);
				}
				this.loading = false;
			}).catch((err) => {
				console.log(err, '00000后端事件错误');
				this.loading = false;
			});
		},
		/**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(sourceConfig) {
      if (!Array.isArray(sourceConfig)) return {};
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = ''
        } = sourceConfig[i];
        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (result && Object.prototype.toString.call(result) === '[object Object]') {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(this.subComponentData, componentId);
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(this.groupComponents, componentId);
          }
					const result = sourceComponent?.resolveData?.[field] || '';
          this.$set(sourceParams, key, result);
        }
      }
      return sourceParams || {};
    },
		/**
		 * @desc: 暴露数据
		 */
		onResolve() {
			const resolveData = [];
			this.fieldsForView.forEach((ele) => {
				const value = this.filterForm[ele.uuid] || '';
				let valueFilter = value;
				if (Array.isArray(value) && value.length === 2) {
					// 时间段
					valueFilter = this.formatDuring(value);
				} else if (ele.viewConfig.ruleType === 'in') {
					// 包含
					if (value && value.toString().toString && value.toString().indexOf('[') !== 0 && value.toString().indexOf(']') !== value.toString().length - 1) {
						valueFilter = value !== '' && value.toString().split(',');
					} else {
						valueFilter = value;
					}
				}
				resolveData.push({
					field_uuid: ele.uuid,
					ruleType: ele.viewConfig.ruleType,
					value: valueFilter
				});
			});
			this.element.resolveData = resolveData;
			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData
			});
			// 自动提交
			if (this.statusConfig.quickSearch) {
				this.onSearch(false);
			}
		},
		/**
		 * @desc: 提交过滤查询
		 */
		onSearch(resolveData = true) {
			const search = [];
			this.fieldsForView.forEach((ele) => {
				const value = this.filterForm[ele.uuid] || '';
				let valueFilter = value;
				if (Array.isArray(value) && value.length === 2) {
					// 时间段
					valueFilter = this.formatDuring(value);
				} else if (ele.viewConfig.ruleType === 'in') {
					// 包含
					valueFilter = value.toString().split(',');
				}
				search.push({
					field_uuid: ele.uuid,
					ruleType: ele.viewConfig.ruleType,
					// 老的处理逻辑下行(暂弃用)
					// value: Array.isArray(value) && value.length === 2 ? this.formatDuring(value) : value
					value: valueFilter
				});
			});
			// 需要暴露数据
			if (resolveData) {
				this.onResolve();
			}
			// 相同参数问题
			//  && !this.statusConfig.quickSearch
			if (JSON.stringify(this.lastSearch) === JSON.stringify(search)) {
				return;
			}
			this.lastSearch = search || [];
			// 提交
			eventBus.$emit('databaseTrigger', {
				componentId: this.element.id,
				action: 'list',
				isSearch: true,
				output: {
					search
				}
			});
		},
		/**
		 * @desc: 导出
		 */
		onExport(config) {
			if (!config) return false;
			const configList = config.eventList;
			if (!configList && !configList.length) return false;
			for (let i = 0; i < configList.length; i++) {
				const { pattern , eventList = []} = configList[i];
				if (pattern === undefined) {
					// 提交
					eventBus.$emit('exportData', {
						...configList[i],
						componentId: configList[i].exportTarget,
						action: 'export'
					});
				} else {
					// 提示
					eventList.forEach(ele => {
						if (ele.actionType === 'export') {
							eventBus.$emit('exportData', {
								...ele,
								componentId: ele.exportTarget,
								action: 'export'
							});
						}
					});
				}
			}
		},
		/**
		 * @desc: 格式化时间段
		 */
		formatDuring(timeArr) {
			if (Array.isArray(timeArr) && timeArr.length === 2) {
				const item = timeArr[1];
				if (typeof item !== 'object' && item.toString().indexOf('00:00:00') > -1) {
					const result = JSON.parse(JSON.stringify(timeArr));
					result.splice(1, 1, timeArr[1].toString().replace('00:00:00', '23:59:59'));
					return result;
				}
			}
			return timeArr || '';
		},
		// 重置
		onReset() {
			for (let key in this.filterForm) {
				this.filterForm[key] = '';
				this.$set(this.filterForm, key, '');
			}
			this.onSearch();
		}
	}
};
</script>

<style lang="less" scoped>
.common-form {
	width: 100%;
	text-align: left;
	:deep(.el-form-item) {
		margin-bottom: 10px;
		.tabs-list {
			display: flex;
			.tab-item {
				// min-width: 106px;
				height: 32px;
				padding: 5px 12px;
				display: flex;
				background: #f2f4f8;
				box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.08);
				border-radius: 20px;
				margin-right: 8px;
				color: #4d5358;
				box-sizing: border-box;
				transition: all .1s;
				&.is-active{
					color: #121619;
					background: #D0E2FF;
				}
				p {
					font-weight: 400;
					line-height: 22px;
					margin-right: 5px;
					font-family: PingFangSC-Regular, PingFang SC;
				}
			}
		}
		.el-input, .el-select,.el-date-editor{
			&.is-radius{
					border-radius: 32px;
				.el-input__inner{
					border-radius: 32px;
				}
			}
		}
	}
}
</style>
