export default class PlotEvents {
    constructor() {}

    static getEvents(viewer, comp) {
        return {
            'ViewerGIS.getPlotOptions': (eid, type, options, cb) => {
                if (eid !== comp.element.id) return;

                let res = viewer.Plot.getPlotOptions(type, options);

                cb && cb(res);
            },
            'ViewerGIS.createPlot': (eid, key, data, isShow = true) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.createPlot(key, data, isShow);
            },
            'ViewerGIS.updatePlot': (eid, key, data) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.updatePlot(key, data);
            },
            'ViewerGIS.removeAllPlot': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.removeAllPlot();
            },
            'ViewerGIS.removePlot': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.removePlot(key);
            },
            'ViewerGIS.showPlot': (eid, key, isShow) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.showPlot(key, isShow);
            },
            'ViewerGIS.locationAllGroup': (eid, keys) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.locationAllGroup(keys);
            },
            'ViewerGIS.locationPlot': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.locationPlot(key);
            },

            'ViewerGIS.createKmlByLocal': (eid, key, cb) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.createKmlByLocal(key).then((res) => {
                    cb && cb(res);
                });
            },
            'ViewerGIS.createKmlByServe': (eid, key, data, isShow = true, cb) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.createKmlByServe(key, data, isShow).then((res) => {
                    cb && cb(res);
                });
            },
            'ViewerGIS.saveKmlByLocal': (eid, key, name, cb) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.saveKmlByLocal(key, name).then((res) => {
                    cb && cb(res);
                });
            },
            'ViewerGIS.updateKml': (eid, key, data) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.updateKml(key, data);
            },
            'ViewerGIS.removeAllKml': (eid) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.removeAllKml();
            },
            'ViewerGIS.removeKml': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.removeKml(key);
            },
            'ViewerGIS.showKml': (eid, key, isShow) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.showKml(key, isShow);
            },
            'ViewerGIS.showKmlItem': (eid, key, itemKey, isShow) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.showKmlItem(key, itemKey, isShow);
            },
            'ViewerGIS.locationKml': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.locationKml(key);
            },
            'ViewerGIS.locationKmlItem': (eid, key, itemKey) => {
                if (eid !== comp.element.id) return;

                viewer.Plot.locationKml(key, itemKey);
            },
        };
    }
}
