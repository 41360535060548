<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2024-07-09 16:48:55
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-next\src\custom-component\bindModel\ButtonType.vue
-->
<template>
  <!-- 绑定模型 -->
  <div>
    <slot name="showType"></slot>
    <el-drawer
      custom-class="no-padding"
      :visible.sync="show"
      size="100%"
      append-to-body
      :with-header="false"
    >
      <BindModel
        :appId="appId"
        :appSecret="appSecret"
        :choose-type="chooseType"
        :search="search"
        @back="show = false"
        @set-data="setData"
        v-if="show"
        :modelData="modelData"
        :config="config"
        :bodyParams="bodyParams"
      />
    </el-drawer>
  </div>
</template>

<script>
import BindModel from "@/custom-component/bindModel/index.vue";
import { Drawer } from "element-ui";

export default {
  name: "ButtonBind",
  components: {
    BindModel,
    "el-drawer": Drawer,
  },
  props: {
    modelData: {
      type: [Array, String],
      default: () => [],
    },
    //模型和视点model/view
    chooseType: {
      type: String,
      default: "model",
    },
    appId: {
      type: String,
      default: "",
    },
    appSecret: {
      type: String,
      default: "",
    },
    search: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    bodyParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
      data: [{ model_id: "cecabbe26e87459c9926f6604fb7ab4e", select: [12] }],
    };
  },
  methods: {
    setData(data) {
      console.log(data, "模型");
      this.$emit("set-data", data);
      this.show = false;
    },
  },
  mounted() {},
};
</script>
<style lang="less">
.no-padding {
  .el-drawer__body {
    padding: 0 !important;
  }
}
</style>
