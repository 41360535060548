<!--
    @name: TaskProps
    @description：TaskProps
    @author: ZengWei
    @date: 2022-05-13 10:11
-->
<template>
  <div class="prop-container">
    <i class="iconfont iconc-close" @click="close"></i>
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane v-if="['task','rect'].includes(nodeType)" label="任务属性" name="info">
        <div class="scrollbar">
          <div class="label-title">任务基本信息</div>
          <el-form ref="form" :rules="rules" :model="task" label-position="top" label-width="80px">
            <el-form-item label="任务名称" prop="name">
              <el-input :disabled="editMode" v-model="task.name" @change="onChange"></el-input>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-radio-group :disabled="editMode" v-model="task.status" @change="onChange">
                <el-radio :label="0">
                  <span class="task-status" :style="{background: taskColor.color0}">未开始</span>
                </el-radio>
                <el-radio :label="1">
                  <span class="task-status" :style="{background: taskColor.color1}">进行中</span>
                </el-radio>
                <el-radio :label="2">
                  <span class="task-status" :style="{background: taskColor.color2}">已完成</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
<!--            <el-form-item label="显示预警">
              <el-radio-group :disabled="editMode" v-model="task.warning">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
              </el-radio-group>
            </el-form-item>-->
          </el-form>

          <div class="label-title">{{task.deadline_name}}</div>
          <el-form class="form-table" label-position="left" label-width="80px">
            <el-form-item label="名称">
              <el-input @change="onChange" :disabled="editMode" v-model="task.deadline_name"></el-input>
            </el-form-item>
            <el-form-item label="日期">
              <el-date-picker
                :disabled="editMode"
                v-model="task.deadline"
                style="width: 100%"
                type="date"
                @change="onChange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="显示">
              <el-radio-group @change="onChange" :disabled="editMode" v-model="task.deadline_show">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>

          <div class="label-title" style="margin-top: 18px">自定义任务内容</div>
          <el-form label-position="left" class="form-table" label-width="80px">
            <template v-for="(item,index) in task.content">
              <div :key="index">
                <div class="clearfix self-define">
                  <span>{{index>9 ? index : '0'+(index+1)}}</span>
                  <span class="float-r" @click="delContent(index)">
                    <i class="el-icon-delete"></i>
                  </span>
                  <span class="float-r" @click="upGo(index)">
                    <i class="el-icon-top"></i>
                  </span>
                  <span class="float-r" @click="downGo(index)">
                    <i class="el-icon-bottom"></i>
                  </span>
                </div>
                <el-form-item label="类型">
                  <el-radio-group :disabled="editMode" class="self-type" v-model="item.type">
                    <el-radio label="input">文本</el-radio>
                    <el-radio label="upload">上传</el-radio>
                    <el-radio label="date">日期</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="名称">
                  <el-input :disabled="editMode" v-model="item.name" @change="onChange"></el-input>
                </el-form-item>
                <el-form-item label="显示">
                  <el-radio-group :disabled="editMode" v-model="item.show" @change="onChange">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="内容">
                  <template v-if="item.type === 'input'">
                    <el-input :disabled="editMode" type="textarea" v-model="item.value" @change="onChange"></el-input>
                  </template>
                  <template v-if="item.type === 'upload'">
                    <uploader :disabled="editMode" :files="item.attach" @extra-files="onSuccess($event,index)"></uploader>
                  </template>
                 <template v-if="item.type === 'date'">
                   <el-date-picker
                     :disabled="editMode"
                     v-model="item.value"
                     type="date"
                     placeholder="选择日期"
                     format="yyyy-MM-dd">
                   </el-date-picker>
                 </template>
                </el-form-item>
              </div>
            </template>
            <el-button :disabled="editMode" @click="addContent" style="width: 100%;margin: 15px 0" icon="el-icon-plus">添加新字段</el-button>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="['rect','task'].includes(nodeType)" label="附件" name="attach">
        <div class="scrollbar">
          <div class="label-title">附件列表</div>
          <div class="file-list">
            <FilesShower :files="files" />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="样式" name="style">
        <div class="scrollbar">
          <div class="label-title">基本样式</div>
          <div v-if="nodeType === 'edge'">
            <el-form ref="form" label-position="top" label-width="80px">
              <el-form-item label="文字内容">
                <el-input :disabled="editMode" v-model="task.name" @change="onChange"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="nodeType === 'text-block'">
            <el-form ref="form" label-position="top" label-width="80px">
              <el-form-item label="文字内容">
                <el-input :disabled="editMode" v-model="task.name" @change="onChange"></el-input>
              </el-form-item>
              <el-form-item label="文字大小颜色">
                <div class="text-size-color">
                  <div class="size">
                    <el-input :disabled="editMode" type="number" v-model="task.fontSize" @change="onChange"></el-input>
                  </div>
                  <div class="color">
                    <el-color-picker :disabled="editMode" v-model="task.fontColor" @change="onChange"></el-color-picker>
                  </div>
                </div>

              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {Tabs,TabPane,RadioGroup,Radio,DatePicker,ColorPicker} from "element-ui";
import uploader from "@/components/fileUpload/newFiles/index";
import FilesShower from './FilesShower'
export default {
  name: "TaskProps",
  components: {
    uploader,
    'el-color-picker': ColorPicker,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
    'el-date-picker': DatePicker,
    FilesShower
  },
  props: ['currentItem','currentNodeId','nodeType','formNode','editMode'],
  data() {
    return {
      activeTab:'info',
      archId: 0,
      taskColor:{
        color0: '#7E8595',
        color1: '#EF9818',
        color2: '#40B67C',
      },
      task: {
        name: '任务名称',
        status: 0,
        warning: false,
        color: '#7E8595',
        deadline_name: '计划完成时间',
        deadline: '',
        deadline_show: true,
        content: [],
        fontSize: 14,
        fontColor: '#000000'
      },
      rules: {
        name: [
          { required: true, message: '请输入任务名称', trigger: 'blur' },
        ],
      },
      departList:[], // 部门数据
      roleList:[], // 岗位数据
      objectList: [],
      objectEventList: [],
      funcFormList: [],
      objectFormList: [],
      allFormFields:[],
    }
  },
  watch: {
    nodeType(){
      this.activeTabs()
    },
  },
  computed: {
    files() {
      const data = this.task.content.filter(el => el.type === 'upload')
      const res = []
      data.map(el => {
        if(el && el.attach && Array.isArray(el.attach)) {
          res.push(...el.attach)
        }
      })
      return res
    }
  },
  created() {

  },
  mounted() {
    this.activeTabs()
    this.initPropsData()
  },
  methods: {
    initPropsData(){
      const name = ['text-block','edge'].includes(this.nodeType) ? '文本文字' : '任务名称';
      this.task.name = this.currentItem?.name || name
      this.task.fontSize = this.currentItem?.fontSize || '14'
      this.task.fontColor = this.currentItem?.fontColor || '#000000'
      this.task.status = this.currentItem?.status || 0
      this.task.warning = this.currentItem?.warning || false
      this.task.color = this.currentItem?.color || '#7E8595'
      this.task.deadline_name = this.currentItem?.deadline_name || '计划完成时间'
      this.task.deadline = this.currentItem?.deadline || ''
      this.task.deadline_show = this.currentItem?.deadline_show || false
      this.task.content = this.currentItem?.content || []
    },
    onSuccess(file,index){
      this.task.content[index].attach = file
      console.log(this.task.content[index], 444)
    },
    activeTabs(){
      if(this.nodeType === 'task' || this.nodeType === 'rect'){
        this.activeTab = 'info'
      } else if(this.nodeType === 'text-block'){
        this.activeTab = 'style'
      } else {
        this.activeTab = 'style'
      }
    },
    addContent(){
      const newData = {type: 'input', name: '',value:'',attach:[], show: true}
      this.task.content.push(newData)
      this.onChange()
    },
    delContent(index){
      this.task.content.splice(index,1);
      this.onChange()
    },
    onChange(){
      let data = {}
      if(['edge','gateway'].includes(this.nodeType)){
        const {name, run_logic} = this.task
        data = {name, run_logic}
      } else {
        let color = 'color' + this.task.status
        this.task.color = this.taskColor[color]
        data = this.task
      }

      console.log(data,112222)

      this.$emit('on-change',data)
    },
    close(){
      this.$emit('on-close')
    },
    upGo(index){
      let fieldData = this.task.content;
      if(index!=0){
        fieldData[index] = fieldData.splice(index-1, 1, fieldData[index])[0];
      }else{
        fieldData.push(fieldData.shift());
      }
      this.task.content = fieldData;
      this.onChange();
    },
    downGo(index){
      let fieldData = this.task.content;
      if(index != fieldData.length-1){
        fieldData[index] = fieldData.splice(index+1, 1, fieldData[index])[0];
      }else{
        fieldData.unshift( fieldData.splice(index,1)[0]);
      }
      this.task.content = fieldData;
      this.onChange();
    },
  },
}
</script>

<style lang="less" scoped>
.scrollbar{
  overflow-y: auto;
  padding: 15px 15px 0 15px;
  height: calc(100% - 15px);
}

:deep(.el-tabs--border-card) {
  border-left: 1px solid #DCDFE6;
  border-top: none;
  border-right: none;
  border-bottom: none;
}

:deep(.form-table) {
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    /*margin-bottom: -1px !important;*/

    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      /*margin-bottom: 1px;*/

      .el-select,
      .el-cascader {
        width: 100%;
      }

      .self-type {
        .el-radio{
          margin-right: 18px;
        }
      }
    }
  }
}

.text-size-color{
  display: flex;
  .size{
    width: 85%;
    margin-right: 12px;
  }
}

.label-title{
  border-left: 3px solid var(--themeColor);
  padding: 0 10px;
  line-height: 1;
  margin: 0 0 16px;
  font-weight: 600;
}

.clearfix:after{
  content: '';
  display: block;
  clear: both;
}

.select-item{
  width: 100%;
}
.mb-10{
  margin-bottom: 10px;
}

.float-r{
  float: right;
  padding: 0 1px;
  cursor: pointer;
}
.self-define{
  margin: 8px 0;
  text-indent: 2px;
}

:deep(.user-type-radio) {
  .el-radio{
    width: 76px;
    margin-bottom: 15px;
  }
}

:deep(.task-run-radio) {
  .el-radio{
    width: 125px;
    margin-bottom: 15px;
  }
}


.prop-container{
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  :deep(.el-tabs) {
    height: 100%;
    .el-tabs__content{
      padding: 0;
      height: calc(100% - 40px);
      .el-tab-pane{
        height: 100%;
      }
    }
  }

  .iconc-close{
    position: absolute;
    font-size: 22px;
    top: 5px;
    right: 8px;
    z-index: 10;
    cursor: pointer;
  }

  .property{
    height: calc(100% - 52px);
  }

  .task-status{
    padding: 5px 7px;
    color: white;
  }
}
</style>
