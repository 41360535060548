<!--
 * @Description: 操作菜单
 * @Author: luocheng
 * @Date: 2022-03-02 10:52:35
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-12-01 17:21:22
-->
<template>
  <el-dropdown placement="bottom" v-if="statusConfig.showActions">
    <i class="setup iconfont iconwangpan-shezhi1x"></i>
    <el-dropdown-menu slot="dropdown">
      <!-- <el-dropdown-item v-if="statusConfig.showInfoBtn && showAction('details')">
        <el-button type="text" @click="onAction('details')">
          <i class="iconfont iconwangpan-xiangqing1x"></i><span>详情</span>
        </el-button>
      </el-dropdown-item> -->
      <div v-for="(item, key) in actionConfig" :key="key">
        <el-dropdown-item v-if="!innerAction.includes(key) && judgingArchiAPermission(item.archiLimit, item.permission, rowData)">
          <el-button type="text" @click="onAction(key)">
            <i :class="['iconfont', item.icon]"></i><span>{{ item.name }}</span>
          </el-button>
        </el-dropdown-item>
      </div>
      <el-dropdown-item v-if="statusConfig.showDownloadBtn && showAction('download') && +rowData.type_id !== 1 && +rowData.type_id !== 3">
        <el-button type="text" @click="onAction('download')">
          <i class="iconfont iconwangpan-xiazai1x"></i><span>下载</span>
        </el-button>
      </el-dropdown-item>
      <el-dropdown-item v-if="statusConfig.showShareBtn && showAction('share')">
        <el-button type="text" @click="onAction('share')">
          <i class="iconfont iconwangpan-fenxiang1x"></i><span>分享</span>
        </el-button>
      </el-dropdown-item>
      <el-dropdown-item  :divided="+rowData.type_id === 2"  v-if="statusConfig.showMoveBtn && showAction('move')">
        <el-button type="text" @click="onAction('move')">
          <i class="iconfont iconwangpan-yidong1x"></i><span>移动</span>
        </el-button>
      </el-dropdown-item>
      <el-dropdown-item v-if="statusConfig.showModifyBtn && showAction('rename')">
        <el-button type="text" @click="onAction('modify')">
          <i class="iconfont iconwangpan-zhongmingming1x"></i><span>
            <!-- 重命名 -->
            {{ +rowData.type_id !== 3 ? '重命名' : '编辑' }}
          </span>
        </el-button>
      </el-dropdown-item>
      <el-dropdown-item v-if="+rowData.type_id === 4 && rowData.path">
        <el-button type="text" @click="onAction('replace')">
          <i class="iconfont icontihuan"></i><span>
            替换
          </span>
        </el-button>
      </el-dropdown-item>
      <el-dropdown-item divided v-if="statusConfig.showRemoveBtn && showAction('delete')">
        <el-button type="text" class="remove" @click="onAction('delete')">
          <i class="iconfont iconwangpan-shanchu1x"></i><span>删除</span>
        </el-button>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { judgingArchiAPermission } from '@/utils/tools';

export default {
  name: 'ActionMenu',
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
  },
  props: {
    rowData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  inject: ['element'],
  data() {
    return {
      innerAction: ['detail', 'details', 'download', 'share', 'move', 'rename', 'delete', 'replace', 'export', 'add', 'modify', 'plus', 'create', 'upload']
    }
  },
  computed: {
    actionConfig() {
      return this.element?.actionConfig;
    },
    statusConfig() {
      return this.element?.statusConfig;
    }
  },
  methods: {
    judgingArchiAPermission,
    /**
     * @desc: 操作
     * @param {String} type 类型
     */
    onAction(type) {
      this.$emit('action', {
        type,
        rowData: this.rowData
      });
    },
    /**
     * @desc: 是否显示操作
     * @param {String} type 操作类型
     * @return {Boolean}
     */
    showAction(type) {
      const item = this.actionConfig[type];
     
      if (!item) return false;
      const { use, archiLimit = [], permission = [] } = item;
      if (!use) {
        return false;
      }
      return judgingArchiAPermission(archiLimit, permission);
    }
  }
}
</script>

<style lang="less" scoped>
@removeColor: #E4323B;
.setup{
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px!important;
}
.el-button{
  text-align: right;
  color: #606266;
  display: flex;
  vertical-align: middle;
  &.remove{
    color: @removeColor;
    &:hover{
      color: @removeColor;
    }
  }
  >span{
    display: flex;
    vertical-align: top;
    i{
      font-size: 14px;
      left: 20px;
      font-weight: 600;
    }
    span{
      left: 20px;
      margin-left: 5px;
      font-size: 14px;
    }
  }
  &:hover{
    color: @theme;
  }
}
</style>