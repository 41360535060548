<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-08-25 16:13:16
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:43:12
 * @FilePath: /dataview-viewer-test/src/console-components/consoleFormNew/components/formCont.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div :class="requestType === 'detail' ? 'console-form-detail' : ''" class="common-form-container console-from-container-new">
		<template v-if="canInitForm">
			<article class="common-form-main">
				<PcParser
					v-if="formDesignData"
					isConsole
					@submit="onFormSubmit"
					:form-data="formDesignData"
					:ref="'common-form-container' + element.id"
					:displayData="displayData"
					@on-close="onEndFlow"
					:editFields="editFields"
					:disabled="formDisabled"
					:needFlow="needFlow"
					:moduleUuid="outParam.formUUID || outParam.objectUUID"
					:needParentFlow="needParentFlow"
					:formMode="requestType"
				>
				</PcParser>
			</article>
		</template>
		<el-empty :description="loading?'数据请求中...':'暂无数据'" v-else></el-empty>
	</div>
</template>

<script>
import PcParser from '@/custom-component/form/newParser/PcParserNew';
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import { getComponentById, getQueryValue } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { Empty } from 'element-ui';

export default {
	name: 'FormInnerCont',
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
		// 附件的数据
		chooseFilesInfo:{
			type: Object,
			default: null
		},
		//选中的bim模型构件信息
		chooseBimInfo:{
			type: Object,
			default: null
		},
		//选中的gis模型构件信息
		chooseGisInfo:{
			type: Object,
			default: null
		},
		//选中的视图信息
		chooseViewInfo: {
			type:Object,
			default:null
		},
		// 外部传入内容配置
		outParam:{
			type: Object,
			default: null
		},
		requestType:{
			type:String,
			default:'add'
		}
	},
	components: {
		PcParser,
		'el-empty': Empty,
	},
	data() {
		return {
			// 表单配置数据
			formDesignData: null,
			displayData: {},
			// 流程中展示字段
      editFields:{},
			// 表单是否disable
			formDisabled: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 是否可以渲染表单
			canInitForm: false,
			// 选中的模型新增
			selection:[],
			//触发多次的计时器
			timer: null,
			//标记传入的id,获取详情
			transferId:'',
			loading: false,
			formConfig:null
		};
	},
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus',
			'_PageStatusList'
		]),
		// 组件属性配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
	},
	watch: {
		outParam:{
			handler() {
				if (!this.outParam || !this.outParam.objectUUID) return;
				this.formDesignData = null
				if(this.requestType === 'add' || this.requestType === 'edit'){
					this.formDisabled = false
				} else {
					this.formDisabled = true
				}
				this.initFormDesign(this.outParam);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		//监听黄旭凯标记点击
		eventBus.$on('ViewerBIM.Marker.onClick',(id) => {
			if(id && id.includes('/')) {
				this.transferId = id.split('/')[2]
				let comp = getComponentById(this.componentData, id.split('/')[1]);
				this.$store.commit('modifyComponent', {
					component: {
						...comp,
						statusConfig:{
							...comp,
							isShow: true,
							displayShow: true
						}
					},
					containerId: null, 
					isModify: true
				});
			}
		})
		this.$nextTick(() => {
			// 监听请求
			//模型返回的状态值监听
			eventBus.$on('Viewer.callback',(id,data) => {
				console.log(id,data)
			})
			//监听模型初始化是否完毕
			eventBus.$on('Viewer.onViewerInited',() => {
				console.log('模型加载完毕')
			})
			//模型加载返回的model_id
			eventBus.$on('Viewer.onLoadedModel',(model_id) => {
				console.log(model_id,'model_id')
			})
			
		});
	},
	methods: {
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			let params = {};
			// 发送请求 整理参数
			const sourceParams = this.getParams();
			const otherParam = this.getOtherData()
			params = {
				__method_name__: this.requestType === 'add'?'createData':'updateData',
				object_uuid: this.outParam.objectUUID,
				...sourceParams,
				// 表单的字段
				...formData,
				...otherParam,
			};
			if(!params.data_id && this.transferId){
				params.data_id = this.transferId
			}
			//取element.id带入的id
			if(params.__method_name__ === 'updateData' && !params.data_id && (this.element.id.includes('-view') || this.element.id.includes('-page'))){
				const arr = this.element.id.split('-');
        const idLastIndex = arr.findIndex(v => v.includes('view') || v.includes('page'));
        if (idLastIndex !== arr.length - 1) {
          params.data_id = arr[idLastIndex + 1];
        }
			}
			this.$loading();
			// 发送请求
			dataInterface(params).then((res) => {
				this.$loading().close();
				if (res.code || (res.status && res.status === 200)) {
					this.$emit('postSuccess')
					if(params.__method_name__ === 'createData'){
						this.getDetail(res.data.data.id)
					}
				}
			}).catch((err) => {
				console.log(err);
				this.$loading().close();
			});
		},
  /**
   * @description: 获取详情
   * @return {*}
   */		
		getDetail(id = 0){
			this.loading = true;
			if(this.formConfig === null) return
			const params = this.getParams();
			if(id){
				params.data_id = id
			}
			if(!params.data_id) return
			dataInterface({
				...params,
				object_uuid: this.outParam.objectUUID,
				view_uuid: this.outParam.viewUUID,
				__method_name__: 'dataInfo'
			}).then((re) => {
				this.$emit('reset-perent-data')
				this.$nextTick( async()=>{
					for (let i = 0; i < this.formConfig.fields.length; i++) {
						let item = this.formConfig.fields[i];
						if(['upload','bimList','gisList','modelView'].includes(item.__config__.tagIcon)){
							this.$emit('set-special-info', item.__config__.tagIcon === 'upload'?'bindFilesInfo':item.__config__.tagIcon, {value:re.data.data[item.__vModel__],field:item.__vModel__})
						}
					}
					//固定中控台表单样式
					this.loading = false;
				})
			});
		},
		
		/**
		 * @desc: 获取提出来的附件、视图、bim、gis的数据
		 */
		getOtherData(){
			let obj = {};
			if (this.chooseFilesInfo !== null) {
				obj = Object.assign(obj,this.chooseFilesInfo)
			}
			if (this.chooseBimInfo !== null) {
				obj = Object.assign(obj,this.chooseBimInfo)
			}
			if (this.chooseGisInfo !== null){
					obj = Object.assign(obj,this.chooseGisInfo)
			}
			if (this.chooseViewInfo !== null){
					obj = Object.assign(obj,this.chooseViewInfo)
			}
			return obj
		},

		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			const params = this.getParams();
			this.displayData = {}
			this.formConfig = null
			transFieldsUUid(contentConfig.objectUUID, contentConfig.formUUID).then((res) => {
					if (res.data.code == 200) {
						this.needFlow = !!res.data.data.need_flow;
						this.needParentFlow = !!res.data.data.need_parent_flow;
						this.nowArchiType = res?.data?.data?.archi_type || '';
						let config = res.data.data;
						this.formConfig = config
						let ruuids = [];
						for (let i = 0; i < config.fields.length; i++) {
							let item = config.fields[i];
							if (item.__config__.tagIcon === 'form') {
								ruuids.push({ relationship_uuid: item.__vModel__ });
							}
							if(this.requestType === 'add' && ['upload','bimList','gisList','modelView'].includes(item.__config__.tagIcon)){
								this.$emit('set-special-info', item.__config__.tagIcon === 'upload'?'bindFilesInfo':item.__config__.tagIcon, {value:[],field:item.__vModel__})
							}
							//查找分类默认值
							if(item.__config__.label && item.__config__.label.includes('分类') && item.__config__.defaultValue){
								if(this._PageStatusList.filter((v)=>v.code === 'typeId').length){
									const statusCodeValue = this._PageCustomStatus['typeId'] || this._APPCustomStatus['typeId']
									if(statusCodeValue){
										item.__config__.defaultValue = statusCodeValue
									}
								}
							}
						}
						// 编辑或详情
						if (
							this.requestType === 'edit' || this.requestType === 'detail'
						) {
							// 获取已经有的数据进行绑定
							if(!params.data_id && this.transferId){
								params.data_id = this.transferId
							}
							//取element.id带入的id
							if(!params.data_id && (this.element.id.includes('-view') || this.element.id.includes('-page'))){
								const arr = this.element.id.split('-');
								const idLastIndex = arr.findIndex(v => v.includes('view') || v.includes('page'));
								if (idLastIndex !== arr.length - 1) {
									params.data_id = arr[idLastIndex + 1];
								}
							}
							if(!params.data_id) return
							dataInterface({
								...params,
								object_uuid: this.outParam.objectUUID,
								view_uuid: this.outParam.viewUUID,
								ruuids,
								__method_name__: 'dataInfo'
							}).then((re) => {
								this.canInitForm = false;
								this.$emit('reset-perent-data')
								this.$nextTick( async()=>{
									let data = re.data.data;
									for (let i = 0; i < config.fields.length; i++) {
										let item = config.fields[i];
										if(['upload','bimList','gisList','modelView'].includes(item.__config__.tagIcon)){
											this.$emit('set-special-info', item.__config__.tagIcon === 'upload'?'bindFilesInfo':item.__config__.tagIcon, {value:re.data.data[item.__vModel__],field:item.__vModel__})
										}
									}
									this.formDesignData = await this.formatData(res.data.data);
									//固定中控台表单样式
									this.formDesignData.labelPosition = 'top'
									this.formDesignData.tableStyle = false
									this.excelFormData = {
										[contentConfig.objectUUID]: JSON.parse(JSON.stringify(data))
									};
									this.excelMetaData = re?.data?.metadata ? JSON.parse(JSON.stringify(re.data.metadata)) : {};
									this.displayData = data || {};
									this.editFields = data.editFields || {};
									this.loading = false;
									this.canInitForm = true;
								})
							});
						} else {
							this.formDesignData = this.formatData(res.data.data);
							//固定中控台表单样式
							this.formDesignData.labelPosition = 'top'
							this.formDesignData.tableStyle = false
							this.canInitForm = true;
							this.loading = false;
						}
					}
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 格式化数据
		 */
		formatData(data){
			let newData = JSON.parse(JSON.stringify(data));
			newData.labelPosition = 'top'
			newData.tableStyle = false
			newData.size = "mini"
			return newData
		},
		/**
		 * @desc: 流程处理结束回调处理
		 */
    onEndFlow() {
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 确认按钮
		 */
		onConfirm() {
			const refString = `common-form-container${this.element.id}`;
			if (this.$refs && this.$refs[refString]) {
				this.$refs[refString].handleSubmit();
			}
		},
		/**
		 * @desc: 获取绑定参数
		 */
		getParams() {
			const sourceConfig = this.element.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const {
					componentId,
					field,
					key,
					originType = '',
					urlParamKey = '',
					statusCode = ''
				} = sourceConfig[i];
				if (originType === 'pageStatus' && statusCode) {
					const result = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode]
					this.$set(sourceParams, key, result);
				} else if (originType === 'url' && urlParamKey) {
					// 从url获取参数
					const result = getQueryValue(urlParamKey);
					this.$set(sourceParams, key, result);
				} else if (componentId && field && key) {
					// 普通从组件获取
					let sourceComponent = getComponentById(this.componentData, componentId);
					if (!sourceComponent && this.isGroup && this.groupComponents.length) {
						sourceComponent = getComponentById(this.groupComponents, componentId);
					}
					const result = sourceComponent?.resolveData ? sourceComponent.resolveData[field] : '';
					this.$set(sourceParams, key, result);
				}
			}
			return sourceParams;
		},
	},
	beforeDestroy() {
		eventBus.$off('databaseTrigger');
	}
};
</script>

<style lang="less" scoped>
.common-form-container {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	text-align:left!important;
	.common-form-main {
		flex: 1;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
	}
	.common-form-main::-webkit-scrollbar{
		display: none;
	}
	// 公共内容
	:deep(.type-label) {
		border-left: 3px solid var(--themeColor) !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
		text-align: left;
	}
}
</style>
<style lang="less">
// 改变自定义表单的样式
.console-from-container-new{
		.el-textarea__inner,.el-input__inner,.el-button{
			background:transparent!important;
			color:inherit !important;
			border-color:#DBDFE6 !important;
		}
		.el-form-item__label{
			color:inherit !important;
			border-color:inherit !important;
		}
		.file-upload {
			padding:0!important;
		}
		.show-value{
			border-color:#DBDFE6 !important;
			border-radius: 4px!important;
		}
		.no-data{
			color:inherit !important;
			border-color:inherit !important;
			opacity: 0.4;
		}
		.type-label{
			display: none !important;
		}
		.el-form-item{
			margin-bottom: 10px !important;
		}
		.el-form--label-top .el-form-item__label{
			padding:0!important;
		}
		:deep(.el-input__prefix){
			color:inherit !important;
			left:0!important;
			i {
				color:inherit !important;
			}
		}
		:deep(.el-empty__description){
			color:inherit !important;
		}
		.el-radio{
			color:inherit !important;
		}
		input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{
			color:inherit !important;
			opacity: 0.4;
		}
		input::-moz-placeholder,textarea::-moz-placeholder{   /* Mozilla Firefox 19+ */
			color:inherit !important;
			opacity: 0.4;
		}
		input:-moz-placeholder,textarea:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
			color:inherit !important;
			opacity: 0.4;
		}
		input:-ms-input-placeholder,textarea:-ms-input-placeholder{  /* Internet Explorer 10-11 */
			color:inherit !important;
			opacity: 0.4;
		}
		.file-upload{
			padding: 0!important;
		}
		.user-avatar{
			display: none!important;
		}
		.right-header{
			padding:0!important;
		}
		.log-item-info-item .info-content .user-info .user-name{
			color:inherit!important;
		}
	}
	// 详情样式
	.console-form-detail{
		.el-textarea__inner,.el-input__inner,.el-button{
			background:#F4F7FA!important;
			color:inherit !important;
			border:none!important;
			border-color:inherit !important;
		}
		.show-value{
			border:none!important;
			border-radius: 4px!important;
			background:#F4F7FA!important;
		}
	}
</style>
