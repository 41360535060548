/*
 * @Author: qinmengyuan
 * @Date: 2024-07-17 16:56:06
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-07-19 16:19:41
 * @FilePath: \dataview-next\src\custom-component\bindLatLng\marker.js
 */
export const div = (name) => `
<style>
.white-box{
  background: white;
  box-shadow: 0px 1px 2px rgba(2, 15, 41, 0.08);
  border-radius: 8px;
  text-align: center;
  max-width: 200px;
  padding: 10px;
  position: relative;
}
.black-p{
    font-weight: 500;
    font-size: 14px;
    color: rgb(22, 28, 31);
    padding-bottom: 5px;
  }
.arrow{
    width:16px;
    height:16px;
    background:white;
    position: absolute;
    transform: translate(-50%,50%) rotate(45deg);
    left:50%;
    bottom: 0;
}
  </style>
  <div class="white-box">
    <p class="black-p">${name}</p>
    <div class='arrow'></div>
    </div>
`;
