import IBehaviors from './IBehaviors';

export default class PrimitiveBehaviors extends IBehaviors {
    constructor(viewer, comp, dataInterface) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;
        this.dataInterface = dataInterface;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        const viewer = this.viewer;
        viewer.on(viewer.EventType.primitiveClicked, (id, parentId, clickData = {}) => {
            if(parentId.indexOf('featurePolygon') !== -1){
                //桩基进度
                this.comp.setResolveData("gis_featurePolygon_id", id);
                this.comp.setResolveData("gis_featurePolygon_graph", parentId.split('_')[1]);

                let list = clickData.data;
                let index = list.findIndex(x => x.id === id);
                if(index !== -1){
                    let center = list[index].center;
                    let centerVal = [{"location_gis":{"lng":center.lng,"lat":center.lat,"height":center.height}}];
                    this.comp.setResolveData("gis_featurePolygon_center", JSON.stringify(centerVal));
                }

                this.comp.onAction('onFeaturePolygonClick');
            }else if(parentId.indexOf('colorPolygonMap') !== -1){
                //碾压区域
                this.comp.setResolveData("gis_block_code", id);
                this.comp.setResolveData("gis_project_id", parentId.split('_')[1]);
                this.comp.onAction('onColorPolygonClick');
            }
        });

        return [
            {
                label: '[大数据标绘]创建多边形',
                desc: `
                    参数[ graph, graphParam, remove, removeTag, tag ]。 
                    根据传入的颜色值进行多边形着色
                    graph => 蓝图标识值
                    graphParam => 蓝图标识字段名
                    remove => 是否清空全部的大数据对象
                    removeTag => 选填，是否清除指定tag的大数据对象
                    tag => 选填，行为所属功能模块tag
                    `,
                value: 'primitive_createMassivePolygon',
                func: (params) => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.graph) return console.warn("[参数出错]");

                    let {
                        graphParam = {
                            id: 'block_code',
                            positions: 'coordinate_point',
                            color: 'block_color',
                            classify: 'project_id'
                        },
                        remove = 'false',
                        removeTag = 'false',
                        tag = 'colorPolygonMap'
                    } = params;

                    if(JSON.parse(removeTag)){
                        viewer.Primitive.removePrimitiveByKeyWords(tag);
                    }else{
                        if(JSON.parse(remove)){
                            viewer.Primitive.removeAllPrimitive();
                            viewer.Marker.removeMarkerByKeyWords('primitive');
                        }
                    }

                    //数据来自蓝图
                    this.dataInterface({}, `api/graph/${params.graph}`).then((res) => {
                        if(res.data.code === 200){
                            let data = res.data.data;
                            let list = {};
                            for (let item of data){
                                let key = params.graph;
                                if(graphParam.classify !== ''){
                                    key = item[graphParam.classify];
                                }

                                if(!list[key]) list[key] = [];
                                list[key].push({
                                    id: item[graphParam.id],
                                    positions: item[graphParam.positions],
                                    color: item[graphParam.color]
                                });
                            }

                            for (let key in list){
                                viewer.Primitive.createMassivePolygon(`${tag}_${key}`, list[key], true);
                            }

                        }
                    })
                }
            },
            {
                label: '[大数据标绘]创建多边形(高程)',
                desc: `
                    参数[ graph, graphParam, remove, removeTag, tag ]。 
                    根据高程值对多边形进行着色
                    graph => 蓝图标识值
                    graphParam => 蓝图标识字段名
                    remove => 是否清空全部的大数据对象
                    removeTag => 选填，是否清除指定tag的大数据对象
                    tag => 选填，行为所属功能模块tag
                    `,
                value: 'primitive_createMassivePolygon2',
                func: (params) => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.graph) return console.warn("[参数出错]");

                    let {
                        graphParam = {
                            id: 'block_code',
                            positions: 'coordinate_point',
                            status: 'block_status_2',
                            height: 'average_elevation',
                            classify: 'project_id'
                        },
                        remove = 'false',
                        removeTag = 'false',
                        tag = 'heightPolygonMap'
                    } = params;

                    if(JSON.parse(removeTag)){
                        viewer.Primitive.removePrimitiveByKeyWords(tag);
                    }else{
                        if(JSON.parse(remove)){
                            viewer.Primitive.removeAllPrimitive();
                            viewer.Marker.removeMarkerByKeyWords('primitive');
                        }
                    }

                    const getColorAlpha = (height) => {
                        if(height < 4){
                            return 0.2;
                        }else if(height >= 4 && height < 8){
                            return 0.7;
                        }else if(height >= 8){
                            return 1;
                        }

                        return 0;
                    };

                    //数据来自蓝图
                    this.dataInterface({}, `api/graph/${params.graph}`).then((res) => {
                        if(res.data.code === 200){
                            let data = res.data.data;
                            let list = {};
                            for (let item of data){
                                let key = params.graph;
                                if(graphParam.classify !== ''){
                                    key = item[graphParam.classify];
                                }

                                let color;
                                if(item[graphParam.status] === 0){
                                    color = `rgba(255, 165, 0, ${getColorAlpha(item[graphParam.height])})`;
                                }else{
                                    color = `rgba(255, 99, 71, ${getColorAlpha(item[graphParam.height])})`;
                                }

                                if(!list[key]) list[key] = [];
                                list[key].push({
                                    id: item[graphParam.id],
                                    positions: item[graphParam.positions],
                                    color: color
                                });
                            }

                            for (let key in list){
                                viewer.Primitive.createMassivePolygon(`${tag}_${key}`, list[key], true);
                            }

                        }
                    })
                }
            },
            {
                label: '[大数据标绘]创建分层叠加多边体',
                desc: `
                    参数[ graph, graphParam, remove, removeTag, tag ]。 
                    根据高程值创建分层叠加的多边体对象
                    graph => 蓝图标识值
                    project_id => 单元格子标段Id
                    block_code => 单元格子区块编码
                    remove => 是否清空全部的大数据对象
                    removeTag => 选填，是否清除指定tag的大数据对象
                    tag => 选填，行为所属功能模块tag
                    `,
                value: 'primitive_createMassivePolygonExtruded',
                func: (params) => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.graph) return console.warn("[参数出错]");

                    let {
                        remove = 'false',
                        removeTag = 'false',
                        tag = 'polygonExtruded'
                    } = params;
                    if(JSON.parse(removeTag)){
                        viewer.Primitive.removePrimitiveByKeyWords(tag);
                        viewer.Marker.removeMarkerByKeyWords(tag);
                    }else{
                        if(JSON.parse(remove)){
                            viewer.Primitive.removeAllPrimitive();
                            viewer.Marker.removeMarkerByKeyWords('primitive');
                        }
                    }

                    //数据来自蓝图
                    this.dataInterface({
                        project_id: params.project_id,
                        block_code: params.block_code
                    }, `api/graph/${params.graph}`).then((res) => {
                        if(res.data.code === 200){
                            let data = res.data.data;

                            let list = [];
                            let index = 0.1;
                            for (let item of data){
                                let color;
                                if(!!item['block_color'] === true){
                                    color = item['block_color'];
                                }else{
                                    color = 'rgba(153, 0, 255, 0.5)';
                                }

                                list.push({
                                    id: item['id'],
                                    positions: item['coordinate_point'],
                                    color: color,
                                    height: index,
                                    extrudedHeight: index + 1.5
                                });
                                index = index + 1.5;
                            }

                            try {
                                viewer.Primitive.createMassivePolygonExtruded(`${tag}_${params.block_code}`, list, true, {allowPicking: false});
                                // setTimeout(()=>{
                                //     viewer.Primitive.locationPrimitive(`${tag}_${params.block_code}`, 5);
                                // }, 500);
                            }catch (e) {
                                console.log('createMassivePolygonExtruded： ', e)
                            }

                            if(list.length === 0) return;

                            this.dataInterface({
                                block_code: params.block_code,
                                token: this.comp.$GetToken(),
                            }, `https://nnjc-api.bimcc.com/api/nnjc/earthwork/equalheight`, 'GET').then((res) => {
                                if (res.data.code === 200) {
                                    let data = res.data.data;
                                    if(data.length === 0) return;

                                    let h = [];
                                    data.forEach(x => h.push(Number(x.pave_height)));
                                    let maxHeight = Math.max.apply(null, h);

                                    let BLHList = viewer.Coordinate.NanNingTransformReverse(data[0].origin_point);
                                    let points = [];
                                    BLHList.map((item) => {
                                        points.push({lng: item.L, lat: item.B, height: item.H});
                                    });
                                    let center = viewer.Turf.getPolygonCentroid(points);
                                    center.height = list.length * 1.5 + 0.1;

                                    let DHS = 0;
                                    data[0].origin_point.map((item) => {
                                        DHS += item.DH;
                                    });
                                    let dh = Number((DHS / points.length).toFixed(2));

                                    let div = `
                                          <style>
                                            .maker {
                                            position: relative;
                                            width: 160px;
                                            }
                                            .container {
                                            display: flex;
                                            width: 160px;
                                            padding: 16px;
                                            flex-direction: column;
                                            align-items: center;
                                            gap: 8px;
                                            border-radius: 4px;
                                            border-top: 2px solid white;
                                            background: linear-gradient(
                                                77deg,
                                                rgba(48, 150, 224, 0.3) 21.84%,
                                                rgba(0, 114, 255, 0.3) 75.6%
                                                ),
                                                rgba(0, 23, 32, 0.9);
                                            }
                                            .title {
                                            color: white;
                                            text-align: center;
                                            font-family: cursive;
                                            font-size: 14px;
                                            font-weight: 700;
                                            line-height: 18px;
                                            }
                                            .desc {
                                            display: flex;
                                            align-items: center;
                                            gap: 8px;
                                            height: 18px;
                                            }
                                            .label {
                                            color: rgba(255, 255, 255, 0.8);
                                            font-family: Source Han Sans CN;
                                            font-size: 14px;
                                            font-weight: 400;
                                            }
                                            .num {
                                            color: white;
                                            font-family: Barlow Condensed;
                                            font-size: 16px;
                                            font-weight: 500;
                                            }
                                            .m {
                                            color: rgba(255, 255, 255, 0.8);
                                            font-family: Source Han Sans CN;
                                            font-size: 14px;
                                            font-weight: 400;
                                            }
                                            .green {
                                            color: rgb(63, 213, 118);
                                            }
                                            .footer {
                                            position: absolute;
                                            width: 12px;
                                            bottom: -80px;
                                            left: 53%;
                                            transform: translateX(6px);
                                            }
                                            .circle {
                                            position: relative;
                                            top: 80px;
                                            width: 8px;
                                            height: 8px;
                                            border-radius: 50%;
                                            border: 2px solid rgba(0, 102, 255, 1);
                                            background-color: white;
                                            }
                                            .line {
                                            width: 3px;
                                            margin: 0 auto;
                                            height: 80px;
                                            background: linear-gradient(
                                                to top,
                                                rgba(0, 102, 255, 1),
                                                rgba(0, 102, 255, 0.3)
                                            );
                                            }
                                        </style>
                                        <div class="maker">
                                            <div class="container">
                                                <div class="title">${params.block_code} 号区块</div>
                                                <div class="desc">
                                                <div class="label">当前标高：</div>
                                                <div class="num">${Number(maxHeight.toFixed(2))}</div>
                                                <div class="m">m</div>
                                                </div>
                                                <div class="desc">
                                                <div class="label">设计标高：</div>
                                                <div class="num green">${dh}</div>
                                                <div class="m">m</div>
                                                </div>
                                            </div>
                                            <div class="footer">
                                                <div class="circle"></div>
                                                <div class="line"></div>
                                            </div>
                                        </div>
                                    `;
                                    let image = viewer.Image.getDivBuilderImage({ div, width: 190, height: 185});
                                    viewer.Marker.createMarker(`primitive_${tag}_label_${params.block_code}`, {
                                        type: 'Billboard',
                                        position: center,
                                        options: {
                                            image: image
                                        }
                                    });
                                    setTimeout(()=>{
                                        viewer.viewer.scene.requestRender();
                                    }, 50);
                                }
                            });
                        }
                    })
                }
            },
            {
                label: '[大数据标绘]根据构件范围创建多边形',
                desc: `
                    参数[ graph, remove, removeTag, tag ]。 
                    根据传入的颜色值进行多边形着色
                    graph => 蓝图标识值
                    remove => 是否清空上一次添加的标绘对象
                    `,
                value: 'primitive_createFeaturePolygon',
                func: (params) => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.graph) return console.warn("[参数出错]");

                    let {
                        remove = 'false',
                        removeTag = 'false',
                        tag = 'featurePolygon'
                    } = params;
                    if(JSON.parse(removeTag)){
                        viewer.Primitive.removePrimitiveByKeyWords(tag);
                    }else{
                        if(JSON.parse(remove)){
                            viewer.Primitive.removeAllPrimitive();
                            viewer.Marker.removeMarkerByKeyWords('primitive');
                        }
                    }

                    //数据来自蓝图
                    this.dataInterface({
                        mtype: 2
                    }, `api/graph/${params.graph}`).then((res) => {
                        if(res.data.code === 200){
                            let data = res.data.data;
                            let list = [];
                            for (let item of data){
                                let temp = [];
                                if(item.model_list){
                                    item.model_list.map((x) => {
                                        temp.push({modelId: x.model_id, dbids: x.dbids})
                                    });
                                }

                                let positions = viewer.Model.getPolygonByMultiKeys(temp);

                                let center = viewer.Turf.getMultiPointCenter(positions, 'json');

                                list.push({
                                    id: item.id,
                                    positions: positions,
                                    center: center,
                                    color: 'rgba(255,255,0,0.5)'
                                });
                            }

                            viewer.Primitive.createGroundPolygon(`${tag}_${params.graph}`, list, true);
                        }
                    })
                }
            },
            {
                label: '[大数据标绘]删除全部大数据标绘',
                desc: `
                    参数[ 无 ]。 
                    `,
                value: 'primitive_removeAllPrimitive',
                func: () => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    viewer.Primitive.removeAllPrimitive();
                    viewer.Marker.removeMarkerByKeyWords('primitive');
                }
            },
            {
                label: '[大数据标绘]删除指定类型的大数据标绘',
                desc: `
                    参数[ type ]。 
                    根据传入的类型值，删除相应类型的大数据标绘对象
                    type => 指定类型值
                    `,
                value: 'primitive_removePrimitiveByType',
                func: (params) => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.type) return console.warn("[参数出错]");

                    viewer.Primitive.removePrimitiveByType(params.type);
                }
            },
            {
                label: '[大数据标绘]删除指定tag的大数据标绘',
                desc: `
                    参数[ tag ]。 
                    根据传入的类型值，删除相应类型的大数据标绘对象
                    tag => 指定tag值
                    `,
                value: 'primitive_removePrimitiveByKeyWords',
                func: (params) => {
                    if (!viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.tag) return console.warn("[参数出错]");

                    viewer.Primitive.removePrimitiveByKeyWords(params.tag);
                    viewer.Marker.removeMarkerByKeyWords(params.tag);
                }
            },
        ];
    }
}
