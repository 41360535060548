<template>
    <div class="wrapPanel">
        <div class="load-wrap">
            <div class="title-wrap">
                <span>模型树</span>
                <a :class="['iconfont iconjiazaimoxing', {'showUnload-btn_active': isShowUnload}]"
                   @click="onToggleUnload"></a>
            </div>

            <el-tree ref="modelTree"
                     class="modelTree-wrap"
                     empty-text=""
                     :data="modelList"
                     :props="treeProps"
                     :expand-on-click-node="false"
                     :default-expanded-keys="modelExpanded"
                     node-key="id"
                     :lazy="true"
                     :load="onLoadModelNode"
                     highlight-current
                     :check-on-click-node="false"
                     :check-strictly="false"
                     :show-checkbox="true"
                     @check=onCheckModelNode
            >
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node']">
                        <a v-if="data.type==='file'||data.type==='model'"
                           :class="['tree-node-icon iconfont', `tree-node-icon-${data.type} `, getIconByType(data.type, data.modelType), {'tree-node-preview': data.isPreview}]"></a>
                        <span :class="['tree-node-content', {'tree-node-preview': data.isPreview}]" :title="data.name"
                              @click="onRadioModelNode(node, data)" @dblclick="onDblClickModelNode(node, data)">{{ data.name}}</span>
                        <a v-if="!data.isPreview&&data.type==='model'&&!data.isLoaded"
                           class="iconfont iconshuaxin tree-node-loading" style="margin-right: 5px"></a>
                        <a v-if="!data.isPreview&&data.type==='model'"
                           class="iconfont icongis_dingwei tree-node-location" @click="onLocationModel(data)"></a>
                    </div>
                </template>
            </el-tree>

            <el-empty v-if="modelList.length === 0" description="什么都没有" class="empty-wrap"></el-empty>
        </div>

        <div v-show="isShowUnload" :class="['unload-wrap', `unload-wrap-${modelTreeSpread}`]">
            <div class="unloadHeader-wrap horizontal-flex"></div>
            <el-input v-model="searchInput" placeholder="搜索" class="unloadHeader-search">
                <template #prefix>
                    <a class="iconfont iconsousuo2 unloadHeader-searchIcon"></a>
                </template>
            </el-input>
            <a class="iconfont iconjijia_quanbucengji unloadHeader-btnIcon" @click="onClickUnloadBtn"></a>
            <a class="iconfont iconzhuomian unloadHeader-btnIcon" @click="onClickUnloadBtn('local')"></a>
            <a class="iconfont iconshangchuanmoxing unloadHeader-btnIcon" @click="onClickUnloadBtn"></a>

            <el-tree class="unloadModelTree-wrap"
                     ref="unloadModelTree"
                     empty-text="暂无数据"
                     :data="unLoadModelList"
                     :props="treeProps"
                     :default-expand-all="true"
                     :expand-on-click-node="false"
                     node-key="id"
                     highlight-current
                     :check-on-click-node="false"
                     :check-strictly="false"
                     :show-checkbox="true"
                     @check="onCheckUnloadNode"
            >
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node-unload']">
                        <a :class="['tree-node-icon iconfont', `tree-node-icon-${data.type} `, getIconByType(data.type)]"></a>
                        <div class="tree-node-content">{{ data.name + getModelLength(data)}}</div>
                        <el-popconfirm title="是否卸载模型?"
                                       confirm-button-text="是"
                                       cancel-button-text="否"
                                       @confirm="onDeleteModel(data)">
                            <template #reference>
                                <a v-show="data.isLoaded" class="iconfont iconbimgis_guanbi tree-node-delete"></a>
                            </template>
                        </el-popconfirm>
                    </div>
                </template>
            </el-tree>

            <div class="horizontal-flex unloadBottom-wrap">
                <a class="iconfont iconinformation unloadBottom-icon"></a>
                <span class="unloadBottom-info-left">已选择</span>
                <span class="unloadBottom-info-center">{{checkModelList.length}}</span>
                <span class="unloadBottom-info-right">个模型</span>
                <div class="unloadBottom-cancel" @click="onCancelAppendUnload">取消</div>
                <div class="unloadBottom-yes" @click="onAppendUnload">确认</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Empty, Tree, Popconfirm, Input} from 'element-ui';
    import {dataInterface} from '@/apis/data/index';

    export default {
        name: "ModelTreeSingle",
        components: {
            'el-empty': Empty,
            'el-tree': Tree,
            'el-popconfirm': Popconfirm,
            'el-input': Input
        },
        data() {
            return {
                isShowUnload: false,
                searchInput: '',
                checkModelList: [],
                checkNodeList: [],
                modelTreeMap: {},
                loadModelMap: {},
                unLoadModelList: [],
                modelList: [],
                modelExpanded: [],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf: "isLeaf",
                }
            }
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            modelTreeSpread: {
                type: String,
                default: 'right'
            },
            showUnloadTree: {
                type: Boolean,
                default: false
            },
            bindViewerId: {
                type: String,
            },
            bindDataMainId: {
                type: String,
            },
            loadModelNodeCallback: {
                type: Function
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        watch: {
          showUnloadTree: {
              handler(newVal) {
                  this.isShowUnload = newVal;
              }
          }
        },
        methods: {
            /**
             * @description: 检验绑定的Viewer是否有效
             */
            checkBind() {
                return this.bindViewerId && this.$parent.checkBind();
            },
            /**
             * @description: 获取GIS未加载列表数据
             * @param {Object} db 数仓表数据
             * @param {String} bindId Viewer标识值
             * @param {Array} search 搜索条件
             * @param {Array} param 配置参数
             */
            async getGISUnloadListData(db, bindId, search, param) {
                if (!db) return;
                if (!bindId) {
                    this.checkModelList = [];
                    this.unLoadModelList = [];
                    this.modelList = [];
                    this.modelExpanded = [];
                    return;
                }

                const handleData = (data, list) => {
                    const node = {
                        id: data.id,
                        name: data.name,
                        type: "file",
                        isLoaded: false,
                    };
                    const children = [];

                    if (data?.gis_type && data.gis_type.length > 0) {
                        for (let {model_id, name, modelSize, updated_at} of data.gis_type) {
                            if (!model_id) continue;
                            if (model_id === '7d0310a1172141f98aa265352c555d42') continue;

                            children.push({
                                id: model_id,
                                name: name,
                                type: "model",
                                size: modelSize,
                                uploadDate: updated_at,
                                isLeaf: true,
                                isLoaded: false,
                            });
                        }
                    }

                    node.children = children;
                    list.push(node);

                    if (data.children && data.children.length > 0) {
                        for (let child of data.children) {
                            handleData(child, children);
                        }
                    }
                };

                const res = await dataInterface({
                    __method_name__: "dataList",
                    object_uuid: db.object_uuid,
                    view_uuid: db.view_uuid,
                    transcode: 0,
                    search: search,
                    ...param
                });

                if (res && res.status === 200) {
                    const data = res.data.data;

                    const treeData = [];
                    for (let obj of data) {
                        handleData(obj, treeData);
                    }

                    this.unLoadModelList = treeData;
                }
            },
            /**
             * @description: 获取BIM未加载列表数据
             * @param {Object} db 数仓表数据
             * @param {String} bindId Viewer标识值
             * @param {Array} search 搜索条件
             * @param {Array} param 配置参数
             */
            async getBIMUnloadListData(db, bindId, search, param) {
                if (!db) return;
                if (!bindId) {
                    this.checkModelList = [];
                    this.unLoadModelList = [];
                    this.modelList = [];
                    this.modelExpanded = [];
                    return;
                }

                const handleData = (data, list, map) => {
                    const node = {
                        name: data.name,
                        id: data.id,
                        type: "file",
                        isLoaded: false,
                    };
                    const children = [];

                    if (data.models && data.models.length > 0) {

                        for (let {allversions, used_version, name, modelSize} of data.models) {
                            let model_id = null;

                            if (!used_version) continue;

                            for (let ver of allversions) {
                                if (ver.id === used_version && ver.model_id) {
                                    model_id = ver.model_id;
                                    break;
                                }
                            }

                            if (!model_id) continue;

                            children.push({
                                id: model_id,
                                type: "model",
                                size: modelSize,
                                isLeaf: true,
                                isLoaded: false,
                                name,
                            });

                            map[model_id] = {
                                id: model_id,
                                name,
                            }
                        }
                    }

                    node.children = children;
                    list.push(node);

                    if (data.children && data.children.length > 0) {
                        for (let child of data.children) {
                            handleData(child, children, this.modelTreeMap, map);
                        }
                    }
                };

                const res = await dataInterface({
                    __method_name__: "dataList",
                    object_uuid: db.object_uuid,
                    view_uuid: db.view_uuid,
                    transcode: 0,
                    search: search,
                    ...param
                });

                if (res && res.status === 200) {
                    const data = res.data.data;

                    const treeData = [];
                    for (let obj of data) {
                        handleData(obj, treeData, this.modelTreeMap);
                    }

                    this.unLoadModelList = treeData;
                }
            },
            /**
             * @description: 获取数据节点图标
             * @param {String} type 数据节点类型
             * @param {String} modelType 数据节点类型为模型时，模型类型
             */
            getIconByType(type, modelType = undefined) {
                switch (type) {
                    case 'file':
                        return 'icona-wenjianjiadakai';
                    case 'model':
                        if (!modelType) return 'iconBIM';
                        switch (modelType) {
                            case 'bim':
                                return 'iconBIM1';
                            case 'gltf':
                                return 'icongltf';
                            case 'osgb':
                                return 'iconosgb';
                            default:
                                return 'iconBIM';
                        }
                    default:
                        return '';
                }
            },
            /**
             * @description: 获取文件夹节点下模型数量
             * @param {Object} node 文件夹节点
             */
            getModelLength(node) {
                if (node.type !== 'file') return '';

                let leaf = [];
                this.getLeafNodes(node, leaf);

                return `(${leaf.length})`;
            },
            /**
             * @description: 遍历文件夹节点下所有模型节点
             * @param {Object} node 文件夹节点
             * @param {Array} leaf 模型节点结果集
             */
            getLeafNodes(node, leaf = []) {
                if (node.type === 'model') leaf.push(node);
                if (node.children && node.children.length > 0) {
                    for (let item of node.children) {
                        if (item.type === 'model') leaf.push(item);

                        if (item.children && item.children.length > 0) {
                            this.getLeafNodes(item, leaf);
                        }
                    }
                }
                return leaf;
            },
            /**
             * @description: 遍历模型树文件夹节点获取模型节点集
             * @param {Object} fileNode 文件夹节点
             * @param {Array} leaf 模型节点结果集
             */
            getModelNodeByFile(fileNode, leaf = []) {
                if (fileNode.type === 'model') leaf.push(fileNode);
                if (fileNode.type === 'file' && fileNode.children && fileNode.children.length > 0) {
                    for (let item of fileNode.children) {
                        if (item.type === 'model') leaf.push(item);

                        if (fileNode.type === 'file' && item.children && item.children.length > 0) {
                            this.getLeafNodes(item, leaf);
                        }
                    }
                }
                return leaf;
            },
            /**
             * @description: 获取模型树列表已勾选的对象列表
             * @param {String} type 已勾选对象类型
             */
            getCheckedModelNodes(type){
                const el = this.$refs.modelTree;

                let nodes = el.getCheckedNodes();
                let keys = el.getCheckedKeys();

                if(!type) return {nodes, keys};

                let list = [];
                let keyList = [];
                for (let node of nodes){
                    if(node.type === type){
                        list.push(node);
                        keyList.push(node.id);
                    }
                }

                return {
                    nodes: list,
                    keys: keyList
                }
            },
            /**
             * @description: 获取未加载模型树列表的指定对象
             * @param {String} key 标签对象id
             */
            getUnloadModelNode(key){
                const el = this.$refs.unloadModelTree;

                let node = el.getNode(key);
                if (!node) return undefined;

                return node;
            },
            /**
             * @description: 获取模型树的指定对象
             * @param {String} key 标签对象id
             */
            getModelItem(key){
                const el = this.$refs.modelTree;

                let node = el.getNode(key);
                if (!node) return undefined;

                return node;
            },

            /**
             * @description: 设置未加载树节点Check
             * @param {String} keys 节点ID集
             * @param {Boolean} isViewerDeep 是否操纵Viewer行为
             * @param {Boolean} isLocation 是否定位到模型
             */
            setUnloadNodeCheck(keys, isViewerDeep = true, isLocation = true) {
                const el = this.$refs.unloadModelTree;
                for (let item of keys) {
                    let node = el.getNode(item);
                    if (!node) continue;

                    el.setChecked(item, true);
                    this.checkModelList.push(node.data);
                }
                this.onAppendUnload(false, isViewerDeep, isLocation);
            },
            /**
             * @description: 设置未加载树节点Loaded
             * @param {String} key 节点ID值
             */
            setUnloadNodeLoaded(key) {
                const el = this.$refs.unloadModelTree;
                let node = el.getNode(key);
                if (!node) return;

                if (typeof node.data.isLoaded === "undefined") {
                    this.$set(node.data, 'isLoaded', true);
                } else {
                    node.data.isLoaded = true;
                }
            },
            /**
             * @description: 设置模型树节点属性值
             * @param {String} key 节点ID
             * @param {Object} props 属性对象
             */
            setModelNodeProps(key, props) {
                const el = this.$refs.modelTree;
                let node = el.getNode(key);
                if (!node) return;

                if (!props) return;
                for (let key in props) {
                    if (typeof node.data[key] === "undefined") {
                        this.$set(node.data, key, props[key]);
                    } else {
                        node.data[key] = props[key];
                    }
                }
            },
            /**
             * @description: 设置模型树节点Loaded
             * @param {String} key 节点ID
             */
            setModelNodeLoaded(key) {
                const el = this.$refs.modelTree;
                let node = el.getNode(key);
                if (!node) return;

                if (typeof node.data.isLoaded === "undefined") {
                    this.$set(node.data, 'isLoaded', true);
                } else {
                    node.data.isLoaded = true;
                }
            },
            /**
             * @description: 勾选模型树节点
             * @param {Array} nodes 节点集
             * @param {Boolean} checked 是否勾选
             */
            setModelNodesCheck(nodes, checked) {
                const el = this.$refs.modelTree;
                for (let key in nodes) {
                    el.setChecked(nodes[key] instanceof Object ? nodes[key].id : nodes[key], checked, true);
                }
            },
            /**
             * @description: 修改BIM LoadMap数据
             * @param {Array} keys 模型Id集
             */
            setLoadMapData(keys) {
                const list = [];

                for (let mid of keys) {
                    if (!this.loadModelMap[mid]) {
                        list.push(mid);
                        this.loadModelMap[mid] = {
                            model_id: mid,
                            isLoaded: false,
                        }
                    }
                }

                return list;
            },
            /**
             * @description: 修改BIM ModelMap数据
             * @param {Array} loadedInfo 模型加载数据
             */
            setModelMapData(loadedInfo) {
                let ids = [];
                for (let mid in loadedInfo) {
                    const info = loadedInfo[mid];

                    if (!this.modelTreeMap[mid]) {
                        this.unLoadModelList.push({
                            id: mid,
                            type: 'model',
                            size: info.size,
                            isLeaf: true,
                            isLoaded: false,
                            name: info.name,
                        });
                        ids.push(mid);
                    } else {
                        this.modelTreeMap[mid] = {
                            id: mid,
                            name: info.name,
                        };
                        ids.push(mid);
                    }

                    this.loadModelMap[mid] = {
                        model_id: mid,
                        isLoaded: false,
                    };
                }

                return ids;
            },
            /**
             * @description: 设置BIM模型树节点Loaded
             * @param {String} key 节点ID
             */
            setBIMNodeLoaded(key) {
                this.loadModelMap[key].isLoaded = true;

                const el = this.$refs.modelTree;
                if (!el) return;
                let node = el.getNode(key);
                if (!node) return;

                if (typeof node.data.isLoaded === "undefined") {
                    this.$set(node.data, 'isLoaded', true)
                } else {
                    node.data.isLoaded = true;
                }
            },
            /**
             * @description: 设置GIS模型树其他来源的模型数据
             * @param {Array} list 模型数据数组
             */
            async setGISModelExtraData(list){
                const el = this.$refs.unloadModelTree;

                let keys = [];
                for (let item of list) {
                    let node = el.getNode(item.modelId);
                    if (!node) {
                        this.unLoadModelList.push({
                            id: item.modelId,
                            name: item.modelName,
                            type: 'model',
                            size: '',
                            uploadDate: '',
                            isLeaf: true,
                            isLoaded: false
                        });
                    }

                    keys.push(item.modelId);
                }

                await this.$nextTick();
                return keys;
            },

            /**
             * @description: 预展示已勾选未加载的模型列表
             * @return Void
             */
            previewModelList() {
                const unloadEl = this.$refs.unloadModelTree;
                const modelTreeEl = this.$refs.modelTree;

                if (this.checkModelList.length === 0) {
                    this.EmptyPreviewModelList();
                    return;
                }

                let loadArray = [];
                let halfCheckedNodes = unloadEl.getHalfCheckedNodes();
                for (let item of halfCheckedNodes) {
                    let temp = unloadEl.getNode(item.id);
                    let pId = -1;
                    if (temp.level > 1) pId = temp.parent.data.id;
                    loadArray.push({
                        id: item.id,
                        name: item.name,
                        pId: pId,
                        level: temp.level,
                        type: item.type,
                        isLeaf: true,
                        children: [],
                        isLoaded: false,
                        isPreview: true
                    })
                }

                let checkedNodes = unloadEl.getCheckedNodes();
                for (let item of checkedNodes) {
                    if (!item.isLoaded) {
                        let temp = unloadEl.getNode(item.id);
                        let pId = -1;
                        if (temp.level > 1) pId = temp.parent.data.id;
                        loadArray.push({
                            id: item.id,
                            name: item.name,
                            pId: pId,
                            level: temp.level,
                            type: item.type,
                            isLeaf: true,
                            children: [],
                            isLoaded: false,
                            isPreview: true
                        });
                    }
                }

                if (this.checkNodeList.length < loadArray.length) {
                    let expand = [];

                    for (let item of loadArray) {
                        let node = modelTreeEl.getNode(item.id);

                        if (!node) {
                            modelTreeEl.append(item, item.pId === -1 ? null : item.pId);
                            expand.push(item.id);
                        }
                    }

                    this.$nextTick(() => {
                        this.modelExpanded = expand;
                    });
                } else {
                    for (let item of this.checkNodeList) {
                        if (loadArray.findIndex(x => x.id === item.id) === -1) {
                            modelTreeEl.remove(item.id);
                        }
                    }
                }
                this.checkNodeList = loadArray;
            },
            /**
             * @description: 清空预展示已勾选未加载的模型列表
             * @return Void
             */
            EmptyPreviewModelList() {
                const modelTreeEl = this.$refs.modelTree;

                let fileList = this.checkNodeList.filter(x => x.type === 'file');
                let modelList = this.checkNodeList.filter(x => x.type === 'model');
                for (let item of modelList) {
                    modelTreeEl.remove(item.id);
                }

                for (let item of fileList) {
                    let node = modelTreeEl.getNode(item.id);
                    if (node && node?.data) {
                        if (node.data.children && node.data.children.length > 0) continue;

                        modelTreeEl.remove(item.id);
                    }
                }
                this.checkNodeList = [];
                this.modelExpanded = [];
            },
            /**
             * @description: 模型树添加数据
             * @param {Array} nodes 数据集
             * @param {Array} checked 模型ID集
             */
            appendModelList(nodes, checked = []) {
                const el = this.$refs.modelTree;
                let expand = [];

                for (let item of nodes) {
                    let node = el.getNode(item.id);

                    if (!node) {
                        el.append(item, item.pId === -1 ? null : item.pId);

                        if (item.type !== 'model') expand.push(item.id);
                    }
                }

                this.$nextTick(() => {
                    if (checked.length > 0) this.setModelNodesCheck(checked, true);

                    this.modelExpanded = expand;
                });
            },
            /**
             * @description: 模型树删除数据
             * @param {Array} leaf 模型节点集
             * @param {Array} nodes 文件夹节点集
             */
            deleteModelList(leaf, nodes) {
                this.modelExpanded = [];
                const el = this.$refs.modelTree;
                for (let item of leaf) {
                    let node = el.getNode(item.id);
                    if (!node) continue;

                    el.remove(item.id);
                }

                for (let item of nodes) {
                    let node = el.getNode(item.id);
                    if (!node) continue;

                    if (node.childNodes.length === 0) el.remove(item.id);
                }
            },
            /**
             * @description: 删除BIM LoadMap数据
             * @param {Array} keys 模型Id集
             */
            deleteLoadMapData(keys) {
                for (let mid of keys) {
                    delete this.loadModelMap[mid];
                }
            },

            /**
             * @description: 显示隐藏未加载模型列表
             */
            onToggleUnload() {
                this.isShowUnload = !this.isShowUnload;
            },
            /**
             * @description: 隐藏未加载模型列表
             */
            onHideUnload() {
                this.isShowUnload = false;
            },
            /**
             * @description: 模型树其他功能按钮事件
             * @param {String} type 功能按钮类型
             */
            onClickUnloadBtn(type = '') {
                this.$emit('clickToolbar', type);
            },
            /**
             * @description: 未加载树的复选框点击触发事件
             * @param {Object} data 选中的该节点对象
             * @param {Object} checked 树目前的选中状态对象
             */
            onCheckUnloadNode(data, checked) {
                let leaf = [];
                let checkedNodes = checked.checkedNodes;
                for (let item of checkedNodes) {
                    if (item.type === 'model' && !item.isLoaded) leaf.push(item);
                }

                this.checkModelList = leaf;
                this.previewModelList();
            },
            /**
             * @description: 未加载树界面确认按钮事件
             * @param {Boolean} deep 是否是组件内部点击触发
             * @param {Boolean} isViewerDeep 是否操纵Viewer行为
             * @param {Boolean} isLocation 是否定位到模型
             */
            onAppendUnload(deep = true, isViewerDeep = true, isLocation = true) {
                if (!this.checkBind()) return this.$message.error('未绑定Viewer组件!');
                if (deep && this.checkModelList.length === 0) return this.$message.warning('请勾选模型加载!');

                this.EmptyPreviewModelList();

                this.$nextTick(() => {
                    const el = this.$refs.unloadModelTree;
                    let loadArray = [];
                    let defaultChecked = [];
                    let halfCheckedNodes = el.getHalfCheckedNodes();
                    for (let item of halfCheckedNodes) {
                        let temp = el.getNode(item.id);
                        let pId = -1;
                        if (temp.level > 1) pId = temp.parent.data.id;
                        loadArray.push({
                            id: item.id,
                            name: item.name,
                            pId: pId,
                            level: temp.level,
                            type: item.type,
                            // isLeaf: item.type === 'model',
                            isLoaded: item.type === 'model' ? false : undefined
                        })
                    }

                    let checkedNodes = el.getCheckedNodes();
                    for (let item of checkedNodes) {
                        if (!item.isLoaded) {
                            let temp = el.getNode(item.id);
                            let pId = -1;
                            if (temp.level > 1) pId = temp.parent.data.id;
                            loadArray.push({
                                id: item.id,
                                name: item.name,
                                pId: pId,
                                level: temp.level,
                                type: item.type,
                                // isLeaf: item.type === 'model',
                                isLoaded: item.type === 'model' ? false : undefined
                            });
                            if (item.type === 'model') defaultChecked.push(item.id);
                        }

                        this.setUnloadNodeLoaded(item.id);
                        item.disabled = true;
                        el.setCurrentKey(item.id);
                    }

                    this.checkModelList = [];
                    this.appendModelList(loadArray, defaultChecked);
                    if(deep) this.onHideUnload();

                    if(isViewerDeep) this.$emit('addModel', defaultChecked, isLocation);
                });
            },
            /**
             * @description: 未加载树界面取消按钮事件
             */
            onCancelAppendUnload() {
                // if (this.checkModelList.length === 0) return;

                const el = this.$refs.unloadModelTree;
                for (let item of this.checkModelList) {
                    let node = el.getNode(item.id);
                    if (!node) continue;

                    el.setChecked(item, false);
                }
                this.checkModelList = [];

                this.EmptyPreviewModelList();

                this.onHideUnload();
            },

            /**
             * @description: 模型树懒加载事件
             * @param {Object} node 选中的该节点对象
             * @param {Object} resolve 异步对象
             */
            onLoadModelNode(node, resolve) {
                if (node.level === 0) return resolve(node.data);

                if (node.data.children) {
                    node.isLeaf = true;
                    return resolve(node.data.children);
                }

                if (!node.isLeaf && this.loadModelNodeCallback) {
                    this.loadModelNodeCallback(node.data).then(({leafs = [], checkedList = [], checked = true}) => {
                        if (leafs.length > 0) {
                            node.data.children = leafs;
                            node.isLeaf = false;

                            this.$nextTick(() => {
                                this.setModelNodesCheck(checkedList, checked);
                            });

                            return resolve(node.data.children);
                        } else {
                            node.isLeaf = true;
                            return resolve([]);
                        }
                    })
                } else {
                    node.isLeaf = true;
                    return resolve([]);
                }
            },
            /**
             * @description: 模型卸载事件
             * @param {Object} data 选中的该节点对象
             */
            onDeleteModel(data) {
                const el = this.$refs.unloadModelTree;
                let beforeChecked = el.getCheckedNodes();
                let beforeHalfChecked = el.getHalfCheckedNodes();

                let leaf = [];
                this.getLeafNodes(data, leaf);
                for (let item of leaf) {
                    el.setChecked(item.id, false, true);
                }

                let nodeList = [];
                let currentChecked = el.getCheckedNodes();
                let currentHalfChecked = el.getHalfCheckedNodes();
                for (let item of beforeHalfChecked) {
                    if (currentHalfChecked.findIndex(x => x.id === item.id) !== -1) continue;

                    nodeList.push(item);
                }
                for (let item of beforeChecked) {
                    if (currentChecked.findIndex(x => x.id === item.id) !== -1) continue;

                    item.isLoaded = false;
                    item.disabled = false;
                    el.setCurrentKey(item.id);
                    nodeList.push(item);
                }

                this.deleteModelList(leaf, nodeList.reverse());

                this.$emit('deleteModel', leaf);
            },
            /**
             * @description: 模型定位事件
             * @param {Object} data 选中的该节点对象
             */
            onLocationModel(data) {
                this.$emit('locationModel', data);
            },
            /**
             * @description: 模型勾选事件
             * @param {Object} data 选中的该节点对象
             */
            onCheckModelNode(data) {
                if (data.isPreview) {
                    this.$refs.modelTree.setChecked(data.id, false, true);
                    return;
                }

                const el = this.$refs.modelTree;
                const node = el.getNode(data.id);
                const isChecked = node.checked;

                this.$emit('checkModel', data, isChecked);
            },
            // onRadioModelNode(node, data) {
            //
            // },
            // onDblClickModelNode(node, data) {
            //
            // }
        }
    }
</script>

<style lang="less" scoped>
    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .wrapPanel {
        float: left;
        padding: 0 !important;
        overflow-y: hidden;
        border-radius: inherit;
        background-color: inherit;
        color: inherit;

        .load-wrap {
            width: 100%;
            height: 100%;
            backdrop-filter: blur(5px);
        }

        .unload-wrap {
            z-index: 2000;
            position: absolute;
            top: 0;
            /*left: calc(100% + 10px);*/
            width: 400px;
            height: 100%;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: inherit;
            background-color: inherit;
            color: inherit;
            backdrop-filter: blur(5px);

            .unloadHeader-wrap {
                height: 50px;
            }

            .unloadHeader-search {
                float: left;
                margin-top: 10px;
                margin-left: 10px;
                width: 280px !important;
            }

            .unloadHeader-searchIcon {
                height: 32px;
                line-height: 32px;
            }

            .unloadHeader-btnIcon {
                padding-left: 10px;
                height: 50px;
                line-height: 50px;
                font-size: 18px;
                color: inherit;
            }

            .unloadBottom-wrap {
                margin-top: 5px;
                float: right;
                height: 50px;

                .unloadBottom-icon {
                    margin-top: 4px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 18px;
                    color: #206EFC;
                }

                .unloadBottom-info-left {
                    margin-top: 4px;
                    margin-left: 7px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 12px;
                    color: #206EFC;
                }

                .unloadBottom-info-center {
                    margin-top: 2px;
                    margin-left: 5px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 20px;
                    color: #206EFC;
                }

                .unloadBottom-info-right {
                    margin-top: 4px;
                    margin-left: 5px;
                    margin-right: 30px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 12px;
                    color: #206EFC;
                }

                .unloadBottom-cancel {
                    float: right;
                    margin-right: 10px;
                    width: 60px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    border: none;
                    border-radius: 4px;
                    background-color: #EFF1F5;
                    color: black;
                    font-weight: 500;
                    text-align: center;
                }

                .unloadBottom-cancel:hover {
                    opacity: 0.7;
                }

                .unloadBottom-yes {
                    float: right;
                    margin-right: 15px;
                    width: 60px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                    border: none;
                    border-radius: 4px;
                    background-color: #206EFC;
                    color: white;
                    font-weight: 500;
                    text-align: center;
                }

                .unloadBottom-yes:hover {
                    opacity: 0.7;
                }
            }
        }

        .unload-wrap-left{
            right: calc(100% + 10px);
        }

        .unload-wrap-right{
            left: calc(100% + 10px);
        }

        .title-wrap {
            width: 100%;
            height: 38px;
            line-height: 38px;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        .title-wrap span {
            float: left;
            padding-left: 10px;
            font-size: 14px;
            color: inherit;
        }

        .title-wrap a {
            float: right;
            padding-right: 10px;
            font-size: 18px;
            color: inherit;
        }

        .showUnload-btn_active {
            color: #0076FF !important;
        }
    }

    .unloadModelTree-wrap {
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 50px - 50px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }
    }

    .modelTree-wrap {
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 13px !important;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent !important;
            border: none;
            height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e739";
            color: #0076FF;
            font-size: 16px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 16px;
            line-height: 35px;
            position: absolute;
            height: 7px;
            top: 0;
            left: 0;
            width: 3px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
            background-color: transparent !important;
        }
    }

    .empty-wrap {
        position: absolute;
        top: 38px;
        width: 100%;
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-empty__description p) {
            color: inherit !important;
        }
    }

    .tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 80%;
        height: 35px;
        line-height: 35px;
        padding-right: 33px;
    }

    .tree-node-unload {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 100%;
        height: 35px;
        line-height: 35px;
        padding-right: 10px;
    }

    .tree-node-icon {
        margin-right: 3px;
        margin-left: -5px;
        margin-top: 2px;
        width: 20px;
    }

    .tree-node-icon-file {
        color: #6DD3FD;
    }

    .tree-node-icon-model {
        color: inherit;
    }

    .tree-node-info {
        position: relative;
        left: -11px;
        top: 2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: red;
    }

    .tree-node-content {
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-preview {
        color: rgba(125, 125, 125, 0.5) !important;
    }

    .tree-node-loading {
        width: 20px;
        margin-top: 2px;
        animation: rotating 2s linear infinite;
    }

    .tree-node-location {
        width: 20px;
        margin-top: 2px;
    }

    .tree-node-location:hover {
        color: #0076FF !important;
    }

    .tree-node-delete {
        width: 20px;
    }

    .tree-node-delete:hover {
        color: #0076FF !important;
    }

    .tree-node-mask {
        width: 25px;
        height: 32px;
        margin-right: -25px;
        background: inherit;
    }
</style>
