/* 处理当前选择的合并名称 */
export const getChooseName = (selectUserList) => {
  let currentList = JSON.parse(JSON.stringify(selectUserList))
  currentList = currentList.sort((a, b) => { return a.id - b.id}) //升序
  let str = ''
  const showArr = []
  if (currentList && currentList.length) {
    const obj = {}
    currentList.forEach(element => {
      if (!obj || !obj[element.type_id]) {
        obj[element.type_id] = [element]
      } else {
        obj[element.type_id].push(element)
      }
    })
    for (const key in obj) {
      let element = obj[key]
      if (key === '1' || key === '5' || key === '6') {
        element.forEach(item => {
          showArr.push(item.name)
        })
      } else if (key === '2') {
        // 构件
        const cateObj = {}
        element = unique(element, 'name')
        element.forEach(item => {
          const splitArr = item.name.split('-')
          if (!cateObj || !cateObj[splitArr[0]]) {
            cateObj[splitArr[0]] = [item]
          } else {
            cateObj[splitArr[0]].push(item)
          }
        })
        for (const i in cateObj) {
          if (cateObj[i] && cateObj[i].length) {
            const name = cateObj[i][0].name.split('-')[0]
            let arr = []
            cateObj[i].forEach(element => {
              arr.push(Number(element.name.split('-')[1]))
            })
            
            arr = [...new Set(arr)]
            const list = fn(arr, 1)
            list.forEach(element => {
              if (element.length > 1) {
                showArr.push(name + '_' + element[0] + '-' + element[element.length - 1])
              } else {
                showArr.push(element[0] ? name + '_' + element[0] : name)
              }
            })
          }
        }
      } else if (key === '3') {
        // 标高
        const cateObj = {}
        element = unique(element, 'name')
        element.forEach(item => {
          const splitArr = item.name.split('-')
          if (!cateObj || !cateObj[splitArr[0]]) {
            cateObj[splitArr[0]] = [item]
          } else {
            cateObj[splitArr[0]].push(item)
          }
        })
        for (const i in cateObj) {
          if (cateObj[i] && cateObj[i].length) {
            const name = cateObj[i][0].name.split('-')[0]
            let arr = []
            let end = ''
            cateObj[i].forEach((element, index) => {
              const str = element.name.split('-H')[1] // -5.2m~-5m
              const strArr = str.split('~')
              arr.push(parseFloat(strArr[0]))
              if (index === (cateObj[i].length - 1)) {
                end = parseFloat(strArr[1])
              }
            })
            arr = [...new Set(arr)].sort((a, b) => a - b)
            const list = fn(arr, cateObj[i][0].option_config.minUnit)
            list.forEach(element => {
              if (element.length > 1) {
                showArr.push(name + '-H' + element[0] + 'm~' + ((element[element.length - 1] + parseFloat(cateObj[i][0].option_config.minUnit)) > end ? end : (element[element.length - 1] + parseFloat(cateObj[i][0].option_config.minUnit))) + 'm')
              } else if (element.length === 1) {
                const endNum = element[0] + cateObj[i][0].option_config.minUnit
                showArr.push(name + '-H' + element[0] + 'm~' + (endNum > end ? end : endNum) + 'm')
              }
            })
          }
        }
      } else if (key === '4') {
        // 里程
        element = unique(element, 'name')
        let positionArr = element
        positionArr = positionArr.sort((a, b) => {
          return a?.option_config?.positionKey - b?.option_config?.positionKey
        })
        const mileObj = {}
        positionArr.forEach(element => {
          const config = element.option_config
          const str = (element?.prefix ? element?.prefix + '_' : '') + 
            config?.name + '-' + 
            config?.start_mile + '-' + 
            config?.start_meter + '-' + 
            config?.deviation + '-' + 
            config?.total + '-' + 
            config?.minUnit + '-' + 
            config?.partial_coding + '-' + 
            (config?.start_width ? '-' + config?.start_width : '') + 
            (config?.end_width ? '-' + config?.end_width : '') + 
            (config?.widthMinUnit ? '-' + config?.widthMinUnit : '') + 
            (config?.start_height ? '-' + config?.start_height : '') + 
            (config?.end_height ? '-' + config?.end_height : '') + 
            (config?.heightMinUnit ? '-' + config?.heightMinUnit : '')
            
          if (!mileObj || !mileObj[str]) {
            mileObj[str] = [element]
          } else {
            mileObj[str].push(element)
          }
        });
        for (const key in mileObj) {
          if (Object.hasOwnProperty.call(mileObj, key)) {
            const element = mileObj[key];
            const arr = fnMile(element)
            if (arr?.length) {
              let arrName =  ''
              arr.forEach(item => {
                if (item?.length && item?.length > 1) {
                  const config = item[0]?.option_config
                  let type = (item[0]?.option_config?.start_width || item[0]?.option_config?.start_width === 0) && (item[0]?.option_config?.start_height || item[0]?.option_config?.start_height === 0) ? 
                    1 : 
                    (item[0]?.option_config?.start_width || item[0]?.option_config?.start_width === 0) && (!item[0]?.option_config?.start_height && item[0]?.option_config?.start_height !== 0) ? 
                      2 : 
                      (!item[0]?.option_config?.start_width && item[0]?.option_config?.start_width !== 0) && (item[0]?.option_config?.start_height || item[0]?.option_config?.start_height === 0) ? 3 
                      : 0;
                  const start = item[0]
                  const end = item[item?.length - 1]
                  const startNum = config?.prefix ? 
                    start?.name?.split('-')[1].indexOf('+') > -1 ? 
                      start?.name?.split('-')[1]?.split('~')[0]?.split('+')[0].replace(/[^0-9]/ig, '') : 
                      start?.name?.split('-')[1].replace(/[^0-9]/ig, '') : 
                    start?.name?.split('-')[0].indexOf('~') > -1 ?
                      start?.name?.split('-')[0]?.split('~')[0]?.split('+')[0].replace(/[^0-9]/ig, '') :
                      start?.name?.split('~')[0]?.split('+')[0].replace(/[^0-9]/ig, '')
                  const startMeters = config?.prefix ? 
                    start?.name?.split('-')[1].indexOf('+') > -1 ?
                      start?.name?.split('-')[1]?.split('~')[0]?.split('+')[1] : 
                      0 - parseFloat(start?.name?.split('-')[2]) :
                    start?.name?.split('-')[0].indexOf('+') > -1 ?
                      start?.name?.split('-')[0]?.split('~')[0]?.split('+')[1] : 
                      0 - parseFloat(start?.name?.split('-')[1]?.split('~')[0])
                  const endNum = config?.prefix ? 
                    end?.name?.split('-')[1]?.split('~')[1]?.split('+')?.length ? 
                      end?.name?.split('-')[1]?.split('~')[1]?.split('+')[0].replace(/[^0-9]/ig, '') :
                      end?.name?.split('-')[1].replace(/[^0-9]/ig, '') : 
                    end?.name?.split('~')[1]?.split('-')[0].indexOf('+') > -1 ? 
                      end?.name?.split('~')[1]?.split('-')[0]?.split('+')[0].replace(/[^0-9]/ig, '') : 
                      end?.name?.split('~')[1]?.split('-')[0].replace(/[^0-9]/ig, '')
                  let meters = config?.prefix ? 
                    end?.name?.split('~')[1].indexOf('+') > -1 ? 
                      end?.name?.split('~')[1]?.split('+')[1]?.split('-')[0] : 
                      0 - parseFloat(end?.name?.split('~')[1]?.split('-')[1]) :
                    end?.name?.split('~')[1].indexOf('+') > -1 ?
                      end?.name?.split('~')[1]?.split('+')[1] : 
                      0 - parseFloat(end?.name?.split('~')[1]?.split('-')[1])
                  if (+meters >= 1000) {
                    meters = +meters - Math.floor(+meters / 1000) * 1000
                  }
                  const startMetersStr = parseFloat(startMeters) >= 0 ? 
                    parseFloat(startMeters) >= 10 && parseFloat(startMeters) < 100 ? '+0' + parseFloat(startMeters) : parseFloat(startMeters) < 10 ? '+00' + parseFloat(startMeters) : '+' + startMeters : 
                    parseFloat(startMeters) <= -10 && parseFloat(startMeters) > -100 ? '-0' + Math.abs(startMeters) : parseFloat(startMeters) > -10 ? '-00' + Math.abs(startMeters) : '-' + Math.abs(startMeters) 
                  const metersStr = parseFloat(meters) >= 0 ? 
                    parseFloat(meters) >= 10 && parseFloat(meters) < 100 ? '+0' + parseFloat(meters) : parseFloat(meters) < 10 ? '+00' + parseFloat(meters) : '+' + parseFloat(meters) : 
                    parseFloat(meters) <= -10 && parseFloat(meters) > -100 ? '-0' + Math.abs(parseFloat(meters)) : parseFloat(meters) > -10 ? '-00' + Math.abs(parseFloat(meters)) : '-' + Math.abs(parseFloat(meters)) 
                  if (type === 1) {
                    const ws = start?.name?.split('~')[1]?.split('-W')[start?.name?.split('~')[1]?.split('-W').length - 1]
                    const we = end?.name?.split('~')[2]?.split('-H')[0]
                    const hs = start?.name?.split('~')[2]?.split('-H')[start?.name?.split('~')[2]?.split('-H').length - 1]
                    const he = end?.name?.split('~')[end?.name?.split('~')?.length - 1]
                    const str = `${config?.prefix ? config.prefix + '-' : ''}${config.name}${startNum}${startMetersStr}~${config.name}${endNum}${metersStr}-W${ws}~${we}-H${hs}~${he}`
                    arrName = arrName ? arrName + ', ' + str : str
                  } else if (type === 2) {
                    console.log(end, 'end')
                    console.log(metersStr, 'metersStr')
                    const ws = start?.name?.split('~')[1]?.split('-W')[start?.name?.split('~')[1]?.split('-W').length - 1]
                    const we = end?.name?.split('~')[2]?.split('-H')[0]
                    const str = `${config?.prefix ? config.prefix + '-' : ''}${config.name}${startNum}${startMetersStr}~${config.name}${endNum}${metersStr}-W${ws}~${we}`
                    arrName = arrName ? arrName + ', ' + str : str
                  } else if (type === 3) {
                    const hs = start?.name?.split('~')[1]?.split('-H')[start?.name?.split('~')[1]?.split('-H').length - 1]
                    const he = end?.name?.split('~')[end?.name?.split('~')?.length - 1]
                    const str = `${config?.prefix ? config.prefix + '-' : ''}${config.name}${startNum}${startMetersStr}~${config.name}${endNum}${metersStr}-H${hs}~${he}`
                    arrName = arrName ? arrName + ', ' + str : str
                  } else {
                    const str = `${config?.prefix ? config.prefix + '-' : ''}${config.name}${startNum}${startMetersStr}~${config.name}${endNum}${metersStr}`
                    arrName = arrName ? arrName + ', ' + str : str
                  }

                } else if (item?.length && item?.length == 1) {
                  arrName = arrName ? arrName + ', ' + item[0].name : item[0].name
                }
              });
              showArr.push(arrName)
            }
          }
        }
      }
    }
  }
  if (showArr && showArr?.length) {
    showArr.forEach(element => {
      if (!str) {
        str += element
      } else {
        str += (', ' + element)
      }
    })
  }
  return str
}
/* 判断里程是否连续 */
const fnMile = (arr) => {
  var result = []
  var i = 0
  result[i] = [arr[0]]
  arr.reduce((prev, cur) => {
    parseFloat(cur.option_config.positionKey) - parseFloat(prev.option_config.positionKey) === 1 ? result[i].push(cur) : result[++i] = [cur]
   return cur
  })
  return result
}
const unique = (arr, attrName) => {
  const res = new Map()
  return arr.filter(
    (a) => !res.has(a[attrName]) && res.set(a[attrName], )
  )
}
/* 获取数组连续的1.2.3二维数组 */
const fn = (arr, interval) => {
  var result = []
  var i = 0
  result[i] = [arr[0]]
  arr.reduce((prev, cur) => {
   parseFloat((parseFloat(cur) - parseFloat(prev)).toFixed(5)) <= parseFloat(parseFloat(interval).toFixed(5)) ? result[i].push(cur) : result[++i] = [cur]
   return cur
  })
  return result
}