<!--
    @name: FlowRecover
    @description：FlowRecover
    @author: ZengWei
    @date: 2021-10-28 11:23
-->
<template>
  <div class="flow-recover">
    <div class="type-label">
      流程审批记录
      <span style="float: right; cursor: pointer" @click="displayFlow">
        {{ flowShow ? '关闭' : '查看' }}流程图
      </span>
    </div>
    <div>
      <div v-if="flowShow" ref="topology" class="topology"></div>
    </div>
    <div class="flow-logs">
      <div class="log-item" v-for="(item,index) in flowLogs" :key="index">
        <div class="log-item-right">
          <div class="log-item-name">
            <div class="log-item-type">
              <div>{{ username(item) }}</div>
              <div :style="item.step_type == -1 ? 'color:#E4323B;backfround: #FFF1F1' : 'color: #24A148'">
                {{ item.name }}
              </div>
            </div>
            <div class="right-date">{{ getDate(item.updated_at) }}</div>
          </div>
          <div class="log-item-remaks">{{ item.remarks }}</div>
          <div  v-if="item.appendix && item.appendix.length">
            <fileUpload :disabled="true" :files="item.appendix"></fileUpload>
          </div>
        </div>
      </div>
      <!-- <el-table border :data="flowLogs" stripe style="width: 100%">
        <el-table-column label="时间">
          <template #default="{ row }">
            {{ row.updated_at ? row.updated_at.substring(0, 16) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="处理人">
          <template #default="{ row }">
            {{ username(row) }}
          </template>
        </el-table-column>
        <el-table-column label="意见附件">
          <template #default="{ row }">
            <div v-if="row.images && row.images.length">
              <img
                v-for="(item, index) in row.images"
                :key="index + 'logImg'"
                class="logImg"
                :src="item"
                @click="handleView(row.images, index)"
              />
            </div>
            <div style="padding: 10px 0">
              <p v-for="(item, index) in row.files" :key="index + 'file'">
                {{ index + 1 }}、
                <a :download="item.filename" :href="item.filepath">
                  {{ item.filename }}
                </a>
              </p>
            </div>
            <div>{{ row.remarks }}</div>
          </template>
        </el-table-column>
        <el-table-column label="动作">
          <template #default="{ row }">
            <span
              :style="row.step_type == -1 ? 'color:#ec4031' : 'color:#0076FF'"
            >
              {{ row.name }}
            </span>
          </template>
        </el-table-column>
      </el-table> -->
    </div>
  </div>
</template>

<script>
  import { Topology } from '@topology/core'
  import { register as registerFlow } from '@topology/flow-diagram'
  import { register as registerActivity } from '@topology/activity-diagram'
  import fileUpload from '@/components/fileUpload/newFiles/index'
	// import { Table,TableColumn } from 'element-ui'
  let canvas
  const canvasOptions = {
    hideInput: true,
    grid: false,
    hideRotateCP: true,
    disableScale: false,
    rotateCursor: 'crosshair',
    scroll:false,
  }

  export default{
    name: 'FlowRecover',
    components: { fileUpload },// 'el-table': Table, 'el-table-column': TableColumn
    props: {
      logs: {
        type: Array,
        default: () => {
          return []
        },
      },
      nextStep: {
        type: String,
        default: '',
      },
      canvasData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data(){
      return {
        flowShow: false,
        flowLogs:null,
      }
    },
    computed:{
      username(){
        return function (row) {
          let targetUsers = row.target_users
          let nameArr = Array.from(targetUsers, (item) => {
            return item.name || item.user_name
          })
          if (nameArr.length > 0) {
            return nameArr.join(',')
          }
          return row.user_name
        }
      }
    },
    methods:{
      // 处理时间
      getDate(value) {
        if (!value) return '';
        let dateObj = null;
        if (value instanceof Date) {
          dateObj = value;
        } else if (isNaN(value)) {
          dateObj = new Date(value);
        } else {
          dateObj = new Date(value * 1000);
        }
        // @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
        if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
        const year = dateObj.getFullYear();
        // 无效的时间
        if (year === 1970) return '';
        let month = this.addZero(dateObj.getMonth() + 1);
        const day = this.addZero(dateObj.getDate());
        const hour = this.addZero(dateObj.getHours());
        const minute = this.addZero(dateObj.getMinutes());
        return `${month}月${day}日 ${hour}:${minute}`;
      },
      /**
       * @desc: 补零
       * @param {Number} val 需要补零的值
       */
      addZero(val) {
        if (isNaN(val) || val < 0 || val >= 10) return val;
        return `0${val}`;
      },
      handleView() {},
      dealWithCanvas(canvasData) {
        let allNode = canvasData.pens
        let repaint = [] //重绘数据
        let stepDone = [] //已处理的步骤
        for (const node of allNode) {
          if (node.data.color_status === 1) {
            stepDone.push(node.id)
          }
        }
        for (const node of allNode) {
          if (node.data.color_status === 1) {
            node.strokeStyle = '#40b67c'
            node.lineWidth = 2
          }
          if (node.name === 'polyline') {
            if (this.nextStep) {
              stepDone.push(this.nextStep)
              if (
                stepDone.indexOf(node.to.id) !== -1 &&
                stepDone.indexOf(node.from.id) !== -1
              ) {
                node.strokeStyle = '#40b67c'
                node.lineWidth = 2
              }
            } else {
              if (stepDone.indexOf(node.from.id) !== -1) {
                node.strokeStyle = '#40b67c'
                node.lineWidth = 2
              }
            }
          }
          if (node.data.target_user !== undefined) {
            let nameArr = Array.from(
              node.data.target_user,
              (item) => item.name
            )
            if (nameArr.length > 0) {
              let name = '审批人：' + nameArr.join(',')
              node.text = name
            }
          }
          repaint.push(node)
        }
        canvasData.pens = repaint
        return canvasData
      },
      displayFlow() {
        this.flowShow = !this.flowShow
        if (this.flowShow) {
          this.$nextTick(() => {
            canvas = new Topology(this.$refs.topology, canvasOptions)
            canvas.data.lineName = 'polyline'
            let recoverJson = this.dealWithCanvas(this.canvasData)
            canvas.open(recoverJson)
            canvas.centerView()
            canvas.fitView(5)
            canvas.lock(1)
            canvas.render()
          })
        } else {
          canvas.destroy()
        }
      },
    },
    created () {
      this.flowLogs = this.logs
      registerFlow()
      registerActivity()
    },
  }
</script>

<style lang="less" scoped>
  .flow-recover {
    .type-label {
      border-left: 3px solid var(--themeColor) !important;
      padding: 0 10px;
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      font-weight: 600;
    }
    .topology {
      width: 100%;
      height: 400px;
    }
    .flow-logs {
      margin-bottom: 20px;
      .log-item {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        border-bottom: 1px solid #F2F4F8;
        padding-bottom: 10px;
        padding-left: 14px;
        box-sizing: border-box;
        .log-item-left {
          width: 20px;
          height: 20px;
          margin: 0 10px;
        }
        .log-item-right {
          flex: 1;
          width: 100%;
          .log-item-name {
            display: flex;
            justify-content: space-between;
            .log-item-type {
              max-width: calc(100% - 80px);
              height: 22px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 550;
              color: #121619;
              line-height: 22px;
              display: flex;
              div:nth-child(1) {
                display: inline-block;
                width: auto;
                margin-right: 6px;
              }
              div:nth-child(2) {
                flex: 1;
                display: inline-block;
                height: 20px;
                background: #D9F5E0;
                border-radius: 10px;
                padding: 8px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                box-sizing: border-box;
                color: #24A148;
                line-height: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .right-date {
              width: auto;
              height: 20px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #8A8F99;
              line-height: 20px;
            }
          }
          .log-item-remaks {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #121619;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
