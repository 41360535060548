<!--
    @name: TaskConfig
    @description：TaskConfig
    @author: ZengWei
    @date: 2022-05-18 17:53
-->
<template>
  <div class="task-config">
    <div class="scrollbar">
      <div style="margin: 15px">
        <div class="label-title">任务引擎配置</div>
        <el-form ref="form" :rules="rules" :model="taskConfig" label-position="top" label-width="80px">
          <el-form-item label="任务引擎名称" prop="name">
            <el-input :disabled="disabled" v-model="taskConfig.name" @change="onChange"></el-input>
          </el-form-item>
          <el-form-item label="任务引擎描述">
            <el-input :disabled="disabled" type="textarea" v-model="taskConfig.desc" @change="onChange"></el-input>
          </el-form-item>
        </el-form>
        <div class="label-title">定时任务配置</div>
        <el-form ref="interval" label-position="top" label-width="80px">
          <el-form-item label="开启定时任务">
            <el-radio-group :disabled="disabled" v-model="taskConfig.interval_open" @change="onChange">
              <el-radio :label="0">关闭</el-radio>
              <el-radio :label="1">开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="任务执行周期" v-if="taskConfig.interval_open === 1">
            <el-select
              :disabled="disabled"
              class="width-130"
              v-model="interval.cycle"
              @change="onChange"
              placeholder="请选择">
              <el-option label="每月" :value="1"></el-option>
              <el-option label="每周" :value="2"></el-option>
              <el-option label="每天" :value="3"></el-option>
              <el-option label="N天" :value="4"></el-option>
              <el-option label="每小时" :value="5"></el-option>
              <el-option label="N小时" :value="6"></el-option>
              <el-option label="N分钟" :value="7"></el-option>
            </el-select>
            <el-select
              :disabled="disabled"
              class="width-130"
              v-if="interval.cycle === 2"
              v-model="interval.week"
              @change="onChange"
              placeholder="请选择">
              <el-option label="周一" :value="1"></el-option>
              <el-option label="周二" :value="2"></el-option>
              <el-option label="周三" :value="3"></el-option>
              <el-option label="周四" :value="4"></el-option>
              <el-option label="周五" :value="5"></el-option>
              <el-option label="周六" :value="6"></el-option>
              <el-option label="周日" :value="0"></el-option>
            </el-select>
            <el-input :disabled="disabled" v-if="interval.cycle === 1" class="width-130" type="number" v-model="interval.day" @change="onChange">
              <template slot="append">日</template>
            </el-input>
            <el-input :disabled="disabled" v-if="interval.cycle === 4" class="width-130" type="number" v-model="interval.day" @change="onChange">
              <template slot="append">天</template>
            </el-input>
            <el-input :disabled="disabled" v-if="interval.cycle !== 7" class="width-130" type="number" v-model="interval.hour" @change="onChange">
              <template slot="append">时</template>
            </el-input>
            <el-input :disabled="disabled" class="width-130" type="number" v-model="interval.minute" @change="onChange">
              <template slot="append">分</template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {RadioGroup,Radio} from "element-ui";
export default {
  name: "TaskConfig",
  components: {
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
  },
  props: ['configProp','disabled'],
  data() {
    return {
      taskConfig:{
        name: '',
        desc: '',
        interval_open: 0
      },
      rules: {
        name: [
          { required: true, message: '请输入任务引擎名称', trigger: 'blur' },
        ],
      },
      interval:{
        cycle: 1,
        week: 1,
        day: 1,
        hour: 1,
        minute: 1,
      }
    }
  },
  watch: {
    configProp: {
      handler() {
        this.initData()
      },
      deep: true
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData(){
      this.taskConfig.name = this.configProp?.name || ''
      this.taskConfig.desc = this.configProp?.desc || ''
      this.taskConfig.interval_open = this.configProp?.interval_open || 0
      this.interval = this.configProp?.interval || {cycle: 1, week: 1, day: 1, hour: 1, minute: 1}
    },
    close(){
      this.$emit('on-close')
    },
    onChange(){
      const data = {
        name: this.taskConfig.name,
        desc: this.taskConfig.desc,
        interval_open: this.taskConfig.interval_open,
        interval: this.interval,
      }
      this.$emit('on-change',data)
    },
    validForm(){
      this.$refs.form.validate()
    },
  },
}
</script>

<style lang="less" scoped>
.task-config{
  height: 100%;
  overflow: hidden;

  .scrollbar{
    overflow-y: auto;
    height: 100%;
  }

  .label-title{
    border-left: 3px solid var(--themeColor);
    padding: 0 10px;
    line-height: 1;
    margin: 0 0 16px;
    font-weight: 600;
  }

  .width-130 {
    width: 134px;
    margin-right: 25px;
    margin-bottom: 10px;
  }

}
</style>
