<!--
  * @Description: BtnGroup
  * @Author: hw
  * @Date: 2021-10-08 17:11:12
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-23 10:04:32
 -->
 <template>
	<article class="icon-btn-menu-container" :style="{ '--icon-scale-size': iconScale }">
		<section class="inner-wrap" :class="layout">
			<div class="icon-btn-menu-wrap" :style="align">
				<nav v-for="(item, index) in btnGroupConfig" :class="['icon-btn-item', { isHidden: !item.isShow }]"
					:style="item.style" :key="index + 'key'">
					
					<img v-if="valueType === 'img'" :src="item.renderImgUrl || item.defaultIcon || initialImg" :style="imgSize"
						@click.stop="onClick(item)" @dblclick.stop="onDoubleClick(item)" />

					<svg v-if="valueType === 'svg'" class="icon svg-icon" aria-hidden="true" :style="imgSize"
						@click.stop="onClick(item)" @dblclick.stop="onDoubleClick(item)">
						<use :style="imgSize" :xlink:href="item.renderSvg || item.defaultIcon || '#iconshoucang1'"></use>
					</svg>

					<span v-if="!textHide" class="icon-text" :style="textConfig(item)" @click.stop="onClick(item)"
						@dblclick.stop="onDoubleClick(item)">{{ item.label }}</span>
				</nav>
			</div>
		</section>
	</article>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { removeEventBus, getStatusVal } from '@/utils/tools';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';
import { judgingEventLogic } from '@/utils/tools';
import screenComs from '@/custom-component/component-config/newScreen.js';
export default {
	name: 'BtnGroup',
	props: {
		element: {
			type: Object,
			required: false,
			default: () => { }
		},
		componentList: {
			default: null
		},
		// 是否为分组
		isGroup: {
			type: Boolean
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			// 单击/和激活状态
			focused: {},
			dblFocused: {},
			// 单击/双击的定时器,解决双击事件触发单击事件
			clickTimer: null,
			// 默认图片
			initialImg: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png',
		};
	},
	computed: {
		...mapState(['componentData', 'subsidiaryComponentData']),
		/**
		 * @description: 嵌入页面的参数获取
		 * @return {*}
		 */
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
		},
		/**
		 * @description: 布局
		 * @return {*}
		 */
		layout() {
			return this.statusConfig?.layout || 'horizontal';
		},
		/**
		 * @description: 对齐方式
		 * @return {*}
		 */
		align() {
			const { align = 'left', gap = 0 } = this.statusConfig;
			const styleMap = {
				left: {
					'justify-content': 'flex-start'
				},
				center: {
					'justify-content': 'center'
				},
				right: {
					'justify-content': 'flex-end'
				}
			};
			return Object.assign({}, styleMap[align], { gap: `${gap}px` });
		},
		/**
		 * @description: img尺寸
		 * @return {*}
		 */
		imgSize() {
			return `width: ${this.statusConfig?.iconSize}px; height: auto;`;
		},
		/**
		 * @description: 配置/确保响应式
		 * @return {*}
		 */
		btnGroupConfig() {
			const result = (this.element?.btnGroupConfig.configList || []).map((el, index) => {
				el.style = {
					flex: 1 / this.size + (el.btnSpace || 0)
				};
				el.index = index
				return el;
			});
			return result;
		},
		/**
		 * @description: 图片/icon类型
		 * @return {*}
		 */
		valueType() {
			return this.element?.btnGroupConfig.valueType || 'icon';
		},
		/**
		 * @description: 显示条目数
		 * @return {*}
		 */
		size() {
			return this.statusConfig?.size || 4;
		},
		/**
		 * @description: 组件配置
		 * @return {*}
		 */
		statusConfig() {
			return this.element ? this.element.statusConfig || {} : {};
		},
		/**
		 * @description: 隐藏文本
		 * @return {*}
		 */
		textHide() {
			return this.element.btnGroupConfig.textHiding || false;
		},
		/**
		 * @description: 按钮缩放
		 * @return {*}
		 */
		iconScale() {
			return this.element.btnGroupConfig.iconScale;
		}
	},
	mounted() {
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			const { component, list = [] } = config;
			if (component !== this.element.id) return;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.subComponentData, [], this.EDITOR_pageUUID);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							window.$EditorDebug.startBehavior(
								this.element,
								screenComs.componentList.find(ele => ele.component === 'BtnGroup'),
								funName,
								param
							);
							eval(this[funName])(param);
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		});
		// 默认选中
		this.btnGroupConfig.map(item => {
			if (item.isDefaultSelected) {
				this.focused = { ...this.focused, [item.type]: true };
				this.switchIcon(item, 1);
				this.toResolveData({ value: 1 }, item, 'click');
			}
		});
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	},
	methods: {
		/**
		 * @description: 文本属性配置
		 * @return {*}
		 */
		textConfig(tab) {
			const e = this.element?.btnGroupConfig.textConfig || {};
			const textObj = {
				color: tab.renderTextColor || e.defaultColor || '#fff',
				fontSize: e.size + 'px' || '14px',
				fontWeight: e.fontWeight || 'normal',
				lineHeight: e.lineHeight + 'px' || '20px'
			};
			return textObj;
		},
		/**
		 * @description: 处理双击触发单击事件
		 * @param {Object} tab
		 */
		onClick(tab) {
			clearTimeout(this.clickTimer);
			// 之前为何要注释掉呢？
			this.clickTimer = setTimeout(() => {
				this.onTabClick(tab);
			}, 200);
		},
		/**
		 * @description: 切换图片/图标
		 * @param {*} tab
		 * @param {*} resolveDataNum
		 * @param {*} dbClick
		 * @return {*}
		 */
		switchIcon(tab, resolveDataNum, dbClick = false) {
			console.log(tab, 'tab---tab');
			const { clickColor, dblColor, defaultColor } = this.element?.btnGroupConfig.textConfig;

			const { defaultIcon, clickActiveIcon, dblclickActiveIcon } = tab;
			const defaultImg = 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/618153195a082d3e151fd5f3a9949f3d.png';
			const defaultSvg = '#iconshoucang1';
			// 单击
			if (!dbClick) {
				switch (resolveDataNum) {
					case 1:
						if (this.valueType === 'img') {
							tab.renderImgUrl = clickActiveIcon || defaultImg;
						} else {
							tab.renderSvg = clickActiveIcon || defaultSvg;
						}
						this.changeTextColor(tab, clickColor);
						break;
					case -1:
						if (this.valueType === 'img') {
							tab.renderImgUrl = defaultIcon || defaultImg;
						} else {
							tab.renderSvg = defaultIcon || defaultSvg;
						}
						this.changeTextColor(tab, defaultColor);
						break;
				}
			} else {
				// 双击
				switch (resolveDataNum) {
					case 1:
						if (this.valueType === 'img') {
							tab.renderImgUrl = dblclickActiveIcon || defaultIcon || defaultImg;
						} else {
							tab.renderSvg = dblclickActiveIcon || defaultIcon || defaultSvg
						}
						this.changeTextColor(tab, dblColor);
						break;
				}
			}
		},

		/**
		 * @description: 替换文本颜色
		 * @param {*}
		 * @return {*}
		 */
		changeTextColor(tab, color) {
			if (!color) return;
			tab.renderTextColor = color;
		},
		/**
		 * @description: 被单击时触发回调
		 * @param {*} tab
		 * @return {*}
		 */
		onTabClick(tab) {
			// 暴露出绑定的数据
			let resolveData = null;
			const { defaultColor } = this.element?.btnGroupConfig.textConfig;
			// 3.双击激活状态->未激活: -1
			if (this.dblFocused[tab.type]) {
				resolveData = {
					value: -1
				};
				this.dblFocused[tab.type] = false;
				this.focused = { ...this.focused, [tab.type]: false };
				this.switchIcon(tab, resolveData.value);
			} else {
				if (this.focused[tab.type]) {
					// 1.激活->未激活: -1
					resolveData = {
						value: -1
					};
					this.focused = { ...this.focused, [tab.type]: false };
					this.switchIcon(tab, resolveData.value);
				} else {
					// 2.未激活->激活: 1
					resolveData = {
						value: 1
					};
					this.focused = { ...this.focused, [tab.type]: true };
					this.switchIcon(tab, resolveData.value);
				}
			}

			// 点击互斥
			if (this.element?.btnGroupConfig.clickedValue) {
				this.btnGroupConfig.map(item => {
					if (item.type !== tab.type) {
						this.focused[item.type] = false;
						this.changeTextColor(item, defaultColor);
						this.switchIcon(item, -1);
					}
				});
			}

			// 暴露值
			this.toResolveData(resolveData, tab, 'click');
			// 触发事件
			// event bus  挂载卸载问题
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action: 'any',
					isSearch: true,
					output: resolveData
				});
			}, 0);
		},
		/**
		 * @description: 处理双击
		 * @return {*}
		 */
		onDoubleClick(tab) {
			// 取消单击事件
			const dbClick = true;
			clearTimeout(this.clickTimer);
			this.clickTimer = null;
			// 暴露出绑定的数据
			let resolveData = null;
			// 双击只存在激活操作！
			// 双击时：如果不为单/双击击激活状态，则激活，否则，停止
			if (!this.focused[tab.type] && !this.dblFocused[tab.type]) {
				// 2.未激活->激活
				resolveData = {
					value: 1
				};
				this.dblFocused = { ...this.dblFocused, [tab.type]: true };
				this.switchIcon(tab, resolveData.value, dbClick);
			} else {
				return;
			}
			// 暴露值
			this.toResolveData(resolveData, tab, 'dblclick');
			// 触发事件
			// event bus  挂载卸载问题
			setTimeout(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action: 'any',
					isSearch: true,
					output: resolveData
				});
			}, 0);
		},
		/**
		 * @description: 触发行为逻辑判断
		 * @return {*}
		 */
		judgingBehaviorLogic(ele) {
			for (let i = 0; i < ele.behaviors.length; i++) {
				const item = ele.behaviors[i];
				item.list.forEach(target => {
					const logics = target.logics;
					let logicBoolean = true;
					let behaviorsItem = {
						component: item.component,
						list: []
					};
					if (logics?.length) {
						logicBoolean = judgingEventLogic(logics, this.subComponentData || []);
					}
					if (logicBoolean) {
						behaviorsItem.list.push(target);
						this.$store.commit('triggerEvents', {
							config: {
								behavior: behaviorsItem,
								isBehavior: true
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					}
				});
			}
		},
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, this.subComponentData)) return;
				const { value } = getStatusVal(ele, this.subComponentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
		/**
		 * @desc: 判断操作类型
		 * @return {*}
		 */
		judgingActionType(ele) {
			const { actionType } = ele;
			switch (actionType) {
				case 'pageStatus':
					// 修改页面状态值
					this.updatePageStatus(ele.stateMachine || []);
					break;
				case 'componentBehavior':
					// 触发行为 setTimeout解决resolveData异步问题
					if (ele.behaviors?.length) {
						setTimeout(() => {
							this.judgingBehaviorLogic(ele);
						}, 0);
					}
					break;
				case 'pageAction':
					// 页面事件
					if (ele.effects?.length) {
						ele.effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...ele,
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
			}
		},
		/**
		 * @description: 暴露值
		 * @param {object} resolveData
		 * @param {object} tab
		 * @param {string} clickType
		 * @return {*}
		 */
		toResolveData(resolveData, tab, clickType) {
			// 固定
			this.element.resolveData = resolveData;

			this.$store.commit('updatePageCustomStatus', {
				origin: this.element,
				resolveData
			});
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData
				},
				containerId: null,
				isModify: true,
				pageUUID: this.EDITOR_pageUUID
			});
			const index = +tab.index;
			const comEvents = this.btnGroupConfig[index]?.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					if (comEvents[i].key === clickType) {
						comEvents[i].effects.forEach(effect => {
							this.$store.commit('triggerEvents', {
								config: {
									...comEvents[i],
									...effect
								},
								element: this.element,
								EDITOR_pageUUID: this.EDITOR_pageUUID
							});
						});
					}
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach(ele => {
					if (ele.key === clickType) {
						this.judgingActionType(ele);
					}
				});
			}
		},
		/**
		 * @description: 取消所有状态
		 * @param {Array} paramArr
		 * @return {*}
		 */
		hideAll(paramArr) {
			if (!paramArr) return;
			this.btnGroupConfig.map(item => {
				this.focused[item.type] = false;
				this.switchIcon(item, -1);
			});
		},
		/**
		 * @description: 选中所有状态
		 * @param {Array} paramArr
		 * @return {*}
		 */
		showAll(paramArr) {
			if (!paramArr) return;
			this.btnGroupConfig.map(item => {
				this.focused[item.type] = true;
				this.switchIcon(item, 1);
			});
		},
		/**
		 * @description: 选中单个
		 * @param {*} param
		 * @return {*}
		 */
		showSign(param) {
			this.element.btnGroupConfig.configList.map(item => {
				if (item.type === param.data_id) {
					this.focused[item.type] = true;
					this.switchIcon(item, 1);
				}
			});
		},
		/**
		 * @description: 隐藏单个
		 * @param {*} param
		 * @return {*}
		 */
		hideSign(param) {
			this.element.btnGroupConfig.configList.map(item => {
				if (item.type === param.data_id) {
					this.switchIcon(item, -1);
					this.focused[item.type] = false;
				}
			});
		}
	},
	beforeDestroy() {
		if (!this.EDITOR_pageUUID) {
			removeEventBus('databaseTrigger', this.element.id);
		}
	}
};
</script>

<style lang="less" scoped>
.icon-btn-menu-container {
	width: 100%;
	height: 100%;

	.inner-wrap {
		width: 100%;
		height: 100%;

		.pre-btn-wrap {
			flex-shrink: 0;

			.pre-btn {
				color: inherit;
				cursor: pointer;
			}
		}

		.next-btn-wrap {
			flex-shrink: 0;

			.next-btn {
				color: inherit;
				cursor: pointer;
			}
		}

		.icon-btn-menu-wrap {
			position: relative;
			overflow: hidden;

			.icon-btn-item {
				color: #000;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				img {
					transition: all 0.3s ease;
					cursor: pointer;

					&:hover {
						transform: scale(var(--icon-scale-size));
					}
				}

				svg {
					&:hover {
						transform: scale(var(--icon-scale-size));
					}

					transition: all 0.3s ease;
				}

				.icon-text {
					color: #fff;
				}
			}

			.isHidden {
				display: none;
			}
		}
	}

	.horizontal {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.icon-btn-menu-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			// flex-grow: 1;
			height: 100%;

			.icon-btn-item {
				height: 100%;

				img {
					height: 100%;
					width: auto;

					// object-fit: scale-down;
					&:hover {
						transform: scale(var(--icon-scale-size));
						transition: all 0.3s ease;
					}
				}

				svg {
					&:hover {
						transform: scale(var(--icon-scale-size));
					}

					transition: all 0.3s ease;
				}
			}
		}
	}

	.vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.icon-btn-menu-wrap {
			display: flex;
			align-items: center;
			flex-direction: column;
			height: 100%;
			width: 100%;

			.icon-btn-item {
				width: 100%;

				img {
					height: auto;
					width: 100%;

					&:hover {
						transform: scale(var(--icon-scale-size));
						transition: all 0.3s ease;
					}
				}

				svg {
					&:hover {
						transform: scale(var(--icon-scale-size));
					}

					transition: all 0.3s ease;
				}
			}
		}
	}
}
</style>
