<template>
    <div style="display: flex;">
      <el-select popper-class="icon-select" ref="iconListBox" remote v-model="element.value" placeholder="请选择图标" :filterable="element.config.filterable"
        :remote-method="remoteMethod" @change="handleIconChange">
        <el-option v-for="icon in iconList" :key="icon.id" :label="icon.name" :value="icon.icon">
          <div class="icon-container">
            <span v-html="icon.svg" class="icon-svg"></span>
            <div class="icon-label">{{ icon.name }}</div>
          </div>
        </el-option>
      </el-select>
      <div class="icon-svgs">
        <template>
          <span class="icon-preview" v-if="element.value">
            <span v-html="getSvgByIcon(element.value)"></span>
          </span>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
  import { Select, Option } from 'element-ui';
  import { dataInterface } from '@/apis/data/index';
  export default {
    name: "WidgetIcon",
    components: {
      'el-select': Select,
      'el-option': Option
    },
    mixins: [formItemMinix],
    props: ['element', 'disabled', 'displayData'],
    data() {
      return {
        iconList: [],
        pager: 1,
        loading: false,
        isFinish: false,
        filterName:''
      };
    },
    methods: {
      remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          this.element.value = query
          this.pager = 1;
          this.iconList = [];
          this.isFinish = false;
          this.getList();
        } else {
          this.element.value = '';
        }
      },
      handleIconChange(value) {
        this.element.value = value;
      },
      getSvgByIcon(iconValue) {
        const icon = this.iconList.find(icon => icon.icon === iconValue);
        if (icon && icon.svg) {
          return icon.svg;
        }
        return '';
      },
      getList(page = 1) {
        this.loading = true;
        dataInterface({
          __method_name__: 'dataList',
          object_uuid: 'object642ba073e0b6a',
          view_uuid: 'view64df17f4d35a6',
          filterName:this.element.value,
          page,
          size: 100,
          transcode: 0
        }).then(res => {
          const iconList = res?.data?.data?.data || [];
          this.pager = res?.data?.data?.current_page || page;
          this.iconList = this.iconList.concat(iconList);
          if (iconList.length < 100) {
            this.isFinish = true;
          }
          this.loading = false;
        }).catch(err => {
          console.error('Failed to fetch icons:', err);
          this.loading = false;
        });
      },
    },
    mounted() {
      this.getList();
      this.$nextTick(() => {
        const dropdownMenu = this.$refs.iconListBox.$el.querySelector('.el-select-dropdown__wrap');
        // const dropdown = this.$refs.iconListBox.$el.querySelector('.el-select-dropdown');
    // dropdown.style.maxWidth = '243px';
        if (dropdownMenu) {
          dropdownMenu.addEventListener('scroll', () => {
            if (this.loading || this.isFinish) return;
            const scrollHeight = dropdownMenu.scrollHeight;
            const scrollTop = dropdownMenu.scrollTop;
            const clientHeight = dropdownMenu.clientHeight;
            if (scrollTop + clientHeight >= scrollHeight - 100) {
              this.getList(this.pager + 1);
            }
          });
        }
      });
    },
  };
  </script>
  
  
  <style lang="less" scoped>
  
  .el-select-dropdown__item {
    height: 50px;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80px;
    cursor: pointer;
    padding: 0;
    margin: 5px;
  }
  
  .icon-label {
    font-size: 12px;
    color: #333;
  }
  
  .icon-svgs {
    background-color: #F5F7FA;
    color: #909399;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 0 20px;
  }
  
  
  .el-select-dropdown__item span {
    min-width: 205px;
  }
  </style>
  <style>
  .icon-select{
    width: min-content !important;
  }
  </style>