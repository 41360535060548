var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-card-container"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"list"},_vm._l((_vm.questionList),function(item,index){return _c('div',{key:index,staticClass:"question-item",class:[_vm.getStatus(item), +index === +_vm.activeId ? 'is-active' : ''],on:{"click":function($event){return _vm.turnToQues(index)}}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(index + 1))])])}),0)]),_c('div',{staticClass:"status-box"},[(!_vm.isPaperLog)?[_vm._m(1),_vm._m(2)]:[_vm._m(3),_vm._m(4),_vm._m(5)]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"name"},[_vm._v("答题卡")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-item"},[_c('div',{staticClass:"frame answered"}),_c('span',{staticClass:"name"},[_vm._v("已答")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-item"},[_c('div',{staticClass:"frame answer"}),_c('span',{staticClass:"name"},[_vm._v("未答")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-item"},[_c('div',{staticClass:"frame correct"}),_c('span',{staticClass:"name"},[_vm._v("正确")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-item"},[_c('div',{staticClass:"frame error"}),_c('span',{staticClass:"name"},[_vm._v("错误")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-item"},[_c('div',{staticClass:"frame empty"}),_c('span',{staticClass:"name"},[_vm._v("未答")])])
}]

export { render, staticRenderFns }