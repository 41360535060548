<!--
 * @Author: zyf
 * @LastEditors: zyf
 * @Date: 2023-06-29 16:13:08
 * @LastEditTime: 2023-07-03 16:48:00
 * @description: 
-->
<template>
  <div class="disk-box-container">
    <fileUpload class="upload-box" :files="files" :drag="true" @extra-files="extraFiles" />
    <el-input class="search-input" v-model="searchVaklue" @input="onSearch" placeholder="请输入内容">
        <template #prefix>
            <a class="iconfont iconsousuo2"></a>
        </template>
    </el-input>
    <div class="file-list-box">
      <div class="file-list-item" v-for="(item,index) in fileList" :key="index">
        <div class="title-box" @click="onExpand(item,index)">
          <p class="title">
            {{ item.title }}
            <span class="count">({{ item.children.length }})</span>
          </p>
          <i class="iconfont axpand-icon" :class="item.isExpand ? 'iconpage-arrow1-16' : 'iconpage-arrow2-16'"></i>
        </div>
        <div v-show="item.isExpand" class="list-box" :class="{'pic-list-box':item.type === 'pic'}">
          <template v-for="(el,i) in item.children">
            <template v-if="item.type === 'pic' || item.type === 'video'">
              <div :key="i" class="pic-box">
                <div class="pic">
                  <img :src="el.path" alt="" srcset="">
                </div>
                <div class="oprate">
                  <div class="name">
                    <svg class="icon svg-icon" aria-hidden="true">
                      <use :xlink:href="getFileIcon(item,el)"></use>
                    </svg>
                    <p>{{ el.name }}</p>
                  </div>
                  <el-popconfirm
                      confirm-button-text='删除'
                      cancel-button-text='取消'
                      icon="el-icon-info"
                      icon-color="red"
                      @confirm="onDelete(el)"
                      title="删除后不可恢复，确定删除吗？"
                    >
                      <span slot="reference"><i class="iconfont iconpage-delete-16"></i></span>
                  </el-popconfirm>
                </div>
              </div>
            </template>
            <template v-else>
              <div :key="i" class="list-item-box">
                <div class="list-item">
                  <div class="name-box">
                    <svg class="icon svg-list-icon" aria-hidden="true">
                      <use :xlink:href="getFileIcon(item,el)"></use>
                    </svg>
                    <p>{{ el.name }}</p>
                  </div>
                  <div class="oprate-btn">
                    <p class="preview" @click="onPreview(el)">预览</p>
                    <el-popconfirm
                      confirm-button-text='删除'
                      cancel-button-text='取消'
                      icon="el-icon-info"
                      icon-color="red"
                      @confirm="onDelete(el)"
                      title="删除后不可恢复，确定删除吗？"
                    >
                      <span slot="reference" class="delete">删除</span>
                  </el-popconfirm>
                    
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui';
import fileUpload from '@/components/fileUpload/newFiles/index.vue'
import {openUrl} from '@/utils/tools'
export default {
  name: "Disk",
  components: {
    fileUpload,
    'el-popconfirm': Popconfirm,
  },
  props: {
  },
  data() {
    return {
      files: null,// 上传的文件夹
      searchVaklue: '',
      fileList: [
        {
          title: '图片',
          type: 'pic',
          children: [
            {
              id:1,
              name: '图片1图片1图片1图片1图片1图片1图片1',
              path: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5ee087cc1b2c70385e1ef6a0596a35aa.png',
            },
            {
              id:2,
              name: '图片1',
              path: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5ee087cc1b2c70385e1ef6a0596a35aa.png',
            },
            {
              id:3,
              name: '图片1',
              path: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/5ee087cc1b2c70385e1ef6a0596a35aa.png',
            }
          ]
        },
        {
          title: '文档',
          type: 'doc',
          children: [
            {
              id:4,
              name:'构件得25列表构件得25列表构件得25列表构件得25列表.xlsx',
            },
            {
              id:5,
              name:'工程资料.docx',
            },
            {
              id:6,
              name:'进度管理.pptx',
            },
            {
              id:7,
              name:'CAD图纸.dwg',
            }
          ]
        },
        {
          title: '视频',
          type: 'video',
          children: []
        },
        {
          title: '模型',
          type: 'mpdel',
          children: []
        },
        {
          title: 'CAD',
          type: 'cad',
          children: [
            {
              id:8,
              name:'CAD图纸1.dwg',
            }
          ]
        },
        {
          title: '其他',
          type: '',
          children: []
        },
      ]
    }
  },
  computed: {
    // TODO 后期根据接口数据在进行文档类型判断
    getFileIcon () { 
      return function (data,childItem) { 
        if (data?.type === 'doc') {
          let name = childItem?.name;
          if (name) {
            if (name.indexOf('.pdf') > -1) {
              return '#iconpdf';
            } else if (name.indexOf('.ppt') > -1) {
              return '#iconppt';
            } else if (name.indexOf('.doc') > -1) {
              return '#iconword';
            } else if (name.indexOf('.xls') > -1) {
              return '#iconbiaoge1';
            } else if (name.indexOf('.txt') > -1) {
              return '#icontxt';
            } else if (name.indexOf('.dwg') > -1) {
              return '#icontuzhi';
            }
          }
        } else if (data?.type === 'video') { 
          return '#iconshipin1'
        } else if (data?.type === 'pic') { 
          return '#icontupianzhaopian'
        }else if (data?.type === 'mpdel') { 
          return '#iconmoxing3'
        }else if (data?.type === 'cad') { 
          return '#icontuzhi'
        }      
        return '#iconweizhiwenjian';
      }
    }
  },
  mounted() {
    
  },
  methods: {
    /**
     * @description: 折叠
     * @param {*} item 每一大类的数据
     * @param {*} index 索引
     * @return {*}
     */
    onExpand (item,index) { 
      item.isExpand = !item.isExpand;
      this.$set(this.fileList, index, item);
    },
    /**
     * @description: 搜索附件
     * @param {*} value
     * @return {*}
     */
    onSearch (value) {
      console.log(value, '搜索');
    },
    /**
     * @description: 删除
     * @param {*} data 删除项的数据
     * @return {*}
     */
    onDelete (data) { 
      console.log(data, '删除');
      this.reMove(data.id);
      this.$message({
        showClose: true,
        message: '删除成功！',
        type: 'success'
      });
    },
    /**
     * @description: 移除数组要删除的数据
     * @param {*} id 移除的id
     * @return {*}
     */
    reMove (id) { 
      const loop = (list) => { 
        list.forEach((item,i) => { 
          if (+item.id === +id) {
            list.splice(i, 1);
          } else if (item.children && item.children.length) { 
            loop(item.children)
          }
        })
      }
      loop(this.fileList);
    },
    /**
     * @description: 预览
     * @param {*} data 预览项的数据
     * @return {*}
     */
    onPreview(data){ 
      console.log(data, '预览');
      if (['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
        data.path.substring(data.path.lastIndexOf('.') + 1).toLowerCase()
      ) >= 0) { 
        // window.open(data.path);
        openUrl(data.path,data.path)
      }
    },

    /**
     * @description: 上传
     * @param {*} files 上传的附件数据
     * @return {*}
     */
    extraFiles (files) {
      console.log(files,'files')
      this.files = files;
      if (files.length === 0) { 
        return;
      }
      // TODO根据接口
      this.fileList.forEach(el => { 
        if (el.type === 'doc' && ['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].includes(files[0].fileext)) { 
          el.children.push(files[0])
        }else if (el.type === 'pic' && ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].includes(files[0].fileext)) { 
          el.children.push(files[0])
        }
      })
      console.log(this.fileList,'this.fileList')
    },
  },
}
</script>
<style lang="less">
.disk-box-container{
  .el-input--small .el-input__inner{
    background: #F3F5F8;
    border: none;
    border-radius: 20px;
  }
}
</style>

<style lang="less" scoped>
  .disk-box-container{
    width: 100%;
    height: 100%;
    padding: 8px 8px 14px 8px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    :deep(.upload-box) {
      width: 100%;
      height: 92px;
      .el-upload{
        width: 100%;
        .el-upload-dragger{
          width: 100%;
          height: 92px;
          .drag-text{
            width: 100%;
          }
        }
      }
    }
    .search-input{
      margin: 16px 0 8px 0;
    }
    .iconsousuo2{
      height: 32px !important;
      line-height: 32px !important;
    }
    
    .file-list-box{
      flex: 1;
      width: 100%;
      overflow-y: auto;
      .file-list-item{
        width: 100%;
        .title-box{
          width: 100%;
          padding: 8px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          overflow: hidden;
          cursor: pointer;
          .title{
            font-family: Source Han Sans CN;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: right;
            .count{
              display: inline-block;
              padding-left: 4px;
            }
          }
        }
        .list-box{
          width: 100%;
          .list-item-box{
            width: 100%;
            padding: 4px 8px 4px 16px;
            box-sizing: border-box;
            overflow: hidden;
            .list-item{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              overflow: hidden;
              .name-box{
                flex: 1;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                overflow: hidden;
                
                p{
                  flex: 1;
                  font-family: Source Han Sans CN;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 0px;
                  white-space:nowrap;
                  overflow:hidden;
                  text-overflow:ellipsis;

                }

                .svg-list-icon{
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  padding-right: 8px;
                }
              }
              .oprate-btn{
                display: none;
              }
            }
            &:hover{
              background: #F4F6FA;
              border-radius: 4px;
              .list-item{
                .oprate-btn{
                  display: block;
                  .preview,.delete{
                    font-family: Source Han Sans CN;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    display: inline-block;
                  }
                  .preview{
                    color: #337EFF;
                  }
                  .delete{
                    color: #EF696F;
                    padding-left: 8px;
                  }
                }
              }
            }
          }
        }
        .pic-list-box{
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          align-content: flex-start;
          justify-content: space-between;
          padding: 0 5px;
          box-sizing: border-box;
          overflow: hidden;
          .pic-box{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 8px 3px;
            box-sizing: border-box;
            overflow: hidden;
            
            .pic{
              width: 100%;
              height: 64px;
              background: #EAF1FB;
              border-radius: 4px;
              text-align: center;
              img{
                width: 64px;
                height: auto;
              }
            }
            .oprate{
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: 10px;
              padding: 10px 4px 0 4px;
              box-sizing: border-box;
              overflow: hidden;
              .name{
                flex: 1;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                overflow: hidden;
                
                p{
                  flex: 1;
                  font-family: Source Han Sans CN;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 16px;
                  letter-spacing: 0px;
                  text-align: left;
                  white-space:nowrap;
                  overflow:hidden;
                  text-overflow:ellipsis;
                }
                .svg-icon{
                  width: 16px;
                  height: 16px;
                  display: inline-block;
                  padding-right: 6px;
                }
              }
              .iconpage-delete-16{
                display: block;
                font-size: 16px;
                color: #C1C7CD;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
</style>
