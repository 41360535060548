<template>
  <p class="drop-down-buttin-container">
    <el-dropdown placement="bottom" trigger="click">
      <span class="el-dropdown-link">
        <i class="iconfont icongengduomore"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="item in actionConfig"
          :key="item.key"
          @click.stop=""
          v-show="
            judgingArchiAPermission(item.archiLimit, item.permission) &&
            judgingUILogic(item.UILogic, subComponentData, mapData)
          "
        >
          <div
            :style="`color: ${item.color}`"
            @click="doAction(item, $event, mapData)"
          >
            {{ item.name }}
          </div>
        </el-dropdown-item>
        <el-dropdown-item
          v-if="
            judgingArchiAPermission(
              deleteLimit.archiLimit,
              deleteLimit.permission
            ) && judgingUILogic(deleteLimit.UILogic, subComponentData, mapData)
          "
          @click.stop=""
        >
          <div :style="`color: red`" @click="onRemove(mapData, $event)">
            删除
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </p>
</template>

<script>
import { mapState } from 'vuex';
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
import { dataInterface } from '@/apis/data/index';
import {
  judgingArchiAPermission,
  judgingUILogic,
  getComponentById,
  doEEActionHandle,
  getLinkByTemplate,
  generateQuery,
  getQueryValue,
} from '@/utils/tools';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonDropDown',
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
  },
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => {},
      required: true,
    },
    fullData: {
      // type: Object
    },
    isGroup: {
      type: Boolean,
    },
    groupComponents: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
    // 循环映射值
    mapData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      that: this,
    };
  },
  // TIPS loopContainerDatabase refreshApi  loopContainerMetadata 渲染容器相关数据，用于处理删除
  inject: [
    'EDITOR_pageUUID',
    'loopContainerDatabase',
    'refreshApi',
    'loopContainerMetadata',
  ],
  computed: {
    ...mapState(['componentData', 'subsidiaryComponentData']),
    // 数据仓库
    // 数据仓库
    database() {
      return this.element.database;
    },
    // 取值组件列表
    subComponentData() {
      if (this.EDITOR_pageUUID) {
        return (
          this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData ||
          this.componentList ||
          this.componentData ||
          []
        );
      }
      return this.componentList || this.componentData || [];
    },
    // 字段对象
    dataObj() {
      if (!this.database || !this.database.containerKey) return {};
      let container = getComponentById(
        this.subComponentData,
        this.database.containerKey
      );
      if (!container && this.isGroup && this.groupComponents.length) {
        container = getComponentById(
          this.groupComponents,
          this.database.containerKey
        );
      }
      if ((!container || !container.containerData) && !this.fullData) return {};
      // 列表情况默认返回一个
      if (
        Array.isArray(container.containerData) &&
        container.containerData.length
      ) {
        return container.containerData?.[0] || {};
      }
      // 列表有分页和不分页情况
      if (this.fullData) {
        if (Array.isArray(this.fullData)) {
          // 分页
          return this.fullData[0] || {};
        } else if (Array.isArray(this.fullData?.data)) {
          // 不分页
          return this.fullData?.data?.[0] || {};
        }
        return this.fullData;
      }
      const result = (container && container.containerData) || this.fullData;
      return result;
    },
    // 绑定的uuid
    bindUUID() {
      if (!this.element || !this.element.database) return '';
      return this.element.database.bindUUID;
    },
    statusConfig() {
      return this.element?.statusConfig;
    },
    actionConfig() {
      return this.element?.actionConfig;
    },
    // 整体操作前权限控制
    actionLimit() {
      return (
        this.element?.actionLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
    // 删除权限控制
    deleteLimit() {
      return (
        this.element?.deleteLimit || {
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      );
    },
  },
  methods: {
    judgingArchiAPermission,
    judgingUILogic,
    /**
     * @desc: 点击节点
     * @param {Object} data 选中的节点对象
     */
    onNodeClick(data) {
      // 如果是可选择则不再触发单几点情况
      if (this.statusConfig.showCheckBox) return;
      const rowClick = this.element.actionConfig.find(
        (ele) => ele.key === 'rowClick'
      );
      // console.log(data, '1111111')
      if (
        rowClick &&
        rowClick.eventList &&
        rowClick.eventList.length &&
        judgingArchiAPermission(
          this.actionLimit.archiLimit,
          this.actionLimit.permission
        ) &&
        judgingUILogic(this.actionLimit.UILogic, this.subComponentData) &&
        this.validDomLogic('rowClick')
      ) {
        // console.log('触发行点击事件')
        // 触发行点击事件
        // console.log('----触发行点击事件')
        const comEvents = rowClick.eventList || [];
        for (let i = 0; i < comEvents.length; i++) {
          const {
            pattern,
            eventList = [],
            specialEventList = [],
          } = comEvents[i];
          if (pattern === undefined) {
            if (comEvents[i].key === 'click') {
              comEvents[i].effects.forEach((effect) => {
                this.$store.commit('triggerEvents', {
                  config: {
                    ...comEvents[i],
                    ...effect,
                  },
                  element: this.element,
                  EDITOR_pageUUID: this.EDITOR_pageUUID,
                });
              });
            }
          } else {
            const result = pattern === 'special' ? specialEventList : eventList;
            result.forEach((ele) => {
              if (ele.key === 'click') {
                ele.effects.forEach((effect) => {
                  this.$store.commit('triggerEvents', {
                    config: {
                      ...ele,
                      ...effect,
                    },
                    element: this.element,
                    EDITOR_pageUUID: this.EDITOR_pageUUID,
                  });
                });
              }
            });
          }
        }
      }
      if (!this.propObj || !data) {
        return false;
      }
      this.currentKey = data?.[this.propObj.id];
      let resolveData = data;
      if (!data) {
        resolveData = {
          ...this.element.metadata,
        };
        this.$set(resolveData, this.propObj?.id, -1);
      }
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
      setTimeout(() => {
        // console.log('触发了-----')
        eventBus.$emit('databaseTrigger', {
          componentId: this.element.id,
          isSearch: true,
          action: 'any',
          output: resolveData,
        });
        this.element.resolveData = resolveData;
        this.$store.commit('updatePageCustomStatus', {
          origin: this.element,
          resolveData,
        });
      }, 0);
    },
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
    getBindParams(comp) {
      if (!comp) {
        window.$EditorDebug.descEEActionParam(1);
        return {};
      }
      window.$EditorDebug.descEEActionParam(2);
      const sourceConfig = comp.sourceConfig || comp.sourceList || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = '',
        } = sourceConfig[i];

        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (
              result &&
              Object.prototype.toString.call(result) === '[object Object]'
            ) {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue =
            this._PageCustomStatus[statusCode] === undefined
              ? this._APPCustomStatus[statusCode] || null
              : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(
            this.subComponentData,
            componentId
          );
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(
              this.groupComponents,
              componentId
            );
          }
          const componentName = componentId.toString().split('-')[0];
          if (
            field === 'DATAVIEWSELECT' &&
            [
              'CommonTree',
              'CommonTableContainer',
              'CommonLoopContainer',
            ].includes(componentName)
          ) {
            this.$set(sourceParams, key, sourceComponent?.resolveData || []);
          } else {
            if (
              Array.isArray(sourceComponent?.resolveData) &&
              sourceComponent?.resolveData.length
            ) {
              const result = sourceComponent?.resolveData.map(
                (item) => item?.[field] || ''
              );
              this.$set(sourceParams, key, result);
            } else {
              const result = this.element.loopData?.sourceData?.[field] || '';
              this.$set(sourceParams, key, result);
            }
          }
        }
      }
      window.$EditorDebug.successEEActionParam(sourceParams, comp.sourceConfig);
      return sourceParams;
    },
    /**
     * @description: 触发单个事件
     * @return {*}
     */
    triggerEventItem(ele, item, eventType = 'click') {
      const {
        actionType,
        effects = [],
        behaviors,
        linkType,
        queryList = [],
        linkUrl = '',
        pageId,
        linkTemplate,
        stateMachine,
      } = ele || {};
      if (actionType === 'eeAction') {
        // 触发后端事件
        window.$EditorDebug.startEEAction(item);
        const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
        if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
          this.$message.error('事件配置错误！');
          window.$EditorDebug.failEEAction(item);
          return false;
        }
        this.loading = true;
        if (this.statusConfig.globalLoading) {
          this.triggerGlobalLoading(true);
        }
        const sourceParams = this.getBindParams(item);
        // 蓝图
        if (eeType === 'blueprint') {
          dataInterface(
            sourceParams,
            interfaceUrl.includes('/api') ? interfaceUrl : `/api${interfaceUrl}`
          )
            .then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.$message.success('操作成功！');
                window.$EditorDebug.successEEAction(
                  item,
                  `/api${interfaceUrl}`
                );
                doEEActionHandle(res.data?.__adds__);
              }
              this.loading = false;
              if (this.statusConfig.globalLoading) {
                this.triggerGlobalLoading(false);
              }
            })
            .catch(() => {
              window.$EditorDebug.errorEEAction(item, `/api${interfaceUrl}`);
              this.loading = false;
              if (this.statusConfig.globalLoading) {
                this.triggerGlobalLoading(false);
              }
            });
          return true;
        }
        // 数仓
        const data_id = sourceParams?.data_id;
        dataInterface({
          __method_name__: 'customEventCall',
          object_uuid: objectUUID,
          view_uuid: viewUUID,
          ...sourceParams,
          data_id, // 参数配置
          event: eventName,
        })
          .then((res) => {
            if (res.status === 200 && res.data.code === 200) {
              this.$message.success('操作成功！');
              window.$EditorDebug.successEEAction(item, eventName);
              doEEActionHandle(res.data?.__adds__);
            }
            this.loading = false;
            if (this.statusConfig.globalLoading) {
              this.triggerGlobalLoading(false);
            }
          })
          .catch(() => {
            this.loading = false;
            if (this.statusConfig.globalLoading) {
              this.triggerGlobalLoading(false);
            }
            window.$EditorDebug.errorEEAction(item, eventName);
          });
        return true;
      } else if (actionType === 'jumpPage') {
        const query = {};
        queryList.forEach((queryItem) => {
          let component = getComponentById(
            this.componentData,
            queryItem.componentId
          );
          this.$set(
            query,
            queryItem.key,
            component?.resolveData?.[queryItem.feild]
          );
        });
        const queryStr = generateQuery(query);
        if (linkType === 'projectPage') {
          window.$EditorDebug.jumpPage(1, pageId, query);
          this.$router.push({
            path: pageId,
            query,
          });
          return;
        } else if (linkType === 'outPage') {
          if (window.EDITORUseDebug) {
            window.$EditorDebug.jumpPage(2, linkUrl);
          }
          window.open(linkUrl);
        } else if (linkType === 'custom') {
          // if (!this.mapData?.renderData) return;
          const customLink = getLinkByTemplate(
            linkTemplate,
            this.mapData?.renderData || {}
          );
          window.$EditorDebug.jumpPage(3, `${customLink}${queryStr}`);
          window.open(`${customLink}${queryStr}`);
        }
      } else if (actionType === 'export') {
        // 导出
        eventBus.$emit('exportData', {
          ...ele,
          componentId: ele.exportTarget,
          action: 'export',
          ...(ele.exportTarget.includes('CommonLoopContainer') && {
            resolveData: this.element.loopData?.sourceData,
          }),
        });
      } else if (actionType === 'pageStatus') {
        // 修改页面状态
        this.updatePageStatus(stateMachine || [], item);
      } else {
        // 页面事件
        effects.forEach((effect) => {
          this.$store.commit('triggerEvents', {
            config: {
              ...ele,
              ...effect,
            },
            element: this.element,
            EDITOR_pageUUID: this.EDITOR_pageUUID,
          });
        });
      }
      // 行为
      if (behaviors && Array.isArray(behaviors)) {
        if (behaviors.length === 1) {
          const { component, list } = behaviors[0];
          if (!component) {
            return;
          }
          if (list.length === 1) {
            const behaviorsList = list[0].behaviors;
            if (!behaviorsList.length) {
              return;
            }
          }
        }
        behaviors.forEach((behavior) => {
          if (behavior.component) {
            this.$store.commit('triggerEvents', {
              config: {
                behavior,
                isBehavior: true,
              },
              element: item,
              EDITOR_pageUUID: this.EDITOR_pageUUID,
            });
          }
        });
      }
    },
    /**
     * @desc: 触发操作
     * @param {Object} item 操作
     * @param {Object} data 当前行数据
     */
    doAction(item, e, data) {
      e.preventDefault();
      // e.stopPropagation();
      this.onNodeClick(data);
      const comEvents = item.eventList || [];
      for (let i = 0; i < comEvents.length; i++) {
        const {
          pattern = undefined,
          eventList = [],
          specialEventList = [],
        } = comEvents[i];
        if (pattern === undefined) {
          if (comEvents[i].key === 'click') {
            comEvents[i].effects.forEach((effect) => {
              this.$store.commit('triggerEvents', {
                config: {
                  ...comEvents[i],
                  ...effect,
                },
                element: this.element,
                EDITOR_pageUUID: this.EDITOR_pageUUID,
              });
            });
          }
          break;
        }
        const result = pattern === 'special' ? specialEventList : eventList;
        result.forEach((ele) => {
          if (ele.key === 'click') {
            this.triggerEventItem(ele, ele, pattern);
          }
        });
      }
      return false;
    },
    /**
     * @desc: Dom渲染逻辑
     */
    validDomLogic(key, data) {
      const btnConfig = this.actionConfig.find((ele) => ele.key === key);
      if (!btnConfig) return false;
      return (
        judgingArchiAPermission(btnConfig.archiLimit, btnConfig.permission) &&
        judgingUILogic(btnConfig.UILogic, this.subComponentData, data)
      );
    },
    /**
     * @desc: 删除
     */
    onRemove(data, e) {
      e.preventDefault();
      // e.stopPropagation();
      // 如果所需的参数都不存在，将不执行后续操作
      const objectData =
        this.loopContainerDatabase && this.loopContainerDatabase();
      if (
        !(objectData && objectData.objectData && objectData.objectData.uuid)
      ) {
        return;
      }
      const loopContainerMetadata = this.loopContainerMetadata();
      let id = '';
      if (loopContainerMetadata) {
        let idUUID = '';
        for (let key in loopContainerMetadata) {
          if (loopContainerMetadata[key] === 'id') {
            idUUID = key;
            break;
          }
        }
        if (!idUUID) return;
        id = this.mapData.renderData[idUUID];
        if (!id) return;
      }
      const refreshApi = this.refreshApi && this.refreshApi();
      if (!(refreshApi && typeof refreshApi === 'function')) {
        return;
      }
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          dataInterface({
            __method_name__: 'deleteData',
            object_uuid: objectData.objectData.uuid,
            data_id: id,
          })
            .then((res) => {
              if (res.status === 200 && res.data.code === 200) {
                this.refreshApi && refreshApi();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.drop-down-buttin-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-dropdown-link {
    padding: 2px 7px;
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      color: #409eff;
      background: #f1f1f1;
    }
  }
}
</style>
