<!--
 * @Description: 抽屉组件
 * @Author: luocheng
 * @Date: 2021-11-24 15:31:08
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:43:34
-->
<template>
	<div>
		<el-drawer
      class="common-drawer"
			:title="statusConfig.title || '操作'"
			:visible.sync="statusConfig.isShow"
			:modal="statusConfig.modal"
			:size="+statusConfig.drawerWidth || 400"
			:withHeader="statusConfig.withHeader"
			:show-close="statusConfig.showClose"
			:append-to-body="statusConfig.appendToBody"
			:close-on-press-escape="statusConfig.closeOnPressEscape"
			:wrapperClosable="statusConfig.wrapperClosable"
			:destroy-on-close="statusConfig.destroyOnClose"
			:modal-append-to-body="statusConfig.modalAppendToBody"
			:direction="statusConfig.direction"
		>
			<article
				class="drawer-main"
				v-loading="loading"
			>
				{{ statusConfig.size }}
				<div class="drawer-content">
          <!-- 外部表单 -->
          <PcParser
            v-if="
                contentConfig &&
                contentConfig.contentType === 'outForm' &&
                formDesignData
              "
            @submit="onFormSubmit"
						@on-close="onEndFlow"
            :form-data="formDesignData"
            ref="dialogForm"
            :displayData="displayData"
            :disabled="formDisabled"
						:editFields="editFields"
						:needFlow="!!needFlow"
						:moduleUuid="contentConfig.formUUID || contentConfig.objectUUID"
						:needParentFlow="!!needParentFlow"
						:formMode="requestType"
          >
          </PcParser>
          <!-- 外部页面 -->
          <!-- <template v-else-if="contentConfig && contentConfig.contentType === 'configPage'">
            {{ pageData }}
          </template> -->
          <PageParser
            v-else-if="contentConfig && contentConfig.contentType === 'configPage'"
            :pageId="contentConfig.pageId"
            :pageData="pageData"
            :pageType="pageType"
            :pageConfig="pageConfig"
          ></PageParser>
        </div>
        <!-- 操作 -->
        <div
          slot="footer"
          class="drawer-footer"
          v-if="statusConfig.showConfirm || statusConfig.showClose"
        >
          <el-button
            v-if="statusConfig.showCancel"
            :type="statusConfig.cancelType"
            @click="statusConfig.isShow = false"
          >
            {{ statusConfig.cancelText }}
          </el-button>
          <el-button
            v-if="statusConfig.showConfirm"
            :type="statusConfig.confirmType"
            @click="onConfirm(false)"
          >
            {{ statusConfig.confirmText }}
          </el-button>
          <el-button
            v-for="(btn, bIndex) in actionConfig.filter((ele) => ele.canCustom)"
            :key="bIndex"
            :type="btn.buttonType"
            @click="onButton(btn)"
          >
            {{ btn.text }}
          </el-button>
					<!-- 缓存表单数据 -->
					<el-button type="" @click="onConfirm(true)" v-if="statusConfig.useCache">保存</el-button>
        </div>
			</article>
		</el-drawer>
	</div>
</template>

<script>
import { transFieldsUUid, dataInterface } from '@/apis/data/index';
import PcParser from '@/custom-component/form/newParser/PcParser';
import PageParser from './parser/Index';
import { getComponentById, getQueryValue } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import { isJSONStr } from '@/utils/tools';

export default {
	name: 'CommonDrawer',
  components: {
		PcParser,
		PageParser
	},
	props: {
		element: {
			type: Object,
			default: () => {},
			required: true
		},
    componentList: {
			default: null
    }
	},
	data() {
		return {
			// 自定义表单相关
			objectUUID: null,
			// 表单配置数据
			formDesignData: null,
			displayData: {},
			// 流程中展示字段
      editFields:{},
			// 表单是否disable
			formDisabled: false,
			// 页面数据
			pageData: [],
			// 页面类型
			pageType: '',
			// 页面样式配置
			pageConfig: {
				height: 600,
				width: 500
			},
			loading: false,
			// 流程
			needFlow: false,
			needParentFlow: false,
			// 新增数据缓存
			isCacheFormData: false
		};
	},
	computed: {
		...mapState(['componentData']),
		// 特殊样式
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 请求类型
		requestType() {
			const types = {
				createData: 'add',
				dataInfo: 'detail'
			};
			return types[this.element?.sourceConfig?.[0].type || ''];
		},
		// 内容配置
		contentConfig() {
			return this.element && this.element.contentConfig;
		},
		// 操作配置
		actionConfig() {
			return this.element && this.element.actionConfig;
		}
	},
	watch: {
		contentConfig: {
			handler() {
				if (!this.contentConfig) return;
				if (this.contentConfig.contentType === 'outForm') {
					// 来至自定义表单@曾伟
					this.objectUUID = null;
					this.initFormDesign(this.contentConfig);
				} else if (this.contentConfig.contentType === 'configPage') {
					// 来自外部页面
					this.initPageDesign();
					// 获取部分参数配置
					const params = this.getParams();
					sessionStorage.setItem(
						`dialogRelationParams_${this.contentConfig.pageId}`,
						JSON.stringify(params)
					);
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		/**
		 * @desc: 流程处理结束回调处理
		 */
		onEndFlow() {
			this.beforeClose();
			this.statusConfig.isShow = false;
		},
		/**
		 * @desc: 确认按钮
		 */
		onConfirm(isCacheFormData = false) {
			this.isCacheFormData = isCacheFormData;
			if (this.contentConfig && this.contentConfig.contentType === 'outForm') {
				if (this.$refs && this.$refs.dialogForm) {
					if (isCacheFormData) {
						this.doCacheFormData(this.$refs.dialogForm.getFormData());
					} else {
						this.$refs.dialogForm.handleSubmit();
					}
				}
			}
		},
		/**
		 * @desc: 缓存表单数据
		 * @param {Object} formData
		 * @return {*}
		 */
		doCacheFormData(value) {
			if (!value) {
				this.$message.warning('暂无可保存数据!');
				return false;
			}
			const loading = this.$loading();
			dataInterface({
				__method_name__: 'createData',
				object_uuid: 'object61bbffbdabbbe',
				user_id: this.$GetUserInfo('id'),
				key: `formcache-${this.contentConfig.formUUID || this.contentConfig.objectUUID}`,
				value
			}).then(res => {
				if (res.status === 200) {
					this.$message.success('操作成功！');
				}
				loading && loading.close();
			}).catch(err => {
				console.log(err);
				loading && loading.close();
			})
		},
		/**
		 * @desc: 初始化表单
		 */
		initFormDesign(contentConfig) {
			if (!contentConfig) return;
			this.loading = true;
			transFieldsUUid(contentConfig.objectUUID, contentConfig.formUUID)
				.then((res) => {
					if (res.data.code == 200) {
						const { sourceConfig } = this.element;
						this.formDisabled = sourceConfig[0].type === 'dataInfo';
						this.needFlow = !!res.data.data.need_flow;
						this.needParentFlow = !!res.data.data.need_parent_flow;
						// 编辑或详情
						if (
							sourceConfig[0].type === 'updateData' ||
							sourceConfig[0].type === 'dataInfo'
						) {
							// 编辑
							// 获取已经有的数据进行绑定
							let config = res.data.data;
							let ruuids = [];
							for (let i = 0; i < config.fields.length; i++) {
								let item = config.fields[i];
								if (item.__config__.tagIcon == 'form') {
									ruuids.push({ relationship_uuid: item.__vModel__ });
								}
							}
							const params = this.getParams();
							dataInterface({
								...params,
								object_uuid: contentConfig.objectUUID,
								view_uuid:
									sourceConfig[0].type === 'dataInfo'
										? this.contentConfig.viewUUID
										: '',
								ruuids,
								__method_name__: 'dataInfo'
							}).then((res1) => {
								let data = res1.data.data;
								this.formDesignData = res.data.data;
								this.displayData = data;
                this.editFields = data.editFields || [];
								this.loading = false;
							});
						} else {
							if (this.statusConfig.useCache && sourceConfig[0].type === 'createData' && res?.data?.data?.cache_data) {
								// 新增
								this.$confirm('是否使用缓存数据填充？', '提示').then(() => {
									try {
										this.formDesignData = res.data.data;
										this.loading = false;
										this.displayData = JSON.parse(res?.data?.data?.cache_data) || {};
									} catch(cacheErr) {
										console.log(cacheErr)
									}
								}).catch(() => {
									this.formDesignData = res.data.data;
									this.loading = false;
								});
							} else {
								this.formDesignData = res.data.data;
								this.loading = false;
							}
						}
					}
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 表单提交
		 * @param {Object} formData 自定义表单中提交的数据
		 */
		onFormSubmit(formData) {
			if (this.isCacheFormData) {
				console.log('缓存数据!');
				this.doCacheFormData(formData);
				return false;
			}
			const { actionConfig } = this.element;
			const btnConfig = actionConfig.find((ele) => ele.key === 'confirm');
			if (!btnConfig || !btnConfig.effect) {
				this.statusConfig.isShow = false;
				return;
			}
			// 附加作用一般是发送请求
			if (btnConfig.effectType === 'postData') {
				// let data_id = '';
				let params = {};
				// 发送请求 整理参数
				const sourceParams = this.getParams();
				// 老的逻辑
				// if (btnConfig.postType === 'updateData') {
				// 	// 编辑时候必须设置data_id
				// 	if (!sourceConfig.componentId || !sourceConfig.field) return;
				// 	const sourceComponent = getComponentById(
				// 		this.componentData,
				// 		sourceConfig.componentId
				// 	);
				// 	if (!sourceComponent || !sourceComponent.resolveData) return;
				// 	data_id = sourceComponent.resolveData[sourceConfig.field];
				// 	if (!data_id) return;
				// }
				params = {
					__method_name__: btnConfig.postType,
					object_uuid: btnConfig.objectUUID,
					...sourceParams,
					// 表单的字段
					...formData
				};
				this.$loading();
				// 发送请求
				dataInterface(params)
					.then((res) => {
						console.log(res);
						this.$loading().close();
						// this.statusConfig.isShow = false;
						if (res.code || (res.status && res.status === 200)) {
							this.beforeClose();
						}
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			}
		},
		/**
		 * @desc: 关闭前需要将组件的暴露数据清空
		 */
		beforeClose() {
			// const sourceComponent = getComponentById(
			// 	this.componentData,
			// 	this.element.sourceConfig.componentId
			// );
			// this.$store.commit('modifyComponent', {
			// 	component: {
			// 		...sourceComponent,
			// 		resolveData: null
			// 	},
			// 	containerId: null,
			// 	isModify: true
			// });
			this.statusConfig.isShow = false;
			// 如果是对表格中的数据进行编辑需要对数据进行更新
			// 对于参照相关参数的需要进行获取
			const sourceConfig = this.element.sourceConfig || [];
			for (let i = 0; i < sourceConfig.length; i++) {
				if (sourceConfig[i] && !sourceConfig[i].originType  && sourceConfig[i].componentId) {
					eventBus.$emit('databaseTrigger', {
						action: 'dataList',
						componentId: sourceConfig[i].componentId,
						isInit: sourceConfig[i].componentId.indexOf('CommonTree-') > -1,
						isUpdate: true,
						output: ''
					});
				}
			}
		},
		/**
		 * @desc: 点击按钮
		 */
		onButton(btnConfig) {
			console.log(btnConfig, '点击按钮');
			if (btnConfig && btnConfig.effectType === 'eeAction') {
				const { objectUUID, viewUUID, eventName } = btnConfig;
				// console.log( objectUUID, viewUUID, eventName)
				if (!objectUUID || !viewUUID || !eventName) {
					this.$message.error('请正确配置事件!');
					return false;
				}
				const data_id = this.getParams()?.data_id;
				// console.log(data_id, '8888888');
				if (!data_id) {
					this.$message.error('事件未配置触发对象!');
					return false;
				}
				this.loading = true;
				// 触发后端事件
				dataInterface({
					__method_name__: 'customEventCall',
					object_uuid: btnConfig.objectUUID,
					view_uuid: btnConfig.viewUUID,
					data_id,
					event: btnConfig.eventName
				})
					.then((res) => {
						if (res.status ===200) {
							this.$message.success('操作成功！');
						}
						this.loading = false;
					})
					.catch((err) => {
						console.log(err, '00000后端事件错误');
						this.loading = false;
					});
			}
		},
		/**
		 * @desc: 获取绑定参数
		 */
		getParams() {
			const sourceConfig = this.element.sourceConfig || [];
			const sourceParams = {};
			for (let i = 0; i < sourceConfig.length; i++) {
				const { componentId, field, key, originType = '', urlParamKey = '' } = sourceConfig[i];
				if (originType === 'url' && urlParamKey) {
					// 从url获取参数
						const result =	getQueryValue(urlParamKey)
					this.$set(sourceParams, key, result);
				} else if (componentId && field && key) {
					// 从组件获取参数
					const sourceComponent = getComponentById(
						this.componentData,
						componentId
					);
					const result = sourceComponent?.resolveData?.[field];
					this.$set(sourceParams, key, result);
				}
			}
			return sourceParams;
		},
		/**
		 * @desc: 页面
		 */
		initPageDesign() {
			this.loading = true;
			dataInterface({
				__method_name__: 'dataInfo',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
				data_id: this.contentConfig.pageId
			})
				.then((res) => {
					this.loading = false;
					if (
						res.status === 200 &&
						res.data &&
						res.data.code === 200 &&
						res.data.data
					) {
						const pageData = res.data.data['f708a9c6-2514-47ad-9056-3c3a1c37496f']
						let { componentData, editorConfig, editorType } = isJSONStr(pageData) ? JSON.parse(pageData) : pageData;
						// console.log(
						// 	JSON.parse(res.data.data['f708a9c6-2514-47ad-9056-3c3a1c37496f']),
						// 	'--------'
						// );
						// this.$store.commit('setConfigData', { componentData, editorConfig, editorType });
						this.pageData = componentData;
						this.pageType = editorType;
						this.pageConfig = editorConfig;
						this.inited = true;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		}
	},
	mounted() {
		// window.addEventListener = (e) => {
		// 	console.log(e, '333333333')
		// }
	},
	beforeDestroy() {
		sessionStorage.removeItem(
			`dialogRelationParams_${this.contentConfig.pageId}`
		);
	}
};
</script>

<style lang="less" scoped>
:deep(.common-drawer)  {
	z-index: 999999;
  .el-drawer__header{
    margin-bottom: 0;
    border-bottom: 1px solid #f2f3f5;
    padding-bottom: 20px;
  }
  .el-drawer__body{
    box-sizing: border-box;
    padding: 15px 16px;
  }
  .type-label {
		border-left: 3px solid var(--themeColor) !important;
		padding: 0 10px;
		line-height: 1;
		font-size: 14px;
		margin: 0 0 16px;
		font-weight: 600;
    text-align: left;
	}
	.drawer-main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .drawer-content{
      flex: 1;
      width: 100%;
      overflow: auto;
    }
	}
}
</style>
