<!--
 * @Author: ttheitao
 * @Date: 2022-01-20 15:54:47
 * @LastEditors: ttheitao
 * @LastEditTime: 2024-07-17 14:14:41
 * @Description: excel组件
-->
<template>
    <div style="width:100%;height: 100%;">
        <div ref="bimccSpreadsheet">
            <div :style="sheetWrapStyle">
                <fc-render :config="fcConfig" :fc-value="fcValue" tabindex="0" ref="fcRender" :dataStore="dataStore"
                    @change="fcValueChange"></fc-render>
            </div>
        </div>
        <Menu v-show="showMenu" :style="menuStyle" tabindex="1" ref="menu" :menus="menus" :isFirst="true"
            @stopBlur="setStopBlur(true)" @menuFocus="menuFocus">
        </Menu>

        <el-dialog v-if="isConfigPanel" :title="panelTitle" width="500px" :visible.sync="isConfigPanel"
            :append-to-body="true">
            <component :is="'Panel' + currentComp" ref="dataVerfityPanel" :config="currentConfig" />
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closePanel()">取 消</el-button>
                <el-button type="primary" size="small" @click="saveDataType()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import { Spreadsheet, Utils } from 'bimcc-spreadsheet';
import Menu from './components/Menu.vue';
import FcRender from './components/FcRender.vue';
import Type from './DataVerifyPanel/js/Type';
import { dataInterface } from '@/apis/data/index';
import { deepCopy } from '../../utils/tools';
import { formatDate } from 'element-ui/src/utils/date-util';
import { getUserList } from '../../custom-component/form/formParser/selectUser/js';
import { getValue } from "@/apis/data/workflow";
// import { CascaderPanel } from 'element-ui';

export default {
    components: {
        Menu,
        FcRender,
    },
    props: {
        workbookData: {
            type: Object,
            default: () => { }
        },
        workbookState: {
            type: Object,
            default: () => { }
        },
        // 是否在设计模板
        isTemplate: {
            type: Boolean,
            default: false
        },
        disabledContextMenu: {
            type: Boolean,
            default: false
        },
        groupIdSuffix: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            // 是否展示菜单
            showMenu: false,
            // 菜单样式
            menuStyle: {
                position: 'absolute',
                zIndex: '9999',
                left: 0,
                top: 0,
                outline: 'none',
                backgroundColor: '#fff',
                userSelect: 'none',
            },
            currentComp: "", // 当前面板组件
            isConfigPanel: false, // 是否打开配置面板
            ssInstance: null, // 表格实例
            panelTitle: "", // 配置面板标题
            currentConfig: {}, // 当前面板配置
            fcConfig: { config: { type: null } }, // 绑定组件配置
            showFcRender: false, // 是否显示绑定组件
            fcValue: "", // 绑定组件值
            currentCell: null, // 当前单元格
            worksheet: null, // 当期工作表
            dataStore: {}, // 数据存储器，用以保存一些临时数据
            sheetWrapStyle: {
                position: 'absolute',
                left: 0,
                top: 0,
                width: 0,
                height: 0,
                overflow: 'hidden',
                // pointerEvents: 'none',
                // zIndex: 5000,
            },
            fcStartX: 0,
            fcStartY: 0,
            stopBlur: false,
            isPasting: false, // 当前是否在粘贴状态
            archiUsers: [], // 当前组织架构下的所有人员
            dwhUsers: [] // 当前数仓操作者
        };
    },
    computed: {
        menus() {
            // 自定义bimcc-spreadsheet右键菜单

            let hasBindRange = false;
            if (!this.isTemplate && this.ssInstance) {
                const graphService = this.ssInstance.getProvider('GraphServiceProvider');
                const nodes = graphService.graphViewer.graph.getNodes();
                const one = nodes.find((item) => { return item.type === 'bind-range' });
                hasBindRange = one ? true : false;
            }
            const menus = [
                {
                    name: '表单组件',
                    // icon: "iconfont iconyou",
                    children: [
                        {
                            name: '图片',
                            action: () => {
                                this.panelTitle = '图片';
                                this.setcurrentComp('Image');
                                this.showConfigPanel();
                            },
                            icon: "iconfont icontupian1",
                            show: true,
                        },
                        {
                            name: '评分',
                            action: () => {
                                this.panelTitle = '评分';
                                this.setcurrentComp('Rate');
                                this.showConfigPanel();
                            },
                            icon: "el-icon-star-off",
                            show: true,
                        },
                        // {
                        //     name: '开关',
                        //     action() { },
                        //     icon: "iconfont iconswitch_off",
                        // },
                        {
                            name: '复选框',
                            action: () => {
                                this.panelTitle = '复选框';
                                this.setcurrentComp('Checkbox');
                                this.showConfigPanel();
                            },
                            icon: "iconfont iconfuxuankuang1",
                            show: true,
                        },
                        {
                            name: '下拉选择',
                            action: () => {
                                this.panelTitle = '下拉选择';
                                this.setcurrentComp('Select');
                                this.showConfigPanel();
                            },
                            icon: "iconfont iconxialaxuanze",
                            show: true,
                        },
                        {
                            name: '日期选择器',
                            action: () => {
                                this.panelTitle = '日期选择器';
                                this.setcurrentComp('DatePicker');
                                this.showConfigPanel();
                            },
                            icon: "el-icon-date",
                            show: true,
                        },
                        {
                            name: '时间选择器',
                            action: () => {
                                this.panelTitle = '时间选择器';
                                this.setcurrentComp('TimePicker');
                                this.showConfigPanel();
                            },
                            icon: "el-icon-date",
                            show: true,
                        },
                        // {
                        //     name: '级联选择器',
                        //     action: () => {
                        //         this.panelTitle = '级联选择器';
                        //         this.setcurrentComp('CascaderPicker');
                        //         this.showConfigPanel();
                        //     },
                        //     icon: "iconfont iconxuanze-zuzhijiagou",
                        // },
                        {
                            name: '用户',
                            action: () => {
                                this.panelTitle = '用户';
                                this.setcurrentComp('User');
                                this.showConfigPanel();
                            },
                            icon: "iconfont iconyonghuzhanghu",
                            show: true,
                        },
                        // {
                        //     name: '组织架构',
                        //     action: () => {
                        //         this.panelTitle = '组织架构';
                        //         this.setcurrentComp('Archi');
                        //         this.showConfigPanel();
                        //     },
                        //     icon: "iconfont iconzuzhijiagou",
                        // },
                        {
                            name: '删除',
                            action: () => {
                                this.clearFcConfig();
                                this.clearDataType();
                            },
                            icon: "iconfont iconshanchu4",
                            show: true,
                        },
                    ],
                    show: this.isTemplate,
                },
                {
                    name: '插入行',
                    action: (options) => {
                        let rowNumber = options?.rowNumber ? parseInt(options?.rowNumber) : 1;
                        const selection = this.ssInstance.getActiveWorksheet().selection;
                        this.ssInstance.insertRows(selection.currentCell.row, rowNumber);
                        this.handleMenuBlur();
                    },
                    options: {
                        rowNumber: 1
                    },
                    show: !hasBindRange || this.isTemplate ? true : false,
                },
                {
                    name: '删除所在行',
                    action: () => {
                        const selection = this.ssInstance.getActiveWorksheet().selection;
                        const rows = [];
                        selection.activeRangeList.forEach((range) => {
                            for (let i = range.startRow; i <= range.endRow; i++) {
                                if (!rows.includes(i)) {
                                    rows.push(i);
                                }
                            }
                        });
                        this.ssInstance.delRows(rows);
                        this.handleMenuBlur();
                    },
                    show: !hasBindRange || this.isTemplate ? true : false,
                },
                {
                    name: '隐藏所在行',
                    action: () => {
                        const selection = this.ssInstance.getActiveWorksheet().selection;
                        const rows = [];
                        selection.activeRangeList.forEach((range) => {
                            for (let i = range.startRow; i <= range.endRow; i++) {
                                if (!rows.includes(i)) {
                                    rows.push(i);
                                }
                            }
                        });
                        this.ssInstance.hideRows(rows);
                        this.handleMenuBlur();
                    },
                    options: {
                        rowNumber: 1
                    },
                    show: !hasBindRange || this.isTemplate ? true : false,
                },
                {
                    name: '插入列',
                    action: (options) => {
                        let columnNumber = options?.columnNumber ? parseInt(options?.columnNumber) : 1;
                        const selection = this.ssInstance.getActiveWorksheet().selection;
                        this.ssInstance.insertColumns(selection.currentCell.column, columnNumber);
                        this.handleMenuBlur();
                    },
                    options: {
                        columnNumber: 1
                    },
                    show: !hasBindRange || this.isTemplate ? true : false,
                },
                {
                    name: '删除所在列',
                    action: () => {
                        const selection = this.ssInstance.getActiveWorksheet().selection;
                        const columns = [];
                        selection.activeRangeList.forEach((range) => {
                            for (let i = range.startColumn; i <= range.endColumn; i++) {
                                if (!columns.includes(i)) {
                                    columns.push(i);
                                }
                            }
                        });
                        this.ssInstance.delColumns(columns);
                        this.handleMenuBlur();
                    },
                    show: !hasBindRange || this.isTemplate ? true : false,
                },
                {
                    name: '隐藏所在列',
                    action: () => {
                        const selection = this.ssInstance.getActiveWorksheet().selection;
                        const columns = [];
                        selection.activeRangeList.forEach((range) => {
                            for (let i = range.startColumn; i <= range.endColumn; i++) {
                                if (!columns.includes(i)) {
                                    columns.push(i);
                                }
                            }
                        });
                        this.ssInstance.hideColumns(columns);
                        this.handleMenuBlur();
                    },
                    options: {
                        rowNumber: 1
                    },
                    show: !hasBindRange || this.isTemplate ? true : false,
                },
                {
                    name: '导入Excel',
                    action: () => {
                        this.ssInstance.importExcel();
                        this.handleMenuBlur();
                    },
                    show: this.isTemplate,
                },
            ];

            if (!this.isTemplate && this.ssInstance) {
                const worksheet = this.ssInstance.getActiveWorksheet();
                const { selection } = worksheet;
                const graphService = this.ssInstance.getProvider('GraphServiceProvider');
                const nodes = graphService.graphViewer.graph.getNodes();
                nodes.forEach((node) => {
                    if (node.type === 'bind-range') {
                        const excelState = node.excelState;
                        const nodeRange = excelState?.loopInfo?.nodeRange;
                        let inRange = false;
                        if ([1, 2].includes(excelState?.direction)
                            && nodeRange.startRow <= selection.currentCell.row
                            && nodeRange.endRow >= selection.currentCell.row) {
                            inRange = true;
                        } else if ([3, 4].includes(excelState?.direction)
                            && nodeRange.startColumn <= selection.currentCell.column
                            && nodeRange.endColumn >= selection.currentCell.column) {
                            inRange = true;
                        }
                        if (excelState.loopType === 2 && inRange) {
                            const title = node.getProperty('title') ?? node.label;
                            menus.push({
                                name: `插入 [${title}] 项`,
                                icon: 'iconfont iconxinzeng4',
                                action: () => {
                                    node.insertLoopItem([{}]);
                                    this.handleMenuBlur();
                                },
                                show: true,
                            });
                            menus.push({
                                name: `删除 [${title}] 项`,
                                icon: 'iconfont iconshanchu-1',
                                action: () => {
                                    const index = [1, 2].includes(excelState.direction)
                                        ? selection.currentCell.row
                                        : selection.currentCell.column;
                                    node.delLoopItem(index);
                                    this.handleMenuBlur();
                                },
                                show: true,
                            });
                        }
                    }
                });
            }

            return menus;
        }
    },
    created() {
        if (!this.isTemplate) {
            // this.menus = [];
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        });
    },
    methods: {
        async init() {
            await this.requestQueue();
            await this.getArchiUsers();
            await this.getDwhUsers();

            const users = {};
            this.dwhUsers.forEach((item) => {
                users[item.id] = {
                    id: item.id,
                    name: item.name,
                };
            });
            this.archiUsers.forEach((item) => {
                users[item.id] = {
                    id: item.id,
                    name: item.name,
                };
            });

            const copyWorkbookData = deepCopy(this.workbookData);
            const copyWorkbookState = deepCopy(this.workbookState);
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));

            // console.log(userInfo,users);
            copyWorkbookData.isPasteStyle = this.isTemplate ? 1 : 0;
            copyWorkbookState.teamkworkConfig = {
                // server: 'ws://localhost:20100/teamwork',
                server: 'ws://124.222.232.214:20100/teamwork',
                enable: false,
                groupId: `page-editor-${copyWorkbookData.id}-${this.groupIdSuffix ?? ''}`,
                userId: String(userInfo.id),
                userColor: Utils.getRandomDarkColor(),
                users: users,
                focusList: {},
            };
            console.log('teamkworkConfig', copyWorkbookState.teamkworkConfig);

            const ssInstance = Spreadsheet.newInstance(this.$refs['bimccSpreadsheet'], copyWorkbookData, copyWorkbookState);
            this.resizeSheetWrap();
            let exported = false;

            ssInstance.on('teamworkSave', (context, params, event) => {
                context, params, event;
                this.isTemplate && this.$parent.upload(false);
            });

            ssInstance.on('showMenuBefore', (context, params, event) => {
                params;
                if (this.disabledContextMenu) {
                    return false;
                }

                const worksheet = context.getActiveWorksheet();
                const { selection } = worksheet;
                const config = worksheet.getCellFormControl(
                    selection.currentCell.row,
                    selection.currentCell.column
                );
                this.currentConfig = config ?? {};

                if (this.menus.length) {
                    this.menuStyle.left = '-10000px';
                    this.showMenu = true;
                    setTimeout(() => {
                        const bodyRect = document.body.getBoundingClientRect();
                        const menuRect = this.$refs.menu.$el.getBoundingClientRect();

                        let left = event.params.x;
                        let top = event.params.y;
                        if (event.params.x + menuRect.width > bodyRect.width) {
                            left -= event.params.x + menuRect.width - bodyRect.width + 10;
                        }

                        if (event.params.y + menuRect.height > bodyRect.height) {
                            top -= event.params.y + menuRect.height - bodyRect.height;
                        }

                        if (menuRect.height > bodyRect.height) {
                            this.menuStyle.top = `0px`;
                            this.menuStyle.height = `100%`;
                            this.menuStyle.overflow = 'auto';
                        } else {
                            if (this.menuStyle.height) {
                                delete this.menuStyle.height;
                            }
                        }

                        this.menuStyle.left = `${left}px`;
                        this.menuStyle.top = `${top}px`;
                        this.$refs['menu'].$el.focus();
                    }, 0);
                }

                return false;
            });

            ssInstance.on('cellClickBefore', (context, params, event) => {
                context, params, event;
                this.clearFcConfig();

                this.currentCell = event.params.cell;
                const cellStyle = this.worksheet.getCellStyle(this.currentCell.row, this.currentCell.column);
                if (cellStyle?.lo || this.currentCell?.__lo__) {
                    return false;
                }

                const fc = this.worksheet.getCellFormControl(this.currentCell.row, this.currentCell.column);
                const { rowTitle, columnTitle } = this.worksheet.getConfig();
                const rowTitleWidth = rowTitle.hidden ? 0 : rowTitle.width;
                const columnTitleHeight = columnTitle.hidden ? 0 : columnTitle.height;
                const translate = this.worksheet.getTranslate();
                if (fc && fc.type > 50) {
                    this.fcValue = this.currentCell.v;
                    this.fcConfig = {
                        x: this.currentCell.x - rowTitleWidth + translate.x,
                        y: this.currentCell.y - columnTitleHeight + translate.y,
                        width: this.currentCell.width,
                        height: this.currentCell.height,
                        config: fc,
                    };
                    this.fcStartX = this.fcConfig.x;
                    this.fcStartY = this.fcConfig.y;
                    this.updateFcRenderPosition();
                }
            });

            ssInstance.on('scrollXAfter', (context, params, event) => {
                context, params, event;
                this.resizeSheetWrap();
                this.updateFcRenderPosition();
            });

            ssInstance.on('scrollYAfter', (context, params, event) => {
                context, params, event;
                this.resizeSheetWrap();
                this.updateFcRenderPosition();
            });

            ssInstance.on('pasteBefore', (context, params, event) => {
                context, params, event;
                this.isPasting = true;
                if (this.fcConfig.config.type) {
                    this.clearFcConfig();
                }
            });

            ssInstance.on('pasteAfter', (context, params, event) => {
                context, params, event;
                this.isPasting = false;
            });

            ssInstance.on('setCellValueBefore', (context, params, event) => {
                context, params, event;
                const worksheet = event.worksheet;
                const row = event.params.row;
                const column = event.params.column;
                const fc = worksheet.getCellFormControl(row, column);
                // console.log('setCellValueBefore', event, fc, '111');
                if (this.isPasting && event?.value?.__id__) {
                    delete event.value.__id__;
                }
                if (fc && fc.type >= 50) {
                    let value = event.value.v;
                    let res = {
                        v: deepCopy(value)
                    };
                    let label = "";

                    // for user
                    let users = [];
                    let names = [];

                    // for select
                    let labelKey = '';
                    let valueKey = '';
                    let selectValue = [];

                    // for user
                    let userValue = [];

                    switch (fc.type) {
                        case Type.SELECT:
                            labelKey = fc.source === 'dwh' ? fc.meta.label_field : 'label';
                            valueKey = fc.source === 'dwh' ? fc.meta.value_field : 'value';
                            if (fc.multiple) {
                                selectValue = Array.isArray(value) ? value : [];
                                const newValue = [];
                                fc.options.forEach(option => {
                                    if (this.isPasting) {
                                        if (option[labelKey] === value) {
                                            label += `,${option[labelKey]}`;
                                            newValue.push(option[valueKey]);
                                        }
                                        res.v = newValue;
                                    } else {
                                        if (selectValue.includes(option[valueKey])) {
                                            label += `,${option[labelKey]}`;
                                        }
                                    }
                                });

                                label = label.replace(',', '');
                            } else {

                                const one = fc.options.find((option) => {
                                    return this.isPasting ? option[labelKey] === value : option[valueKey] === value;
                                });

                                if (this.isPasting) {
                                    res.v = one?.[valueKey];
                                }

                                label = one?.[labelKey] ?? '';
                            }
                            res.m = label;
                            break;
                        case Type.DATE_PICKER:
                            res.m = formatDate(res.v, fc.format);
                            if (res.v == '0000-00-00 00:00:00') {
                                res.v = '';
                            }
                            break;
                        case Type.TIME_PICKER:
                            res.m = value;
                            break;
                        case Type.USER:
                            if (this.isPasting) {
                                const one = this.archiUsers.find(element => {
                                    return element.name === value;
                                });

                                if (one) {
                                    userValue.push({
                                        user_id: one.user_id,
                                        section_id: one.section_id,
                                        name: one.name,
                                    });
                                }
                            } else {
                                userValue = Array.isArray(value) ? value : [];
                            }

                            userValue.forEach(element => {
                                users.push({ user_id: element.user_id, section_id: element.section_id, name: element.name });
                                names.push(element.name);
                            });
                            res.v = users;
                            res.m = names.join(',');
                            break;
                    }

                    event.value.v = res.v;
                    event.value.m = res.m ?? '';
                } else if (!fc) {
                    if (event?.value?.m) {
                        event.value.v = event.value.m;
                        delete event.value.m;
                    }
                }
            });

            // ssInstance.on('refreshBefore', (context, params, event) => {
            //     context, params, event;
            // });

            ssInstance.on('refreshAfter', (context, params, event) => {
                context, params, event;
                // console.log('refreshAfter');
                if (!exported && context.loadingEl.size <= 0) {
                    exported = true;
                    this.$emit('export-prepared');
                }
            });

            ssInstance.on('workbookCreated', (context, params, event) => {
                params, event;
                this.$emit('workbook-created', context);
            });

            ssInstance.on('cellRenderBefore', (context, params, event) => {
                this.$emit('cell-render-before', {
                    context,
                    params,
                    event,
                });
            });

            this.$refs['menu'].$el.addEventListener('blur', () => {
                if (!this.stopBlur) {
                    this.showMenu = false;
                }
            });

            this.$refs['menu'].$el.addEventListener('contextmenu', (e) => {
                e.preventDefault();
                e.stopPropagation();
            });

            this.$refs.fcRender.$el.addEventListener('wheel', (e) => {
                this.fcRenderBlur();
                this.ssInstance.scroll.addScrollTop(e.deltaY);
            })

            this.ssInstance = ssInstance;
            window.ssInstance = ssInstance;
            this.ssInstance.start();
            this.worksheet = this.ssInstance.getActiveWorksheet();

            this.$emit('spreadsheet-mounted');
        },
        async requestQueue() {
            const requestQueue = [];
            let storeKey = '';
            for (let i in this.workbookData.formControls) {
                const fc = this.workbookData.formControls[i];
                switch (fc.type) {
                    case Type.SELECT:
                        storeKey = `${fc.meta.object_uuid}_${fc.meta.view_uuid}`;
                        if (fc.source === 'dwh' && this.dataStore[storeKey] === undefined) {
                            let params = {
                                object_uuid: fc.meta.object_uuid,
                                view_uuid: fc.meta.view_uuid,
                                __method_name__: 'dataList',
                            }

                            this.dataStore[storeKey] = {
                                options: [],
                                isRender: false, // 数据是否已渲染到单元格
                            };
                            requestQueue.push(dataInterface(params));
                        }
                        break;
                }
            }

            if (requestQueue.length) {
                await Promise.all(requestQueue).then((resList) => {
                    resList.forEach((res) => {
                        if (res.data.code === 200) {
                            const data = Array.isArray(res.data.data) ? res.data.data : res.data.data.data;

                            data.forEach(element => {
                                this.dataStore[storeKey].options.push(element);
                            });
                        }
                    });
                });
            }
        },
        handleMenuBlur() {
            this.showMenu = false;
        },
        setcurrentComp(comp) {
            this.currentComp = comp;
        },
        showConfigPanel() {
            this.showMenu = false;
            this.isConfigPanel = true;
        },
        closePanel() {
            this.isConfigPanel = false;
        },
        saveDataType() {
            // TODO 设置到表格实例中?
            const dataVerifyService = this.ssInstance.getProvider('DataVerifyServiceProvider');
            const worksheet = this.ssInstance.getActiveWorksheet();
            const config = this.$refs['dataVerfityPanel'].getConfig();
            dataVerifyService.setRangeConfig(config, worksheet);
            this.closePanel();
        },
        clearDataType() {
            this.showMenu = false;
            const dataVerifyService = this.ssInstance.getProvider('DataVerifyServiceProvider');
            const worksheet = this.ssInstance.getActiveWorksheet();
            dataVerifyService.clearRangeConfig(worksheet);
        },
        fcRenderBlur() {
            this.showFcRender = false;
        },
        clearFcConfig() {
            this.fcConfig = { config: { type: null } };
        },
        fcValueChange(value) {
            this.ssInstance.setCellValue(this.currentCell.row, this.currentCell.column, value, this.worksheet);
        },
        resizeSheetWrap() {
            this.$nextTick(() => {
                const sheetWrap = this.ssInstance.sheetWrap;
                this.sheetWrapStyle.width = `${sheetWrap.DOM.clientWidth}px`;
                this.sheetWrapStyle.height = `${sheetWrap.DOM.clientHeight}px`;
                this.sheetWrapStyle.left = `${sheetWrap.DOM.offsetLeft}px`;
                this.sheetWrapStyle.top = `${sheetWrap.DOM.offsetTop}px`;
            });
        },
        updateFcRenderPosition() {
            if (!this.fcConfig) return;
            const translate = this.worksheet.getTranslate();
            this.fcConfig.x = this.fcStartX - translate.x;
            this.fcConfig.y = this.fcStartY - translate.y;
        },
        serialize() {
            return this.ssInstance.serialize();
        },
        resize() {
            if (this.ssInstance) {
                setTimeout(() => {
                    this.ssInstance.resize();
                }, 0);
            }
        },
        getFormData() {
            return this.ssInstance.getFormData();
        },
        /**
         * @description: 设置动态配置的单元格显示值，此方法在单元格动态选项为异步时再完善，目前所有请求在表格加载时已完成
         * @return {*}
         */
        setDynamicCellValue() {
            // this.nodeGraph.getChildrenNodes();
            // this.dataStore.forEach((item) => {
            //     if (item.isRender) return;
            //     item.isRender = true;

            //     nodeGraph.getChildrenNodes();
            // });
        },
        exportPDF() {
            this.ssInstance.exportPDF();
        },
        exportExcel() {
            this.ssInstance.exportExcel();
        },
        setStopBlur(status) {
            this.stopBlur = status;
            setTimeout(() => {
                this.stopBlur = false;
            }, 0);
        },
        menuFocus() {
            this.$refs['menu'].$el.focus();
        },
        reload() {
            this.ssInstance.reload(deepCopy(this.workbookData), deepCopy(this.workbookState));
        },
        refresh() {
            this.ssInstance.refresh();
        },
        async getArchiUsers() {
            const archiInfo = this.$GetTargetArchi();
            const archiId = archiInfo.archiId
                ? archiInfo.archiId
                : archiInfo.id.split("-")[1]; // 当前登录的架构id

            const obj = {
                object_uuid: "object616fe6fe8119f",
                view_uuid: "view6177ce7668c82",
                __method_name__: "dataList",
                transcode: 0,
                project_id: archiId,
            };
            if (this.isItself) {
                obj.object_uuid = "object617117a3ec2ce";
                obj.view_uuid = "view61793d2a8e4f5";
            }

            await getValue(obj).then((res) => {
                if (res.status === 200) {
                    const list = JSON.parse(JSON.stringify(res.data.data));
                    const arr = getUserList(list, "project", "users");
                    this.archiUsers = JSON.parse(JSON.stringify(arr));
                }
            });
        },
        async getDwhUsers() {
            const obj = {
                object_uuid: "844a47c6-6380-46dd-bc4a-d73b6245175f",
                view_uuid: "view65f421bc3bf3c",
                __method_name__: "dataList",
                transcode: 0,
            };

            await getValue(obj).then((res) => {
                if (res.status === 200) {
                    this.dwhUsers = res.data.data;
                }
            });
        }
    },
    beforeDestroy() {
        if (this.ssInstance) {
            const teamworkService = this.ssInstance.getProvider('TeamworkServiceProvider');
            teamworkService.enable && teamworkService.ws.close();
        }
    }
};
</script>