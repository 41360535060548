/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-11-06 09:35:05
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-12-27 20:00:27
 * @FilePath: \dataview-next\src\custom-component\viewerGIS\Events\CoordinateEvents.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default class CoordinateEvents {
  constructor() {}

  static getEvents(viewer, comp) {
    return {
      'ViewerGIS.getCanvasByWgs': (eid, position, cb) => {
        if (eid !== comp.element.id || !position|| position === null) return;
        let canvas = viewer.Coordinate.getCanvasByWgs(
          position.lng,
          position.lat,
          position.height
        );
        cb && cb(canvas);
      }
    };
  }
}
