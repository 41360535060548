<template>
	<div class="table-list">
    <header v-if="showHeader" :class="{ 'table-header-radius': !!statusConfig.headerFillet }" class="table-header">
			<div class="header-item" v-if="statusConfig.showIndex"
				:style="{
					width: '55px',
					'flex-grow': 0,
					'flex-basis': '55px',
					textAlign: 'center',
					...headerStyle
				}"
			>
				序号
			</div>
			<div v-for="(item, index) in fieldForView" class="header-item"
				:style="Object.assign({
					width: `${item.width}px`,
					'flex-basis': `${item.width}px`,
					'flex': item.width ? '': '1',
					color: item.color,
					textAlign: item.headerAlign || (item.align || 'left'),
					...headerStyle
				}, userInnerBorder && (statusConfig.showIndex || index > 0) ? cellBorder : {})"
				:key="item.uuid">
				{{ item.label }}
			</div>
    </header>
    <div class="table-body" v-loading="loading">
      <template v-if="tableData.length !== 0">
        <section class="row" v-for="(row, index) in tableData" :key="index" :style="rowStyle(index)">
					<div class="cell"
						v-if="statusConfig.showIndex"
						:style="{
							'flex-basis': '55px',
							'flex-grow': 0,
							width: '55px',
							textAlign: 'center',
							...bodyStyle
						}"
					>
						<span>
							<span v-if="statusConfig.orderPointShow" :style="{ color: statusConfig.orderPointColor }" class="order-point-icon">•</span>
							{{ index | indexToOrderNum }}
						</span>
					</div>
					<div v-for="(item, subindex) in fieldForView" class="cell"
						:style="Object.assign({
							width: `${item.width}px`,
							'flex-basis': `${item.width}px`,
							'flex': item.width ? '': '1',
							color: item.color,
							textAlign: item.align || 'left',
							...bodyStyle
						}, userInnerBorder && (statusConfig.showIndex || subindex > 0) ? cellBorder : {})"
						:key="item.uuid"
					>
						<span >
							<!-- 图片 -->
							<template v-if="item.type === 2">
								<el-image
									v-if="Array.isArray(row[item.uuid])"
									:src="row[item.uuid] && row[item.uuid].length && row[item.uuid][0].url.toString().indexOf('http') > -1 ? row[item.uuid][0].url : defaultImage"
									:previewSrcList="[row[item.uuid].length ? (row[item.uuid][0].url.toString().replace('_thumb', '')) : defaultImage ]"
									lazy
									fit="cover"
									alt="图片"
									class="image"
									:style="{
										height: (item.imgConfig.height || 60) + 'px',
										width: (item.imgConfig.height || 60) + 'px',
									}"
								></el-image>
								<!-- 链接 -->
								<el-image
									v-else
									:src="row[item.uuid] && row[item.uuid].indexOf('http') > -1 ? row[item.uuid] : defaultImage"
									:previewSrcList="[row[item.uuid] ? (row[item.uuid].toString().replace('_thumb', '')) : defaultImage ]"
									lazy
									fit="cover"
									alt="图片"
									class="image"
									:style="{
										height: (item.imgConfig.height || 60) + 'px',
										width: (item.imgConfig.height || 60) + 'px',
									}"
								></el-image>
							</template>
							<!-- 状态 -->
							<span
								v-else-if="item.type === 3 && !isNull(row[item.uuid])"
								:style="{
									color:
										filterStatus(row[item.uuid], item.statusOptions)
											.color || item.color,
								}"
							>
								{{
									filterStatus(row[item.uuid], item.statusOptions)
										.label
								}}
							</span>
							<!-- 时间 -->
							<template v-else-if="item.type === 4 && !isNull(row[item.uuid])">{{ filterTime(row[item.uuid], item.timeFormat, item.connector) }}</template>
							<!-- 富文本 -->
							<template v-else-if="item.type === 5 && !isNull(row[item.uuid])">
								<div
									class="rich-text"
									v-html="row[item.uuid]"
								></div>
							</template>
							<!-- 富文本 -->
							<template v-else-if="item.type === 6 && !isNull(row[item.uuid])">
								<a
									:href="row[item.uuid]"
									class="link"
									alt="链接"
								>
									{{
										row[item.uuid]
									}}
								</a>
							</template>
							<!-- switch 开关 -->
							<template v-else-if="item.type === 7">
								<el-switch
									v-model="row[item.uuid]"
									@change="onSwitch(item, row[item.uuid], row)"
								></el-switch>
							</template>
							<!-- 常规显示 -->
							<template v-else>{{ row[item.uuid] ?? '/' }}</template>
						</span>
					</div>
        </section>
      </template>
      <el-empty v-else description="暂无数据"></el-empty>
    </div> 
    <footer class="paging" v-if="statusConfig.pagination">
      <el-pagination
				:class="statusConfig.transparentPagination ? 'custom-pagination' : ''"
        @size-change="onSize"
        @current-change="onPage"
        :current-page="+pager.current_page"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="+pager.per_page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="+pager.total"
      ></el-pagination>
    </footer>
  </div>
</template>

<script>
import { $ } from '@/utils/tools';
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { getComponentById, initParams,openUrl } from '@/utils/tools';
import { Empty, Image, Pagination, Switch } from 'element-ui';
import { mapState } from 'vuex';
import { judgingArchiAPermission } from '@/utils/tools';
import containerMixin from '@/custom-component/container/mixins';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';

/**
 * @description 表格列表
 */
export default {
	name: 'CommonTableContainer',
	mixins: [containerMixin, databaseTriggerDebug],
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
     default: null
    }
	},
	components: {
		'el-pagination': Pagination,
		'el-image': Image,
		'el-switch': Switch,
		'el-empty': Empty,
	},
	data() {
		return {
			// 当前组件唯一Key
			boxId: `table-container-${new Date().getTime()}`,
			// 分页
			pager: {
				current_page: 1,
				per_page: 15,
				total: 0
			},
			// 表格数据
			tableData: [],
			// 表格高度
			height: 250,
			// 配置数据
			metadata: [],
			loading: false,
			// 缓存的搜索条件
			search: [],
			// 周几
			weekDays: ['周天', '周一', '周二', '周三', '周四', '周五', '周六'],
			// 默认图片
			defaultImage: require('@/assets/images/defaultImage.png'),
			param: {},
			// 树形图
			treeProps: {},
			rowKey: '',
			// 表格弹窗
			showSheet: false,
			// 表格数据
			sheetFormData: null,
			exportConfig: null,
			// 操作类型：add - 新增，edit - 编辑，info - 详情，export - 导出
			spreadsheetType: 'export',
			exportViewParams: {},
			exportViewSearch: [],
			// 使用后端返回配置导出的导出配置
			exportWay: '', // 导出配置 eeConfig 后端配置， '' 自定义
			eeExportTemplate: '',
			eeObjectUUID: '',
			eeViewUUID: '',
			fieldForView: []
		};
	},
	created() {
		setTimeout(() => {
			if (this.database && !this.hasComponentParam()) {
				this._initDataDebug();
				const { search = [], param = {}, canPost } = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.subComponentData,
					this.groupComponents
				);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				this.getList(this.database, search, param);
			}
		}, 500)
		this.metadata = this.element.metadata || [];
		this.setInterval();
	},
	computed: {
		...mapState(['componentData']),
		// 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
		// 操作类型
		actionConfig() {
			return this.element && this.element.actionConfig;
		},
		// 子表操作配置
		nestingActionConfig() {
			return this.element && this.element.nestingActionConfig;
		},
		// 配置
		statusConfig() {
			return this.element && this.element.statusConfig;
		},
		// 字段配置
		fieldConfig() {
			const configs = this.element && this.element.fieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele && ele.show) {
					result.push(ele);
				}
			});
			return result;
		},
		// 字表字段配置
		nestingFieldConfig() {
			const configs = this.element && this.element.nestingFieldConfig;
			if (!configs) return [];
			if (!configs && !configs.length) return this.metadata || [];
			const result = [];
			configs.forEach((ele) => {
				if (ele && ele.show) {
					result.push(ele);
				}
			});
			return result;
		},
		showHeader() {
			return this.statusConfig.showHeader;
		},
		headerStyle () {
			return {
				'line-height': this.statusConfig.headerHieght,
				color: this.statusConfig.headerColor,
				background: this.statusConfig.headerTransparent ? 'transparent' : this.statusConfig.headerBackGround,
				fontSize:this.statusConfig.headerFontSize,
			};
		},
		bodyStyle () {
			return {
				'line-height': this.statusConfig.bodyHieght,
				background: this.statusConfig.bodyTransparent ? 'transparent' : this.statusConfig.bodyBackGround,
				fontSize:this.statusConfig.bodyFontSize,
			};
		},
		// 前端排序
		useSort() {
			return !!this.element?.statusConfig?.useSort;
		},
		userInnerBorder() {
			return this.element?.statusConfig?.userInnerBorder
		},
		rowBorder() {
			return {
				borderTopColor: this.element?.statusConfig?.borderColor,
				borderTopWidth: (this.element?.statusConfig?.borderWidth || 0) + 'px',
				borderTopStyle: 'solid'
			}
		},
		cellBorder() {
			return {
				borderLeftColor: this.element?.statusConfig?.borderColor,
				borderLeftWidth: (this.element?.statusConfig?.borderWidth || 0) + 'px',
				borderLeftStyle: 'solid'
			}
		},
		// 排序字段类表
		sortConfigs() {
			return this.fieldConfig?.filter(ele => ele.bySort);
		},
		oddRowsStyle() {
			const oddRowsBackground = this.statusConfig?.oddRowsBackground;
			if(oddRowsBackground) {
				return { background: oddRowsBackground };
			}
			return {};
		},
		evenRowsStyle() {
			const evenRowsBackground = this.statusConfig?.evenRowsBackground;
			if(evenRowsBackground) {
				return { background: evenRowsBackground };
			}
			return {};
		},
		rowStyle() {
			return (index) => {
				const useStripe = this.statusConfig?.stripe;
				const borderStyle = this.userInnerBorder && (this.showHeader || index > 0) ? this.rowBorder : {};
				if (useStripe) {
					let realIndex = index + 1;
					if(realIndex % 2 === 1) {
						return {
							...borderStyle,
							...this.oddRowsStyle
						};
					} else if (realIndex % 2 === 0) {
						return {
							...borderStyle,
							...this.evenRowsStyle
						};
					}
				} else {
					return borderStyle;
				}
			}
		}
	},
	watch: {
		// 为了触发配置时候字段排序更新
		fieldConfig: {
			handler() {
				this.fieldForView = [];
				setTimeout(() => {
					this.fieldForView = this.fieldConfig || [];
				}, 0);
			},
			deep: true,
			immediate: true
		}
	},
	filters: {
  /**
   * @description: index 补 0 序号
   * @param {*} v
   * @return {*}
   */		
		indexToOrderNum(v) {
			if(isNaN(v)) {
				return v
			} else {
				const orderNum = Number(v) + 1;
				if(orderNum < 10) {
					return `0${orderNum}`
				} else {
					return orderNum
				}
			}
		}
	},
	mounted() {
		this.$nextTick(() => {
			const box = $(`#${this.boxId}`);
			if (box && box.offsetHeight) {
				this.height = box.offsetHeight - 42;
			}
		});
		// 导出
		const exportData = {
			[this.element.id]: (exportConfig) => {
				if (!exportConfig || exportConfig.componentId !== this.element.id) return;
				this.exportConfig = exportConfig;
				this.doExport(exportConfig);
			}
		}
		eventBus.$on('exportData', exportData[this.element.id]);
		// 监听请求
		// 配置关联参数的容器才需要监听
		const databseTrigger = {
			[this.element.id]: (data) => {
				if (data.parentId && data.parentId !== this.element.id) return false;
				// 配置时候触发请求
				if (data.componentId === this.element.id && data.isInit) {
					this._startDataDebug();
					const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
					if (!canPost) {
						this._failDataDebug('参数必填校验未通过');
						return;
					}
					this.getList(this.element.database, search, param);
					return;
				}
				// 点击操作时候不更新数据
				if (data.noUpdate) return;
				const { paramsConfig } = this.element.database;
				if (!paramsConfig || !paramsConfig.length) return;
				let isTarget = false;
				paramsConfig.forEach((ele) => {
					if (ele.componentId === data.componentId) {
						isTarget = true;
					}
				});
				if (!isTarget && !data.isUpdate) return;
				this._startDataDebug();
				// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
				const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
				if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
				this.param = param;
				this.getList(this.element.database, search, param);
			}
		}
		eventBus.$on('databaseTrigger', databseTrigger[this.element.id]);
	},
	methods: {
		/**
		 * @desc: 判断是否为逻辑空(空对象，空数组等)
		 * @param {Number} val
		 */
		isNull(val) {
			if (val === 0) return false;
			if (val === '00000-00-00 00:00:00') return true;
			if (Array.isArray(val) && !val.length) return true;
			if (Object.prototype.toString.call(val) === '[object Object]' && JSON.stringify(val) === '{}') return true;
			if (!val) return true;
			return false;
		},
		// 判断架构及权限
		judgingArchiAPermission,
		/**
		 * @description: 请求数据 定时刷新用
		 */
		doInterface() {
			if (!this.interfaceFinished) return;
			this.interfaceFinished = false;
			this.onPage(1);
		},
		/**
		 * @desc: 根据配置逻辑判断操作是否显示
		 * @param {Object} actionConfig
		 */
		logicAction(actionConfig, row) {
			if (!actionConfig.useDepend || !actionConfig || !actionConfig.dependLogic ||!actionConfig.dependLogic.length) {
				return true;
			}
			const dependLogic = actionConfig.dependLogic;
			const logicList = [];
			for (let i = 0, len = dependLogic.length; i < len; i++) {
				const { field, fieldAttr, fieldLogic, itemLogic, value, valueType } =
					dependLogic[i];
				let logicValue = null;
				if (fieldAttr === 'length') {
					logicValue = row[field].length;
				} else {
					logicValue = row[field];
				}
				// 当前项判断条件
				// 字段关系 大于>  小于 < 等于 === 大于等于>=  小于等于 <= 不等于 !=
				let itemBoolean = true;
				if (fieldLogic === '===') {
					if (valueType === 'string') {
						itemBoolean = logicValue === value;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (fieldLogic === '!=') {
					if (valueType === 'string') {
						itemBoolean = logicValue !== value;
					} else if (valueType === 'number') {
						itemBoolean = logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (fieldLogic === '>') {
					if (valueType === 'number') {
						itemBoolean = logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (fieldLogic === '<') {
					if (valueType === 'number') {
						itemBoolean = logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (fieldLogic === '<=') {
					if (valueType === 'number') {
						itemBoolean = logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (fieldLogic === '>=') {
					if (valueType === 'number') {
						itemBoolean = logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				} else if (fieldLogic === 'like') {
					if (valueType === 'string') {
						itemBoolean = logicValue.toString()?.includes(value.toString());
					}
				} else if (fieldLogic === 'whereIn') {
					// 包含
					try {
						let valArr = [];
						if (Array.isArray(logicValue)) {
							valArr = logicValue;
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
							valArr = JSON.parse(logicValue);
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (value.toString()?.startsWith('[') && value.toString()?.endsWith(']')) {
							valArr = JSON.parse(value);
							itemBoolean = valArr.includes(logicValue) || valArr.includes(+logicValue);
						} else {
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						}
					} catch (err) {
						console.log(err);
					}
				}
				logicList.push({ itemLogic, itemBoolean });
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 循环逻辑关系
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		},
		/**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.subComponentData, componentId);
      if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 获取行key
		 */
		getRowKey(row) {
			return row[this.rowKey];
		},
		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		/**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.tableData = [];
			if (!this.validDatabase(database)) {
				this.interfaceFinished = true;
				this._failDataDebug('请求配置错误');
				return;
			}
			// 外部参数
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			this.loading = true;
			const paramsObj = {
				...params,
				...outParams,
				page: this.pager.current_page || 1,
				size: this.statusConfig.pagination ? this.pager.per_page || 15 : 15,
				search
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			let url = '/api/mapi';
			if (mapping === 'interface') {
				configObj = {
					...paramsObj
				}
				if (database?.interfaceConfig?.url?.indexOf('http') === -1) {
					url = `/api${database?.interfaceConfig?.url}`;
				} else {
					url = database?.interfaceConfig?.url
				}
			} else if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj
				};
			}
			// 将使用的字段添加到请求用于后端请求优化(隐式使用的字段分析问题？暂时注释)
			// configObj = {
			// 	...configObj,
			// 	__page_uuid__ : this.getPageUUID(), // 页面UUID
			// 	__component_id__: this.element?.id, // 组件ID
			// 	__selects__: this.getTableFields() // 使用的数组
			// }
			// 获取表格数据
			dataInterface(configObj, url).then((res) => {
				if (res && res.status === 200) {
					let tableData = [];
					if (mapping === 'interface' || ['dataList', 'relationList'].includes(__method_name__)) {
						// 列表数据
						tableData = this.getListFromRes(res, true) || [];
					}
					for (let i = 0; i < this.fieldConfig.length; i++) {
						const item = this.fieldConfig[i];
						if (item.type === 7) {
							// switch 编辑
							tableData = tableData.map((ele) => {
								if (item.switchOptions.type === 'number') {
									// 数字
									ele[item.uuid] =
										+ele[item.uuid] === +item.switchOptions.trueValue;
								} else if (item.switchOptions.type === 'boolean') {
									if (ele[item.uuid] === 'true') {
										ele[item.uuid] = true;
									} else if (ele[item.uuid] === 'false') {
										ele[item.uuid] = false;
									}
								} else if (item.switchOptions.type === 'string') {
									ele[item.uuid] =
										ele[item.uuid] === item.switchOptions.trueValue;
								}
								return ele;
							});
						}
					}
					// 字段列表
					if (!this.metadata || !this.metadata.length) {
						let metadata = {};
						if (mapping === 'interface') {
							// 接口
							if (Array.isArray(tableData)) {
								if (Object.prototype.toString.call(tableData?.[0]) === '[object Object]') {
									for (let key in tableData?.[0]) {
										metadata[key] = key;
									}
								} 
							}
						} else {
							metadata = res.data.metadata;
						}
						this.metadata = metadata;
					}
					// 树形表格
					if (this.statusConfig.isTreeTable) {
						let children = '';
						for (let key in this.metadata) {
							if (this.metadata[key] === this.statusConfig.children) {
								children = key;
							}
						this.rowKey = this.getIdUUID();
						}
						tableData = this.formatTreeData(tableData, children);
						this.treeProps = {
							children: 'children',
							hasChildren: 'hasChildren'
						};
					} else if (this.statusConfig.useFeWBS) {
						// 非树形WBS
						tableData = this.formatTreeData(tableData, '');
					}
					// 非树形表格
					if (!this.statusConfig.isTreeTable && this.statusConfig.feTreeKey) {
						const { feTreeKey } = this.statusConfig;
						this.rowKey = this.getIdUUID();
						this.treeProps = {
							children: 'children',
							hasChildren: 'hasChildren'
						}
						tableData = this.listToTree(tableData, feTreeKey, this.getIdUUID());
						// console.log(this.rowKey, '---123123----', this.treeProps);
					}
					// 嵌套表格
					if (this.statusConfig.isNesting) {
						this.rowKey = '';
						let children = '';
						for (let key in this.metadata) {
							if (this.metadata[key] === this.statusConfig.nestingKey) {
								children = key;
							}
							this.rowKey = this.getIdUUID();
						}
						tableData = tableData.map((dataItem) => {
							return {
								...dataItem,
								children: dataItem[children] || []
							};
						});
						// 字段处理
						for (let i = 0; i < this.nestingFieldConfig.length; i++) {
							const item = this.nestingFieldConfig[i];
							if (item.type === 7) {
								// switch 编辑
								tableData = tableData.map((ele) => {
									const childrenTable = ele[children] || [];
									return {
										...ele,
										[children]: childrenTable.map(child => {
											if (item.switchOptions.type === 'number') {
												// 数字
												child[item.uuid] = +child[item.uuid] === +item.switchOptions.trueValue;
											} else if (item.switchOptions.type === 'boolean') {
												if (child[item.uuid] === 'true') {
													child[item.uuid] = true;
												} else if (child[item.uuid] === 'false') {
													child[item.uuid] = false;
												}
											} else if (item.switchOptions.type === 'string') {
												child[item.uuid] = child[item.uuid] === item.switchOptions.trueValue;
											}
											return {
												...child
											};
										})
									}
								});
							}
						}
					}
					// 排序
					if (this.useSort && this.sortConfigs && this.sortConfigs.length) {
						tableData = this.customSort(tableData);
					}
					// console.log(tableData, '----tableData----');
					this.tableData = tableData;
					if (this.statusConfig?.canSelect) {
						// 获取默认选中
						if (this.defaultTableSelectData) {
							this.getDefaultSelect();
						}
					}
					// 完全数据 暂时保存
					this.fullData = res.data.data;
					// 保存容器数据
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							containerData: this.tableData,
							fullData: this.fullData,
							metadata: this.metadata,
							database: {
								...this.element.database,
								fieldList: this.getFieldList(this.metadata)
							}
						},
						containerId: null,
						isModify: true,
						pageUUID: this.EDITOR_pageUUID
					});
				}
				this.interfaceFinished = true;
				this.loading = false;
				this._successDataDebug({
					url,
					content: configObj,
					res
				});
			}).catch((err) => {
				console.log(err);
				this.interfaceFinished = true;
				this.loading = false;
				this._errorDataDebug({
					url,
					content: configObj,
					err
				});
			});
		},
		/**
		 * @desc: 前端自定义排序
		 * @param {Array} arr
		 * sortWay:排序顺序 ascending 升序， descending 降序
		 */
		customSort(arr) {
			if (!arr || !Array.isArray(arr) || !arr.length) return [];
			let tableData = arr;
			try {
				this.sortConfigs.forEach(ele => {
					const { uuid, sortWay } = ele;
					tableData.sort((a, b) => {
						return a?.[uuid] - b?.[uuid];
					});
					// 降序
					if (sortWay === 'descending') {
						tableData.reverse();
					}
				});
			} catch(e) {
				console.log(e, '前端排序故障---表格1');
			}
			return tableData;
		},
		/**
		 * @desc: 获取字段列表
		 * @param {Object} obj metadata对象
		 */
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		},
		/**
		 * @desc: 切换switch操作状态
		 * @param {Object} item 配置
		 * @param {Boolean} data 当前切换的布尔值
		 * @param {Object} row 当前行
		 * @param {Boolean} isNesting 是否为子表操作
		 */
		onSwitch(item, data, row, isNesting) {
			this.loading = true;
			let idUUID = this.statusConfig.idUUID || '';
			if (!idUUID) {
				for (let key in this.metadata) {
					if (this.metadata[key] === 'id') {
						idUUID = key;
					}
				}
			}
			// 需要将boolean还原为后端允许的值
			let object_uuid = this.element?.database?.objectData?.uuid;
			if (isNesting && this.statusConfig?.nestingObjectUUID) {
				object_uuid = this.statusConfig?.nestingObjectUUID;
			}
			if (!object_uuid) {
				this.loading = false;
				this.$message.error('操作失败！')
				return false;
			}
			const params = {
				__method_name__: 'updateData',
				object_uuid,
				data_id: row[idUUID],
				...row
			};
			const value = data ? item.switchOptions.trueValue : item.switchOptions.falseValue;
			this.$set(params, item.uuid, value);
			dataInterface(params)
				.then(() => {
					this.loading = false;
					this.onPage(this.pager.current_page);
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
		/**
		 * @desc: 格式化比对收缩内容
		 * @param {Array} search 当前组件中的搜索内容
		 * @param {Array} outputSearch 外部传入的搜索项目
		 */
		formatSearch(search, outputSearch) {
			if (!search.length) return outputSearch;
			let result = search || [];
			for (let i = 0; i < outputSearch.length; i++) {
				const item = outputSearch[i];
				const index = search.findIndex(
					(ele) => ele.field_uuid === item.field_uuid
				);
				if (index === -1) {
					result.push(item);
				} else {
					result.splice(index, 1, item);
				}
			}
			return result;
		},
		/**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (database.mapping === 'interface' && database.interfaceConfig) return true;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
		/**
		 * @desc: 根据请求返回获取列表结构
		 * @param {Object} res 请求返回的数据
		 * @param {Boolean} withPager 是否需要重置pager
		 */
		getListFromRes(res, withPager = true) {
			if (res.data && Array.isArray(res.data)) {
				if (withPager) {
					this.pager = res;
				}
				return res.data;
			}
			if (res.data && typeof res === 'object') {
				return this.getListFromRes(res.data, withPager);
			}
			return [];
		},
		/**
		 * @desc: 点击行展开详情操作（@廖总）
		 * @param {Object} row 行数据
		 * @param {Object} column
		 */
		onRowClick(row) {
			if (!this.actionConfig || !this.actionConfig.detail || !this.statusConfig.rowShowDetails) {
				return;
			}
			const { detail } = this.actionConfig;
			if (!detail || !detail.eventList || !detail.eventList.length) return;
			const comEvents = detail.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.onRowClickItem(comEvents[i], row);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.onRowClickItem(ele, row);
				});
			}
		},
		onRowClickItem(eventItem, row) {
			const { effects } = eventItem;
				if (effects && effects.length) {
					for (let j = 0; j < effects.length; j++) {
						const effectItem = effects[j];
						const { actions = [], targets = [] } = effectItem;
						if (actions.length && targets.length && actions.includes('show')) {
							targets.forEach((ele) => {
								if (ele.toString().indexOf('CommonDialog-') === 0) {
									this.element.resolveData = row;
									this.$store.commit('triggerEvents', {
										config: {
											...eventItem,
										...effectItem,
										actions: ['hidden']
										},
										element: this.element,
										EDITOR_pageUUID: this.EDITOR_pageUUID
									});
									this.$nextTick(() => {
										this.$store.commit('triggerEvents', {
											config: {
												...eventItem,
											...effectItem
											},
											element: this.element,
											EDITOR_pageUUID: this.EDITOR_pageUUID
										});
										this.$nextTick(() => {
											const interval = setInterval(() => {
												const drawer = document.querySelector('.common-drawer');
												if (drawer) {
													clearInterval(interval);
													const drawerBox = drawer.parentNode?.parentNode;
													if (drawerBox) {
														drawerBox.classList.add('Editor-drawer__wrapper');
													}
												}
											}, 100);
										});
									});
								}
							});
						}
					}
				}
		},
		/**
		 * @desc: 操作
		 * @param {String} action 操作
		 * @param {Object} output 输出的数据
		 */
		onAction(action, output, e, type, actionConfig) {
			e.preventDefault();
			e.stopPropagation();
			// 是否为删除
			if (action === 'delete') {
				this.doDelete(output);
				return;
			}
			// 为兼容自定义export，多个导出
			if (action === 'export') {
				// 行内导出
				const exportConfig = this.element.exportConfig;
				this.exportConfig = exportConfig;
				this.spreadsheetType = 'export';
				this.exportWay = exportConfig.exportWay || '';
				this.doExport(exportConfig, output);
				return;
			}
			// 其他导出
			if (action.toString().indexOf('export') === 0) {
				// 行内导出
				const exportConfig = actionConfig[action].eventList[0];
				exportConfig.exportType = 'targetRow';
				this.exportConfig = exportConfig;
				this.spreadsheetType = 'export';
				this.exportWay = exportConfig.exportWay || '';
				this.doExport(exportConfig, output);
			}
			this.element.resolveData = output;
			// 详情
			if (action === 'detail' && this.statusConfig?.rowShowDetails) {
				this.onRowClick(output);
				return;
			}
			// 关闭所有弹窗及抽屉
			this.removeModel();
			// 事件
			const actionKey = type === 'nesting' ? 'nestingActionConfig' : 'actionConfig'

			const comEvents = this.element[actionKey][action].eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.onActionItem(comEvents[i], output);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.onActionItem(ele, output);
				});
			}
			this.$nextTick(() => {
				eventBus.$emit('databaseTrigger', {
					componentId: this.element.id,
					action,
					output,
					isInit: false,
					noUpdate: true
				});
			});
			return false;
		},
		/**
		 * @desc: 单个操作
		 * @param {Object} ele
		 */
		onActionItem(ele) {
			if (ele.key === 'click') {
				// 跳转页面
				if (ele.actionType === 'jumpPage') {
					if (ele.linkType === 'projectPage') {
						const query = {};
						if (ele.queryList && ele.queryList.length) {
							ele.queryList.forEach((queryItem) => {
								const component = getComponentById(
									this.subComponentData,
									queryItem.componentId
								);
								this.$set(
									query,
									queryItem.key,
									component.resolveData[queryItem.feild]
								);
							});
						}
					} else if (ele.linkType === 'outPage') {
            openUrl(ele.linkUrl,ele.linkUrl)
						// window.open(ele.linkUrl);
					}
				} else if (ele.actionType === 'eeAction') {
					// 触发后端事件
					console.log(ele, '000000');
					// 搜集参数
				}else {
					ele.effects.forEach((effect) => {
						this.$store.commit('triggerEvents', {
							config: {
								...ele,
							...effect
							},
							element: this.element,
							EDITOR_pageUUID: this.EDITOR_pageUUID
						});
					});
				}
			}
		},
		/**
		 * @desc: 移除所有显示弹窗
		 */
		removeModel() {
			if (!this.actionConfig || !this.actionConfig.detail) return;
			const { detail } = this.actionConfig;
			if (!detail || !detail.eventList || !detail.eventList.length) return;
			const comEvents = detail.eventList || [];
			for (let i = 0; i < comEvents.length; i++) {
				const { pattern, eventList = [], specialEventList = [] } = comEvents[i];
				if (pattern === undefined) {
					this.removeModelItem(comEvents[i]);
					break;
				}
				const result = pattern === 'special' ? specialEventList : eventList
				result.forEach((ele) => {
					this.removeModelItem(ele);
				});
			}
		},
		removeModelItem(eventItem) {
			const { effects } = eventItem;
				if (effects && effects.length) {
					for (let j = 0; j < effects.length; j++) {
						const effectItem = effects[j];
						const { actions = [], targets = [] } = effectItem;
						if (actions.length && targets.length && actions.includes('show')) {
							targets.forEach((ele) => {
								if (ele.toString().indexOf('CommonDialog-') === 0) {
									this.$store.commit('triggerEvents', {
										config: {
											...eventItem,
										...effectItem,
										actions: ['hidden']
										},
										element: this.element,
										EDITOR_pageUUID: this.EDITOR_pageUUID
									});
								}
							});
						}
					}
				}
		},
		/**
		 * @desc: 删除
		 * @param {Object} data 删除行的数据
		 */
		doDelete(data) {
			this.$confirm('是否确认删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			})
				.then(() => {
					const metadata = this.element.metadata || {};
					let data_id = '';
					for (let key in metadata) {
						if (metadata[key] === 'id') {
							data_id = data[key];
							break;
						}
					}
					this.loading = true;
					dataInterface({
						__method_name__: 'deleteData',
						object_uuid: this.element.database.objectData.uuid,
						data_id
					})
						.then((res) => {
							this.loading = false;
							// 获取数据
							this.onPage(1);
							console.log(res, '---res');
						})
						.catch((err) => {
							this.loading = false;
							console.log(err, '---err');
						});
				})
				.catch(() => {});
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} current_page 当前页码
		 */
		onPage(current_page) {
			this.pager = {
				...this.pager,
				current_page
			};
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
			if (canPost) {
				this.getList(this.database,search, param || {});
			} else {
				this.interfaceFinished = true;
			}
		},
		/**
		 * @desc: 切换分页参数
		 * @param {Number} per_page 跳转的页码
		 */
		onSize(per_page) {
			this.pager = {
				...this.pager,
				current_page: 1,
				per_page
			};
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
			if (canPost) {
				this.getList(this.database, search, param || {});
			}
		},
		/**
		 * @desc: 获取状态值
		 * @param {any普通类型} value 值
		 * @param {Array} statusOptions 状态选项
		 * @return {Object} 用于状态显示的对象  label value color
		 */
		filterStatus(value, statusOptions = []) {
			if (!statusOptions || !Array.isArray(statusOptions)) {
				return {
					label: value
				};
			}
			for (let i = 0; i < statusOptions.length; i++) {
				if (
					(isNaN(value) && statusOptions[i].value === value) ||
					+statusOptions[i].value === +value
				) {
					return statusOptions[i];
				}
			}
			return {
				label: value
			};
		},
		/**
		 * @desc: 格式化时间
		 * @param {String/Object/Number} value 时间值 兼容时间戳、时间字符串、时间对象
		 * @param {String} timeFormat 格式化
		 * @param {String} connector 连接符
		 */
		filterTime(value, timeFormat, connector = '') {
			if (!value) return '';
			// 时间格式表单无法统一不做校验
			// const reg = /^(\d{4})(-|\/)(\d{2})(-|\/)(\d{2})/;
			// if ((isNaN(+value) && !reg.test(value))) return '';
			let dateObj = null;
			if (value instanceof Date) {
				dateObj = value;
			} else if (isNaN(value)) {
				dateObj = new Date(value);
			} else {
				dateObj = new Date(value * 1000);
			}
			// @凌志华 后端返回的无效时间显示为 '' (2022-02-15 15:51:51)
			if (!(dateObj instanceof Date) || dateObj.toString() === 'Invalid Date') return '';
			const year = dateObj.getFullYear();
			// 无效的时间
			if (year === 1970) return '';
			let month = this.addZero(dateObj.getMonth() + 1);
			const day = this.addZero(dateObj.getDate());
			const hour = this.addZero(dateObj.getHours());
			const minute = this.addZero(dateObj.getMinutes());
			const second = this.addZero(dateObj.getSeconds());
			let connectorStr = '-';
			if (connector === '/') {
				connectorStr = '/';
			} else if (connector === 'cn') {
				connectorStr = connector;
			}
			const defaultStr = `${year}${
				connectorStr === 'cn' ? '年' : connectorStr
			}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
				connectorStr === 'cn' ? '日' : ''
			} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
				connectorStr === 'cn' ? '分' : ':'
			}${second}${connectorStr === 'cn' ? '秒' : ''}`;
			switch (timeFormat) {
				case 'YMDhms':
					return defaultStr;
				case 'YMDhm':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'YMDh':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					} ${hour}${connectorStr === 'cn' ? '时' : ''}`;
				case 'YMD':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr
					}${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${
						connectorStr === 'cn' ? '日' : ''
					}`;
				case 'YM':
					return `${year}${
						connectorStr === 'cn' ? '年' : connectorStr}${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'MD':
					return `${month}${connectorStr === 'cn' ? '月' : connectorStr}${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'hms':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ':'
					}${second}${connectorStr === 'cn' ? '秒' : ''}`;
				case 'hm':
					return `${hour}${connectorStr === 'cn' ? '时' : ':'}${minute}${
						connectorStr === 'cn' ? '分' : ''
					}`;
				case 'yyyy':
					return `${year}${connectorStr === 'cn' ? '年' : ''}`;
				case 'mm':
					return `${month}${connectorStr === 'cn' ? '月' : ''}`;
				case 'dd':
					return `${day}${connectorStr === 'cn' ? '日' : ''}`;
				case 'weekDay':
					return this.weekDays[dateObj.getDay()];
				case 'week':
					return `第${this.getWeek(dateObj)}周`;
				default:
					return defaultStr;
			}
		},
		/**
		 * @desc: 数字
		 * @param {Number} value
		 * @param {Object} numberFormat
		 */
		filterNumber(value, numberFormat = {}) {
			if (isNaN(value) && isNaN(+value)) return value;
			const { type, decimalPlace, prefix = '', suffix = '' } = numberFormat;
			let result = value;
			if (type === 'float') {
				result = (+value).toFixed(isNaN(+decimalPlace) ? 0 : +decimalPlace);
			}
			return `${prefix} ${result} ${suffix}`;
		},
		/**
		 * @desc: 补零
		 * @param {Number} val 需要补零的值
		 */
		addZero(val) {
			if (isNaN(val) || val < 0 || val >= 10) return val;
			return `0${val}`;
		},
		/**
		 * @desc: 获取当前日期对象为全年第几周
		 * @param {Object<Date>} dateObj 时间对象
		 * @return {Number} 第几周
		 */
		getWeek(dateObj) {
			const firstDay = new Date(`${dateObj.getFullYear()}-1-1`);
			const timeBetween = dateObj - firstDay;
			const between = timeBetween / (7 * 24 * 60 * 60 * 1000);
			return Math.ceil(between);
		},
		/**
		 * @desc: 验证架构类型
		 * @param {Array, String} types 类型 group company project
		 */
		validArchiType(types) {
			if (!types || !Array.isArray(types) || !types.length) return true;
			if (this.$GetUserInfo('name') === 'admin') return true;
			const archiType = this.$GetTargetArchi('archiType');
			if (archiType && types.includes(archiType)) {
				return true;
			}
			return false;
		}
	}
};
</script>

<style lang="less" scoped>
.table-list{
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .table-header{
    width: 100%;
    display: flex;
    .header-item{
      min-height: 30px;
      box-sizing: border-box;
      padding: 5px;
      line-height: 20px;
      text-align: center;
      word-break: break-all;
    }
  }
	.table-header-radius{
		border-radius: 9999px;
		overflow: hidden;
	}
  .table-body{
    flex: 1;
    width: calc(~"100% + 7px");
    position: relative;
    left: 0;
    overflow: hidden;
    overflow-y: scroll;
    .row{
      display: flex;
      .cell{
        min-height: 30px;
        // line-height: 30px;
        padding: 5px 0;
        box-sizing: border-box;
        text-align: center;
        word-break: break-all;
      }
    }
  }
  .paging{
    text-align: center;
    padding: 5px 0;
    .ivu-page .ivu-page-item{
      background: transparent;
    }
  }
	.custom-pagination{
		:deep(.el-input__inner) {
			background: transparent;
			border: none;
		}
		:deep(button) {
			background: transparent;
			border: none;
		}
		:deep(li) {
			background: transparent;
			border: none;
		}
	}
}
</style>
