var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"drop-down-buttin-container"},[_c('el-dropdown',{attrs:{"placement":"bottom","trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"iconfont icongengduomore"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_vm._l((_vm.actionConfig),function(item){return _c('el-dropdown-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.judgingArchiAPermission(item.archiLimit, item.permission) &&
          _vm.judgingUILogic(item.UILogic, _vm.subComponentData, _vm.mapData)
        ),expression:"\n          judgingArchiAPermission(item.archiLimit, item.permission) &&\n          judgingUILogic(item.UILogic, subComponentData, mapData)\n        "}],key:item.key,on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{style:(`color: ${item.color}`),on:{"click":function($event){return _vm.doAction(item, $event, _vm.mapData)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),(
          _vm.judgingArchiAPermission(
            _vm.deleteLimit.archiLimit,
            _vm.deleteLimit.permission
          ) && _vm.judgingUILogic(_vm.deleteLimit.UILogic, _vm.subComponentData, _vm.mapData)
        )?_c('el-dropdown-item',{on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{style:(`color: red`),on:{"click":function($event){return _vm.onRemove(_vm.mapData, $event)}}},[_vm._v(" 删除 ")])]):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }