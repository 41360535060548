export default class MeasureEvents {
    constructor() {}

    static getEvents(viewer, comp) {
        return {
            'ViewerGIS.setMeasurePoint': (eid, coordType, cb) => {
                if (eid !== comp.element.id) return;

                viewer.Measure.measurePosition({
                    isLoop: true,
                    callback: (xyz) => {
                        switch (coordType) {
                            case 'WGS84':
                                cb && cb(xyz);
                                break;
                            case 'NanNing':
                                var ABList = viewer.Coordinate.NanNingTransform([xyz]);
                                cb && cb({lng: ABList[0].A.toFixed(3), lat: ABList[0].B.toFixed(3)});
                                break;
                        }
                    }
                });

            }
        };
    }
}
