<!--
 * @Author: 姚铃 1871883713@qq.com
 * @Date: 2022-11-11 16:09:29
 * @LastEditors: 姚铃 1871883713@qq.com
 * @LastEditTime: 2023-08-30 09:22:23
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-color.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
    @name: widget-color
    @description：widget-color
    @author: ZengWei
    @date: 2022-03-25 09:35
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show" :style="{background: element.value}"></div>
    <el-color-picker
      v-else
      v-model="element.value"
      recommend
    >
    </el-color-picker>
  </div>
</template>

<script>
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {ColorPicker} from "element-ui";

export default {
  name: "WidgetColor",
  components: {
    'el-color-picker':ColorPicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
</style>
