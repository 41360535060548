import { render, staticRenderFns } from "./LogicRules.vue?vue&type=template&id=389b7d7d&scoped=true"
import script from "./LogicRules.vue?vue&type=script&lang=js"
export * from "./LogicRules.vue?vue&type=script&lang=js"
import style0 from "./LogicRules.vue?vue&type=style&index=0&id=389b7d7d&prod&lang=css"
import style1 from "./LogicRules.vue?vue&type=style&index=1&id=389b7d7d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389b7d7d",
  null
  
)

export default component.exports