/*
 * @Description: 编辑及相关方法
 * @Author: luocheng
 * @Date: 2021-08-16 17:29:21
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-08 14:17:43
 */
import Vue from 'vue';
import { getComponentId } from '@/utils/tools';

/**
 * @desc: 将组件插入到指定id的容器中
 * @param {Array} componentData 组件数据
 * @param {String} containerID 容器ID
 * @param {Object} component 组件数据
 * @return {Array} 被格式化完毕的数据
 */
export function appChildById(componentData, containerID, component) {
	if (!Array.isArray(componentData) || !containerID || !component) return componentData || [];
	return componentData.map((ele) => {
		if (ele.type === 'container') {
			if (!ele.children) {
        Vue.set(ele, 'children', [])
			}
			if (ele.id === containerID) {
				ele.children.push(component);
				return ele;
			} else if (ele.children && ele.children.length) {
				Vue.set(ele, 'children', appChildById(ele.children, containerID, component));
			}
		}
		return ele;
	});
}

/**
 * @desc: 粘贴时候计算组件数据
 * @param {Object} data 组件对象
 * @return {Object} 组件编辑结果
 */
export function setParseData(data) {
	if (data.children && Array.isArray(data.children) && data.children.length) {
		for (let i = 0; i < data.children.length; i++) {
			data.children.splice(i, 1, {
				...data.children[i],
				id: getComponentId(data.children[i].component)
			});
			if (data.children[i].children && Array.isArray(data.children) && data.children.length) {
				data.children.splice(i, 1, {
					...data.children[i],
					children: setParseData(data.children[i].children)
				});
			}
		}
	}
	return data;
}

/**
 * @desc: 从图层列表中移除指定ID的组件
 * @param {Array} componentData 图层列表
 * @param {String} comId 需要移除的组件ID
 * @return {Array} 移除后新的图层列表
 */
export function removeComponent(componentData, comId) {
	const resultList = componentData;
	for (let i = 0; i < resultList.length; i++) {
		const item = resultList[i];
		if (item.id === comId) {
			resultList.splice(i, 1);
			break;
		}
		if (item.children && Array.isArray(item.children) && item.children.length) {
			item.children = removeComponent(item.children, comId);
		}
	}
	return resultList;
}

/**
 * @desc: 编辑组件数据(强行修改)
 * @param {Array} componentData 图层列表
 * @param {Object} component 新的组件内容
 * @return {Array} 编辑后的组件
 */
export function editorComponent(componentData, component) {
	const resultList = componentData;
	const comId = component.id;
	for (let i = 0; i < resultList.length; i++) {
		const item = resultList[i];
		if (item.id === comId) {
			resultList.splice(i, 1, component);
			break;
		}
		if (item.children && Array.isArray(item.children) && item.children.length) {
			item.children = editorComponent(item.children, component);
		}
	}
	return resultList;
}
