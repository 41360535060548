<!--
 * @Author: your name
 * @Date: 2022-04-24 09:17:17
 * @LastEditTime: 2024-08-30 18:01:59
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @Description: 绑定经纬度
 * @FilePath: \dataview-viewer-test\src\custom-component\bindLatLng\ButtonType.vue
-->
<template>
  <!-- 绑定图纸 -->
  <div>
    <slot name="showType"></slot>
    <el-drawer
      custom-class="no-padding"
      :visible.sync="show"
      size="100%"
      append-to-body
      :with-header="false"
    >
      <BindLat
        :modelData="data"
        :componentType="componentType"
        :appId="appId"
        :appSecret="appSecret"
        v-if="show"
        @back="show = false"
        @set-data="setData"
        :drawType="drawType"
        :config="config"
        :interfaceData="interfaceData"
        :searchData="searchData"
        :bodyParams="bodyParams"
      />
    </el-drawer>
  </div>
</template>

<script>
import BindLat from './index.vue';
import { Drawer } from 'element-ui';
export default {
  name: 'BindLatButton',
  components: {
    BindLat,
    'el-drawer': Drawer,
  },
  props: {
    modelData: {
      type: [Object, Array],
      default: null,
    },
    componentType: {
      type: String,
      default: '',
    },
    appId: {
      type: String,
      default: '',
    },
    appSecret: {
      type: String,
      default: '',
    },
    drawType: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: null,
    },
    interfaceData: {
      type: Object,
      default: null,
    },
    searchData: {
      type: Array,
      default: () => [],
    },
    bodyParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
      data: null,
      // data:[{
      // "model_id": "ab5058ebe0734423b372b3e42db995ba",
      // "select": [5615],
      // "polygon":[{
      // 	position:[
      // 		{height:0,lat:42.3722835,lng:115.46074032},
      // 		{height:0,lat:40.17670467,lng:115.40127004},
      // 		{height:0,lat:40.47180661,lng:117.56421734},
      // 		{height:0,lat:40.57052359,lng:117.58191834},
      // 	],
      // }],
      // "location_gis": {
      // 		"lng": 104.14924889,
      // 		"lat": 30.39431894,
      // 		"height": 62.55
      // },
      // "location_name":'四川省/成都市/双流区|成都市双流区清水堰西南约135米'
      // }]
      // data:{
      // "coverData": [[{height:0,lat:42.3722835,lng:115.46074032},
      // 						{height:0,lat:40.17670467,lng:115.40127004},
      // 						{height:0,lat:40.47180661,lng:117.56421734},
      // 						{height:0,lat:40.57052359,lng:117.58191834},
      // ]],
      // "models": [
      // 		{
      // 				"model_id": "ab5058ebe0734423b372b3e42db995ba",
      // 				"dbids": [
      // 						5615
      // 				]
      // 		}
      // ],
      // "position": {
      // 		"lng": 104.14924889,
      // 		"lat": 30.39431894,
      // 		"height": 62.55
      // }
      // }
    };
  },
  methods: {
    /**
     * @description: 绑定数据
     * @param {Object} data
     * @param {Object} info
     */
    setData(data) {
      this.$emit('set-data', data);
      this.show = false;
    },
  },
  mounted() {
    if (
      this.modelData !== null &&
      (this.modelData?.data || this.modelData?.models)
    ) {
      this.data = this.modelData?.data || this.modelData;
    } else if (Array.isArray(this.modelData) && this.modelData.length) {
      this.data = this.modelData;
    }
  },
};
</script>
