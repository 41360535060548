<!--
 * @Description: 文件操作
 * @Author: luocheng
 * @Date: 2022-09-22 11:09:43
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-23 15:26:57
-->
<!--
 * @Description: 移动文件
 * @Author: luocheng
 * @Date: 2022-03-02 15:50:16
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-17 14:27:23
-->
<template>
	<div class="operation-file" v-if="value">
		<el-dialog
			class="operation-file-dialog"
			:title="actionTitle + '文件至'"
			:visible.sync="value"
			:show-close="false"
			:before-close="handleClose"
			:destroy-on-close="true"
			append-to-body
			width="680px"
		>
			<article class="move-main">
				<header class="header">
					<section class="path">
						<i
							class="iconfont iconxiangzuojiantou1"
							@click="onBackPath"
							:class="{
								disabled: !movePathList || movePathList.length < 2
							}"
						></i>
						<el-breadcrumb separator="/">
							<el-breadcrumb-item v-for="(item, index) in movePathList" :key="index" @click.native="onPath(item)">
								{{ item.label }}
							</el-breadcrumb-item>
						</el-breadcrumb>
					</section>
				</header>
				<article class="move-contents">
					<OutType @getTypes="onTypes" v-if="operationConfig.withClass"></OutType>
					<article class="folder-list" v-loading="loading">
						<el-table
							ref="tableRef"
							class="table"
							:data="folderList"
							:header-cell-style="{ background: '#f5f5f5', border: 'none' }"
							height="520"
							@row-click="onTabRow"
							@row-dblclick="onInterFolder"
							highlight-current-row
						>
							<el-table-column prop="name" label="文件夹名">
								<template slot-scope="{ row }">
									<div class="custom-file" style="cursor: pointer">
										<i class="iconfont iconwenjianjia"></i>
										<span style="font-size: 14px; line-height: 20px">
											{{ row?.[operationConfig?.labelUUID] || row.name }}
										</span>
									</div>
								</template>
							</el-table-column>
						</el-table>
						<section class="new-file" v-if="showCreate">
							<i class="iconfont iconwenjianjia"></i>
							<el-input
								v-model="newFolderName"
								placeholder="文件名(回车创建)"
								clearable
								ref="newFileInput"
								@blur="onBlur"
								@change="onCreateFolder"
							>
							</el-input>
						</section>
					</article>
				</article>
			</article>
			<span slot="footer" class="dialog-footer">
				<section class="create-folder">
					<el-button @click="onCreate">创建文件夹</el-button>
				</section>
				<el-button @click="onClose">取 消</el-button>
				<!--  :disabled="!targetFolder" -->
				<el-button type="primary" @click="onConfirm">
					{{ actionTitle.split('').join(' ') }}
				</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem } from 'element-ui';
import { dataInterface } from '@/apis/data';
import { initParams } from '@/utils/tools';
import OutType from './OutType.vue';
import { mapState } from 'vuex';

export default {
	name: 'OperationFile',
	components: {
		'el-breadcrumb': Breadcrumb,
		'el-breadcrumb-item': BreadcrumbItem,
    OutType
	},
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		// 被移动数据
		movedData: {
			type: Array,
			required: false,
			default: () => []
		},
		// 操作类型
		type: {
			type: String,
			required: true,
			default: () => {}
		},
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => [],
    },
    componentList: {
      default: null,
    },
	},
	inject: ['element'],
	data() {
		return {
			folderList: [],
			showCreate: false,
			// 创建文件文件名
			newFolderName: '',
			// 上级文件夹ID
			parentFolderId: 0,
			// 路径列表
			movePathList: [],
			// 目标文件夹
			targetFolder: null,
      loading: true,
      // 固定参数
      fixParams: {},
		};
	},
	computed: {
    ...mapState([
      'componentData'
    ]),
		statusConfig() {
			return this.element?.statusConfig;
		},
		database() {
			return this.element?.database;
		},
		// 标题
		actionTitle() {
			if (this.type === 'move') {
				return '移动';
			}
			if (this.type === 'copy') {
				return '复制';
			}
			return '操作';
		},
    // 文件操作配置
    operationConfig() {
      return this.element?.operationConfig || {};
    },
    // 类型
    typeKey() {
      return this.operationConfig?.typeKey;
    }
	},
	created() {
		this.movePathList.push({
			label: this.statusConfig.partName, // 模块名称
			type: 'folder', // 类型 folder 文件夹， file 文件
			parentId: 0 // 父级id
		});
		this.getFolders();
	},
	methods: {
    /**
     * @desc: 获取文件列表
     */
    getFolders() {
			// TODO NET
			this.folderList = [];
			const { objectData = {}, viewData = {} } = this.database;
			if (!objectData || !viewData) {
				this.$message.error('获取数据失败!');
				return false;
			}
			const {
				param = {},
				search = [],
				canPost
			} = initParams(
				this.element?.database?.paramsConfig || [],
				this.isGroup,
				this.componentList || this.componentData,
				this.groupComponents
			);
			if (!canPost) return;
			this.loading = true;
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: objectData.uuid,
				view_uuid: this.operationConfig.fileViewUUID,
				...param,
        ...this.fixParams,
				search,
				parent_id: this.parentFolderId || 0,
				transcode: 0
			})
				.then((res) => {
					const dataList = res?.data?.data || [];
					const needIgnore = this.movedData.map(el => el.id)
					this.folderList = dataList.filter((ele) => +ele.type_id === 1 && (!needIgnore.includes(ele.id) || this.type === 'copy'));
					this.loading = false;
				})
				.catch((err) => {
					console.log(err, '---');
					this.$message.error('获取文件失败！');
					this.loading = false;
				});
    },
    /**
     * @desc: 类型对象
     * @param {Object} typeObj
     */
    onTypes(typeObj) {
      const { typeKey, idUUID } = this.operationConfig;
      this.targetFolder = typeObj;
      this.fixParams = {
        [typeKey]: typeObj?.[idUUID]
      };
      this.parentFolderId = 0;
      this.getFolders();
      this.movePathList = [];
      this.movePathList.push({
        label: this.statusConfig.partName, // 模块名称
        type: 'folder', // 类型 folder 文件夹， file 文件
        parentId: 0 // 父级id
      });
    },
		/**
		 * @desc: 进入文件夹
		 * @param {Object} file
		 */
		onInterFolder(file) {
      if (this.folderType === 'type') {
        this.parentFolderId = file?.[this.operationConfig.idUUID] || 0;
      } else {
        this.parentFolderId = file.id;
      }
			// 文件夹
			this.movePathList.push({
				label: file.name, // 模块名称
				type: 'folder', // 类型 folder 文件夹， file 文件
				parentId: file.id // 父级id
			});
			this.getFolders();
		},
		/**
		 * @desc: 创建文件夹
		 */
		onCreate() {
			this.showCreate = true;
			this.$nextTick(() => {
				this.$refs?.newFileInput?.focus();
			});
		},
		/**
		 * @desc: 创建文本输入框失焦
		 */
		onBlur() {
			this.newFolderName = '';
			this.showCreate = false;
		},
		/**
		 * @desc: 关闭前回调
		 */
		handleClose(done) {
			this.onClose();
			done();
		},
		onClose() {
			this.showCreate = false;
			this.$emit('input', false);
		},
		/**
		 * @desc: 移动/复制
		 */
		onConfirm() {
			this.$confirm(
				`是否确认${this.actionTitle}至文件夹【${
          this.targetFolder.name || this.targetFolder.label || this.targetFolder[this.operationConfig?.labelUUID] || ''
        }】？`
			).then(() => {
        const { objectData = {} } = this.database;
        if (!objectData) {
          this.$message.error('操作失败!');
          return false;
        }
        this.loading = true;
        let submitData = this.movedData;
        let param = {};
        if (this.type === 'move') {
          submitData = this.movedData.map(ele => {
            return {
              id: ele.id,
              parent_id: this.targetFolder.id || this.targetFolder.parentId,
              ...this.fixParams
            };
          });
          param = {
						object_uuid: objectData.uuid,
            __method_name__: 'batchUpdateData',
            data: submitData
          }
        } else {
					// 追加附加参数 @蒲亚军 2023.7.5  文件操作=> 附加参数传入到extra_field
					const paramObj = initParams(
						this.operationConfig?.params || [],
						this.isGroup,
						this.componentData,
						this.groupComponents
					)?.param;
					let widthParams = '';
					if (Object.prototype.toString.call(paramObj) === '[object Object]') {
						for (let key in paramObj) {
							widthParams += `${key}:${paramObj[key]};`
						}
					}
          //  复制
					const { typeKey } = this.operationConfig;
					param = {
						__method_name__: 'globalFunctionCall',
						typeName: 'PublicFunc',
						type: 'behavior',
						funcName: 'TargetDataCreate',
						payload: {
							source_object_uuid: objectData.uuid,
							source_data_ids: this.formatCopyData(this.movedData),
							source_data_children_mark: 'child_data',
							target_object_uuid: objectData.uuid,
							field_transform: 'name:name;ext:ext;file_size:file_size;hash:hash;path:path;type_id:type_id;full_data:full_data',
							extra_field: `parent_id:${this.targetFolder.id || this.targetFolder.parentId || 0};data_type_id:${this.fixParams?.[typeKey]};${widthParams}`,
							query_children_data: true // 复制的情况肯定需要查询下级，文件情况后端会处理，（注意：后端递归查询，可能层级太多会很慢）
						}
					};
        }
        dataInterface({
          ...param,
        }).then((res) => {
          this.loading = false;
          if (res.status === 200 && res.data.code=== 200) {
            this.$message.success('操作成功！');
            this.$emit('updateList');
            this.onClose();
          }
        }).catch((err) => {
          console.log(err);
          this.loading = false;
          this.$message.error('操作失败！');
        });
      }).catch(() => {});
		},
		/**
		 * @desc: 格式化复制数据
		 * @param {Array} arr
		 */
		formatCopyData(arr) {
			let result = [];
			if(Array.isArray(arr) && arr.length) {
				result = arr.map(ele => {
					const { 
						id,
						name,
						ext,
						file_size,
						hash,
						path,
						type_id,
						child_data,
						full_data
					} = ele || {};
					return {
						id,
						name,
						ext,
						file_size,
						hash,
						path,
						type_id,
						full_data,
						child_data: this.formatCopyData(child_data)
					};
				});
			} 
			return result;
		},
		/**
		 * @desc: 创建文件夹
		 * @param {String} val
		 */
		onCreateFolder(name) {
			setTimeout(() => {
				if (this.showCreate && name && name.trim()) {
					const { objectData = {} } = this.database;
					if (!objectData) {
						this.$message.error('操作失败！');
						return;
					}
					this.loading = true;
					const { param } = initParams(
						this.operationConfig?.params || [],
						this.isGroup,
						this.componentData,
						this.groupComponents
					);
					dataInterface({
						__method_name__: 'createData',
						name,
						type_id: 1,
						object_uuid: objectData.uuid,
						parent_id: this.parentFolderId || 0,
            ...this.fixParams,
						...param
					}).then((res) => {
							console.log(res);
							// this.$message.success('操作成功！');
							this.showCreate = false;
							this.getFolders();
						}).catch((err) => {
							console.log(err);
							this.$message.error('操作失败！');
							this.loading = false;
						});
				}
			}, 0);
		},
		/**
		 * @desc: 选择文件夹
		 * @param {Object} row 行数据
		 */
		onTabRow(row) {
			this.targetFolder = row;
		},
		/**
		 * @desc: 点击面包屑
		 */
		onPath(bread) {
			if (!bread || this.parentFolderId === bread.parentId) {
				return;
			}
			const index = this.movePathList.findIndex((ele) => ele.parentId === bread.parentId);
			if (index !== -1) {
				this.targetFolder = this.movePathList[index];
				this.movePathList = this.movePathList.slice(0, index + 1);
				this.parentFolderId = bread.parentId;
				this.getFolders();
			}
		},
		/**
		 * @desc: 返回上一级
		 */
		onBackPath() {
			if (!this.movePathList || this.movePathList.length < 2) {
				this.$message.warning('已到最顶级！');
				return false;
			}
			const parentFolder = this.movePathList[this.movePathList.length - 2];
			if (!parentFolder) {
				this.$message.error('操作失败！');
				return false;
			}
			this.movePathList.pop();
			this.parentFolderId = parentFolder.parentId;
			this.targetFolder = parentFolder.parentId;
			this.getFolders();
		}
	}
};
</script>

<style lang="less" scoped>
:deep(.el-table) {
	&::before {
		height: 0;
	}
	td.el-table__cell,
	.el-table th.el-table__cell.is-leaf {
		border: none;
	}
	&.el-table--small tbody .el-table__cell {
		height: 39px !important;
	}
}
:deep(.el-breadcrumb) {
	margin-left: 10px;
	flex: 1;
	.el-breadcrumb__item {
		height: 22px;
		line-height: 22px;
		span {
			color: #000;
			font-weight: bold;
		}
		&:last-of-type {
			span {
				color: #606266;
				font-weight: 400;
			}
		}
	}
	.el-breadcrumb__separator {
		margin: 0 4px;
	}
}
</style>
<style lang="less">
.operation-file-dialog {
	.el-dialog__header {
		text-align: left;
		border-bottom: 1px solid #f5f5f5;
		.el-dialog__title {
			font-size: 14px;
			font-weight: bold;
		}
	}
	.el-dialog__body {
		padding: 12px 16px;
	}
	.iconxiangzuojiantou1 {
		&.disabled {
			color: #c0c4cc;
		}
	}
	.move-main {
		width: 100%;
		height: 580px;
		.header {
			width: 100%;
			.path {
				width: 100%;
				display: flex;
				i {
					line-height: 22px;
					height: 22px;
					width: 22px;
					box-sizing: border-box;
					border: 1px solid #f5f5f5;
					text-align: center;
					font-size: 16px;
					&:first-of-type {
						margin-right: 10px;
					}
				}
			}
		}
		.move-contents {
			padding: 10px 0;
			box-sizing: border-box;
			height: 100%;
			display: flex;
			.folder-list {
				flex: 1;
				box-sizing: border-box;
				.iconfont {
					margin-right: 5px;
					font-size: 20px;
					line-height: 20px;
				}
				.iconwenjianjia {
					color: rgb(249, 194, 10);
				}
			}
		}
	}
	.new-file {
		text-align: left;
		.el-input {
			width: 200px;
		}
	}
	.dialog-footer {
		display: flex;
		justify-content: space-between;
		.create-folder {
			flex: 1;
			text-align: left;
			box-sizing: border-box;
			padding-right: 20px;
		}
	}
}
</style>
