var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.skeleton)?_c('div',{staticClass:"skeleton"},[_c('skeleton')],1):_c('div',[_c('el-form',{ref:"formParser",class:{ 'form-parser': _vm.tableStyle, 'normal-parser': !_vm.tableStyle },attrs:{"size":"medium","model":_vm.formValidate,"show-message":!_vm.tableStyle,"label-width":_vm.formData.labelWidth + 'px',"label-position":_vm.formData.labelPosition},nativeOn:{"submit":function($event){$event.preventDefault();}}},[(_vm.formLabel)?_c('div',{staticClass:"type-label"},[_vm._v(_vm._s(_vm.formLabel))]):_vm._e(),_c('el-row',{attrs:{"gutter":_vm.gutter}},_vm._l((_vm.formValidate.items),function(item,index){return _c('el-col',{key:index,attrs:{"span":item.config.__config__.span}},[(
              ['radio', 'switch', 'select'].indexOf(item.type) > -1 &&
              !_vm.showHideField(item.config.__config__, item)
            )?[_vm._v(" "+_vm._s(_vm.initTrigger(item))+" ")]:_vm._e(),(
              ['row', 'table', 'tab', 'steps', 'text'].includes(item.type) &&
              _vm.showHideField(item.config.__config__, item)
            )?[_c('InputItem',{ref:"childParser",refInFor:true,attrs:{"element":item,"disabled":_vm.canEditField(item),"editFields":_vm.editFields,"isConsole":_vm.isConsole,"formData":_vm.formConf,"displayData":_vm.displayData,"contentConfig":_vm.contentConfig,"dataIds":_vm.dataIds,"formMode":_vm.formMode},on:{"trigger-active":function($event){return _vm.triggerActive(arguments)},"auto-fill-relate":_vm.selectAutoFillRelate}})]:(
              item.type === 'form' && _vm.showHideField(item.config.__config__)
            )?[_c('el-form-item',{staticClass:"no-padding",attrs:{"prop":'items.' + index + '.value',"label":'',"rules":item.validate,"label-width":'0px'}},[_c('InputItem',{ref:"childParser",refInFor:true,attrs:{"element":item,"disabled":_vm.canEditField(item),"editFields":_vm.editFields,"formData":_vm.formConf,"displayData":_vm.displayData,"contentConfig":_vm.contentConfig,"dataIds":_vm.dataIds,"formMode":_vm.formMode},on:{"trigger-active":function($event){return _vm.triggerActive(arguments)},"auto-fill-relate":_vm.selectAutoFillRelate}})],1)]:(_vm.showHideField(item.config.__config__, item))?[_c('el-form-item',{staticClass:"no-padding",attrs:{"prop":'items.' + index + '.value',"label":item.config.__ques__ ? '' : item.config.__config__.label,"rules":item.validate,"label-width":_vm.labelWidth(item)}},[_c('InputItem',{ref:"childParser",refInFor:true,attrs:{"element":item,"isConsole":_vm.isConsole,"disabled":_vm.canEditField(item),"editFields":_vm.editFields,"formData":_vm.formConf,"displayData":_vm.displayData,"contentConfig":_vm.contentConfig,"dataIds":_vm.dataIds,"formMode":_vm.formMode},on:{"trigger-active":function($event){return _vm.triggerActive(arguments)},"auto-fill-relate":_vm.selectAutoFillRelate}})],1)]:_vm._e()],2)}),1),_c('div',{staticClass:"parser-tp"},[_vm._t("other")],2),_c('div',{staticClass:"parser-tp"},[(_vm.needFlow)?[(_vm.formMode === 'add')?[_c('FlowPicker',{attrs:{"type":"self","module":_vm.moduleUuid,"flow-edit":_vm.editFieldsValue},on:{"checked-users":_vm.dealWithPicker,"selected-step":_vm.getSelectStep}})]:(_vm.formMode === 'detail')?[(Object.keys(_vm.displayData).length)?_c('FlowRecover',{attrs:{"canvas-data":_vm.displayData.recover,"logs":_vm.displayData.logs,"next-step":_vm.displayData.nextStep}}):_vm._e(),(
                Object.keys(_vm.displayData).length &&
                _vm.displayData.btn &&
                _vm.displayData.btn.length
              )?_c('FlowOperate',{attrs:{"edit-data":_vm.displayData,"flow-edit":_vm.editFieldsValue},on:{"change-end":_vm.onRefresh}}):_vm._e()]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"parser-tp"},[_vm._t("button")],2),(!_vm.hideBtn)?_c('div',{staticStyle:{"text-align":"right","margin-top":"15px"}},[_c('el-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"default"},on:{"click":_vm.handleReset}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }