<!--
 * @Description: 大屏容器
 * @Author: luocheng
 * @Date: 2022-05-06 15:53:49
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-18 11:59:48
 * @LastEditTime: 2022-09-08 13:40:13
-->
<template>
	<div  class="common-screen-container">
		<!-- eslint-disable -->
		<template v-for="(item, index) in componentList">
			<AnimateCom  :key="item.id"  :animations="item.animations">
				<ShapeBox
					:key="item.id"
					:index="index"
					:defaultStyle="item.style || {}"
					:element="item"
					:style="getBoxStyle(item.style, item.specialStyle.fixedSize,  false, item)"
					v-if="
						item.statusConfig &&
						item.statusConfig.isShow &&
						judgingArchiAPermission(item.archiLimit, item.authConfig && item.authConfig.list) &&
						judgingUILogic(item.UILogic, customComponentList)
					"
					v-show="item.statusConfig && (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)"
					@click.native.stop="onClickBox(item, 'click')"
					@dblclick.native.stop="onDblClickBox(item)"
					@mouseleave.native.stop="onClickBox(item, 'mouseleave')"
					@mouseenter.native.stop="onClickBox(item, 'mouseenter')"
				>
				<!-- v-show="item.statusConfig && (item.statusConfig.displayShow || item.statusConfig.displayShow === undefined)" -->
					<component
						:is="item.component"
						:index="index"
						:element="item"
						:id="'component' + item.id"
						:style="getComStyle(item.style, item.specialStyle.fixedSize, item)"
						:propValue="item.propValue"
						:scaleHeight="+scaleHeight"
						:scaleWidth="+scaleWidth"
						:isPreview="isPreview"
						:ratio="+ratio"
						:containerData="{}"
					>
					</component>
				</ShapeBox>
			</AnimateCom>
		</template>
	</div>
</template>

<script>
import { getStyle } from '@/utils/style';
import { judgingEventLogic, getStatusVal, getComponentById, judgingUILogic, judgingArchiAPermission, getLinkByTemplate, doEEActionHandle, getQueryValue, generateQuery, openUrl } from '@/utils/tools';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data';
import AnimateCom from '@/components/parser/screen/AnimateCom.vue';

// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const customComponents = {};
const specialName = ['CommonGridContainer','CommonGridContainer','ConsoleSignCont']
componentsList.keys().forEach(fileName => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	customComponents[name] = componentsList(fileName).default;
});
//中控台组件
const componentsConsole = require.context('@/console-components', true, /\.+vue$/);
componentsConsole.keys().forEach(fileName => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	if(specialName.includes(name)) {
		setTimeout(()=>{
			customComponents[name] = componentsConsole(fileName).default;
		},0)
	}else{
		customComponents[name] = componentsConsole(fileName).default;
	}
});

export default {
	name: 'CommonScreenContainer',
	components: {
		...customComponents,
		AnimateCom
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: false
		},
		scaleHeight: {
			type: Number,
			default: 1
		},
		scaleWidth: {
			type: Number,
			default: 1
		},
		ratio: {
			type: Number,
			default: 1
		}
	},
	inject: ['EDITOR_pageUUID'],
	data() {
		return {
			timer: null
		};
	},
	computed: {
		...mapState([
			'componentData',
			'sContainer',
			'originComponentData', // 大屏容器组件的元组件
			'subsidiaryComponentData'
		]),
		componentList() {
			return this.element?.children || [];
		},
		// 父组件配置时候的尺寸位置信息
		childPositionInfo() {
			return (
				this.element?.childPositionInfo || {
					top: 0,
					left: 0,
					right: 0,
					bottom: 0
				}
			);
		},
		// Y额面列表
		customComponentList() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentData || [];
			}
			return this.componentData || [];
		}
	},
	methods: {
		judgingArchiAPermission,
		judgingUILogic,
		/**
		 * @desc: 根据图层的样式对象获取可渲染的组件样式
		 * @param {Object/null} styleObj
		 * @return {Object} 壳渲染组件
		 */
		// w 1861
		// h 897
		// Y 162
		// X 32
		getComStyle(styleObj) {
			if (!styleObj || !styleObj.backgroundColor) return {};
			let excludesArr = [
				'top',
				'left',
				'rotate',
				'height',
				'width',
				'fontSize',
				'lineHeight',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'paddingTop',
				'paddingBottom',
				'paddingLeft',
				'paddingRight',
				'borderRadius',
				'borderWidth'
			];
			const heightArr = ['height', 'lineHeight', 'borderWidth'];
			const widthArr = [
				'marginTop',
				'marginBottom',
				'marginLeft',
				'marginRight',
				'paddingTop',
				'paddingBottom',
				'paddingLeft',
				'paddingRight',
				'borderRadius',
				'borderWidth'
			];
			let styleResult = getStyle(styleObj, excludesArr);
			excludesArr.forEach(ele => {
				let val = styleObj[ele] || 0;
				if (ele !== 'rotate') {
					if (heightArr.includes(ele)) {
						val = `${val * this.scaleHeight}px`;
					} else if (widthArr.includes(ele)) {
						val = `${val * this.scaleWidth}px`;
					} else if (ele === 'fontSize') {
						val = `${val * this.scaleWidth}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${val * this.scaleWidth}px`;
						}
					}
				} else {
					val = styleObj[ele] && styleObj[ele] !== '0' ? 'rotate(' + styleObj[ele] + 'deg)' : '';
				}
				styleResult = {
					...styleResult,
					[ele]: val
				};
			});
			return {
				...styleResult,
				background: `${styleResult.backgroundColor || styleResult.background}!important`,
				top: 0,
				left: 0
			};
		},
		/**
		 * @desc: 获取组件盒子的样式
		 * @param {Object} style 组件样式
		 * @return {Object} 格式化后样式对象
		 */
		getBoxStyle(styleObj, fixedSize, isLayout, item) {
			if (isLayout) {
				// 布局样式
				return this.getComStyle(styleObj, isLayout);
			}
			let result = {};
			const  coefficientHeight = (+fixedSize || this.scaleHeight);
			const  coefficientWidth = (+fixedSize || this.scaleWidth);
			['width', 'height', 'top', 'left', 'rotate'].forEach(ele => {
				let val = styleObj[ele] || 0;
				if (ele !== 'rotate') {
					if (ele === 'top') {
						if (!isNaN(+val)) {
							val = `${((val - (this.childPositionInfo.top)) * coefficientHeight).toFixed(2)}px`;
						}
					} else if (ele === 'left') {
						if (!isNaN(+val)) {
							val = `${((val - this.childPositionInfo.left) * coefficientWidth).toFixed(2)}px`;
						}
					} else if (ele === 'height') {
						if (!isNaN(+val)) {
							val = `${(val * coefficientHeight).toFixed(2)}px`;
						}
					} else {
						if (!isNaN(+val)) {
							val = `${(val * coefficientWidth).toFixed(2)}px`;
						}
					}
					result[ele] = val;
				} else {
					result.transform = styleObj[ele] && styleObj[ele] !== '0' ? 'rotate(' + styleObj[ele] + 'deg)' : '';
				}
			});
			if (item.specialStyle?.useZIndex) {
				result = {
					...result,
					zIndex: item.specialStyle?.zIndex ?? 'inherit'
				}
			}
			return result;
		},
		/**
		 * @desc: 触发点击事件
		 * @param {Object} element 点击的组件
		 *  触发方式实质就是在改变指定的组件状态
		 */
		onClickBox(element, eventType) {
			window.$EditorDebug.startEvent(element);
			if (!element.events || !element.events[eventType] || !element.events[eventType].effects) {
				return;
			}
			if (this.timer) {
				clearTimeout(this.timer);
			}
			const comEvents = element?.events?.[eventType] || {};
			const { pattern, eventList = [], specialEventList = [] } = comEvents;
			if (element.events.dblclick) {
				this.timer = setTimeout(() => {
					if (!pattern) {
						this.doActionItem(comEvents, element);
					} else {
						const result = pattern === 'special' ? specialEventList : eventList;
						result.forEach(ele => {
							this.doActionItem(ele, element);
						});
					}
				}, 300);
			} else {
				if (!pattern) {
					this.doActionItem(comEvents, element);
				} else {
					const result = pattern === 'special' ? specialEventList : eventList;
					result.forEach(ele => {
						this.doActionItem(ele, element);
					});
				}
			}
		},
		/**
		 * @description: 双击事件
		 * @param {Object} element
		 */
		onDblClickBox(element) {
			window.$EditorDebug.startEvent(element, true);
			if (!element.events || !element.events.dblclick || !element.events.dblclick.effects) {
				return;
			}
			if (this.timer) {
				clearTimeout(this.timer);
			}
			const comEvents = element?.events?.dblclick || {};
			const { pattern, eventList = [], specialEventList = [] } = comEvents;
			if (!pattern) {
				this.doActionItem(comEvents, element);
			} else {
				const result = pattern === 'special' ? specialEventList : eventList;
				result.forEach(ele => {
					this.doActionItem(ele, element);
				});
			}
		},
		/**
		 * @description: 触发单个操作
		 * @param {Object} item 单个操作配置
		 */
		doActionItem(eventConfig, element) {
			const { actionType, linkType, queryList = [], linkUrl = '', pageId, effects, linkTemplate } = eventConfig;
			if (actionType === 'pageStatus') {
				// 修改页面状态
				this.updatePageStatus(eventConfig.stateMachine || []);
			} else if (actionType === 'jumpPage') {
				const query = {};
				queryList.forEach(queryItem => {
					let component = getComponentById(this.customComponentList, queryItem.componentId);
					this.$set(query, queryItem.key, component?.resolveData[queryItem.feild]);
				});
				const queryStr = generateQuery(query);
				if (linkType === 'projectPage') {
					this.$router.push({
						path: pageId,
						query
					});
					window.$EditorDebug.jumpPage(1, pageId, query);
					return;
				} else if (linkType === 'outPage') {
					window.$EditorDebug.jumpPage(2, linkUrl);
          openUrl(linkUrl,linkUrl)
					// window.open(linkUrl);
				} else if (linkType === 'custom') {
					// 暂未处理
					// if (!this.mapData?.renderData) return;
          const customLink = getLinkByTemplate(linkTemplate, this.mapData?.renderData || {})
					window.$EditorDebug.jumpPage(3, customLink);
          openUrl(`${customLink}${queryStr}`,`${customLink}${queryStr}`)
          // window.open(`${customLink}${queryStr}`);
				}
			} else if (actionType === 'eeAction') {
				// 触发后端事件 暂未处理
				// console.log(ele, '0000001111111111', output);
				// this.doEEAction(element, output, type);
				// 搜集参数
				this.doEEAction(eventConfig, element);
			} else {
				effects.forEach(ele => {
					this.$store.commit('triggerEvents', {
						config: ele,
						element: this.element,
						EDITOR_pageUUID: this.EDITOR_pageUUID
					});
				});
			}
			// 页面行为
			const { behaviors = [] } = eventConfig;
			behaviors.forEach(behavior => {
				if (behaviors.length === 1) {
          const { component, list } = behaviors[0];
          if (!component) {
            return;
          }
          if (list.length === 1) {
            const behaviorsList = list[0].behaviors;
            if (!behaviorsList.length) {
              return;
            }
          }
        }
				if (behavior.component) {
					this.$store.commit('triggerEvents', {
						config: {
							behavior,
							isBehavior: true
						},
						element,
						EDITOR_pageUUID: this.EDITOR_pageUUID
					});
				}
			});
		},
		/**
		 * @description: 触发后端事件
		 * @return {*}
		 */
		doEEAction(ele, element) {
			// 触发后端事件
			window.$EditorDebug.startEEAction(element);
			const { objectUUID, viewUUID, eventName, eeType, interfaceUrl } = ele;
			if ((!objectUUID || !viewUUID || !eventName) && !interfaceUrl) {
				this.$message.error('事件配置错误！');
				window.$EditorDebug.failEEAction(element);
				return false;
			}
			this.loading = true;
			const sourceParams = this.getBindParams(element);
			// 蓝图
			if (eeType === 'blueprint') {
				dataInterface(sourceParams, `/api${interfaceUrl}`).then(res => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						window.$EditorDebug.successEEAction(element, `/api${interfaceUrl}`);
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				}).catch(() => {
					this.loading = false;
					window.$EditorDebug.errorEEAction(element, `/api${interfaceUrl}`);
				})
				return true;
			}
			// 数仓
			const data_id = sourceParams?.data_id;
			dataInterface({
				__method_name__: 'customEventCall',
				object_uuid: objectUUID,
				view_uuid: viewUUID,
				...sourceParams,
				data_id, // 参数配置
				event: eventName
			})
				.then(res => {
					if (res.status === 200 && res.data.code === 200) {
						this.$message.success('操作成功！');
						window.$EditorDebug.successEEAction(element, eventName);
						doEEActionHandle(res.data?.__adds__);
					}
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
					window.$EditorDebug.errorEEAction(element, eventName);
				});
			return true;
		},
    /**
     * @desc: 后端事件获取绑定参数
     * @param {Object} comp 组件数据
     * @return {Object}
     */
		getBindParams(comp) {
			if (!comp) {
        window.$EditorDebug.descEEActionParam(1);
        return {};
      }
      window.$EditorDebug.descEEActionParam(2);
      const sourceConfig = comp.sourceConfig || [];
      const sourceParams = {};
      for (let i = 0; i < sourceConfig.length; i++) {
        const {
          componentId,
          field,
          key,
          originType = '',
          urlParamKey = '',
          systemKey = '',
          systemCode = '',
          fixedValue = '',
          statusCode = ''
        } = sourceConfig[i];
        if (originType === 'url' && urlParamKey) {
          // 从url获取参数
          const result = getQueryValue(urlParamKey);
          this.$set(sourceParams, key, result);
        } else if (originType === 'system') {
          // 系统参数
          try {
            let obj = sessionStorage.getItem(systemKey);
            if (!obj) {
              obj = localStorage.getItem(systemKey);
            }
            if (!obj) break;
            const result = JSON.parse(obj);
            if (result && Object.prototype.toString.call(result) === '[object Object]') {
              const queryVal = result[systemCode];
              this.$set(sourceParams, key, queryVal);
            }
          } catch (err) {
            console.log(err, '99999999');
          }
        } else if (originType === 'fixed') {
          // 固定值
          this.$set(sourceParams, key, fixedValue);
        } else if (originType === 'pageStatus') {
          // 页面状态
          const statusCodeValue = this._PageCustomStatus[statusCode] === undefined ? (this._APPCustomStatus[statusCode] || null) : this._PageCustomStatus[statusCode];
          this.$set(sourceParams, key, statusCodeValue || '');
        } else if (componentId && field && key) {
          // 普通从组件获取
          let sourceComponent = getComponentById(this.subComponentData, componentId);
          if (!sourceComponent && this.isGroup && this.groupComponents.length) {
            sourceComponent = getComponentById(this.groupComponents, componentId);
          }
          const componentName = componentId.toString().split('-')[0];
          if (field === 'DATAVIEWSELECT' && ['CommonTree', 'CommonTableContainer', 'CommonLoopContainer'].includes(componentName)) {
            this.$set(sourceParams, key, sourceComponent?.resolveData || []);
          } else {
            const result = sourceComponent?.resolveData?.[field] || '';
            this.$set(sourceParams, key, result);
          }
        }
      }
			window.$EditorDebug.successEEActionParam(sourceParams, comp.sourceConfig);
      return sourceParams;
    },
		/**
		 * @desc: 更新页面状态
		 * @param {Array} arr 页面状态列表
		 */
		updatePageStatus(arr) {
			window.$EditorDebug.startPageStatus(null);
			let componentData = this.sContainer ? this.originComponentData : this.componentList;
			arr.forEach(ele => {
				if (!judgingEventLogic(ele.logics, componentData)) {
					window.$EditorDebug.failPageStatus(null, ele?.code);
					return;
				}
				const { value } = getStatusVal(ele, componentData);
				this.$store.commit('updatePageCustomStatus', {
					key: ele.code,
					value
				});
			});
		},
		/**
		 * @desc: 获取UI判断逻辑(已弃用！暂保留)
		 */
		getUILogicStatus(item) {
			const { UILogic } = item;
			if (!UILogic || !UILogic.enable) return true;
			const { logicConfigs } = UILogic;
			const logicList = [];
			for (let i = 0; i < logicConfigs.length; i++) {
				const item = logicConfigs[i];
				const { dataAttr, field, itemLogic, logicRule, type, value, valueType } = item
				// 逻辑关系
				let itemBoolean = true;
				let logicValue = null;
				if (type === 'userInfo') {
					// 用户信息
					const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
					logicValue = userInfo && userInfo[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				} else if (type === 'framework') {
					// 架构信息
					const targetArchi = localStorage.getItem('targetArchi') && JSON.parse(localStorage.getItem('targetArchi'));
					logicValue = targetArchi && targetArchi[field];
					if (dataAttr === 'length') {
						logicValue = logicValue.length;
					}
				}
				// 逻辑关系
				if (logicRule === '===') {
					if (valueType === 'string') {
						itemBoolean = `${logicValue}` === `${value}`;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue === +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() === value;
					}
				} else if (logicRule === '!=') {
					if (valueType === 'string') {
						itemBoolean = `${logicValue}` !== `${value}`;
					} else if (valueType === 'number') {
						itemBoolean = +logicValue !== +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() !== value;
					}
				} else if (logicRule === '>') {
					if (valueType === 'number') {
						itemBoolean = +logicValue > +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() > value;
					}
				} else if (logicRule === '<') {
					if (valueType === 'number') {
						itemBoolean = +logicValue < +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() < value;
					}
				} else if (logicRule === '<=') {
					if (valueType === 'number') {
						itemBoolean = +logicValue <= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() <= value;
					}
				} else if (logicRule === '>=') {
					if (valueType === 'number') {
						itemBoolean = +logicValue >= +value;
					} else if (valueType === 'date') {
						itemBoolean = new Date(logicValue).getTime() >= value;
					}
				} else if (logicRule === 'like') {
					if (valueType === 'string') {
						itemBoolean = logicValue.toString()?.includes(value.toString());
					}
				} else if (logicRule === 'whereIn') {
					// 包含
					try {
						let valArr = [];
						if (Array.isArray(logicValue)) {
							valArr = logicValue;
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (logicValue.toString()?.startsWith('[') && logicValue.toString()?.endsWith(']')) {
							valArr = JSON.parse(logicValue);
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						} else if (value.toString()?.startsWith('[') && value.toString()?.endsWith(']')) {
							valArr = JSON.parse(value);
							itemBoolean = valArr.includes(logicValue) || valArr.includes(+logicValue);
						} else {
							itemBoolean = valArr.includes(value) || valArr.includes(+value);
						}
					} catch (err) {
						console.log(err);
					}
				}
				logicList.push({
					itemBoolean,
					itemLogic
				});
			}
			if (logicList.length === 1) {
				return logicList[0].itemBoolean;
			}
			// 组合逻辑判断
			let prevLogicBoolean = null;
			for (let j = 1; j < logicList.length; j++) {
				const prev = logicList[j - 1];
				const prevItemLogic = prev.itemLogic;
				if (prevItemLogic === 'and') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean && logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean && logicList[j].itemBoolean;
					}
					if (!prevLogicBoolean) {
						return false;
					}
				} else if (prevItemLogic === 'or') {
					if (j === 1) {
						prevLogicBoolean = prev.itemBoolean || logicList[j].itemBoolean
					} else {
						prevLogicBoolean = prevLogicBoolean || logicList[j].itemBoolean
					}
					if (!prevLogicBoolean) {
						return false;
					}
				}
			}
			return true;
		}
	}
};
</script>

<style lang="less" scoped>
.common-screen-container {
	height: 100%;
	width: 100%;
	position: absolute;
}
</style>
