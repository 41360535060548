<!--
 * @Author: zyf
 * @Date: 2024-08-12 09:42:25
 * @LastEditors: zyf
 * @LastEditTime: 2024-08-19 09:35:05
 * @Descripttion: 
-->
<!--
    @name: ques-textarea
    @description：ques-textarea
    @author: ZengWei
    @date: 2022-04-01 11:19
-->
<template>
  <div class="widget-textarea">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="sub-ques-type">
        简答题
      </div>
      <div class="q-title">
        <span class="ques-title">{{ element.config.__config__.label }}</span>
        <!-- <span class="view-resources">查看题干资源</span> -->
        <!-- <span v-if="!element.config.__config__.required" class="select-do">（选做题）</span> -->
        <span v-if="element.config.__ques__.answerScore" class="score">（{{element.config.__ques__.score}}分）</span>
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-input v-model="element.value" type="textarea" :disabled="element.disabled" :rows="6" placeholder="请输入"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {Input} from 'element-ui'

export default {
  name: "ques-textarea",
  components: {
    'el-input':Input
  },
  props: ['element','number'],
  data() {
    return {}
  },
  methods: {
    calcScore(value){
      const config = this.element.config.__ques__
      const keyword = config.answer.split(';'); //答案关键词
      let score = 0
      if(config.scoreType === 1 && config.scoreTypeTwo === 1){
        // 包含关键词得分，答出几个得几分
        for (const item of keyword){
          if(value.indexOf(item)){
            score += parseFloat(config.optScore)
          }
        }
      } else if(config.scoreType === 1 && config.scoreTypeTwo === 2){
        // 包含关键词得分，答出全部得分
        let number = 0
        for (const item of keyword){
          if(value.indexOf(item)){
            number++
          }
        }
        if(number === keyword.length) {
          score = config.score
        }
      } else if(config.scoreType === 2){
        // 完全相同得分，答出几个得几分
        if(value === config.answer){
          score = config.score
        }
      }
      this.element.score = score
      this.$emit('change')
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/safetyQuestionItem';
.q-option-list{
  width: 100%;
  padding-top: 12px;
  overflow: hidden;
  box-sizing: border-box;
}
</style>
