<template>
    <LayerSingle
            ref="layerTree"
            class="moduleWrap"
            :element.sync="element"
            :bindViewerId="bindViewerId"
            @createFile="onCreateFile"
            @saveRow="onSaveRow"
            @renameRow="onRenameRow"
            @deleteRow="onDeleteRow"
            @clickCurrent="onClickCurrent"
            @locationNode="onLocationNode"
            @checkNode="onCheckNode"
    >
    </LayerSingle>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import { dataInterface } from '@/apis/data/index';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import LayerSingle from "@/custom-component/viewerComponent/LayerSingle";

    const DataBaseInfo = {
        Group: {
            object_uuid: 'object643758ceb0fce',
            view_uuid: {
                all: 'view64375e6e75e1d',
            }
        },
        Child: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        }
    };

    export default {
        name: "ViewerGISLayer",
        components: {
            LayerSingle
        },
        data() {
            return {
                isLoadData: false,
                bindViewerId: null,
                isViewerInited: false,

                compInitedFlag: false
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted(){
            this.initEventListener();
            this.initEditorListener();
            this.initViewerListener();

            if (this.element.viewerBindId) {
                if (this.checkBind(this.element.viewerBindId)) {
                    this.bindViewerId = this.element.viewerBindId;
                }
            }

            if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && this.bindViewerId){
                eventBus.$emit("ViewerGIS.getViewerInitedFlag", this.bindViewerId, async ({viewerInited = false, dynamicLoaded = false}) => {
                    if(!viewerInited || !dynamicLoaded){
                        this.initViewerInitedEventListener();
                    }

                    if(viewerInited && dynamicLoaded && !this.isLoadData){
                        this.isLoadData = true;
                        await this.getMapData(this.bindViewerId);
                        await this.getGISData(this.bindViewerId);
                        this.getModelData(this.bindViewerId);
                        this.isLoadData = false;
                    }
                });
            }else{
                this.initViewerInitedEventListener();
            }
        },
        methods: {
            initEventListener() {
                eventBus.$on('ViewerGISLayer.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== 'ViewerGIS') {
                                if (item.children && item.children.length > 0)
                                    loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId
                    });
                });

                eventBus.$on('ViewerGISLayer.BindViewer', (eid, bindId) => {
                    if (eid !== this.element.id) return;
                    if (!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);

                    this.getTreeData();
                });

                eventBus.$on('ViewerGISLayer.getCompInitedFlag', (eid , cb) => {
                    if( eid !== this.element.id) return;

                    cb && cb(this.compInitedFlag);
                });

                eventBus.$on("ViewerGISLayer.onGISShow", (eid, checked) => {
                    if (eid !== this.element.id) return;
                    if(!checked) return;

                    let {nodes} = this.$refs.layerTree.getCheckedNodes('gis');
                    for (let item of nodes){
                        if(item.module !== 'gis' || item.type !=='leaf') continue;

                        if(!checked.includes(item.key)){
                            this.onCheckNode(item, [item], false);
                            this.$refs.layerTree.setTreeNodeCheck([item.id], false);
                        }
                    }

                    let gisData = this.$refs.layerTree.getNode(`gis`);
                    let leafList = [];
                    let keys = [];
                    for (let item of checked){
                        let data = this.$refs.layerTree.getNode(`gis_leaf_${item}`);
                        if(!data) continue;

                        leafList.push(data.data);
                        keys.push(`gis_leaf_${item}`);
                    }

                    this.onCheckNode(gisData.data, leafList, true);
                    this.$refs.layerTree.setTreeNodeCheck(keys, true);
                });

                eventBus.$on("ViewerGISLayer.onModelShow", (eid, checked) => {
                    if (eid !== this.element.id) return;
                    if(!checked) return;

                    let {nodes} = this.$refs.layerTree.getCheckedNodes('model');
                    for (let item of nodes){
                        if(item.module !== 'model' || item.type !=='leaf') continue;

                        if(!checked.includes(item.key)){
                            this.onCheckNode(item, [item], false);
                            this.$refs.layerTree.setTreeNodeCheck([item.id], false);
                        }
                    }

                    let modelData = this.$refs.layerTree.getNode(`model`);
                    let leafList = [];
                    let keys = [];
                    for (let item of checked){
                        let data = this.$refs.layerTree.getNode(`model_leaf_${item}`);
                        if(!data) continue;

                        leafList.push(data.data);
                        keys.push(`model_leaf_${item}`);
                    }

                    this.onCheckNode(modelData.data, leafList, true);
                    this.$refs.layerTree.setTreeNodeCheck(keys, true);
                });
            },
            initEditorListener() {
                eventBus.$on("ViewerGISLayer.onEditorAdd", (eid, type, data) => {
                    if (eid !== this.element.id) return;

                    switch (type) {
                        case 'switchImagery':
                            this.onSwitchImageryRow(data);
                            break;
                        case 'addImagery':
                            this.onAddImageryRow(data);
                            break;
                        case 'addTerrain':
                            this.onChangeTerrainRow(data, true);
                            break;
                        case 'deleteTerrain':
                            this.onChangeTerrainRow(data, false);
                            break;
                        case 'addEntity':
                            this.onAddEntityRow(data);
                            break;
                    }
                });
            },
            initViewerListener() {
                //Viewer初始化及模型树组件初始化后，监听模型加载事件
                eventBus.$on("ViewerGIS.onModelLoaded", (eid) => {
                    if (eid !== this.bindViewerId) return;
                    if(!this.isViewerInited) return;

                    this.getModelData(this.bindViewerId);
                });

                //Viewer初始化及模型树组件初始化后，监听模型显示隐藏事件
                eventBus.$on("ViewerGIS.onShowModel", (eid, model_id, isShow) => {
                    if (eid !== this.bindViewerId) return;
                    if(!this.isViewerInited) return;

                    this.$refs.layerTree.setTreeNodeCheck([`model_leaf_${model_id}`], isShow);
                    this.getNodeShow();
                });
            },
            initViewerInitedEventListener(){
                eventBus.$on("ViewerGIS.onViewerInited", async (eid, opt = {}) => {
                    if (eid !== this.bindViewerId) return;

                    let {viewerInited = false, dynamicLoaded = false} = opt;
                    if(viewerInited && dynamicLoaded && !this.isLoadData){
                        this.isLoadData = true;
                        await this.getMapData(this.bindViewerId);
                        await this.getGISData(this.bindViewerId);
                        this.getModelData(this.bindViewerId);
                        this.isLoadData = false;
                    }
                });
            },
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },
            checkInited(){
                if(!this.compInitedFlag) {
                    this.compInitedFlag = true;
                    eventBus.$emit('ViewerGISLayer.onCompInited', this.element.id);
                    this.getNodeShow();
                }
            },

            getDataSearch(type = 'GISLayer') {
                const archi_id = this.$GetTargetArchi('id');

                let search = [
                    {
                        code: 'archi_type',
                        ruleType: 'eq',
                        value: archi_id
                    },
                    {
                        code: 'type',
                        ruleType: 'eq',
                        value: type
                    }
                ];

                return search;
            },
            getNodeShow(){
                let gis = [];
                let model = [];
                let {nodes} = this.$refs.layerTree.getCheckedNodes();
                for (let item of nodes){
                    if(item.module === 'gis' && item.type ==='leaf') {
                        gis.push(item.key);
                    }else if(item.module === 'model' && item.type ==='leaf') {
                        model.push(item.key);
                    }
                }

                eventBus.$emit('ViewerGISViewPoint.GetLayerStatus', this.element.id, {gis, model});
            },

            async getMapData(bindId){
                if(!bindId) return;

                eventBus.$emit("ViewerGIS.getImageryLayer", this.bindViewerId, ({type, name}) => {
                    this.$refs.layerTree.setTreeNodeProps('imagery', {name, tag: type});
                    this.$refs.layerTree.setTreeNodeCheck(['imagery'], true);
                });

                const mapRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch('GISImagery')
                });
                if (mapRes?.data?.data && mapRes?.data?.data?.data && mapRes.status === 200) {
                    const childList = mapRes?.data?.data?.data;

                    for (let {id, name, group_id, state} of childList) {
                        let item = {
                            id: `mapData_leaf_${id}`,
                            key: id,
                            name: name,
                            type: 'leaf',
                            module: 'mapData',
                            tag: state.mapType,
                            pId: 'mapData',
                            group_id: group_id,
                            isBase: false,
                            isSave: true,
                            state: state
                        };
                        let msg = this.$refs.layerTree.appendTreeData(item, item.pId, true);
                        if(msg) {
                            let options = JSON.parse(JSON.stringify(state.options));
                            options.mapId = item.id;
                            eventBus.$emit("ViewerGIS.addImageryByType", this.bindViewerId, options);
                        }
                    }
                }
            },
            async getGISData(bindId){
                if (!bindId) {
                    this.emptyLayerData();
                    return;
                }

                this.emptyLayerData();
                this.$refs.layerTree.setIsLoading(true);

                let kmlLoading = [];
                let groupKeys = [];
                const groupRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch()
                });
                if (groupRes?.data?.data && groupRes?.data?.data?.data && groupRes.status === 200) {
                    const groupList = groupRes?.data?.data?.data;
                    for (let i = groupList.length - 1; i >= 0; i--) {
                        let { id, name, group_id } = groupList[i];
                        let item = {
                            id: `gis_file_${id}`,
                            key: id,
                            name: name,
                            type: 'file',
                            module: 'gis',
                            pId: group_id === -1 || group_id === null ? 'gis' : `gis_file_${group_id}`,
                            group_id: group_id,
                            children: []
                        };
                        this.$refs.layerTree.appendTreeData(item, item.pId, false);
                        groupKeys.push(id);
                    }
                }

                const pointRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch('GISPoint')
                });
                if (pointRes?.data?.data && pointRes?.data?.data?.data && pointRes.status === 200) {
                    const childList = pointRes?.data?.data?.data;

                    for (let {id, name, group_id, state} of childList) {
                        let index = groupKeys.findIndex(x => x === group_id);
                        let item = {
                            id: `gis_leaf_${id}`,
                            key: id,
                            name: name,
                            type: 'leaf',
                            module: 'gis',
                            tag: state.pointType,
                            pId: index === -1 ? 'gis' : `gis_file_${group_id}`,
                            group_id: index === -1 ? -1 : group_id,
                            state: state
                        };
                        let msg = this.$refs.layerTree.appendTreeData(item, item.pId, false);
                        if(msg) {
                            eventBus.$emit('ViewerGIS.createMarker', this.bindViewerId, item.id, state, false);
                        }
                    }
                }

                const markerRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch('GISMarker')
                });
                if (markerRes?.data?.data && markerRes?.data?.data?.data && markerRes.status === 200) {
                    const childList = markerRes?.data?.data?.data;

                    for (let {id, name, group_id, state} of childList) {
                        let index = groupKeys.findIndex(x => x === group_id);
                        let item = {
                            id: `gis_leaf_${id}`,
                            key: id,
                            name: name,
                            type: 'leaf',
                            module: 'gis',
                            tag: state.markerType,
                            pId: index === -1 ? 'gis' : `gis_file_${group_id}`,
                            group_id: index === -1 ? -1 : group_id,
                            state: state
                        };
                        let msg = this.$refs.layerTree.appendTreeData(item, item.pId, false);
                        if(msg) {
                            eventBus.$emit('ViewerGIS.getTemplateMarkerCanvas', this.bindViewerId, state, (data) => {
                                eventBus.$emit('ViewerGIS.createTemplateMarker', this.bindViewerId, item.id, data, false);
                            });
                        }
                    }
                }

                const plotRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch('GISPlot')
                });
                if (plotRes?.data?.data && plotRes?.data?.data?.data && plotRes.status === 200) {
                    const childList = plotRes?.data?.data?.data;

                    for (let {id, name, group_id, state} of childList) {
                        let index = groupKeys.findIndex(x => x === group_id);
                        let item = {
                            id: `gis_leaf_${id}`,
                            key: id,
                            name: name,
                            type: 'leaf',
                            module: 'gis',
                            tag: state.plotType,
                            pId: index === -1 ? 'gis' : `gis_file_${group_id}`,
                            group_id: index === -1 ? -1 : group_id,
                            state: state
                        };
                        let msg = this.$refs.layerTree.appendTreeData(item, item.pId, false);
                        if(msg) {
                            if(state.plotType === 'kml'){
                                kmlLoading.push(item.id);
                                eventBus.$emit('ViewerGIS.createKmlByServe', this.bindViewerId, item.id, state, false, () => {
                                    kmlLoading.splice(kmlLoading.findIndex(x => x === item.id), 1);
                                    if(kmlLoading.length === 0) {
                                        this.checkInited();
                                    }
                                });
                            }else{
                                eventBus.$emit('ViewerGIS.createPlot', this.bindViewerId, item.id, state, false);
                            }
                        }
                    }
                }

                this.$refs.layerTree.setIsLoading(false);
                if(kmlLoading.length === 0) {
                    this.checkInited();
                }
            },
            getModelData(bindId){
                if(!bindId) return;

                eventBus.$emit("ViewerGIS.getModelInfo", this.bindViewerId, async (res) => {
                    for(let data of res){
                        let item = {
                            id: `model_leaf_${data.modelId}`,
                            key: data.modelId,
                            name: data.modelName,
                            type: 'leaf',
                            module: 'model',
                            tag: data.modelType,
                            pId: 'model',
                            group_id: -1
                        };
                        this.$refs.layerTree.appendTreeData(item, item.pId, true);
                    }
                    this.getNodeShow();
                    this.isViewerInited = true;
                });
            },
            emptyLayerData(){
                this.$refs.layerTree.emptyTreeData();
                // eventBus.$emit('ViewerGIS.removeAllPlot', this.bindViewerId);
                // eventBus.$emit('ViewerGIS.removeAllKml', this.bindViewerId);
            },

            async renameMapRow(row){
                if(row.isBase){
                    this.$message.warning('基础数据无法重命名！');
                }else {
                    let name = await this.$prompt(`请输入影像地图名称：`, '提示', {
                        inputValue: row.name
                    }).catch(() => {});
                    if(!name) return;

                    if (row.isSave) {
                        await dataInterface({
                            __method_name__: 'updateData',
                            object_uuid: DataBaseInfo.Child.object_uuid,
                            view_uuid: DataBaseInfo.Child.view_uuid['all'],
                            data_id: row.key,
                            name: name.value
                        });
                    }

                    let msg = this.$refs.layerTree.updateTreeData(row.id, 'name', name.value);
                    this.$message.success(`[GIS]影像地图重命名${msg ? '成功' : '失败'}！`);
                }
            },
            async renameGISRow(row){
                let name = await this.$prompt(`请输入${row.type === 'file' ? '文件夹' : '对象'}名称：`, '提示', {
                    inputValue: row.name
                }).catch(() => {});
                if(!name) return;

                let database;
                if(row.type === 'file'){
                    database = DataBaseInfo.Group;
                }else{
                    database = DataBaseInfo.Child;
                }

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: database.object_uuid,
                    view_uuid: database.view_uuid['all'],
                    data_id: row.key,
                    name: name.value
                });

                let msg = this.$refs.layerTree.updateTreeData(row.id, 'name', name.value);
                this.$message.success(`[GIS]图层${row.type === 'file' ? '文件夹' : '对象'}重命名${msg ? '成功' : '失败'}！`);
            },
            async deleteMapRow(row){
                if(row.isBase){
                    this.$message.warning('基础数据无法删除！');
                }else{
                    if(row.isSave){
                        await dataInterface({
                            __method_name__: 'deleteData',
                            object_uuid: DataBaseInfo.Child.object_uuid,
                            view_uuid: DataBaseInfo.Child.view_uuid['all'],
                            data_id: row.key
                        });
                    }
                    let msg = this.$refs.layerTree.deleteTreeData(row.id);
                    this.$message.success(`[GIS]影像地图删除${msg ? '成功' : '失败'}！`);
                    if(msg) eventBus.$emit('ViewerGIS.removeImagery', this.bindViewerId, row.id);
                }
            },
            async deleteGISRow(row){
                if(row.type === 'leaf') {
                    await this.onDeleteItem([row]);
                    this.getNodeShow();
                    return;
                }

                let leafList = [];
                this.$refs.layerTree.getNodeLeaf(row, leafList);
                await this.onDeleteItem(leafList);

                await dataInterface({
                    __method_name__: 'deleteData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    data_id: row.key
                });
                let msg = this.$refs.layerTree.deleteTreeData(row.id);
                this.$message.success(`[GIS]图层文件夹删除${msg ? '成功' : '失败'}！`);
                this.getNodeShow();
            },

            async onCreateFile(node){
                let name = await this.$prompt('请输入文件夹名称：', '提示').catch(() => {});
                if(!name) return;

                let group_id = node.data.id === 'gis' ? -1 : node.data.key;
                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    name: name.value,
                    type: 'GISLayer',
                    group_id: group_id
                });

                if (!res?.data?.data || res.status !== 200){
                    return this.$message.warning(`[GIS]创建图层文件夹失败！`);
                }
                const data = res?.data?.data;

                let item = {
                    id: `gis_file_${data.id}`,
                    key: data.id,
                    name: name.value,
                    type: 'file',
                    module: node.data.module,
                    pId: node.data.id,
                    group_id: group_id,
                    children: []
                };
                this.$refs.layerTree.appendTreeData(item, item.pId, true);
            },
            async onSaveRow(row){
                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    type: 'GISImagery',
                    name: row.name,
                    group_id: row.group_id,
                    state: JSON.stringify(row.state)
                });

                if (!res?.data?.data || res.status !== 200){
                    return this.$message.warning(`[GIS]保存影像地图失败！`);
                }
                const data = res?.data?.data;

                let item = {
                    key: data.id,
                    name: row.name,
                    isSave: true
                };
                this.$refs.layerTree.setTreeNodeProps(row.id, item);
                this.$message.success(`[GIS]保存影像地图成功！`);
            },
            async onRenameRow(row){
                if(row.module === 'mapData'){
                    await this.renameMapRow(row);
                }else if(row.module === 'gis'){
                    await this.renameGISRow(row);
                }
            },
            async onDeleteRow(row){
                if(row.module === 'mapData'){
                    await this.deleteMapRow(row);
                }else if(row.module === 'gis'){
                    await this.deleteGISRow(row);
                }
            },
            async onDeleteItem(leafList){
                for(let item of leafList){
                    await dataInterface({
                        __method_name__: 'deleteData',
                        object_uuid: DataBaseInfo.Child.object_uuid,
                        view_uuid: DataBaseInfo.Child.view_uuid['all'],
                        data_id: item.key
                    });

                    let msg = this.$refs.layerTree.deleteTreeData(item.id);
                    if(msg) {
                        if(item?.state?.module === 'point' || item?.state?.pointType){
                            eventBus.$emit('ViewerGIS.removeMarker', this.bindViewerId, item.id);
                        }else if(item?.state?.module === 'marker' || item?.state?.markerType){
                            eventBus.$emit('ViewerGIS.removeTemplateMarker', this.bindViewerId, item.id);
                        }else if(item?.state?.module === 'plot' || item?.state?.plotType){
                            if(item.state.plotType === 'kml'){
                                eventBus.$emit('ViewerGIS.removeKml', this.bindViewerId, item.id);
                            }else{
                                eventBus.$emit('ViewerGIS.removePlot', this.bindViewerId, item.id);
                            }
                        }
                        this.$message.success(`[GIS]图层对象${item.name}删除${msg ? '成功' : '失败'}！`);
                    }
                }
            },

            onSwitchImageryRow(data){
                this.$refs.layerTree.setTreeNodeProps('imagery', data);
            },
            onAddImageryRow(data){
                let item = Object.assign(data, {
                    key: data.id,
                    type: 'leaf',
                    module: 'mapData',
                    pId: 'mapData',
                    group_id: -1,
                    isBase: false,
                    isSave: false
                });
                this.$refs.layerTree.appendTreeData(item, item.pId, true);
            },
            onChangeTerrainRow(data, isAdd){
                if(isAdd){
                    let item = Object.assign(data, {
                        key: data.id,
                        type: 'leaf',
                        module: 'mapData',
                        pId: 'mapData',
                        group_id: -1,
                        isBase: true
                    });
                    this.$refs.layerTree.appendTreeData(item, item.pId, true);
                }else{
                    this.$refs.layerTree.deleteTreeData(data.id);
                }
            },
            onAddEntityRow(data){
                this.$refs.layerTree.appendTreeData(data, data.pId, true);
                this.getNodeShow();
            },

            onClickCurrent(node){
                eventBus.$emit('ViewerGISEditor.layerCurrentChange', this.element.id, node);
            },
            onLocationNode(row){
                if(row.module === 'gis'){
                    if(row.type === 'leaf'){
                        if(row?.state?.module === 'point' || row?.state?.pointType){
                            eventBus.$emit('ViewerGIS.locationMarker', this.bindViewerId, row.id);
                        }else if(row?.state?.module === 'marker' || row?.state?.markerType){
                            eventBus.$emit('ViewerGIS.locationTemplateMarker', this.bindViewerId, row.id);
                        }else if(row?.state?.module === 'plot' || row?.state?.plotType){
                            if(row.state.plotType === 'kml'){
                                eventBus.$emit('ViewerGIS.locationKml', this.bindViewerId, row.id);
                            }else{
                                eventBus.$emit('ViewerGIS.locationPlot', this.bindViewerId, row.id);
                            }
                        }
                    }
                }else if(row.module === 'model'){
                    if(row.type === 'leaf'){
                        eventBus.$emit('ViewerGIS.locationModel', this.bindViewerId, row.key);
                    }
                }else{
                    this.$message.warning('底图数据无法定位！');
                }
            },
            onCheckNode(row, leafList, isChecked){
                for (let item of leafList){
                    if(row.module === 'mapData'){
                        if(item.key === 'imagery'){
                            eventBus.$emit('ViewerGIS.showImagery', this.bindViewerId, item.tag, isChecked);
                        }else if(item.key !== 'imagery' && item.tag === 'CustomMap'){
                            eventBus.$emit('ViewerGIS.showImagery', this.bindViewerId, item.id, isChecked);
                        }else if(item.key !== 'imagery' && item.tag === 'IonWorldTerrain'){
                            eventBus.$emit("ViewerGIS.setTerrain", this.bindViewerId, isChecked);
                        }
                    }else if(row.module === 'gis'){
                        if(item?.state?.module === 'point' || item?.state?.pointType){
                            eventBus.$emit('ViewerGIS.showMarker', this.bindViewerId, item.id, isChecked);
                        }else if(item?.state?.module === 'marker' || item?.state?.markerType){
                            eventBus.$emit('ViewerGIS.showTemplateMarker', this.bindViewerId, item.id, isChecked);
                        }else if(item?.state?.module === 'plot' || item?.state?.plotType){
                            if(item.state.plotType === 'kml'){
                                eventBus.$emit('ViewerGIS.showKml', this.bindViewerId, item.id, isChecked);
                            }else{
                                eventBus.$emit('ViewerGIS.showPlot', this.bindViewerId, item.id, isChecked);
                            }
                        }
                    }else if(row.module === 'model'){
                        eventBus.$emit('ViewerGIS.showModel', this.bindViewerId, item.key, isChecked);
                    }
                }
                this.getNodeShow();
            }
        }
    }
</script>

<style scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }
</style>
