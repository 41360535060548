<!--root
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-03-06 10:42:32
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
	<div class="slider-cont">
		<el-slider :id="'elSlider_' + element.id" v-model="value" @change="onChangeValue()" :show-tooltip="false" :disabled="disabled"></el-slider>
		<!-- <span @click="onPlay()">播放</span>	 -->
	</div>	
</template>
<script>
import { Slider } from 'element-ui';
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import business from '@/custom-component/component-config/newBusiness.js'
import { initParams, parseTime, findModelComponentId } from '@/utils/tools';
import databaseTriggerDebug from '@/custom-component/mixins/databaseTriggerDebug.js';
import { hex2Rgb } from '@/utils/color';
export default {
  name: 'CommonScheduleSlider',
	mixins: [databaseTriggerDebug],
	props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为预览
		isPreview: {
			type: Boolean,
			required: false,
			default: true
		},
    componentList: {
      default: null
    }
  },
  inject: ['EDITOR_pageUUID'],
  components: {
		'el-slider': Slider
  },
	computed: {
		...mapState([
			'componentData',
			'_PageCustomStatus',
			'_APPCustomStatus',
      'subsidiaryComponentData'
		]),
		// 嵌入页面的参数获取
		subComponentData() {
			if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
		},
    
		objectUuid(){
			return this.element?.database?.objectData?.uuid || '';
		},
		viewUuid(){
			return this.element?.database?.viewData?.uuid || '';
		},
		requestType(){
			return this.element.database.requestType || "dataList";
		},
		//时间字段
		dateField () {
			return this.element.sliderFieldConfig?.dateField || ''
		}, 
		//时间字段
		idField () {
			return this.element.sliderFieldConfig?.idField || ''
		}, 
		//gis字段
		gisField () {
			return this.element.sliderFieldConfig?.gisField || 'gis_info'
		}, 
		//bim字段
		bimField () {
			return this.element.sliderFieldConfig?.bimField || 'model_list'
		}, 
		//颜色字段
		taskColorField () {
			return this.element.sliderFieldConfig?.taskColField || ''
		},
		//播放速度字段
		speed () {
			return this.element.sliderFieldConfig?.speed || 400
		},
		paramsConfig() {
			if (!this.element || !this.element.database || !this.element.database.paramsConfig) return [];
			return this.element.database.paramsConfig;
		},
		//是否是gis页面显示
		isGis () {
			let gisComponent = this.findComponentWithField(this.subComponentData, 'ViewerGIS')
			if(gisComponent?.statusConfig?.displayShow && gisComponent?.statusConfig?.isShow){
				return true
			} else {
				return false
			}
		},
	},
	directives: {  // 使用局部注册指令的方式
    resize: { // 监听容器变化
      mounted (el, binding) { // el为绑定的元素，binding为绑定给指令的对象
        let width = '', height = '';
        function isReize () {
          const style = document.defaultView.getComputedStyle(el);
          if (width !== style.width || height !== style.height) {
            binding.value({width:style.width,height:style.height});  // 关键
          }
          width = style.width;
          height = style.height;
        }
        el.__vueSetInterval__ = setInterval(isReize, 500);
      },
      beforeUnmount (el) {
        clearInterval(el.__vueSetInterval__);
      }
    }
  },
	data() {
		return {
      value: 0,
			isPlay:false,
			disabled:true,
			minDate:'',
			maxDate:'',
			list:[],
			bindInfoData:[],//绑定了模型的数据
			dateLineData:[],//格式化后的数组
			bindModelArr:[],//绑定了的模型
			totalDays:0,
			currentNum:0,
			timer:null,
			cacheBehavior:null,
    }
	},
	watch: {
		disabled: {
			handler(newVal){
				if(!newVal && this.cacheBehavior){
					this.behaviorFun(this.cacheBehavior)
					this.cacheBehavior=null
				}
			},
			deep: true
		},
	},
	created () {
  },
  mounted () {
		this.bimComponentId = findModelComponentId(this.subComponentData,'ViewerBIM') || ''
		this.gisComponentId = findModelComponentId(this.subComponentData,'ViewerGIS') || ''
		let { search = [],param } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents, this.EDITOR_pageUUID);
		if(!this.param){
			this.param = param
		}
		this.getData(search, this.param)
		// 监听行为
    const doComponentBehavior = {
      [this.element.id]: config => {
				if(this.element.id!==config.component) return
				if(this.disabled){
					this.cacheBehavior = config
				} else {
					this.behaviorFun(config)
				}
      }
    }
		eventBus.$on('doComponentBehavior', doComponentBehavior[this.element.id])
    const databaseTrigger = {
      [this.element.id]: (data) => {
				const { paramsConfig } = this.element.database;
        if (!data || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === data.componentId)) {
          return;
        }
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
				this._startDataDebug();
        if (!isTarget && !data.isUpdate) return;
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents, this.EDITOR_pageUUID);
        // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
        if (!canPost) {
					this._failDataDebug('参数必填校验未通过');
					return;
				}
        this.param = param;
        this.getData(search, param);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
	methods: {
		/**
		 * @desc: 根据字段查找组件
		 */
		findComponentWithField(componentList, field){
			for (let i = 0, len = componentList.length; i < len; i++) {
				const item = componentList[i];
				if (item.component === field) {
					return item;
				}
				if (item.children && Array.isArray(item.children) && item.children.length) {
					const result = this.findComponentWithField(item.children, field) || null;
					if (result) {
						return result;
					}
				}
			}
			return null;
		},
		
  /**
   * @description: 调用行为
   * @param {*} config
   * @return {*}
   */		
		behaviorFun(config){
			if (!config) return false
			const { component, list = [] } = config;
			if (component === null) return false
			let componentName = component.split('-')[0]
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.subComponentData, [], this.EDITOR_pageUUID);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							window.$EditorDebug.startBehavior(
								this.element,
								business.componentList.find(ele => ele.component === 'CommonScheduleSlider'),
								funName,
								param
							);
							eval(this[funName])(param)
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		},
	/**
   * @description: 获取数据
   * @return {*}
   */
		getData(search = [], params = {}) {
			this.disabled = true
			this.minDate = ''
			this.maxDate = ''
			this.list = []
			this.dateLineData = []
			this.bindInfoData = []
			let requestUrl = '';
			let requestContent = null;
			this.requestParam = {search:search,params:params}
			if(this.element.database?.mapping === 'interface'){
				if(this.element.database.interfaceConfig){
					const { url } = this.element.database.interfaceConfig;
					// 注入的参数
					requestUrl = `${url}`;
					if (url && url.indexOf('http') === -1) {
						requestUrl = `/api${url}`;
					}
				}
				requestContent = {
					search:search,
					// mType:2,
					// archi_type:['org_projects-10','org_projects-9','org_projects-22','org_projects-15','org_projects-1'],
					...params
				};
			} else {
				if(!this.objectUuid || !this.viewUuid) {
					this._failDataDebug('请求配置错误');
					return
				}
				requestContent = {
					object_uuid: this.element.database.objectData?.uuid,
					view_uuid: this.element.database.viewData?.uuid,
					__method_name__: this.requestType,
					search:search,
					...params
				};
			}	
			dataInterface(requestContent,requestUrl).then(async(res) => {
				if (res.data?.code === 200 && res.data?.data?.length) {
					let metadata = null
					if(this.element.database?.mapping === 'interface'){
						metadata = {}
						Object.keys(res.data.data[0]).forEach((index,key)=>{
							metadata[index] = key+1
						})
          } else {
						metadata = res.data.metadata
					}
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							metadata:metadata
						},
						containerId: null,
						isModify: true
					});
					//处理数据
					this.list = await this.formatData(res.data.data)
					if(this.minDate && this.maxDate){
						const baseDate = 24 * 60 * 60 * 1000
						this.totalDays = (Date.parse(this.maxDate) - Date.parse(this.minDate)) / baseDate
						console.time()
						let totalBindArr = []
						for(let i = 0; i < this.totalDays + 1; i++){
							const date = parseTime(Date.parse(this.minDate) + (i*baseDate), '{y}-{m}-{d}')
							const info = this.bindInfoData.find(v=>v[this.dateField].includes(date))
							let color = ''
							let selection = null
							if(info){
								color = info[this.taskColorField]
								if(info?.selection){
									totalBindArr.push({color:info[this.taskColorField],selection:info.selection})
								}
								if(info?.selection){
									selection = info.selection
								}
							}
							
							this.dateLineData.push({
								date: date,
								info:info,
								color:color,
								bindInfo: selection,
								totalBindArr: [...totalBindArr]
							})
						}
						this.disabled = false
						console.timeEnd()
					}
				}
				this._successDataDebug({
					url: '',
					requestContent,
					res
				});
			}).catch(err => {
				this._errorDataDebug({
					url: '',
					requestContent,
					err
				});
			})
		},
  /**
   * @description: 格式化数据
   * @return {*}
   */		
		formatData(data){
			return new Promise((resolve) => {
				const arr = data.map((item)=>{
					if((!this.minDate || ( new Date(this.minDate) > new Date(item[this.dateField]))) && item[this.dateField] !== '0000-00-00 00:00:00' && isNaN(item[this.dateField]) && !isNaN(Date.parse(item[this.dateField]))){
						this.minDate = item[this.dateField].substring(0, 10)
					}
					if((!this.maxDate || new Date(this.maxDate) < new Date(item[this.dateField])) && item[this.dateField] !== '0000-00-00 00:00:00' && isNaN(item[this.dateField]) && !isNaN(Date.parse(item[this.dateField]))){
						this.maxDate = item[this.dateField].substring(0, 10)
					}
					item.taskColor = item[this.taskColorField]
					item.selection = item[this.bimField] || []
					if(this.isGis){
						item.selection = item[this.gisField] || []
					}
					if(item.selection?.length && typeof(item.selection) === 'string'){
						item.selection = JSON.parse(item.selection)
					}
					
					if(item.selection?.length){
						this.bindInfoData.push(item)
						//收集要隔离的模型id,播放先隔离
						for(let i = 0; i < item.selection.length; i++){
							if(item.selection[i].model_id && !this.bindModelArr.includes(item.selection[i].model_id)){
								!this.bindModelArr.push(item.selection[i].model_id)
							}
						}
					}
					return {
						...item,
						id: item[this.idField]
					}
				})
        resolve(arr)
      })
		},
		onChangeValue(){
			this.currentNum = Math.floor(this.totalDays * (this.value / 100))
			if(this.currentNum === 0){
				this.onReset()
				this.toggleModel(true)
			} else {
				const item = this.dateLineData[this.currentNum]
				this.toggleModel(false)
				if(item?.totalBindArr){
					for(let i = 0; i < item.totalBindArr.length;i++){
						let color = item.totalBindArr[i].color
						if(item.totalBindArr[i].selection?.length){
							for(let j = 0; j < item.totalBindArr[i].selection?.length;j++){
								const selection = item.totalBindArr[i].selection[j]
								if(this.isGis){
									eventBus.$emit('ViewerGIS.showFeature', this.gisComponentId,selection.model_id,selection.dbids, true );
									eventBus.$emit('ViewerGIS.setFeatureColor', this.gisComponentId,selection.model_id,selection.dbids, color );
								} else {
									eventBus.$emit('ViewerBIM.show',this.bimComponentId,selection.dbids,selection.model_id)
									eventBus.$emit('ViewerBIM.setColor', this.bimComponentId,selection.model_id,selection.dbids, color );
								}
							}
						}
					}
				}
			}
		},
    /**
     * @description: 播放进度
     * @return {*}
     */		
    playFunction(){
			this.timer = setInterval(() => {
				if(this.currentNum >= this.totalDays){
					//超过时间清除各种数据
					this.onReset()
					this.toggleModel(true)
					return
				}
				this.currentNum++
				this.value = (this.currentNum / this.totalDays) * 100
				const item = this.dateLineData[this.currentNum]
				const bindInfo = item?.bindInfo
				//暴露播放时间
				this.$store.commit('modifyComponent', {
					component: {
						...this.element,
						resolveData:item?.date || null
					},
					containerId: null,
					isModify: true
				});
				if(bindInfo?.length && item.color){
					for(let i = 0; i < bindInfo.length;i++){
						if(this.isGis){
							eventBus.$emit('ViewerGIS.showFeature', this.gisComponentId,bindInfo[i].model_id,bindInfo[i].dbids, true );
							eventBus.$emit('ViewerGIS.setFeatureColor', this.gisComponentId,bindInfo[i].model_id,bindInfo[i].dbids, item.color );
						} else {
							eventBus.$emit('ViewerBIM.show',this.bimComponentId,bindInfo[i].dbids,bindInfo[i].model_id)
							eventBus.$emit('ViewerBIM.setColor', this.bimComponentId,bindInfo[i].model_id,bindInfo[i].dbids, item.color );
						}
					}
				}
			}, this.speed);
		},
  /**
   * @description: 隔离显示模型
   * @return {*}
   */		
    toggleModel (bol){
			if(this.isGis){
				eventBus.$emit('ViewerGIS.getModelKeys', this.gisComponentId,(ids) =>{
					if(bol){
						eventBus.$emit('ViewerGIS.clearFeatureKeeping', this.gisComponentId)
					} else {
						if(ids.length){
							for(let i=0;i<ids.length;i++){
								eventBus.$emit('ViewerGIS.setFeatureKeeping',this.gisComponentId,'insulate', [{modelId:ids[i], dbids:[]}], true)
							}
						}
					}
				})
			} else {
				if(this.bindModelArr?.length){
					for(let i = 0; i < this.bindModelArr.length; i++){
						eventBus.$emit('ViewerBIM.clearAllColor',this.bimComponentId)
						//bim模型隔离
						if(bol){
							eventBus.$emit('ViewerBIM.show',this.bimComponentId,[],this.bindModelArr[i])
						} else {
							eventBus.$emit('ViewerBIM.isolateData',this.bimComponentId,[7897897897])
						}
					}
				}
			}
				
			
		},

		/**
   * @description: 清除颜色
   * @return {*}
   */		
    clearColor (){
			if(this.isGis){
				for(let i = 0;i<this.bindInfoData.length;i++){
					if(this.bindInfoData[i]?.selection?.length && this.bindInfoData[i].taskColor){
						const bindInfo = this.bindInfoData[i].selection
						for(let j=0;j<bindInfo.length;j++){
								eventBus.$emit('ViewerGIS.clearFeatureColor', this.gisComponentId,bindInfo[j].model_id,bindInfo[j].dbids );
						}
					}
				}
			} else {
				eventBus.$emit('ViewerBIM.clearAllColor',this.bimComponentId)
			}
			
		},
    /**
   * @description: 播放进度
   * @return {*}
   */
    onPlay () {
      this.isPlay = !this.isPlay
			if(this.isPlay){
				if(this.currentNum === 0){
					//隔离全部
					this.toggleModel(false)
				}
				this.playFunction()
			} else {
				// this.toggleModel(true)
				clearInterval(this.timer)
			}
    },
  /**
   * @description: 暂停
   * @return {*}
   */		
		onPause(){
			this.isPlay = false
			clearInterval(this.timer)
		},
  /**
   * @description: 充值数据
   * @return {*}
   */		
		onRefresh(){
			this.toggleModel(true)
			this.onReset()
		},
		/**
   * @description: 重置刷新
   * @return {*}
   */
    onReset () {
			this.isPlay = false
			this.$store.commit('modifyComponent', {
				component: {
					...this.element,
					resolveData:null
				},
				containerId: null,
				isModify: true
			});
			this.currentNum = 0
			this.value = 0
			clearInterval(this.timer)
			this.clearColor()
    },
		/**
		 * @description: 全选数据
		 * @return {*}
		 */		
		async onCheckAll(){
			if(this.bindInfoData?.length){
				this.onReset()
				this.toggleModel(true)
				eventBus.$emit('ViewerGIS.getModelKeys', this.gisComponentId,(ids) =>{
					//所有模型变成隔离状态
					eventBus.$emit('ViewerGIS.setShadows', this.gisComponentId,true)
					if(ids.length){
						for(let i=0;i<ids.length;i++){
								eventBus.$emit('ViewerGIS.setFeatureKeeping',this.gisComponentId,'insulate', [{modelId:ids[i], dbids:[]}], true)
								eventBus.$emit('ViewerGIS.showAllFeature', this.gisComponentId,ids[i], false)
						}
					}
				})
				for(let i = 0;i<this.bindInfoData.length;i++){
					if(this.bindInfoData[i]?.selection?.length && this.bindInfoData[i].taskColor){
						const bindInfo = this.bindInfoData[i].selection
						const componentCol = hex2Rgb(this.bindInfoData[i].taskColor, 0.85)
						for(let j=0;j<bindInfo.length;j++){
							if(this.isGis){
								if(this.bindInfoData[i].task_status !== 1){
									//显示构件
									eventBus.$emit('ViewerGIS.showFeature', this.gisComponentId,bindInfo[j].model_id,bindInfo[j].dbids,true );
									//着色构件
									eventBus.$emit('ViewerGIS.setFeatureColor', this.gisComponentId,bindInfo[j].model_id,bindInfo[j].dbids, componentCol );
								}
							} else {
								eventBus.$emit('ViewerBIM.setColor', this.bimComponentId,bindInfo[j].model_id,bindInfo[j].dbids, componentCol );
							}
						}
					}
				}
			}
		},
  },
}
</script>
