<template>
    <div :class="['moduleWrap', `${subItemSpread}-flex`]">
        <template v-for="item in btnList">
            <el-tooltip placement="bottom" :open-delay="500" :key="item.id" v-if="item.show" :content="item.name" >
                <a :class="['btn-item', item.icon, {'btn-item-active': item.active}]" @click="onClick(item)"></a>
            </el-tooltip>
        </template>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import { Tooltip } from 'element-ui';

    export default {
        name: "ViewerGISToolbar",
        components: {
            'el-tooltip': Tooltip,
        },
        data() {
            return {
                bindViewerId: null,
                subItemSpread: 'horizontal',

                btnList: [
                    {id: 'splitScreen', icon: 'iconfont iconjijia_zhucai', name: '分屏联动', needActive: false, show: false},
                    {id: 'terrain', icon: 'iconfont icongis_dixing', name: '开启地形', needActive: true, active: false, show: false},
                    {id: 'depthTest', icon: 'iconfont icongis_shendujiancetoushi', name: '深度检测', needActive: true, active: false, show: false},
                    {id: 'measure', icon: 'iconfont iconbimgis_suanliang', name: '测量', needActive: true, active: false, show: false},
                    {id: 'keyboardRoam', icon: 'iconfont iconbim_diyirencheng', name: '第一人称', needActive: true, active: false, show: true},
                    {id: 'boxesSelect', icon: 'iconfont iconbimgis_kuangxuan', name: '框选', needActive: false, show: true},
                    {id: 'intersectSelect', icon: 'iconfont iconbimgis_jiaoxuan', name: '交选', needActive: false, show: true},
                    {id: 'shadow', icon: 'iconfont iconbim_yinying', name: '显示阴影', needActive: true, active: false, show: true},
                    {id: 'show', icon: 'iconfont iconbimgis_xianshi', name: '全显', needActive: false, show: true},
                    {id: 'hide', icon: 'iconfont iconbimgis_yincang', name: '隐藏', needActive: false, show: true},
                    {id: 'insulate', icon: 'iconfont iconbimgis_geli', name: '隔离', needActive: false, show: true},
                    {id: 'modelManage', icon: 'iconfont icongis_moxingguanli', name: '模型管理', needActive: true, active: false, show: false},
                    {id: 'northView', icon: 'iconfont icongis_zhaobei', name: '朝北正视', needActive: false, show: false},
                    {id: 'squarelyView', icon: 'iconfont icongis_fushi', name: '视角俯视', needActive: false, show: false},
                ]
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            this.initCheckBind();

            this.initViewerInitedListener(this.bindViewerId, (opt = {}) => {
                let {viewerInited = false, dynamicLoaded = false} = opt;
                if(viewerInited && dynamicLoaded){
                    this.getToolbarData();
                }
            });
        },
        methods: {
            /**
             * @description: 注册组件事件
             */
            initEventListener() {
                eventBus.$on('ViewerGISToolbar.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== 'ViewerGIS') {
                                if (item.children && item.children.length > 0)
                                    loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                        spreadDirection: this.subItemSpread,
                        btnConfig: this.getBtnStatus()
                    });
                });

                eventBus.$on('ViewerGISToolbar.setSource', (eid, {bindViewerId, spreadDirection, gisBtnConfig}) => {
                    if (eid !== this.element.id) return;
                    if (this.checkBind(bindViewerId)){
                        this.element.viewerBindId = bindViewerId;
                        this.bindViewerId = bindViewerId;
                        this.$message.success(`[${this.element.name}] 绑定成功`);
                    }

                    this.element.subItemSpread = spreadDirection;
                    this.subItemSpread = spreadDirection;

                    this.element.gisBtnConfig = gisBtnConfig;
                    this.setBtnStatus(gisBtnConfig);
                });
            },
            /**
             * @description: 初始化组件配置参数
             */
            initCheckBind(){
                if (this.element.viewerBindId) {
                    if (this.checkBind(this.element.viewerBindId)) {
                        this.bindViewerId = this.element.viewerBindId;
                    }
                }

                if (this.element.subItemSpread) {
                    this.subItemSpread = this.element.subItemSpread;
                }

                if (this.element.gisBtnConfig) {
                    this.setBtnStatus(this.element.gisBtnConfig);
                }
            },
            /**
             * @description: 注册Viewer组件初始化监听事件
             * @param {String} bindId 关联组件Id
             * @param {Function} cb 触发方法
             */
            initViewerInitedListener(bindId = this.bindViewerId, cb = null){
                function listener() {
                    eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                        if (eid !== bindId) return;

                        cb && cb(opt);
                    });
                }

                if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && bindId){
                    eventBus.$emit("ViewerGIS.getViewerInitedFlag", bindId, (opt = {}) => {
                        let {viewerInited = false, dynamicLoaded = false} = opt;
                        if(viewerInited && dynamicLoaded){
                            cb && cb(opt);
                        }else{
                            listener();
                        }
                    });
                }else{
                    listener();
                }
            },
            /**
             * @description: 检验绑定的组件Id是否有效
             * @param {String} bindId 组件标识值
             */
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },

            /**
             * @description: 初始化Toolbar参数状态
             */
            getToolbarData() {
                for (let item of this.btnList){
                    if(item.needActive && item.show){
                        eventBus.$emit(`ViewerGIS.get${this.getFuncName(item.id)}`, this.bindViewerId, (active) => {
                            item.active = active;
                        });
                    }
                }
            },

            /**
             * @description: 获取功能配置状态
             */
            getBtnStatus(){
                let res = [];
                for (let item of this.btnList){
                    if(!item.show) continue;
                    res.push(item.id);
                }

                return res;
            },
            /**
             * @description: 获取功能名称，首字母大写
             */
            getFuncName(str){
              return str.charAt(0).toUpperCase() + str.slice(1);
            },

            /**
             * @description: 设置功能配置状态
             */
            setBtnStatus(config){
                for (let item of this.btnList){
                    if(config.findIndex(x => x === item.id) === -1) {
                        item.show = false;
                    }else{
                        item.show = true;
                    }
                }
            },

            /**
             * @description: 功能点击事件
             */
            onClick(item){
                if(item.id === 'splitScreen') return;

                if(item.needActive) {
                    item.active = !item.active;

                    let cb = null;
                    if(item.id === 'measure' ||
                        item.id === 'modelManage'){
                        cb = () => {
                            item.active = false;
                        };
                    }

                    eventBus.$emit(`ViewerGIS.set${this.getFuncName(item.id)}`, this.bindViewerId, item.active, cb);
                }else{
                    eventBus.$emit(`ViewerGIS.set${this.getFuncName(item.id)}`, this.bindViewerId);
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: inherit;
        backdrop-filter: blur(5px);
    }

    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .btn-item{
        margin: 5px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 20px;
        color: inherit;
        border-radius: 6px;
        text-align: center;
    }

    .btn-item:hover{
        background-color: rgba(0, 118, 255, 0.1);
    }

    .btn-item-active{
        color: #0076FF !important;
    }
</style>
