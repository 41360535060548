<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-04-29 17:18:40
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-08-14 10:22:35
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-gisList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
    @name: widget-modelView
    @description：widget-modelView
    @author: ZengWei
    @date: 2022-03-25 09:43
-->
<template>
  <ChooseGis
    ref="chooseGis"
		componentType="gis"
    @set-data="setModelData"
    :search="searchData"
    :modelData="element.value && element.value != null? element.value: []">
    <template v-slot:showType>
      <el-button
        style="width: 100%"
        @click="openModelDrawer()"
        size="medium"
        type="primary"
        v-if="!disabled"
        v-html="
          element.value && element.value !== null && element.value.length !== 0
          ? '已选择': '请选择'"
      ></el-button>
      <span class="disabled-value" v-else-if="element.value?.length" @click="openModelDrawer()"> 查看 </span>
      <p class="disabled-cont" v-else> - </p>
    </template>
  </ChooseGis>
</template>

<script>
import ChooseGis from "@/custom-component/bindLatLng/ButtonType.vue";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import eventBus from "@/plugins/eventBus";
import {getSearchData} from "@/custom-component/form/newParser/scripts/tools";

export default {
  name: "WidgetGisList",
  components: {ChooseGis},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      searchData: []
    }
  },
  created() {
    this.initSearch();
  },
  mounted() {
    eventBus.$on('TRIGGER_selectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule || []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(compFormId.includes(formId)){
        this.initSearch();
      }
    })
  },
  methods: {
    initSearch(){
      const filterOpen = this.element.config.__config__?.openFilter ?? false
      const filterRule = this.element.config.__config__?.filterRule ?? []
      if(filterOpen && filterRule.length>0){
        this.searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      }
    },
    openModelDrawer() {
      this.$nextTick(() => {
        this.$refs.chooseGis.show = true;
      })
    },
    setModelData(data) {
      this.element.value = data;
    },
  },
}
</script>

<style scoped>
.disabled-cont{
  padding: 5px 12px;
  box-sizing: border-box;
  height: 34px;
  line-height: 24px;
  background: #F2F4F7;
  border-radius: 4px;
  font-size: 14px;
}
.disabled-value{
  color:var(--themeColor);
}
</style>

