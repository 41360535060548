<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-10-08 16:57:12
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-07-26 09:37:22
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-user.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <select-user
    :key="key"
    :disabled="disabled"
    :element="element"
    :data-info="element.config.__config__.userData || {}"
    :is-check-type="!element.config.__config__?.alone"
    :show-list="element.value"
    :isItself="isItself"
		:isConsole="isConsole"
    :lazy="element.config.__config__.lazy || false"
    @on-select-change="onSelectChange"
  />
</template>

<script>
import selectUser from "@/custom-component/form/formParser/selectUser/index";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {formRequest} from "@/apis/data/form";
import {uniqid} from "@/plugins/util";
import eventBus from "@/plugins/eventBus";
import {getSearchData} from "@/custom-component/form/newParser/scripts/tools";

export default {
  name: "WidgetUser",
  components: {selectUser},
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData', 'isConsole'],
  data() {
    return {
      key:uniqid(),
      isItself: false
    }
  },
  created() {
    this.initUserData()
  },
  mounted() {
    eventBus.$on('TRIGGER_selectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule ?? []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(compFormId.includes(formId)){
        this.initUserData();
      }
    })
    eventBus.$on('TRIGGER_treeSelectChange',(formId)=>{
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule ?? []
      const formIdArr = filterRule.filter(item => item.valueType === 2)
      const compFormId = Array.from(formIdArr,item => item.value)
      if(compFormId.includes(formId)){
        this.initUserData();
      }
    })
  },
  methods: {
    initUserData(){
      const url= '/api/mapi';
      const objectUuid = this.element.config.__config__.objectUuid
      const viewUuid = this.element.config.__config__.viewUuid
      const filterRule = this.element.config.__config__.filterRule ?? []
      const params = {
        __method_name__: 'dataList', object_uuid: objectUuid,
        view_uuid: viewUuid, transcode: 0,
        __now_archi_type: this.parser.nowArchiType,
      }
      const searchData = getSearchData(filterRule,this.parser.getFormIdValue);
      if(searchData.length) params.search = searchData
      formRequest('post', url, params).then(res => {
        let respData = res.data.data;
        if (res.data.data.data) {
          respData = res.data.data.data;
        }
        if (params?.search?.length) {
          params.search = params.search.filter((item) => item.value)
        }
        if(!this.element.config.__config__.dataType || (objectUuid && viewUuid && params?.search?.length)){
          const archiInfo = this.$GetTargetArchi()
          let userData = {list:respData, type:this.element.config.__config__.dataType || archiInfo.archiType}
          this.element.config.__config__.userData = userData
        } else {
          this.isItself = true // 本级架构数据
        }
        if(this.displayData && Object.prototype.toString.call(this.displayData) === '[object Object]' && Object.keys(this.displayData).includes(this.element.field)){
          this.element.value = this.displayData[this.element.field];
        }
        this.key = uniqid()
      })
    },
    onSelectChange(userData) {
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          name: item.name,
          user_table: item.user_table,
          user_id: item.user_id,
          section_id: item.section_id,
        }
        users.push(user)
      }
      this.triggerFlowBranch(users)
      this.element.value = users;
    },
  },
}
</script>

<style scoped>

</style>
