<!--
 * @Author: qinmengyuan
 * @Date: 2024-06-19 17:29:36
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-28 09:38:29
 * @FilePath: \dataview-viewer-test\src\custom-component\form\newParser\FormItem\widget-bimList.vue
-->
<template>
  <ChooseModel
    ref="chooseModel"
    @set-data="setModelData"
    :search="searchData"
    :modelData="element.value && element.value != null ? element.value : []"
    :config="element.config"
    :bodyParams="bodyParams"
  >
    <template v-slot:showType>
      <el-button
        style="width: 100%"
        @click="openModelDrawer"
        size="medium"
        type="primary"
        v-if="!disabled"
        v-html="
          element.value && element.value != null && element.value.length !== 0
            ? '已选择'
            : '请选择'
        "
      ></el-button>
      <el-button
        style="width: 100%"
        v-else-if="element.value?.length"
        size="medium"
        type="primary"
        @click="openModelDrawer()"
        >查看</el-button
      >
      <p class="disabled-cont" v-else>-</p>
    </template>
  </ChooseModel>
</template>

<script>
import ChooseModel from '@/custom-component/bindModel/ButtonType.vue';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';
import {
  getSearchData,
  getBodyParams,
} from '@/custom-component/form/newParser/scripts/tools';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'WidgetBimList',
  components: { ChooseModel },
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      searchData: [],
      bodyParams: {},
    };
  },
  mixins: [formItemMinix],
  created() {
    this.initSearch();
  },
  mounted() {
    eventBus.$on('TRIGGER_selectChange', (formId) => {
      const eleConf = this.element.config.__config__;
      const filterRule = eleConf?.filterRule || [];
      const formIdArr = filterRule.filter((item) => item.valueType === 2);
      const compFormId = Array.from(formIdArr, (item) => item.value);
      if (compFormId.includes(formId)) {
        this.initSearch();
      }
    });
  },
  methods: {
    initSearch() {
      const filterOpen = this.element.config.__config__?.openFilter ?? false;
      const filterRule = this.element.config.__config__?.filterRule ?? [];
      if (filterOpen && filterRule.length > 0) {
        this.searchData = getSearchData(filterRule, this.parser.getFormIdValue);
        this.bodyParams = getBodyParams(filterRule, this.parser.getFormIdValue);
      }
    },
    openModelDrawer() {
      this.$nextTick(() => {
        this.$refs.chooseModel.show = true;
      });
    },
    setModelData(data) {
      this.element.value = data;
    },
  },
};
</script>

<style scoped>
.disabled-cont {
  padding: 5px 12px;
  box-sizing: border-box;
  height: 34px;
  line-height: 24px;
  background: #f2f4f7;
  border-radius: 4px;
  font-size: 14px;
}
.disabled-value {
  color: var(--themeColor);
}
</style>
