<template>
  <div class="calendar-filter">
    <i class="iconfont icona-zuojiantouyuanjiaoL" @click="arrow('left')"></i>
    <span>{{ showTime }}</span>
    <i
      class="iconfont icona-zuojiantouyuanjiaoL-copy"
      @click="arrow('right')"
    ></i>
  </div>
</template>

<script>
import { mapState } from "vuex";
import eventBus from "@/plugins/eventBus";
import { removeEventBus } from "@/utils/tools";

export default {
  name: "CalendarFilter",
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  inject: ["EDITOR_pageUUID"],
  data() {
    return {
      currentYear: "", // 年份
      currentMonth: "", // 月份
      currentWeek: 1, // 星期
    };
  },
  computed: {
    ...mapState(["componentData"]),
    // 组件配置
    statusConfig() {
      return this.element.statusConfig || {};
    },
    showTime() {
      let result = "";
      switch (this.element.statusConfig.type) {
        case "week":
          result = `${this.currentYear}年${this.getMonth(this.currentMonth)}月\xa0\xa0\xa0\xa0第${this.currentWeek}周`;
          break;
        case "year":
          result = `${this.currentYear}年`;
          break;
        case "month":
          result = `${this.currentYear}年${this.getMonth(this.currentMonth)}月`;
          break;
      }
      return result;
    },
    // 参数配置
    paramsConfig() {
      return this.element?.paramsConfig || [];
    },
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {
    this.initData();
    const databaseTrigger = {
      [this.element.id]: (config) => {
        if (
          !config ||
          !this.paramsConfig.length ||
          !this.paramsConfig.find(
            (ele) => ele.componentId === config.componentId
          )
        ) {
          return;
        }
        this.initData();
      },
    };
    eventBus.$on("databaseTrigger", databaseTrigger[this.element.id]);
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus("databaseTrigger", this.element.id);
    }
  },
  methods: {
    getMonth(month) {
      if (month < 10) {
        return `0${month}`;
      } else {
        return month;
      }
    },
    /**
     * @desc: 改变时间
     */
    arrow(arrow) {
      switch (this.element.statusConfig.type) {
        case "week":
          this.paramsWeek(arrow);
          break;
        case "year":
          this.paramsYear(arrow);
          break;
        case "month":
          this.paramsMonth(arrow);
          break;
      }
      this.exposureData();
    },
    /**
     * @desc: 周切换
     */
    paramsWeek(sign) {
      if (sign == "left") {
        this.currentWeek--;
        if (this.currentWeek < 1) {
          this.currentWeek = 4;
          this.currentMonth--;
          if (this.currentMonth < 1) {
            this.currentMonth = 12;
            this.currentYear--;
          }
        }
      } else if (sign == "right") {
        this.currentWeek++;
        if (this.currentWeek > 4) {
          this.currentWeek = 1;
          this.currentMonth++;
          if (this.currentMonth > 12) {
            this.currentMonth = 1;
            this.currentYear++;
          }
        }
      }
    },
    /**
     * @desc: 年切换
     */
    paramsYear(sign) {
      if (sign == "left") {
        this.currentYear--;
      } else if (sign == "right") {
        this.currentYear++;
      }
    },
    /**
     * @desc: 月切换
     */
    paramsMonth(sign) {
      if (sign == "left") {
        this.currentMonth--;
        if (this.currentMonth < 1) {
          this.currentMonth = 12;
          this.currentYear--;
        }
      } else if (sign == "right") {
        this.currentMonth++;
        if (this.currentMonth > 12) {
          this.currentMonth = 1;
          this.currentYear++;
        }
      }
    },
    /**
     * @desc: 数据暴露
     */
    exposureData() {
      const resolveData = {
        year: this.currentYear,
        month: this.getMonth(this.currentMonth),
        week: this.currentWeek,
        "year-month": `${this.currentYear}-${this.getMonth(this.currentMonth)}`,
      };
      this.element.resolveData = {
        time: resolveData || {},
      };
      this.$store.commit("updatePageCustomStatus", {
        origin: this.element,
        resolveData,
      });
      this.$store.commit("modifyComponent", {
        component: {
          ...this.element,
          resolveData,
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID,
      });
      // 触发事件
      // event bus  挂载卸载问题
      setTimeout(() => {
        eventBus.$emit("databaseTrigger", {
          componentId: this.element.id,
          action: "any",
          isSearch: true,
          output: resolveData,
        });
      }, 0);
    },
    /**
     * @desc: 初始时间
     */
    initData() {
      const date = new Date();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      this.exposureData();
    },
  },
};
</script>

<style lang="less" scoped>
.calendar-filter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .iconfont {
    &:hover {
      transform: scale(1.2);
    }
  }
}
</style>