/*
 * @Date: 2022-12-09 14:25:40
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-13 15:07:22
 * @FilePath: /dataview-next/src/custom-component/viewerBIM/Components/Graffiti/index.js
 */
import Viewer from "../../Viewer";
import eventBus from '@/plugins/eventBus';
export default class Graffiti {

    get id(){
        return "Graffiti";
    }

    get data(){
        return {
        }
    }

    get watch (){
        return {

        }
    }

    get methods (){
        return {
            //初始化行为监听
            gra_initBehaviors(){
                this.registerBehaviors([{
                    label : "[涂鸦]打开涂鸦工具",
                    desc : "参数[ mode ]。[edit] 编辑模式打开（默认） [view]视图模式打开",
                    value : "gra_open",
                    params: ['mode'],
                    func : (params)=>{
                        console.log(params, '---111----')
                        this.gra_open(params?.mode);
                    },
                },{
                    label : "[涂鸦]关闭涂鸦工具",
                    desc : "",
                    value : "gra_close",
                    params: [],
                    func : ()=>{
                        this.gra_close();
                    },
                },{
                    label : "[涂鸦]切换涂鸦工具",
                    desc : "",
                    value : "gra_switch",
                    params: [],
                    func : ()=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        graffitiTool.switch();
                    },
                },{
                    label : "[涂鸦]切换模式",
                    desc : "参数[ mode ]。[edit] 编辑模式 [view]视图模式 ；（不传入mode就会进行切换）",
                    value : "gra_switch_mode",
                    params: ['mode'],
                    func : (params)=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        if(params?.mode){
                            if(params.mode === 'edit'){
                                graffitiTool.editMode();
                            }else{
                                graffitiTool.viewMode();
                            }
                        }else{
                            graffitiTool.switchMode();
                        }
                    },
                },{
                    label : "[涂鸦]改变当前绘制内容",
                    desc : `参数[ type ]。
                        [arrow] 绘制箭头
                        [freehand] 绘制笔
                        [circle] 绘制圆圈
                        [rectangle] 绘制长方形
                        [cloud] 绘制带花边的长方形
                        [text] 绘制文本
                    `,
                    params: ['type'],
                    value : "gra_change_tool",
                    func : (params)=>{
                        const graffitiTool = this.viewer.graffitiTool;
                        
                        if(params?.type){
                            graffitiTool.changeMode(params.type);
                        }
                    },
                },{
                    label : "[涂鸦]复制当前选中",
                    desc : ``,
                    value : "gra_copy",
                    params: [],
                    func : ()=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        graffitiTool.copy();
                    },
                },{
                    label : "[涂鸦]粘贴当前复制",
                    desc : ``,
                    value : "gra_paste",
                    params: [],
                    func : ()=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        graffitiTool.paste();
                    },
                },{
                    label : "[涂鸦]下一步",
                    desc : ``,
                    value : "gra_undo",
                    params: [],
                    func : ()=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        graffitiTool.undo();
                    },
                },{
                    label : "[涂鸦]上一步",
                    desc : ``,
                    value : "gra_redo",
                    params: [],
                    func : ()=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        graffitiTool.redo();
                    },
                },{
                    label : "[涂鸦]获取涂鸦数据",
                    desc : ``,
                    value : "gra_getdata",
                    params: [],
                    func : ()=>{
                        const graffitiTool = this.viewer.graffitiTool;

                        const data = graffitiTool.getGraffiti();
                        this.setResolveData("field_graffiti_data", data);
                        this.onAction("gra_ongetdata");
                    },
                },{
                    label : "[涂鸦]还原涂鸦数据",
                    desc : ``,
                    value : "gra_setdata",
                    params: ['data'],
                    func : ( params )=>{
                        if(!params.data) return ;

                        this.gra_setData(params.data);
                    },
                }]);
            },
            gra_initActionConfig(){
                this.registerActionConfig( "gra_ongetdata" , "获取涂鸦数据" );
            },
            gra_initEventListener(){
                const map = {
                    'ViewerBIM.Graffiti.getData' : (eid , cb )=>{
                        if( eid !== this.element.id)return;
                        const graffitiTool = this.viewer.graffitiTool;
                        const data = graffitiTool.getGraffiti();
    
                        cb && cb(data);
                    },
                    'ViewerBIM.Graffiti.close' : (eid )=>{
                        if( eid !== this.element.id)return;
                        this.gra_close();
                    },
                    'ViewerBIM.Graffiti.isActive' :( eid , cb )=>{
                        if( eid !== this.element.id)return;
                        cb && cb(this.gra_isActive());
                    },
                    'ViewerBIM.Graffiti.setData' : (eid,data)=>{
                        if( eid !== this.element.id)return;

                        this.gra_setData(data);
                    },
                    'ViewerBIM.Graffiti.open':(eid , mode)=>{
                        if( eid !== this.element.id)return;
                        
                        this.gra_open(mode);
                    },
                }

                for( let id in map){
                    eventBus.$on(id , map[id]);
                }
            },
            gra_close(){
                const graffitiTool = this.viewer.graffitiTool;

                graffitiTool.close();
            },
            gra_isActive(){
                return this.viewer.graffitiTool.active
            },
            gra_setData(data){
                const graffitiTool = this.viewer.graffitiTool;

                graffitiTool.loadGraffiti(data);
            },
            gra_open( mode ){
                const graffitiTool = this.viewer.graffitiTool;

                graffitiTool.open();
                
                if( mode === 'view'){
                    graffitiTool.viewMode();
                }
            },
        }
    }

    get func(){
        return {
            initViewerEventListener(){
                this.viewer.on(Viewer.Events.ViewerInited , ()=>{
                });
            },
            mounted (){
                this.gra_initEventListener();
                this.gra_initBehaviors();
                this.addResolveList('field_graffiti_data' , '当前涂鸦数据');
            }
        }
    }
}

