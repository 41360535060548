import { render, staticRenderFns } from "./widget-database.vue?vue&type=template&id=a0ca99fe&scoped=true"
import script from "./widget-database.vue?vue&type=script&lang=js"
export * from "./widget-database.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0ca99fe",
  null
  
)

export default component.exports