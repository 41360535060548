/*
 * @Description: 自定义配置页面状态
 * @Author: luocheng
 * @Date: 2022-06-14 16:22:36
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-11-06 14:53:06
 */
import Vue from 'vue';
import eventBus from '@/plugins/eventBus';

const vm = new Vue();
export default {
  state: {
    _PageStatusList: [],
    _PageCustomStatus: {}, // 页面状态
    _APPCustomStatus: {}, // 全局状态
    _PageStatusTagList: {} // 用于管理装填 防止一开始就触发事件
  },
  mutations: {
    /**
     * @desc: 初始化页面状态
     */
    initPageCustomStatus(state, statusList) {
      state._PageStatusList = statusList || [];
      for(let i = 0; i < statusList.length; i++) {
        const { code = '', useDefault = false, defaultValue, type } = statusList[i];
        if (+type === 1) {
          state._PageCustomStatus = {
            ...state._PageCustomStatus,
            [code]: useDefault ? defaultValue : undefined
          }
          state._PageStatusTagList = {
            ...state._PageStatusTagList,
            [code]: false
          }
        } else if (+type === 2) {
          state._APPCustomStatus = {
            ...state._APPCustomStatus,
            [code]: useDefault ? defaultValue : undefined
          }
          state._PageStatusTagList = {
            ...state._PageStatusTagList,
            [code]: false
          }
        }
      }
    },
    /**
     * @desc: 清除页面状态
     */
    clearPageCustomStatus(state) {
      vm.$set(state._PageCustomStatus, {});
    },
    /**
     * @desc: 改变页面状态
     * @param {String} key 状态key
     * @param {String/Number} value 状态值
     * @param {String} oringin 来源组件ID
     * @param {Object} resolveData 组件暴露值
     */
    updatePageCustomStatus(state, { key, value, origin, resolveData }) {
      // if (key === undefined) return;
      /* eslint-disable */ 
      const { _PageCustomStatus, _APPCustomStatus, _PageStatusList } = state;
      const allStatus = {
        ..._PageCustomStatus,
        ..._APPCustomStatus
      };
      let watchValue = false,
        eventList = [],
        eventWay = '',
        initTrigger = false,
        statusCode = '';
      // 此处历史原因使用undefined 注意
      let oldVal = null, newVal = null;
      if (key) {
        if (_PageCustomStatus.hasOwnProperty(key)) {
          newVal = value;
          // 页面状态
          oldVal = state._PageCustomStatus[key];
          state._PageCustomStatus[key] = value;
        } else if (_APPCustomStatus.hasOwnProperty(key)) {
          newVal = value;
          // 全局状态
          oldVal = state._APPCustomStatus[key];
          state._APPCustomStatus[key] = value;
        }
        window.$EditorDebug.successPageStatus(key, value);
        const target = _PageStatusList.find(ele => ele.code === key);
        if (target) {
          watchValue = target?.watchValue;
          eventList = target?.eventList;
          eventWay = target?.eventWay;
          initTrigger = target?.initTrigger;
          statusCode = target?.code;
          // 值改变
          if (watchValue) {
            if (!initTrigger && !state._PageStatusTagList?.[statusCode]) {
              state._PageStatusTagList[statusCode] = true;
              return;
            }
            if (eventWay === 'updated') {
              window.$EditorDebug.startPageStatusEvent(2);
              eventBus.$emit('EDITOR_statusEvent', eventList);
            } else if (eventWay === 'changed' && oldVal !== newVal) {
              // 值变化
              window.$EditorDebug.startPageStatusEvent(1);
              eventBus.$emit('EDITOR_statusEvent', eventList);
            }
          }
        }
      } else {
        // 通过组件绑定值改变
        for (let i = 0; i < _PageStatusList.length; i++) {
          const item = _PageStatusList[i];
          const {
            code,
            useComponentResolve = false,
            componentId = '',
            fieldUUID = ''
          } = item;
          if (useComponentResolve && componentId === origin.id && fieldUUID) {
            oldVal = allStatus?.[code] || undefined;
            watchValue = item.watchValue;
            eventList = item.eventList;
            eventWay = item.eventWay;
            initTrigger = item.initTrigger;
            statusCode = code;
            let usedResolveData = resolveData || origin?.resolveData;
            if (['resolveLength', 'fullLength'].includes(fieldUUID)) {
              const { containerData } = origin;
              if (fieldUUID === 'resolveLength') {
                newVal = Array.isArray(usedResolveData) ? usedResolveData.length : 0;
              }
              if (fieldUUID === 'fullLength') {
                newVal = Array.isArray(containerData) ? containerData.length : 0;
              }
            } else {
              // null 用于处理 +undefined为NAN
              newVal = usedResolveData?.[fieldUUID] || null;
            }
            if (_PageCustomStatus.hasOwnProperty(code)) {
              // 页面状态
              oldVal = state._PageCustomStatus[code];
              state._PageCustomStatus[code] = newVal;
            } else if (_APPCustomStatus.hasOwnProperty(code)) {
              // 全局状态
              console.log('全局状态');
              oldVal = state._APPCustomStatus[code];
              state._APPCustomStatus[code] = newVal;
            }
            // 值改变
            if (watchValue) {
              if (!initTrigger && state._PageStatusTagList?.[statusCode] === undefined) {
                state._PageStatusTagList[statusCode] = true;
                return;
              }
              if (eventWay === 'updated') {
                eventBus.$emit('EDITOR_statusEvent', eventList);
              } else if (eventWay === 'changed' && oldVal !== newVal) {
                // 值变化
                eventBus.$emit('EDITOR_statusEvent', eventList);
              }
            }
          }
        }
      }
    }
  }
}