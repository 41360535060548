<!--
    @name: TaskNode
    @description：TaskNode
    @author: ZengWei
    @date: 2022-05-13 16:35
-->
<template>
  <div class="swim-lane">
    <div class="line"></div>
    <div
      class="swim-lane-item"
      v-for="(item,index) in content"
      :key="index"
      :style="{height: swimHeight(item.ratio)}">
      <div class="left">{{item.name}}</div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwimLane",
  components: {},
  inject: ["getGraph", "getNode"],
  data() {
    return {
      content: []
    }
  },
  mounted() {
    const self = this
    const node = this.getNode()
    const data = node.getData()
    if(data){
      self.content = data?.content || []
    }
    // 监听数据改变事件
    node.on("change:data", ({ current }) => {
      self.content = current.content
    });
  },
  computed: {
    swimHeight(){
      return (ratio) => {
        let ratioAll = Array.from(this.content,t=>t.ratio)
        const sum = (arr) => {
          return arr.reduce((total, value) => {
            return parseInt(total) + parseInt(value);
          }, 0);
        }
        let total = sum(ratioAll)
        return (ratio/total)*100 + '%'
      }
    }
  },
  methods: {

  },
}
</script>

<style lang="less" scoped>
.swim-lane{
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #C2C5CC;
  border-radius: 8px;
  box-shadow: 0 4px 10px #0c1f5014;


  .line {
    position: absolute;
    left: 40px;
    top: 0;
    height: 100%;
    border-left: 2px solid #C2C5CC;
  }

  .swim-lane-item {
    height: 100%;
    border-bottom: 2px solid #C2C5CC;
    .left {
      width: 40px;
      height: 100%;

      text-align: center;
      writing-mode: vertical-lr;
      line-height: 40px;
    }
    &:nth-last-child(1){
      border-bottom: none;
    }
  }
}
</style>
