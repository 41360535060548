/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2024-01-22 17:48:52
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2024-04-26 15:44:21
 * @FilePath: \large-screen-next\src\views\dataViewLt\components\voiceAssistant\directiveControl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
let instance = null;
// import { mounted, beforeDestroy } from 'vue';

class DirectiveControl {
  constructor() {
    if(instance) {
      return instance;
    }
    this.directives = new Map();
    instance = this;
  }
  add(key, directive) {
    const { directives } = this;
    const multiple = Array.isArray(directive);
    if(directives.has(key)) {
      if(multiple) {
        directives.get(key).push(...directive);
      } else {
        directives.get(key).push(directive);
      }
    } else {
      if(multiple) {
        directives.set(key, directive);
      } else {
        directives.set(key, [directive]);
      }
    }
  }
  remove(key, directive = null) {
    const { directives } = this;
    const multiple = Array.isArray(directive);
    if(directives.has(key)) {
      if(directive) {
        if(multiple) {
          const arr = directives.get(key);
          for(let i = arr.length - 1; i >= 0; i --) {
            if(directive.includes(arr[i])) {
              directives.get(key).splice(i, 1);
            }
          }
        } else {
          const index = directives.get(key).findIndex((el) => el === directive);
          if(index > -1) {
            directives.get(key).splice(index, 1);
          }
        }
      } else {
        directives.delete(key);
      }
    } else {
      return '没有找到相关内容！';
    }
  }
  emit(message = '') {
    const { directives } = this;
    console.log(directives,'directives---directives');
    const keys = directives.keys();
    let currentKey = keys.next();
    // 查找 需要多个匹配的话需要更改
    while(!currentKey.done) {
      if(typeof currentKey.value === 'string') {
        if(message.includes(currentKey.value)) {
          break;
        }
      } 
      // else {
      //   if(currentKey.value.test && currentKey.value.test(message)) {
      //     break;
      //   }
      // }
      currentKey = keys.next();
    }
    if(currentKey) {
      const dirs = this.directives.get(currentKey.value);
      console.log(dirs, 'dirs--dirs');
      for (let i = 0; i < dirs?.length; i++) {
        const el = dirs[i];
        if(typeof el.key === 'string') {
          if(el.key === message) {
            if(el.args) {
              return el.cb(message,...el.args);
            } else {
              return el.cb(message);
            }
          }
        } else {
          // 将匹配的位置置为0,避免每次结果不一样
          el.key.lastIndex = 0;
          if(el.key.test && el.key.test(message)) {
            if(el.args) {
              return el.cb(message, ...el.args);
            } else {
              return el.cb(message);
            }
          }
        }
      }
    } else {
      return {
        msg: '没有找到相关内容！',
        notFind: 'true',
        done: true
      };
    }
  }
}

/**
 * @description: 注册的hooks
 * @param {*} key
 * @param {*} dir
 * @return {*}
 */
export const directiveRegister = (key, dir) => {
	console.log(key,dir)
  // const dirs = new DirectiveControl();
	// mounted (()=>{
	// 	dirs.add(key, dir);
	// })
  // beforeDestroy(() => {
  //   dirs.remove(key, dir);
  // });
};

/**
 * @description: 计算分词匹配度
 * @param {*} content
 * @param {*} message
 * @return {*} 
 */
export const matchingDegree = (content, message) => {
  const keys = Array.from(new Intl.Segmenter('cn', { granularity: 'word'}).segment(message));
  const len = keys.length;
  let count = 0;
  keys.forEach(el => {
    if(content.includes(el.segment)) {
      count += 1;
    }
  });
  return count / len;
}

export default DirectiveControl;
