<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-11-20 15:29:44
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-23 16:43:26
 * @FilePath: /dataview-viewer-test/src/custom-component/tree/TreeIcon.vue
 * @Description: 前置
-->
<template>
  <div class="tree-icon" v-if="config && iconStr">
    <!-- element图标 -->
    <i :class="iconStr" v-if="iconStr.indexOf('el-') === 0"></i>
    <!-- 图片 -->
    <img :src="iconStr" alt="" v-else-if="iconStr.indexOf('http') === 0">
    <!-- 阿里图标库 -->
    <i :class="['iconfont', iconStr]" v-else></i>
  </div>
</template>

<script>
import { judgingEventLogic } from '@/utils/tools';

export default {
  name: 'TreeIcon',
  props: {
    config: {
      type: Object,
      isRequired: true,
      default: () => {}
    },
    rowData: {
      type: Object,
      isRequired: true,
      default: () => {}
    },
    // 类型 pre 前置， action 操作
    type: {
      type: String,
      isRequired: true,
    },
    componentData: {}
  },
  data() {
    return {}
  },
  computed: {
    // 图标字符串
    iconStr() {
      const { type = '', fixedConfig = {}, customConfig = {} } = this.config;
      if (!type) {
        if (this.type === 'action') {
          return 'el-icon-more';
        }
        return '';
      }
      // 固定
      if (type === 'fixed') {
        if (this.type === 'pre') {
          return fixedConfig?.preIcon;
        }
        if (this.type === 'action') {
          return fixedConfig?.actionIcon || 'el-icon-more';
        }
        return '';
      }
      // 自定义
      if (type === 'custom') {
        if (this.type === 'pre') {
          return this.getCustomIcon(customConfig.preList);
        }
        if (this.type === 'action') {
          return this.getCustomIcon(customConfig.actionList) || 'el-icon-more';
        }
        return '';
      }
      // 逻辑
      return '';
    }
  },
  methods: {
    /**
     * @description: 获取自定义图标
     * @param {Array} configList
     * @return {String} 图标
     */
    getCustomIcon(configList = []) {
      for (let i = 0; i < configList.length; i++) {
        const item = configList[i];
        const { origin = 'fixed', icon = '', logics = [] } = item;
        let logicBoolean = true;
        if (logics.length) {
          logicBoolean = judgingEventLogic(logics, this.componentData || [], this.rowData);
        }
        if (logicBoolean) {
          if (origin === 'field') {
            // 字段取值
            return this.rowData?.[icon] || '';
          }
          if (icon) {
            return icon;
          }
        }
      }
      return '';
    }
  }
}
</script>

<style lang="less" scoped>
.tree-icon {
  z-index: 1;
  padding-right: 4px;
  line-height: 26px;
  i {
    line-height: 30px;
    font-size: 14px;
  }
  img {
    height: 14px;
    width: 14px;
    // margin-top: 12px;
    margin-top: 6px;
  }
}
</style>