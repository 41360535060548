<template>
    <div class="moduleWrap horizontal-flex">
        <div class="horizontal-flex item-wrap">
            <span class="item-content-long">A：{{lng}}</span>
            <span class="item-content-long">B：{{lat}}</span>
        </div>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';

    export default {
        name: "ViewerGISMeasurePoint",
        data() {
            return {
                bindViewerId: null,
                coordType: 'WGS84',
                lng: 0,
                lat: 0
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            this.initCheckBind();

            this.initViewerInitedListener(this.bindViewerId, (opt = {}) => {
                let {viewerInited = false} = opt;
                if(viewerInited){
                    this.getMeasureData();
                }
            });
        },
        methods: {
            initEventListener() {
                eventBus.$on('ViewerGISMeasurePoint.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== 'ViewerGIS') {
                                if (item.children && item.children.length > 0)
                                    loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                        coordType: this.coordType,
                    });
                });

                eventBus.$on('ViewerGISMeasurePoint.setSource', (eid, {bindViewerId, coordType}) => {
                    if (eid !== this.element.id) return;
                    if (this.checkBind(bindViewerId)){
                        this.element.viewerBindId = bindViewerId;
                        this.bindViewerId = bindViewerId;
                        this.$message.success(`[${this.element.name}] 绑定成功`);
                    }

                    this.element.coordType = coordType;
                    this.coordType = coordType;
                });
            },
            initViewerInitedListener(bindId = this.bindViewerId, cb = null){
                function listener() {
                    eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                        if (eid !== bindId) return;

                        cb && cb(opt);
                    });
                }

                if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && bindId){
                    eventBus.$emit("ViewerGIS.getViewerInitedFlag", bindId, (opt = {}) => {
                        let {viewerInited = false, dynamicLoaded = false} = opt;
                        if(viewerInited && dynamicLoaded){
                            cb && cb(opt);
                        }else{
                            listener();
                        }
                    });
                }else{
                    listener();
                }
            },
            initCheckBind(){
                if (this.element.viewerBindId) {
                    if (this.checkBind(this.element.viewerBindId)) {
                        this.bindViewerId = this.element.viewerBindId;
                    }
                }

                if (this.element.coordType) {
                    this.coordType = this.element.coordType;
                }
            },
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);
                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },

            getMeasureData(){
                eventBus.$emit(`ViewerGIS.setMeasurePoint`, this.bindViewerId, this.coordType, (xyz) => {
                    this.lng = xyz.lng;
                    this.lat = xyz.lat;
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: inherit;
        backdrop-filter: blur(2px);
    }

    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .item-wrap{
        height: 40px;
        border-radius: 4px;
        color: inherit;
    }

    .item-content-long{
        width: 130px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        color: inherit;
        font-size: 16px;
    }

    .item-icon{
        width: 50px;
        height: 40px;
        line-height: 40px;
        font-size: 36px;
        color: inherit;
    }
</style>
