<!--
 * @Description: tabs
 * @Author: luocheng
 * @Date: 2021-10-08 17:11:12
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-08-19 17:30:54
-->
<template>
  <div v-loading="loading" class="pile-foundation-cont">
    <div class="inner-top-handle">
        <div class="left-side">
          <div class="top-handle">
            <p>下钻模拟2D回放</p>
            <el-button size="small"  @click="reset()">重置</el-button>
            <el-dropdown trigger="click">
              <div class="el-dropdown-link" style="height: 32px; line-height: 32px">
                倍数：{{ checkedMultiple.label.replace("x", "") }} <i class="iconfont iconxiala"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in multipleData" :key="index">
                  <p
                    :style="{ color: checkedMultiple.type === item.type ? '#337EFF' : '' }"
                    @click="handleFilterScale(item)"
                  >
                    {{ item.label }}
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="progress-cont">
            <el-progress :percentage="percentNUm" style="margin-top: 10px; width: 100%" />
          </div>
        </div>
        <div class="right-side">
          <i
            class="iconfont right-handle-icon"
            :class="[
              isPlay ? 'iconkaishi' : 'iconzanting',
              machineData.length ? '' : 'disable-play',
            ]"
            @click="onPlay"
          />
        </div>
    </div>
    <div class="base-soil" />
    <div id="rotor-cont">
      <div class="top-rotor">
        <div class="inner-box"></div>
      </div>
      <div id="bottom-rotor">
        <div v-for="(item,index) in 5" :key="index" class="drill-box" :style="{top:`${index * 35}px`}" :class="item%2 === 0?'even-box':''">
           <div class="inner-box"></div>
        </div>
      </div>
      <div v-show="currentMachine?.design_length <= currentMachine?.actual_depth" class="inner-soil"></div>
    </div>
    <div class="bottom-soil"></div>
    <div v-show="currentMachine" :id="currentMachine?.design_length <= currentMachine?.actual_depth?'actual-cont':'design-cont'">
       <div>
        <p>实际桩长</p>
        <p class="num green-font">{{currentMachine?.actual_depth}}M</p>
       </div>
    </div>
    <div v-show="currentMachine" :id="currentMachine?.design_length > currentMachine?.actual_depth?'actual-cont':'design-cont'">
      <div>
        <p>设计桩长</p>
        <p class="num">{{currentMachine?.design_length}}M</p>
       </div>
    </div>
    <ul v-if="currentMachine" class="detail-cont">
      <li v-for="(item,index) in detailList" :key="index" :class="item.twoCol?'two-col':''">
        <div v-if="item.twoCol" class="item-value">{{currentMachine[item.value] }}</div>
        <div class="item-label">{{ item.label }}</div>
        <div v-if="!item.twoCol" class="item-value">{{  Number(currentMachine[item.value]).toFixed(2) }}</div>
        <div class="item-unit">{{ item.unit }}</div>
      </li>    
    </ul>
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data/index';
import { removeEventBus } from '@/utils/tools';
import { Dropdown, DropdownMenu, DropdownItem, Progress } from 'element-ui';
import { initParams } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
  name: 'CommonPileFoundation',
  components:{
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem,
    'el-progress': Progress,
  },
  props: {
    element: {
      type: Object,
      required: false,
      default: () => {}
    },
    componentList: {
      default: null
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      timer: null,
      lineTimer: null,
      dropSpeed: 3000, // 控制下落速度，数值越小，速度越快
      percentNUm: 0, 
      isPlay: false,
      interfaceFinished: true,
      checkedMultiple :{
        label: '1.0x',
        type: 200
      },
      multipleData:[
        { label: '10.0x', type: 500 },
        { label: '5.0x', type: 1000 },
        { label: '1.0x', type: 3000 },
        { label: '0.5x', type: 6000 }
      ],
      machineData:[],
      currentMachine:null,
      lineIndex: 0,
      detailList:[
        {value:'depth',label:'深度:',twoCol:false,unit:'m'},
        {value:'drilling_speed',label:'转速:',twoCol:false,unit:'m/min'},
        {value:'pipe_current',label:'电流:',twoCol:false,unit:'A'},
        {value:'flow',label:'流量:',twoCol:false,unit:'L/min'},
        {value:'station_number',label:'当前桩号',twoCol:true,unit:''},
        {value:'design_length',label:'设计桩长',twoCol:true,unit:''},
      ],
      loading:false,
    };
  },
  watch: {
    currentMachine: {
      handler(val) {
        setTimeout(()=>{
          let idStr = 'design-cont';
          if(+val.actual_depth > +val.design_length){
            idStr = 'actual-cont';
          }
          const actualDiv = document.getElementById(idStr).getBoundingClientRect();
          const rotorDiv = document.getElementById('rotor-cont');
          if(actualDiv && rotorDiv){
            const long = (actualDiv.height / Number(val.actual_depth)) * Number(val.depth);
            rotorDiv.style.bottom = `calc(50% - ${long}px)`;
          }
          
        },0);
      },
      deep: true
    }
  },
 
  computed: {
    ...mapState(['componentData', 'subsidiaryComponentData']),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
    database() {
			return this.element && this.element.database;
		},
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: data => {
        if (this.tabOrigin !== 'dynamic') return;
        let { paramsConfig } = this.element.database;
        let isTarget = false;
        for (let i = 0; i < paramsConfig.length; i++) {
          if (paramsConfig[i].componentId === data.componentId) {
            isTarget = true;
          }
        }
        if (!isTarget && !data.isInit && !data.isUpdate) return;
        this.getTabList(this.dynamicConfig);
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
    if (!this.interfaceFinished) return;
    this.interfaceFinished = false;
    const {
      search = [],
      param = {},
      canPost
    } = initParams(
      this.element?.database?.paramsConfig || [],
      this.isGroup,
      this.subComponentData,
      this.groupComponents,
      this.element
    );
    if (!canPost) {
      this._failDataDebug('参数必填校验未通过');
      return;
    }
    this.getList(this.element.database, search, param);
 },
  methods: {
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (database.mapping === 'interface' && database.interfaceConfig) return true;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.tableData = [];
			if (!this.validDatabase(database)) {
				this.interfaceFinished = true;
				this._failDataDebug('请求配置错误');
				return;
			}
			// 外部参数
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			this.loading = true;
			const paramsObj = {
				...params,
				...outParams,
				page: 1,
				size: 10000,
				search
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			let url = '/api/mapi';
			if (mapping === 'interface') {
				configObj = {
					...paramsObj
				}
				if (database?.interfaceConfig?.url?.indexOf('http') === -1) {
					url = `/api${database?.interfaceConfig?.url}`;
				} else {
					url = database?.interfaceConfig?.url
				}
			} else if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj
				};
			}
      this.loading = true;
			dataInterface(configObj, url).then((res) => {
        this.loading = false;
        this.interfaceFinished = true;
				if (res && res.status === 200) {
          this.machineData = res.data.data;
          this.currentMachine = res.data.data[0] || null;
        }
			}).catch((err) => {
				console.log(err);
				this.interfaceFinished = true;
				this.loading = false;
				this._errorDataDebug({
					url,
					content: configObj,
					err
				})
			});
		},
    /**
		 * @desc: 模拟动画
		 * @param {Object} 
		 */
    animate () {
      const container = document.getElementById('bottom-rotor');
      const box = document.getElementsByClassName('drill-box');
      box.forEach(box => {
        // 计算盒子的新top值
        let newTop = box.offsetTop + 5;
        if (newTop > container.offsetHeight - 20) {
          newTop = -10; // 重置位置
        }
        box.style.top = `${newTop}px`;
      });
      // 继续动画
      this.timer = setTimeout(this.animate, 200);
    },
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
    handleFilterScale (item) {
      clearTimeout(this.timer);
      clearInterval(this.lineTimer);
      this.dropSpeed = item.type;
      this.percentNUm = 0;
      this.checkedMultiple = item;
      this.isPlay = false;
    },
    /**
		 * @desc: 播放
		 */
    onPlay(){
      
      clearTimeout(this.timer);
      clearInterval(this.lineTimer);
      if (this.percentNUm === 100) {
        this.lineIndex = 0;
        this.percentNUm = 0;
      }
      if(!this.isPlay){
        if(!this.machineData?.length) return;
        this.animate();
        this.currentMachine = this.machineData[0];
        this.isPlay = !this.isPlay;
        this.lineTimer = setInterval(() => {
          this.lineIndex++;
          this.currentMachine = this.machineData[this.lineIndex - 1];
          this.percentNUm = Math.min(Number((this.lineIndex / this.machineData.length * 100).toFixed(1)),100);
          if (this.percentNUm === 100) {
            this.currentMachine = this.machineData[this.machineData.length - 1];
            clearTimeout(this.timer);
            clearInterval(this.lineTimer);
            this.isPlay = false;
          }
        }, this.dropSpeed);
      } else {
        this.isPlay = !this.isPlay;
      }
    },
    reset(){
      clearTimeout(this.timer);
      clearInterval(this.lineTimer);
      this.isPlay = false;
      this.lineIndex = 0;
      this.percentNUm = 0;
    }
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
     removeEventBus('databaseTrigger', this.element.id);
    }
    clearTimeout(this.timer);
    clearInterval(this.lineTimer);
  }
};
</script>

<style lang="less" scoped>
  .pile-foundation-cont{
    width: 100%;
    height: 100%;
    position:relative;
    background: linear-gradient(180deg, #D2E1FE 0%, #F6FAFC 100%);
    overflow: hidden;
    .inner-top-handle{
      width: 80%;
      margin: auto;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      padding: 8px 0;
      position: relative;
      top: 16px;
      z-index: 100;
      .left-side{
        flex: 1;
        margin:0 10px;
        .top-handle{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 14px 0 0;
          height: 32px;
          line-height: 32px;
          margin-bottom: 16px;
        }
        p{
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          color: var(--text-on-surface-primary, #181B22);
        }
      }
      .right-side{
        .right-handle-icon {
          font-size: 34px;
          color: #fff;
          background: #337eff;
          border-radius: 50%;
          padding: 10px;
          margin-right: 10px;
          line-height: 65px;
        }
        .disable-play {
          opacity: 0.3;
        }
      }
    }
    .base-soil{
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0px;
      left: 0;
      background: url('./land.png') no-repeat;
      background-size: cover;
      z-index: 5;
    }
    #rotor-cont{
      width:4%;
      height: 80%;
      background: linear-gradient(180deg, #D2E1FE 0%, #F6FAFC 100%);
      position: absolute;
      left: calc(30% - 20px);
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      z-index: 7;
      overflow: hidden;
      .top-rotor{
        flex: 1;
        width: 100%;
        background: url('./top-rotor.png') no-repeat center;
        background-size: cover;
        position: relative;
        .inner-box{
          position: absolute;
          top: 40px;
          left: -30%;
          width: 160%;
          height: 40px;
          background: linear-gradient(270deg, #737373 0%, #D9D9D9 49.43%, #737373 100%);
        }
      }
      #bottom-rotor{
        height: 180px;
        width: 100%;
        background: url('./bottom-rotor.png') no-repeat;
        background-size: cover;
        position: relative;
        z-index: 7;
        .drill-box{
          height: 30px;
          width: 160%;
          margin-bottom:10px;
          margin-left: -30%;
          overflow: hidden;
          position: absolute;
          .inner-box{
            height: 18px;
            width: 130%;
            margin-top:8px;
            margin-left: -7px;
            transform: rotateZ(6deg);
            background: linear-gradient(270deg, #786F50 3.67%, #F7E9C6 52.67%, #786F50 100%);
          }
        }
        .even-box{
          margin-left: -20%;
          width: 140%;
        }
      }
      .inner-soil{
        width:calc(100% - 2px);
        height: 40px;
        background: #964C3A;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 6;
        border:2px dotted #2FA45A;
        border-top:none;
      }
      
    }
    .bottom-soil{
      width: 100%;
      height: 20%;
      border-radius: 60% 14% 0 0;
      background: #964C3A;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 6;
    }
    #design-cont{
      width: 42px;
      height: calc(44% - 40px);
      position: absolute;
      bottom:calc(6% + 40px);
      left: calc(30% - 62px);
      background: url('./arrow.png') no-repeat;
      background-size: 100% 100%;
      z-index: 8;
      div{
        position: relative;
        width: 100%;
        height: 100%;
        p{
          position: absolute;
          width: 60px;
          left: -60px;
          top: 60px;
          color: #181B22;
          text-align: right;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.4px;
        }
      }
    }
    #actual-cont{
      width: 60px;
      height: 44%;
      position: absolute;
      bottom:6%;
      left: calc(34% + 20px);
      background: url('./arrow.png') no-repeat;
      background-size: 100% 100%;
      z-index: 11;
      div{
        position: relative;
        width: 100%;
        height: 100%;
        p{
          position: absolute;
          width: 100px;
          left: 50px;
          top: 60px;
          text-align: left;
          color: #181B22;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.4px;
        }
      }
    }
    .num {
      top: 90px!important;
      color: #181B22!important;
      font-family: YouSheBiaoTiHei!important;
      font-size: 16px!important;
      font-weight: 400!important;
      line-height: 24px!important;
    }
    .green-font{
      color: #2FA45A!important;
    }
    .detail-cont{
      width: 150px;
      padding: 12px;
      height: auto;
      background: url('./detail.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: 10%;
      bottom: 30%;
      z-index: 10;
      li{
        width: 100%;
        line-height: 21px;
        font-size: 12px;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
        text-align: left;
        color:#fff;
        display: flex;
        flex-direction: row;
        .item-label{
          color: #5BABFF;
          font-family: "Source Han Sans CN";
          font-size: 14px;
          line-height: 21px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .item-value{
          text-align: center;
          font-family: YouSheBiaoTiHei;
          font-size: 16px;
          line-height: 21px;
          margin:  0 4px 0 10px;
          font-style: normal;
          font-weight: bold;
          line-height: normal;
          background: linear-gradient(180deg, #FFF 33.47%, #C4DFFE 88.31%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .item-unit{
          line-height: 21px;
        }
      }
      .two-col{
        flex-direction: column;
        text-align: center;
        margin-top: 13px;
        margin-bottom: 0
      }

    }

  }
</style>
