<!--
    @name: widget-tab
    @description：widget-tab
    @author: ZengWei
    @date: 2022-03-25 09:41
-->
<template>
  <div class="widget-tab">
    <el-form
      ref="childForm"
      size="medium"
      :model="element"
      :class="{'form-parser':formData.tableStyle,'normal-parser': !formData.tableStyle}"
      :show-message="!formData.tableStyle"
      :label-width="formData.labelWidth + 'px'"
      :label-position="formData.labelPosition"
    >
      <el-tabs
        v-model="element.config.__config__.activeName"
        :type="element.config.__config__.type"
        :tab-position="element.config.__config__.tabPosition"
      >
        <el-tab-pane
          v-for="(tab,tabInd) in element.config.__tabs__"
          :key="tabInd"
          :label="tab.label"
          :name="tab.name">
          <el-row :gutter="gutter">
            <template v-for="(item, ii) in element.value[tabInd]">
              <el-col
                :key="item.config.__config__.formId"
                v-if="showHideField(item.config.__config__)"
                :span="item.config.__config__.span"
              >
                <el-form-item
                  :prop="'value.' + tabInd + '.' + ii + '.value'"
                  :rules="item.validate"
                  :label="showLabel(item)"
                  :label-width="labelWidth(item)"
                >
                  <InputItem
                    ref="tabChildForm"
                    :element="item"
                    :disabled="canEditField(item.config.__config__.formId)"
                    :formData="formData"
                    :display-data="displayData"
                    :editFields="editFields"
                    :key="element.config.__config__.formId"
                    @trigger-active="triggerActive(arguments)"
                  >
                  </InputItem>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>

</template>

<script>
/* eslint-disable */
import {Tabs,TabPane} from "element-ui";
export default {
  name: "WidgetTab",
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane
  },
  props: ['element', 'disabled', 'displayData', 'formData', 'editFields'],
  inject: ["parser"],
  data() {
    return {}
  },
  computed: {
    gutter(){
      if(this.formData.tableStyle){
        return 0
      } else {
        return this.formData.gutter || 15
      }
    },
    showLabel() {
      return function (item) {
        const config = item.config.__config__
        if (item.config.__ques__ || config.showLabel === undefined || config.showLabel === false)
          return "";
        return config.label;
      };
    },
    labelWidth(){
      return function (item) {
        if(item.type === 'form'){
          if(item.config.__config__.componentName === ""){
            return "0px"
          }
        }
        const labelWidth = item.config?.__config__?.labelWidth ?
          item.config.__config__.labelWidth + 'px' : '120px'
        return  labelWidth
      }
    },
    canEditField() {
      return function (formId) {
        if(this.parser?.formMode === 'edit') return this.disabled;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId)){
          if(this.parser?.newFlow){
            return !(this.editFields[formId] === 1);
          }
          return this.editFields[formId];
        }
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        if(this.parser?.formMode === 'edit') return config.addShow;
        const formId = config.formId;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)) return true;
        return config.addShow;
      };
    }
  },
  mounted() {

  },
  methods: {
    getChildFormData(){
      const childFormData = {}
      const tabs = this.element.value
      if(tabs instanceof Array){
        for (const tab of tabs){
          for (const ele of tab){
            if (ele.type === "form") {
              let fieldData = [];
              const equalField = ele.config.__config__.addShow;
              if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
                for (let eleData of ele.value) {
                  let itemField = {};
                  for (let eleItem of eleData) {
                    itemField[eleItem.field] = eleItem.value;
                  }
                  fieldData.push(itemField);
                }
                childFormData[ele.field] = fieldData;
              }
            } else if (ele.type === "row") {
              if (ele.value) {
                for (let eleItem of ele.value) {
                  childFormData[eleItem.field] = eleItem.value;
                }
              }
            } else {
              if (ele.field) {
                childFormData[ele.field] = ele.value;
              }
            }
          }
        }
      }
      return childFormData
    },
    validateFormInput(){
      for (const index in this.$refs.tabChildForm){
        if(this.$refs.tabChildForm[index]?.validateFormInput){
          this.$refs.tabChildForm[index].validateFormInput()
        }
      }
      this.$refs.childForm.validate((valid) => {
        if (!valid) {
          throw new Error('数据验证失败')
        }
      })
    },
    triggerActive(params) {
      const compShowHide = params[0]; // 受控控件
      const ctrlType = params[1] ?? '' // 交互类型 - 默认显示隐藏
      const optionData = params[2] ?? ''// 级联数据

      if (ctrlType === 'cascader') {

      } else if (ctrlType === 'selectFill') {

      } else {
        // 显示隐藏交互
        const ctrlFormId = Object.keys(compShowHide);
        const childFormItem = this.element.value;

        if (childFormItem instanceof Array) {
          for (let eleItem of childFormItem) {
            for (const item of eleItem) {
              if (item.config) { //关联子表展示数据无配置
                const childFormId = item.config.__config__.formId;
                if (ctrlFormId.includes(childFormId)) {
                  item.config.__config__.addShow = compShowHide[childFormId]
                }
              }
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.widget-tab{
  //border: 1px solid #eaebed;
}
</style>
