import IBehaviors from './IBehaviors';

export default class PlotBehaviors extends IBehaviors {
    constructor(viewer, comp) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        return [
            {
                label: '[标绘]创建多边形标绘-BIM商住',
                desc: `
                    参数[ data, isShow ]。 
                    data=>多边形对象数据
                    options=>标绘样式对象
                    isShow=>是否默认显示，选填，默认为true
                    `,
                value: 'plot_createPolygon',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.data) return console.warn("[参数出错]");

                    let data = params.data;
                    if(typeof data === "string"){
                        data = JSON.parse(data);
                    }

                    if (!data.coverData || data.coverData.length === 0) return console.warn("[创建多边形对象参数出错]");

                    for (let i = 0; i < data.coverData.length; i++){
                        let positions = data.coverData[i];
                        this.viewer.Plot.createPlot(undefined, {
                            plotType: 'polygon',
                            positions: positions,
                            options: params.options
                        }, params.isShow);
                    }
                }
            },
            {
                label: '[标绘]删除全部标绘数据',
                desc: `
                    参数[ 无 ]。 
                    `,
                value: 'plot_removeAllPlot',
                func: () => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Plot.removeAllPlot();
                }
            },
            {
                label: '[标绘]创建线对象',
                desc: `
                    参数[ data ]。 
                    data => 通用数据对象
                    `,
                value: 'plot_createPolyline',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.data) return console.warn("[参数出错]");

                    let data = params.data;
                    if(typeof data === "string"){
                        data = JSON.parse(data);
                    }

                    for (let temp of data) {
                        if (!temp.polyline || temp.polyline.length === 0) continue;

                        let modelId = temp.model_id;
                        let array = temp.polyline;
                        for (let item of array) {
                            let positions = [];
                            if(item.dbids){
                                for (let dbid of item.dbids){
                                    let bound = this.viewer.Model.getBoundByKeys(modelId, [dbid]);
                                    if(bound){
                                        positions.push({
                                            lng: (bound.west + bound.east) / 2,
                                            lat: (bound.south + bound.north) / 2,
                                            height: (bound.minHeight + bound.maxHeight) / 2
                                        })
                                    }
                                }
                            }else{
                                positions = item.position;
                            }


                            let id = item?.options?.id;
                            this.viewer.Plot.createPlot(id, {
                                plotType: 'polyline',
                                positions: positions,
                                options: item.options
                            }, true);
                        }
                    }

                }
            },
            {
                label: '[标绘]创建线对象并定位',
                desc: `
                    参数[ data ]。 
                    data => 通用数据对象
                    `,
                value: 'plot_createPolylineLocation',
                func: (params) => {
                    if (!this.viewer) return this.comp.$message.error('操作失败，viewer未初始化');
                    if(!params.data) return console.warn("[参数出错]");

                    let data = params.data;
                    if(typeof data === "string"){
                        data = JSON.parse(data);
                    }

                    let positions = [];
                    data.map((item) => {
                        if(item instanceof Array) {
                            positions.push({lng: item[0], lat: item[1]});
                        }else {
                            positions.push(item);
                        }
                    });
                    if(positions[0].lng !== positions[positions.length - 1].lng ||
                        positions[0].lat !== positions[positions.length - 1].lat){
                        positions.push(positions[0]);
                    }

                    if(this.viewer.Plot.plotCollection[params.key]){
                        this.viewer.Plot.locationPlot(params.key, 90);
                        return;
                    }

                    let view = this.viewer.Scene.getCameraView();

                    this.viewer.Plot.createPlot(`polygon_${params.key}`, {
                        plotType: 'polygon',
                        positions: positions,
                        options: {
                            fillColor: '#ffffff',
                            alpha: 20
                        }
                    }, true);
                    this.viewer.Plot.createPlot(`polyline_${params.key}`, {
                        plotType: 'polyline',
                        positions: positions,
                        options: {
                            fillType: 'dashLine',
                            fillColor: '#ff0000',
                            fillWidth: 7
                        }
                    }, true);
                    this.viewer.Plot.locationPlot(params.key, {
                        angle: 90,
                        heading: view.heading,
                        range: 300
                    });

                }
            },
        ];
    }
}
