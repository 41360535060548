<!--
 * @Date: 2023-11-20 09:28:08
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-01-16 10:27:06
 * @FilePath: /dataview-next/src/custom-component/UEClient/UEStreamViews.vue
-->
<template>
    <ViewPointSingle
        ref="viewPointTree"
        class="moduleWrap"
        :element.sync="element"
        :viewFilter="viewFilter"
        :viewFilterIndex="0"
        :useDefaultView="false"
        @addGroup="onAddGroup"
        @addViewPoint="onAddViewPoint"
        @renameGroup="onRenameGroup"
        @deleteGroup="onDeleteGroup"

        @setViewPoint="onSetViewPoint"
        @renameViewPoint="onRenameViewPoint"
        @refreshViewPoint="onRefreshViewPoint"
        @deleteViewPoint="onDeleteViewPoint"
    ></ViewPointSingle>
</template>
  
<script>
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { dataInterface } from '@/apis/data/index';
import ViewPointSingle from "@/custom-component/viewerComponent/ViewPointSingle";

    
const DataBaseInfo = {
    Group: {
        object_uuid: 'object655af85b8030b',
        view_uuid: {
            all: 'view655af89b02cdd',
        }
    },
    Views: {
        object_uuid: 'object655af66e7fda9',
        view_uuid: {
            all: 'view655af71062b48',
        }
    }
};

export default {
    name: 'UEStreamViews',
    components: {
    ViewPointSingle
    },
    data() {
        return {
            bindViewerId : null,
            viewFilter : [{
                id : 'UEViews',
                name : "UE推流视点列表",
            }],
            UEID : "",
            isInit : false,
        };
    },
    props: {
    },
    mixins: [CustomComponentMixin],
    computed: {
        ...mapState(['componentData', 'originComponentData'])
    },
    methods: {
        async onAddViewPoint( group ){
            if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);

            let name = await this.$prompt('请输入视点名称：', '提示').catch(() => {});
            if(!name) return;
            eventBus.$emit('UEStreamClient.getViewState', this.bindViewerId , async ( vs )=>{
                const state = JSON.stringify(vs);
                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Views.object_uuid,
                    view_uuid: DataBaseInfo.Views.view_uuid['all'],
                    name: name.value,
                    group_id: group.id.split('_')[1],
                    img: '',
                    state,
                    isDefault: 'false',
                    UEID : this.UEID,
                });

                if (res.status !== 200) return;

                const id = res.data.data.id;
                const created_at = res.data.data.created_at;
                
                let item = {
                    id: `child_${id}`,
                    type: 'Child',
                    name: name.value,
                    tooltip: '',
                    group_id: group.id.split('_')[1],
                    img: null,
                    state : vs,
                    created_at,
                    isDefault: false
                };
                group.children.push(item);

                this.$message.success('视点创建成功！');
            });

        },
        async onAddGroup(){
            if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);
            let name = await this.$prompt('请输入分组名称：', '提示').catch(() => {});
            if(!name) return;

            const res = await dataInterface({
                __method_name__: 'createData',
                object_uuid: DataBaseInfo.Group.object_uuid,
                view_uuid: DataBaseInfo.Group.view_uuid['all'],
                type: 'ViewState',
                name: name.value,
                UEID : this.UEID,
            });

            if (!res?.data?.data || res.status !== 200){
                return this.$message.warning(`[UE]创建视点分组失败！`);
            }
            
            const data = res?.data?.data;
            let item = {    
                id: `group_${data.id}`,
                name: name.value,
                type: 'Group',
                children: [],
            };
            this.$refs.viewPointTree.appendTreeData(item, null);
            this.$message.success(`创建视点分组成功！`);
        },
        async onRenameGroup(data){
                        if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);

            let name = await this.$prompt('请输入分组名称：', '提示', {
                inputValue: data.name
            }).catch(() => {});
            if(!name) return;

            await dataInterface({
                __method_name__: 'updateData',
                object_uuid: DataBaseInfo.Group.object_uuid,
                view_uuid: DataBaseInfo.Group.view_uuid['all'],
                data_id: data.id.split('_')[1],
                name: name.value
            });
            
            this.$refs.viewPointTree.updateTreeData(data.id, 'name', name.value);
            this.$message.success(`视点分组重命名成功！`);
        },
        async onDeleteGroup(data){
                        if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);

            if(data.children){
                let children = [];
                for(let item of data.children){
                children.push({
                    id: item.id,
                    group_id: item.group_id
                })
                }

                for(let item of children){
                await this.onDeleteViewPoint(item);
                }
            }

            await dataInterface({
                __method_name__: 'deleteData',
                object_uuid: DataBaseInfo.Group.object_uuid,
                view_uuid: DataBaseInfo.Group.view_uuid['all'],
                data_id: data.id.split('_')[1]
            });
            this.$refs.viewPointTree.deleteTreeData(data.id);
            this.$message.success(`视点分组删除成功！`);
        },
        onSetViewPoint(data){
            eventBus.$emit('UEStreamClient.setViewState', this.bindViewerId, data.state);
        },
        async onRenameViewPoint( data ){
                        if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);
            let name = await this.$prompt('请输入视点名称：', '提示', {
                inputValue: data.name
            }).catch(() => {});
            if(!name) return;

            await dataInterface({
                __method_name__: 'updateData',
                object_uuid: DataBaseInfo.Views.object_uuid,
                view_uuid: DataBaseInfo.Views.view_uuid['all'],
                data_id: data.id.split('_')[1],
                name: name.value
            });

            this.$refs.viewPointTree.updateTreeData(data.id, 'name', name.value, `group_${data.group_id}`);
            this.$message.success(`视点对象重命名成功！`);
        },
        async onRefreshViewPoint( data ){
            if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);

            eventBus.$emit('UEStreamClient.getViewState', this.bindViewerId , async ( vs )=>{


                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Views.object_uuid,
                    view_uuid: DataBaseInfo.Views.view_uuid['all'],
                    data_id: data.id.split('_')[1],
                    state: JSON.stringify(vs)
                });
                this.$refs.viewPointTree.updateTreeData(data.id, 'state', vs, `group_${data.group_id}`);
                this.$message.success('视点更新成功！');
            });
        },
        async onDeleteViewPoint(data){
                        if( !this.bindViewerId || !this.UEID) return this.$message.warning(`[UE]操作失败,请先绑定客户端`);
            
            await dataInterface({
                __method_name__: 'deleteData',
                object_uuid: DataBaseInfo.Views.object_uuid,
                view_uuid: DataBaseInfo.Views.view_uuid['all'],
                data_id: data.id.split('_')[1]
            });

            this.$refs.viewPointTree.deleteTreeData(data.id, `group_${data.group_id}`);
            this.$message.success('视点删除成功！');
        },
        //刷新列表数据
        async refreshList(){
            this.$refs.viewPointTree.emptyTreeData();
            this.$refs.viewPointTree.setIsLoading(true);

            //刷新分组信息
            const groupRes = await dataInterface({
                __method_name__: 'dataList',
                object_uuid: DataBaseInfo.Group.object_uuid,
                view_uuid: DataBaseInfo.Group.view_uuid['all'],
                transcode: 0,
                search: [{
                    code: 'type',
                    ruleType: 'eq',
                    value: 'ViewState'
                },{
                    code: 'UEID',
                    ruleType: 'eq',
                    value: this.UEID,
                }]
            });

            if ( groupRes?.data?.data && groupRes?.data?.data?.data && groupRes.status === 200 ) {
                const groupList = groupRes?.data?.data?.data;
                for (let { id, name } of groupList) {
                    let item = {
                        id: `group_${id}`,
                        name: name,
                        type: 'Group',
                        children: []
                    };
                    this.$refs.viewPointTree.appendTreeData(item, null);
                }
            }

            //刷新视点数据
            const childRes = await dataInterface({
                __method_name__: 'dataList',
                object_uuid: DataBaseInfo.Views.object_uuid,
                view_uuid: DataBaseInfo.Views.view_uuid['all'],
                transcode: 0,
                search: [{
                    code: 'UEID',
                    ruleType: 'eq',
                    value: this.UEID,
                }]
            });

            
            if (childRes?.data?.data && childRes?.data?.data?.data &&childRes.status === 200 ) {
                const childList = childRes?.data?.data?.data;

                for (let {id, name, group_id, tooltip, img, state, created_at, isDefault} of childList) {
                    let item = {
                        id: `child_${id}`,
                        type: 'Child',
                        name: name,
                        tooltip: tooltip,
                        group_id: group_id,
                        img: img,
                        state: state,
                        created_at: created_at,
                        isDefault: isDefault && isDefault !== "undefined" ? JSON.parse(isDefault) : false
                    };
                    this.$refs.viewPointTree.appendTreeData(item, `group_${group_id}`);
                }
            }

            this.$refs.viewPointTree.setDefaultExpand();
            this.$refs.viewPointTree.setIsLoading(false);
        },
        initEventListener() {
            eventBus.$on('UEStreamViews.GetSource', (eid, cb) => {
                if (eid !== this.element.id) return;

                const loop = (array = [], resList = [], type) => {
                    for (let item of array) {
                        if (item.component !== type) {
                        if (item.children && item.children.length > 0)
                            loop(item.children, resList, type);
                        continue;
                        }

                        if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                        resList.push({
                        id: item.id,
                        name: item.name
                        });
                    }

                    return resList;
                };

                const viewerComps = [];
                loop(this.subComponentData, viewerComps, 'UEStreamClient');
                // loop(this.subComponentData, viewerComps, 'UEStreamClient');

                cb({
                    viewerList: viewerComps,
                    viewerBindId: this.bindViewerId,
                })
            });

            eventBus.$on('UEStreamViews.BindSource', (eid, {viewerBindId,}) => {
                if (eid !== this.element.id) return;
                if(this.checkBind(viewerBindId)){
                    this.element.viewerBindId = viewerBindId;
                    this.bindViewerId = viewerBindId;
                    this.$message.success(`[${this.element.name}] 绑定UE推流客户端成功`);
                }
            });

            eventBus.$on('UEStreamClient.initedViewer',(eid,{ UEID } )=>{
                if(eid !== this.bindViewerId) return;
                
                this.UEID = UEID;
                this.isInit = true;
                this.refreshList();
            });
        },
        checkBind(bindId = null) {
            if(!bindId) return false;

            const checkFunc = (bid, list) => {
                let hasComp = false;
                if(!list) return hasComp;

                for (let comp of list) {
                if (comp.children) {
                    const flag = checkFunc(bid, comp.children);

                    if (flag) {
                    hasComp = true;
                    break;
                    }
                }
                if (comp.id !== bid) continue;

                hasComp = true;
                break;
                }

                return hasComp;
            };

            const hasComp = checkFunc(bindId, this.subComponentData);
            // const hasOriginComp = checkFunc(bindId, this.subComponentData);

            if (!hasComp)
                return this.$message.error(
                `[${this.element.name}] 绑定失效，组件可能已经被删除`
                );

            return hasComp;
        },
        initCheckBind(){
            if (this.element.viewerBindId) {
                if (this.checkBind(this.element.viewerBindId)) {
                    this.bindViewerId = this.element.viewerBindId;
                }
            }
        },
        //主动获取客户端信息
        refreshClientInfo(){
            if(!this.checkBind(this.viewerBindId)) return;

            eventBus.$emit('UEStreamClient.getInfo', this.viewerBindId, ({ UEID })=>{
                this.UEID = UEID;
                this.isInit = true;
                this.refreshList();
            });
        },
    },
    mounted() {
        this.initCheckBind();
        this.initEventListener();
        if(!this.isInit) this.refreshClientInfo();
    },
};
</script>
<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }
</style>
  