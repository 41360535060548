/*
 * @Description: 事件列表
 * @Author: luocheng
 * @Date: 2021-08-25 10:20:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-02-06 11:59:02
 */
import store from './index';
import { getComponentById } from '@/utils/tools';
import Vue from 'vue';
import eventBus from '@/plugins/eventBus';

/**
	用于记录每个组件的缓存状态
	记录值为Boolean, 键值为组件id
 */
const cacheHash = {}
export default {
	state: {
		eventList: [],
		// 状态对应列表
		event2Status: {
			hidden: {
				key: 'isShow',
				value: false
			},
			show: {
				key: 'isShow',
				value: true
			},
			displayShow: {
				key: 'displayShow',
				value: true
			},
			displayHidden: {
				key: 'displayShow',
				value: false
			},
			cacheDisplay: {
				key: 'USECATCH',
				value: true
			},
			cacheHidden: {
				key: 'USECATCH',
				value: false
			},
			active: {
				key: 'isActive',
				value: true
			},
			inactive: {
				key: 'isActive',
				value: false
			}
		}
	},
	mutations: {
		/**
		 * @desc: 编辑组件事件
		 */
		modifyComponentEvent(state, eventConfigs) {
			const keys = Object.keys(state.curComponent.events);
			const newKeys = [];
			for (let i = 0; i < eventConfigs.length; i++) {
				const { eventList = [], pattern = undefined, specialEventList = [] } = eventConfigs[i];
				if (pattern === undefined) {
					const data = eventConfigs[i];
					newKeys.push(data.key);
						const eventKey = data.key || data.type;
						if (!state.curComponent.events[eventKey]) {
						state.curComponent.events[eventKey] = {};
					}
					// 修复组件事件绑定
					state.curComponent.events[eventKey] = {
						...data
					}
				} else {
					// 复杂事件
					const result = !pattern ? eventList : specialEventList;
					for (let i = 0; i < result.length; i++) {
						const data = result[i];
						const eventKey = data.key || data.type;
						newKeys.push(eventKey);
						if (!state.curComponent.events[eventKey]) {
							state.curComponent.events[eventKey] = {};
						}
						// 老逻辑已注释
						// state.curComponent.events[data.key].effects = data.effects;
						// 修复组件事件绑定
						state.curComponent.events[data.key] = {
							...data
						}
					}
				}
			}
					// 删除已不存在的事件绑定
			for (let j = 0; j < keys.length; j++) {
				if (!newKeys.includes(keys[j])) {
					delete state.curComponent.events[keys[j]];
				}
			}
			delete state.curComponent.events['undefined'];
			// 测试
			// sessionStorage.setItem('componentData', JSON.stringify(state.componentData));
		},
		/**
		 * @desc: 编辑组件内部事件
		 * @param {Array} eventList 事件列表
		 * @param {Object} partData 当前操作的按钮
		 */
		modifyComInsideEvent({ curComponent }, { eventList = [], partData, isNesting = false }) {
			if (!curComponent || !partData || (!partData.type && !partData.key)) return;
			if (
				['CommonTableContainer', 'ListContainerOne', 'ConsoleList',
					'ConsoleForm','ConsoleFormNew', 'MobileSelectTree', 'ConsoleTypeTree', 'ViewerBIM', 'ViewerGIS','UEClient',
					'ThreeTerminal', 'BindComponent', 'CommonGantt', 'ConceptualDrawing', 'CommonConfigPage', 'CommonNetDisk'].includes(curComponent.component)
			) {
				// 表格容器
				if (isNesting) {
					// 子表
					if (!curComponent.nestingActionConfig) return;
					Vue.set(curComponent.nestingActionConfig[partData.type], 'eventList', {
						...curComponent.nestingActionConfig[partData.type].eventList,
						...eventList
					})
					curComponent.nestingActionConfig[partData.type].eventList = eventList;
				} else {
					// 单一表格
					if (!curComponent.actionConfig) return;
					Vue.set(curComponent.actionConfig[partData.type], 'eventList', {
						...curComponent.actionConfig[partData.type].eventList,
						...eventList
					});
					curComponent.actionConfig[partData.type].eventList = eventList;
				}
			} else if (['CommonTabs', 'TabsMenu'].includes(curComponent.component)) {
				// tab组件
				const tabConfig = curComponent.tabConfig;
				for (let i = 0; i < tabConfig.length; i++) {
					if (partData.type === tabConfig[i].type) {
						tabConfig.splice(i, 1, {
							...tabConfig[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent, 'tabConfig', tabConfig);
			} else if (['CommonIconBtnMenu', 'IconBtnMenu','ConsoleIconBtn'].includes(curComponent.component)) {
				// icon按钮菜单组件
				const configList = curComponent.iconBtnMenuConfig.configList;
				for (let i = 0; i < configList.length; i++) {
					if (partData.type === configList[i].type) {
						configList.splice(i, 1, {
							...configList[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent.iconBtnMenuConfig, 'configList', configList);
			}  else if (['CommonBtnGroup', 'BtnGroup'].includes(curComponent.component)) {
				// 按钮组组件
				const configList = curComponent.btnGroupConfig.configList;
				for (let i = 0; i < configList.length; i++) {
					if (partData.type === configList[i].type) {
						configList.splice(i, 1, {
							...configList[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent.btnGroupConfig, 'configList', configList);
			} else if (['RouteTop', 'Calendar', 'NewCommonSwiper'].includes(curComponent.component)) {
				if (partData.type && partData.type === 'rowClick' && partData.use) {
					// 表格容器
					if (isNesting) {
						// 子表
						if (!curComponent.nestingActionConfig) return;
						Vue.set(curComponent.nestingActionConfig[partData.type], 'eventList', {
							...curComponent.nestingActionConfig[partData.type].eventList,
							...eventList
						})
						curComponent.nestingActionConfig[partData.type].eventList = eventList;
					} else {
						// 单一表格
						if (!curComponent.actionConfig) return;
						Vue.set(curComponent.actionConfig[partData.type], 'eventList', {
							...curComponent.actionConfig[partData.type].eventList,
							...eventList
						})
						curComponent.actionConfig[partData.type].eventList = eventList;
					}
					return
				}
				if (curComponent.component === 'RouteTop') {
					// 移动端 tab组件
					const tabConfig = curComponent.tabConfig;
					for (let i = 0; i < tabConfig.length; i++) {
						// console.log(partData.type,'partData.type');
						// console.log(tabConfig[i].type,'tabConfig[i].type');
						if (partData.type === tabConfig[i].type) {
							tabConfig.splice(i, 1, {
								...tabConfig[i],
								eventList: eventList
							});
							break;
						}
					}
					Vue.set(curComponent, 'tabConfig', tabConfig);
				} else {
					// 移动端 tab组件
					const tabConfig = curComponent.tabConfig.tabs;
					for (let i = 0; i < tabConfig.length; i++) {
						if (partData.type === tabConfig[i].type) {
							tabConfig.splice(i, 1, {
								...tabConfig[i],
								eventList: eventList
							});
							break;
						}
					}
					Vue.set(curComponent.tabConfig, 'tabs', tabConfig);
				}
			} else if (['CommonForm', 'ConsoleFilter'].includes(curComponent.component)) {
				// Form组件
				const actionConfig = curComponent.actionConfig;
				for (let i = 0; i < actionConfig.length; i++) {
					if (partData.type === actionConfig[i].type) {
						actionConfig.splice(i, 1, {
							...actionConfig[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent, 'actionConfig', actionConfig);
			} else if (['CommonTree', 'DropDownButton'].includes(curComponent.component)) {
				// 树组件
				const actionConfig = curComponent.actionConfig || [];
				for (let i = 0; i < actionConfig.length; i++) {
					if (actionConfig[i].key === partData.type) {
						// const result = actionConfig[i].eventList.concat(eventList)
						actionConfig.splice(i, 1, {
							...actionConfig[i],
							eventList: eventList
						});
						break;
					}
				}
				Vue.set(curComponent, 'actionConfig', actionConfig)
			} else if (curComponent.component === 'CommonProjectMap') {
				// GIS组件
				const actionConfig = curComponent.actionConfig || [];
				for (let key in actionConfig) {
					if (key === partData.type) {
						actionConfig[key] = {
							...actionConfig[key],
							eventList
						};
					}
				}
				Vue.set(curComponent, 'actionConfig', actionConfig)
			} else if (curComponent.component === 'CommonDialog') {
				if (!curComponent.actionConfig) return;
				const actionConfig = curComponent?.actionConfig || [];
				const index = actionConfig.findIndex(ele => ele.key === partData.key);
				actionConfig.splice(index, 1, {
					...actionConfig[index],
					eventList
				})
				Vue.set(curComponent, 'actionConfig', actionConfig);
			} else {
				// 老版表格(弃用)
				if (
					!curComponent.statusConfig ||
					!curComponent.statusConfig.action ||
					!curComponent.statusConfig.action[partData.type]
				) {
					return;
				}
				Vue.set(curComponent.statusConfig.action[partData.type], 'eventList', {
					...curComponent.statusConfig.action[partData.type].eventList,
					...eventList
				})
				curComponent.statusConfig.action[partData.type].eventList = eventList;
			}
			store.commit('modifyComponent', {
				component: curComponent,
				containerId: null,
				isModify: true
			});
		},
		/**
		 * @desc: 触发事件
		 * @param {Array} targets 目标组件
		 * @param {Array} actions 要进行的操作
		 */
		triggerEvents(state, { config = {}, element, EDITOR_pageUUID }) {
			// console.log(config, element, EDITOR_pageUUID, '------111111111111-------');
			if (element?.loopData?.sourceData) {
				eventBus.$emit('EDITOR_loopResolve', element.loopData);
			}
			const { targets = [], actions = [], logics = [], isBehavior = false, behavior = {} } = config;
			if (isBehavior) {
				//  && behavior.component
				// 触发行为
				// console.log('触发行为了', behavior, config, element);
				if (window.EDITORUseDebug) {
					const componentList = state.subsidiaryComponentData?.[EDITOR_pageUUID]?.componentData;
					let component = getComponentById(componentList || state.componentData, behavior.component);
					if( component === null && state.signComponentData?.length){
						//标记的点击的组件未在state.componentData中，导致标记点击不触发行为
						component = getComponentById(state.signComponentData, behavior.component);
					}
					if (window.EDITORUseDebug) {
						window.$EditorDebug.push({
							desc: `<p>组件【${component?.name || ''}】开始响应行为</p>`,
							details: {
								element: component
							}
						});
					}
				}
				eventBus.$emit('doComponentBehavior', {
					...behavior,
					EDITORBehaviorElement: element 
				});
				return;
			}
			if (!targets.length || !actions.length) return;
			const status = [];
			actions.forEach((action) => {
				status.push(state.event2Status[action]);
			});
			// 循环切换
			targets.forEach((target) => {
				// event bus 挂载卸载问题
				setTimeout(() => {
					store.commit('changeComponentStatus', { target, status, logics, element, EDITOR_pageUUID });
				}, 0)
			});
		},
		/**
		 * @desc: 切换组件状态值(事件)
		 * @param {String} target 目标组件ID
		 * @param {Array} status 状态数组
		 */
		changeComponentStatus(state, { target, status, logics, EDITOR_pageUUID }) {
			const componentList = state.subsidiaryComponentData?.[EDITOR_pageUUID]?.componentData;
			let component = getComponentById(componentList || state.componentData, target);
			if( component === null && state.signComponentData?.length){
				//标记的点击的组件未在state.componentData中，导致标记点击不触发行为
				component = getComponentById(state.signComponentData, target);
			}
			// console.log(component, 'state.component',logics,status,target)
			// 附加逻辑判断
			let logicBoolean = true;
			let details = [];
			if (logics && logics.length) {
				for (let i = 0; i < logics.length; i++) {
					const item = logics[i];
					// type: 'component/ system / inner
					// dataAttr: "value"
					// dataField: "field61a885dcdbd42"
					// dataKey: "resolveData"
					// itemLogic: "and"
					// logicRule: "!="
					// target: "CommonTree-1642581368412"
					// type: "component" 暂未启用
					// value: "version"
					// valueType: "string"
					const { type, dataAttr, dataField, dataKey, itemLogic, logicRule, value, valueType, valueObj, valueKey, statusCode } = item;
					let resolveData = null;
					let desc = `【${i+1}】、`;
					// console.log(type, '-----type 取值类型')
					if (type === 'component') {
						// 对象参数
						const resolveTargetKey = item.target;
						if (!dataKey || !resolveTargetKey || !valueType || !logicRule || !dataAttr) {
							break;
						}
						let resolveTarget = getComponentById(componentList || state.componentData, resolveTargetKey);
						// console.log(resolveTarget, 'resolveTarget');
						if( resolveTarget === null && state.signComponentData?.length){
							//标记的点击的组件未在state.componentData中，导致标记点击不触发行为
							resolveTarget = getComponentById(state.signComponentData, resolveTargetKey);
						}
						if (!resolveTarget) {
							desc += `组件【${resolveTargetKey}】不存在！`;
							break;
						}
						if (!resolveTarget.statusConfig.isShow) {
							desc += `组件【${resolveTarget.name}】未显示！`;
							logicBoolean = false;
							break;
						}
						if (dataKey === 'propValue') {
							resolveData = resolveTarget.propValue;
						} else if (dataAttr === 'value') {
							resolveData = resolveTarget.resolveData?.[dataField];
						} else if (dataAttr === 'length') {
							resolveData = resolveTarget.resolveData.length;
						}
						desc += `自组件【${resolveTarget.name}】取值为<span>${resolveData}</span>。`
					} else if (type === 'system') {
						// 前端取值对象
						let result = sessionStorage.getItem(valueObj);
						if (!result) {
							result = localStorage.getItem(valueObj);
						}
						try {
							const value = JSON.parse(result)?.[valueKey];
							if (dataAttr === 'value') {
								resolveData = value;
							} else if (dataAttr === 'length') {
								resolveData = value.length;
							}
							desc += `自系统取值字段【${valueObj}】,值为<span>${resolveData}</span>。`
						} catch (err) {
							console.log(err);
							desc += `自系统取值字段【${valueObj}】,<em>失败！</em>。`
						}
					} else if (type === 'pageStatus') {
						// 页面状态
						resolveData = state._PageCustomStatus[statusCode] === undefined ? (state._APPCustomStatus[statusCode] || null) : state._PageCustomStatus[statusCode];
						desc += `自页面状态【${statusCode}】,值为<span>${resolveData}</span>。`
					}
					// 判断关系
					let booleanItem = false;
					if (logicRule === '===') {
						if (valueType === 'string') {
							booleanItem = `${resolveData}` === `${value}`;
						} else if (valueType === 'number') {
							booleanItem = +resolveData === +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() === +value;
						}
					} else if (logicRule === '!=') {
						if (valueType === 'string') {
							booleanItem = `${resolveData}` !== `${value}`;
						} else if (valueType === 'number') {
							booleanItem = +resolveData !== +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() !== +value;
						}
					} else if (logicRule === '>') {
						if (valueType === 'number') {
							booleanItem = +resolveData > +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() > +value;
						}
					} else if (logicRule === '<') {
						if (valueType === 'number') {
							booleanItem = +resolveData < +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() < +value;
						}
					} else if (logicRule === '<=') {
						if (valueType === 'number') {
							booleanItem = +resolveData <= +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() <= +value;
						}
					} else if (logicRule === '>=') {
						if (valueType === 'number') {
							booleanItem = +resolveData >= +value;
						} else if (valueType === 'date') {
							booleanItem = (new Date(resolveData)).getTime() >= +value;
						}
					} else if (logicRule === 'like') {
						if (valueType === 'string') {
							booleanItem = resolveData.toString()?.includes(value.toString());
						}
					} else if (logicRule === 'whereIn') {
						// 包含
						try {
							let valArr = [];
							if (Array.isArray(resolveData)) {
								valArr = resolveData;
								booleanItem = valArr.includes(value) || valArr.includes(+value);
							} else if (resolveData.toString()?.startsWith('[') && resolveData.toString()?.endsWith(']')) {
								valArr = JSON.parse(resolveData);
								booleanItem = valArr.includes(value) || valArr.includes(+value);
							} else if (value.toString()?.startsWith('[') && value.toString()?.endsWith(']')) {
								valArr = JSON.parse(value);
								booleanItem = valArr.includes(resolveData) || valArr.includes(+resolveData);
							} else {
								booleanItem = valArr.includes(value) || valArr.includes(+value);
							}
						} catch (err) {
							console.log(err);
						}
					}
					desc += `<i>${logicRule}</i>对比值：${value}(${valueType})，${booleanItem ? '<strong>成立</strong>' : '<em>不成立</em>'}!`
					if (i === 0) {
						logicBoolean = booleanItem;
					} else {
						if (itemLogic === 'and') {
							logicBoolean = logicBoolean && booleanItem;
							desc += '\n【且】';
						} else if (itemLogic === 'or') {
							logicBoolean = logicBoolean || booleanItem;
							desc += '\n【或】';
						}
					}
					// console.log(resolveData)
					details.push({
						desc: `<p>${desc}</p>`
					})
				}
			}
			// console.log(logicBoolean, '----logicBoolean-----')
			if (!logicBoolean) {
				window.$EditorDebug.push({
					desc: `<p>切换事件，逻辑<em>不成立，执行结束！</em></p>`,
					origin: null,
					eventType: '',
					details: {
						logics,
						details
					}
				});
				return;
			}
			if (!component || !component.statusConfig) {
				window.$EditorDebug.push({
					desc: `<p>切换组件【${component?.name}】<em>不存在，执行结束！</em></p>`,
					origin: null,
					eventType: ''
				});
				return;
			}
			if (component.component === 'CommonDialog') {
				// 表单特殊处理
			}
			status.forEach((item) => {
				/* eslint-disable */
				if (component.statusConfig.hasOwnProperty(item.key) && component.statusConfig?.[item.key] !== item.value) {
					component.statusConfig[item.key] = item.value;
					if (window.EDITORUseDebug) {
						let attributeKey = item.key;
						let statusVal = item.value;
						if (attributeKey === 'isShow') {
							attributeKey = '是否显示'
						} else if (attributeKey === 'displayShow') {
							attributeKey = '虚拟显示'
						}
						if (statusVal === true) {
							statusVal = '<strong>是</strong>';
						} else if (statusVal === false) {
							statusVal = '<em>否</em>';
						} 
						window.$EditorDebug.push({
							desc: `<p>切换组件【${component?.name}】属性<i>【${attributeKey}】</i>为${statusVal}</p>`,
							origin: null,
							eventType: '',
							details: {
								element: component
							}
						});
					}
				} else if (item.key === 'USECATCH') {
					// 如果是需要缓存显示或隐藏的
					// 显示操作
					const componentIsShow = component.statusConfig['isShow'];
					const componentDisplayShow = component.statusConfig['displayShow'];
					if (item.value === true) {
						// 这里强制处理v-if 与 v-show 避免显示时失败
						if (Object.hasOwnProperty.call(cacheHash, target)) {
							if (componentDisplayShow !== item.value) {
								component.statusConfig['displayShow'] = item.value;
								window.$EditorDebug.push({
									desc: `<p>切换组件【${component?.name}】属性<i>【虚拟显示】</i>为<strong>${item.value}</strong></p>`,
									origin: null,
									eventType: '',
									details: {
										element: component
									}
								});
							}
							if (item.value !== componentIsShow && componentIsShow === false) {
								component.statusConfig['isShow'] = item.value;
								window.$EditorDebug.push({
									desc: `<p>切换组件【${component?.name}】属性<i>【显示】</i>为<strong>${item.value}</strong></p>`,
									origin: null,
									eventType: '',
									details: {
										element: component
									}
								});
							}
						} else {
							if (item.value !== componentIsShow) {
								component.statusConfig['isShow'] = item.value;
								window.$EditorDebug.push({
									desc: `<p>切换组件【${component?.name}】属性<i>【显示】</i>为<strong>${item.value}</strong></p>`,
									origin: null,
									eventType: '',
									details: {
										element: component
									}
								});
							}
							if (item.value !== componentDisplayShow && componentDisplayShow === false) {
								component.statusConfig['displayShow'] = item.value;
								window.$EditorDebug.push({
									desc: `<p>切换组件【${component?.name}】属性<i>【虚拟显示】</i>为<strong>${item.value}</strong></p>`,
									origin: null,
									eventType: ''
								});
							}
						}
						cacheHash[target] = item.value;
					} else if (item.value === false) { // 隐藏操作
						// if(Object.hasOwnProperty.call(cacheHash, target)) {
						// 	component.statusConfig['displayShow'] = item.value;
						// } else {
						// 	component.statusConfig['isShow'] = item.value;
						// }
						// 无论如何都只修改displayShow
						if (componentDisplayShow !== item.value) {
							component.statusConfig['displayShow'] = item.value;
							window.$EditorDebug.push({
								desc: `<p>切换组件【${component?.name}】属性<i>【虚拟显示】</i>为<strong>${item.value}</strong></p>`,
								origin: null,
								eventType: '',
								details: {
									element: component
								}
							});
						}
						cacheHash[target] = item.value;
					}
				}
			});
		}
	}
};
