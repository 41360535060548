/*
 * @Description: 表单相关
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:31
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-12-12 11:49:56
 */
const form = {
  type: 'form',
  title: '表单组件',
  show: true,
  order: 7,
  componentList: [
    {
      component: 'CommonForm',
      type: 'form',
      name: '表单筛选',
      icon: 'icon-shaixuan',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showButton: true, // 显示操作按钮
        quickSearch: false, // 快速搜索
      },
      style: {
        minHeight: 40,
        height: '',
        width: '',
        rotate: 0,
        paddingTop: 18,
        paddingBottom: 0,
        paddingLeft: 15,
        paddingRight: 15
      },
      actionConfig: [
        {
          label: '查询',
          type: 'search',
          btnType: 'primary',
          icon: '',
          show: true,
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '重置',
          type: 'reset',
          btnType: '',
          icon: '',
          show: true,
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '导出',
          type: 'export',
          btnType: '',
          icon: '',
          show: false,
          eventList: [],
          permission: [], // 权限
          archiLimit: [], // 架构限制
        },
        {
          label: '新增',
          type: 'add',
          btnType: '',
          icon: 'el-icon-plus',
          show: false,
          eventList: [],
          permission: [], // 权限
          archiLimit: [], // 架构限制
        }
      ]
    },
    {
      component: 'CommonInput',
      type: 'form',
      name: '输入框',
      icon: 'icon-danhangshuru',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true // 是否显示
      },
      style: {
        minHeight: 40,
        height: '',
        width: '',
        rotate: 0,
        paddingTop: 18,
        paddingBottom: 0,
        paddingLeft: 15,
        paddingRight: 15
      }
    },
    {
      component: 'FormOnlyOne',
      type: 'form',
      name: '独立表单',
      icon: 'icon-a-dulibiaodandanchuang',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        AutoCreate: true
      },
      style: {
        width: '100%',
        height: "100%"
      },
      contentConfig: {
        objectUUID: '', // 数据对象
        viewUUID: '', // 视图ID
        formUUID: '', // 表单对象
        moreCondition: [], // 查找数据时的额外条件，会自动当成字典在新增时写入
        moreCreateDicts: [], // 自动创建时的附加字典
      },
    },
    {
      component: 'CommonSelect',
      type: 'form',
      name: '下拉框',
      icon: 'icon-xialaxuanze',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        showLabel: true,
        label: '描述',
        labelWidth: 100,
        labelPosition: 'left',
        selectSize: 'medium',
        clearable: false,
        filterable: false,
        multiple: false,
        disabled: false,
        placeholder: '请选择',
        allowCreate: false,
        noDataText: '无数据',
        prefixIcon: '',
        optionLabel: '名称',
        optionValue: 'id',
        optionValueUUID: '',
        defaultSelect: false,
        needAll: false,
        transparent: false,
        hideBorder: false,
        // selectWidth: 220,
        selectHeight: 36
      },
      style: {
        width: '220px',
        height: '',
        minHeight: 40
      }
    },
    {
      component: 'CommonDateFilter',
      type: 'form',
      name: '日期组',
      icon: 'icon-riqishijian',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        valueFormat: 'yyyy-MM-dd',
        monthDay: 31,
        transparent: false,
        selectHeight: 36,
        hideBorder: false,
        hideIcon: false
      },
      style: {
        width: '',
        height: '',
        minHeight: 34
      }
    },
    {
      component: 'CommonBaseDate',
      type: 'form',
      name: '基础日期',
      icon: 'icon-riqishijian',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
        valueFormat: 'yyyy-MM-dd',
        type: 'date', // 类型
        shortcuts: 'targetMonth', // 快捷选择备选项
        transparent: false,
        // selectWidth: 220,
        selectHeight: 36,
        hideIcon: false,
        hideBorder: false,
      },
      style: {
        width: '',
        height: '',
        minHeight: 34
      }
    },
		{
			component: 'CommonCount',
			type: 'form',
			name: '计数器',
			icon: 'iconshuzi',
			isLock: false,
			needCommonStyle: true,
			propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
				fontSize: 64,
				margin: 12,
				bit: 'auto', // 位数 auto, 不补零  Number 位数需要大于数字长度
				// zeroFill: false, // 补零
				// useBorder: false, // 边框
				borderColor: '#abc8ff',
				borderSize: 1,
				borderMargin: 8,
				borderStyle: 'solid',
				radius: 0,
				stepTime: 0, // 生长动画毫秒数
				stepCount: 5, // 步进数
      },
			countConfig: null,
      style: {
        width: '100%',
        height: 84,
				color: '#4872FB',
      }
		}
  ]
};
export default form;