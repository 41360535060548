<!--
    @name: widget-date-range
    @description：widget-date-range
    @author: ZengWei
    @date: 2022-03-25 09:37
-->
<template>
  <div>
    <div v-if="disabled || element.config.disabled" class="input-show">{{ getDateRange }}</div>
    <el-date-picker
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      :type="element.config.type"
      :range-separator="element.config['range-separator']"
      :format="element.config.format"
      :placeholder="element.config.placeholder"
      :value-format="element.config['value-format']"
      :picker-options="pickerOptions"
      @change="onChange"
      style="width: 100%"
    >
    </el-date-picker>
  </div>
</template>

<script>
import {DatePicker} from "element-ui";
import formItemMinix from "@/custom-component/form/newParser/scripts/formItemMinix";
import {dateFormat} from "@/custom-component/form/newParser/scripts/tools";

export default {
  name: "WidgetDateRange",
  components: {
    'el-date-picker': DatePicker
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {
      maxEndDate: '',
      pickerOptions: {
        disabledDate: time => {
          const pre = 1 * 24 * 3600 * 1000;
          const minTime = Date.now() - pre;
          const preDate = this.element.config.__config__.preDate || false
          const afterDate = this.element.config.__config__.afterDate || false
          const days = this.element.config.__config__.days || 0
          if(preDate) {
            return time.getTime() < minTime
          }
          if(afterDate){
            return time.getTime() > minTime
          }
          if(days) {
            const one = days * 24 * 3600 * 1000;
            const maxTime = Date.now() + one;
            return (
                time.getTime() > maxTime ||
                time.getTime() < minTime
            )
          }
        },
        onPick: ({ minDate, maxDate })=>{
          const preDate = this.element.config.__config__.preDate || false
          const afterDate = this.element.config.__config__.afterDate || false
          const days = this.element.config.__config__.days || 0
          const fmt = this.element.config['value-format']
          if((preDate || afterDate) && days && maxDate) {
            const one = days * 24 * 3600 * 1000;
            const maxTime = minDate.getTime() + one;
            if(maxDate.getTime() > maxTime) {
              this.$message.error(`日期范围最多只能选【${days}】天，已自动更改截止时间`)
              const maxDatetime = new Date(maxTime)
              this.maxEndDate = dateFormat(fmt, maxDatetime)
            }
          }
        }
      }
    }
  },
  computed: {
    getDateRange() {
      const dataRange = this.element.value
      let result = dataRange.map(el => {
        return el.split('-').join('/')
      })
      return result.join(' - ')
    }
  },
  methods: {
    onChange(){
      if(this.maxEndDate) {
        this.element.value[1] = this.maxEndDate
      }
    }
  },
}
</script>

<style scoped>
</style>
