import Utils from "./Utils";

export default class MarkerUtils {
    getGlobal() {
        return window;
    }

    async initResource() {
        const host = 'https://models-1309784708.cos.ap-shanghai.myqcloud.com/';

        const loadUrl = [
            `${host}javascripts/viewer/ViewerMarker.js` //todo 切换线上
            // `http://localhost:8080/files/ViewerMarker.js`, //todo 切换本地测试
        ];

        Utils.checkLoadedUrl([...loadUrl], {
            onLoaded: (url) => {
                if (loadUrl.indexOf(url) !== -1) {
                    loadUrl.splice(loadUrl.indexOf(url), 1);
                }
            }
        });

        Utils.appendListInHead(loadUrl);
    }

    async initViewerMarker() {
        const global = this.getGlobal();

        await this.initResource();

        await Utils.waitLoaded(() => {
            if (global.BIMCC_GIS_Marker) {
                return true;
            } else {
                return false;
            }
        });

        const vm = (this.vm = new global.BIMCC_GIS_Marker.ViewerMarker());
        await vm.init();

        return vm;
    }

    getCollection(viewerType){
        const forEachList = (list) => {
            list.forEach(x => {
                x.name = x.content;

                if(x.children && x.children.length > 0) forEachList(x.children);
            });
        };

        let tree = this.vm.getCollection();
        if(viewerType === 'BIM'){
            tree = [tree[0]];
        }
        forEachList(tree);

        return tree;
    }

    getMarkerModule(type){
        return this.vm.getMarkerModule(type);
    }

    getImageType(type){
        return this.vm.getImageType(type);
    }

    getIconArray(){
        let list = this.vm.getIconArray();
        list.forEach(x => {
            x.name = x.content;
        });

        return list;
    }

    getMarkerOptByType(parentType, markerType){
        return this.vm.getMarkerOptByType(parentType, markerType);
    }

    getMarkerUrlByType(parentType, markerType, opt){
        return this.vm.getMarkerUrlByType(parentType, markerType, opt);
    }
}
