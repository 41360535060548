<!--
    @name: ques-checkbox
    @description：ques-checkbox
    @author: ZengWei
    @date: 2022-04-01 11:17
-->
<template>
  <div class="widget-checkbox">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[多选题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div class="q-resource" v-if="element.config.__ques__.resource">
        <ChooseModel
          ref="chooseModel"
          :modelData="element.config.__ques__.resource !== null ? element.config.__ques__.resource: []">
          <template v-slot:showType>
            <el-button
              @click="openModelDrawer"
              size="small"
              type="primary"
            >查看资源</el-button>
          </template>
        </ChooseModel>
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-checkbox-group v-model="element.value" @change="calcScore">
          <el-checkbox
            :class="columnClass"
            v-for="(option,index) in element.config.__slot__.options"
            :key="index"
            :label="option.value"
          >
            {{optNumber[index]}} 、{{ option.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import {CheckboxGroup,Checkbox} from "element-ui";
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";

export default {
  name: "ques-checkbox",
  components: {
    'el-checkbox-group': CheckboxGroup,
    'el-checkbox': Checkbox,
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  data() {
    return {}
  },
  methods: {
    calcScore(value){
      const config = this.element.config.__ques__
      const options = this.element.config.__slot__.options

      const rightOption = options.filter(item=>item.right === true)
      const rightArr = Array.from(rightOption,item=>item.value)

      let score = 0
      if(config.scoreType === 1){
        // 全部答对得分
        let equal = true
        for (const item of value){
          if(!rightArr.includes(item)){
            equal = false // 正确答案没包含选项不得分
          }
        }
        if(equal) score = config.score
      } else if (config.scoreType === 2){
        // 答对几项得几分，答错不得分
        let equal = true
        for (const item of value){
          if(!rightArr.includes(item)){
            equal = false // 正确答案没包含选项不得分
          }
        }
        if(equal){
          for (const opt of options){
            for (const optVal of value){
              if(opt.right && optVal == opt.value){
                score += parseFloat(opt.score)
              }
            }
          }
        }
      } else if (config.scoreType === 3){
        for (const opt of options){
          for (const optVal of value){
            if(optVal === opt.value){
              score += parseFloat(opt.score)
            }
          }
        }
      }
      this.element.score = score
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
