<!--
 * @Author: zyf
 * @LastEditors: zyf
 * @Date: 2023-06-29 16:13:08
 * @LastEditTime: 2023-07-03 16:48:00
 * @description: 
-->
<template>
  <div class="disk-box-container">
    <fileUpload class="upload-box" :files="files" :drag="true" @extra-files="extraFiles" />
    <el-input class="search-input" v-model="searchVaklue" @input="onSearch" placeholder="请输入内容">
        <template #prefix>
            <a class="iconfont iconsousuo2"></a>
        </template>
    </el-input>
    <div class="file-list-box">
      <div class="file-list-item" v-for="(item,index) in fileList" :key="index">
        <div class="title-box" @click="onExpand(item,index)">
          <p class="title">
            {{ item.title }}
            <span class="count">({{ item.children.length }})</span>
          </p>
          <i class="iconfont axpand-icon" :class="item.isExpand ? 'iconpage-arrow1-16' : 'iconpage-arrow2-16'"></i>
        </div>
        <div v-show="item.isExpand" class="list-box" :class="{'pic-list-box':item.type === 'pic'}">
          <template v-for="(el,i) in item.children">
            <template v-if="item.type === 'pic' || item.type === 'video'">
              <div :key="i" class="pic-box">
                <div class="pic">
                  <img :src="el.path" alt="" srcset="">
                </div>
                <div class="oprate">
                  <div class="name">
                    <svg class="icon svg-icon" aria-hidden="true">
                      <use :xlink:href="getFileIcon(item,el)"></use>
                    </svg>
                    <p>{{ el.name }}</p>
                  </div>
                  <el-popconfirm
                      confirm-button-text='删除'
                      cancel-button-text='取消'
                      icon="el-icon-info"
                      icon-color="red"
                      @confirm="onDelete(el)"
                      title="删除后不可恢复，确定删除吗？"
                    >
                      <span slot="reference"><i class="iconfont iconpage-delete-16"></i></span>
                  </el-popconfirm>
                </div>
              </div>
            </template>
            <template v-else>
              <div :key="i" class="list-item-box">
                <div class="list-item">
                  <div class="name-box">
                    <svg class="icon svg-list-icon" aria-hidden="true">
                      <use :xlink:href="getFileIcon(item,el)"></use>
                    </svg>
                    <p>{{ el.name }}</p>
                  </div>
                  <div class="oprate-btn">
                    <p class="preview" @click="onPreview(el)">预览</p>
                    <el-popconfirm
                      confirm-button-text='删除'
                      cancel-button-text='取消'
                      icon="el-icon-info"
                      icon-color="red"
                      @confirm="onDelete(el)"
                      title="删除后不可恢复，确定删除吗？"
                    >
                      <span slot="reference" class="delete">删除</span>
                  </el-popconfirm>
                    
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui';
import fileUpload from '@/components/fileUpload/newFiles/index.vue'
export default {
  name: "Disk",
  components: {
    fileUpload,
    'el-popconfirm': Popconfirm,
  },
  props: {
  },
  data() {
    return {
      
      
    }
  },
  computed: {
    
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>


<style lang="less" scoped>
  .disk-box-container{
    width: 100%;
    height: 100%;
    
  }
</style>
