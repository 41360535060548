<!--
 * @Description:
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-01-10 17:39:56
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-02-14 14:09:39
-->
<template>
  <article class="gisViewer-cont">
    <div id="viewerGISDom" ref="viewerGISDom" class="viewerGIS_main"></div>

    <div ref="viewerMaskDom" class="mask" v-show="setting.showMask"></div>
<!--    <div-->
<!--      class="mask_revert iconfont iconzhuye"-->
<!--      v-show="!setting.showMask"-->
<!--    ></div>-->
  </article>
</template>

<script>
import VueConfig from './VueConfig';

const vc = new VueConfig();

export default vc.generate();
</script>

<style scoped>
.viewerGIS_main {
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  /*z-index: 100;*/
}

.mask_revert {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  font-size: 20px;
  /*z-index: 100;*/
}
</style>
