/*
 * @Description: router
 * @Author: luocheng
 * @Date: 2021-09-13 11:40:21
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2024-08-09 11:25:39
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
import { routes } from './baseConfig';
import {dataInterface} from "@/apis/data";
Vue.use(VueRouter);
import { getHomePath } from '@/utils/tools';

/* eslint-disable */
let userInfo = {};

if (localStorage.getItem('userInfo')) {
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
}
const userAuths = localStorage.getItem('userRoles') && localStorage.getItem('userRoles').split();

/**
 * @desc: 检测权限
 * @param {Array || String} menuAuth
 */
const checkAuth = (menuAuth) => {
  if (!menuAuth) return true;
  if (userInfo.name === 'admin' && +userInfo.id === 1) return true;
  if (!userAuths || !Array.isArray(userAuths) || !userAuths.length) return false;
  if (typeof menuAuth === 'string') {
    return userAuths.includes(menuAuth);
  }
  // 数组的情况暂时选择包含其中一种即可
  for (let i = 0; i < menuAuth.length; i++) {
    if (userAuths.includes(menuAuth[i])) {
      return true;
    }
  }
  return false;
}

// 页面 0 ， 跳转外链 1，分组  2 ， 微前端 3 ，单存跳转 4
const configRoutes = (arr) => {
  if (!arr || !Array.isArray(arr)) return [];
  return arr.map(ele => {
    const eleType = ele['9b251b20-6276-4db5-8f8e-4ea03e4f79c3']
    const path = ele['7c5166f1-84aa-4639-83f6-7a2a07a518d9'];
    const title = ele['97fb3c74-5e6b-4770-9fb6-75bf2695f30b'];
    const auth = ele['field617141d07b8dd'];
    const routeName = path + (new Date().getTime());
    if (eleType === 0 && checkAuth(auth)) {
      // 普通页面
      return {
        path,
        name: routeName, // 暂时将path作为name
        meta: {
          title
        },
        component: () => import(/* webpackChunkName: "index" */'@/views/Index')
      }
    } else if (eleType === 1) {
      // 外链
    } else if (eleType === 2 && checkAuth(auth)) {
      // 分组
      return {
        path,
        name: routeName, // 暂时将path作为name
        meta: {
          title,
          auth
        },
        children: configRoutes(ele['72da4bd8-c508-48c9-8e75-5ed59e6b09d2']),
        component: () => import(/* webpackChunkName: "index" */'@/views/Index')
      }
    } else if (eleType === 3 && checkAuth(auth)) {
      return {
        path,
        name: routeName,
        meta: {
          title,
          auth
        }
      }
    } else if (eleType === 4) {

    }
    return {
      path,
      name: routeName,
      meta: {
        title: 'other'
      }
    }
  });
};

const configAppRoutes = (arr) => {
  if (!arr || !Array.isArray(arr)) return;
  return arr.filter(el => el.menu_path !== undefined && el.menu_path !== null).map(ele => {
    const { menu_path, menu_type, name, permissions, is_screen, children_list = [] } = ele;
    // 权限(暂无) && checkAuth(permissions)
    const query = {};
    if (menu_path &&  menu_path.toString().includes('?')) {
      const str = menu_path.split('?')?.[1];
      const queryList = str.toString().split('&');
      queryList.forEach(ele => {
        const eleArr = ele.toString()?.split('=')
        Vue.set(query, eleArr?.[0] || '', eleArr?.[1] || '')
      })
    }
    const routeName = `${menu_path}${(new Date().getTime())}`;
    if (menu_type === 0 ) {
      // 普通页面
      return {
        path: menu_path,
        // path: menu_path.toString().split('?')[0],
        name: routeName, // 暂时将path作为name
        meta: {
          title: name,
          isScreen: is_screen,
          auth: permissions
        },
        query,
        component: () => import(/* webpackChunkName: "index" */'@/views/Index')
      }
    } else if (menu_type === 1) {
      // 外链
    } else if ((menu_type === 2 || menu_type === 0) && children_list.length ) {
      // 分组
      return {
        path: menu_path,
        // path: menu_path.toString().split('?')[0],
        name: routeName,
        meta: {
          title: name,
          isScreen: is_screen,
          auth: permissions
        },
        children: configAppRoutes(children_list),
        component: () => import(/* webpackChunkName: "index" */'@/views/Index')
      }
    } else if (menu_type === 3 ) {
      return {
        path: menu_path,
        // path: menu_path.toString().split('?')[0],
        name: routeName, // 暂时将path作为name
        meta: {
          title: name,
          isScreen: is_screen,
          auth: permissions
        },
        query
      }
    } else if (menu_type === 4) {}
    return {
      path: menu_path,
      // path: menu_path.toString().split('?')[0],
      name: routeName, // 暂时将path作为name
      meta: {
        title: name,
        isScreen: is_screen,
        auth: permissions
      },
      query
    }
  });
}

const appListString = sessionStorage.getItem('appList');
if (appListString && JSON.parse(appListString) && JSON.parse(appListString).length) {
  let result = {
    path: '/home',
    name: 'MyHome',
    component: () => import(/*index*/'@/views/appEntry/Index'),
    children: routes[1].children
  };
  let homeChildren = [];
  const appList = JSON.parse(appListString);
  for (let i = 0; i < appList.length; i++) {
    const appOriginData = appList[i].app_orign_data || [];
    const pathList = appOriginData[0]?.children_list || [];
    if (appOriginData.length) {
      const item = JSON.parse(JSON.stringify(appOriginData[0]));
      delete item.children_list;
      pathList.push(item)
      homeChildren = homeChildren.concat(configAppRoutes(pathList));
    }
  }
  result = {
    ...result,
    children: result.children.concat(homeChildren.filter((el) => el.path))
  };
  // console.log(result.children.concat(homeChildren), '----result.children.concat(homeChildren)-----')
  routes.splice(1, 1, result);
  store.commit('setAppList', appList);
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 所有应用平摊为数组
const flatTree = (treeData) => {
  let result = []
  treeData.forEach((item) => {
    // 先克隆一份数据作为第一层级的填充
    let res = JSON.parse(JSON.stringify(item))
    delete res.children_list
    result.push(res)
    if (item.children_list && item.children_list.length > 0) {
      // 如果当前children为数组并且长度大于0，才可进入flatTree()方法
      result = result.concat(flatTree(item.children_list))
    }
  })
  return result
}



const formatPath = (path) => {
  if (!path || typeof path !== 'string') return '/';
  const arr = path.split('/')
  if (arr.length < 2) return '/';
  arr.splice(0, 2);
  return `/${arr.join('/')}`;
}

const getResult = (arr, key) => {
  const item = arr.find(ele => ele.path.includes(key));
  if (item) {
    return item;
  }
  for (let i = 0; i < arr.length; i++) {
    const children = arr[i]?.children || [];
    if (children && children.length) {
      const result = getResult(children, key);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

/**
 * @description: 是否强制更新密码
 */
const focusUpdatePWD = () => {
  let systemConfig = localStorage.getItem('systemConfig');
  if (!systemConfig) return false;
  systemConfig = JSON.parse(systemConfig);
  if (!systemConfig.force_password) return false;
  return !!(localStorage.getItem('updatePwd') == '0')
}

// 拦截器
router.beforeEach((to, from, next) => {
  window.BIMCCRouteList.push(to);
  try {
    console.log(getResult(window.testRoutes, 'anuual-cost-detail'), '-----anuual-cost-detail---1111111----');
  } catch (err) {
    console.log(err,' ---112324555----');
  }
  if (to.path.indexOf('/nhmicro/') === 0 || to.path.indexOf('/aboutModel/') === 0 || to.path.indexOf('/digitalmicro/') === 0) {
    console.log('重新定向回去', '----formatPath(to.path)---', formatPath(to.path));
    setTimeout(() => {
      if (focusUpdatePWD()) {
        next({
          path: '/pedestal-user-center'
        })
        return;
      }
      next({
        ...to,
        path: formatPath(to.path)
      });
    }, 0);
    return;
  }
  const homePath = getHomePath();
  const appArr = []
  // 不用埋点的路由数组
  const routerArr = ['/second-screen','/base-home','/login','/pedestal-user-center','/todo']
  if(routerArr.indexOf(to.path) === -1){
    let params ={
      __method_name__: 'createData',
      object_uuid:'object645c9db7840cf',
      view_uuid:'view645c9ff48b739',
      platform:'PC',
      path:to.path,
      pageUUID:to.query.pageUUID??''
    }
    const appList = JSON.parse(sessionStorage.getItem('appList'));
    if(appList){
      appList.forEach((item)=>{
        appArr.push(...flatTree(item.app_orign_data,'children_list'))
      })
    }
    
    // console.log(appArr)
    window.appArr = appArr;
    // some 可以return 出 不用一直循环
    if(appArr.length){
      appArr.some(item=>{
        if(item.menu_path && item.name && to.path.includes(item.menu_path) &&  to.meta?.title?.includes(item.name) ){
          params.infos = {
            shortcut_icon:item.icon,
            shortcut_name:item.name
          }
          params.appId = item.id
          return true;
        }
      })
      dataInterface(params).then(res=>{
        // console.log('埋点')
      })
    } 
  }
  const excludesRoutes = ['/public-transfer-page'];
  //带长效token的不拦截 或独立页面不拦截
  if(to.query?.token || excludesRoutes.includes(to.path)) {
    next();
    return
  }
  if (+sessionStorage.getItem('isLimit') === 1) {
    let limitConfig = sessionStorage.getItem('limitConfig');
    if (!limitConfig) return;
    try {
      limitConfig = JSON.parse(limitConfig)
      if (to.fullPath !== limitConfig.fullPath) {
        // 不允许跳转其他路由
        next({
          path: limitConfig.fullPath
        })
        return;
      }
      // 刷新？
      next();
    } catch(err) {
      // 不重新登录
      // next({
      //   path: 'login'
      // });
      next();
    }
    return;
  }
  const userInfo = localStorage.getItem('userInfo');
  const targetArchi = localStorage.getItem('targetArchi');
  if ((!userInfo && !targetArchi) && to.path !== '/login') {
    console.log('44444444444');
    let loginPath = '/login'
    if (to.path === '/public-form-page') {
      loginPath = `/login?url=${to.fullPath}`;
    }
    next({
      path: loginPath
    });
    console.log('跳转登录3')
    return;
  }
  if (userInfo && targetArchi && to.path === '/login') {
    if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
      next({
        path: '/pedestal-user-center'
      })
      return;
    }
    next({
      path: from.path
    });
    return;
  }
  // 跳转首页
  if (userInfo && targetArchi && !to.path) {
    if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
      next({
        path: '/pedestal-user-center'
      })
      return;
    }
    next({
      path: process.env.VUE_APP_HOME_PATH
    });
    return;
  }
  /**
   * @desc: 获取指定路由菜单对象
   * @param {Array} list 列表
   * @param {String} path 路由
   */
  const getCurrentMenuItem = (list, path) => {
    let obj = {}
    if (!list || !Array.isArray(list)) {
      return obj;
    }
    const loop = (list) => {
      for (let index = 0; index < list.length; index++) {
        const ele = list[index];
        if (ele?.menu_path?.indexOf(path) > -1 || ele?.menu_path === path) {
          obj = ele
          break
        } else {
          const children_list = ele.children_list;
          if (children_list && Array.isArray(children_list) && children_list.length) {
            loop(children_list)
          }
        }
      }
    }
    loop(list)
    return obj
  }
  // 判断首次路由是否跳转到微应用
  if (to.path.indexOf('/child-app') > -1 && !store.state.currentMenu && !store.state?.appNavHistoryList?.length) {
    if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
      next({
        path: '/pedestal-user-center'
      })
      return;
    }
    next()
    // 先加载页面菜单，然后判断是否有这个功能菜单，决定是否跳转首页
    const interval = setInterval(() => {
      // 判断菜单已经加载判断当前菜单完成
      if (store.state?.appNavHistoryList?.length) {
        const children = store.state.currentMenu?.children_list || store.state.currentMenu?.app_orign_data || [];
        const menuObj = getCurrentMenuItem(children, to.path);
        // 当前菜单已经找到
        if (!menuObj?.id) {
          // 菜单中未找到当前路由菜单  执行跳转首页
          if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
            next({
              path: '/pedestal-user-center'
            })
          } else {
            router.push({
              path: process.env.VUE_APP_HOME_PATH
            })
          }
        }
        clearInterval(interval);
      }
    }, 1000)
    return
  }
  if (to.matched.length === 0 && to.path.indexOf('/child-app') === -1) {
    //匹配不到相对应的路由时，跳转到首页
    console.log(to.matched.length, '匹配不到相对应的路由时，跳转到首页', '--22222222--', to.path);
    let i = 0;
    const interval = setInterval(() => {
      i++;
      if (router) {
        const routes = router.getRoutes();
        const targetRoute = routes.find(ele => ele.path.includes(`${to.path}?hidePadding=1`))
        if (targetRoute) {
          if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
            next({
              path: '/pedestal-user-center'
            })
            clearInterval(interval);
            return;
          }
          next({
            path: to.path
          });
          clearInterval(interval);
          return;
        }
        if (routes.length && routes.find(ele => ele.path === homePath)) {
          if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
            next({
              path: '/pedestal-user-center'
            })
            clearInterval(interval);
            return;
          }
          next({
            path: homePath
          });
          clearInterval(interval);
        }
      }
      if (i > 500) {
        clearInterval(interval);
        next({
          path: '/404'
        });
      }
    }, 20)
    return;
  }
  if (to.path === from.path) {
    if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
      next({
        path: '/pedestal-user-center'
      })
      return;
    }
    next();
    return;
  }
  if (to.path !== '/pedestal-user-center' && focusUpdatePWD()) {
    next({
      path: '/pedestal-user-center'
    })
    return;
  }
  next();
});

export default router
