import IBehaviors from './IBehaviors';
import eventBus from '@/plugins/eventBus';

export default class MeasureBehaviors extends IBehaviors {
    constructor(viewer, comp) {
        super(viewer, comp);
        this.viewer = viewer;
        this.comp = comp;

        this.init();
    }

    init() {
        const list = this.getBehaviors();

        this.registerBehaviors(list);
    }

    getBehaviors() {
        return [
            {
                label: '[测量]测点',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'measure_measurePosition',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Measure.measurePosition({
                        isLoop: true,
                        callback: (xyz) => {
                            eventBus.$emit('ViewerGIS.onMeasurePoint', this.comp.element.id, xyz);
                        }
                    });
                }
            },
            {
                label: '[测量]注销测量',
                desc: `
                    参数[ 空 ]。 
                    `,
                value: 'measure_clearMeasure',
                func: () => {
                    if (!this.viewer)
                        return this.comp.$message.error('操作失败，viewer未初始化');

                    this.viewer.Measure.clearMeasure();
                }
            },
        ];
    }
}
