<template>
    <MarkerSingle
            ref="markerTree"
            class="moduleWrap"
            :element.sync="element"
            :bindViewerId="bindViewerId"
            :bindViewerType="bindViewerType"
            :subItemSpread="subItemSpread"
            @showAll="onShowAll"
            @createGroup="onCreateGroup"
            @updateGroup="onUpdateGroup"
            @removeGroup="onRemoveGroup"
            @createActiveMarker="onCreateActiveMarker"
            @updateActiveMarker="onUpdateActiveMarker"
            @removeActiveMarker="onRemoveActiveMarker"
            @createMarker="onCreateMarker"
            @showMarker="onShowMarker"
            @locationMarker="onLocationMarker"
            @renameMarker="onRenameMarker"
            @preUpdateMarker="onPreUpdateMarker"
            @updateMarker="onUpdateMarker"
            @resetMarker="onResetMarker"
            @saveUpdateMarker="onSaveUpdateMarker"
            @removeMarker="onRemoveMarker"
    >
    </MarkerSingle>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import { dataInterface } from '@/apis/data/index';
    import MarkerSingle from "@/custom-component/viewerComponent/MarkerSingle";

    const DataBaseInfo = {
        Group: {
            object_uuid: 'object643758ceb0fce',
            view_uuid: {
                all: 'view64375e6e75e1d',
            }
        },
        Marker: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        }
    };

    export default {
        name: "ViewerGISMarker",
        components: {
            MarkerSingle
        },
        data() {
            return {
                subItemSpread: 'right',
                bindViewerId: null,
                bindViewerType: 'GIS',
                editObject: null,

                compInitedFlag: false
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            if (this.element.viewerBindId) {
                if (this.checkBind(this.element.viewerBindId)) {
                    this.bindViewerId = this.element.viewerBindId;
                }
            }

            if(this.element.subItemSpread){
                this.subItemSpread = this.element.subItemSpread;
            }

            if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && this.bindViewerId){
                eventBus.$emit("ViewerGIS.getViewerInitedFlag", this.bindViewerId, (opt = {}) => {
                    let {viewerInited = false} = opt;
                    if(viewerInited){
                        this.getMarkerData(this.bindViewerId);
                    }else{
                        this.initViewerInitedEventListener();
                    }
                });
            }else{
                this.initViewerInitedEventListener();
            }
        },
        methods: {
            /**
             * @description: 注册组件事件
             */
            initEventListener() {
                eventBus.$on("ViewerGISMarker.GetSource", (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== "ViewerGIS") {
                                if (item.children && item.children.length > 0) loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex(x => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                        spreadDirection: this.subItemSpread
                    });
                });

                eventBus.$on("ViewerGISMarker.BindViewer", (eid, bindId) => {
                    if (eid !== this.element.id) return;
                    if(this.element.viewerBindId && this.element.viewerBindId === bindId) return;
                    if (!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);
                    this.getMarkerData(this.bindViewerId);
                });

                eventBus.$on("ViewerGISMarker.SubItemSpread", (eid, direction) => {
                    if (eid !== this.element.id) return;

                    this.element.subItemSpread = direction;
                    this.subItemSpread = direction;
                });

                eventBus.$on('ViewerGISMarker.getCompInitedFlag', (eid , cb) => {
                    if( eid !== this.element.id) return;

                    cb && cb(this.compInitedFlag);
                });

                eventBus.$on("ViewerGISMarker.onTemplateMarkerClick", (eid, key, cb) => {
                    if (eid !== this.bindViewerId) return;

                    let data = this.$refs.markerTree.getMarkerItem(key);
                    cb && cb(data);
                });

                eventBus.$on("ViewerGISMarker.onMarkerShow", (eid, checked) => {
                    if (eid !== this.element.id) return;
                    if(!checked) return;

                    let {nodes} = this.$refs.markerTree.getMarkerCheckedNodes();

                    for (let item of nodes){
                        if(item.type ==='Group'){
                            continue;
                        }else if(item.type === 'Child'){
                            let temp = Number(item.id.split('_')[1]);
                            if(!checked.includes(temp)){
                                this.onShowMarker(item, false, false);
                                this.$refs.markerTree.setTreeNodeCheck([item.id], false);
                            }
                        }
                    }

                    for (let item of checked){
                        let data = this.$refs.markerTree.getMarkerItem(`child_${item}`);
                        if(!data) continue;

                        this.onShowMarker(data, true, false);
                        this.$refs.markerTree.setTreeNodeCheck([data.id], true);
                    }

                    this.getNodeShow();
                });
            },
            /**
             * @description: 注册Viewer组件初始化监听事件
             */
            initViewerInitedEventListener(){
                eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                    if (eid !== this.bindViewerId) return;

                    let {viewerInited = false} = opt;
                    if(viewerInited) this.getMarkerData(this.bindViewerId);
                });
            },
            /**
             * @description: 检验绑定的Viewer是否有效
             * @param {String} bindId Viewer标识值
             */
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

                return hasComp;
            },

            /**
             * @description: 获取数据仓库检索条件
             */
            getDataSearch() {
                const archi_id = this.$GetTargetArchi('id');

                let search = [
                    {
                        code: 'archi_type',
                        ruleType: 'eq',
                        value: archi_id
                    },
                    {
                        code: 'type',
                        ruleType: 'eq',
                        value: 'GISMarker'
                    }
                ];

                return search;
            },
            /**
             * @description: 获取标记列表数据
             */
            async getMarkerData(bindId = this.bindViewerId) {
                if (!bindId) {
                    this.$refs.markerTree.emptyTreeData();
                    eventBus.$emit('ViewerGIS.removeAllTemplateMarker', this.bindViewerId);
                    return;
                }

                this.$refs.markerTree.emptyTreeData();
                eventBus.$emit('ViewerGIS.removeAllTemplateMarker', this.bindViewerId);
                this.$refs.markerTree.setIsLoading(true);

                const groupRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch()
                });

                if (
                    groupRes?.data?.data &&
                    groupRes?.data?.data?.data &&
                    groupRes.status === 200
                ) {
                    const groupList = groupRes?.data?.data?.data;

                    for (let { id, name } of groupList) {
                        let item = {
                            id: `group_${id}`,
                            name: name,
                            type: 'Group',
                            children: []
                        };
                        this.$refs.markerTree.appendTreeData(item, null, false);
                    }
                }

                const childRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Marker.object_uuid,
                    view_uuid: DataBaseInfo.Marker.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch()
                });

                if (
                    childRes?.data?.data &&
                    childRes?.data?.data?.data &&
                    childRes.status === 200
                ) {
                    const childList = childRes?.data?.data?.data;

                    for (let {id, name, group_id, state, archi_id, archi_type, created_at} of childList) {
                        let item = {
                            id: `child_${id}`,
                            name: name,
                            type: 'Child',
                            group_id: `group_${group_id}`,
                            state: state,
                            archi_id,
                            archi_type,
                            created_at
                        };
                        let msg = this.$refs.markerTree.appendTreeData(item, item.group_id, false);
                        if(msg) {
                            let options = this.$refs.markerTree.getMarkerOptions(JSON.parse(JSON.stringify(state)));
                            eventBus.$emit('ViewerGIS.createTemplateMarker', this.bindViewerId, item.id, options, false);
                        }
                    }
                }

                this.$refs.markerTree.setIsLoading(false);
                if(!this.compInitedFlag) {
                    this.compInitedFlag = true;
                    eventBus.$emit('ViewerGISMarker.onCompInited', this.element.id);
                    this.getNodeShow();
                }
            },
            /**
             * @description: 设置标记当前显示的状态
             */
            getNodeShow(){
                this.$nextTick(() => {
                    let {nodes} = this.$refs.markerTree.getMarkerCheckedNodes();

                    let checked = [];
                    for (let item of nodes){
                        if(item.type === 'Group'){
                            continue;
                        }else if(item.type === 'Child') {
                            checked.push(Number(item.id.split('_')[1]));
                        }
                    }

                    eventBus.$emit('ViewerGISViewPoint.GetMarkerStatus', this.element.id, checked);
                });
            },

            /**
             * @description: 显示全部
             * param {Boolean} isShow 是否显示
             */
            onShowAll(isShow){
                this.$refs.markerTree.setAllNodeCheck(isShow);

                let nodes = this.$refs.markerTree.getAllMarkerItem();
                for (let item of nodes){
                    this.onShowMarker(item, isShow, false);
                }
                this.getNodeShow();
            },

            /**
             * @description: 创建标记分组
             */
            async onCreateGroup(){
                let name = await this.$prompt('请输入分组名称：', '提示').catch(() => {});
                if(!name) return;

                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    type: 'GISMarker',
                    name: name.value
                });

                if (!res?.data?.data || res.status !== 200){
                    return this.$message.warning(`[GIS]创建标记分组失败！`);
                }
                const data = res?.data?.data;

                let item = {
                    id: `group_${data.id}`,
                    name: name.value,
                    type: 'Group',
                    children: []
                };
                let msg = this.$refs.markerTree.appendTreeData(item, null, true);
                this.$message.success(`创建标记分组${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 修改标记分组
             * @param {Object} data 树节点对象
             */
            async onUpdateGroup(data){
                let name = await this.$prompt('请输入分组名称：', '提示', {
                    inputValue: data.name
                }).catch(() => {});
                if(!name) return;

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    data_id: data.id.split('_')[1],
                    name: name.value
                });

                let msg = this.$refs.markerTree.updateTreeData(data.id, 'name', name.value);
                this.$message.success(`标记分组重命名${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 删除标记分组
             * @param {Object} data 树节点对象
             */
            async onRemoveGroup(data){
                if(data.children){
                    let children = [];
                    for(let item of data.children){
                        children.push({
                            id: item.id,
                            type: item.type
                        })
                    }

                    for(let item of children){
                        await this.onRemoveMarker(item, false);
                    }
                }

                await dataInterface({
                    __method_name__: 'deleteData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    data_id: data.id.split('_')[1]
                });
                let msg = this.$refs.markerTree.deleteTreeData(data.id);
                this.$message.success(`[GIS]标记分组删除${msg ? '成功' : '失败'}！`);
                this.getNodeShow();
            },

            /**
             * @description: 创建临时标记
             * @param {String} data 标签数据
             */
            onCreateActiveMarker(data){
                eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.bindViewerId, (position) => {
                    data = Object.assign({
                        position
                    }, data);

                    eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.bindViewerId);
                    eventBus.$emit('ViewerGIS.createTemplateMarker', this.bindViewerId, 'temp', data, true);
                    this.$refs.markerTree.setEditorByActive(data);
                });
            },
            /**
             * @description: 修改临时标记
             * @param {String} data 标签数据
             */
            onUpdateActiveMarker(data){
                eventBus.$emit('ViewerGIS.updateTemplateMarker', this.bindViewerId, 'temp', data);
            },
            /**
             * @description: 删除临时标记
             */
            onRemoveActiveMarker(){
                eventBus.$emit('ViewerGIS.removeTemplateMarker', this.bindViewerId, 'temp');
                this.$refs.markerTree.setEditorByEmpty();
            },

            /**
             * @description: 创建标记
             * @param {String} data 标签数据
             */
            async onCreateMarker(data){
                let name = await this.$prompt('请输入标记名称：', '提示').catch(() => {});
                if(!name) return;

                const group_id = data.options.pId.split('_')[1];
                let state = {
                    imageType: data.imageType,
                    markerType: data.markerType,
                    position: data.position,
                    options: data.options
                };
                eventBus.$emit('ViewerGIS.removeTemplateMarker', this.bindViewerId, 'temp');

                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Marker.object_uuid,
                    view_uuid: DataBaseInfo.Marker.view_uuid['all'],
                    type: 'GISMarker',
                    name: name.value,
                    group_id: group_id,
                    state: JSON.stringify(state)
                });

                if (!res?.data?.data || res.status !== 200){
                    return this.$message.warning(`[GIS]创建标记失败！`);
                }
                const resData = res?.data?.data;

                let item = {
                    id: `child_${resData.id}`,
                    name: name.value,
                    type: 'Child',
                    group_id: `group_${group_id}`,
                    state: state,
                    archi_id: resData.archi_id,
                    archi_type: resData.archi_type,
                    created_at: resData.created_at
                };
                let msg = this.$refs.markerTree.appendTreeData(item, item.group_id, true);
                if(msg) {
                    eventBus.$emit('ViewerGIS.createTemplateMarker', this.bindViewerId, item.id, data, true);
                    this.$refs.markerTree.setEditorByEmpty();
                    this.this.getNodeShow();
                }
                this.$message.success(`[GIS]创建标记${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 标记列表显示隐藏事件
             * @param {Object} data 树节点对象
             * @param {Boolean} isChecked 是否勾选
             * @param {Boolean} isDeep 是否加入计算属性
             */
            onShowMarker(data, isChecked, isDeep = true){
                if(data.type === 'Group'){
                    data.children.map((item) => {
                        eventBus.$emit('ViewerGIS.showTemplateMarker', this.bindViewerId, item.id, isChecked);
                    });
                }else if(data.type === 'Child'){
                    eventBus.$emit('ViewerGIS.showTemplateMarker', this.bindViewerId, data.id, isChecked);
                }

                if(isDeep) this.getNodeShow();
            },
            /**
             * @description: 标记列表对象定位事件
             * @param {Object} data 树节点对象
             * @param {Boolean} isChecked 当前对象是否勾选
             */
            onLocationMarker(data, isChecked){
                if(!isChecked) {
                    this.onShowMarker(data, true);
                    this.$refs.markerTree.setTreeNodeCheck([data.id], true);
                }

                if(data.type === 'Group'){
                    let child = [];
                    data.children.forEach(x => child.push(x.id));

                    eventBus.$emit('ViewerGIS.locationTemplateMarkerGroup', this.bindViewerId, child);
                }else{
                    eventBus.$emit('ViewerGIS.locationTemplateMarker', this.bindViewerId, data.id);
                }
            },
            /**
             * @description: 标记列表对象重命名事件
             * @param {Object} data 树节点对象
             */
            async onRenameMarker(data){
                let name = await this.$prompt('请输入标记名称：', '提示', {
                    inputValue: data.name
                }).catch(() => {});
                if(!name) return;

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Marker.object_uuid,
                    view_uuid: DataBaseInfo.Marker.view_uuid['all'],
                    data_id: data.id.split('_')[1],
                    name: name.value
                });

                let msg = this.$refs.markerTree.updateTreeData(data.id, 'name', name.value);
                this.$message.success(`标记对象重命名${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 标记列表对象预修改事件
             * @param {Object} data 树节点对象
             */
            onPreUpdateMarker(data){
                this.editObject = data;

                let {state} = data;
                this.$refs.markerTree.setEditorByUpdate(JSON.parse(JSON.stringify(state)));
            },
            /**
             * @description: 标记列表对象修改事件
             * @param {Object} data 修改样式对象
             */
            onUpdateMarker(data){
                if(!this.editObject) return;

                eventBus.$emit('ViewerGIS.updateTemplateMarker', this.bindViewerId, this.editObject.id, data);
            },
            /**
             * @description: 标记列表对象样式重置事件
             */
            onResetMarker(){
                if(!this.editObject) return;

                let data = this.$refs.markerTree.getMarkerOptions(Object.assign({}, this.editObject.state));
                eventBus.$emit('ViewerGIS.updateTemplateMarker', this.bindViewerId, this.editObject.id, data);
                this.$refs.markerTree.setEditorByEmpty();

                this.editObject = null;
            },
            /**
             * @description: 标记列表对象保存修改事件
             * {Object} data 修改样式对象
             */
            async onSaveUpdateMarker(data){
                if(!this.editObject) return;
                let state = {
                    imageType: data.imageType,
                    markerType: data.markerType,
                    position: data.position,
                    options: data.options
                };

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Marker.object_uuid,
                    view_uuid: DataBaseInfo.Marker.view_uuid['all'],
                    data_id: this.editObject.id.split('_')[1],
                    state: JSON.stringify(state)
                });

                let msg = this.$refs.markerTree.updateTreeData(this.editObject.id, 'state', state);
                if(msg) {
                    eventBus.$emit('ViewerGIS.updateTemplateMarker', this.bindViewerId, this.editObject.id, data);
                    this.$refs.markerTree.setEditorByEmpty();
                }
                this.$message.success(`[GIS]标记修改${msg ? '成功' : '失败'}！`);

                this.editObject = null;
            },
            /**
             * @description: 标记列表对象删除事件
             * @param {Object} data 树节点对象
             * @param {Boolean} isDeep 是否加入计算属性
             */
            async onRemoveMarker(data, isDeep = true){
                await dataInterface({
                    __method_name__: 'deleteData',
                    object_uuid: DataBaseInfo.Marker.object_uuid,
                    view_uuid: DataBaseInfo.Marker.view_uuid['all'],
                    data_id: data.id.split('_')[1]
                });

                let msg = this.$refs.markerTree.deleteTreeData(data.id);
                if(msg) {
                    eventBus.$emit('ViewerGIS.removeTemplateMarker', this.bindViewerId, data.id);
                    if(isDeep) this.getNodeShow();
                }
                this.$message.success(`[GIS]删除标记${msg ? '成功' : '失败'}！`);
            },
        },
        destroyed(){
            eventBus.$emit('ViewerGIS.removeAllTemplateMarker', this.bindViewerId);
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }
</style>
