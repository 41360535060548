const comps = require.context('./Components/' , true , /\/index.js$/);
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import Viewer from "./Viewer";
import Utils from './Utils';
import ViewerToolbar from "./ViewerToolbar";
// import ViewerModelList from "./ViewerModelList";
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import BehaviorHandlerMixin from '@/custom-component/mixins/BehaviorHandlerMixin.js';
// import { dataInterface } from '@/apis/data/index';

const DefaultServerConfig = {
    host : 'models.bimcc.net',
    isHttps : true,
    name : '模型服务器',
};
const DefaultAppConfig = {
    appId : 'bdafce12a2d9465d89821ec14214a382',
    appSecret : "3EYcdAjbnWxP",
    name : '主应用',
}
const DefaultModelID = 'fae9fe29b0fa477587b34812f1522b5e';

const ModelLoadedCallBack = {}

const getGuid = (format = 'xxxxxxxxxxxx') =>{
    var d = new Date().getTime();
    var guid = format.replace(
        /[xy]/g,
        function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
    return guid;
}
export default class ComponentFactory {
    constructor(){
        this.name = "ViewerBIM";
        this.components = {}
        this.initComponents();
    }

    /**
     * 初始化组件
     */
    initComponents(){
        for (let key of comps.keys()){
            const CompClass = comps(key).default;

            const comp = new CompClass();

            this.components[comp.id] = comp;
        }
    }

    generate(){
        let data = {
            viewer : null,
            componentIds : [],
            modelId : '',
            _EventBus  : {},
            sourceConfig : {
                host : '',
                port : '',
                isHttps : '',
                appId : '',
                appSecret : '',
            },
            setting : { //基础配置项
                showMask : false,
                backgroundColor : '',
                defaultViewState : null,
                fps : 70,
            },
            callbackMap : {}, //事件回调处理
            toolbarMap : {},
            preload : null, //预加载事件
            preloadModelMap : null, //预加载多模型处理
            eventBlockMap : { //事件锁
                select : false,
                state : false,
            },
            viewerInitFlag : false, //初始化事件完成前标记已经进入初始化
            viewerInitedFlag : false,
            isCloseMode : false, //是否是关闭模式
        }

        let watch = {
            "element.modelBIMSourceConfig"  ( val = {} , oldval = {} ){
                let flag = false;

                const list = ['host' , 'port' , 'isHttp' , 'appId' , 'appSecret' , 'modelId' , 'isDynamicSource'];

                for(let name of list) {

                    if(val[name] !== oldval[name]){
                        flag = true;
                        break;
                    }
                }

                if(flag){
                    this.refresh(val);
                }
            },
            "element.modelBIMSetting" (val = {} , old){
                if(JSON.stringify(val)!==JSON.stringify(old)){
                    this.refreshSetting(val);
                }
            },
            "element.database.viewData" (){
                // this.refreshModelListField();
            },
            "element.isCloseMode"( val ){
                this.isCloseMode = val;
                this.refresh();
            }, 
        }

        let methods = {
            //初始化
            init (){
                this.initEventListener();

                const SourceConfig = this.element.modelBIMSourceConfig;

                if( SourceConfig ){
                    if(this.preload && SourceConfig.isDynamicSource){

                        const opt = {
                            host : DefaultServerConfig.host,
                            isHttps : DefaultServerConfig.isHttps,
                            isDynamicSource : false,
                            appId : process.env.VUE_APP_MODEL_APPID,
                            appSecret :  process.env.VUE_APP_MODEL_APPSECRET,
                        };

                        //如果有选中信息就先把选中内容中查看的第一个模型设置为默认模型
                        if(this.preload.selection && this.preload.selection.length > 0){
                            const selection = this.preload.selection;

                            const firstMid = selection[0].model_id;
                            if(!opt.modelId){
                                opt.modelId =  firstMid;
                            }

                            for(let {model_id} of selection){
                                if(!this.preloadModelMap) this.preloadModelMap = {}

                                this.preloadModelMap[model_id] = false;
                            }

                            this.preloadModelMap[firstMid] = true;
                        }

                        if(this.preload.modelId) opt.modelId = this.preload.modelId;
                        if(this.preload.appId) opt.appId = this.preload.appId;
                        if(this.preload.appSecret) opt.appSecret = this.preload.appSecret;

                        this.refresh(opt);
                    }else{
                        this.refresh(SourceConfig);
                    }
                }
            },
            //刷新viewer
            async refresh(opt){
                for( let node of this.$refs.viewerDom.childNodes){
                    node.remove();
                }

                for( let node of this.$refs.viewerMarkDom.childNodes){
                    node.remove();
                }

                //不加载模式屏蔽掉所有功能
                if(this.isCloseMode) return;

                //动态数据源通过行为初始化viewer;
                if( !opt || opt.isDynamicSource ) return;

                if( !opt.modelId ) console.warn('非动态数据源加载BIM Viewer未设定模型id 将打开默认模型');

                this.viewerInitFlag = true;

                let modelId = opt.modelId ? opt.modelId : DefaultModelID;

                if(!opt) {
                    opt = {
                        host: DefaultServerConfig.host,
                        port: DefaultServerConfig.port,
                        isHttps: DefaultServerConfig.isHttps,
                        appId: DefaultAppConfig.appId,
                        appSecret: DefaultAppConfig.appSecret,
                    }
                }

                const setting = {
                    host : opt.host,
                    port : opt.port,
                    isHttps : opt.isHttps,
                    appId : opt.appId,
                    appSecret : opt.appSecret,
                }

                const viewer = this.viewer = new Viewer( this.$refs.viewerDom  , setting );

                this.initViewerEventListener();

                await viewer.init(modelId);

                this.modelId = modelId;

                this.sourceConfig = Object.assign({},opt);

                const Setting = this.element.modelBIMSetting;
                if( Setting ) this.refreshSetting(Setting);
            },
            //刷新viewer设置
            refreshSetting(opt) {
                this.setting.showMask = !!opt.showMask;

                if( opt.backgroundColor ){
                    const color = Utils.color16ToVector3(opt.backgroundColor)

                    this.setting.backgroundColor = opt.backgroundColor;

                    this.viewer.setBackgroundColor(...color);
                }

                if( opt.defaultViewState ){
                    const state = Object.assign({} , opt.defaultViewState);

                    this.setting.defaultViewState = state;
                    this.viewer.restoreState( state );
                }

                if( opt.fps ){
                    this.viewer.setFPS( opt.fps );
                }

                if( opt.explode ){
                    this.viewer.setExplode( opt.explode /100 );
                }

                if( opt.antialiasing !== undefined ){
                    this.viewer.setAntialiasing( opt.antialiasing );
                }

                if( opt.groundShadow !== undefined ){
                    this.viewer.setGroundShadow( opt.groundShadow );
                }

                this.viewer.action('setClearColor',!!opt.removeBackground);
            },
            //viewer事件监听
            initViewerEventListener(){
                const viewer = this.viewer;
                viewer.on( Viewer.Events.Error.ViewerLoadModelInfoError , ( modelId , err)=>{
                    let msg = `[${this.element.name}]加载模型[${modelId}]失败 `;

                    if(err){
                        if(err.msg){
                            msg += err.msg;
                        }

                        if(err.data && err.data.msg){
                            msg += err.data.msg;
                        }
                    }

                    this.$message.error(msg);
                });

                viewer.on( Viewer.Events.ViewerLoadedModel , (model_id)=>{
                    eventBus.$emit("Viewer.onLoadedModel" , this.element.id ,  model_id);
                    this.onAction('onViewerLoadedModel');
                    //处理预加载选中构件
                    if(this.preloadModelMap){
                        this.preloadModelMap[model_id] = true;

                        let isAllLoad = true;
                        for(let mid in this.preloadModelMap){
                            if(!this.preloadModelMap[mid]){
                                isAllLoad = false;
                                break;
                            }
                        }

                        if(isAllLoad && this.preload && this.preload.selection){
                            this.viewer.select(this.preload.selection);
                            this.viewer.fitToView();

                            this.preloadModelMap = null;
                        }
                    }


                    if(ModelLoadedCallBack[model_id]){
                        ModelLoadedCallBack[model_id]();
                        delete ModelLoadedCallBack[model_id];
                    }

                });

                viewer.on( Viewer.Events.ViewerSelect , async ( selection ) =>{
                    eventBus.$emit("Viewer.onViewerSelect" , this.element.id , selection);
                    this.onAction('onViewerSelect');

                    const ele = this.element;

                    if(this.eventBlockMap['select']){
                        this.eventBlockMap['select'] = false;
                    }else{
                        if(ele.bindViewerId && ele?.bindViewerInfo?.bindSelect ){
                            eventBus.$emit("ViewerBIM.eventBlock" , this.element.bindViewerId , "select");
                            eventBus.$emit("ViewerBIM.setSelect" , this.element.bindViewerId , selection);
                        }
                    }

                    if(selection.length > 0 ){
                        const { dbids } = selection[0];
                        const prop =  await this.viewer.action('getProperties', dbids[0]);
                        if( !prop) return;

                        this.setResolveData("SelectionProps", prop);
                        eventBus.$emit('databaseTrigger', {
                            componentId: this.element.id,
                            isSearch: true,
                            action: 'any',
                            output: this.element.resolveData,
                        });
                    }
                });

                viewer.on( Viewer.Events.ViewerInited , () =>{
                    // this.refreshModelListField();

                    this.viewerInitedFlag = true;
                    eventBus.$emit("Viewer.onViewerInited" , this.element.id );
                    this.onAction('onViewerInited');

                    if(this.element.modelBIMToolbar){
                        this.$refs.toolbar.refresh(this.toolbarMap , this.element.modelBIMToolbar.display , this.element.id);

                        if(this.element.modelBIMToolbar.hideIds){
                            this.$refs.toolbar.setHideIds(this.element.modelBIMToolbar.hideIds );
                        }

                    }else{
                        this.$refs.toolbar.refresh(this.toolbarMap , true , this.element.id);
                    }

                    if(this.preloadModelMap){
                        for(let mid in this.preloadModelMap){
                            if(!this.preloadModelMap[mid]){
                                this.viewer.loadModel(mid);
                            }
                        }
                    }

                });

                viewer.on( Viewer.Events.ViewerSingleClick , ( { x, y })=>{
                    eventBus.$emit("Viewer.onViewerSingleClick" , this.element.id , { x , y});
                    this.onAction('onViewerSingleClick' );
                });

                viewer.on( Viewer.Events.ViewerCameraChange ,(obj , cameraPos) =>{
                    eventBus.$emit("Viewer.onCameraChange" , this.element.id , obj , cameraPos);
                    this.onAction('onViewerCameraChange');

                    const ele = this.element;

                    if(ele.bindViewerId  ){
                        if( ele?.bindViewerInfo?.bindCamera && ele?.bindViewerInfo?.bindCameraMainId === ele.id ){
                            const state = this.viewer.getState();

                            eventBus.$emit('ViewerBIM.restoreState' , ele.bindViewerId , state);
                        }
                    }
                });

                for(let compId of this.componentIds){
                    this[`_${compId}_initViewerEventListener`]()
                }
            },
            /**
             * 初始化eventbus事件
             */
            initEventListener(){
                this._EventBus = {
                    "EDITOR_resizeComponent" : ()=>{
                        if(this.viewer){
                            this.viewer.resize();
                        }
                    },
                    "ViewerBIM.getState" :  ( eid , cb )=>{
                        if( eid !== this.element.id)return;
                        if( !this.viewer ) return cb({});
                        const state = this.viewer.getState();
                        cb( Object.assign({} , state));
                    },
                    'ViewerBIM.restoreState' : ( eid , state)=>{
                        if( eid !== this.element.id )return;
                        if( !this.viewer ) return;

                        this.viewer.restoreState( Object.assign({} , state) );
                    },
                    'ViewerBIM.getMousePoint' : async (eid , cb )=>{
                        if( eid !== this.element.id)return;

                        const position = await this.viewer.action('getMousePoint');
                        cb && cb(position);
                    },
                    'ViewerBIM.setSelect' : (eid , list )=>{
											if( eid !== this.element.id)return;
											//连选
											if (+this._APPCustomStatus?.continuousCheck === 1) {
												this.viewer.addSelect(list)
												//隔离
												if(+this._APPCustomStatus?.isolation === 1){
													this.viewer.setGhosting(true)
													this.viewer.addIsolate(list)
												}else{
													this.viewer.setGhosting(false)
												}
											} else {
												this.viewer.showAll()
												this.viewer.select(list);
												//隔离
												if(+this._APPCustomStatus?.isolation === 1){
													this.viewer.addIsolate(list)
													this.viewer.setGhosting(true)
												}else{
													this.viewer.setGhosting(false)
												}
											}
                    },
                    'ViewerBIM.getSelection' : ( eid , cb) => {
                        if( eid !== this.element.id)return;

                        cb && cb(this.viewer.getSelection());
                    },
                    'ViewerBIM.getModelIds' : ( eid , cb )=>{
                        if( eid !== this.element.id)return;
                        const ids = this.viewer.action('getModelIds');

                        if(!ids) return cb([]);

                        cb([...ids]);
                    },
										'ViewerBIM.isolateData' : ( eid, dbids  )=>{
											if( eid !== this.element.id)return;
											console.log(this.viewer,'this.viewer')
											this.viewer.isolate(dbids);
										},
										'ViewerBIM.setColor' : ( eid, model_id, dbids, color  )=>{
											if( eid !== this.element.id)return;
											this.viewer.action('setColor' , dbids , color , model_id);
										},
										
										'ViewerBIM.clearAllColor' : ( eid  )=>{
											if( eid !== this.element.id)return;
											this.viewer.action('clearAllcolor');
										},
                    'ViewerBIM.loadModel' : ( eid , id)=>{
                        if( eid !== this.element.id)return;

                        this.viewer.loadModel(id);
                    },
                    'ViewerBIM.unloadModel' : (eid , id)=>{
                        if( eid !== this.element.id)return;

                        this.viewer.unloadModel(id);
                    },
                    'ViewerBIM.addPosition' : ( eid ,id , position, cb )=>{
                        if( eid !== this.element.id)return;
                        let info = this.viewer.addPosition(id , position );
                        cb && cb( info );
                    },
                    'ViewerBIM.changeUseToolbar' : ( eid , isUse)=>{
                        if( eid !== this.element.id)return;

                        this.$refs.toolbar.display(isUse);

                        if(!this.element.modelBIMToolbar){
                            this.element.modelBIMToolbar = {}
                        }

                        this.element.modelBIMToolbar['display'] = isUse;
                    },
                    "ViewerBIM.Toolbar.GetConfig" :( eid , cb )=>{
                        if( eid !== this.element.id)return;

                        if( !this.element.modelBIMToolbar )  cb && cb();

                        cb && cb( Object.assign({} , this.element.modelBIMToolbar));
                    },
                    "ViewerBIM.CustomToolbar.GetConfig" : (eid , cb )=>{
                        if( eid !== this.element.id)return;

                        if( !this.element.modelBIMCustomToolbar) cb && cb();

                        cb && cb( Object.assign({} , this.element.modelBIMCustomToolbar));
                    },
                    "ViewerBIM.changeToolbarHideIds" : (eid , ids) =>{
                        if( eid !== this.element.id)return;

                        this.$refs.toolbar.setHideIds(ids);

                        if(!this.element.modelBIMToolbar){
                            this.element.modelBIMToolbar = {}
                        }

                        this.element.modelBIMToolbar['hideIds'] = ids;
                    },
                    // "ViewerBIM.changeModelListField" : (eid , { modelId, modelName})=>{
                    //     if( eid !== this.element.id)return;

                    //     this.element.modelBIMField["ModelList"].modelId = modelId;
                    //     this.element.modelBIMField["ModelList"].modelName = modelName;

                    //     this.refreshModelList();
                    // },
                    "ViewerBIM.syncCustomToolbar" : (eid , data) =>{
                        if( eid !== this.element.id)return;

                        this.addCustomToolbar(data);
                    },
                    "ViewerBIM.deleteCustomToolbar" : (eid , id)=>{
                        if( eid !== this.element.id)return;

                        this.deleteCustomToolbar(id);
                    },
                    "ViewerBIM.getNodeChildren" : async (eid , model_id , dbid , cb)=>{
                        if( eid !== this.element.id)return;
                        const data = await this.viewer.getNodeChildren(model_id , dbid);
                        cb && cb(data);
                    },
                    "ViewerBIM.fitToView" : (eid , list )=>{
                        if( eid !== this.element.id)return;

                        this.viewer.fitToView(list);
                    },
                    "ViewerBIM.getLoadedModelInfo" : (eid , cb )=>{
                        if( eid !== this.element.id)return;

                        cb && cb(this.viewer.getLoadedModelInfo());
                    },
                    "ViewerBIM.hide" : (eid , selection )=>{
                        if( eid !== this.element.id)return;

                        this.viewer.hide(selection);
                    },
                    "ViewerBIM.show" : (eid , dbids , model_id) =>{
                        if( eid !== this.element.id)return;

                        this.viewer.show(dbids , model_id);
                    },
                    "ViewerBIM.getBindViewerInfo" : (eid , cb) =>{
                        if( eid !== this.element.id)return;

                        const comps = [];

                        for(let comp of this.componentData){
                            if(comp.component !== "ViewerBIM" || comp.id === this.element.id ) continue;

                            comps.push({
                                id : comp.id,
                                name : comp.name,
                            });
                        }

                        cb({
                            list : comps,
                            bindId : this.element.bindViewerId,
                            info : this.element.bindViewerInfo,
                        });
                    },
                    "ViewerBIM.bindingViewer" : (eid , targetId , info )=>{
                        if( eid !== this.element.id)return;

                        this.element.bindViewerId = targetId;
                        this.element.bindViewerInfo = info;
                    },
                    "ViewerBIM.eventBlock" : (eid , type) => {
                        if( eid !== this.element.id)return;

                        this.eventBlockMap[type] = true;
                    },
                    "ViewerBIM.changeBindInfo" : (eid , info)=>{
                        if( eid !== this.element.id)return;

                        this.element.bindViewerInfo = info;
                    },
                    "ViewerBIM.initViewer" : (eid , modelId) =>{
                        if( eid !== this.element.id)return;

                        const appId = process.env.VUE_APP_MODEL_APPID;
                        const appSecret = process.env.VUE_APP_MODEL_APPSECRET;

                        const opt = {
                            appId, appSecret, modelId,
                            host : DefaultServerConfig.host,
                            isHttps : DefaultServerConfig.isHttps,
                        }

                        this.refresh(opt);
                    },
                    "ViewerBIM.getDataMainId" : (eid , cb)=>{
                        if( eid !== this.element.id)return;

                        switch(this.element.modelBIMDataMainIdType){
                            case "custom":
                                cb && cb(this.element.modelBIMDataMainId);
                                break;
                            case "model":
                                cb && cb(this.viewer.getFristLoadModelId());
                                break;
                        }
                    },
                    "ViewerBIM.getScreenShotBuffer" :async (eid , cb)=>{
                        if( eid !== this.element.id) return;

                        const data = await this.viewer.action('getScreenShot');
                        cb && cb(data);
                    },
                    "ViewerBIM.getViewerInitFlag" : (eid , cb )=>{
                        if( eid !== this.element.id) return;

                        cb && cb(this.viewerInitFlag);
                    },
                    "ViewerBIM.getViewerInitedFlag" : (eid , cb )=>{
                        if( eid !== this.element.id) return;

                        cb && cb(this.viewerInitedFlag);
                    },
                    "ViewerBIM.getBoundingSphere" : async (eid , dbid , model_id , cb)=>{
                        if( eid !== this.element.id) return;

                        const bs = await this.viewer.getBoundingSphere(dbid , model_id)
                        cb && cb(bs);
                    },
                    "databaseTrigger" : ( { componentId } )=>{
												if(!this.element.paramsConfig) return
                        for(let params of this.element.paramsConfig){
                            if(params.componentId === componentId){
                                //处理数据获取

                                //需要初始化模型的数据获取 初始化已经完成就放弃了
                                if(!this.viewer){
                                    const element = this.element;
                                    const { param } = this.initParams(element.paramsConfig, element.isGroup, element.componentData, element.groupComponents);

                                    if( param.modelId ){
                                        
                                        let mid = param.modelId
                                        if( Array.isArray( param.modelId  )){
                                            if( !this.preloadModelMap ) this.preloadModelMap = {}
                                            mid = param.modelId.splice( 0 , 1 )[0];
                
                                            for(let id of param.modelId ){
                                                this.preloadModelMap[id] = false;
                                            }
                                        }

                                        if(this.element.modelBIMSourceConfig){
                                            //初始化viewer
                                            const opt = Utils.deepCopy(this.element.modelBIMSourceConfig);

                                            //动态源也需要读取默认
                                            if( !opt.appId || opt.isDynamicSource) opt.appId = process.env.VUE_APP_MODEL_APPID;
                                            if( !opt.appSecret || opt.isDynamicSource) opt.appSecret =  process.env.VUE_APP_MODEL_APPSECRET;

                                            opt.modelId = mid;
                                            opt.isDynamicSource = false;


                                            this.refresh(opt);
                                        }else{
                                            //万一没配源呢。。

                                            const opt = {
                                                host : DefaultServerConfig.host,
                                                isHttps : DefaultServerConfig.isHttps,
                                                isDynamicSource : false,
                                                appId : process.env.VUE_APP_MODEL_APPID,
                                                appSecret :  process.env.VUE_APP_MODEL_APPSECRET,
                                                modelId : mid,
                                            };
                                            this.refresh(opt);
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "setCameraRotationBasePoint": () => {
                        this.viewer.setCameraRotationBasePoint()
                    },
                    'setCameraRotation':(h, v, fov = 90)=>{
                        this.viewer.setCameraRotation(h, v, fov);
                    }
                }

                for( let id in this._EventBus){
                    eventBus.$on(id , this._EventBus[id]);
                }
            },
            //初始化基础的行为
            base_initBehaviors(){

                const parseParamDbids = ( _dbids )=>{
                    return _dbids.split(",").map(( str )=>{
                        return parseInt(str);
                    });
                }

                this.registerBehaviors([{
                    label : "[基础]初始化Viewer",
                    desc : `
                        参数[ appId ]。 appId=>有效的应用ID 例如[bdafce12a2d9465d89821ec14214a382] 
                        参数[ appSecret ]。 appSecret=>有效的应用秘钥 例如[3EYcdAjbnWxP]
                        参数[ modelId ]。 modelId=>模型ID 例如[fae9fe29b0fa477587b34812f1522b5e]
                    `,
                    value : "init_viewer",
                    params : [ 'modelId', 'appSecret', 'appId'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【init_viewer】`,params);
                        if( !params?.modelId ) return this.$message.error('操作失败，初始化模型未传入模型id'); 
                        const { modelId } = params;
                        console.log(params,'初始化模型参数', modelId)
                        const appId = params.appId ? params.appId : process.env.VUE_APP_MODEL_APPID;
                        const appSecret = params.appSecret ? params.appSecret :process.env.VUE_APP_MODEL_APPSECRET;

                        let mid = modelId
                        if( Array.isArray( modelId )){
                            if( !this.preloadModelMap ) this.preloadModelMap = {}
                            mid = modelId.splice( 0 , 1 )[0];

                            for(let id of modelId){
                                this.preloadModelMap[id] = false;
                            }
                        }

                        const opt = {
                            appId, 
                            appSecret, 
                            modelId : mid,
                            host : DefaultServerConfig.host,
                            isHttps : DefaultServerConfig.isHttps,
                        }

                        this.refresh(opt);
                    },
                },{
                    label : "[基础]加载模型",
                    desc : `
                        参数[ modelId ]。 modelId=>模型ID 例如[fae9fe29b0fa477587b34812f1522b5e]
                    `,
                    value : "load_model",
                    params : [ 'modelId' ],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【load_model】`,params);
                        if( !params?.modelId )return this.$message.error('操作失败，未传入模型id');
                        if( !this.viewer) return this.$message.error('操作失败，viewer未初始化');
                        const { modelId } = params;
                        this.viewer.loadModel(modelId);
                    },
                },{
                    label : "[基础]选中构件",
                    desc : `
                        参数[ dbids ]。 dbids=>构件id列表 例如[12,15]
                    `,
                    value : "viewer_select",
                    params : [ 'dbids' ],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_select】`,params);
                        if( !params.dbids && !params.common) return this.$message.error('操作失败，参数错误');
                        if( !this.viewer) return this.$message.error('操作失败，viewer未初始化');
    
                        // 通用格式解析
                        if( params.common){
                            const list = [];
                            for(let { model_id, select} of params.common){
                                list.push({model_id, dbids : select});
                            }
                            this.viewer.select(list);
                        }else{
                            const dbids = parseParamDbids(params.dbids);
                            this.viewer.select([{
                                model_id : this.viewer.mainModelId,
                                dbids,
                            }]);
                        }
                    },
                },{
                    label : "[基础]隔离构件",
                    desc : `
                        参数[ dbids ]。 dbids=>构件id列表 例如[12,15]
                    `,
                    value : "viewer_isolate",
                    params : ['dbids'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_isolate】`,params);
                        if( !params?.dbids ) return this.$message.error('操作失败，参数错误');
                        if( !this.viewer) return this.$message.error('操作失败，viewer未初始化');

                        const dbids = parseParamDbids(params.dbids);
                        this.viewer.isolate(dbids);
                    },
                },{
                    label : "[基础]视角聚焦",
                    desc : `
                        参数[ dbids ]。 dbids=>构件id列表 例如[12,15]
                    `,
                    value : "viewer_fitToView",
                    params : ['dbids'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_fitToView】`,params);
                        if( !params?.dbids ) return this.$message.error('操作失败，参数错误');
                        if( !this.viewer) return this.$message.error('操作失败，viewer未初始化');

                        const dbids = parseParamDbids(params.dbids);
                        this.viewer.fitToView(dbids);
                    },
                },{
                    label : "[基础]隐藏构件",
                    desc : `
                        参数[ dbids ]。 dbids=>构件id列表 例如[12,15]
                    `,
                    value : "viewer_hide",
                    params : ['dbids'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_hide】`,params);
                        if( !params?.dbids ) return this.$message.error('操作失败，参数错误');
                        if( !this.viewer) return this.$message.error('操作失败，viewer未初始化');

                        const selection = parseParamDbids(params.dbids);
                        this.viewer.hide(selection);
                    },
                },{
                    label : "[基础]显示隐藏",
                    desc : `
                        无参数
                    `,
                    value : "viewer_showall",
                    params : [],
                    func : ()=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_showall】`);
                        if( !this.viewer) return this.$message.error("操作失败，viewer未初始化");

                        this.viewer.showAll();
                    },
                },{
                    label : "[基础]回调行为",
                    desc : `
                        获取需要的信息
                        参数[id] 回调id，通过此id来判断是否是当前回调
                        参数[event] 回调具体事件名，当前可用的事件:
                               [ViewState.getState] 获取当前视点;
                        参数[params] 回调事件的参数 
                    `,
                    value : "viewer_callback",
                    params : ['id','event','params'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_callback】`,params);
                        if( !params?.id || !params?.event || !this.callbackMap[params.event]) return console.error(`模型回调方法失败！`, params);
                        if( !this.viewer) return this.$message.error("操作失败，viewer未初始化");

                        this.callbackMap[params.event]( params.id , params.params);
                    },
                },{
                    label : "[特殊]通用数据处理",
                    desc : `
                        参数[selection] 传入数据(目前参数有：model_id , dbids....可约定后续补充)
                        参数[data] 传入数据 (该参数为甘特图配置取值类型当前选中（列表）的数据结构使用)
                        参数[command] 处理数据的方法具体有{ [select : 选中构件], [ hide : 隐藏构件 ] , [color : 着色] , [ show : 显示构件 ] , [fitToView : 定位到中心]  }
                        可以进行多个组合。组合用","分割 例如[select,color] ->选中并着色; [fitToView,select] ->定位到构件并选中
                    `,
                    value : "viewer_command",
                    params : ['selection','data','command'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_command】`,params);
                        if( !this.viewer) return this.$message.error("操作失败，viewer未初始化");
                        if( !params.command ) return this.$message.error("[通用数据处理参数出错]");
                        if(!params.data && !params.selection) return this.$message.error("[通用数据处理参数出错]");
                        const commands = params.command.split(",");
                        let data = params.data;

                        if( !data && !params.selection ) return this.$message.error(`模型通用接口数据错误`,data);

                        if(typeof data === "string"){
                            data = JSON.parse(data);
                        }

                        const modelMap = {}
                        const colorMap = {}

                        if(commands.indexOf("color")!== -1){
                            this.viewer.action('clearAllcolor');
                        }

                        if( !params.selection ) {
                            if( !Array.isArray(data) || !Array.isArray(data[0].selection) ) return console.warn(`模型通用接口数据错误`,data);
                        }
                         if( params.selection  ){
                            if(!data) data = []
                            if( typeof params.selection === 'string'){
                                data.push({ selection : JSON.parse(params.selection)});
                            }else{
                                data.push({ selection : params.selection});
                            }
                        }

                        for(let obj of data ){
                            if( obj.selection ){
                                for(let {model_id , dbids} of obj.selection){
                                    if(!modelMap[model_id]){
                                        modelMap[model_id] = {
                                            dbids : []
                                        }
                                    }

                                    modelMap[model_id].dbids.push(...dbids);

                                    if( commands.indexOf('color') !== -1 &&  obj.taskColor){
                                        modelMap[model_id].color = obj.taskColor;

                                        if( !colorMap[obj.taskColor] ){
                                            colorMap[obj.taskColor] = []
                                        }

                                        colorMap[obj.taskColor].push({
                                            model_id,
                                            dbids,
                                        })
                                    }
                                }
                            }
                        }

                        const selection = [];

                        for(let model_id in modelMap ){
                            const { dbids } = modelMap[model_id];
                            selection.push({
                                model_id, dbids: Array.from(new Set(dbids))
                            });
                        }

                        if( commands.indexOf('color') !== -1 ){


                            for(let color in colorMap){
                                const list = colorMap[color];

                                for(let {dbids , model_id} of list){
                                    this.viewer.action('setColor' , dbids , color , model_id);
                                }
                            }
                        }

                        for(let command of commands){
                            switch(command){
                                case "select" :
                                    this.viewer.select(selection);
                                    break;
                                case "hide":
                                    this.viewer.hide(selection);
                                    break;
                                case "show":
                                    this.viewer.showByList(selection);
                                    break;
                                case "fitToView":
                                    this.viewer.fitToView(selection);
                                    break;
                            }
                        }
                    },
                },{
                    label : "[绘制]绘制半圆型",
                    desc : `
                    【id】 表示绘制形状的id
                    【position】 绘制中心店
                    【radius】 绘制半径
                    【angle】 旋转角度
                    【thetaStart】 起始角度
                    `,
                    value : "viewer_angleCircle",
                    params : ['id','position','radius','angle','thetaStart'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_angleCircle】`,params);
                        if( !params.position | !params.radius | !params.angle | !params.id) return this.$message.error('模型绘制参数错误');
                        let { position,  radius, angle, id, thetaStart } = params;

                        if(!thetaStart){
                            thetaStart = 0;
                        }

                        if( typeof position === 'string'){
                            position = JSON.parse(position);
                        }

                        this.viewer.createAngleCircle(id, position, radius, angle,{
                            thetaStart, 
                        });
                    }
                },{
                    label : "[基础]模型对比",
                    desc : `
                        参数[list1] 对比模型组1 传入： modelId0,modelId1....
                        参数[list2] 对比模型组2 传入： modelId0,modelId1....
                        注:两边数量必须相等且都必须要加载后的模型
                    `,
                    value : "viewer_diffmodel",
                    params : ['list1', 'list2'],
                    func : (params)=>{
                        if( this.isCloseMode ) return console.log(`模型现在处于不加载模式,事件已经触发【viewer_diffmodel】`,params);
                        if(!params.list1 || !params.list2) return this.$message.error('模型对比参数错误');

                        const list1 = params.list1.split(',');
                        const list2 = params.list2.split(',');
                        
                        this.viewer.diffModel(list1,list2);
                    },
                }]);
            },
            /**
             * 注册回调
             * @param {*} id
             * @param {*} func
             */
            registerCallback( id , func ){
                this.callbackMap[id] = func;
            },
            /**
             * 初始化toolbar
             */
            initToolbar(){
                this.toolbarMap = {
                    "bimWalk" : {
                        id : "bimWalk",
                        icon : 'iconfont iconbim_diyirencheng',
                        name : "第一人称",
                        needActive : true,
                        func : ()=>{
                            this.viewer.action('switchBIMWalk');
                        }
                    },
                    "shadow" : {
                        id : "shadow",
                        icon : 'iconfont iconbim_yinying',
                        name : "显示阴影",
                        needActive : true,
                        func : ()=>{
                            this.viewer.action("switchGhosting");
                        }
                    },
                    "jiaoxuan" : {
                        id : "jiaoxuan",
                        icon : 'iconfont iconbimgis_jiaoxuan',
                        name : "交选",
                        func : ()=>{
                            this.viewer.useSelectWin(true)
                        }
                    },
                    "kuangxuan" : {
                        id : "kuangxuan",
                        icon : 'iconfont iconbimgis_kuangxuan',
                        name : "框选",
                        func : ()=>{
                            this.viewer.useSelectWin()
                        }
                    },
                    "focus" : {
                        id : "focus",
                        icon : 'iconfont iconbim_huidaojiaodian',
                        name : "焦点",
                        func :()=>{
                            this.viewer.action("setFocus");
                        }
                    },
                    "measure" : {
                        id : "measure",
                        icon : "iconfont iconbimgis_suanliang",
                        name : "测量工具",
                        needActive : true,
                        func :()=>{

                            this.viewer.getBIMViewer().getExtension('Autodesk.Measure').setUnits('mm');
                            this.viewer.action("switchMeasure");
                        }
                    },
                    // "modellist" : {
                    //     id : "modellist",
                    //     name : "模型列表",
                    //     icon : "iconfont icongis_moxingshu",
                    //     func : ()=>{
                    //         this.$refs.modellist.switch();
                    //     }
                    // }
                }

                if(this.element.modelBIMCustomToolbar){
                    for(let id in this.element.modelBIMCustomToolbar){
                        const {  label , icon , needActive } = this.element.modelBIMCustomToolbar[id];
                        this.toolbarMap[id] = {
                            id ,
                            icon ,
                            name : label,
                            needActive ,
                            func : ( active )=>{
                                let aid = active ? id : `${id}_active`;
                                this.onAction(aid);
                            },
                        }
                    }
                }
            },
            // refreshModelListField(){
            //     const database = this.element.database;

            //     if(!database || !database.objectData || !database.viewData ) {
            //         if(this.element.modelBIMField && this.element.modelBIMField['ModelList']){
            //             delete this.element.modelBIMField['ModelList'];
            //         }

            //         const loadedMap = this.viewer.getLoadedModelName();
            //         const list = [];
            //         for(let id in loadedMap){
            //             list.push({
            //                 modelId : id,
            //                 label : loadedMap[id],
            //             });
            //         }

            //         this.$refs.modellist.refresh(list , Object.keys(loadedMap));
            //         return;
            //     }

            //     dataInterface({
            //         __method_name__ : "dataList",
            //         object_uuid: database.objectData.uuid,
            //         view_uuid: database.viewData.uuid,
            //     }).then(( res )=>{
            //         if (res && res.status === 200) {
            //             const data = res.data;
            //             if(!this.element.modelBIMField) this.element.modelBIMField = {}
            //             if(!this.element.modelBIMField['ModelList']) this.element.modelBIMField['ModelList'] = {
            //                 metadata : {},
            //                 data : {},
            //                 modelId : "",
            //                 modelName : "",
            //             }

            //             this.element.modelBIMField["ModelList"].metadata = data.metadata;
            //             this.element.modelBIMField["ModelList"].data = data.data.data;

            //             eventBus.$emit('ViewerBIM.refreshModelListField' , this.element.id);

            //             this.refreshModelList();
            //         }
            //     });

            // },
            // refreshModelList(){
            //     if(this.element.modelBIMField && this.element.modelBIMField["ModelList"]){
            //         const {metadata , data , modelId , modelName} = this.element.modelBIMField["ModelList"];

            //         if(Object.keys(metadata).length > 0 && Object.keys(data).length > 0 && modelId && modelName ){
            //             const list = [];

            //             const loadedMap = this.viewer.getLoadedModelName();

            //             const addedMap = {}
            //             for(let meta of data){
            //                 if( meta[modelId] && meta[modelName] ){
            //                     const mid = meta[modelId];

            //                     if(addedMap[mid] ) continue;
            //                     if(loadedMap[mid]) {
            //                         loadedMap[mid] = meta[modelName];
            //                         continue;
            //                     }

            //                     addedMap[mid] = true;
            //                     list.push({
            //                         modelId : meta[modelId],
            //                         label : meta[modelName],
            //                     });
            //                 }
            //             }


            //             for(let mid in loadedMap){
            //                 list.unshift({
            //                     modelId : mid,
            //                     label : loadedMap[mid],
            //                 })
            //             }

            //             this.$refs.modellist.refresh(list , Object.keys(loadedMap));
            //         }
            //     }
            // },
            loadModel(mid , cb){
                ModelLoadedCallBack[ mid ] = cb;
                this.viewer.loadModel(mid);
            },
            unloadModel(mid){
                this.viewer.unloadModel(mid);
            },
            fitToModel( mid ){
                this.viewer.action("fitToView" , [{
                    model_id : mid,
                    dbids : [1]
                }]);
            },
            //注册组件内事件
            registerActionConfig( id , label ){
                const element = this.element;

                if(!element.actionConfig){
                    element.actionConfig = {}
                }

                if(!element.actionConfig[id]){
                    element.actionConfig[id] = {
                        use: true,
                        useDepend: false,
                        dependLogic: [],
                        type: id,
                        name: label,
                        color: '#409EFF',
                        btnType: 'text',
                        btnSize: '',
                        margin: 5,
                        // eventList: [],
                        // permission: [], // 权限
                        // archiLimit: [], // 架构限制
                    }
                }
            },
            /**
             * 删除组件内事件
             * @param {} id
             */
            removeActionConfig(id){
                const element = this.element;

                if(!element.actionConfig){
                    element.actionConfig = {}
                }

                if(element.actionConfig[id]){
                    delete element.actionConfig[id]
                }
            },
            //触发行为
            onAction( id ){
                const ac = this.element.actionConfig;
                if(!ac[id]) return;

                const eventList = ac[id].eventList || [];

                for(let el of eventList){
                    const { pattern, eventList = [], specialEventList = []  } = el;
                    const resultList = pattern === 'special' ? specialEventList : eventList;

                    for(let ele of resultList){
                        const { effects , behaviors } = ele;
                        effects.forEach((effect) => {
                            this.$store.commit('triggerEvents', {
                                config: {
                                    ...ele,
                                ...effect
                                },
                                element: this.element,
                                EDITOR_pageUUID: this.EDITOR_pageUUID
                            });
                        });

                        behaviors.forEach(behavior => {
                            this.$store.commit('triggerEvents', {
                                config: {
                                    behavior,
                                    isBehavior: true
                                },
                                element: this.element,
                                EDITOR_pageUUID: this.EDITOR_pageUUID
                            });
                        });
                    }
                }

            },
            //注册默认组件内事件
            base_initActionConfig(){
                const map = [{
                    id : "onViewerInited",
                    label : "初始化完成",
                },{
                    id : "onViewerLoadedModel",
                    label : "模型加载完成"
                },{
                    id : "onViewerSelect",
                    label : "选中构件"
                },{
                    id : "onViewerSingleClick",
                    label : "点击viewer"
                },{
                    id : "onViewerCameraChange",
                    label : "相机变化"
                }];

                for( let { id , label} of map){
                    this.registerActionConfig( id , label );
                }
            },
            addCustomToolbar( { label , icon , id , needActive} ){
                this.$refs.toolbar.add(id , {
                    name : label ,
                    needActive : !!needActive,
                    icon,
                    func : ( active )=>{
                        let aid = active ? id : `${id}_active`;
                        this.onAction(aid);
                    },
                });

                const actionConfig = this.element.actionConfig;

                if(!actionConfig[id]){ //如果没有内部事件就添加
                    this.registerActionConfig( id , `工具栏:【${label}】激活事件` );

                    if(needActive){
                        this.registerActionConfig( `${id}_active` , `工具栏:【${label}】取消事件` );
                    }
                }else{
                    if(!needActive && actionConfig[`${id}_active`]){ //有取消事件但是需要改成不取消的,就删除事件
                        delete actionConfig[id];
                    }

                    if(needActive && !actionConfig[id]){ //没有取消事件但是需要改成可以取消的
                        this.registerActionConfig( `${id}_active` , `工具栏:【${label}】取消事件` );
                    }
                }

                if(!this.element.modelBIMCustomToolbar){
                    this.element.modelBIMCustomToolbar = {}
                }

                if(!this.element.modelBIMCustomToolbar[id]){
                    this.element.modelBIMCustomToolbar[id] = {
                        label , icon , id , needActive,
                    }
                }else{
                    this.element.modelBIMCustomToolbar[id].label = label;
                    this.element.modelBIMCustomToolbar[id].icon = icon;
                }
            },
            deleteCustomToolbar( id ) {
                this.$refs.toolbar.delete(id);

                const actionConfig = this.element.actionConfig;

                if(actionConfig[id]){
                    delete actionConfig[id];
                }

                if(this.element.modelBIMCustomToolbar && this.element.modelBIMCustomToolbar[id]){
                    delete this.element.modelBIMCustomToolbar[id];
                }
            },
            //设置组件暴露数据
            setResolveData( id , data){
                this.element.resolveData[id] = data;
            },
            //添加到组件暴露数据的列表
            addResolveList( uuid , name){
                this.element.modelBIMResolveList.push({
                    uuid,
                    name,
                });
            },
        }

        for(let compId in this.components){
            const comp = this.components[compId];

            data.componentIds.push(compId);
            data = Object.assign(data , comp.data);

            const {mounted , initViewerEventListener} = comp.func;
            methods = Object.assign(methods , comp.methods , {
                [`_${compId}_mounted`] : mounted,
                [`_${compId}_initViewerEventListener`] : initViewerEventListener,
            });

            watch = Object.assign(watch , comp.watch);
        }

        const state = {
            name : this.name,
            data (){
                return Utils.deepCopy(data);
            },
            props :{
                element: {
                    type: Object,
                    required: true,
                    default: () => {
                        return {
                        }
                    }
                },
                // 是否在组合内
                isGroup: {
                    type: Boolean,
                },
                // 组合内组件列表
                groupComponents: {
                    type: Array,
                    default: () => []
                }
            },
            components : {
                "bim-toolbar" : ViewerToolbar,
                // "bim-modellist" : ViewerModelList,
            },
            watch : Object.assign({},watch),
            mixins : [CustomComponentMixin , BehaviorHandlerMixin],
            computed: {
                ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
            },
            methods : Object.assign({},methods),
            mounted() {
                this.element.behaviors = [];
                this.element.resolveData = {};
                if( typeof this.element.isCloseMode === 'undefined'){
                    this.element.isCloseMode = false;
                }
         
                this.$store.commit('updatePageCustomStatus', {
                    origin: this.element,
                    resolveData: {}
                });
                this.element.modelBIMResolveList = [];

                //viewer数据id类型
                if( ! this.element.modelBIMDataMainIdType){
                    this.element.modelBIMDataMainIdType = 'model';//viewer数据id类型['model':来自模型id , 'custom':自定义]
                }

                //viewer数据自定义id
                if( ! this.element.modelBIMDataMainId ){
                    this.element.modelBIMDataMainId = `BIMDataId_${getGuid()}`;
                }

                this.init();
                this.base_initBehaviors();
                this.base_initActionConfig();
                this.initToolbar();

                for(let compId of this.componentIds){
                    this[`_${compId}_mounted`]()
                }

                this.addResolveList('SelectionProps',"选中模型构件信息")
            },
            created(){
                const element = this.element;
                const { param } = this.initParams(element.paramsConfig, element.isGroup, element.componentData, element.groupComponents);

                // 动态源且预设模型id
                if (element.modelBIMSourceConfig && element.modelBIMSourceConfig.isDynamicSource ){
                    if(param && param.modelId){
                        
                        if( Array.isArray(param.modelId) ){
                            if( !this.preloadModelMap ) this.preloadModelMap = {}
                            const mid = param.modelId.splice( 0 , 1 )[0];

                            for(let id of param.modelId){
                                this.preloadModelMap[id] = false;
                            }

                            this.preload = {
                                modelId : mid,
                                appId : param.appId ? param.appId : process.env.VUE_APP_MODEL_APPID,
                                appSecret : param.appSecret ? param.appSecret : process.env.VUE_APP_MODEL_APPSECRET,
                            }
                        }else{
                            if( typeof param.modelId !== 'string') console.warn('预加载模型失败传入模型id错误' , param.modelId);

                            this.preload = {
                                modelId : param.modelId,
                                appId : param.appId ? param.appId : process.env.VUE_APP_MODEL_APPID,
                                appSecret : param.appSecret ? param.appSecret : process.env.VUE_APP_MODEL_APPSECRET,
                            }
                        }
                    }

                    if(param && param.selection){
                        if(!this.preload) this.preload = {}

                        if( typeof param.selection === 'string'){
                            this.preload['selection'] = JSON.parse(param.selection);
                        }else {
                            this.preload['selection'] = param.selection.concat();
                        }
                    }
                }

            },
            destroyed(){
                for( let id in this._EventBus){
                    eventBus.$off(id , this._EventBus[id]);
                }
            },
        }

        return  state;
    }
}
