<!--
    @name: ques-judge
    @description：ques-judge
    @author: ZengWei
    @date: 2022-04-01 11:18
-->
<template>
  <div class="widget-judge">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[判断题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div class="q-resource" v-if="element.config.__ques__.resource">
        <ChooseModel
          ref="chooseModel"
          :modelData="element.config.__ques__.resource !== null ? element.config.__ques__.resource: []">
          <template v-slot:showType>
            <el-button
              @click="openModelDrawer"
              size="small"
              type="primary"
            >查看资源</el-button>
          </template>
        </ChooseModel>
      </div>
      <div
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc"
        class="q-title">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <el-radio-group v-model="element.value" @change="calcScore">
          <el-radio
            :class="columnClass"
            v-for="(option,index) in element.config.__slot__.options"
            :key="index"
            :label="option.value"
          >
            {{optNumber[index]}} 、{{ option.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import {Radio, RadioGroup} from "element-ui";
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";

export default {
  name: "ques-judge",
  components: {
    'el-radio-group':RadioGroup,
    'el-radio':Radio,
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  data() {
    return {}
  },
  methods: {
    // 计算分值
    calcScore(value){
      const config = this.element.config.__ques__
      const options = this.element.config.__slot__.options
      let score = 0
      if(config.scoreType === 1){
        for (const opt of options){
          if(opt.right && value == opt.value){
            score = config.score
          }
        }
      } else if (config.scoreType === 2){
        for (const opt of options){
          if(value == opt.value){
            score = opt.score
          }
        }
      }
      this.element.score = score
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
