/*
 * @Description: 图表
 * @Author: luocheng
 * @Date: 2021-10-15 14:52:56
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-09-20 14:32:42
 */
// 图表
const business = {
	type: 'business',
	title: '业务组件',
	show: true,
  key: 4,
	order: 11,
  componentList: [
		{
			component: 'CommonStatTableOne',
			type: 'container',
			name: '统计列表',
			icon: 'icongonneng-zlb',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				planKey: '',
				actualKey: '',
				fixedColumn: false,
				showSelect: false
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0,
				overflowY: 'hidden'
			},
			actionConfig: {}
		},
		{
			component: 'CommonStatTableTwo',
			type: 'container',
			name: '统计列表-1',
			icon: 'iconbiaodan-bglb',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				fixedColumn: false
			},
			style: {
				height: '100%',
				width: '100%',
				rotate: 0,
				overflowY: 'hidden'
			},
			actionConfig: {}
		},
		{
			component: 'MiniCard',
			type: 'MiniCard',
			name: '迷你图标卡片',
			icon: 'iconbiaodan-bdsx',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				title: '测试路由',
				description: '测试描述',
				iconUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.uimaker.com%2Fuploads%2Fallimg%2F140114%2F1_140114095705_3.jpg&refer=http%3A%2F%2Fwww.uimaker.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659007588&t=f6a364010fc31312bdd48f2a918172e3',
				route: '',
				pageUUID: '',
				menuType: 0
			},
			style: {
				height: 78,
				width: 256,
				rotate: 0,
			}
		},
		{
			component: 'LargeCard',
			type: 'LargeCard',
			name: '图标卡片',
			icon: 'iconzhengfangxing',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			span: -1,
			statusConfig: {
				displayShow: true,
				isShow: true,
				title: '工单系统',
				description: '项目模型管理查看编辑修改',
				miniIconUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.uimaker.com%2Fuploads%2Fallimg%2F140114%2F1_140114095705_3.jpg&refer=http%3A%2F%2Fwww.uimaker.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1659007588&t=f6a364010fc31312bdd48f2a918172e3',
				largeIconUrl: 'https://img2.baidu.com/it/u=2113220341,3117661873&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
				type: 'default',
				route: '',
				pageUUID: '',
				menuType: 0
			},
			style: {
				height: 372,
				width: 200,
				rotate: 0,
				// width:532,
				// height:176
			}
		},
		{
			component: 'CommonSubitemTemplate',
			type: 'subitemPage',
			name: '分部分项(标准库)页面',
			icon: 'iconyewu-fbfx',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			style: {
				width: '100%',
				height: '100%'
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				title: '标准用表',
				type: 'table', // 类型
				needTreeTerminal: false,
			},
			database: {
				objectUUID: 'object62830dfd61652',
				viewUUID: 'view62845e89b1dfa'
			},
			paramsConfig: [
				{
					paramType: 'param', // 请求类型
					code: 'table_type', //  字段code
					fieldUUID: '', // 字段UUID
					isRequired: true, // 是否必填字段
					originType: 'fixed', // 参数来源. 组件 component， URL参数 url
					componentId: '', // 来源组件 Id
					componentKey: '', // 组件字段key
					urlKey: '', // url参数的key
					ruleType: '', // 逻辑类型
					fixedValue: '监理表格' // 固定值
				}
			]
		},
		{
			component: 'CommonPlatformDynamics',
			type: 'business',
			name: '平台动态',
			icon: 'iconyewu-ptdt',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'row', // flex 主轴方向
				flexWrap: false, // 是否换行
				fixedSize: false, // 大屏固定尺寸显示
				isAbsolute: false,
				absoluteTop: 0,
				absoluteBottom: 0,
				absoluteLeft: 0,
				absoluteRight: 0,
				zIndex: 10
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			database: {
				objectUUID: 'object62830dfd61652',
				viewUUID: 'view62845e89b1dfa'
			},
			paramsConfig: [
				{
					paramType: 'param', // 请求类型
					code: 'table_type', //  字段code
					fieldUUID: '', // 字段UUID
					isRequired: true, // 是否必填字段
					originType: 'fixed', // 参数来源. 组件 component， URL参数 url
					componentId: '', // 来源组件 Id
					componentKey: '', // 组件字段key
					urlKey: '', // url参数的key
					ruleType: '', // 逻辑类型
					fixedValue: '监理表格' // 固定值
				}
			]
		},
		{
			component: 'BindComponent',
			type: 'container',
			name: '绑定组件',
			icon: 'iconyewu-bdzj',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				zIndex: 100
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			bindConfig: {
				type: 'bim',
				isBtn: true,
				appSecret:process.env.VUE_APP_MODEL_APPSECRET,
				appId:process.env.VUE_APP_MODEL_APPID
			},
			actionConfig: {
				needAction: true,
				setData: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'setData',
					name: '确定',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				back: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'back',
					name: '返回',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
			}
		},
		{
			component: 'CommonGantt',
			type: 'container',
			name: '甘特图',
			icon: 'iconyewu-gtt',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				zIndex: 100
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			ganttConfig : {
				textField:'',//名称显示字段
				frontLineField:'',//前锋线fielduuid字段判断
				startDateField:'',//计划开始时间fielduuid
				endDateField:'',//计划结束时间fielduuid
				checkbox:false,//显示多选框
        bimField:'',//绑定的bim模型fielduuid
        gisField:'',//绑定的gis模型fielduuid
        taskColField:'',//绑定的任务颜色fielduuid
			},
			actionConfig: {
				needAction: true,
				clickTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'clickTask',
					name: '点击任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				checkTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'checkTask',
					name: '选中任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				addTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'addTask',
					name: '新增任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				detailTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'detailTask',
					name: '任务详情',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				editTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'editTask',
					name: '编辑任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
				deleteTask: {
					use: true,
					useDepend: false,
					dependLogic: [],
					type: 'deleteTask',
					name: '删除任务',
					color: '#409EFF',
					btnType: 'text',
					btnSize: '',
					margin: 5,
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				},
			},
			behaviors: [
				{
					label: '仅显示表格数据',
					value: 'onOnlyTable',
					desc: '仅显示表格数据'
				},
				{
					label: '仅显示甘特图',
					value: 'onOnlyGantt',
					desc: '只显示甘特图部分'
				},
				{
					label: '重置/刷新',
					value: 'onReset',
					desc: '刷新或重置甘特图数据'
				},
				{
					label: '隐藏任务',
					value: 'onHideTask',
					desc: ''
				},
				{
					label: '适应屏幕',
					value: 'onZoomFit',
					desc: '甘特图进度适应调整'
				},
				{
					label: '开启前锋线',
					value: 'onShowFrontLine',
					desc: '无参数配置'
				},
				{
					label: '关闭前锋线',
					value: 'onHideFrontLine',
					desc: '无参数配置'
				},
				{
					label: '搜索名称',
					value: 'onFilterName',
					desc: '参数配置事件名name'
				},
				{
					label: '筛选任务时间段',
					value: 'onFilterDate',
					desc: '参数配置事件名start_date、end_date'
				},
				{
					label: '选中全部',
					value: 'onCheckAll',
					desc: '无参数配置'
				},
			]
		},
		{
			name: '里程碑',
			component: 'MilestoneCom',
			type: 'functionCom',
			icon: 'iconyewu-lcb',
			needCommonStyle: true,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showTitle: true, // B标题
			}
		},
    {
      component: 'CommonProgressCurve',
      type: 'commonProgressCurve',
      name: '进度曲线图表',
      icon: 'iconyewu-jdqx',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: 400,
        width: 500,
        rotate: 0,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
    {
      component: 'CommonOverallProgress',
      type: 'commonProgressCurve',
      name: '总进度曲线',
      icon: 'iconyewu-zjdqx',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: 400,
        width: 500,
        rotate: 0,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5
      },
      paramsConfig: [], // 筛选参数
      actionConfig: []
    },
		{
			component: 'CommonArchiTree',
			type: 'business',
			name: '组织架构树',
			icon: 'iconyewu-zzjg',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
			style: {
				width: '100%',
				height: '100%',
			},
			specialStyle: {
				isCollector: false, // 是否设为收集者 flex 1
				flexDirection: 'column', // flex 主轴方向
				flexWrap: false, // 是否换行
				fixedSize: false, // 大屏固定尺寸显示
				isAbsolute: false,
				absoluteTop: 0,
				absoluteBottom: 0,
				absoluteLeft: 0,
				absoluteRight: 0,
				zIndex: 10
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			database: {
				objectUUID: 'object62830dfd61652',
				viewUUID: 'view62845e89b1dfa'
			},
			paramsConfig: [],
			actionConfig: [
				{
					key: 'rowClick',
					type: 'rowClick',
					name: '点击行',
					eventList: [],
					permission: [], // 权限
					archiLimit: [], // 架构限制
				}
			]
		},
		{
			component: 'SyncPlanModel',
			type: 'button',
			name: '同步模型',
			icon: 'iconyewu-tbmx',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '同步模型',
			valueOrign:'database',
			style: {
				height: '',
				width: '',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				fontSize: 12
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				type: 'primary', // primary / success / warning / danger / info / text
				size: '', // medium / small / mini
				plain: false,
				round: false,
				circle: false,
				loading: false,
				disabled: false,
				icon: '',
				autofocus: false,
				outId: ''
			},
			syncConfig: {
				textName:'同步模型',//按钮名称
				modelField:'',//模型字段
				codeField:'',//code字段
			},
			behaviors: [
				{
					label: '同步进度计划模型',
					value: 'onSyncPlanModel',
					desc: '参数配置名为schedule_id,即进度计划id'
				},
			]
		},
    {
      component: 'CommonStatisticsAnalyze', // 生产督察安全问题排列分析
      type: 'commonStatisticsAnalyze',
      name: '排列统计分析',
      icon: 'icontongyongtubiao1',
      isLock: false,
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
        displayShow: true,
        isShow: true,
        valueStatus: 2,
        projectLevelFilterHidden: false
      },
      style: {
        minHeight: 400,
        height: 400,
        width: 800,
        minWidth: 600,
        rotate: 0
      },
      paramsConfig: [],
      actionConfig: []
    },
		{
			name: '问题状态组件',
			component: 'StatusCom',
			type: 'container',
			icon: 'iconyeqian',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showNum: true,
				title: '-',
				showTitle: true,
				fontSize: '16px',
				numColor: ''
			},
			style: {
				height: 150,
				width: 100
			},
		},
		{
			name: '全景对比',
			component: 'PanoCom',
			type: 'functionCom',
			icon: 'iconyewu-qjdb',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			}
		},
		{
			name: '全景组件',
			component: 'Pano',
			type: 'container',
			icon: 'iconyewu-qjzj',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			}
		},
		{
			name: '监控组件',
			component: 'CommonVideo',
			type: 'functionCom',
			icon: 'iconyewu-jkzj',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showControl: false,
				autoplay: false
			},
			mapConfig: [
				{
					label: '平台信息',
					objectUUID: '',
					bindField: '',
					key: 'app_info'
				},
				{
					label: '设备名称',
					objectUUID: '',
					bindField: '',
					key: 'monitor_name'
				},
				{
					label: '分类名称',
					objectUUID: '',
					bindField: '',
					key: 'type_name'
				},
				{
					label: '设备号',
					objectUUID: '',
					bindField: '',
					key: 'device_serial'
				},
				{
					label: '设备ID',
					objectUUID: '',
					bindField: '',
					key: 'monitor_id'
				},
				{
					label: '封面',
					objectUUID: '',
					bindField: '',
					key: 'monitor_img'
				},
				{
					label: '监控路径',
					objectUUID: '',
					bindField: '',
					key: 'monitor_path'
				},
				{
					label: '通道id',
					objectUUID: '',
					bindField: '',
					key: 'channel_id'
				},
				{
					label: '云台状态',
					objectUUID: '',
					bindField: '',
					key: 'yun_tai_status'
				},
				{
					label: '在线状态',
					objectUUID: '',
					bindField: '',
					key: 'onLine'
				},
				{
					label: '平台token',
					objectUUID: '',
					bindField: '',
					from: 'app_info',
					key: 'accessToken'
				},
				{
					label: '标识',
					objectUUID: '',
					bindField: '',
					from: 'app_info',
					key: 'app_name'
				},
				{
					label: '应用id',
					objectUUID: '',
					bindField: '',
					from: 'app_info',
					key: 'appId'
				}
			]
		},
		{
			name: '人员打卡',
			component: 'PunchIn',
			type: 'functionCom',
			icon: 'iconyewu-rydk',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				showDateScreen: false,
				iconColor: '#ff0000',
				titleColor: '#ffffff'
			},
			style: {
				height: 300,
				width: 400
			},
		},
		{
			name: '考勤统计',
			component: 'PunchinStatistics',
			type: 'container',
			icon: 'iconyewu-kqtj',
			statusConfig: {
				isShow: true, // 是否显示
				displayShow: true,
				isDark: true,
				label: '在场监理'
			},
			style: {
			}
		},
    {
			name: '方案图',
			component: 'ConceptualDrawing',
			type: 'functionCom',
			icon: 'iconh5-lb',
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				background: '#777',
				backgroundImage: ''
			},
			style: {
				height: 400,
				width: 400
			},
			actionConfig: {
			},
			behaviors: [
				{
					label: '改变绑定值',
					value: 'changeStore',
					desc: '修改指定key的store值'
				},
				{
					label: '改变节点颜色',
					value: 'changeNodeStyle',
					desc: '修改指定事件类型节点背景颜色,可用颜色red、brown、green、blue、pale_blue、cyan、purple、yellow、black'
				}
			],
			// 没有请求，固定meateData
			metadata: {
				'event_name': 'event_name'
			}
		},
    {
      name: '任务拓扑',
      component: 'TaskDesigner',
      type: 'taskEngine',
      icon: 'iconyewu-kqtj',
      statusConfig: {
        isShow: false, // 是否显示
        displayShow: false,
      },
      style: {
        height: '100%',
        width: '100%'
      },
      taskConfig: {
        autoPublish: false,
        taskMode: 'designer',
      }
    },
  ]
};

export default business;
