<template>
    <div :class="['moduleWrap moduleWrap_back', `${subItemSpread}-flex`]">
        <div v-for="item in btnList" :key="item.id" :class="['btn-item', item.children.length > 0 ? 'btn-item-popup' : '']">
            <el-tooltip :placement="subItemSpread === 'vertical' ? 'left' : 'bottom'" :open-delay="5000" :content="item.name" >
                <a :class="['btn-item-icon', item.icon, {'btn-item-active': item.active}]" @click="onClick(item)"></a>
            </el-tooltip>

            <ul v-if="item.children.length > 0" class="popupItem">
                <template v-for="obj in item.children">
                    <li :key="obj.id" :class="{'popupItem-active': obj.active}" @click="onClickItem(item, obj)">{{obj.name}}</li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import { Tooltip } from 'element-ui';

    export default {
        name: "ViewerGISHelper",
        components: {
            'el-tooltip': Tooltip,
        },
        data() {
            return {
                bindViewerId: null,
                subItemSpread: 'vertical',

                btnList: [
                    {id: '3d', icon: 'iconfont icona-3D', name: '3D', needActive: true, active: false, show: false, children: []},
                    {id: '2.5d', icon: 'iconfont icona-Frame15997', name: '2.5D', needActive: true, active: false, show: false, children: [
                            {id: 'squarelyPitch', name: '俯视', needActive: true, active: false},
                            {id: 'fixPitch', name: '定向', needActive: true, active: false}
                        ]},
                    {id: 'defaultView', icon: 'iconfont iconzhuyeshouyehome', name: '主页', needActive: false, show: true, children: []},
                    {id: 'myLocation', icon: 'iconfont icongis_zidongdingwei', name: '我的位置', needActive: false, show: true, children: []}
                ]
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            this.initCheckBind();

            this.initViewerInitedListener(this.bindViewerId, (opt = {}) => {
                let {viewerInited = false, dynamicLoaded = false} = opt;
                if(viewerInited && dynamicLoaded){
                    this.getHelperData();
                }
            });
        },
        methods: {
            /**
             * @description: 注册组件事件
             */
            initEventListener() {
                eventBus.$on('ViewerGISHelper.GetSource', (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== 'ViewerGIS') {
                                if (item.children && item.children.length > 0)
                                    loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex((x) => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                        spreadDirection: this.subItemSpread,
                        btnConfig: this.getBtnStatus()
                    });
                });

                eventBus.$on('ViewerGISHelper.setSource', (eid, {bindViewerId, spreadDirection, gisBtnConfig}) => {
                    if (eid !== this.element.id) return;
                    if (this.checkBind(bindViewerId)){
                        this.element.viewerBindId = bindViewerId;
                        this.bindViewerId = bindViewerId;
                        this.$message.success(`[${this.element.name}] 绑定成功`);
                    }

                    this.element.subItemSpread = spreadDirection;
                    this.subItemSpread = spreadDirection;

                    this.element.gisBtnConfig = gisBtnConfig;
                    this.setBtnStatus(gisBtnConfig);
                });
            },
            /**
             * @description: 初始化组件配置参数
             */
            initCheckBind(){
                if (this.element.viewerBindId) {
                    if (this.checkBind(this.element.viewerBindId)) {
                        this.bindViewerId = this.element.viewerBindId;
                    }
                }

                if (this.element.subItemSpread) {
                    this.subItemSpread = this.element.subItemSpread;
                }

                if (this.element.gisBtnConfig) {
                    this.setBtnStatus(this.element.gisBtnConfig);
                }
            },
            /**
             * @description: 注册Viewer组件初始化监听事件
             * @param {String} bindId 关联组件Id
             * @param {Function} cb 触发方法
             */
            initViewerInitedListener(bindId = this.bindViewerId, cb = null){
                function listener() {
                    eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                        if (eid !== bindId) return;

                        cb && cb(opt);
                    });
                }

                if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && bindId){
                    eventBus.$emit("ViewerGIS.getViewerInitedFlag", bindId, (opt = {}) => {
                        let {viewerInited = false, dynamicLoaded = false} = opt;
                        if(viewerInited && dynamicLoaded){
                            cb && cb(opt);
                        }else{
                            listener();
                        }
                    });
                }else{
                    listener();
                }
            },
            /**
             * @description: 检验绑定的组件Id是否有效
             * @param {String} bindId 组件标识值
             */
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp)
                    return this.$message.error(
                        `[${this.element.name}] 绑定失效，组件可能已经被删除`
                    );

                return hasComp;
            },

            /**
             * @description: 初始化Toolbar参数状态
             */
            getHelperData() {
                eventBus.$emit(`ViewerGIS.getFixPitch`, this.bindViewerId, (active) => {
                    if(active){
                        this.btnList[1].active = true;
                        this.btnList[1].children[1].active = true;
                    }else{
                        this.btnList[0].active = true;
                    }
                });
            },

            /**
             * @description: 获取功能配置状态
             */
            getBtnStatus(){
                let res = [];
                for (let item of this.btnList){
                    if(!item.show) continue;
                    res.push(item.id);
                }

                return res;
            },
            /**
             * @description: 获取功能名称，首字母大写
             */
            getFuncName(str){
                return str.charAt(0).toUpperCase() + str.slice(1);
            },

            /**
             * @description: 设置功能配置状态
             */
            setBtnStatus(config){
                for (let item of this.btnList){
                    if(config.findIndex(x => x === item.id) === -1) {
                        item.show = false;
                    }else{
                        item.show = true;
                    }
                }
            },
            /**
             * @description: 设置单选功能，其他项关闭
             */
            setBtnUnRadio(keys){
                for (let item of this.btnList){
                    if(keys.findIndex(x => x === item.id) === -1) continue;

                    item.active = false;
                    item.children.map((x) => {
                        if(x.active){
                            x.active = false;
                        }
                    })
                }
            },
            /**
             * @description: 设置单选子功能，其他项关闭
             */
            setBtnItemUnRadio(item, key){
                item.children.map((x) => {
                    if(x.id !== key){
                        x.active = false;
                    }
                })
            },

            /**
             * @description: 功能点击事件
             */
            onClick(item){
                if(item.needActive) {
                    switch (item.id) {
                        case '3d':
                            if(item.active) return;

                            eventBus.$emit(`ViewerGIS.setFixPitch`, this.bindViewerId, false);
                            this.setBtnUnRadio(['2.5d']);
                            item.active = true;
                            break;
                        case '2.5d':
                            if(item.active) return;

                            eventBus.$emit(`ViewerGIS.setFixPitch`, this.bindViewerId, true);
                            this.setBtnUnRadio(['3d']);
                            item.children[1].active = true;
                            item.active = true;
                            break;
                    }
                }else{
                    switch (item.id) {
                        case 'defaultView':
                            eventBus.$emit(`ViewerGIS.fly${this.getFuncName(item.id)}`, this.bindViewerId);
                            break;
                        case 'myLocation':
                            eventBus.$emit(`ViewerGIS.fly${this.getFuncName(item.id)}`, this.bindViewerId);
                            break;
                    }
                }
            },
            onClickItem(item, subItem){
                if(subItem.needActive) {
                    if(subItem.active) return; //不允许取消

                    subItem.active = true;
                    this.setBtnItemUnRadio(item, subItem.id);

                    switch (subItem.id) {
                        case 'squarelyPitch':
                            eventBus.$emit(`ViewerGIS.setSquarelyPitch`, this.bindViewerId, true);
                            this.setBtnUnRadio(['3d']);
                            item.active = true;
                            break;
                        case 'fixPitch':
                            eventBus.$emit(`ViewerGIS.setFixPitch`, this.bindViewerId, true);
                            this.setBtnUnRadio(['3d']);
                            item.active = true;
                            break;
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: inherit;
        backdrop-filter: blur(5px);
    }

    .moduleWrap_back{
        background-color: inherit;
    }

    .horizontal-flex {
        display: inline-flex;
        flex-direction: row;
        line-height: initial;
        vertical-align: middle;
    }

    .vertical-flex {
        display: inline-flex;
        flex-direction: column;
        line-height: initial;
        vertical-align: middle;
    }

    .btn-item{
        margin: 5px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
    }

    .btn-item-icon{
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 20px;
        color: inherit;
        border-radius: 6px;
        text-align: center;
    }

    .btn-item-active{
        color: #0076FF !important;
    }

    .btn-item-popup{
        position: relative;
    }

    .popupItem{
        .moduleWrap_back;

        position: absolute;
        right: 50px;
        top: -35px;
        padding-top: 15px;
        padding-bottom: 15px;
        white-space: nowrap;
        border-radius: 6px;
        visibility: hidden;
        transition: visibility .2s, background-color .2s;
    }

    .btn-item-popup:hover{
        background-color: inherit;

        .popupItem{
            visibility: visible;
            background-color: inherit;
        }
    }

    .popupItem:hover{
        visibility: visible;
    }

    .popupItem li{
        margin-top: 3px;
        margin-bottom: 3px;
        padding-left: 15px;
        padding-right: 15px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: inherit;
        background-color: rgba(0, 118, 255, 0.1);
    }

    .popupItem-active{
        color: #0076FF !important;
        background-color: rgba(0, 118, 255, 0.3);
    }
</style>
