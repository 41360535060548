<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-03-14 10:26:29
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-03-15 15:22:17
 * @FilePath: /dataview-next/src/custom-component/dialog/Email.vue
 * @Description: 邮件分享
-->
<template>
  <div class="email" :style="{
    height: showEmail ? '500px' : 0
  }">
    <article class="email-box">
      <SelectUser
        v-if="showEmail || isInit"
        @on-select-change="onUser"
        :inpage="true"
        :notForm="true"
      ></SelectUser>
    </article>
    <section class="thoroughfare">
      <el-radio v-model="thoroughfare" label="Email">通过邮件系统转发</el-radio>
      <el-radio v-model="thoroughfare" label="IM">通过IM系统转发</el-radio>
    </section>
    <footer class="footer">
      <el-button type="" @click="onCancel">取消</el-button>
      <el-button type="primary" @click="onSend" :disabled="!users.length">发送</el-button>
    </footer>
  </div>
</template>

<script>
import SelectUser from '@/custom-component/form/formParser/selectUser/index.vue';
import { Radio } from 'element-ui';

export default {
  name: 'Email',
  props: {
    showEmail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'el-radio': Radio,
    SelectUser
  },
  data() {
    return {
      users: [],
      thoroughfare: 'Email',
      isInit: false
    }
  },
  created() {
    
  },
  watch: {
    showEmail() {
      if (this.showEmail) {
        this.isInit = true;
      }
    }
  },
  methods: {
    /**
     * @description: 选择的人员
     * @param {*} userData
     * @return {*}
     */
    onUser(userData) {
      console.log(userData, '---userData-----');
      let users = []
      for (let item of userData) {
        let user = {
          id: item.user_id,
          name: item.name,
          user_table: item.user_table,
          section_id: item.section_id,
          user_id: item.user_id,
        };
        users.push(user);
      }
      console.log('--users----', users)
      this.users = users;
    },
    /**
     * @description: 取消
     */
    onCancel() {
      this.$emit('cancel-email');
    },
    /**
     * @description: 发送 
     */
    onSend() {
      console.log(this.users, '----this.users----', this.thoroughfare);
      this.$emit('on-send', {
        receive_users: this.users,
        type: this.thoroughfare
      });
    }
  }
}
</script>

<style lang="less" scoped>
.email {
  width: 100%;
  height: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding: 12px;
  transform: height .1s;
  .email-box {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
  .thoroughfare {
    box-sizing: border-box;
    padding: 12px 0;
  }
  .footer {
    border-top: 1px solid #f2f3f5;
    padding-top: 12px;
    text-align: right;
    display: flex;
    .el-button {
      flex: 1;
    }
  }
}
</style>