<!--
 * @Description: 中控台Bim模型组件
 * @Author: luocheng
 * @Date: 2022-7-26 14:27:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-10-09 11:38:19
-->
<template>
  <div class="common-model">
    <div :id="element.id" ref="bimRef" style="width: 100%; height: 100%" ></div>
		<!-- 监控等图标 -->
		<div v-for="(val,key) in aiotSignData" :key="key">
			<div v-if="val.data.length">
				<div :class="[lockMouse?'pointer-none':'',item.showDetail?'top-level':'']"  class="aiot-sign" v-for="(item,index) in val.data" :key="index"  :style="getPosition(item,val.color,zoom)">
					<Player  v-if="item.showDetail && item.type === 'monitor'" ref="playerRef" @closeMonitor="item.showDetail = false" :playerType="item.param.playerType" :playerItem="item.param.playerItem" />
					<i @click.stop="clickSign(item)" class="iconfont"  :style="{color:val.color}" :class="val.icon"></i>
					<span class="trangle" :style="{borderTopColor:val.color}"></span>
				</div>
			</div>
		</div>
		<div class="mask" v-if="$route.path === '/modify-page' && showToggle"></div>
		<!-- 遮罩 -->
		<a href="javascript:;" class="mask-btn" v-if="$route.path === '/modify-page'" @click="onToggle">{{ showToggle ? '隐藏遮罩' : '显示遮罩' }}</a>
  </div>
</template>

<script>

import { dataInterface } from '@/apis/data';
import Player from '@/custom-component/model/components/player.vue'
import ConsoleBimMixin from '@/custom-component/mixins/ConsoleBimMixin.js';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';

export default {
  name: 'ConsoleBimModel',
	mixins: [ConsoleBimMixin],
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		}
  },
	components: {Player},
  data() {
    return {
      // 定时器对象
      intervalObj: null,
			//这张显示
			showToggle: true,
			//iot标记数据
			aiotSignData:{
				monitor:{data:[],icon:'',color:''},
				towerCrane:{data:[],icon:'',color:''},
			},
    };
  },
  computed: {
    ...mapState(['componentData']),
    // 模型配置
    modelConfig() {
      return this.element && this.element.modelConfig;
    },
		getPosition: ()=>{
			return (data,col,zoom) =>{
				if (data != undefined && data.screenPointInfo != undefined) {
					return {
							left: data.screenPointInfo.x - (19*1) + "px",
							top: data.screenPointInfo.y - 50 + "px",
							transform: "scale(" + zoom + ")",
							position:'absolute',
							borderColor:col
					};
				}
			}

		}
  },
  mounted() {
    this.$nextTick(() => {
			if(+this.modelConfig?.showToolbar === 0){
				this.initModel();
			}else{
				this.getData(true)
			}
      eventBus.$on('doComponentBehavior', config => {
        const { component, list = [] } = config;
        if (component !== this.element.id) return false;
        list.forEach(ele => {
          const { behaviors, params } = ele;
					const { param = {}, canPost } = initParams(params, this.isGroup, this.componentData, this.groupComponents);
          if (canPost) {
            // 调用行为方法
            behaviors.forEach(funName => {
							// 不存在此组件
              try {
                eval(this[funName])(param)
              } catch (err) {
                console.log(err);
              }
            });
          }
        });
      })
    });
  },
  updated() {
    // this.$nextTick(() => {
    //   this.initModel();
    // });
  },
  methods: {
		/**
		 * @desc: 切换遮罩
		 */
		onToggle() {
			this.showToggle = !this.showToggle;
		},
    /**
     * @desc: 显示详情
     */
		clickSign(){
			
		},
		/**
     * @desc: 获取列表
     */
		getData(bol){
			dataInterface({
				object_uuid: 'object6176693334cff',
				view_uuid: 'view6176699c082b5',
				__method_name__: 'dataList',
				transcode: 0,
			}).then(async (res) => {
				if (res.data.code === 200) {
					const newTree = []
					if (res.data.data.length !== 0) {
						await this.formatData(res.data.data, newTree, [])
						this.modelTreeData = newTree
						if(bol){
							this.initModel()
						}
					}else{
						if(bol){
							this.initModel()
						}
					}
				}else{
					if(bol){
						this.initModel()
					}
				}
			})
		},
		/**
     * @desc: 初始化模型
     */
		formatData (treeList, newTreeList) {
			treeList.map(item => {
				const version = item.allversions?.filter((v) => v.id === item.used_version) || []
				const tempData = {
					content: item.name,
					id: version && version.length ? version[0].model_id : item.id,
					type: version && version.length ? 'model' : 'file'
				}
				if (item.model_id) {
					tempData.isLeaf = true
				}
				if (item.children) {
					let children = []
					// if (targetArchi.modelApproval) {
					// 	children = Array.from(new Set(item.children.concat(item.models_new || [])))
					// } else {
						children = Array.from(new Set(item.children.concat(item.models || [])))
					// }
					if (children?.length > 0) {
						tempData.children = []
						this.formatData(children, tempData.children)
					}
				}
				if (tempData.id !== '') {
					newTreeList.push(tempData)
				}
			})
		},
		/**
     * @desc: 字符串转json
     * @return {}
     */
		strToJson (str) {
			let json = (new Function("return " + str))()
			return json
		},
    /**
     * @desc: 验证配置
     * @return {Boolean}
     */
    validConfig() {
      return (
        this.modelConfig &&
        this.modelConfig.appId &&
        this.modelConfig.appSecret
      );
    },
  },
  beforeDestroy() {
    if (this.intervalObj) {
      clearInterval(this.intervalObj);
    }
    eventBus.$off('doComponentBehavior');
  }
};
</script>

<style lang="less" scoped>
.common-model {
  height: 100%;
  width: 100%;
  overflow: hidden;
	position: relative;
	.mask-btn{
		position: absolute;
		padding: 5px;
		font-size: 14px;
		color: @theme;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	.mask{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: transparent;
		line-height: 100%;
		color: #fff;
	}
	.top-level{
		z-index: 20!important;
	}
	.aiot-sign{
		background: #fff;
		border-width: 4px;
		border-style: solid;
		border-radius: 50%;
		z-index: 10;
		i{
			font-size: 30px;
			z-index: 10;
		}
		.trangle{
			width: 0px;
			height: 0px;
			border-width: 12px;
			border-style: solid;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			position: absolute;
			bottom: -24px;
			left: 3px;
			z-index: 10;
		}
	}
	.player-cont{
			position: absolute;
			width: 250px;
			height: 150px;
			top: -165px;
      left: -110px;
			z-index: 20;
			.close{
				top: 2px;
				right: 5px;
				font-size: 22px;
				color:#fff;
			}
			.full-screen{
				font-size: 15px;
				top: 5px;
				right:30px;
				color:#fff;
			}
	}
	.pointer-none{
		pointer-events: none;
	}
}
</style>