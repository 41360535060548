import formValidate from "@/components/answer/quesParser/scripts/quesParserFormat.js"
import {formatValue} from "@/custom-component/form/newParser/scripts/tools";
import {formRequest} from "@/apis/data/form";
import { dataInterface } from '@/apis/data';
import { getComponentById, getQueryValue } from '@/utils/tools';

export default {
	format(){
		return this.activeId + ' / ' + this.indicator.length
	},
	turnToQues(blockInd){
		this.activeId = blockInd;
		const domId = document.querySelectorAll('.ques-wrapper-item');
		domId[blockInd].scrollIntoView(true)
	},
	initQuesFormatData(jsonData){
		if(!jsonData ||(Object.prototype.toString.call(jsonData) === '[object Object]') && Object.keys(jsonData).length === 0){
			return;
		}
		const data = formValidate(jsonData)
		const recoverData = this.paperLog?.value || {}
		const recoverScore = this.paperLog?.score || {}
		this.correctCount = 0;
		this.errorCount = 0;
		if(Object.keys(recoverData).length){
			const existRecover = Object.keys(recoverData);
			const existScoreRecover = Object.keys(recoverScore);
			for (let item of data) {
				const tagIcon = item?.config.__config__.tagIcon;
				let multiple = item?.config.multiple || ''
				if(item.type === 'treeSelect') multiple =  item?.config.props.props.multiple
				if(existRecover.includes(item.field)){
					const valueWait = recoverData[item.field];
					item.value = formatValue(tagIcon,valueWait,multiple);
					item.answered = false;
					if(['checkbox','upload','video','row'].includes(item.type)){
            if(['checkbox','upload'].includes(item.type) && item.value.length){
              item.answered = true;
            }else if(item.type === 'video' && item.value > item?.config?.__ques__?.minimumLimit - 1){
              item.answered = true;
            }else if(item.type === 'row' && Array.isArray(item.child) && item.child.length){
              this.rowFinshed = true;
              this.checkRowFinish(item.child);
              if(this.rowFinshed){
                item.answered = true;
              }
            }
          }else if(item.value){
            item.answered = true;
          }
				}

				if(existScoreRecover.includes(item.field) && recoverScore[item.field] !== 0){
					this.correctCount += 1;
					item.isCorrect = true;
				}else if(existScoreRecover.includes(item.field) && recoverScore[item.field] === 0){
					this.errorCount += 1;
					item.isCorrect = false;
				}
			}
		}
		this.formValidate.items = this.formValidate.items.concat(data);
		const indicator = []; // 去除文本控件与前置后置表单控件 试题指示器
		let number = 1;
		let totalScore = 0;
		if(this.formValidate.items.length){
			this.formValidate.items.forEach((item,index)=>{
				const position = item?.config.__config__?.position || 1
				const score = item?.config?.__ques__?.score ? parseInt(item?.config?.__ques__?.score) : 0;
				totalScore = totalScore + score;
				if(item.type === 'outerForm' && position !== 2){
					if(position === 1){
						this.position = position;
						this.outerFormElement = item // 考前 填写表单
					}else{
						this.afterPosition = position;
						this.outerAfterElement = item //考后 填写表单
					}
					this.formValidate.items.splice(index,1)
				}
			})
			this.formValidate.items.forEach((item,index)=>{
				if(item.type !== 'text'){
					item.indicator = number // 重新生成序号
					indicator.push({indicator:number, blockInd: index})
					number++;
				}
				if(this.isPaperLog){
					item.disabled = true;
				}else{
					item.disabled = false;
				}
			})
		}
		this.totalScore = totalScore;
		this.indicator = indicator;
		this.skeleton = false;
	},
	isNumber(value){
		return !isNaN(Number(value)) && isFinite(value);
	},
	getSubmitData(){
		// 采集表单输入数据
		const formSubmitData = {};
		const scoreSubmitData = {};
		for (const ele of this.formValidate.items) {
			if (ele.type === "form") {
				const fieldData = [];
				const scoreData = [];
				const equalField = ele?.config.__config__.addShow;
				if(equalField === undefined || equalField){ // 存在交互时，并且字段相同，数据相互覆盖bug
					for (let eleData of ele.value) {
						let itemField = {};
						let itemScore = {};
						for (let eleItem of eleData) {
							itemField[eleItem.field] = eleItem.value;
							itemScore[eleItem.field] = eleItem.score || 0;
						}
						fieldData.push(itemField);
						scoreData.push(itemField);
					}
					formSubmitData[ele.field] = fieldData;
					scoreSubmitData[ele.field] = scoreData;
				}
			} else if (ele.type === "row") {
				if (ele.value) {
					for (let eleItem of ele.value) {
						formSubmitData[eleItem.field] = eleItem.value;
						scoreSubmitData[eleItem.field] = eleItem.score || 0;
					}
				}
			} else {
				if (ele.field) {
					// if(ele.config.__ques__.quesType === 'judge'){
					// 	if(+ele.value === 0){
					// 		formSubmitData[ele.field] = '';
					// 	}else{
					// 		formSubmitData[ele.field] = ele.value;
					// 	}
					// }else{
						formSubmitData[ele.field] = ele.value;
					// }
					scoreSubmitData[ele.field] = ele.score || 0;
				}
			}
		}
		if(this.outerFormElement){
			// 考试前外部表单数据
			formSubmitData[this.outerFormElement.field] = this.outerFormElement.value;
			scoreSubmitData[this.outerFormElement.field] = this.outerFormElement.score || 0;
		}

		if(this.outerAfterElement){
			// 考试后外部表单数据
			formSubmitData[this.outerAfterElement.field] = this.outerAfterElement.value;
			scoreSubmitData[this.outerAfterElement.field] = this.outerAfterElement.score || 0;
		}
		return {value:formSubmitData,score:scoreSubmitData}
	},
	// 完成考试并提交
	submitPaperResult(){
		const submitData = this.getSubmitData()
		const score = Object.values(submitData.score)
		const total = eval(score.join("+"))
		this.$refs.QuesParser.validate(async valid=>{
			// 下级的表单Ref
			const subFormRef = []
			const handleSubValidate = (data) => {
				data.map(el => {
					if(el.$refs && el.$refs.rowFormRef) {
						subFormRef.push(el.$refs.rowFormRef)
					}
					if (Array.isArray(el.$children) && el.$children.length) {
						handleSubValidate(el.$children)
					}
				})
			}
			// 递归获取所有下级需要验证的表单
			handleSubValidate(this.$refs.QuesParser.$children)
			let subflag = true
			try{
				// Promise try catch
				const res = await Promise.all(subFormRef.map(el => el.validate()))
				subflag = res.every(el => el)
			} catch(err) {
				subflag = false
			}
			// 如果所有的验证都通过
			if(valid && subflag) {
				this.$confirm('您的得分为{'+ total +'} 分, 您确定要交卷?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.outerAfterElement){
						this.openOuterForm(this.outerAfterElement)
					} else {
						this.submitData()
					}
				})
			} else {
				this.$message.error('您还有必答试题未完成答题');
			}
		})
	},
	getScore(list = []){
		let count = 0;
		if(list.length){
			for(let key in list){
				count = count + parseInt(list[key]);
			}
		}
		return count;
	},
 /**
  * @description: 完成考试交卷，提交考试记录
  * @param {*} type 1 结束考试 0 作废考试
  * @param {*} submitType 1 手动提交 2 点击返回提交 3 自动提交
  * @return {*}
  */
	submitData(type = 1,submitType = 1){
		const submitData = this.getSubmitData()
		const score = Object.values(submitData.score)
		const total = this.getScore(score);
		let paperLog ={
			...this.paperInfo,
		};
		paperLog.score = +type === 1 ? total : 0;
		paperLog.answer = submitData;
		paperLog.totalScore = this.totalScore;
		const passCondition = this.paperData?.[this.safetyKeyConfig?.passCondition] ? this.paperData?.[this.safetyKeyConfig?.passCondition] : +this.paperData?.pass_condition;
		let passScore = 0;
		if(+passCondition === 1){
			passScore = +this.paperData?.[this.safetyKeyConfig?.passScore]; 
		}else if(+passCondition === 2){
			passScore = +this.totalScore;
		}
		paperLog.isPass = +paperLog.score >= passScore ? 1 : 0;
		this.onResolve(paperLog);
		this.onSubmitInterface(submitType)
	},
	onSubmitInterface(submitType) {
		const {interfaceConfig,mapping,objectData,paramsConfig,requestType} = this.element?.submitConfig;
		const param = {};
		if(paramsConfig.length){
			for (let i = 0; i < paramsConfig.length;i++) {
				const ele = paramsConfig[i];
				let originComponent = getComponentById(this.componentData, ele.componentId);
				if (!originComponent && this.isGroup && this.groupComponents.length) {
					originComponent = getComponentById(this.groupComponents, ele.componentId);
				}
				if (ele.sourceType === 'url') {
					// url参数
					const queryVal = getQueryValue(ele.urlParamsKey);
					this.$set(param, ele.key, queryVal);
				} else if (!ele.sourceType) {
					// 来源于组件
					if (originComponent && originComponent.resolveData) {
						const queryVal = originComponent && originComponent.resolveData[ele.paramKey];
						this.$set(param, ele.key, queryVal);
					}
				} else if (ele.sourceType === 'system') {
					// 系统及架构参数 用户信息，架构信息
					let systemObject = null;
					if (ele.systemKey) {
						if (ele.systemKey === 'userInfo') {
							systemObject = this.$GetUserInfo()
						} else if (ele.systemKey === 'targetArchi') {
							systemObject = this.$GetTargetArchi()
						}
					}
					let systemValue = '';
					if (systemObject && ele.systemCode) {
						systemValue = systemObject[ele.systemCode];
					}
					this.$set(param, ele.key, systemValue);
				}else if (ele.sourceType === 'fixed') {
					param[ele.key] = ele.fixedValue;
				}
			}
		}
		// 配置
    let configObj = null;
    let api = 'api/mapi';
		if (mapping === 'object') {
      configObj = {
        __method_name__: requestType,
        object_uuid: objectData?.objectData?.uuid,
        view_uuid: objectData?.viewData?.uuid,
        ...param,
      };
    } else if (mapping === 'interface') {
      api = `api${interfaceConfig?.url}`;
      configObj = {
        ...param,
      }
    }
		dataInterface(configObj, api).then(res=>{
			if (res && res.status === 200) {
				if(+this.exitType === 1 && this.cacheList.length){
					this.deleteStorage();
				}
				if(submitType === 1){
					this.submitSuccess();
				}else if(submitType === 2){
					this.onAction('back');
				}else if(submitType === 3){
					this.submitPaper(2);
				}
			}
		})
	},
	// 打开外部表单
	async openOuterForm(element) {
		// const element = this.outerFormElement

		const formDesignId = element?.config.__config__.formDesignId
		const url = '/api/metadata/transformuuid/'+ formDesignId
		const response = await formRequest('get', url, {});
		const resData = response.data.data;
		element.config.__config__.designData = resData

		this.outerFormData = resData
		this.outerNeedFlow = !!element?.config.__config__.designData?.need_flow
		this.outerFormMode = element.value ? 'detail': 'add'
		this.outerFormTitle = element?.config.__config__.label
		this.outerFormShowType = element?.config.__config__.showType
		this.outerFormType = element?.config.__config__.formType
		this.outerFormUuid = element?.config.__config__.formDesignId
		this.outerObjectUuid = element?.config.__config__.objectUuid

		if(parseInt(element.value)){
			//数据详情回显
			const url='api/mapi',method='POST';
			let ruuids = [];
			for (let i = 0; i < this.outerFormData?.fields.length; i++) {
				let item = this.outerFormData?.fields[i];
				if (item.__config__.tagIcon == 'form') {
					ruuids.push({ relationship_uuid: item.__vModel__ });
				}
			}
			const param = {
				object_uuid: this.outerObjectUuid,
				view_uuid: '',
				data_id: element.value,
				ruuids,
				__method_name__: 'dataInfo'
			}
			formRequest(method,url,param).then(res => {
				let data = res.data.data;
				this.outerFormDisplay = data;
				this.outerFormVisible = true
			});
		} else {
			this.outerFormVisible = true
		}
	},
	confirmSubmit(){
		this.$refs.outerFormParser.handleSubmit()
	},
	async outerFormSubmit(formSubmitData){
		if(!formSubmitData){
			formSubmitData = this.$refs.outerFormParser.getFormData()
		}
		if(this.outerObjectUuid){
			const url = '/api/mapi'
			const data = {
				__method_name__: 'createData',
				object_uuid: this.outerObjectUuid,
			}
			Object.assign(data,formSubmitData);
			let response = await formRequest('post', url, data);
			if(response.data.code === 200){
				const id = response.data.data.id

				if(this.outerFormElement){
					this.outerFormElement.value = id
					this.outerFormVisible = false;
					this.$message.success(response.data.msg)
				}
				if(this.outerAfterElement){
					this.outerAfterElement.value = id
					this.outerFormVisible = false;
					this.$message.success(response.data.msg)
					this.submitData()
				}
			} else {
				this.$message.error(response.data.msg)
			}
		}
	},
}
