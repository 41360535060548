<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-09-05 10:18:59
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-09-05 10:51:48
 * @FilePath: \dataview-next\src\custom-component\video\CommonVideoCG.vue
 * @Description: 视频组件呈贡专用版本
-->
<template>
  <div class="live-com-container" v-loading="loading">
    <div key="video-wrap" v-if="!loading && successed && liveOption.src" class="live-com-inner" v-generateLive="liveOption">
    </div>
    <div key="video-msg" v-if="!loading && !successed || !liveOption.src" class="none-msg">
      <el-empty description="暂无内容"></el-empty>
    </div>
  </div>
</template>
<script>
import HLS from '@/libs/HLS.js'
import Minin from '../functionCom/mixin'
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
import axios from 'axios'
export default {
  name: 'LiveCom',
  components: {
		'el-empty': Empty,
  },
  mixins: [Minin],
  data() {
    return {
      liveObj: null,
      player: null,
      url: '',
      loading: false,
      successed: true,
    }
  },
  inject: ['EDITOR_pageUUID'],
  created() {
    if (this.database) {
			const { search = [], param = {} } = initParams(
        this.element?.database?.paramsConfig || [],
        this.isGroup,
        this.subComponentData,
        this.groupComponents
      );
			this.getList(this.database, search, param);
		}
    console.log(this.database)
	},
  mounted() {
		// 监听请求
		// 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.element.id]: (data) => {
        if (data.parentId && data.parentId !== this.element.id) return false;
        // 配置时候触发请求
        if (data.componentId === this.element.id && data.isInit) {
          const { search = [], param = {}, canPost } = initParams(
            this.element?.database?.paramsConfig || [],
            this.isGroup,
            this.subComponentData,
            this.groupComponents
          );
          // console.log("配置时候触发请求-------", canPost, search, param);
          if (!canPost) return;
          this.getList(this.element.database, search, param);
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) return;
        // 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
        let { search = [], param = {}, canPost } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents
        );
        // console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
        if (!canPost) return;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.param = param;
        this.getList(this.element.database, search, param);
      }
    }
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
	},
  directives: {
    /**
     * @description 用于生成直播流的指令
     * @param el { Object } element
     * @param binding { Object } src:地址; videoType:播放类型; setLiveObj:设置播放对象; setPlayer: 设置播放对象; player: 返回的实例
     */
    generateLive: (() => {
      // 使用闭包暂存处理函数
      const generateLive = (el, binding) => {
        // 如果存在旧值
        if (binding.oldValue) {
          const typeFlag = binding.oldValue.videoType === binding.value.videoType;
          const srcFlag = binding.oldValue.src === binding.value.src;
          const showControlFlag = binding.oldValue.showControl === binding.value.showControl;
          // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
          if (typeFlag && srcFlag && showControlFlag) {
            return;
          }
        }
        // 如果存在之前的实例，停掉它
        if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
        // 清空el
        el.innerHTML = '';
        const videoObj = document.createElement('video');
        videoObj.style = 'width: 100%; height: 100%;object-fit: fill;';
        videoObj.setAttribute('autoplay', 'autoplay');
        if(binding.value.showControl) {
          videoObj.setAttribute('controls', binding.value.showControl);
        }
        videoObj.setAttribute('playsInline', true);
        videoObj.setAttribute('webkit-playsinline', true);
        videoObj.muted = true;
        const source = document.createElement('source');
        if(binding.value.videoType === 'HLS') {
          if(HLS.isSupported()) {
            const hls = new HLS();
            hls.attachMedia(videoObj);
            hls.on(HLS.Events.MEDIA_ATTACHED, function () {
              console.log('video and hls.js are now bound together !');
              hls.loadSource(binding.value.src);
              hls.on(HLS.Events.MANIFEST_PARSED, function (event, data) {
                console.log('manifest loaded, found ' + data.levels.length + ' quality level');
                videoObj.play();
              })
              hls.on(HLS.ErrorTypes.NETWORK_ERROR, (err, data) => {
                console.error(err, data);
              })
              hls.on(HLS.ErrorTypes.MEDIA_ERROR, (err, data) => {
                console.error(err, data);
              })
              hls.on(HLS.ErrorTypes.OTHER_ERROR, (err, data) => {
                console.error(err, data);
              })
            })
            binding.value.setPlayer(hls);
          }
        } else {
          // TIPS 对上传的内容进行适配
          if(Array.isArray(binding.value.src)&& binding.value.src.length) {
            if(binding.value.src[0].path || binding.value.src[0].url) {
              source.src = binding.value.src[0].path || binding.value.src[0].url;
            }
          } else {
            source.src = binding.value.src || '';
          }
          videoObj.appendChild(source);
          videoObj.setAttribute('loop', true);
          videoObj.setAttribute('autoplay', true);
          videoObj.addEventListener('click', () => {
            videoObj.muted = !videoObj.muted;
          })
        }
        el.appendChild(videoObj);
        binding.value.setLiveObj(videoObj);
      }
      return (
        {
          inserted: generateLive,
          update: generateLive,
          unbind: (el, binding) => {
            if (binding.value.player && binding.value.player.stop) {
              binding.value.player.stop();
            }
            if (binding.value.player && binding.value.player.destroy) {
              binding.value.player.destroy();
            }
          }
        }
      )
    })()
  },
  computed: {
    ...mapState(['componentData']),
    // 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
    liveOption() {
      return  {
        src: this.formatURL(this.url || this.statusConfig.src || this.URLConfig || ''),
        videoType: this.url ? 'YS7' : '' || this.statusConfig.videoType || 'other',
        showControl: this.showControl,
        setLiveObj: this.setLiveObj,
        setPlayer: this.setPlayer,
        player: this.player
      }
    },
    showControl() {
      return this.element && this.element.statusConfig && this.element.statusConfig.showControl;
    }
  },
  methods: {
    /**
     * @description 在 https 下将地址 http 转为 https; 目前只是权宜之计;
     * @param { String } url 需要处理的url
     */
     formatURL(url) {
       if(url && typeof url === 'string') {
         const reg = /http[s]?/i;
         if(window.location.protocol === 'https:') {
           return url.replace(reg, 'https');
         }
       }
       return url;
     },
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search, params = {}) {
      if(!params.appKey || !params.appSecret) return;
      this.loading = true;
			axios.post('http://192.168.16.10:9017/artemis-web/debug', {
        "httpMethod":"POST",
        "path":"/api/video/v1/cameras/previewURLs",
        "headers":{},
        "query":{},
        "parameter":{},
        "body":{
          "cameraIndexCode":"bd97b15532394e53a74af098a0ffa8de",
          "streamType":0,
          "protocol":"hls",
          "transmode":1
        },
        "contentType":"application/json;charset=UTF-8",
        "mock":false,
        "appKey": params.appKey, //"22787793",
        "appSecret": params. appSecret//"zEKok4KjYDmZXl680ch3"
      }).then(res => {
        if(res?.statusCode === 200 && res?.response?.data && res?.response?.data?.url) {
          this.url = res?.response?.data?.url;
          this.successed = true;
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      })
		},
    setLiveObj(obj) {
      this.liveObj = obj;
    },
    setPlayer(obj) {
      this.player = obj;
    }
  }
}
</script>
<style lang="less" scoped>
  .live-com-container{
    width: 100%;
    height: 100%;
    .live-com-inner{
      width: 100%;
      height: 100%;
    }
    .none-msg{
       width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>