<!--
    @name: ques-upload
    @description：ques-upload
    @author: ZengWei
    @date: 2022-04-01 11:20
-->
<template>
  <div class="widget-upload">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        <span class="ques-type">[案例题]</span>
        {{ element.config.__config__.label }}
      </div>
      <div class="q-resource" v-if="element.config.__ques__.resource">
        <ChooseModel
          ref="chooseModel"
          :modelData="element.config.__ques__.resource !== null ? element.config.__ques__.resource: []">
          <template v-slot:showType>
            <el-button
              @click="openModelDrawer"
              size="small"
              type="primary"
            >查看资源</el-button>
          </template>
        </ChooseModel>
      </div>
      <div
        class="q-title"
        v-if="element.config.__ques__.quesShowDesc"
        v-html="element.config.__ques__.quesDesc">
      </div>
    </div>
    <div class="q-option-list">
      <div class="q-option-ul">
        <uploader
          :disabled="disabled"
          :files="element.value"
          @extra-files="onSuccess"
        >
        </uploader>
      </div>
    </div>
  </div>
</template>

<script>
import uploader from "@/components/fileUpload/newFiles/index";
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";


export default {
  name: "ques-upload",
  components: {uploader},
  mixins:[quesWidgetMinix],
  props: ['element','number','disabled'],
  data() {
    return {}
  },
  methods: {
    onSuccess(files){
      this.element.value = files;
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
