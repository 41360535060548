<!--
 * @Description: vue
 * @Author: luocheng
 * @Date: 2021-10-29 12:08:51
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-22 14:05:05
-->
<template>
	<div class="w-full h-full relative">
		<div
			v-if="iframeUrl == ''"
			class="w-full h-full relative"
		>
			<p class="align-center">{{ tipName }}</p>
		</div>
		<iframe
			v-else
			class="w-full h-full"
			:src="iframeUrl"
		/>
	</div>
</template>

<script>
import { baseUrl } from '@/apis/http/request';
import { getToken } from '@/utils/tools';
import axios from 'axios';
export default {
	name: 'DrawingPreview',
	components: {},
	props: {
		info: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			loading: false,
			iframeUrl: '',
			isFinsh: false, // 图纸未完成转换
			tipName: ''
		};
	},
	mounted() {
		let paper = null;
		if(this.info?.paper_img?.length){
			paper = this.info.paper_img[0]
		}
		if (paper != null) {
			if (paper.fileext !== 'dwg') {
				if (paper.path.includes('https:')) {
					this.iframeUrl = 'https://weboffice.bimcc.net?ssl=1&furl=' + paper.path;
				} else if (paper.path.includes('http:')) {
					this.iframeUrl = 'https://weboffice.bimcc.net?furl=' + paper.path;
				} else {
					let url = 'https://weboffice.bimcc.net?furl=';
					if (baseUrl.includes('https://')) {
						url = 'https://weboffice.bimcc.net?ssl=1&furl=';
					}
					this.iframeUrl = url + baseUrl + '/' + this.info.path;
				}
			} else if (paper.fileext === 'dwg') {
				this.loading = true;
				if (paper.model_file_id === undefined) {
					this.tipName = '该文件格式错误，请联系管理员！';
					this.loading = false;
					return;
				}
				axios({
					method: 'post',
					url: baseUrl + 'api/model/auth/token',
					headers: {
						Bimcctoken: getToken()
					}
				}).then(res => {
					if (res.data?.data?.token) {
						axios({
							method: 'get',
							url: 'https://models.bimcc.net/api/v3/model/tranlate?file_id=' + paper.model_file_id,
							headers: {
								authorization: `Bearer ${res.data.data.token}`
							}
						}).then(re => {
							if (re.data.data?.progress === 'Finish') {
								let modelId = re.data.data.data.model_id;
								if (re.data?.data?.data?.f2d && re.data.data.data.f2d.length !== 0) {
									modelId = re.data.data.data.f2d[0].model_id;
								}
								this.isFinsh = true;
								this.iframeUrl =
									'https://models.bimcc.net/viewer/' +
									modelId +
									'?id=' +
									process.env.VUE_APP_MODEL_APPID +
									'&secret=' +
									process.env.VUE_APP_MODEL_APPSECRET;
							} else {
								this.tipName = 'dwg文件转换中...';
								this.loading = false;
							}
						});
					} else {
						this.$message.error('请稍后再试！');
						this.loading = false;
					}
				});
			}
		}
	}
};
</script>
