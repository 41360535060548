<template>
    <PlotSingle
            ref="plotTree"
            class="moduleWrap"
            :element.sync="element"
            :bindViewerId="bindViewerId"
            :bindViewerType="bindViewerType"
            :subItemSpread="subItemSpread"
            @showAll="onShowAll"
            @createGroup="onCreateGroup"
            @updateGroup="onUpdateGroup"
            @removeGroup="onRemoveGroup"
            @createActivePlot="onCreateActivePlot"
            @updateActivePlot="onUpdateActivePlot"
            @removeActivePlot="onRemoveActivePlot"
            @createPlot="onCreatePlot"
            @showPlot="onShowPlot"
            @locationPlot="onLocationPlot"
            @renamePlot="onRenamePlot"
            @preUpdatePlot="onPreUpdatePlot"
            @updatePlot="onUpdatePlot"
            @resetPlot="onResetPlot"
            @saveUpdatePlot="onSaveUpdatePlot"
            @removePlot="onRemovePlot"
    ></PlotSingle>
</template>

<script>
    import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
    import eventBus from '@/plugins/eventBus';
    import { mapState } from 'vuex';
    import { dataInterface } from '@/apis/data/index';
    import PlotSingle from "@/custom-component/viewerComponent/PlotSingle";

    const DataBaseInfo = {
        Group: {
            object_uuid: 'object643758ceb0fce',
            view_uuid: {
                all: 'view64375e6e75e1d',
            }
        },
        Child: {
            object_uuid: 'object643758eb898b2',
            view_uuid: {
                all: 'view64375e4a7f478',
            }
        }
    };

    export default {
        name: "ViewerGISPlot",
        components: {
            PlotSingle
        },
        data() {
            return {
                subItemSpread: 'right',
                bindViewerId: null,
                bindViewerType: 'GIS',
                editObject: null,

                compInitedFlag: false
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {}
                }
            },
            isGroup: {
                type: Boolean,
            },
            groupComponents: {
                type: Array,
                default: () => []
            }
        },
        mixins: [CustomComponentMixin],
        computed: {
            ...mapState(['componentData', 'originComponentData'])
        },
        mounted() {
            this.initEventListener();

            if (this.element.viewerBindId) {
                if (this.checkBind(this.element.viewerBindId)) {
                    this.bindViewerId = this.element.viewerBindId;
                }
            }

            if(this.element.subItemSpread){
                this.subItemSpread = this.element.subItemSpread;
            }

            if(eventBus._events['ViewerGIS.getViewerInitedFlag'] && this.bindViewerId){
                eventBus.$emit("ViewerGIS.getViewerInitedFlag", this.bindViewerId, (opt = {}) => {
                    let {viewerInited = false} = opt;
                    if(viewerInited){
                        this.getPlotData(this.bindViewerId);
                    }else{
                        this.initViewerInitedEventListener();
                    }
                });
            }else{
                this.initViewerInitedEventListener();
            }
        },
        methods: {
            /**
             * @description: 注册组件事件
             */
            initEventListener() {
                eventBus.$on("ViewerGISPlot.GetSource", (eid, cb) => {
                    if (eid !== this.element.id) return;

                    const loop = (array = [], resList = []) => {
                        for (let item of array) {
                            if (item.component !== "ViewerGIS") {
                                if (item.children && item.children.length > 0) loop(item.children, resList);
                                continue;
                            }

                            if (resList.findIndex(x => x.id === item.id) !== -1) continue;
                            resList.push({
                                id: item.id,
                                name: item.name
                            });
                        }

                        return resList;
                    };

                    const comps = [];
                    loop(this.subComponentData, comps);
                    // loop(this.subComponentData, comps);

                    cb({
                        list: comps,
                        bindId: this.bindViewerId,
                        spreadDirection: this.subItemSpread
                    });
                });

                eventBus.$on("ViewerGISPlot.BindViewer", (eid, bindId) => {
                    if (eid !== this.element.id) return;
                    if(this.element.viewerBindId && this.element.viewerBindId === bindId) return;
                    if (!this.checkBind(bindId)) return;

                    this.element.viewerBindId = bindId;
                    this.bindViewerId = bindId;
                    this.$message.success(`[${this.element.name}] 绑定成功`);
                    this.getPlotData(this.bindViewerId);
                });

                eventBus.$on("ViewerGISPlot.SubItemSpread", (eid, direction) => {
                    if (eid !== this.element.id) return;

                    this.element.subItemSpread = direction;
                    this.subItemSpread = direction;
                });

                eventBus.$on('ViewerGISPlot.getCompInitedFlag', (eid , cb) => {
                    if( eid !== this.element.id) return;

                    cb && cb(this.compInitedFlag);
                });

                eventBus.$on("ViewerGISPlot.onPlotShow", (eid, checked) => {
                    if (eid !== this.element.id) return;
                    if(!checked) return;

                    let {nodes, keys} = this.$refs.plotTree.getPlotCheckedNodes();

                    for (let item of nodes){
                        if(item.type ==='Group'){
                            continue;
                        }else if(item.type === 'Child'){
                            let temp = Number(item.id.split('_')[1]);
                            if(!checked.includes(temp)){
                                this.onShowPlot(item, false, false);
                                this.$refs.plotTree.setTreeNodeCheck([item.id], false);
                            }
                        }else if(item.type === 'ChildItem'){
                            if(keys.includes(item.group_id)) continue;

                            let temp = `${item.group_id.split('_')[1]}_${item.childIndex}`;
                            if(!checked.includes(temp)){
                                this.onShowPlot(item, false, false);
                                this.$refs.plotTree.setTreeNodeCheck([item.id], false);
                            }
                        }
                    }

                    for (let item of checked){
                        if(typeof item === 'number'){
                            let data = this.$refs.plotTree.getPlotItem(`child_${item}`);
                            if(!data) continue;

                            this.onShowPlot(data, true, false);
                            this.$refs.plotTree.setTreeNodeCheck([`child_${item}`], true);
                        }else if(typeof item === 'string'){
                            let data = this.$refs.plotTree.getPlotItem(`child_${item.split('_')[0]}`);
                            if(!data) continue;

                            let index = Number(item.split('_')[1]);
                            this.onShowPlot(data.children[index], true, false);
                            this.$refs.plotTree.setTreeNodeCheck([data.children[index].id], true);
                        }
                    }

                    this.getNodeShow();
                });
            },
            /**
             * @description: 注册Viewer组件初始化监听事件
             */
            initViewerInitedEventListener(){
                eventBus.$on("ViewerGIS.onViewerInited", (eid, opt = {}) => {
                    if (eid !== this.bindViewerId) return;

                    let {viewerInited = false} = opt;
                    if(viewerInited) this.getPlotData(this.bindViewerId);
                });
            },
            /**
             * @description: 检验绑定的Viewer是否有效
             * @param {String} bindId Viewer标识值
             */
            checkBind(bindId = this.bindViewerId) {
                if(!bindId) return false;

                const checkFunc = (bid, list) => {
                    let hasComp = false;
                    if(!list) return hasComp;

                    for (let comp of list) {
                        if (comp.children) {
                            const flag = checkFunc(bid, comp.children);

                            if (flag) {
                                hasComp = true;
                                break;
                            }
                        }
                        if (comp.id !== bid) continue;

                        hasComp = true;
                        break;
                    }

                    return hasComp;
                };

                const hasComp = checkFunc(bindId, this.subComponentData);
                // const hasOriginComp = checkFunc(bindId, this.subComponentData);

                if (!hasComp) return this.$message.error(`[${this.element.name}] 绑定失效，组件可能已经被删除`);

                return hasComp;
            },
            /**
             * @description: 检验是否Inited完成
             */
            checkInited(){
                if(!this.compInitedFlag) {
                    this.compInitedFlag = true;
                    eventBus.$emit('ViewerGISPlot.onCompInited', this.element.id);
                    this.getNodeShow();
                }
            },

            /**
             * @description: 获取数据仓库检索条件
             */
            getDataSearch() {
                const archi_id = this.$GetTargetArchi('id');

                let search = [
                    {
                        code: 'archi_type',
                        ruleType: 'eq',
                        value: archi_id
                    },
                    {
                        code: 'type',
                        ruleType: 'eq',
                        value: 'GISPlot'
                    }
                ];

                return search;
            },
            /**
             * @description: 获取标绘列表数据
             */
            async getPlotData(bindId = this.bindViewerId) {
                if (!bindId) {
                    this.emptyPlotData();
                    return;
                }

                this.emptyPlotData();
                this.$refs.plotTree.setIsLoading(true);

                let kmlLoading = [];
                const groupRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch()
                });

                if (
                    groupRes?.data?.data &&
                    groupRes?.data?.data?.data &&
                    groupRes.status === 200
                ) {
                    const groupList = groupRes?.data?.data?.data;

                    for (let { id, name } of groupList) {
                        let item = {
                            id: `group_${id}`,
                            name: name,
                            type: 'Group',
                            icon: 'file',
                            children: []
                        };
                        this.$refs.plotTree.appendTreeData(item, null, false);
                    }
                }

                const childRes = await dataInterface({
                    __method_name__: 'dataList',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    transcode: 0,
                    search: this.getDataSearch()
                });

                if (
                    childRes?.data?.data &&
                    childRes?.data?.data?.data &&
                    childRes.status === 200
                ) {
                    const childList = childRes?.data?.data?.data;

                    for (let {id, name, group_id, state} of childList) {
                        let item = {
                            id: `child_${id}`,
                            name: name,
                            type: 'Child',
                            icon: state.plotType,
                            group_id: `group_${group_id}`,
                            state: state
                        };
                        let msg = this.$refs.plotTree.appendTreeData(item, item.group_id, false);
                        if(msg) {
                            if(state.plotType === 'kml'){
                                kmlLoading.push(item.id);
                                eventBus.$emit('ViewerGIS.createKmlByServe', this.bindViewerId, item.id, state, false, ({child}) => {
                                    child.map((obj, index) => {
                                        let temp = {
                                            id: `${obj.id}`,
                                            name: obj.name,
                                            type: 'ChildItem',
                                            group_id: item.id,
                                            childIndex: index,
                                            icon: `kmlItem_${obj.type}`
                                        };
                                        this.$refs.plotTree.appendTreeData(temp, item.id, false);
                                    });

                                    kmlLoading.splice(kmlLoading.findIndex(x => x === item.id), 1);
                                    if(kmlLoading.length === 0) this.checkInited();
                                });
                            }else{
                                eventBus.$emit('ViewerGIS.createPlot', this.bindViewerId, item.id, state, false);
                            }
                        }
                    }
                }

                this.$refs.plotTree.setIsLoading(false);

                if(kmlLoading.length === 0) this.checkInited();
            },
            /**
             * @description: 清空列表数据
             */
            emptyPlotData(){
                this.$refs.plotTree.emptyTreeData();
                eventBus.$emit('ViewerGIS.removeAllPlot', this.bindViewerId);
                eventBus.$emit('ViewerGIS.removeAllKml', this.bindViewerId);
            },
            /**
             * @description: 设置标绘当前显示的状态
             */
            getNodeShow(){
                this.$nextTick(() => {
                    let {nodes, keys} = this.$refs.plotTree.getPlotCheckedNodes();

                    let checked = [];
                    for (let item of nodes) {
                        if (item.type === 'Group') {
                            continue;
                        } else if (item.type === 'Child') {
                            checked.push(Number(item.id.split('_')[1]));
                        } else if (item.type === 'ChildItem') {
                            if (keys.includes(item.group_id)) continue;

                            checked.push(`${item.group_id.split('_')[1]}_${item.childIndex}`);
                        }
                    }

                    eventBus.$emit('ViewerGISViewPoint.GetPlotStatus', this.element.id, checked);
                });
            },

            /**
             * @description: 显示全部
             * param {Boolean} isShow 是否显示
             */
            onShowAll(isShow){
                this.$refs.plotTree.setAllNodeCheck(isShow);

                let nodes = this.$refs.plotTree.getAllPlotItem();
                for (let item of nodes){
                    this.onShowPlot(item, isShow, false);
                }
                this.getNodeShow();
            },

            /**
             * @description: 创建标绘分组
             */
            async onCreateGroup(){
                let name = await this.$prompt('请输入分组名称：', '提示').catch(() => {});
                if(!name) return;

                const res = await dataInterface({
                    __method_name__: 'createData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    type: 'GISPlot',
                    name: name.value
                });

                if (!res?.data?.data || res.status !== 200){
                    return this.$message.warning(`[GIS]创建标记分组失败！`);
                }
                const data = res?.data?.data;

                let item = {
                    id: `group_${data.id}`,
                    name: name.value,
                    type: 'Group',
                    icon: 'file',
                    children: []
                };
                let msg = this.$refs.plotTree.appendTreeData(item, null, true);
                this.$message.success(`[GIS]创建标绘分组${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 修改标绘分组
             * @param {Object} data 树节点对象
             */
            async onUpdateGroup(data){
                let name = await this.$prompt('请输入分组名称：', '提示', {
                    inputValue: data.name
                }).catch(() => {});
                if(!name) return;

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    data_id: data.id.split('_')[1],
                    name: name.value
                });

                let msg = this.$refs.plotTree.updateTreeData(data.id, 'name', name.value);
                this.$message.success(`[GIS]标绘分组重命名${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 删除标绘分组
             * @param {Object} data 树节点对象
             */
            async onRemoveGroup(data){
                if(data.children){
                    let children = [];
                    for(let item of data.children){
                        children.push({
                            id: item.id,
                            type: item.type,
                            state: item.state
                        })
                    }

                    for(let item of children){
                        await this.onRemovePlot(item, false);
                    }
                }

                await dataInterface({
                    __method_name__: 'deleteData',
                    object_uuid: DataBaseInfo.Group.object_uuid,
                    view_uuid: DataBaseInfo.Group.view_uuid['all'],
                    data_id: data.id.split('_')[1]
                });
                let msg = this.$refs.plotTree.deleteTreeData(data.id);
                this.$message.success(`[GIS]标绘分组删除${msg ? '成功' : '失败'}！`);

                this.getNodeShow();
            },

            /**
             * @description: 创建临时标绘
             * @param {String} type 标绘类型
             * @param {String} drawType 绘制类型
             */
            onCreateActivePlot(type, drawType){
                const createActiveByPlot = (plotType, data) => {
                    eventBus.$emit('ViewerGIS.getPlotOptions', this.bindViewerId, plotType, data.options, (res) => {
                        data.options = res;

                        eventBus.$emit('ViewerGIS.removeMouseCustomEvent', this.bindViewerId);
                        eventBus.$emit('ViewerGIS.createPlot', this.bindViewerId, 'temp', data, true);
                        this.$refs.plotTree.setEditorByActive(data);
                    });
                };

                const createActiveByKml = (data) => {
                    this.$refs.plotTree.setEditorByActive(data);
                };

                switch (drawType) {
                    case 'point':
                        eventBus.$emit('ViewerGIS.drawPointByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [res];
                            let data = {
                                plotType: type,
                                positions: positions,
                                options: {}
                            };

                            createActiveByPlot(type, data);
                        });
                        break;
                    case 'polyline':
                        eventBus.$emit('ViewerGIS.drawPolylineByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [];
                            res.map((item) => {
                                positions.push({lng: item[0], lat: item[1]});
                            });

                            let data = {
                                plotType: type,
                                positions: positions,
                                options: {}
                            };

                            createActiveByPlot(type, data);
                        });
                        break;
                    case 'circle':
                        eventBus.$emit('ViewerGIS.drawCircleByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [res.position];

                            let data = {
                                plotType: type,
                                positions: positions,
                                options: {
                                    radius: res.radius
                                }
                            };

                            createActiveByPlot(type, data);
                        });
                        break;
                    case 'rectangle':
                        eventBus.$emit('ViewerGIS.drawRectangleByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [
                                {lng: res.west, lat: res.south},
                                {lng: res.east, lat: res.south},
                                {lng: res.east, lat: res.north},
                                {lng: res.west, lat: res.north},
                            ];

                            let data = {
                                plotType: type,
                                positions: positions,
                                options: {

                                }
                            };

                            createActiveByPlot(type, data);
                        });
                        break;
                    case 'polygon':
                        eventBus.$emit('ViewerGIS.drawPolygonByMouseCustom', this.bindViewerId, (res) => {
                            let positions = [];
                            res.map((item) => {
                                positions.push({lng: item[0], lat: item[1]});
                            });

                            let data = {
                                plotType: type,
                                positions: positions,
                                options: {}
                            };

                            createActiveByPlot(type, data);
                        });
                        break;
                    case 'file':
                        eventBus.$emit('ViewerGIS.createKmlByLocal', this.bindViewerId, 'temp', (res) => {
                            let {options} = res;

                            let data = {
                                plotType: type,
                                options: options
                            };

                            createActiveByKml(data);
                        });
                        break;
                }
            },
            /**
             * @description: 修改临时标绘
             * @param {String} data 标绘数据
             */
            onUpdateActivePlot(data){
                let {plotType} = data;
                if(plotType === 'kml'){
                    eventBus.$emit('ViewerGIS.updateKml', this.bindViewerId, 'temp', data);
                }else{
                    eventBus.$emit('ViewerGIS.updatePlot', this.bindViewerId, 'temp', data);
                }
            },
            /**
             * @description: 删除临时标绘
             */
            onRemoveActivePlot(){
                eventBus.$emit('ViewerGIS.removePlot', this.bindViewerId, 'temp');
                eventBus.$emit('ViewerGIS.removeKml', this.bindViewerId, 'temp');
                this.$refs.plotTree.setEditorByEmpty();
            },

            /**
             * @description: 创建标绘
             * @param {Object} data 标绘数据
             */
            async onCreatePlot(data){
                const createItem = async (vName, vPId, vState) => {
                    const res = await dataInterface({
                        __method_name__: 'createData',
                        object_uuid: DataBaseInfo.Child.object_uuid,
                        view_uuid: DataBaseInfo.Child.view_uuid['all'],
                        type: 'GISPlot',
                        name: vName,
                        group_id: vPId,
                        state: JSON.stringify(vState)
                    });

                    if (!res?.data?.data || res.status !== 200){
                        return this.$message.warning(`[GIS]创建标绘失败！`);
                    }
                    const resData = res?.data?.data;

                    let item = {
                        id: `child_${resData.id}`,
                        name: vName,
                        type: 'Child',
                        icon: vState.plotType,
                        group_id: `group_${vPId}`,
                        state: vState
                    };
                    let msg = this.$refs.plotTree.appendTreeData(item, item.group_id, true);
                    if(!msg) return false;

                    return item;
                };

                let name = await this.$prompt('请输入标绘名称：', '提示').catch(() => {});
                if(!name) return;

                const group_id = data.options.pId.split('_')[1];

                let {plotType} = data;

                if(plotType === 'kml'){
                    eventBus.$emit('ViewerGIS.saveKmlByLocal', this.bindViewerId, 'temp', name.value, async (res) => {
                        if(!res) return this.$message.warning(`[GIS]保存Kml文件失败！`);

                        eventBus.$emit('ViewerGIS.removeKml', this.bindViewerId, 'temp');
                        let state = {
                            plotType: data.plotType,
                            kmlFile: res,
                            options: data.options,
                        };
                        let item = await createItem(name.value, group_id, state);
                        if(!item) return this.$message.warning(`[GIS]创建标绘失败！`);

                        eventBus.$emit('ViewerGIS.createKmlByServe', this.bindViewerId, item.id, state, true, ({child}) => {
                            child.map((obj, index) => {
                                let temp = {
                                    id: `${obj.id}`,
                                    name: obj.name,
                                    type: 'ChildItem',
                                    group_id: item.id,
                                    childIndex: index,
                                    icon: `kmlItem_${obj.type}`
                                };
                                this.$refs.plotTree.appendTreeData(temp, item.id, true);
                            })
                        });
                        this.getNodeShow();
                        this.$refs.plotTree.setEditorByEmpty();
                        this.$message.success(`[GIS]创建标绘成功！`);
                    });
                }else{
                    eventBus.$emit('ViewerGIS.removePlot', this.bindViewerId, 'temp');

                    let res = await createItem(name.value, group_id, data);
                    if(!res) return this.$message.warning(`[GIS]创建标绘失败！`);

                    eventBus.$emit('ViewerGIS.createPlot', this.bindViewerId, res.id, data, true);
                    this.getNodeShow();
                    this.$refs.plotTree.setEditorByEmpty();
                    this.$message.success(`[GIS]创建标绘成功！`);
                }
            },
            /**
             * @description: 标绘列表显示隐藏事件
             * @param {Object} data 树节点对象
             * @param {Boolean} isChecked 是否勾选
             * @param {Boolean} isDeep 是否加入计算属性
             */
            onShowPlot(data, isChecked, isDeep = true){
                if(data.type === 'Group'){
                    data.children.map((item) => {
                        this.onShowPlot(item, isChecked, isDeep);
                    });
                }else if(data.type === 'Child'){
                    if(data.state.plotType === 'kml'){
                        eventBus.$emit('ViewerGIS.showKml', this.bindViewerId, data.id, isChecked);
                    }else{
                        eventBus.$emit('ViewerGIS.showPlot', this.bindViewerId, data.id, isChecked);
                    }
                }else if(data.type === 'ChildItem'){
                    eventBus.$emit('ViewerGIS.showKmlItem', this.bindViewerId, data.group_id, data.id, isChecked);
                }

                if(isDeep) this.getNodeShow();
            },
            /**
             * @description: 标绘列表对象定位事件
             * @param {Object} data 树节点对象
             * @param {Boolean} isChecked 当前对象是否勾选
             */
            onLocationPlot(data, isChecked){
                if(!isChecked) {
                    this.onShowPlot(data, true);
                    this.$refs.plotTree.setTreeNodeCheck([data.id], true);
                }

                if(data.type === 'Group'){
                    let child = [];
                    data.children.forEach(x => child.push(x.id));

                    eventBus.$emit('ViewerGIS.locationAllGroup', this.bindViewerId, child);
                }else if(data.type === 'Child'){
                    if(data.state.plotType === 'kml'){
                        eventBus.$emit('ViewerGIS.locationKml', this.bindViewerId, data.id);
                    }else{
                        eventBus.$emit('ViewerGIS.locationPlot', this.bindViewerId, data.id);
                    }
                }else if(data.type === 'ChildItem'){
                    eventBus.$emit('ViewerGIS.locationKmlItem', this.bindViewerId, data.group_id, data.id);
                }
            },
            /**
             * @description: 标绘列表对象重命名事件
             * @param {Object} data 树节点对象
             */
            async onRenamePlot(data){
                let name = await this.$prompt('请输入标绘名称：', '提示', {
                    inputValue: data.name
                }).catch(() => {});
                if(!name) return;

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    data_id: data.id.split('_')[1],
                    name: name.value
                });

                let msg = this.$refs.plotTree.updateTreeData(data.id, 'name', name.value);
                this.$message.success(`[GIS]标绘对象重命名${msg ? '成功' : '失败'}！`);
            },
            /**
             * @description: 标绘列表对象预修改事件
             * @param {Object} data 树节点对象
             */
            onPreUpdatePlot(data){
                if(data.type === 'ChildItem'){
                    return this.$message.warning(`[GIS]Kml子对象暂不支持编辑！`);
                }

                this.editObject = data;

                let {state} = data;

                let {plotType, options} = state;
                if(plotType === 'kml'){
                    if(!options.styleType) state.options.styleType = 'Origin';
                }

                this.$refs.plotTree.setEditorByUpdate(JSON.parse(JSON.stringify(state)));
            },
            /**
             * @description: 标绘列表对象修改事件
             * @param {Object} data 修改样式对象
             */
            onUpdatePlot(data){
                if(!this.editObject) return;

                let {plotType} = data;
                if(plotType === 'kml'){
                    eventBus.$emit('ViewerGIS.updateKml', this.bindViewerId, this.editObject.id, data);
                }else{
                    eventBus.$emit('ViewerGIS.updatePlot', this.bindViewerId, this.editObject.id, data);
                }
            },
            /**
             * @description: 标绘列表对象样式重置事件
             */
            onResetPlot(){
                if(!this.editObject) return;

                if(this.editObject.state.plotType === 'kml'){
                    eventBus.$emit('ViewerGIS.updateKml', this.bindViewerId, this.editObject.id, this.editObject.state);
                }else{
                    eventBus.$emit('ViewerGIS.updatePlot', this.bindViewerId, this.editObject.id, this.editObject.state);
                }
                this.$refs.plotTree.setEditorByEmpty();

                this.editObject = null;
            },
            /**
             * @description: 标绘列表对象保存修改事件
             * {Object} data 修改样式对象
             */
            async onSaveUpdatePlot(data){
                if(!this.editObject) return;

                await dataInterface({
                    __method_name__: 'updateData',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    data_id: this.editObject.id.split('_')[1],
                    state: JSON.stringify(data)
                });

                let msg = this.$refs.plotTree.updateTreeData(this.editObject.id, 'state', data);
                if(msg) {
                    if(data.plotType === 'kml'){
                        eventBus.$emit('ViewerGIS.updateKml', this.bindViewerId, this.editObject.id, data);
                    }else{
                        eventBus.$emit('ViewerGIS.updatePlot', this.bindViewerId, this.editObject.id, data);
                    }
                    this.$refs.plotTree.setEditorByEmpty();
                }
                this.$message.success(`[GIS]标绘修改${msg ? '成功' : '失败'}！`);

                this.editObject = null;
            },
            /**
             * @description: 标绘列表对象删除事件
             * @param {Object} data 树节点对象
             * @param {Boolean} isDeep 是否加入计算属性
             */
            async onRemovePlot(data, isDeep = true){
                if(data.type === 'ChildItem'){
                    return this.$message.warning(`[GIS]Kml子对象暂不支持删除！`);
                }

                await dataInterface({
                    __method_name__: 'deleteData',
                    object_uuid: DataBaseInfo.Child.object_uuid,
                    view_uuid: DataBaseInfo.Child.view_uuid['all'],
                    data_id: data.id.split('_')[1]
                });

                let msg = this.$refs.plotTree.deleteTreeData(data.id);
                if(msg) {
                    if(data.state.plotType === 'kml'){
                        eventBus.$emit('ViewerGIS.removeKml', this.bindViewerId, data.id);
                    }else{
                        eventBus.$emit('ViewerGIS.removePlot', this.bindViewerId, data.id);
                    }
                    if(isDeep) this.getNodeShow();
                }
                this.$message.success(`[GIS]删除标绘${msg ? '成功' : '失败'}！`);
            }
        },
        destroyed(){
            eventBus.$emit('ViewerGIS.removeAllPlot', this.bindViewerId);
            eventBus.$emit('ViewerGIS.removeAllKml', this.bindViewerId);
        }
    }
</script>

<style scoped>

</style>
