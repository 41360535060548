<!--
 * @Author: 姚铃 1871883713@qq.com
 * @Date: 2023-08-15 15:25:58
 * @LastEditors: zyf
 * @LastEditTime: 2023-10-07 11:47:27
 * @FilePath: \dataview-next\src\custom-component\task-flow\components\TaskStatus.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div class="task-status">
		<el-table
			ref="table"
			:data="form"
			:key="Math.random()"
			border
			:row-key="getRowKeyOfTarget"
      :cell-class-name="tableCellClassName"
			:header-cell-style="{
				height: '32px',
				padding: '4.5px 0',
				color: 'rgba(24, 27, 34, 1)',
				'background-color': 'rgba(240, 241, 244, 1)',
				'text-align': 'center',
				'font-family': 'PingFang SC',
				'font-size': '12px',
				'line-height': '18px',
				'letter-spacing': '0.4000000059604645px'
			}"
			:cell-style="{
				height: '32px',
				padding: '0',
				color: 'rgba(24, 27, 34, 1)',
				'text-align': 'center',
				'font-family': 'PingFang SC',
				'font-size': '12px',
				'line-height': '18px',
				'letter-spacing': '0.4000000059604645px'
			}"
			style="width: auto"
			@cell-click="editCell"
			@select-all="selectAll"
			@selection-change="handleSelectionChange"
			>
			<el-table-column
				v-if="hasCheckbox"
				:reserve-selection="true"
				type="selection"
				width="52">
			</el-table-column>
			<el-table-column
				label="序号"
        width="52">
        <template slot-scope="scope">
          {{scope.$index + 1}}
        </template>
      </el-table-column>
			<!-- 全局配置 -->
			<el-table-column
				prop="status"
				:width="hasOperate ? 76 : ''"
				label="状态">
				<template slot-scope="scope">
					<el-input
						v-if="scope.row.index + ',' + scope.column.index == currentCell && scope.row.isEdit"
						type="text"
						:ref="scope.row.index + ',' + scope.column.index"
						v-model="scope.row.status"
						@blur.stop="blurInput(scope.row)">
					</el-input>
					<span
						v-else
						class="hover-area"
						>{{ scope.row.status }}</span
					>
				</template>
			</el-table-column>
      <el-table-column
        prop="index"
        width="76"
        label="标识"
        v-if="!hasFieldValue">
        <template slot-scope="scope">
          <el-input
            :ref="scope.row.index + ',' + scope.column.index"
            v-if="scope.row.index + ',' + scope.column.index == currentCell && scope.row.isEdit  && !defaultStatusArr.includes(scope.row.index)"
            type="text"
            v-model="scope.row.index"
            @blur.stop="blurInput(scope.row)">
          </el-input>
          <span
            v-else
            class="hover-area"
          >{{ scope.row.index }}</span>
        </template>
      </el-table-column>
			<el-table-column
				prop="color"
				width="52"
				label="颜色">
				<template slot-scope="scope">
					<el-popover
						v-if="isColorEdit"
						placement="bottom"
						title="状态颜色"
						width="198"
            v-model="scope.row.visible"
						:visible-arrow="false"
						trigger="click"
						popper-class="color-popover-style">
						<div class="color-content">
							<div
								class="item-content"
								v-for="(item, index) in colorLists"
								:class="[colorIndex === index ? 'active' : '']"
								:key="index">
								<div
									class="item"
									@click.stop="checkColor(scope.row, item, index)"
									:style="{ backgroundColor: item }"></div>
							</div>
						</div>
						<div
							slot="reference"
							class="color-board"
							@click.stop
							:style="{ backgroundColor: scope.row.color }"></div>
					</el-popover>
					<div
						v-else
						class="color-board"
						@click.stop
						:style="{ backgroundColor: scope.row.color }"></div>
				</template>
			</el-table-column>
			<el-table-column
        v-if="hasFieldValue"
				prop="fieldValue"
				width="76"
				label="字段值">
				<template slot-scope="scope">
					<el-input
            :ref="scope.row.index + ',' + scope.column.index"
						v-if="scope.row.index + ',' + scope.column.index == currentCell && scope.row.isEdit"
						type="text"
						v-model="scope.row.fieldValue"
						@blur.stop="blurInput(scope.row)">
					</el-input>
					<span
						v-else
						class="hover-area"
						>{{ scope.row.fieldValue }}</span>
				</template>
			</el-table-column>
			<el-table-column
				v-if="hasOperate"
				label="操作"
				width="52">
				<template slot-scope="scope">
					<el-button
            v-if="!defaultStatusArr.includes(scope.row.index)"
						type="text"
						size="mini"
						@click="deleteRow(scope.$index)">
            <span class="del-text">删除</span>
          </el-button>
          <span v-else>-</span>
				</template>
			</el-table-column>
		</el-table>
		<el-button
			v-if="hasAdd"
			icon="el-icon-plus"
			@click.stop="addStatusRow">
      <span>新增</span>
    </el-button>
	</div>
</template>

<script>
import { Popover } from 'element-ui';
import { colorList } from '@/utils/color';

export default {
	identifying: 'TaskStatus',
	components: {
		'el-popover': Popover
	},
	props: {
		// 是否有操作
		hasOperate: {
			type: Boolean,
			default: false
		},
		// 是否有标识
		hasIdentifying: {
			type: Boolean,
			default: false
		},
		// 是否有多选框
		hasCheckbox: {
			type: Boolean,
			default: false
		},
		// 是否有字段值
		hasFieldValue: {
			type: Boolean,
			default: false
		},
		// 是否可以新增
		hasAdd: {
			type: Boolean,
			default: false
		},
		// 状态是否可以编辑
		isStatusEdit: {
			type: Boolean,
			default: false
		},
		// 颜色是否可以编辑
		isColorEdit: {
			type: Boolean,
			default: true
		},
		statusList: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
      activeList: [],
			form: [],
			// 颜色选择列表
			colorLists: [],
			colorIndex: null,
			checkList: [],
			// 当前选中框
			currentCell: '',
      visible: false,
      defaultStatusArr:[0,1,2,3,4,5]
		};
	},
	watch: {
		form: {
			handler(val) {
				this.$emit('status-change', val);
			},
			deep: true
		}
	},
	computed: {},
	mounted() {
		// 获取颜色
		this.colorLists = JSON.parse(JSON.stringify(colorList));
		const taskStatusList = this.$store.state.task.taskStatusList;
    this.form = taskStatusList;
    this.$nextTick(() => {
      const arr = Array.from(this.statusList,t=>t.index)
      this.form.forEach((item) => {
        if(arr.includes(item.index)){
          this.$refs.table.toggleRowSelection(item);
        }
      });
    });
		this.changeValue();
	},
	methods: {
		/**
		 * @desc: 颜色选择
		 * @param {String} data 颜色编码
		 */
		checkColor(row, data, index) {
			row.color = data;
      row.visible = false
			this.colorIndex = index;
		},
		/**
		 * @desc: 新增状态行
		 */
		addStatusRow() {
			const data = {
				identifying: '',
				status: '',
				color: 'rgba(191, 199, 216, 1)',
				fieldValue: '',
				isEdit: true
			};
			this.form.push(data);
			this.changeValue();
		},
		changeValue() {
			this.$store.commit('setStatusList', this.form);
			this.$emit('status-change', this.form);
		},
		blurInput(row) {
			if (row) {
				row.isEdit = false;
				this.activeLabel = '';
			}
			this.$emit('edit', this.form);
		},
		editCell(row, column) {
      if(this.isStatusEdit || (column.index === 3 && this.hasFieldValue)) {
        row.isEdit = true;
        this.currentCell = row.index + ',' + column.index;
        this.$nextTick(() => {
          this.$refs[row.index + ',' + column.index].focus();
        });
      }
		},
		deleteRow(index) {
			this.form.splice(index, 1);
		},
		// onEdit(column, row) {
    //   console.log(column, 'column')
    //   console.log(row, 'row')
		// 	if (row) {
		// 		row.isEdit = true;
		// 	}
    //   this.$nextTick(() => {
		// 		this.$refs[row.index + ',' + column.index].focus();
		// 	});
		// },
		handleSelectionChange(val) {
			this.checkList = val;
			this.$emit('check', this.checkList);
		},
		selectAll(list) {
			this.checkList = list;
			this.$emit('check', this.checkList);
		},
		getRowKeyOfTarget(row) {
			return row.index;
		},
		/**
		 * @desc: 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
		 * @param {Object} row
		 * @param {Object} column
		 * @param {Number} rowIndex
		 * @param {Number} columnIndex
		 */
		tableCellClassName({ row, column, rowIndex, columnIndex }) {
			row.index = rowIndex;
			column.index = columnIndex;
		}
	}
};
</script>

<style lang="less" scoped>
.task-status {
	height: 100%;
	overflow: hidden;
	:deep(.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell) {
		background: transparent;
	}
	.del-text {
		color: #ff0000;
	}
	.hover-area {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 58px;
		height: 26px;
		border-radius: 6px;
	}
	.border {
		border: 1px solid transparent;
	}
	.border:hover {
		border-color: rgba(56, 127, 252, 1);
	}
	:deep(.border::before) {
		border: none;
	}
	:deep(.el-input--small .el-input__inner) {
		padding: 0 4px;
		border-radius: 6px;
	}
	:deep(.el-table .cell) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.color-circle {
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}
	.color-board {
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}
	.el-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 32px;
		color: rgba(56, 127, 252, 1);
		font-family: PingFang SC;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.4000000059604645px;
		text-align: center;
		border-top: none;
		span {
			padding-left: 4px;
			box-sizing: border-box;
		}
	}
	.el-button:hover {
		background-color: transparent;
	}
}
</style>
<style lang="less">
.el-popover.color-popover-style {
	width: 198px;
	height: 232px;
	padding: 12px 16px;
	box-sizing: border-box;
	border-radius: 8px;
	box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.1);
	.el-popover__title {
		color: rgba(24, 27, 34, 1);
		font-family: PingFang SC;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.4000000059604645px;
	}
	.color-content {
		display: grid;
		grid-auto-flow: row dense;
		grid-template-columns: repeat(5, 28px);
		grid-template-rows: repeat(5, 28px);
		grid-gap: 8px 6.5px;
		border-radius: 8px;
		background-color: transparent;
		.item-content {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			border: 1px solid transparent;
			border-radius: 4px;
			.item {
				width: 24px;
				height: 24px;
				border-radius: 4px;
				border: 1px;
			}
		}
		.item-content:hover {
			border-color: rgba(99, 157, 255, 1);
		}
		.active {
			border-color: rgba(99, 157, 255, 1);
		}
	}
}
.el-popover.status-popover-style {
	width: 115px;
	min-width: 115px;
	height: auto;
	padding: 4px;
	box-sizing: border-box;
	border-radius: 8px;
	box-shadow: 0px 2px 16px 0px rgba(25, 27, 35, 0.1);
	.status-content {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		background-color: transparent;
		.item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			height: 28px;
			padding-left: 12px;
			box-sizing: border-box;
			border-radius: 4px;
			border: 1px;
			.status-color {
				width: 12px;
				height: 12px;
				margin-right: 8px;
				border-radius: 2px;
			}
			.status-desc {
				color: rgba(24, 27, 34, 1);
				font-family: PingFang SC;
				font-size: 12px;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 0.4000000059604645px;
			}
		}
		.item:hover {
			background-color: rgba(244, 246, 249, 1);
		}

		// .item-content:hover {
		// 	border-color: rgba(99, 157, 255, 1);
		// }
		// .active {
		// 	border-color: rgba(99, 157, 255, 1);
		// }
	}
}
</style>
